<template>
  <v-card class="card-wrap">
    <v-container
      fluid
      :class="[
        'pt-6',
        'body-2',
        'card-layout',
        confirmationState === 'register' ? 'heightNotFull' : 'heightFull',
      ]"
    >
      <v-icon
        v-if="
          confirmationState !== 'register' ||
            stateRegistration === 1 ||
            stateRegistration === 2
        "
        style="position: absolute; right: 2vw; top: 1.5vw; z-index: 10;"
        @click="onCloseButtonClick"
        >mdi-close</v-icon
      >

      <!-- Register Header -->
      <div
        v-if="
          state === 'order' &&
            confirmationState === 'register' &&
            activityStatus !== 'detail-register'
        "
      >
        <div v-if="stateRegistration === 0">
          <h2 class="title">Pendaftaran Pasien</h2>
        </div>

        <div v-if="stateRegistration === 1">
          <v-row no-gutters class="card-layout__header px-16">
            <v-col cols="9" align-self="center" class="text-left">
              <div class="grid-header">
                <div>
                  <span>No RM</span>
                  <span class="black--text font-weight-bold mx-8">{{
                    patientRegistration('rmNumber') !== ''
                      ? patientRegistration('rmNumber')
                      : generatedRmNumber
                  }}</span>
                </div>

                <div>
                  <span>Name</span>
                  <span class="black--text font-weight-bold mx-8"
                    >{{ patientRegistration('name') }} ({{
                      patientRegistration('gender') ? 'L' : 'P'
                    }})</span
                  >
                </div>

                <div>
                  <span>Tanggal Lahir</span>
                  <span class="black--text font-weight-bold mx-8"
                    >{{ formatDateTime(patientRegistration('birthDate')) }} ({{
                      patientRegistration('age')
                    }}
                    tahun)</span
                  >
                </div>
              </div>
              <div class="py-3">
                <span>Asal Poli</span>
                <span class="black--text font-weight-bold mx-6"
                  >Pendaftaran</span
                >
              </div>
            </v-col>
            <v-col cols="3" class="text-right"
              ><span class="black--text font-weight-bold headline mr-8"
                >Pemeriksaan Laboratorium</span
              ></v-col
            >
          </v-row>
        </div>

        <div v-if="stateRegistration === 2">
          <span class="black--text font-weight-bold headline mr-8">
            Detail Permintaan Pemeriksaan Laboratorium
          </span>
          <div class="d-flex justify-center align-center detail-header">
            <div>
              <span>No RM</span>
              <span class="black--text font-weight-bold mx-3">{{
                patientRegistration('rmNumber') !== ''
                  ? patientRegistration('rmNumber')
                  : generatedRmNumber
              }}</span>
            </div>
            <div>
              <span>Name</span>
              <span class="black--text font-weight-bold mx-3"
                >{{ patientRegistration('name') }} ({{
                  patientRegistration('gender') ? 'L' : 'P'
                }})</span
              >
            </div>
            <div>
              <span>Tanggal Lahir</span>
              <span class="black--text font-weight-bold mx-3"
                >{{ formatDateTime(patientRegistration('birthDate')) }} ({{
                  patientRegistration('age')
                }}
                tahun)</span
              >
            </div>
            <div>
              <span>Asal Poli</span>
              <span class="black--text font-weight-bold mx-3">Pendaftaran</span>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="
          state === 'detail' ||
            confirmationState === 'confirmation' ||
            (activityStatus === 'detail-register' &&
              confirmationState === 'register')
        "
      >
        <span class="black--text font-weight-bold headline mr-8">
          Detail Permintaan Pemeriksaan Laboratorium
        </span>
        <div class="d-flex justify-center align-center detail-header">
          <div>
            <span>No RM</span>
            <span class="black--text font-weight-bold mx-3">{{
              dataLab === undefined
                ? getPatientData?.rmNumber
                : dataLab?.patient?.rmNumber
            }}</span>
          </div>
          <div>
            <span>Name</span>
            <span class="black--text font-weight-bold mx-3"
              >{{
                dataLab === undefined
                  ? getPatientData?.meta?.name
                  : dataLab?.patient?.name
              }}
              ({{
                dataLab === undefined
                  ? getPatientData?.meta?.gender === 'Laki-laki'
                    ? 'L'
                    : 'P'
                  : dataLab?.patient?.gender
              }})</span
            >
          </div>
          <div>
            <span>Tanggal Lahir</span>
            <span class="black--text font-weight-bold mx-3"
              >{{
                formatDateTime(
                  dataLab === undefined
                    ? getPatientData?.meta?.birthDateUnformatted
                    : dataLab?.patient?.birthDate,
                )
              }}({{
                dataLab === undefined
                  ? getPatientData?.meta?.age
                  : dataLab?.patient.age
              }}
              tahun)</span
            >
          </div>
          <div
            v-if="
              activityStatus !== 'detail-register' &&
                confirmationState !== 'register'
            "
          >
            <span>Petugas</span>
            <span class="black--text font-weight-bold mx-3">{{
              dataLab === undefined
                ? labByReqId?.timestamps?.created_by?.name === undefined
                  ? '-'
                  : labByReqId?.timestamps?.created_by?.name
                : dataLab.createdBy
            }}</span>
          </div>
          <div>
            <span>Asal Poli</span>
            <span class="black--text font-weight-bold mx-3">{{
              dataLab === undefined
                ? getPatientData?.meta?.polyName
                : dataLab.polyName
            }}</span>
          </div>
        </div>
      </div>

      <div
        v-if="
          state === 'order' &&
            confirmationState !== 'confirmation' &&
            confirmationState !== 'register'
        "
      >
        <v-row no-gutters class="card-layout__header px-16">
          <v-col cols="9" align-self="center" class="text-left">
            <div class="grid-header">
              <div>
                <span>No RM</span>
                <span class="black--text font-weight-bold mx-8">{{
                  dataLab === undefined
                    ? getPatientData?.rmNumber
                    : dataLab?.patient?.rmNumber
                }}</span>
              </div>

              <div>
                <span>Name</span>
                <span class="black--text font-weight-bold mx-8"
                  >{{
                    dataLab === undefined
                      ? getPatientData?.meta?.name
                      : dataLab?.patient?.name
                  }}
                  ({{
                    dataLab === undefined
                      ? getPatientData?.meta?.gender === 'Laki-laki'
                        ? 'L'
                        : 'P'
                      : dataLab?.patient?.gender
                  }})</span
                >
              </div>

              <div>
                <span>Tanggal Lahir</span>
                <span class="black--text font-weight-bold mx-8"
                  >{{
                    formatDateTime(
                      dataLab === undefined
                        ? getPatientData?.meta?.birthDateUnformatted
                        : dataLab?.patient?.birthDate,
                    )
                  }}({{
                    dataLab === undefined
                      ? getPatientData?.meta?.age
                      : dataLab?.patient?.age
                  }}
                  tahun)</span
                >
              </div>
            </div>
            <div class="py-3">
              <span>Petugas</span>
              <span class="black--text font-weight-bold mx-6">{{
                dataLab === undefined
                  ? labByReqId?.timestamps?.created_by?.name === undefined
                    ? '-'
                    : labByReqId?.timestamps?.created_by?.name
                  : dataLab.createdBy
              }}</span>
              <span>Asal Poli</span>
              <span class="black--text font-weight-bold mx-6">{{
                dataLab === undefined
                  ? getPatientData?.meta?.polyName
                  : dataLab.polyName
              }}</span>
              <span v-if="confirmationState === 'result'">No Order</span>
              <span
                v-if="confirmationState === 'result'"
                class="black--text font-weight-bold mx-6"
                >{{ dataLab.orderNumber }}</span
              >
            </div>
          </v-col>
          <v-col cols="3" class="text-right"
            ><span class="black--text font-weight-bold headline mr-8"
              >Pemeriksaan Laboratorium</span
            ></v-col
          >
        </v-row>
      </div>

      <!-- Registration -->
      <template
        v-if="
          confirmationState === 'register' &&
            activityStatusLab !== 'detail-register'
        "
      >
        <div v-if="stateRegistration === 0" class="px-8 py-8">
          <SocialDataPatientsLaboratory ref="patient" />
        </div>
        <div v-if="stateRegistration === 1" class="px-8 py-8">
          <OrderExaminationTab ref="order" state="register" />
        </div>
        <div v-if="stateRegistration === 2" class="px-8 py-8">
          <DetailExaminationTab ref="update" />
        </div>
      </template>

      <template
        v-if="
          confirmationState === 'register' &&
            activityStatusLab === 'detail-register'
        "
      >
        <div class="py-8">
          <v-divider></v-divider>
          <div class="px-8 py-8">
            <DetailExaminationTab />
          </div>
        </div>
      </template>

      <!-- Confirmation -->
      <template v-if="confirmationState === 'confirmation'">
        <div class="py-8">
          <v-divider></v-divider>
          <div class="px-8 py-8">
            <div
              v-if="dataLab?.isConfirmed"
              class="text-start"
              style="width: 200px;"
            >
              <v-alert
                dense
                text
                type="success"
                style=" border: 0.5px solid #BDBDBD;"
              >
                <span
                  style="color: #404040; font-weight: 400; font-size: 14px;"
                >
                  Sudah Dikonfirmasi
                </span>
              </v-alert>
            </div>
            <DetailExaminationTab
              :confirmation-state="'confirmation'"
              ref="detailConfirmation"
              :isConfirmed="dataLab?.isConfirmed"
            />
          </div>
        </div>
      </template>

      <!-- Result -->
      <template v-if="confirmationState === 'result'">
        <div class="py-8">
          <v-divider></v-divider>
          <div class="px-8 py-8">
            <ResultExaminationTab
              :stateIsFinished="activityStatusLab"
              stateResult="result"
              ref="result"
            />
          </div>
        </div>
      </template>

      <!-- Order Not shceduled -->
      <template v-if="activityStatusLab === 'not_scheduled'">
        <div v-if="state === 'detail'">
          <div class="py-8">
            <v-divider></v-divider>
            <div class="px-8 py-8">
              <DetailExaminationTab />
            </div>
          </div>
        </div>
        <div v-if="state === 'order'">
          <v-main class="py-8 container-layout px-16">
            <v-tabs grow v-model="tabs">
              <v-tab class="text-none w-100"
                >Order Permintaan Laboratorium</v-tab
              >
              <v-tab class="text-none">Hasil Pemeriksaan Laboratorium</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabs" class="pt-6">
              <v-tab-item><OrderExaminationTab ref="order"/></v-tab-item>
              <v-tab-item
                ><ResultExaminationTab
                  :stateIsFinished="activityStatusLab"
                  stateResult="request"
              /></v-tab-item>
            </v-tabs-items>
          </v-main>
        </div>
      </template>

      <!-- Order Scheduled but not confirmed -->
      <template v-else-if="activityStatusLab === 'scheduled'">
        <v-main class="py-8 container-layout px-16">
          <v-tabs grow v-model="tabs">
            <v-tab class="text-none w-100"
              >Detail Permintaan Laboratorium</v-tab
            >
            <v-tab class="text-none">Hasil Pemeriksaan Laboratorium</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabs" class="pt-4">
            <v-tab-item class="py-4"
              ><DetailExaminationTab ref="update"
            /></v-tab-item>
            <v-tab-item
              ><ResultExaminationTab
                :stateIsFinished="activityStatusLab"
                stateResult="request"
            /></v-tab-item>
          </v-tabs-items>
        </v-main>
        <v-snackbar
          light
          content-class="font-weight-medium pr-0"
          :value="snackbar.show"
          elevation="0"
          :color="snackbar.color"
          height="15"
        >
          <div class="d-flex justify-space-between align-center">
            <span style="color: white">
              {{ snackbar.text }}
            </span>
            <v-btn
              style="color: white"
              text
              fab
              small
              right
              @click="snackbar.show = false"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </div>
        </v-snackbar>
      </template>

      <!-- Order Confirmed -->
      <template v-else-if="activityStatusLab === 'confirmed'">
        <v-main class="py-8 container-layout px-16">
          <v-tabs grow v-model="tabs">
            <v-tab class="text-none w-100"
              >Detail Permintaan Laboratorium</v-tab
            >
            <v-tab class="text-none">Hasil Pemeriksaan Laboratorium</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabs" class="pt-4">
            <v-tab-item class="py-4"><DetailExaminationTab /></v-tab-item>
            <v-tab-item
              ><ResultExaminationTab
                :stateIsFinished="activityStatusLab"
                stateResult="request"
            /></v-tab-item>
          </v-tabs-items>
        </v-main>
      </template>
    </v-container>
    <v-card-actions color="white" class="card-footer">
      <div v-if="state === 'detail'">
        <v-btn
          outlined
          color="secondary"
          class="text-none rounded"
          tile
          @click="handleSetState('order')"
        >
          Kembali
        </v-btn>
      </div>
      <span
        v-if="
          confirmationState === 'register' &&
            activityStatusLab === 'detail-register'
        "
      >
        <v-btn
          text
          color="secondary"
          class="text-none rounded mr-4"
          tile
          @click="handleBackRegistration"
        >
          Kembali
        </v-btn>
      </span>
      <div v-if="state !== 'detail'"></div>
      <div>
        <span
          v-if="
            confirmationState !== 'register' ||
              (confirmationState === 'register' &&
                activityStatusLab === 'detail-register')
          "
        >
          <v-btn
            v-if="confirmationState !== 'confirmation'"
            :outlined="
              confirmationState === 'result' && dataLab?.isFinished === true
            "
            color="primary"
            class="text-none rounded mr-4"
            tile
            :disabled="
              (confirmationState === 'result' &&
                dataLab?.isInputed === false) ||
                (activityStatusLab === 'confirmed' &&
                  labByReqId?.isInputed === false) ||
                activityStatusLab === 'scheduled' ||
                activityStatusLab === 'not_scheduled' ||
                (activityStatusLab === 'detail-register' &&
                  labByReqId?.isInputed === false)
            "
            depressed
            @click="printResult()"
          >
            Print{{
              activityStatusLab === 'detail-register'
                ? ' Hasil Laboratorium'
                : ''
            }}
          </v-btn>
        </span>
        <span
          v-if="
            confirmationState === 'register' &&
              activityStatusLab !== 'detail-register'
          "
        >
          <v-btn
            text
            color="secondary"
            class="text-none rounded mr-4"
            tile
            @click="handleBackRegistration"
          >
            Kembali
          </v-btn>
        </span>
        <span v-if="dataLab?.isFinished !== true">
          <v-btn
            :outlined="
              confirmationState === 'register' && stateRegistration !== 2
            "
            :disabled="onState()?.disabled"
            v-if="
              (tabs === 0 && activityStatusLab !== 'confirmed') ||
                confirmationState === 'confirmation' ||
                confirmationState === 'result' ||
                (confirmationState === 'register' &&
                  activityStatusLab !== 'detail-register')
            "
            color="primary"
            depressed
            tile
            class="text-none rounded"
            @click="onState()?.method()"
            >{{ onState()?.buttonText }}</v-btn
          >
        </span>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from 'moment';

import Constant from '@/const';
import printMixin from '@/mixin/printMixin';
import formatMixin from '@/mixin/formatMixin';
import AlertMixin from '@/mixin/alertMixin';
import OrderExaminationTab from './OrderExaminationTab.vue';
import DetailExaminationTab from './DetailExaminationTab.vue';
import ResultExaminationTab from './ResultExaminationTab.vue';
import printLaboratoryResult from '@/mixin/printLaboratoryResult.js';
import SocialDataPatientsLaboratory from '../../../Registration/components/SocialDataPatientsLaboratory.vue';

import { createNamespacedHelpers } from 'vuex';
const {
  mapGetters: labGetters,
  mapActions: labActions,
  mapMutations: labMutations,
} = createNamespacedHelpers('ExaminationLaboratory');
const {
  mapGetters: patientGetters,
  mapMutations: patientMutations,
} = createNamespacedHelpers('PatientRegistration');

export default {
  name: 'ExaminationDialogV2',
  mixins: [AlertMixin, printMixin, formatMixin, printLaboratoryResult],
  components: {
    OrderExaminationTab,
    ResultExaminationTab,
    DetailExaminationTab,
    SocialDataPatientsLaboratory,
  },
  computed: {
    ...labGetters([
      'getInputOrderCheckedItems',
      'getProvisionalDiagnosis',
      'getPatientData',
      'getShowDialog',
      'getActivityStatusLab',
      'getSnackbar',
      'getIsChanged',
      'getInputResultIsChanged',
      'getLabReqById',
      'getLabResultById',
    ]),
    ...patientGetters(['getPatientRegistration', 'getGeneratedRmNumber']),
    inputOrderCheckedItems: {
      get() {
        return this.getInputOrderCheckedItems;
      },
    },
    provisionalDiagnosis: {
      get() {
        return this.getProvisionalDiagnosis;
      },
    },
    showDialog: {
      get() {
        return this.getShowDialog;
      },
    },
    activityStatusLab: {
      get() {
        return this.getActivityStatusLab;
      },
      set(value) {
        this.setActivityStatusLab(value);
      },
    },
    snackbar: {
      get() {
        return this.getSnackbar;
      },
      set(value) {
        this.setSnackbar(value);
      },
    },
    isChanged: {
      get() {
        return this.getIsChanged;
      },
    },
    inputResultIsChanged: {
      get() {
        return this.getInputResultIsChanged;
      },
    },
    labByReqId: {
      get() {
        return this.getLabReqById;
      },
    },
    labResult: {
      get() {
        return this.getLabResultById;
      },
    },
    letterData() {
      return Constant.client.find(
        item =>
          item.name.toLowerCase() ===
          this.$store.getters.userLoggedIn.clinic.name.toLowerCase(),
      );
    },
    patientRegistration() {
      return function(key) {
        return this.getPatientRegistration[key];
      };
    },
    generatedRmNumber: {
      get() {
        return this.getGeneratedRmNumber;
      },
    },
  },
  props: {
    patientData: Object,
    activityStatus: String,
    confirmationState: String,
    dataLab: Object,
    stateResult: String,
  },
  data() {
    return {
      tabs: null,
      state: 'order',
      stateRegistration: 0,
    };
  },
  mounted() {
    this.clearSnackbar();
    this.setPatientData(this.patientData);
    this.setActivityStatusLab(this.activityStatus);
  },
  watch: {
    showDialog: {
      deep: true,
      handler() {
        if (!this.showDialog) {
          this.$emit('close-dialog');
          this.clearState();
        }
      },
    },
  },
  methods: {
    ...labMutations([
      'clearState',
      'setPatientData',
      'setShowDialog',
      'setActivityStatusLab',
      'setSnackbar',
      'clearSnackbar',
    ]),
    ...patientMutations(['clearStatePatientRegistration']),
    ...labActions([
      'resolvePostOrderExamination',
      'resolveUpdateLabRequest',
      'resolveConfirmLabRequest',
      'resolvePostLabResult',
      'resolvePostLabRegistration',
    ]),
    onState() {
      switch (this.confirmationState) {
        case 'register':
          switch (this.state) {
            case 'order':
              switch (this.stateRegistration) {
                case 0:
                  return {
                    method: this.handleContinueRegistration,
                    buttonText: 'Lanjut',
                    disabled: false,
                  };
                case 1:
                  return {
                    disabled: this.inputOrderCheckedItems?.length === 0,
                    method: this.handleContinueRegistration,
                    buttonText: 'Lanjut',
                  };
                case 2:
                  return {
                    method: this.handlePostRegistration,
                    buttonText: 'Order',
                    disabled: false,
                  };
              }
              break;
          }
          break;
        case 'order':
          switch (this.activityStatusLab) {
            case 'not_scheduled':
              switch (this.state) {
                case 'order':
                  switch (this.tabs) {
                    case 0: {
                      return {
                        disabled: this.inputOrderCheckedItems?.length === 0,
                        method: this.handleContinueOrderExamination,
                        buttonText: 'Lanjut',
                      };
                    }
                    case 1: {
                      return {
                        disabled: false,
                        method: this.handleContinueResultExamination,
                      };
                    }
                  }
                  break;
                case 'detail':
                  return {
                    disabled: false,
                    method: this.handlePostOrderExamination,
                    buttonText: 'Order',
                  };
                default:
                  break;
              }
              break;
            case 'scheduled':
              return {
                disabled: !this.isChanged,
                method: this.handleUpdateLabRequest,
                buttonText: 'Simpan Perubahan',
              };
          }
          break;
        case 'confirmation':
          switch (this.dataLab.isConfirmed) {
            case true: {
              return {
                disable: false,
                method: this.onCloseButtonClick,
                buttonText: 'Kembali',
              };
            }
            case false: {
              return {
                disabled: false,
                method: this.handleConfirmationLabRequest,
                buttonText: 'Konfirmasi',
              };
            }
          }
          break;
        case 'result':
          switch (this.dataLab.isInputed) {
            case false:
              return {
                disabled: this.inputResultIsChanged,
                method: this.handleSaveResultExamination,
                buttonText: 'Simpan',
              };
            case true:
              return {
                disabled: this.inputResultIsChanged,
                method: this.handleSaveResultExamination,
                buttonText: 'Simpan Perubahan',
              };
            default:
              break;
          }
      }
    },
    handleSetState(state) {
      this.state = state;
    },
    onCloseButtonClick() {
      this.clearState();
      this.setShowDialog(false);
      this.$emit('close-dialog');
    },
    formatDateTime(date) {
      return moment(date, 'DD/MM/YYYY', true).isValid()
        ? date
        : moment(date).format('DD/MM/YYYY');
    },
    handleContinueOrderExamination() {
      if (this.$refs.order.$refs.form.validate()) {
        this.handleSetState('detail');
      }
    },
    handleUpdateLabRequest() {
      if (this.$refs.update.$refs.form.validate()) {
        this.resolveUpdateLabRequest();
      }
    },
    handleContinueResultExamination() {},
    handleConfirmationLabRequest() {
      if (this.$refs.detailConfirmation.$refs.form.validate()) {
        this.resolveConfirmLabRequest();
      }
    },
    handleSaveResultExamination() {
      if (this.$refs.result.$refs.formResult.validate()) {
        this.resolvePostLabResult({ isInputed: this.dataLab.isInputed });
      }
    },
    handlePostOrderExamination() {
      this.resolvePostOrderExamination();
      this.clearStatePatientRegistration();
    },
    handleBackRegistration() {
      if (this.stateRegistration === 0) {
        this.$emit('close-dialog');
        this.clearState();
        this.clearStatePatientRegistration();
      } else {
        this.clearState();
        this.stateRegistration--;
      }
    },
    handleContinueRegistration() {
      if (this.stateRegistration === 1) {
        if (this.$refs.order.$refs.form.validate()) {
          this.stateRegistration++;
        }
        this.setActivityStatusLab('registration');
      } else {
        if (!this.$refs.patient.$refs.form.validate()) {
          this.$refs.patient.checkRules();
          return;
        }
        this.stateRegistration++;
      }
    },
    handlePostRegistration() {
      const patientData = {
        id_emr: this.patientRegistration('idEmr'),
        status: {
          isNew: this.patientRegistration('statusIsNew'),
          type: this.patientRegistration('statusType'),
        },
        meta: {
          name: this.patientRegistration('name'),
          nickname: this.patientRegistration('nickname'),
          phone_number: this.patientRegistration('phoneNumber'),
          gender: this.patientRegistration('gender'),
          birth: {
            date: this.patientRegistration('birthDate'),
            place: this.patientRegistration('birthPlace'),
          },
          address: {
            district: this.patientRegistration('addressDistrict')?.name || '',
            province: this.patientRegistration('addressProvince')?.name || '',
            sub_district:
              this.patientRegistration('addressSubDistrict')?.name || '',
            text: this.patientRegistration('addressText'),
            city: this.patientRegistration('addressCity')?.name || '',
          },
          identity: {
            type: this.patientRegistration('identityType'),
            number: this.patientRegistration('identityNumber'),
          },
          religion: this.patientRegistration('religion'),
          blood_type: this.patientRegistration('bloodType'),
          education: this.patientRegistration('education'),
          ethnicity: this.patientRegistration('ethnicity'),
          profession: this.patientRegistration('profession'),
          martial_status: this.patientRegistration('martialStatus'),
          father: this.patientRegistration('father'),
          mother: this.patientRegistration('mother'),
          disabilities: this.patientRegistration('disabilities'),
          communication_obstacles: this.patientRegistration(
            'communicationObstacles',
          ),
          language_mastering: this.patientRegistration('languageMastering'),
          assurance: {
            type: this.patientRegistration('assuranceType'),
            name: this.patientRegistration('assuranceName'),
            number: this.patientRegistration('assuranceNumber'),
            isProlanis: this.patientRegistration('assuranceIsProlanis'),
          },
        },
      };
      this.resolvePostLabRegistration(patientData);
    },
    dataPrint() {
      if (this.patientData === undefined) {
        const {
          staff,
          clinical_notes,
          recommendation,
          test_item,
          timestamps,
        } = this.labResult;
        const { guarantorType, patient, orderNumber } = this.dataLab;

        return {
          doctor: staff?.name || '-',
          guarantorType,
          timeOrder: `${moment(timestamps.created_at)
            .locale('id')
            .format('dddd, Do MMMM YYYY, h:mm:ss')}`,
          timeFinish: `${
            timestamps.finished_at
              ? moment(timestamps.finished_at)
                  .locale('id')
                  .format('dddd, Do MMMM YYYY, h:mm:ss')
              : '-'
          }`,

          name: patient?.name || '-',
          address: this.formatAddress(patient?.address),
          gender: patient?.gender === 'L' ? 'Laki - Laki' : 'Perempuan' || '-',
          birthDateAge: patient?.birthDate
            ? `${patient.birthDate} / ${patient.age} thn`
            : '-',
          noLabRM: orderNumber ? `${orderNumber} / ${patient?.rmNumber}` : '-',

          clinicalNotes: clinical_notes || '',
          recommendation: recommendation || '',
          testItem: test_item,
        };
      } else {
        const { meta, rmNumber } = this.patientData;
        const {
          code,
          timestamps,
          clinical_notes,
          recommendation,
          test_item,
          staff,
        } = this.labByReqId;

        return {
          doctor: staff?.name || '-',
          guarantorType: meta?.guarantorType || '-',
          timeOrder: `${moment(timestamps.created_at)
            .locale('id')
            .format('dddd, Do MMMM YYYY, h:mm:ss')}`,
          timeFinish: `${
            timestamps.finished_at
              ? moment(timestamps.finished_at)
                  .locale('id')
                  .format('dddd, Do MMMM YYYY, h:mm:ss')
              : '-'
          }`,

          name: meta?.name || '-',
          address: meta?.address || '-',
          gender: meta?.gender || '-',
          birthDateAge: meta?.birthDate
            ? `${meta.birthDate} / ${meta.age} thn`
            : '-',
          noLabRM: `${code} / ${rmNumber || '-'}`,

          clinicalNotes: clinical_notes || '',
          recommendation: recommendation || '',
          testItem: test_item,
        };
      }
    },
    printResult() {
      const data = this.dataPrint();
      const doc = this.printLabResult({
        doctor: data.doctor,
        guarantorType: data.guarantorType,
        timeOrder: data.timeOrder,
        timeFinish: data.timeFinish,
        name: data.name,
        address: data.address,
        gender: data.gender,
        birthDateAge: data.birthDateAge,
        noLabRM: data.noLabRM,
        testItem: data.testItem,
        clinicalNotes: data.clinicalNotes,
        recommendation: data.recommendation,
      });
      this.printDocument(doc, {
        docName: 'Hasil Laboratorium',
        rmNumber: data.noLabRM,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.heightFull {
  height: 90vh;
}

.heightNotFull {
  height: 80vh;
}
.card-layout {
  .title {
    font-weight: bold !important;
    padding-bottom: 0 !important;
  }
  &__header {
    position: sticky;
  }
  .container-layout {
    overflow: auto;
    width: 98%;
    &::-webkit-scrollbar {
      width: 3px;
      background-color: grey;
    }
    &::-webkit-scrollbar-thumb {
      width: 3px;
      background-color: black;
    }
  }
}
.grid-header {
  display: flex;
  gap: 1rem;
}
.detail-header {
  gap: 1rem;
  padding: 2rem 4rem 0 4rem;
}
.card-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card-footer {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 1rem 4rem !important;
}
</style>
