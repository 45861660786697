<template>
  <div class="main">
    <v-row align="center" class="py-3 px-8">
      <v-col cols="8">
        <h2 class="title">Pendaftaran Pasien Rawat Inap</h2>
        <h5 v-show="tabs == -0" class="subtitle caption">
          {{ waitingPatient }} Pasien waiting list hari ini
        </h5>
        <h5 v-show="tabs === 1" class="subtitle caption">
          {{ registPatient }} Pasien terdaftar hari ini
        </h5>
      </v-col>
      <v-col cols="1">
        <v-btn icon>
          <font-awesome-icon
            icon="sync-alt"
            class="btn-refresh"
            @click="refreshAndAutoUpdate()"
          />
        </v-btn>
      </v-col>
      <v-col cols="3">
        <v-text-field
          class="input"
          background-color="grey lighten-3"
          placeholder="Cari.."
          solo
          dense
          hide-details
          flat
          prepend-inner-icon="mdi-magnify"
          single-line
          v-model="search"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-tabs v-model="tabs" centered>
      <v-tab class="text-none">Waiting List</v-tab>
      <v-tab class="text-none">Terdaftar</v-tab>
    </v-tabs>
    <v-divider></v-divider>
    <v-tabs-items v-model="tabs">
      <v-tab-item>
        <v-row no-gutters>
          <v-data-table
            :headers="tableWait.headers"
            :items="tableWait.data"
            hide-default-footer
            :expanded="expanded"
            :height="height"
            item-key="noRM"
            single-expand
            @click:row="
              (item, slot) => {
                slot.expand(!slot.isExpanded);
              }
            "
            :loading="loadingWait"
            class="data-table"
            :items-per-page="itemsPerRow"
          >
            <template v-slot:no-data>
              <v-alert
                :value="!loadingWait && tableWait.data < 1"
                type="info"
                tile
              >
                Maaf tidak ada pasien di waiting list pendaftaran rawat inap
              </v-alert>
            </template>
            <template v-slot:expanded-item="{ item, headers }">
              <td :colspan="headers.length" class="expand px-0">
                <v-card flat>
                  <v-container class="pt-0">
                    <v-row no-gutters>
                      <v-col cols="1" style="max-width: 70px"></v-col>
                      <v-col
                        cols="3"
                        class="pl-3 text-left grey--text caption"
                        style="max-width: 340px"
                      >
                        <span
                          >Terdaftar {{ item.time }} <br />
                          {{ item.date }}</span
                        ></v-col
                      >
                      <v-col cols="3" class="text-left grey--text caption">
                        <span
                          >{{ item.gender }} {{ item.age }} Tahun</span
                        ></v-col
                      >
                      <v-col cols="2" class="text-left pl-9 grey--text caption"
                        >Pasien {{ item.isNew ? 'Baru' : 'Lama' }}</v-col
                      >
                      <v-col cols="2" class="text-left pl-9 grey--text caption">
                        <v-row
                          v-for="(doctor, index) in item.dpjp"
                          :key="'doctor-' + index"
                          no-gutters
                          ><span>{{ doctor }}</span></v-row
                        >
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>

                <v-card flat color="grey lighten-4" class="text-right">
                  <v-container class="py-1">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              class="hover mx-2"
                              v-bind="attrs"
                              v-on="on"
                              @click="selectItem(0, item)"
                            >
                              <v-icon>mdi-account</v-icon>
                            </v-btn>
                          </template>
                          <span>Data Sosial</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template #activator="{ on: tooltip }">
                            <v-btn
                              icon
                              class="hover mx-2"
                              v-on="{ ...tooltip }"
                              @click="selectItem(1, item)"
                            >
                              <v-icon>mdi-file-plus</v-icon>
                            </v-btn>
                          </template>
                          <span>Input Data</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template #activator="{ on: tooltip }">
                            <v-btn
                              icon
                              class="hover mx-2"
                              v-on="{ ...tooltip }"
                              @click="
                                selectItem(3, item);
                                printInpatientLetter(item);
                              "
                            >
                              <v-icon>mdi-file-document</v-icon>
                            </v-btn>
                          </template>
                          <span>Surat Perintah Rawat Inap</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              @click="deleteWaitingListPatient(item)"
                              icon
                              v-bind="attrs"
                              v-on="on"
                              class="mx-2"
                            >
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </template>

                          <span>Hapus</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </td>
            </template>
          </v-data-table>
          <v-footer color="white">
            <v-container fluid>
              <v-row align="center">
                <v-pagination
                  v-model="tableWait.pagination.page"
                  :length="waitingPages"
                  total-visible="5"
                  @input="showAllWaitingListPatient()"
                ></v-pagination>
              </v-row>
            </v-container>
          </v-footer>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row no-gutters>
          <v-data-table
            :headers="tableRegist.headers"
            :items="tableRegist.data"
            hide-default-footer
            :expanded="expanded"
            :height="height"
            item-key="idRegistration"
            single-expand
            @click:row="
              (item, slot) => {
                slot.expand(!slot.isExpanded);
              }
            "
            :loading="loadingRegist"
            class="data-table"
            :items-per-page="itemsPerRow"
          >
            <template v-slot:[`item.no`]="{ item }">
              <td>{{ tableRegist.data.indexOf(item) + 1 }}</td>
            </template>
            <template v-slot:[`item.guarantorType`]="{ item }">
              <td class="text-uppercase text-left">{{ item.guarantorType }}</td>
            </template>
            <template v-slot:no-data>
              <v-alert :value="true" type="info" tile>
                Maaf tidak ada pasien yang terdaftar
              </v-alert>
            </template>
            <template v-slot:expanded-item="{ item, headers }">
              <td :colspan="headers.length" class="expand px-0">
                <v-card flat>
                  <v-container class="pt-0">
                    <v-row no-gutters>
                      <v-col cols="1" style="max-width: 70px"></v-col>
                      <v-col
                        cols="4"
                        class="pl-3 text-left grey--text caption"
                        style="max-width: 340px"
                      >
                        <span
                          >Terdaftar {{ item.time }} <br />
                          {{ item.date }}</span
                        ></v-col
                      >
                      <v-col
                        cols="3"
                        class="text-left ml-3 pl-5 grey--text caption"
                      >
                        <span
                          >{{ item.gender ? 'Laki-laki' : 'Perempuan' }} <br />
                          {{ item.age }} Tahun</span
                        ></v-col
                      >
                      <v-col cols="2" class="text-left pl-9 grey--text caption"
                        >Pasien {{ item.isNew ? 'Baru' : 'Lama' }}</v-col
                      >
                    </v-row>
                  </v-container>
                </v-card>

                <v-card flat color="grey lighten-4" class="text-right">
                  <v-container class="py-1">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              class="hover mx-2"
                              v-bind="attrs"
                              v-on="on"
                              @click="selectItem(0, item)"
                            >
                              <v-icon>mdi-account</v-icon>
                            </v-btn>
                          </template>
                          <span>Data Sosial</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template #activator="{ on: tooltip }">
                            <v-btn
                              icon
                              class="hover mx-2"
                              v-on="{ ...tooltip }"
                              @click="
                                selectItem(3, item);
                                printInpatientLetter(item);
                              "
                            >
                              <v-icon>mdi-file-document</v-icon>
                            </v-btn>
                          </template>
                          <span>Surat Perintah Rawat Inap</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </td>
            </template>
          </v-data-table>
          <v-footer color="white">
            <v-container fluid>
              <v-row align="center">
                <v-pagination
                  v-model="tableRegist.pagination.page"
                  :length="inpatientPages"
                  :total-visible="5"
                  @input="showAllRegisteredPatient()"
                ></v-pagination>
              </v-row>
            </v-container>
          </v-footer>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
    <v-dialog
      v-model="dialog"
      :max-width="selectedForm === null ? 0 : widthDialog[selectedForm].width"
      :persistent="selectedForm === 1"
    >
      <SocialData
        v-if="selectedForm === 0 && dialog"
        @close-form="closeForm"
        :medical-record="selectedPatient.rmNumber"
      />
      <SocialDataPatients
        v-else-if="selectedForm === 1 && dialog"
        :selected-patient="selectedPatient"
        :patient-status="'rawat inap'"
        @close-form="closeForm"
        @back-dialog="dialog = false"
        @on-clicked="onClicked"
      />
      <BedInpatient
        v-else-if="selectedForm === 2"
        :selected-patient="selectedPatient"
        :medical-record="selectedPatient.rmNumber"
        @close-form="
          closeForm();
          refreshAndAutoUpdate();
        "
        @on-clicked="onClicked"
      />
    </v-dialog>
  </div>
</template>

<script>
import SocialData from '../SharedComponent/SocialData.vue';
import SocialDataPatients from './components/SocialDataPatients.vue';
import BedInpatient from './components/Inpatient/BedInpatient.vue';
import Letter from '@/mixin/Letter';
import 'jspdf-autotable';
import Constant from '@/const';
import moment from 'moment-timezone';
import jwtMixin from '@/mixin/jwtMixin';
import printMixin from '@/mixin/printMixin';
import formatMixin from '@/mixin/formatMixin';
import Swal from 'sweetalert2';
import alertMixin from '../../mixin/alertMixin';

const axios = require('axios');
const _ = require('lodash');
axios.defaults.headers.post['Content-Type'] = 'application/json';

export default {
  name: 'InpatientRegist',
  mixins: [jwtMixin, printMixin, alertMixin, formatMixin, Letter],
  components: {
    SocialDataPatients,
    SocialData,
    BedInpatient,
  },
  data() {
    return {
      clientData: this.$store.getters.userLoggedIn.clinic,
      dialog: false,
      dialogPrint: false,
      clicked: false,
      expanded: [],
      loadingRegist: false,
      loadingWait: false,
      selectedItem: null,
      tabs: 0,
      inpatientPages: 0,
      waitingPages: 0,
      registPatient: 0,
      waitingPatient: 0,
      selectedForm: null,

      input: {
        insurance_type: '',
        case_type: '',
        ward: 'Umum',
        dpjp: [''],
        diagnosis_in: [''],
        service_type: '',
      },
      numberLetter: '',
      tableWait: {
        headers: [
          { text: 'No', value: 'no', align: 'center' },
          { text: 'No. RM', value: 'noRM' },
          { text: 'No. Pendaftaran', value: 'noRegist' },
          { text: 'Nama', value: 'name' },
          { text: 'Tipe Penjamin', value: 'guarantorType' },
          { text: 'Unit', value: 'unit' },
          { text: 'Jenis Kasus', value: 'caseType' },
        ],
        data: [],
        pagination: {
          descending: false,
          page: 1,
          rowsPerPage: 5,
          sortBy: '',
        },
      },
      tableRegist: {
        headers: [
          { text: 'No', value: 'no', align: 'center' },
          { text: 'No. RM', value: 'noRM' },
          { text: 'No. Pendaftaran', value: 'noRegist' },
          { text: 'Nama', value: 'name' },
          { text: 'Tipe Penjamin', value: 'guarantorType' },
          { text: 'Nomor Bed', value: 'bed' },
          { text: 'Dokter DPJP', value: 'doctor' },
        ],
        data: [],
        pagination: {
          descending: false,
          page: 1,
          rowsPerPage: 7,
          sortBy: '',
        },
      },
      search: '',
      widthDialog: [
        { text: 'SocialData', width: 1200 },
        { text: 'SocialDataPatients', width: 1200 },
        { text: 'BedInpatient', width: 1200 },
        { text: 'PaymentType', width: 250 },
      ],
    };
  },
  watch: {
    search() {
      this.searchData(this);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshAndAutoUpdate();
      // this.showAllRegisteredPatientLetter();
    });
  },
  computed: {
    selectedPatient() {
      if (this.$store.getters.selectedPatient !== null) {
        return this.$store.getters.selectedPatient;
      }
      return null;
    },
    letterData() {
      return Constant.client.find(
        item =>
          item.name.toLowerCase() ===
          this.$store.getters.userLoggedIn.clinic.name.toLowerCase(),
      );
    },
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
    height() {
      let height = 0;
      switch (this.$vuetify.breakpoint.name) {
        case 'md':
          height = '55vh';
          break;
        case 'lg':
          height = '59vh';
          break;
        case 'xl':
          height = '67vh';
          break;
      }
      return height;
    },
  },
  methods: {
    searchData: _.debounce(v => {
      if (v.tabs) {
        v.showAllRegisteredPatient();
      } else {
        v.showAllWaitingListPatient();
      }
    }, 600),
    closeForm() {
      this.dialog = false;
      this.showAllWaitingListPatient();
    },
    onClicked(val) {
      if (val === 3) {
        this.selectedForm = val;
        this.dialog = false;
      } else {
        this.selectedForm = val;
        this.dialog = true;
      }
    },
    deleteWaitingListPatient(data) {
      Swal.fire({
        title: `Apakah anda yakin ingin menghapus pasien waiting list rawat inap atas nama ${data.name} ?`,
        text: ``,
        icon: 'question',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batal',
      }).then(result => {
        if (result.isConfirmed) {
          axios
            .put(`${Constant.apiUrl}/inpatient/${data.id}`)
            .then(response => {
              if (response) {
                Swal.fire('Berhasil menghapus pasien', '', 'success').then(
                  result => {
                    if (result.isConfirmed) {
                      this.refreshAndAutoUpdate();
                    }
                  },
                );
              }
            })
            .catch(error => this.showErrorAxios(error));
        }
      });
    },
    getRegisteredCount() {
      axios
        .get(Constant.apiUrl.concat('/inpatient/registered/count'))
        .then(res => {
          const { data } = res.data;
          this.waitingPatient = data.waitingListPatient;
          this.registPatient = data.registeredPatient;
        });
    },
    generateNumber() {
      axios
        .get(
          Constant.apiUrl.concat(`/letter/control-inpatient-letter/generate/`),
        )
        .then(response => {
          this.numberLetter = response.data.data;
        });
    },
    printInpatientLetter(data) {
      axios
        .get(
          Constant.apiUrl.concat(
            `/letter/control-inpatient-letter/registration/${this.selectedPatient.id_registration}`,
          ),
        )
        .then(async response => {
          const inpatient = response.data.data;
          const sip = await axios.get(
            Constant.apiUrl.concat(`/master/staff/find/${data.id_doctor}`),
          );
          let doctorSip = 'SIP : ' + sip.data.data.detail.sip;
          this.input = {
            insurance_type: _.get(inpatient, 'detail.insurance_type', ''),
            case_type: _.get(inpatient, 'detail.case_type', ''),
            dpjp: _.get(inpatient, 'detail.dpjp', ['']).filter(x => x !== null),
            sip: sip.data.data.detail.sip,
            service_type: _.get(inpatient, 'detail.service_type', ''),
            diagnosis_in: _.get(inpatient, 'detail.diagnosis_in', ['']).filter(
              x => x !== null,
            ),
          };
          this.numberLetter = inpatient.letter_number;
          const doc = this.generateInpatientLetter(
            {
              ...this.selectedPatient,
              meta: {
                ...this.selectedPatient.meta,
                address: this.formatAddress(this.selectedPatient.meta.address),
              },
              numberLetter: this.numberLetter,
              doctorSip: doctorSip,
              timestamps: new Date(),
            },
            this.input,
          );
          this.printDocument(doc, {
            docName: 'Surat Perintah Rawat Inap',
            rmNumber: this.selectedPatient.rmNumber,
          });
        })
        .catch(error => {
          if (error?.response?.status === 404) {
            this.generateNumber();
          }
        });
    },
    showAllWaitingListPatient() {
      this.loadingWait = true;
      this.runApiCalls(() => {
        axios
          .get(
            `${Constant.apiUrl}/letter/control-inpatient-letter/all?page=${
              this.tableWait.pagination.page
            }&itemCount=${this.itemsPerRow}&search=${this.search || ''}`,
          )
          .then(response => {
            const { data } = response.data;
            const { length: dataLength } = response.data;
            this.waitingPages = Math.ceil(dataLength / this.itemsPerRow);

            this.tableWait.data = data.map((patient, index) => {
              return {
                idRegistration: patient.id_registration._id,
                no:
                  (this.tableWait.pagination.page - 1) * this.itemsPerRow +
                  index +
                  1,
                //registration
                id_doctor: patient.id_registration.id_staff,
                noRegist: patient.id_registration.registration_number,
                polyId: patient.id_registration.id_unit,
                unit: patient.id_registration.id_unit.name,
                doctor: patient.detail.timestamps.created_by,
                time: moment(patient.detail.timestamps.created_at).format(
                  'hh:mm [WIB]',
                ),
                date: moment(patient.detail.timestamps.created_at).format(
                  'DD/MM/YYYY',
                ),
                //medical record
                idPatient: patient.id_emr._id,
                noRM: patient.id_emr.medical_record_number,
                name: patient.id_emr.social[0].name,
                birthDate: moment(patient.id_emr.social[0].birth.date)
                  .locale('id')
                  .format('DD/MM/YYYY'),
                birthPlace: patient.id_emr.social[0].birth.place,
                address: patient.id_emr.social[0].address,
                age: patient.id_emr.social[0].age,
                id: patient._id,
                gender:
                  patient.id_emr.social[0].gender === true
                    ? 'Laki-laki'
                    : 'Perempuan',
                //assurance
                guarantorType: _.startCase(patient.detail.insurance_type),
                dpjp: patient.detail.dpjp,
                caseType: patient.detail.case_type,
                ward: patient.detail.ward,
                serviceType: patient.detail.service_type,
                isNew: patient.id_registration.status.isNew,
              };
            });
            this.loadingWait = false;
          })
          .catch(() => {
            this.tableWait.data = [];
            this.loadingWait = false;
          });
      });
      this.getRegisteredCount();
    },
    showAllRegisteredPatient() {
      this.loadingRegist = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/inpatient?page=${this.tableRegist.pagination.page}&itemCount=${
              this.itemsPerRow
            }&search=${this.search || ''}`,
          ),
        )
        .then(response => {
          const { data, length } = response.data;
          this.inpatientPages = Math.ceil(length / this.itemsPerRow);

          this.tableRegist.data = data.map(arr => {
            return {
              idRegistration: arr.id_registration,
              id_doctor: arr.doctor,
              noRegist: _.get(arr, 'registration[0].registration_number', ''),
              idPatient: arr.id_emr,
              name: _.get(arr, 'social.name', ''),
              address: _.get(arr, 'social.address', ''),
              gender: _.get(arr, 'social.gender', ''),
              birthDate: _.get(arr, 'social.birth.date', ''),
              guarantorType: _.get(arr, 'registration[0].assurance.type', ''),
              age: _.get(arr, 'social.age', ''),
              isNew: _.get(arr, 'registration[0].status.isNew'),
              date: moment(
                _.get(arr, 'registration[0].timestamps.created_at', null),
              ).format('DD/MM/YYYY'),
              time: moment(
                _.get(arr, 'registration[0].timestamps.created_at', null),
              ).format('hh:mm [WIB]'),
              bed: _.get(arr, 'bed_number', ''),
              noRM: _.get(arr, 'medical_record_number', ''),
              doctor: _.get(arr, 'doctor_dpjp', ''),
            };
          });
          this.loadingRegist = false;
        })
        .catch(() => {
          this.tableRegist.data = [];
          this.loadingRegist = false;
        });
    },
    selectItem(val, patient) {
      const patientData = {
        id_emr: patient.idPatient,
        id_registration: patient.idRegistration,
        rmNumber: patient.noRM,
        noQueue: patient.noQueue,
        caseType: patient.caseType,
        status: 'old',
        meta: {
          name: patient.name,
          birthDate: patient.birthDate,
          birthPlace: patient.birthPlace,
          gender: patient.gender,
          age: patient.age,
          address: patient.address,
          identityType: patient.identityType,
          identityNumber: patient.identityNumber,
          doctor: patient.doctor,
          polyId: patient.polyId,
          profession: patient.profession,
          guarantorType: patient.guarantorType,
        },
      };
      this.$store.commit('changeSelectedPatient', patientData);
      this.onClicked(val);
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.showAllWaitingListPatient();
      this.showAllRegisteredPatient();
      this.interval = setInterval(this.showAllWaitingListPatient, 60000);
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.main {
  font-family: 'Roboto';
  .title {
    text-align: left;
    font-weight: bold !important;
    padding-bottom: 0 !important;
  }
  .subtitle {
    text-align: left;
    color: #adb5bd !important;
    font-weight: 500;
  }
  .data-table {
    width: 100%;
  }

  .btn-refresh {
    cursor: pointer;
  }

  ::v-deep .v-dialog--active.dialogPrint {
    width: 450px;
    box-shadow: none !important;
  }

  #print,
  #printQueue {
    display: none;
  }

  ::v-deep {
    .theme--light.v-data-table
      > .v-data-table__wrapper
      > table
      > tbody
      > tr:not(:last-child)
      > td:not(.v-data-table__mobile-row),
    .theme--light.v-data-table
      > .v-data-table__wrapper
      > table
      > tbody
      > tr:not(:last-child)
      > th:not(.v-data-table__mobile-row) {
      border: none;
    }
  }
}
::v-deep .v-dialog:not(.v-dialog--fullscreen) {
  max-height: 95%;
}
</style>
