<template>
  <v-container fluid>
    <v-row>
      <v-col cols="10" offset="1">
        <label for="Cari Rekam Medis" class="mb-5 font-weight-bold"
          >Cari Rekam Medis</label
        >
        <!-- v-on:keyup.enter="searchByKey(searchInput)" -->
        <v-autocomplete
          v-model="searchedItem"
          @click.native="clearData"
          ref="emrBar"
          open-on-clear
          outlined
          :filter="partFilter"
          :loading="isLoading"
          :items="resource.databaseItem"
          :search-input.sync="searchInput"
          cache-items
          prepend-inner-icon="mdi-magnify"
          class="mx-4 mt-5"
          flat
          return-object
          item-text="name"
          item-value="no_rm"
          filled
          hide-selected
          hide-details
          label="Masukkan No RM. atau Nama Lengkap"
        >
          <template #item="{ item }">
            <router-link :to="`/emr/patient/${item.id}`">
              <v-card
                @click="updateHistory(item)"
                flat
                class="mx-auto text-left"
                min-width="998"
              >
                <v-list class="pa-0">
                  <v-list-item-group>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          class="font-weight-bold primary--text"
                          >{{ item.name }} ({{ item.age }} tahun)
                          <v-icon color="pink" v-if="!item.gender"
                            >mdi-gender-female</v-icon
                          >
                          <v-icon color="primary" v-else
                            >mdi-gender-male</v-icon
                          >
                        </v-list-item-title>

                        <v-list-item-title class="caption my-2">{{
                          `${item.no_rm} ${
                            item.old_no_rm ? '| ' + item.old_no_rm : ''
                          }`
                        }}</v-list-item-title>
                        <v-list-item-title class="caption">{{
                          `Last Visit: ${item.last_visit}`
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </router-link>
          </template>
          <template v-slot:progress>
            <v-progress-linear
              absolute
              color="orange lighten-1"
              height="8"
              indeterminate
            ></v-progress-linear>
          </template>
          <template v-slot:no-data> Data tidak ditemukan </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="8" offset="2">
        <h2 class="mt-6 title text-center">Riwayat Pencarian</h2>
        <div
          class="row-history-search"
          v-if="resource.searchHistory.length !== 0"
        >
          <template v-for="(item, index) in resource.searchHistory">
            <router-link
              :key="'col-router-history-search' + index"
              :to="`/emr/patient/${resource.searchHistory[index].id}`"
            >
              <v-card class="mt-4 history" outlined flat>
                <v-card-title
                  class="overline history__rm"
                  :key="'label-name-age-history-search' + index"
                  >{{
                    showNameAge(
                      resource.searchHistory[index].name,
                      resource.searchHistory[index].age,
                    )
                  }}</v-card-title
                >

                <v-card-text class="history__rm">
                  <v-row align="center" class="mx-0">
                    <div
                      class="subtitle-2"
                      :key="'label-rm-history-search' + index"
                    >
                      {{ resource.searchHistory[index].no_rm }}
                    </div>
                  </v-row>

                  <div
                    class="mt-4 subtitle-2 text-left history__gender"
                    :key="'label-name-age-history-search' + index"
                  >
                    {{
                      resource.searchHistory[index].gender
                        ? `Laki-Laki`
                        : `Perempuan`
                    }}
                  </div>
                </v-card-text>
              </v-card>
            </router-link>
          </template>
        </div>
        <v-btn
          v-if="resource.searchHistory.length !== 0"
          text
          class="primary--text text-right"
          @click="deleteHistory()"
          >Hapus Riwayat Pencarian</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Constant from '@/const';
const moment = require('moment-timezone');
const _ = require('lodash');
const axios = require('axios');

export default {
  data() {
    return {
      searchedItem: null,
      searchInput: null,
      isLoading: false,
      retrievedItem: [],
      resource: {
        databaseItem: [],
        searchHistory: [],
      },
    };
  },
  watch: {
    searchInput() {
      this.searchData(this);
    },
  },
  mounted() {
    if (localStorage.searchHistory) {
      this.resource.searchHistory = JSON.parse(
        localStorage.getItem('searchHistory'),
      );
    } else {
      localStorage.setItem('searchHistory', JSON.stringify([]));
      this.resource.searchHistory = [];
    }
  },
  methods: {
    clearData() {
      this.resource.databaseItem.length = 0;
    },
    searchByKey(key) {
      this.getQuerySearchKey(key);
    },
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.getQuerySearchKey();
    }, 650),
    getQuerySearchKey() {
      this.isLoading = true;
      const keyword = this.searchInput;

      axios
        .get(
          `${Constant.apiUrl}/patient/emr/all?page=1&itemCount=&search=${keyword}&sort=`,
        )
        .then(response => {
          const patients = response.data.data;
          this.resource.databaseItem = patients.map(item => {
            return {
              id: item._id,
              name: item.social.name,
              gender: item.social.gender,
              age: item.social.age,
              last_visit: moment(item.last_visit)
                .locale('id')
                .format('DD MMMM YYYY'),
              no_rm: item.medical_record_number,
              old_no_rm: item?.old_medical_record_number,
            };
          });
        })
        .catch(() => {
          this.resource.databaseItem = [];
        })

        .finally(() => {
          this.isLoading = false;
        });
    },
    updateHistory(item) {
      this.resource.searchHistory = JSON.parse(
        localStorage.getItem('searchHistory'),
      );
      this.resource.searchHistory.unshift(item);
      localStorage.setItem(
        'searchHistory',
        JSON.stringify(this.resource.searchHistory),
      );
    },
    deleteHistory() {
      this.resource.searchHistory = [];
      localStorage.setItem('searchHistory', JSON.stringify([]));
    },
    partFilter(item, queryText) {
      return (
        item.name.toLowerCase().includes(queryText.toLowerCase()) ||
        item.no_rm.includes(queryText) ||
        item.old_no_rm?.toLowerCase().includes(queryText)
      );
    },
    showNameAge(name, age) {
      const str = `${name} (${age} tahun)`;
      return str;
    },
  },
};
</script>

<style lang="scss" scoped>
.history:hover > .history__rm {
  background-color: #2196f3;
  color: #fff;
}
// .history__rm:hover {
//   color: #fff;
// }
// .history__gender:hover {
//   color: #fff;
// }

::v-deep {
  .v-list-item {
    padding: 0;
  }
  .v-list-item__content {
    padding: 12px 16px !important;
  }
}
a {
  text-decoration: none;
}
</style>
