<template>
  <v-container fluid class="main-container py-5 px-10">
    <v-layout row wrap justify-space-between>
      <!-- flex dan layout untuk grafik -->
      <v-flex xs9 class="pa-3">
        <v-layout column class="ma-0 pa-3 chart-style" v-show="activeTab === 0">
          <v-flex class="text-left px-3">
            <h4>
              Grafik Kepatuhan Peresepan Dokter Nasional
            </h4>
            <label class="font-weight-medium caption grey--text">
              Rata-rata angka kepatuhan peresepan Dokter terhadap Formularium
              Nasional tahun ini
            </label>
          </v-flex>
          <v-flex class="chart-container">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
              v-if="isLoading"
            ></v-progress-circular>
            <apexcharts
              ref="chartNational"
              type="line"
              height="260"
              :options="chartNational.chartOptions"
              :series="chartNational.series"
              v-else
            ></apexcharts>
          </v-flex>
        </v-layout>
        <v-layout column class="ma-0 pa-3 chart-style" v-show="activeTab === 1">
          <v-flex class="text-left px-3">
            <h4>Grafik Kepatuhan Peresepan Dokter Nasional</h4>
            <label class="font-weight-medium caption grey--text">
              Rata-rata persentase keseluruhan dokter per unit
            </label>
          </v-flex>
          <v-flex class="chart-container">
            <apexcharts
              ref="chartAllDoctor"
              type="bar"
              height="260"
              :options="chartAllDoctor.chartOptions"
              :series="chartAllDoctor.series"
            ></apexcharts>
          </v-flex>
        </v-layout>
        <v-layout column class="ma-0 pa-3 chart-style" v-show="activeTab === 2">
          <v-flex class="text-left px-3">
            <h4>Grafik Kepatuhan Peresepan Dokter Nasional</h4>
            <label class="font-weight-medium caption grey--text">
              Rata-rata angka kepatuhan peresepan Dokter terhadap Formularium
              Nasional tahun ini
            </label>
          </v-flex>
          <v-flex class="chart-container">
            <apexcharts
              ref="chartDoctor"
              type="line"
              height="260"
              :options="chartDoctor.chartOptions"
              :series="chartDoctor.series"
            ></apexcharts>
          </v-flex>
        </v-layout>
      </v-flex>
      <!-- flex dan layout untuk button home, cek dan keterangan -->
      <v-flex xs3 class="pa-3">
        <v-layout column>
          <v-flex class="text-left">
            <v-btn class="ml-0 mr-0 text-none" color="primary" depressed>
              <router-link :to="routeBack" active>
                Back to Home
              </router-link>
            </v-btn>
          </v-flex>
          <v-flex>
            <v-layout column class="py-5" v-show="activeTab === 0">
              <v-flex class="text-left font-weight-bold py-5"
                >Keterangan Warna</v-flex
              >
              <template v-for="(item, index) in chartNational.series">
                <v-flex pb-2 align-self-start :key="item.name + '-' + index">
                  <v-btn
                    depressed
                    :color="chartNational.chartOptions.colors[index]"
                    :ripple="false"
                    class="white--text ml-0 mr-0 text-none body-2 btn-detail"
                    @click="showSeries('national', item.name, index)"
                  >
                    <s v-if="item.isActive">{{ item.name }}</s>
                    <template v-else>{{ item.name }}</template>
                  </v-btn>
                </v-flex>
              </template>
            </v-layout>
          </v-flex>
          <v-flex class="text-left">
            <v-btn
              v-show="activeTab === 0"
              class="ml-0 mr-0 text-none"
              color="primary"
              depressed
              @click="activeTab = 1"
            >
              Cek Laporan per Unit
            </v-btn>
          </v-flex>
          <v-flex>
            <v-layout column class="py-5" v-show="activeTab === 1">
              <v-flex class="text-left font-weight-bold py-5"
                >Keterangan Warna</v-flex
              >
              <template v-for="(item, index) in chartAllDoctor.series">
                <v-flex pb-2 align-self-start :key="item.name + '-' + index">
                  <v-btn
                    depressed
                    :color="chartAllDoctor.chartOptions.colors[index]"
                    :ripple="false"
                    class="white--text ml-0 mr-0 text-none body-2 btn-detail"
                    @click="showSeries('allDoctor', item.name, index)"
                  >
                    <s v-if="item.isActive">{{ item.name }}</s>
                    <template v-else>{{ item.name }}</template>
                  </v-btn>
                </v-flex>
              </template>
            </v-layout>
          </v-flex>
          <v-flex class="text-left">
            <v-btn
              v-show="activeTab === 1"
              class="ml-0 mr-0 text-none"
              color="primary"
              depressed
              @click="activeTab = 2"
            >
              Pencarian Nama Dokter
            </v-btn>
          </v-flex>
          <v-flex>
            <v-layout column class="py-5" v-show="activeTab === 2">
              <v-flex class="text-left font-weight-bold py-5"
                >Keterangan Warna</v-flex
              >
              <template v-for="(item, index) in chartDoctor.series">
                <v-flex pb-2 align-self-start :key="item.name + '-' + index">
                  <v-btn
                    depressed
                    :color="chartDoctor.chartOptions.colors[index]"
                    :ripple="false"
                    class="white--text ml-0 mr-0 text-none body-2 btn-detail"
                    @click="showSeries('doctor', item.name, index)"
                  >
                    <s v-if="item.isActive">{{ item.name }}</s>
                    <template v-else>{{ item.name }}</template>
                  </v-btn>
                </v-flex>
              </template>
            </v-layout>
          </v-flex>
          <v-flex class="text-left">
            <v-btn
              v-show="activeTab === 2"
              class="ml-0 mr-0 text-none"
              color="primary"
              depressed
              @click="activeTab = 0"
              >Laporan keseluruhan</v-btn
            >
          </v-flex>
        </v-layout>
      </v-flex>
      <!-- flex dan layout untuk rekap -->
      <v-flex xs4 class="pl-5">
        <v-form v-show="activeTab === 0">
          <v-layout wrap class="pt-3">
            <v-flex xs12 class="font-weight-bold text-left pb-2"
              >Rekap per Bulan</v-flex
            >
            <v-flex xs6 class="pr-2">
              <v-select
                :items="months"
                item-text="text"
                placeholder="Bulan"
                height="25px"
                single-line
                dense
                v-model="filter.month"
              ></v-select>
            </v-flex>
            <v-flex xs6 class="pl-2">
              <v-autocomplete
                :items="yearRange"
                placeholder="Tahun"
                height="25px"
                single-line
                dense
                v-model="filter.year"
              ></v-autocomplete>
            </v-flex>
            <v-flex xs6 class="pr-2">
              <v-btn
                block
                outlined
                tile
                class="text-none"
                color="primary"
                @click="getAllData()"
              >
                Cari
              </v-btn>
            </v-flex>
            <v-flex xs6 class="pl-2">
              <v-btn block outlined tile class="text-none" color="primary">
                Unduh Laporan
              </v-btn>
            </v-flex>
          </v-layout>
        </v-form>
        <v-form v-show="activeTab === 1">
          <v-layout wrap class="pt-3 body-2 text-left">
            <v-flex xs12 class="font-weight-bold text-left pb-2"
              >Persentase Keseluruhan Dokter per Unit</v-flex
            >
            <v-flex xs4 class="pr-2 pt-1 font-weight-medium">
              <label>Nama Bulan</label>
            </v-flex>
            <v-flex xs4 class="pl-2">
              <v-autocomplete
                :items="months"
                placeholder="Bulan"
                height="25px"
                single-line
                dense
                v-model="filter.month"
              ></v-autocomplete>
            </v-flex>
            <v-flex xs4 class="pl-2">
              <v-autocomplete
                :items="yearRange"
                placeholder="Tahun"
                height="25px"
                single-line
                dense
                v-model="filter.year"
              ></v-autocomplete>
            </v-flex>
            <v-flex xs4 class="pr-2 pt-1 font-weight-medium">
              <label>Spesialisasi</label>
            </v-flex>
            <v-flex xs8 class="pl-2">
              <v-select
                placeholder="Pilih Spesialisasi"
                height="25px"
                single-line
                dense
                :items="resource.specialization"
                item-text="name"
                v-model="filter.specialize"
              ></v-select>
            </v-flex>
            <v-flex xs4 class="pr-2 fpt-1 font-weight-medium">
              <label>Unit</label>
            </v-flex>
            <v-flex xs8 class="pl-2">
              <v-select
                placeholder="Pilih Unit"
                height="25px"
                single-line
                :items="resource.unit"
                item-text="name"
                item-value="id"
                dense
                v-model="filter.unit"
              ></v-select>
            </v-flex>
            <v-flex xs6 class="pr-2">
              <v-btn
                block
                outlined
                tile
                class="text-none"
                color="primary"
                @click="getByUnitData()"
              >
                Cari
              </v-btn>
            </v-flex>
            <v-flex xs6 class="pl-2">
              <v-btn block outlined tile class="text-none" color="primary">
                Unduh Laporan
              </v-btn>
            </v-flex>
          </v-layout>
        </v-form>
        <v-form v-show="activeTab === 2">
          <v-layout wrap class="pt-3 body-2 text-left">
            <v-flex xs12 class="font-weight-bold text-left pb-2"
              >Pencarian Berdasarkan Nama Dokter</v-flex
            >
            <v-flex xs4 class="pr-2 pt-1 font-weight-medium">
              <label>Nama Dokter</label>
            </v-flex>
            <v-flex xs8 class="pl-2">
              <v-autocomplete
                placeholder="Pilih Dokter"
                height="25px"
                single-line
                dense
                :items="resource.doctors"
                item-text="doctorName"
                item-value="doctorId"
                v-model="filter.doctor"
              ></v-autocomplete>
            </v-flex>
            <v-flex xs4 class="pr-2 pt-1 font-weight-medium">
              <label>Range Bulan</label>
            </v-flex>
            <v-flex xs4 class="pl-2">
              <v-select
                :items="months"
                placeholder="Bulan"
                height="25px"
                single-line
                dense
                v-model="filter.month"
              ></v-select>
            </v-flex>
            <v-flex xs4 class="pl-2">
              <v-autocomplete
                :items="yearRange"
                placeholder="Tahun"
                height="25px"
                single-line
                dense
                v-model="filter.year"
              ></v-autocomplete>
            </v-flex>
            <v-flex xs4>
              <label></label>
            </v-flex>
            <v-flex xs4 class="pl-2">
              <v-select
                :items="months"
                placeholder="Bulan"
                height="25px"
                single-line
                dense
                v-model="filter.endMonth"
              ></v-select>
            </v-flex>
            <v-flex xs4 class="pl-2">
              <v-autocomplete
                :items="yearRange"
                placeholder="Tahun"
                height="25px"
                single-line
                dense
                v-model="filter.endYear"
              ></v-autocomplete>
            </v-flex>
            <v-flex xs6 class="pr-2">
              <v-btn
                block
                outlined
                tile
                class="text-none"
                color="primary"
                @click="getByDoctorData()"
              >
                Cari
              </v-btn>
            </v-flex>
            <v-flex xs6 class="pl-2">
              <v-btn block outlined tile class="text-none" color="primary">
                Unduh Laporan
              </v-btn>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
      <!-- flex dan layout untuk tabel -->
      <v-flex xs8 class="pl-4 pt-4" v-show="activeTab === 0">
        <v-layout row wrap class="ma-0 pa-0">
          <v-flex xs12 class="data-grid sum-data-grid head">
            <label class="label-item-table head left">Nama Dokter</label>
            <label class="label-item-table head">Pelayanan Rawat Inap</label>
            <label class="label-item-table head">Pelayanan Rawat Jalan</label>
            <label class="label-item-table head">Pelayanan IGD</label>
          </v-flex>
          <v-flex xs12 class="data-grid sum-data-grid scrollable mt-2">
            <template v-for="(doc, index) in table.recapDoctor.detail">
              <label class="label-item-table left" :key="'name-' + index">{{
                doc.doctor
              }}</label>
              <label class="label-item-table" :key="'inpatient-' + index">{{
                doc.inpatient
              }}</label>
              <label class="label-item-table" :key="'outpatient-' + index">{{
                doc.outpatient
              }}</label>
              <label class="label-item-table" :key="'igd-' + index">{{
                doc.igd
              }}</label>
            </template>
          </v-flex>
          <v-flex xs12 class="data-grid sum-data-grid mt-2">
            <label class="label-item-table font-weight-medium left"
              >Persen rata rata per unit</label
            >
            <label class="label-item-table "
              >{{
                table.recapDoctor.average === null
                  ? ''
                  : table.recapDoctor.average.inpatient
              }}%</label
            >
            <label class="label-item-table "
              >{{
                table.recapDoctor.average === null
                  ? ''
                  : table.recapDoctor.average.outpatient
              }}%</label
            >
            <label class="label-item-table "
              >{{
                table.recapDoctor.average === null
                  ? ''
                  : table.recapDoctor.average.igd
              }}%</label
            >
            <label class="label-item-table font-weight-medium left"
              >Persen rata rata keseluruhan unit dan dokter</label
            >
            <label class="label-item-table "
              >{{ table.recapDoctor.totalPercentage }}%</label
            >
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs8 class="pl-4 pt-4" v-show="activeTab === 1">
        <v-layout row wrap class="ma-0 pa-0">
          <v-flex class="data-grid doctor-table-grid head">
            <label class="label-item-table head left py-3">Nama Dokter</label>
            <label class="label-item-table head"
              >Jumlah Obat Fornas pada Resep</label
            >
            <label class="label-item-table head">Jumlah Obat Keseluruhan</label>
            <label class="label-item-table head py-3">Persentase</label>
          </v-flex>
          <v-flex class="data-grid doctor-table-grid scrollable mt-3">
            <template v-for="(doctor, index) in table.doctor.detail">
              <label
                class="label-item-table left"
                :key="'doctorName-' + index"
                >{{ doctor.doctor }}</label
              >
              <label class="label-item-table" :key="'doctorFornas-' + index">{{
                doctor.fornas
              }}</label>
              <label class="label-item-table" :key="'doctorTotal-' + index">{{
                doctor.nonFornas + doctor.fornas
              }}</label>
              <label class="label-item-table" :key="'doctorPercent-' + index"
                >{{ doctor.percentage }}%</label
              >
            </template>
          </v-flex>
          <v-flex class="data-grid doctor-table-grid mt-2">
            <label class="label-item-table bold-text left"
              >Jumlah Rata-rata</label
            >
            <label class="label-item-table ">{{
              table.doctor.average === null ? '' : table.doctor.average.fornas
            }}</label>
            <label class="label-item-table ">{{
              table.doctor.average === null
                ? ''
                : table.doctor.average.nonFornas + table.doctor.average.fornas
            }}</label>
            <label class="label-item-table "
              >{{
                table.doctor.average === null
                  ? ''
                  : table.doctor.average.percentage
              }}%</label
            >
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs8 class="pl-4 pt-4" v-show="activeTab === 2">
        <v-layout row wrap class="ma-0 pa-0">
          <v-flex xs12 class="data-grid doctor-table-grid head">
            <label class="label-item-table head left py-3">Unit</label>
            <label class="label-item-table head"
              >Jumlah Obat Fornas pada Resep</label
            >
            <label class="label-item-table head">Jumlah Obat Keseluruhan</label>
            <label class="label-item-table head py-3">Persentase</label>
          </v-flex>
          <v-flex xs12 class="data-grid doctor-table-grid scrollable mt-3">
            <template v-for="(unit, index) in table.unit.detail">
              <label
                class="label-item-table left text-capitalize"
                :key="'unitName-' + index"
                >{{ unit.name }}</label
              >
              <label class="label-item-table" :key="'unitFornas-' + index">{{
                unit.fornas
              }}</label>
              <label class="label-item-table" :key="'unitAllDrugs-' + index">{{
                unit.nonFornas + unit.fornas
              }}</label>
              <label class="label-item-table" :key="'unitPercent' + index"
                >{{ unit.percentage }}%</label
              >
            </template>
          </v-flex>
          <v-flex xs12 class="data-grid doctor-table-grid mt-2">
            <label class="label-item-table bold-text left">
              Jumlah Rata-rata
            </label>
            <label class="label-item-table ">{{
              table.unit.average === null ? '' : table.unit.average.fornas
            }}</label>
            <label class="label-item-table ">{{
              table.unit.average === null
                ? ''
                : table.unit.average.nonFornas + table.unit.average.fornas
            }}</label>
            <label class="label-item-table "
              >{{
                table.unit.average === null
                  ? ''
                  : table.unit.average.percentage
              }}%</label
            >
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import Constant from '@/const';
import AlertMixin from '@/mixin/alertMixin';

const axios = require('axios');

export default {
  name: 'DoctorCompliance',
  mixins: [AlertMixin],
  components: {
    apexcharts: VueApexCharts,
  },
  data() {
    return {
      activeTab: 0,
      months: [
        { text: 'Januari', value: 1 },
        { text: 'Februari', value: 2 },
        { text: 'Maret', value: 3 },
        { text: 'April', value: 4 },
        { text: 'Mei', value: 5 },
        { text: 'Juni', value: 6 },
        { text: 'Juli', value: 7 },
        { text: 'Agustus', value: 8 },
        { text: 'September', value: 9 },
        { text: 'Oktober', value: 10 },
        { text: 'November', value: 11 },
        { text: 'Desember', value: 12 },
      ],
      chartNational: {
        chartOptions: {
          chart: {
            id: 'chartNational',
            width: '730px',
            height: 300,
          },
          xaxis: {
            categories: [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'Mei',
              'Jun',
              'Jul',
              'Ags',
              'Sep',
              'Okt',
              'Nov',
              'Des',
            ],
          },
          stroke: {
            curve: 'smooth',
            width: 2,
          },
          dataLabels: {
            enabled: false,
          },
          colors: ['#80C2FF', '#F2994A', '#FF8080'],
          legend: {
            show: false,
          },
        },
        series: [
          {
            name: 'Poli Mata',
            data: [],
            isActive: false,
          },
          {
            name: 'Poli KIA',
            data: [],
            isActive: false,
          },
          {
            name: 'Poli Gigi',
            data: [],
            isActive: false,
          },
        ],
      },
      chartDoctor: {
        chartOptions: {
          chart: {
            id: 'doctor-compliance',
            width: '730px',
            height: 300,
          },
          xaxis: {
            categories: [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'Mei',
              'Jun',
              'Jul',
              'Ags',
              'Sep',
              'Okt',
              'Nov',
              'Des',
            ],
          },
          stroke: {
            curve: 'smooth',
            width: 2,
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: false,
          },
          colors: ['#80C2FF', '#F2994A', '#FF8080'],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              gradientToColors: ['#80C2FF'],
              shadeIntensity: 1,
              type: 'vertical',
              opacityFrom: 1,
              opacityTo: 0.5,
              stops: [0],
            },
          },
        },
        series: [
          {
            name: 'Poli Mata',
            data: [],
            isActive: false,
          },
          {
            name: 'Poli KIA',
            data: [],
            isActive: false,
          },
          {
            name: 'Poli Gigi',
            data: [],
            isActive: false,
          },
        ],
      },
      chartAllDoctor: {
        chartOptions: {
          chart: {
            id: 'doctor-compliance',
            width: '730px',
            height: 300,
          },
          xaxis: {
            categories: [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'Mei',
              'Jun',
              'Jul',
              'Ags',
              'Sep',
              'Okt',
              'Nov',
              'Des',
            ],
          },
          stroke: {
            curve: 'smooth',
            width: 2,
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: false,
          },
          colors: ['#80C2FF', '#F2994A', '#FF8080'],
        },
        series: [
          {
            name: 'Dr. Budi',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            isActive: false,
          },
          {
            name: 'Dokter Agung',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            isActive: false,
          },
        ],
      },
      resource: {
        unit: [],
        doctors: [],
        specialization: [],
      },
      filter: {
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
        specialize: '',
        unit: '',
        doctor: '',
        endMonth: '',
        endYear: '',
      },
      table: {
        unit: {
          detail: [],
          average: null,
        },
        doctor: {
          detail: [],
          average: null,
        },
        recapDoctor: {
          detail: [],
          average: null,
          totalPercentage: null,
        },
      },
      isLoading: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getDoctor();
      this.getSpecialize();
      this.getUnit();
      this.getAllData();
      this.getByUnitData();
      this.getByDoctorData();
    });
  },
  computed: {
    routeBack() {
      return '/reporting/laporan-pelayanan';
    },
    yearRange() {
      let startYear = 1980;
      const currentYear = new Date().getFullYear();
      const years = [];
      while (startYear <= currentYear) {
        years.push(startYear++);
      }
      return years;
    },
  },
  methods: {
    showSeries(type, name, index) {
      switch (type) {
        case 'national':
          this.$refs.chartNational.toggleSeries(name);
          this.chartNational.series[index].isActive = !this.chartNational
            .series[index].isActive;
          break;
        case 'doctor':
          this.$refs.chartDoctor.toggleSeries(name);
          this.chartDoctor.series[index].isActive = !this.chartDoctor.series[
            index
          ].isActive;
          break;
        case 'allDoctor':
          this.$refs.chartAllDoctor.toggleSeries(name);
          this.chartAllDoctor.series[index].isActive = !this.chartAllDoctor
            .series[index].isActive;
          break;
        default:
          break;
      }
    },
    async getAllData() {
      this.isLoading = true;
      await axios
        .get(
          Constant.apiUrl.concat(
            `/report/compliance/monthly?month=${this.filter.month}&year=${this.filter.year}`,
          ),
        )
        .then(response => {
          const dataTable = response.data.data;
          dataTable.graph.forEach(month => {
            this.chartNational.series.forEach((arr, index) => {
              month.datas.length === 0
                ? month.datas.forEach(unit => {
                    if (unit.unit === arr.name) {
                      arr[index].data.push(unit.fornas + unit.nonFornas);
                    }
                  })
                : (this.chartNational.series[index].data = [
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                  ]);
            });
          });

          this.table.recapDoctor = {
            detail: dataTable.table.length !== 0 ? dataTable.table : [],
            average: dataTable.table_percentage,
            totalPercentage: dataTable.total_percentage.toFixed(2),
          };
          this.isLoading = false;
        })
        .catch(err => {
          this.showErrorAxios(err);
        });
    },
    async getByUnitData() {
      await axios
        .get(
          Constant.apiUrl.concat(
            `/report/compliance/doctorbyunit?month=${this.filter.month}&year=${this.filter.year}&specialize=${this.filter.specialize}&unit=${this.filter.unit}`,
          ),
        )
        .then(response => {
          const dataTable = response.data.data;
          dataTable.graph.forEach(month => {
            this.chartAllDoctor.series.forEach((arr, index) => {
              month.datas.length === 0
                ? month.datas.forEach(unit => {
                    if (unit.unit === arr.name) {
                      arr[index].data.push(unit.fornas + unit.nonFornas);
                    }
                  })
                : (this.chartAllDoctor.series[index].data = [
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                  ]);
            });
          });

          this.table.doctor = {
            detail: dataTable.table,
            average: dataTable.total_percentage,
          };
        })
        .catch(err => {
          this.showErrorAxios(err);
        });
    },
    async getByDoctorData() {
      await axios
        .get(
          Constant.apiUrl.concat(
            `/report/compliance/doctor?startMonth=${this.filter.month}&startYear=${this.filter.year}&endMonth=${this.filter.endMonth}&endYear=${this.filter.endYear}&doctor=${this.filter.doctor}`,
          ),
        )
        .then(response => {
          const dataTable = response.data.data;
          dataTable.graph.forEach(month => {
            this.chartDoctor.series.forEach((arr, index) => {
              month.datas.length === 0
                ? month.datas.forEach(unit => {
                    if (unit.unit === arr.name) {
                      arr[index].data.push(unit.fornas + unit.nonFornas);
                    }
                  })
                : (this.chartDoctor.series[index].data = [
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                  ]);
            });
          });

          this.table.unit = {
            detail: dataTable.table,
            average: dataTable.total_percentage,
          };
        })
        .catch(err => {
          this.showErrorAxios(err);
        });
    },
    getStaffById(id, index) {
      axios
        .get(Constant.apiUrl.concat(`/master/staff/find/${id}`))
        .then(response => {
          const staff = response.data.data;
          this.doctor.detail[index].name = staff.detail.name;
        })
        .catch(() => {
          this.doctor.detail[index].name = '';
        });
    },
    getDoctor() {
      axios.get(Constant.apiUrl.concat('/master/staff')).then(response => {
        if (response.status === 200) {
          var data = response.data.data;
          this.resource.doctors = data
            .filter(v => v.role.role.toLowerCase() === 'dokter')
            .map(doctor => {
              return {
                doctorId: doctor._id.toString(),
                doctorName: doctor.detail.name,
              };
            });
        }
      });
    },
    getSpecialize() {
      axios
        .get(
          Constant.apiUrl.concat(
            '/master/specialize?page=1&itemCount=8&search=&sort=',
          ),
        )
        .then(response => {
          const spesialisasi = response.data.data;
          this.resource.specialization = spesialisasi.map(arr => {
            return {
              id: arr._id,
              name: arr.name,
            };
          });
        })
        .catch(() => {
          this.resource.specialization = [];
        });
    },
    getUnit() {
      axios
        .get(
          Constant.apiUrl.concat(
            '/master/unit?page=1&itemCount=8&search=&sort=name',
          ),
        )
        .then(response => {
          const unit = response.data.data;
          this.resource.unit = unit.map(arr => {
            return {
              id: arr._id,
              name: arr.name,
            };
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  font-family: 'Roboto';
  font-style: normal;

  .chart-style {
    background: #f0f9ff;
    border-radius: 25px;
    height: 320px;

    .chart-subtitle {
      padding-left: 20px;
      text-align: left;
      font-style: normal;
      font-weight: 500;
      color: #adb5bd;
    }
    .chart-container {
      position: relative;
      margin: auto;
      height: 100%;
      width: 100%;
    }
  }
  .action-container {
    padding-left: 12px;
    padding-right: 12px;
    .title-action {
      font-weight: bold;
      color: #000;
      text-align: left;
      font-size: 1em;
    }
    .action-button {
      height: 38px;
      font-weight: 700;
      background-color: #fff !important;
      color: #3498db;
      border: 2px solid $primary-color;
    }
  }
  .data-grid {
    display: grid;

    &.sum-data-grid {
      grid-template-columns: 0.9fr 160px 160px 120px;
      grid-row-gap: 2px;
      grid-auto-rows: 30px;
      &.head {
        border-bottom: 1px solid black;
      }
    }
    .label-item-table {
      font-family: Roboto;
      font-style: normal;
      font-size: 0.9rem;
      &.head {
        height: 100%;
        font-weight: 500;
      }
      &.bold-text {
        font-weight: bold;
      }
      &.left {
        text-align: left;
        padding-left: 12px;
      }
    }
    &.doctor-table-grid {
      grid-template-columns: 200px 150px 150px 100px;
      grid-row-gap: 2px;
      grid-auto-rows: 30px;
      &.head {
        border-bottom: 1px solid #828282;
        grid-auto-rows: 50px;
      }
      &.end {
        border-top: 1px solid #828282;
      }
      &.scrollable {
        max-height: 160px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 2px;
          background-color: rgb(163, 163, 163);
        }
        &::-webkit-scrollbar-thumb {
          width: 3px;
          background-color: black;
        }
      }
    }
  }
  .btn-detail {
    border-radius: 10px;
  }
}
</style>
