<template>
  <v-card class="big-card">
    <v-card-title class="justify-center">
      <h3>Kategori Kas</h3>
    </v-card-title>
    <v-card-text class="pb-0">
      <v-tabs v-model="tab" centered>
        <v-tab :key="create">
          <label class="text-none font-weight-bold">Buat Kategori Baru</label>
        </v-tab>
        <v-tab>
          <label class="text-none font-weight-bold">Edit Kategori</label>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item :key="create">
          <v-row class="row-layout my-5 mx-0">
            <v-col class="pl-16">
              <v-row class="pl-6">
                <v-col cols="2" class="text-left black--text">
                  <label>Nama Kategori</label>
                </v-col>
                <v-col cols="3" class="pt-2 pb-0">
                  <v-text-field
                    v-model="input.name"
                    placeholder="Masukan Nama Kategori"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="pl-6">
                <v-col cols="2" class="text-left black--text">
                  <label>Sub Kategori</label>
                </v-col>
                <v-col cols="5" class="pt-0">
                  <v-row>
                    <v-col class="pb-0">
                      <div
                        v-for="(subcategoryCreate, i) in subcategoryCreate"
                        :key="i"
                      >
                        <v-text-field
                          class="mt-2"
                          :label="subcategoryCreate.label"
                          v-model="subcategoryCreate.value"
                        ></v-text-field>
                      </div>
                    </v-col>
                    <v-col align-self="center">
                      <v-btn
                        @click="addSubCategoryCreate"
                        icon
                        elevation="0"
                        width="16px"
                        height="16px"
                      >
                        <v-icon color="primary">mdi-plus-circle</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" class="pt-0"> </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              tile
              elevation="0"
              width="108"
              class="text-none"
              @click="saveCreate()"
            >
              Simpan
            </v-btn>
          </v-card-actions>
        </v-tab-item>

        <v-tab-item :key="edit">
          <v-row class="row-layout my-5 mx-0">
            <v-col class="pl-16">
              <v-row class="pl-6">
                <v-col cols="2" class="text-left black--text">
                  <label>Nama Kategori</label>
                </v-col>
                <v-col cols="3" class="pt-2 pb-0">
                  <v-combobox
                    placeholder="Masukan Nama Category"
                    v-model="edit.name"
                    item-text="name"
                    item-value="id"
                    return-object
                    :items="allCategory"
                    @input="getCategoryById()"
                  ></v-combobox>
                </v-col>
              </v-row>
              <v-row class="pl-6">
                <v-col cols="2" class="text-left black--text">
                  <label>Sub Kategori</label>
                </v-col>
                <v-col cols="5" class="pt-0 pb-0">
                  <v-row>
                    <v-col class="pb-0">
                      <div
                        v-for="(subcategoryEdit, i) in subcategoryEdit"
                        :key="i"
                      >
                        <v-combobox
                          class="mt-2"
                          item-value="value"
                          item-text="value"
                          :items="allSub"
                          :label="subcategoryEdit.label"
                          v-model="subcategoryEdit.value"
                        ></v-combobox>
                      </div>
                    </v-col>
                    <v-col align-self="center">
                      <v-btn
                        @click="addSubCategoryEdit"
                        icon
                        elevation="0"
                        width="16px"
                        height="16px"
                      >
                        <v-icon color="primary">mdi-plus-circle</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" class="pt-0"> </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              tile
              elevation="0"
              width="108"
              class="text-none"
              @click="saveEdit()"
            >
              Simpan
            </v-btn>
          </v-card-actions>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import Constant from '@/const';
const axios = require('axios');

export default {
  data() {
    return {
      subCategory: [],
      editSub: {
        value: '',
      },
      nameCategory: {
        value: '',
      },
      allCategory: [],
      allSub: [],

      input: {
        name: '',
        subCategory: '',
      },
      edit: {
        name: '',
        subCategory: {
          value: '',
        },
      },
      tab: null,
      subcategoryCreate: [
        {
          value: '',
        },
      ],
      subcategoryEdit: [
        {
          value: '',
        },
      ],
    };
  },
  mounted() {
    this.getCategory();
  },
  methods: {
    addSubCategoryCreate() {
      this.subcategoryCreate.push({
        label: 'Masukkan Sub Kategori',
        value: '',
      });
    },
    addSubCategoryEdit() {
      this.subcategoryEdit.push({
        label: 'Masukkan Sub Kategori',
        value: '',
      });
    },
    closeButton() {
      this.$emit('close-form');
    },
    addSubEdit() {
      this.subcategoryEdit.push({
        label: 'Masukkan Sub Kategori',
        value: this.editSub.value,
      });
    },

    getCategory() {
      axios
        .get(Constant.apiUrl.concat('/finance/category/get-all'))
        .then(response => {
          const getData = response.data.data;
          this.allCategory = getData.map(item => {
            return {
              id: item._id,
              name: item.name,
              sub: item.sub.map(item => {
                return {
                  name: item.name,
                };
              }),
            };
          });
        });
    },

    getCategoryById() {
      const id = this.edit.name.id;
      axios
        .get(Constant.apiUrl.concat('/finance/category/get/').concat(id))
        .then(response => {
          const getData = response.data.data.sub;
          this.allSub = getData.map(item => {
            return {
              value: item.name,
            };
          });
        });
    },
    saveCreate() {
      if (this.subcategoryCreate.length > 0) {
        const itemArr = this.subcategoryCreate.map(item => {
          return {
            name: item.value,
            timestamps: {
              created_by: this.$store.getters.userLoggedIn.name,
            },
          };
        });
        axios.post(Constant.apiUrl.concat('/finance/category '), {
          name: this.input.name,
          sub: itemArr,
          timestamps: {
            created_by: this.$store.getters.userLoggedIn.name,
          },
        });
        Swal.fire('Berhasil', '', 'success');
        this.closeButton();
        this.textfields = [];
        this.subcategoryCreate = [
          {
            value: '',
          },
        ];
        this.input.name = '';
      }
    },
    saveEdit() {
      this.subcategoryEdit.concat(this.editSub);
      if (this.subcategoryEdit.length > 0) {
        const itemArr = this.subcategoryEdit.map(item => {
          return {
            name: item.value.value,
            timestamps: {
              created_by: this.$store.getters.userLoggedIn.name,
            },
          };
        });
        const id = this.edit.name.id;
        axios.post(
          Constant.apiUrl.concat('/finance/category?id_category=').concat(id),
          {
            name: this.edit.name.name,
            sub: itemArr,
            timestamps: {
              created_by: this.$store.getters.userLoggedIn.name,
            },
          },
        );
        Swal.fire('Berhasil', '', 'success');
        this.closeButton();
        this.textfields = [];
        this.subcategoryEdit = [
          {
            value: '',
          },
        ];
        this.edit.name = '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.big-card {
  height: 100%;
}
.row-layout {
  text-align: left;
  height: 300px;
}
</style>
