<template>
  <v-card class="pa-5 main-card">
    <div class="d-flex justify-space-between mb-4 pl-4">
      <h2>Buat Obat Racik Penjualan Bebas</h2>
      <v-btn text fab width="35" height="35" @click="$emit('close-dialog')"
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </div>
    <v-row class="px-4">
      <v-col cols="4" class="text-left">
        <span class="primary--text font-weight-medium"
          >Pembuatan Obat Racik</span
        >
        <v-form ref="generalData">
          <div class="data-input pt-3 mb-15">
            <label class="font-weight-medium text-left">Nama Obat Racik</label>
            <v-text-field
              single-line
              :rules="rules.mixDrugName"
              dense
              v-model="input.mixDrugName"
            ></v-text-field>
            <label class="font-weight-medium text-left">Jumlah</label>
            <v-text-field
              single-line
              type="number"
              :min="0"
              :rules="rules.amountGeneral"
              dense
              v-model="input.amount"
            ></v-text-field>
            <label class="font-weight-medium text-left">Satuan</label>
            <v-combobox
              append-icon="mdi-chevron-down"
              auto-select-first
              single-line
              dense
              :rules="rules.unit"
              item-text="name"
              :items="resource.unit"
              v-model="input.unit"
            ></v-combobox>
          </div>
        </v-form>
        <div class="add-drug-material">
          <span class="primary--text font-weight-medium"
            >Tambah Bahan Obat Racik</span
          >
          <v-form ref="mixdata">
            <div class="data-input pt-3">
              <label class="font-weight-medium text-left">Nama Obat</label>
              <v-combobox
                append-icon="mdi-chevron-down"
                single-line
                :rules="rules.drug"
                dense
                auto-select-first
                :search-input.sync="searchDrug"
                :items="resource.drugs"
                v-model="input.material.drugName"
                :loading="isLoading"
              >
                <!-- :rules="rules.drug" -->
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-layout>
                        <v-flex class="d-flex justify-start">
                          <span class="text-left">
                            {{ `${data.item.text || ''}` }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <v-layout>
                        <span class="mr-4">{{
                          `Sisa Stok : ${data.item.quantity || ''} ${data.item
                            .packaging || ''}`
                        }}</span>
                        <span>{{ currency(data.item.price || 0) }}</span>
                      </v-layout>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template></v-combobox
              >
              <label class="font-weight-medium text-left">Jenis Sediaan</label>
              <label class="font-weight-medium text-left">{{
                input.material.drugName.preparation
              }}</label>
              <label class="font-weight-medium text-left">Dosis</label>
              <v-text-field
                :suffix="input.material.drugName.strength_unit"
                v-model="input.material.drugName.strength"
                hide-details
                single-line
                dense
              >
              </v-text-field>
              <label class="font-weight-medium text-left"
                >Jumlah Obat Diambil</label
              >
              <v-text-field
                v-model="input.material.amountDrugTaken"
                single-line
                :rules="rules.amount"
                type="number"
                :min="0"
                dense
              >
              </v-text-field>
            </div>
          </v-form>
          <v-row>
            <v-col cols="12" class="text-right pt-8">
              <v-btn
                color="primary"
                class="font-weight-medium caption"
                outlined
                @click="addToList()"
                height="30"
                width="200"
                style="background-color: white !important"
                ><v-icon small class="font-weight-bold">mdi-plus</v-icon>TAMBAH
                BAHAN OBAT RACIK
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="8">
        <v-data-table
          :headers="table.headers"
          :items="table.items"
          :items-per-page="table.items.length"
          height="450"
          hide-default-footer
        >
          <template v-slot:item="{ item, index }">
            <tr>
              <td class="text-center">{{ index + 1 }}</td>
              <td class="text-left">{{ item.drugName }}</td>
              <td class="text-center">{{ item.preparation }}</td>
              <td class="text-center">{{ item.dosage }}</td>
              <td class="text-center">
                {{ `${item.amountDrugTaken} ${item.package}` }}
              </td>
              <td class="text-center">
                <v-icon color="red" @click="deleteFromList(item)"
                  >mdi-delete</v-icon
                >
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-footer class="d-flex justify-end" color="white">
      <div class="d-flex" style="margin-top: -20px">
        <v-btn
          class="font-weight-medium mr-3"
          color="primary"
          height="30"
          width="80"
          outlined
          @click="$emit('close-dialog')"
          >KEMBALI</v-btn
        >
        <v-btn
          class="font-weight-medium"
          @click="save()"
          height="30"
          width="80"
          color="primary"
          >SIMPAN</v-btn
        >
      </div>
    </v-footer>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import axios from 'axios';
import Constant from '@/const';
import formatMixin from '@/mixin/formatMixin';

export default {
  name: 'MixDrugManufacturing',
  mixins: [formatMixin],
  data() {
    return {
      isLoading: false,
      searchDrug: '',
      input: {
        mixDrugName: '',
        amount: '',
        unit: '',
        material: {
          drugName: '',
          amountDrugTaken: '',
        },
      },
      resource: {
        unit: ['Kapsul', 'Bungkus', 'Sirup', 'Salep'],
        drugs: [],
      },
      rules: {
        drug: [v => !!v || 'Masukkan nama obat'],
        amount: [v => !!v || 'Masukkan jumlah obat'],
        mixDrugName: [v => !!v || 'Masukkan nama obat racik'],
        amountGeneral: [v => !!v || 'Masukkan jumlah obat racik'],
        unit: [v => !!v || 'Masukkan satuan obat racik'],
      },
      table: {
        headers: [
          {
            text: 'No',
            value: 'no',
            sortable: false,
            width: '5%',
          },
          {
            text: 'Nama Obat',
            value: 'drugName',
            sortable: false,
            width: '42%',
          },
          {
            text: 'Jenis Sediaan',
            value: 'preparation',
            sortable: false,
            width: '15%',
            class: 'text-sm-center',
          },
          {
            text: 'Dosis',
            value: 'dosage',
            sortable: false,
            class: 'text-sm-center',
            width: '10%',
          },
          {
            text: 'Jumlah Obat yang Diambil',
            value: 'amountDrugTaken',
            class: 'text-sm-center',
            sortable: false,
            width: '25%',
          },
          {
            text: '',
            value: 'action',
            class: 'text-sm-center pr-2 px-0 mx-0',
            sortable: false,
            width: '3%',
          },
        ],
        items: [],
      },
    };
  },
  watch: {
    searchDrug(value) {
      if (value) {
        this.getDrugs(value);
      }
    },
  },
  mounted() {
    this.getDrugs('');
  },
  methods: {
    save() {
      if (this.$refs.generalData.validate()) {
        if (this.table.items.length === 0) {
          Swal.fire({
            icon: 'error',
            title: 'Gagal',
            text: 'Tidak Ada Obat Yang Ditambahkan',
          });
        }
        if (this.table.items.length > 0) {
          const dataTable = {
            mixDrugName: this.input.mixDrugName,
            amount: this.input.amount,
            unit: this.input.unit,
            table: this.table.items,
          };
          this.$emit('save', dataTable);
          this.$emit('close-dialog');
        }
      }
    },
    addToList() {
      if (this.$refs.mixdata.validate()) {
        if (
          this.input.material.amountDrugTaken <=
          this.input.material.drugName.quantity
        ) {
          this.table.items.push({
            drugName: this.input.material.drugName.displayName,
            package: this.input.material.drugName.packaging,
            dosage: this.input.material.drugName.strength,
            amountDrugTaken: this.input.material.amountDrugTaken,
            id: this.input.material.drugName.id,
            id_stock: this.input.material.drugName.id_stock,
            name: this.input.material.drugName.name,
            strength_unit: this.input.material.drugName.strength_unit,
            price: +this.input.material.drugName.price,
            expiry: this.input.material.drugName.expiry,
            quantity: this.input.material.drugName.quantity,
            preparation: this.input.material.drugName.preparation,
          });
          this.input.material.drugName = '';
          this.input.material.amountDrugTaken = '';
          this.$refs.mixdata.resetValidation();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Terjadi Kesalahan',
            text: `Stok Obat ${this.input.material.drugName.displayName} tidak mencukupi, tersisa ${this.input.material.drugName.quantity}`,
          });
        }
      }
    },
    deleteFromList(item) {
      const index = this.table.items.indexOf(item);
      this.table.items.splice(index, 1);
    },
    async getDrugs(keyword) {
      this.isLoading = true;
      try {
        const response = await axios.get(
          Constant.apiUrl.concat(
            `/stock/all/names?page=1&itemCount=30&category=Obat&name=${
              !keyword ? '' : keyword
            }`,
          ),
        );
        const data = response.data.data;
        this.resource.drugs = data.map(arr => {
          return {
            id: arr.id_drugs,
            id_stock: arr._id,
            name: arr.drugs.detail?.name || '',
            text: `${arr.drugs.detail.name} ${arr.drugs.detail.strength} ${arr.drugs.detail.strength_unit} (${arr.drugs.detail.factory})`,
            displayName: `${arr.drugs.detail.name} ${arr.drugs.detail.strength} ${arr.drugs.detail.strength_unit} (${arr.drugs.detail.factory})`,
            strength_unit: arr.drugs?.detail?.strength_unit || '',
            strength: arr.drugs.detail?.strength || '',
            preparation: arr.drugs.detail?.preparation || '',
            packaging: arr.package || '',
            price: arr.price || '',
            quantity: arr.quantity || '',
            expiry: arr.expiry_date || '',
          };
        });
      } catch (error) {
        if (error) {
          this.resource.drugs = [];
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-card {
  min-height: 600px;
  height: 100%;
}
.add-drug-material {
  background-color: #f4f5f5;
  padding: 15px;
  border-radius: 4px;
}
.data-input {
  display: grid;
  grid-template-columns: 40% 60%;
  align-items: center;
  row-gap: 5px;
  font-size: 0.9rem;
}
::v-deep .v-data-table-header {
  background-color: #f4f5f5;
  border-bottom-color: #9ca2a5;
  span {
    color: rgb(56, 55, 55);
  }
}
</style>
