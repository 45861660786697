<template>
  <div>
    <v-container fluid>
      <v-row class="pb-3">
        <v-col cols="6" class="text-left pb-0 mr-15">
          <h2>Laporan Jumlah Pasien Rujukan BPJS</h2>
          <v-row style="color: #828282;" class="pa-3">
            <h5>Update: {{ timestampDate }}</h5>
            <h5 class="pl-5">
              Kuota Terpakai : {{ usedQuota }} dari {{ quotaTotal }} pasien
            </h5>
          </v-row>
        </v-col>
        <v-col align-self="center">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                append-icon="mdi-chevron-down"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                dense
                placeholder="Tanggal Awal"
                hide-details="false"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              @input="
                menu = false;
                getInstance();
                getDoctor();
              "
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col align-self="center">
          <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                hide-details="false"
                v-model="dateEnd"
                class="input"
                outlined
                placeholder="Tanggal Akhir"
                append-icon="mdi-chevron-down"
                readonly
                dense
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateEnd"
              @input="
                menu2 = false;
                getInstance();
                getDoctor();
              "
            >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8" class="pb-0">
          <v-row>
            <v-col class="text-left pb-0">
              <h5>Tujuan Instansi RS Rujukan</h5>
            </v-col>
            <v-col class="text-right pb-0">
              <p class="caption grey--text">
                Data yang ditampilkan berdasarkan total terbanyak
              </p>
            </v-col>
          </v-row>
          <v-row class="pl-3 pb-3">
            <v-col class="pt-0">
              <v-data-table
                :headers="hospital.headers"
                :items="hospital.items"
                :items-per-page="hospital.items.length"
                hide-default-footer
                fixed-header
                height="178"
                class="table-style"
              >
                <template v-slot:[`item.no`]="{ item }">
                  <tr>
                    <td>{{ hospital.items.indexOf(item) + 1 }}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row class="pt-3">
            <v-col class="text-left pb-0 pt-0">
              <h5>Nama Dokter Perujuk</h5>
            </v-col>
          </v-row>
          <v-row class="pl-3 pb-3">
            <v-col>
              <v-data-table
                :headers="doctors.headers"
                :items="doctors.items"
                :items-per-page="doctors.items.length"
                hide-default-footer
                fixed-header
                height="178"
                class="table-style"
              >
                <template v-slot:[`item.no`]="{ item }">
                  <tr>
                    <td>{{ doctors.items.indexOf(item) + 1 }}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-left">
              <router-link :to="showDetail" active>
                <v-btn tile color="primary" elevation="0" class="text-none">
                  Lihat Detail Pasien
                </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4">
          <v-row justify-sm="center" class="py-3">
            <v-card
              height="125"
              width="150"
              class="total-card rounded-lg"
              elevation="0"
            >
              <div class="pl-4 pt-4 text-left white--text">
                <p class="caption mb-0" style="font-weight: 500">
                  Total Kuota BPJS
                </p>
                <p style="font-weight: 700; font-size: 16px">
                  {{ timestampMonth }}
                </p>
                <p class="mt-6" style="font-weight: 700; font-size: 22px">
                  {{ quotaTotal }} pasien
                </p>
              </div>
            </v-card>
          </v-row>
          <v-row class="py-3" justify-sm="center">
            <v-card
              height="125"
              width="150"
              class="used-card rounded-lg"
              elevation="0"
            >
              <div class="pl-4 pt-4 text-left white--text">
                <p class="caption mb-0" style="font-weight: 500">
                  Kuota Terpakai
                </p>
                <p style="font-weight: 700; font-size: 16px">
                  {{ timestampMonth }}
                </p>
                <p class="mt-6" style="font-weight: 700; font-size: 22px">
                  {{ usedQuota }} pasien
                </p>
              </div>
            </v-card>
          </v-row>
          <v-row class="py-3" justify-sm="center">
            <v-card
              height="125"
              width="150"
              class="remain-card rounded-lg"
              elevation="0"
              v-if="warning === false"
            >
              <div class="pl-4 pt-4 text-left white--text">
                <p class="caption mb-0" style="font-weight: 500">Sisa Kuota</p>
                <p style="font-weight: 700; font-size: 16px">
                  {{ timestampMonth }}
                </p>
                <p class="mt-6" style="font-weight: 700; font-size: 22px">
                  {{ remainQuota }} pasien
                </p>
              </div>
            </v-card>
            <v-card
              height="125"
              width="150"
              class="remain-card-warning rounded-lg"
              elevation="0"
              v-if="warning === true"
            >
              <div class="pl-4 pt-4 text-left white--text">
                <p class="caption mb-0" style="font-weight: 500">Sisa Kuota</p>
                <p style="font-weight: 700; font-size: 16px">
                  {{ timestampMonth }}
                </p>
                <p class="mt-6" style="font-weight: 700; font-size: 22px">
                  {{ remainQuota }} pasien
                </p>
              </div>
            </v-card>
          </v-row>
          <v-row justify-sm="center" class="pt-2" v-if="warning === true">
            <div style="width: 151px; color: #EB5757;">
              <p class="text-left caption">
                Warning : Kuota bulan ini telah terpakai melebihi batas
                maksimal!
              </p>
            </div>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Constant from '@/const';

const axios = require('axios');

export default {
  data() {
    return {
      timestampDate: '',
      timestampMonth: '',
      quotaTotal: 500,
      usedQuota: 600,
      remainQuota: null,
      warning: false,
      hospital: {
        headers: [
          { text: 'No', value: 'no' },
          { text: 'Nama Instansi RS', value: 'hospitalName' },
          { text: 'Jumlah Pasien', value: 'amount' },
        ],
        items: [],
      },
      doctors: {
        headers: [
          { text: 'No', value: 'no' },
          { text: 'Nama Dokter', value: 'doctorsName' },
          { text: 'Jumlah Pasien', value: 'amount' },
        ],
        items: [],
      },
      date: '',
      dateEnd: '',
    };
  },
  created() {
    setInterval(this.getNow, 500);
    setInterval(this.quota, 500);
  },
  mounted() {
    this.$nextTick(() => {
      this.getInstance();
      this.getDoctor();
      this.getQuota();
    });
  },
  computed: {
    showDetail() {
      return '/reporting/laporan-pelayanan/pasien-rujukan-bpjs';
    },
  },
  methods: {
    getQuota() {
      axios
        .get(
          Constant.apiUrl.concat(`/report/patient-referral-bpjs/total-report`),
        )
        .then(response => {
          const data = response.data.data;
          this.remainQuota = data.remain_quota;
          this.quotaTotal = data.total_quota;
          this.usedQuota = data.used_quota;
        });
    },
    getNow() {
      const monthNames = [
        'Januari',
        'Februari',
        'Maret',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Agustus',
        'September',
        'Oktober',
        'November',
        'Desember',
      ];
      const today = new Date();
      const date =
        today.getDate() +
        ' ' +
        monthNames[today.getMonth()] +
        ' ' +
        today.getFullYear();
      const month = monthNames[today.getMonth()] + ' ' + today.getFullYear();
      this.timestampDate = date;
      this.timestampMonth = month;
    },
    quota() {
      if (this.usedQuota > this.quotaTotal) {
        this.remainQuota = 0;
        this.warning = true;
      } else {
        this.remainQuota = this.quotaTotal - this.usedQuota;
        this.warning = false;
      }
    },
    getInstance() {
      axios
        .get(
          Constant.apiUrl.concat(
            `/report/patient-referral-bpjs/group-instance?start_date=${this.date}&finish_date=${this.dateEnd}`,
          ),
        )
        .then(response => {
          const data = response.data.data;
          this.hospital.items = data.map(hospital => {
            return {
              hospitalName: hospital.instansi,
              amount: hospital.count,
            };
          });
        });
    },
    getDoctor() {
      axios
        .get(
          Constant.apiUrl.concat(
            `/report/patient-referral-bpjs/group-doctor?start_date=${this.date}&finish_date=${this.dateEnd}`,
          ),
        )
        .then(response => {
          const data = response.data.data;
          this.doctors.items = data.map(doctors => {
            return {
              doctorsName: doctors.doctor,
              amount: doctors.count,
            };
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.total-card {
  background-image: linear-gradient(to right, #80a1ff, #b2d1ff);
}
.used-card {
  background-image: linear-gradient(to right, #ff8a58, #ffd08d);
}
.remain-card {
  background-image: linear-gradient(to right, #4b966b, #7ccf9f);
}
.remain-card-warning {
  background-image: linear-gradient(to right, #eb5757, #eba4a4);
}

.table-style {
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 3px;
  }
}
</style>
