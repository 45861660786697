<template>
  <v-card style="height: 100%">
    <v-card-title class="justify-center">
      <h3>Edit Data Kas</h3>
    </v-card-title>
    <v-card-text>
      <v-row class="row-layout my-0 pa-3 mx-6">
        <v-col class="ml-6 pl-6">
          <v-row class="pl-6">
            <v-col cols="5" class="text-left pb-0">
              <label>Tanggal Transaksi</label>
            </v-col>
            <v-col cols="5" class="pt-0 pb-0">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    disabled
                    v-model="detail.date"
                    append-icon="mdi-chevron-down"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateFormat"
                  no-title
                  scrollable
                  @input="menu = false"
                >
                  <!-- <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(date)">
                    OK
                  </v-btn> -->
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row class="pl-6">
            <v-col cols="5" class="text-left pb-0">
              <label>Nama Transaksi</label>
            </v-col>
            <v-col cols="5" class="pt-0">
              <v-text-field
                placeholder="Masukkan Nama Transaksi"
                v-model="detail.transName"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row>
            <v-col cols="5" class="text-left pb-0">
              <label>Sub Kategori</label>
            </v-col>
            <v-col cols="5" class="pt-0 pb-0">
              <v-select
                v-model="detail.subCategory"
                return-object
                :items="selectSub"
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="text-left pb-0">
              <label>Jenis Kas</label>
            </v-col>
            <v-col cols="6" class="pt-0 pb-0">
              <v-radio-group row v-model="detail.radioSelected">
                <v-radio
                  label="Kas Masuk"
                  value="kredit"
                  @click="detail.radioSelected = 'kredit'"
                ></v-radio>
                <v-radio
                  label="Kas Keluar"
                  value="debit"
                  @click="detail.radioSelected = 'debit'"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="text-left pb-0">
              <label>Nominal</label>
            </v-col>
            <v-col cols="5" class="pt-0 pb-0">
              <v-text-field
                placeholder="Masukkan Nominal"
                v-model.number="detail.nominal"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="text-left pb-0">
              <label>Alasan</label>
            </v-col>
            <v-col cols="5" class="pt-0 pb-0">
              <v-textarea
                v-model="detail.reason"
                auto-grow
                outlined
                placeholder="Masukan Alasan"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="pr-16">
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        tile
        elevation="0"
        @click="updateItem()"
        width="108"
      >
        Simpan
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import Constant from '@/const';
const moment = require('moment');
const axios = require('axios');
export default {
  data() {
    return {
      menu: false,
      subCategory1: [],
      subCategory2: [],
      selectSub: [],
      select: {
        subCategory: [],
      },
      dateFormat: new Date().toISOString().substr(0, 10),
      radioSelected: '',
      detail: {
        reason: '',
        date: '',
        nominal: '',
        subCategory: '',
        transName: '',
        radioSelected: '',
      },
    };
  },
  mounted() {
    this.getDataById();
    this.getSubCategory();
  },

  watch: {
    dateFormat(val) {
      if (val) {
        this.detail.date = this.formatDate(this.dateFormat);
      }
    },
  },

  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    updateItem() {
      axios
        .post(
          Constant.apiUrl
            .concat('/finance/report?id_fund=')
            .concat(localStorage.getItem('cashInput_id')),
          {
            sub_category: this.detail.subCategory,
            reason: this.detail.reason,
            detail: [
              {
                name_transaction: this.detail.transName,
                type_fund: this.detail.radioSelected,
                total: this.detail.nominal,
                timestamps: {
                  created_by: this.$store.getters.userLoggedIn.name,
                },
              },
            ],
            timestamps: {
              created_by: this.$store.getters.userLoggedIn.name,
            },
          },
        )
        .then(response => {
          if (response.status === 200) {
            Swal.fire(
              'Berhasil',
              'Perubahan Data Transaksi berhasil disimpan',
              'success',
            );
            this.closeButton();
          }
        })
        .catch(error => {
          if (error.response) {
            const message = error.response.data.pesan;
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: message,
            });
          } else if (error.request) {
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: 'Tidak dapat menyambung ke server',
            });
          }
        });
    },
    getDataById() {
      axios
        .get(
          Constant.apiUrl
            .concat('/finance/report/')
            .concat(localStorage.getItem('cashInput_id')),
        )
        .then(response => {
          const getDataItem = response.data.data;
          this.detail.nominal = getDataItem.detail[0].total;
          this.detail.transName = getDataItem.detail[0].name_transaction;
          this.detail.date = moment(getDataItem.date).format('DD/MM/YYYY');
          this.detail.subCategory = getDataItem.sub_category;
          this.detail.radioSelected = getDataItem.detail[0].type_fund;
          this.detail.reason = getDataItem.reason;
        });
    },
    getSubCategory() {
      axios
        .get(Constant.apiUrl.concat('/finance/category/sub'))
        .then(response => {
          const getItemSub1 = response.data.data;
          this.subCategory1 = getItemSub1.map(item => {
            return {
              sub: item.sub.map(item => {
                return item.name;
              }),
            };
          });

          for (var i = 0; i < this.subCategory1.length; i++) {
            if (this.subCategory1[i].sub !== undefined) {
              this.select.subCategory.push(...this.subCategory1[i].sub);
            }
          }
          const arr = this.select.subCategory;
          const result = arr.filter(item => item !== undefined);
          this.selectSub.push(...result);
        });
    },
    closeButton() {
      this.$emit('close-form');
    },
  },
};
</script>

<style>
.v-text-field {
  padding-top: 0 !important;
  margin-top: 0 !important;
}
.row-layout {
  height: 370px;
  width: 1100px;
}
.text__label {
  text-align: left;
}
.v-input--selection-controls {
  margin-top: 8px !important;
}
</style>
