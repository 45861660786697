<template>
  <v-card class="main-container">
    <section class="pt-5 pb-2 mx-8 card-header">
      <div class="mb-8 d-flex justify-space-between">
        <h2 class="text-left">Detail Resep</h2>
        <v-btn @click="$emit('close-dialog')" small fab text>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="meta-header">
        <label class="font-weight-medium text-left">No RM</label>
        <label class="grey--text text-left">{{ patientData.rmNumber }}</label>
        <label class="font-weight-medium text-left">Nama</label>
        <label class="grey--text text-left">{{
          `${patientData.meta.name} (${patientData.meta.gender[0]})`
        }}</label>
        <label class="font-weight-medium text-left">Tanggal Lahir</label>
        <label class="grey--text text-left">{{
          `${birthDate} (${age})`
        }}</label>
      </div>
    </section>
    <v-container class="item-container px-12" fluid>
      <v-row>
        <v-expansion-panels
          flat
          class="elevation-0"
          style="width: 90vw; margin: auto"
        >
          <v-expansion-panel
            v-for="(item, index) in items"
            :key="`panel-${index}`"
          >
            <v-expansion-panel-header class="panel-header">
              {{ `${item.name}, ${item.date} pukul ${item.time} WIB` }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container>
                <v-data-table
                  :headers="headers"
                  :items="item.table"
                  hide-actions
                  item-key="name"
                  hide-default-footer
                >
                </v-data-table>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-container>
    <v-row class="card-footer d-flex justify-end px-8">
      <v-btn @click="$emit('back')" color="primary" outlined>Kembali</v-btn>
    </v-row>
  </v-card>
</template>

<script>
import Constant from '@/const';
import moment from 'moment-timezone';

const axios = require('axios');

export default {
  name: 'DetailRecipe',
  props: {
    patientData: {
      type: Object,
      default: null,
    },
    allergy: {
      type: Array,
    },
    idEmr: {
      type: String,
    },
  },
  data() {
    return {
      birthDate: '',
      age: '',
      headers: [
        {
          text: 'Nama Obat',
          value: 'name',
          width: '35%',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Jenis Sediaan',
          value: 'preparation',
          width: '11%',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Dosis',
          value: 'dosage',
          width: '11%',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Aturan Pakai',
          value: 'rule',
          width: '11%',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Jumlah Diminta',
          value: 'amountAsked',
          width: '11%',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Jumlah Diberikan',
          value: 'amountGiven',
          width: '11%',
          sortable: false,
          align: 'center',
        },
      ],
      items: [],
    };
  },
  mounted() {
    this.getDetailRecipe();
    this.birthDate = moment(this.patientData.meta.birthDate).format(
      'DD/MM/YYYY',
    );
    this.age = this.patientData.meta.age + ' tahun';
  },
  methods: {
    getDetailRecipe() {
      axios
        .get(
          Constant.apiUrl.concat(
            `/patient/recipe/all/${this.idEmr}?type=rawat_inap`,
          ),
        )
        .then(response => {
          const { data } = response.data;
          this.items = data.map((d, index) => {
            return {
              name: `Resep ${index + 1}`,
              date: d.dateSplit[0],
              time: d.dateSplit[1],
              table: d.recipe.map(drug => {
                return {
                  name: drug.name,
                  preparation: drug.preparation || '',
                  dosage: drug.strength || '',
                  rule: drug.usage,
                  amountAsked: drug.quantity,
                  amountGiven: drug.quantity,
                };
              }),
            };
          });
        })
        .catch(error => {
          if (error) {
            this.items = [];
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.item-container {
  max-height: 60vh;
  min-height: 60vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.panel-header {
  overflow-x: hidden;
  background-color: #deeef9;
  font-weight: 500;
}
.meta-header {
  margin-bottom: 20px;
  overflow-x: hidden;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr 2fr);
}
.card-header {
  overflow-x: hidden;
  top: 0;
  position: sticky;
  background-color: white;
  z-index: 2;
}
.card-footer {
  overflow-x: hidden;
  bottom: 0;
  position: sticky;
  background-color: white;
  z-index: 2;
}
.main-container {
  overflow-x: hidden;
  min-height: 90vh;
}

::v-deep .v-data-table-header {
  background-color: #f4f5f5;
  border-bottom: 1.2px solid #9ca2a5;
}

::v-deep td {
  border-bottom: 1.2px solid #f4f5f5 !important;
}

.v-data-table::v-deep th {
  font-size: 12px !important;
}
.v-data-table::v-deep td {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #3f484a !important;
}

::v-deep .v-expansion-panel-header {
  border-bottom: 1px solid #86bcdb !important;
}
</style>
