<template>
  <div class="reporting-goods">
    <v-container fluid>
      <v-row align="center" class="pa-3">
        <v-col cols="5">
          <v-toolbar-title class="font-weight-bold float-left">
            Laporan Pemakaian Barang
          </v-toolbar-title>
        </v-col>

        <v-spacer></v-spacer>
        <v-btn depressed color="primary" class="mx-4 text-none" tile>
          Export
        </v-btn>
        <v-btn @click="show = !show" text>
          <span class="text-capitalize">Filters</span>
          <v-icon>mdi-filter-variant </v-icon>
        </v-btn>
        <v-btn class="mr-2" color="grey" icon @click="getAllInventory()">
          <v-icon>mdi-autorenew </v-icon>
        </v-btn>
        <v-text-field
          background-color="grey lighten-3"
          placeholder="Search"
          solo
          dense
          hide-details
          flat
          prepend-inner-icon="mdi-magnify"
          single-line
          v-model="search"
        ></v-text-field>
      </v-row>
      <v-row v-if="show === true">
        <v-col cols="8"></v-col>
        <v-col cols="2">
          <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                dense
                hide-details
                append-icon="mdi-chevron-down"
                v-model="date2"
                placeholder="Pilih Tanggal"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date2" @input="menu2 = false">
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="2"
          ><v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                append-icon="mdi-chevron-down"
                dense
                hide-details
                v-model="date"
                placeholder="Pilih Tanggal"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" @input="menu = false" /> </v-menu
        ></v-col>
      </v-row>
    </v-container>

    <v-main>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <template>
              <v-data-table
                :headers="table.headers"
                :items="table.items"
                :search="search"
                show-expand
                :expanded="expanded"
                :loading="loading"
                single-expand
                item-key="name"
                :items-per-page="5"
                hide-default-footer
                @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
              >
                <template v-slot:[`item.no`]="{ item }">
                  <tr>
                    <td>{{ table.items.indexOf(item) + 1 }}</td>
                  </tr>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length" class="expand">
                    <v-card flat color="#F4F5F5">
                      <v-container class="pb-0">
                        <v-row no-gutters>
                          <v-col
                            cols="5"
                            class="text-center  grey--text text--darken-2 subtitle-2"
                          >
                            <span>Jumlah Barang Keluar </span></v-col
                          >
                          <v-col cols="2"></v-col>
                          <v-col
                            cols="5"
                            class="text-center  grey--text text--darken-2 subtitle-2"
                          >
                            <span>Sisa Stock </span></v-col
                          >
                        </v-row>
                      </v-container>
                      <v-container>
                        <v-row no-gutters>
                          <v-col cols="5">
                            <v-row>
                              <v-col cols="4" class="pb-0">Rawat Jalan</v-col>
                              <v-col cols="4" class="pb-0">IGD</v-col>
                              <v-col cols="4" class="pb-0">Rawat Inap</v-col>
                              <v-col cols="4" class="pt-0">{{
                                item.historyOut
                              }}</v-col>
                              <v-col cols="4" class="pt-0">{{
                                item.historyOut
                              }}</v-col>
                              <v-col cols="4" class="pt-0">{{
                                item.historyOut
                              }}</v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="2"></v-col>
                          <v-col cols="5">
                            <v-row>
                              <v-col cols="4" class="pb-0">Rawat Jalan</v-col>
                              <v-col cols="4" class="pb-0">IGD</v-col>
                              <v-col cols="4" class="pb-0">Rawat Inap</v-col>
                              <v-col cols="4" class="pt-0">{{
                                item.stock
                              }}</v-col>
                              <v-col cols="4" class="pt-0">{{
                                item.stock
                              }}</v-col>
                              <v-col cols="4" class="pt-0">{{
                                item.stock
                              }}</v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                  </td>
                </template>
              </v-data-table>
            </template>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer color="white">
      <v-container fluid>
        <v-row>
          <v-pagination
            v-model="table.pagination.page"
            :length="pageLength"
            :total-visible="10"
            @input="getAllDrugsQueue()"
          ></v-pagination>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import axios from 'axios';
import Constant from '@/const';
import jwtMixin from '@/mixin/jwtMixin';

export default {
  name: 'ReportingGoods',
  mixins: [jwtMixin],
  data() {
    return {
      date: '',
      date2: '',
      loading: false,
      expanded: [],
      show: false,
      menu: false,
      menu2: false,
      table: {
        headers: [
          {
            text: 'No',
            value: 'no',
            width: '70px',
          },
          { text: 'Nama Obat', value: 'name', sortable: false },
          { text: 'Kekuatan', value: 'strength', sortable: false },
          { text: 'Bentuk', value: 'preparation', sortable: false },
          { text: 'Fornas', value: 'isFornas', sortable: false },
          { text: 'VEN', value: 'ven', sortable: false },
          { text: 'HPP', value: 'hpp', sortable: false },
          {
            text: 'Jumlah Total Barang Keluar',
            value: 'historyOut',
            sortable: false,
          },
          {
            text: 'Jumlah Total Sisa Stock',
            value: 'stock',
            sortable: false,
          },
        ],
        items: [],
        pagination: {
          descending: false,
          page: 1,
          rowsPerPage: 7,
          sortBy: '',
        },
      },
      pageLength: 0,
      search: '',
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshAndAutoUpdate();
    });
  },
  methods: {
    getAllInventory() {
      this.loading = true;
      this.runApiCalls(() => {
        axios
          .get(Constant.apiUrl.concat('/inventory/report/all'))
          .then(response => {
            const data = response.data.data;
            this.pageLength = Math.ceil(data.length / 5);
            this.table.items = data.map(item => {
              return {
                name:
                  item.drugs !== undefined ? item.drugs.name : item.goods.name,
                strength:
                  item.drugs !== undefined
                    ? `${item.drugs.strength} ${item.drugs.strength_unit}`
                    : '',
                preparation:
                  item.drugs !== undefined ? item.drugs.preparation : '',
                isFornas:
                  item.drugs !== undefined
                    ? item.drugs.isFornas
                      ? 'Ya'
                      : 'Tidak'
                    : '',
                hpp: item.drugs !== undefined ? item.drugs.hpp : '',
                historyOut: item.history_out[0].quantity,
                stock:
                  item.stock.length > 1
                    ? item.stock.reduce((a, b) => {
                        return a.quantity + b.quantity;
                      })
                    : item.stock[0].quantity,
              };
            });
            this.loading = false;
          })
          .catch(() => {
            this.table.items = [];
            this.loading = false;
          });
      });
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.getAllInventory();
      this.interval = setInterval(this.getAllInventory, 60000);
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.input {
  max-width: 30%;
}

::v-deep {
  .v-data-table
    > .v-data-table__wrapper
    tbody
    tr.v-data-table__expanded__content {
    box-shadow: none;
    background-color: #f4f5f5;
  }

  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border: none;
  }
}
</style>
