<template>
  <v-card tile>
    <v-card-title class="justify-center">
      <h4>Tambah Data Bed</h4>
    </v-card-title>
    <v-row no-gutters class="px-6 py-3">
      <v-col cols="12" class="text-left">
        <label class="font-weight-bold body-2">Nomor Bed</label>
        <v-text-field
          dense
          outlined
          placeholder="Contoh : 101"
          :rules="[validateBedNumber(input.bed)]"
          type="number"
          v-model="input.bed"
        ></v-text-field>
      </v-col>
      <v-col cols="12" class="text-left pt-3">
        <label class="font-weight-bold body-2">Ketersediaan Bed</label>
        <v-radio-group row class="mt-0" v-model="input.availability">
          <v-radio label="Tersedia" value="available"></v-radio>
          <v-radio label="Rusak" value="broken"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-card-actions>
      <v-spacer />
      <v-btn
        outlined
        color="primary"
        class="text-none"
        @click="$emit('close-dialog')"
        >Batal</v-btn
      >
      <v-btn
        color="primary"
        class="text-none"
        depressed
        @click="isValidate ? saveData() : ''"
        >Simpan</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import Constant from '@/const';

const axios = require('axios');

export default {
  name: 'InputNewBed',
  data() {
    return {
      input: {
        bed: null,
        room: '',
        availability: 'available',
      },
      rooms: [],
      loading: false,
      bedNumber: [],
      isValidate: true,
    };
  },
  mounted() {
    this.getAllBedNumber();
  },
  methods: {
    validateBedNumber(val) {
      if (this.bedNumber.indexOf(parseInt(val)) >= 0) {
        this.isValidate = false;
        return 'Nomor bed sudah ada';
      } else {
        this.isValidate = true;
      }
    },
    getAllBedNumber() {
      axios
        .get(Constant.apiUrl.concat(`/master/bed`))
        .then(response => {
          const { data } = response.data;
          this.bedNumber = data.map(arr => arr.bed_number);
        })
        .catch(() => {
          this.bedNumber = [];
        });
    },
    saveData() {
      const payload = {
        bed_number: this.input.bed,
        id_room: this.input.room.value,
        availability: this.input.availability,
        timestamps: {
          created_by: this.$store.getters.userLoggedIn.id,
        },
      };
      axios
        .post(Constant.apiUrl.concat('/master/bed/'), payload)
        .then(() => {
          Swal.fire('Data Bed Berhasil Ditambah!', '', 'success');
          this.$emit('close-dialog');
        })
        .catch(error => {
          const message = error.response.data.pesan;
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: message,
          });
          this.$emit('close-dialog');
        });
    },
  },
};
</script>

<style></style>
