<template>
  <div class="main-drugs">
    <v-toolbar flat outlined>
      <v-container fluid>
        <v-row align="center">
          <v-col>
            <v-toolbar-title class="font-weight-bold float-left">
              Data Barang Farmasi
            </v-toolbar-title>
          </v-col>

          <v-spacer></v-spacer>

          <v-btn
            class="mr-2"
            color="grey"
            icon
            text
            @click="refreshAndAutoUpdate"
          >
            <v-icon>mdi-autorenew</v-icon>
          </v-btn>
          <v-text-field
            class="input"
            v-model="search"
            background-color="grey lighten-3"
            placeholder="Search"
            solo
            dense
            hide-details
            flat
            prepend-inner-icon="mdi-magnify"
            single-line
          ></v-text-field>
        </v-row>
      </v-container>
    </v-toolbar>

    <v-main>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-data-table
              :headers="table.headers"
              :items="items.dataDrugs.data"
              :expanded="expanded"
              :loading="items.dataDrugs.isLoading"
              single-expand
              item-key="id"
              class="expand"
              :items-per-page="itemsPerRow"
              hide-default-footer
              @click:row="
                (item, slot) => {
                  slot.expand(!slot.isExpanded);
                }
              "
            >
              <template v-slot:[`item.no`]="{ item }">
                <td>
                  {{
                    (table.pagination.page - 1) * itemsPerRow +
                      items.dataDrugs.data.indexOf(item) +
                      1
                  }}
                </td>
              </template>
              <template v-slot:[`item.isActive`]="{ item }">
                <td>
                  <v-switch
                    v-model="item.isActive"
                    :label="`${item.isActive ? 'Aktif' : 'Tidak'}`"
                    color="info"
                    @click="changeIsActive(item)"
                    inset
                    dense
                  ></v-switch>
                </td>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="mr-4 icon-style"
                      dense
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteItem(item)"
                      color="red"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span> Hapus </span>
                </v-tooltip>
              </template>
              <template v-slot:expanded-item="{ item, headers }">
                <td :colspan="headers?.length" class="expand px-0">
                  <v-card flat>
                    <v-container>
                      <v-row no-gutters>
                        <v-col
                          cols="2"
                          class="text-left text--darken-2 caption"
                        >
                          <span
                            ><li>
                              Formulari:
                              <span class="font-weight-medium">{{
                                item.isFormulary ? 'Ya' : 'Tidak'
                              }}</span>
                            </li>
                          </span></v-col
                        >

                        <v-col
                          cols="2"
                          class="text-left text--darken-2 caption"
                        >
                          <span
                            ><li>
                              Fornas:<span class="font-weight-medium">
                                {{ item.isFornas ? 'Ya' : 'Tidak' }}</span
                              >
                            </li>
                          </span></v-col
                        >
                        <v-col cols="2" class="text-left text--darken-2 caption"
                          ><li>
                            Obat Keras:<span class="font-weight-medium">
                              {{ item.isPotent ? 'Ya' : 'Tidak' }}</span
                            >
                          </li>
                        </v-col>
                        <v-col cols="2" class="text-left text--darken-2 caption"
                          ><li>
                            Generic:<span class="font-weight-medium">
                              {{ item.isGeneric ? 'Ya' : 'Tidak' }}</span
                            >
                          </li>
                        </v-col>
                        <v-col class="text-left text--darken-2 caption"
                          ><li>
                            Katastropik :
                            <span class="font-weight-medium">
                              {{ item.isCatastrophic ? 'Ya' : 'Tidak' }}</span
                            >
                          </li>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                  <v-card flat color="grey lighten-4" class="text-right">
                    <v-container>
                      <v-row no-gutters>
                        <v-col cols="12">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                x-small
                                icon
                                class="mx-2 hover"
                                @click="openDialog(item, 'detail')"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon>mdi-clipboard-text</v-icon>
                              </v-btn>
                            </template>
                            <span>Detail Barang</span>
                          </v-tooltip>

                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                x-small
                                icon
                                class="mx-2 hover"
                                v-bind="attrs"
                                v-on="on"
                                @click="openDialog(item, 'edit')"
                              >
                                <v-icon>mdi-clipboard-plus</v-icon>
                              </v-btn>
                            </template>

                            <span>Ubah Barang</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-footer color="white">
      <v-container fluid>
        <v-row>
          <v-pagination
            v-model="table.pagination.page"
            :length="drugsPages"
            :total-visible="10"
            @input="getAllDrugsQueue()"
          ></v-pagination>
          <v-spacer></v-spacer>

          <template>
            <v-btn color="primary" fab dark @click="openDialog(null, 'add')">
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </template>
        </v-row>
      </v-container>
    </v-footer>

    <v-dialog v-model="dialog" max-width="1200" presistent>
      <FormDrugs
        v-if="dialog"
        ref="formDrugs"
        @close-dialog="closeDialog"
        :state="formState"
      />
    </v-dialog>
  </div>
</template>

<script>
import FormDrugs from './components/Drugs/FormDrugs.vue';
import jwtMixin from '@/mixin/jwtMixin';
import alertMixin from '@/mixin/alertMixin';
import Swal from 'sweetalert2';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(
  'MasterDrugs',
);

const _ = require('lodash');

export default {
  name: 'MasterDataDrugs',
  mixins: [jwtMixin, alertMixin],
  components: {
    FormDrugs,
  },
  data() {
    return {
      expanded: [],
      drugsPages: 0,
      formState: 'add',
      table: {
        headers: [
          { text: 'No', align: 'start', value: 'no' },
          {
            text: 'Nama',
            align: 'start',
            value: 'name',
          },
          { text: 'Kategori', value: 'category', sortable: false },
          { text: 'Kekuatan', value: 'strength', sortable: false },
          { text: 'Sediaan', value: 'preparation', sortable: false },
          { text: 'Pabrik', value: 'factory', sortable: false },
          { text: 'Hpp', value: 'hpp', sortable: false },
          { text: 'Status', value: 'isActive', sortable: false },
          { text: '', value: 'actions', sortable: false },
        ],
        pagination: {
          descending: false,
          page: 1,
          rowsPerPage: 5,
          sortBy: '',
        },
      },
      search: '',
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshAndAutoUpdate();
    });
  },
  watch: {
    search() {
      this.searchData(this);
      this.table.pagination.page = 1;
    },
    dialog: {
      handler() {
        this.getDialog === false && this.getAllDrugsQueue();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['getItems', 'getDialog']),
    items: {
      get() {
        return this.getItems;
      },
    },
    dialog: {
      get() {
        return this.getDialog;
      },
      set(val) {
        return this.setDialog(val);
      },
    },
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
  },
  methods: {
    ...mapMutations(['setDialog', 'setForm']),
    ...mapActions([
      'resolveGetAllDrugs',
      'resolveDeleteDrugs',
      'resolvePutStatusDrugs',
    ]),
    searchData: _.debounce(v => {
      v.getAllDrugsQueue();
    }, 650),
    openDialog(item, state) {
      this.setDialog(true);
      this.formState = state;
      this.$refs?.formDrugs?.$refs?.form?.reset();
      switch (state) {
        case 'add':
          break;
        case 'edit':
          this.setForm({ id: item.id });
          break;
        case 'detail':
          this.setForm({ id: item.id });
          break;
      }
    },
    closeDialog() {
      this.setDialog(false);
    },

    changeIsActive(item) {
      this.resolvePutStatusDrugs({
        id_drug: item.id,
        isActive: item.isActive,
      }).catch(error => this.showErrorAxios(error));
    },

    getAllDrugsQueue() {
      const itemCount = this.itemsPerRow;
      const halaman = this.table.pagination.page;
      let sortBy = '';
      let keyword = this.search;
      this.resolveGetAllDrugs({
        halaman,
        itemCount,
        sortBy,
        keyword,
      }).then(item => {
        this.drugsPages = Math.ceil(item / itemCount);
      });
    },
    deleteItem(item) {
      Swal.fire({
        title: 'Yakin?',
        html: `Anda akan menghapus Data <br> <b>"${item.name}"<b/>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak',
      }).then(result => {
        if (result.value) {
          this.resolveDeleteDrugs(item.id).then(res => {
            if (res.status === 200) {
              Swal.fire('Berhasil', `${item.name} berhasil dilapus`, 'success');
              this.getAllDrugsQueue();
            } else {
              Swal.fire(
                `${item.name} gagal dihapus`,
                res?.response?.data?.pesan,
                'error',
              );
            }
          });
        }
      });
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.getAllDrugsQueue();
      this.interval = setInterval(this.getAllDrugsQueue, 60000);
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.input {
  max-width: 30%;
}

.hover:hover {
  color: #2196f3;
}

::v-deep {
  .v-data-table
    > .v-data-table__wrapper
    tbody
    tr.v-data-table__expanded__content {
    box-shadow: none;
    background-color: #f4f5f5;
  }
  .v-data-table-header {
    background-color: #f4f5f5;
  }

  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border: none;
  }
}
</style>
