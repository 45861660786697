<template>
  <div>
    <v-toolbar flat height="73">
      <v-container fluid>
        <v-row align="center">
          <v-col md="5" lg="7" class="text-left">
            <h2>Booking Pendaftaran Rawat Jalan</h2>
          </v-col>
          <v-btn depressed @click="filterOpen = !filterOpen" color="#F4F5F5">
            <img src="@/assets/image/ic_filter.png" class="mr-2" />
            <span class="black--text text-capitalize">Filters</span>
          </v-btn>
          <v-btn class="mx-2" color="grey" icon text @click="getBookingData">
            <v-icon>mdi-autorenew</v-icon>
          </v-btn>
          <v-text-field
            class="input"
            background-color="grey lighten-3"
            placeholder="Cari berdasarkan nama pasien, dokter atau RM..."
            solo
            dense
            hide-details
            flat
            prepend-inner-icon="mdi-magnify"
            single-line
            v-model="search"
          ></v-text-field>
        </v-row>
      </v-container>
    </v-toolbar>
    <template v-if="filterOpen === true">
      <v-row justify="end" class="px-3 pb-3">
        <v-col cols="2">
          <v-select
            placeholder="Nama Klinik"
            dense
            outlined
            :items="unit"
            item-text="unitName"
            item-value="unitId"
            v-model="unitFilter"
            @change="getBookingData()"
            hide-details="false"
            clearable
          ></v-select>
        </v-col>
        <v-col cols="2">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="Tanggal Awal"
                outlined
                prepend-inner-icon="mdi-calendar-month"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                hide-details="false"
                clearable
                @change="getBookingData()"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              no-title
              scrollable
              @input="
                $refs.menu.save(date);
                menu = false;
              "
            />
          </v-menu>
        </v-col>
        <v-col cols="1" class="pt-5 pl-0 pr-0" style="max-width: 30px">
          <v-icon>mdi-minus</v-icon>
        </v-col>
        <v-col cols="2">
          <v-menu
            ref="menuLast"
            v-model="menuLast"
            :close-on-content-click="false"
            :return-value.sync="dateLast"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateLast"
                label="Tanggal Akhir"
                outlined
                prepend-inner-icon="mdi-calendar-month"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                hide-details="false"
                clearable
                @change="getBookingData()"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateLast"
              no-title
              scrollable
              @input="
                $refs.menuLast.save(dateLast);
                menuLast = false;
              "
            />
          </v-menu>
        </v-col>
      </v-row>
    </template>
    <v-divider></v-divider>
    <v-data-table
      :headers="booking.headers"
      :items="booking.items"
      single-expand
      :expanded="expanded"
      item-key="noRM"
      hide-default-footer
      :search="search"
      @click:row="
        (item, slot) => {
          slot.expand(!slot.isExpanded);
          selectItem(item);
        }
      "
      :loading="isLoading"
    >
      <template v-slot:[`item.no`]="{ item }">
        <tr class="text-left">
          <td>
            {{ booking.items.indexOf(item) + 1 }}
          </td>
        </tr>
      </template>

      <template v-slot:expanded-item="{ item, headers }">
        <td
          @click="selectItem(item)"
          :colspan="headers.length"
          class="expand px-0"
        >
          <v-card flat color="grey lighten-4" class="text-right">
            <v-container>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-tooltip bottom>
                    <template #activator="{ on: tooltip }">
                      <v-btn
                        x-small
                        icon
                        class="mx-2 hover"
                        v-on="{ ...tooltip }"
                        @click="onClicked(0)"
                      >
                        <v-icon>mdi-square-edit-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit Dokumen</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        icon
                        class="mx-2 hover"
                        v-bind="attrs"
                        v-on="on"
                        color="red"
                        @click="deleteData()"
                      >
                        <v-icon>mdi-close-thick</v-icon>
                      </v-btn>
                    </template>
                    <span>Hapus Jadwal</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </td>
      </template>
    </v-data-table>
    <v-card-actions>
      <v-pagination class="page"></v-pagination>
      <v-spacer></v-spacer>

      <v-btn
        fab
        color="primary"
        elevation="0"
        class="btn-add"
        @click="onClicked(2)"
      >
        <v-icon> mdi-account-plus </v-icon>
      </v-btn>
    </v-card-actions>
    <v-dialog
      v-model="dialog"
      :max-width="selectedForm === null ? 0 : widthDialog[selectedForm].width"
    >
      <SocialDataShow
        v-if="selectedForm === 0"
        @close-form="dialog = false"
        :medical-record="selectedPatient.rmNumber"
        :patient-data="selectedPatient"
      />
      <SocialDataPatients
        :selected-patient="patientSelected"
        @back-to-form="dialog = true"
        @update-data="getBookingData"
        @back-dialog="
          selectedForm = 2;
          searchPatient = '';
        "
        @close-form="closeForm"
        v-else-if="selectedForm === 1"
        :patient-status="'rawat jalan'"
        :isBooking="true"
      />
      <!-- <OldNewDialog v-if="selectedForm === 2 && dialog" @on-clicked="getData" /> -->
      <OldNewDialog
        v-if="selectedForm === 2 && dialog"
        @on-clicked="getData"
        @close-form="closeForm"
        @to-regist="onClicked(1)"
      />
      <SearchDataPatient
        v-else-if="selectedForm === 3 && dialog"
        @close-form="closeForm"
        @on-clicked="getData"
        @back="
          selectedForm = 2;
          searchPatient = '';
        "
        @to-regist="
          patientSelected = null;
          onClicked(1);
        "
      />
    </v-dialog>
  </div>
</template>

<script>
import SocialDataShow from './components/Booking/SocialDataShow.vue';
import SocialDataPatients from './components/SocialDataPatients.vue';
import Swal from 'sweetalert2';
// import OldNewDialog from './components/OldNewDialog.vue';
import OldNewDialog from './components/OldNewDialogV2.vue';
import SearchDataPatient from './components/SearchDataPatient.vue';
import Constant from '@/const';
import moment from 'moment-timezone';
import jwtMixin from '@/mixin/jwtMixin';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'OutpatientBook',
  mixins: [jwtMixin],
  components: {
    OldNewDialog,
    SocialDataShow,
    SocialDataPatients,
    SearchDataPatient,
  },
  data() {
    return {
      searchPatient: '',
      booking: {
        headers: [
          { text: 'No.', value: 'no' },
          { text: 'Tgl Kunjungan', value: 'date' },
          { text: 'Nama Pasien', value: 'name' },
          { text: 'No. RM', value: 'noRM' },
          { text: 'Klinik', value: 'poly', sortable: false },
          { text: 'Nama Dokter', value: 'doctor', sortable: false },
          { text: 'No. Antrian', value: 'noQueue', sortable: false },
          { text: 'Tipe Penjamin', value: 'guarantorType', sortable: false },
          { text: 'Nama Petugas', value: 'officerName', sortable: false },
        ],
        items: [],
      },
      date: moment(new Date())
        .add(1, 'd')
        .locale('id')
        .format('YYYY-MM-DD'),
      menu: false,
      dateLast: null,
      menuLast: false,
      filterOpen: false,
      dialog: false,
      selectedForm: null,
      widthDialog: [
        { text: 'SocialData', width: 1200 },
        { text: 'SocialDataPatients', width: 1200 },
        { text: 'OldNewPatient', width: 600 },
        { text: 'SearchDataPatient', width: 1200 },
      ],
      unit: [],
      unitFilter: '',
      expanded: [],
      search: '',
      isLoading: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshAndAutoUpdate();
    });
  },
  computed: {
    selectedPatient() {
      if (this.$store.getters.selectedPatient !== null) {
        return this.$store.getters.selectedPatient;
      }
      return null;
    },
  },
  methods: {
    getData(val) {
      if (val === null) {
        this.patientSelected = val;
        this.onClicked(1);
      } else {
        this.patientSelected = {
          status: 'old',
          rmNumber: val,
        };
        if (val !== null && this.selectedForm === 3) {
          this.onClicked(1);
        } else {
          this.onClicked(3);
        }
      }
    },
    deleteData() {
      Swal.fire({
        html:
          '<p style="font-size:20px; font-weight:500;">Apakah Anda yakin ingin membatalkan jadwal pendaftaran pasien?</p>',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batal',
        reverseButtons: true,
      }).then(result => {
        if (result.isConfirmed) {
          Swal.fire('Jadwal pasien berhasil dihapus!', '', 'success');
        }
      });
    },
    onClicked(val) {
      this.selectedForm = val;
      this.dialog = true;
    },
    getBookingData() {
      this.isLoading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/patient?page=1&itemCount=8&startDate=${this.date ||
              ''}&endDate=${this.dateLast || ''}&polyclinic=${this.unitFilter ||
              ''}&doctor=`,
          ),
        )
        .then(response => {
          const booking = response.data.data;
          this.booking.items = booking.map((patient, index) => {
            this.getStaffById(patient.timestamps.created_by, index);
            return {
              idRegistration: patient._id.toString(),
              //registration
              noRegist: patient.registration.registration_number.toString(),
              polyId: patient.registration.id_unit._id,
              poly: patient.registration.id_unit.name,
              doctorId: patient.registration.id_staff._id,
              doctor: patient.registration.id_staff.name,
              noQueue: patient.registration.queue.number.toString(),
              isFinish:
                patient.registration.queue.isFinish === true ? 'Ya' : 'Tidak',
              time: patient.registration.time,
              date: moment(patient.registration.date).format('YYYY/MM/DD'),
              visitDate: moment(patient.registration.date).format('YYYY-MM-DD'),
              //medical record
              idPatient: patient.medical_record._id.toString(),
              noRM: patient.medical_record.medical_record_number,
              name: patient.medical_record.name,
              birthDate: moment(patient.medical_record.birth.date)
                .locale('id')
                .format('DD/MM/YYYY'),
              birthPlace: patient.medical_record.birth.place,
              age: patient.medical_record.age,
              gender:
                patient.medical_record.gender === true
                  ? 'Laki-laki'
                  : 'Perempuan',
              address: `${patient.address.text}, ${patient.address.sub_district}, ${patient.address.district}, ${patient.address.city}, ${patient.address.province}`,
              identityType: patient.identity.type,
              identityNumber: patient.identity.number,
              profession: patient.profession,
              //assurance
              guarantorType: _.startCase(patient.assurance.type),
              guarantorName: patient.assurance.name,
              guarantorNumber: patient.assurance.number,
              isProlanis: patient.assurance.isProlanis,
              //allergy
              allergy: patient.allergy,
              registration: patient.registration,
              officerName: '',
            };
          });
        })
        .catch(() => {
          this.booking.items = [];
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    selectItem(patient) {
      const patientData = {
        id_emr: patient.idPatient,
        id_registration: patient.idRegistration,
        rmNumber: patient.noRM,
        noQueue: patient.noQueue,
        meta: {
          doctor: {
            name: patient.doctor,
            id: patient.doctorId,
          },
          poly: {
            name: patient.poly,
            id: patient.polyId,
          },
          profession: patient.profession,
          visitDate: patient.visitDate,
        },
        registration: patient.registration,
      };
      this.$store.commit('changeSelectedPatient', patientData);
    },
    getUnit() {
      axios
        .get(
          Constant.apiUrl.concat(
            '/master/unit?page=1&itemCount=8&search=&sort=name&installation=Instalasi Rawat Jalan',
          ),
        )
        .then(response => {
          if (response.status === 200) {
            this.unit = response.data.data.map(unit => {
              return {
                unitId: unit._id.toString(),
                unitName: unit.name,
              };
            });
          }
        });
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.getBookingData();
      this.getUnit();
      this.interval = setInterval(this.getBookingData, 60000);
    },
    closeForm() {
      this.dialog = false;
      this.refreshAndAutoUpdate();
    },
    async getStaffById(id, index) {
      const res = await axios.get(
        Constant.apiUrl.concat(`/master/staff/find/${id}`),
      );
      const { data } = res.data;
      this.booking.items[index].officerName = data.detail.name;
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border: none;
}
.btn-add {
  position: absolute;
  bottom: 50px;
  right: 30px;
}
.page {
  position: absolute;
  bottom: 50px;
}
::v-deep .v-dialog:not(.v-dialog--fullscreen) {
  max-height: 95%;
}
::v-deep i.v-icon.notranslate.mdi.mdi-calendar-month.theme--light {
  color: #3498db;
}
</style>
