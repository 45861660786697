<template>
  <v-card class="main-container">
    <v-card-title class="justify-space-between">
      <v-col class="pa-0">
        <h3>Ubah Data Supplier</h3>
      </v-col>
      <v-col class="pa-0" cols="1">
        <v-btn icon @click="$emit('close-form')"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-col>
    </v-card-title>
    <v-row no-gutters justify="center" class="py-5">
      <v-form lazy-validation ref="form">
        <div class="form-style">
          <label class="font-weight-bold body-2">Nama Supplier</label>
          <v-text-field
            placeholder="Masukkan Nama Supplier"
            dense
            v-model="input.name"
            :rules="[v => !!v || 'Wajib diisi']"
          ></v-text-field>

          <label class="font-weight-bold body-2">Alamat</label>
          <v-textarea
            placeholder="Masukkan Alamat"
            outlined
            dense
            rows="4"
            no-resize
            v-model="input.address"
          ></v-textarea>

          <label class="font-weight-bold body-2">No. Telepon</label>
          <v-text-field
            placeholder="Masukkan Nomor Telepon"
            dense
            v-model="input.telp"
          ></v-text-field>

          <label class="font-weight-bold body-2">E-mail</label>
          <v-text-field
            placeholder="Masukkan Alamat Email"
            dense
            v-model="input.email"
          ></v-text-field>

          <label class="font-weight-bold body-2">Tanggal Jatuh Tempo</label>
          <v-row no-gutters align="baseline">
            <v-col class="pa-0" cols="4">
              <v-text-field
                dense
                class="pr-3"
                v-model.number="input.dueDate"
              ></v-text-field>
            </v-col>
            <v-col class="pa-0">
              <label>hari</label>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </v-row>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        depressed
        class="text-none"
        width="110"
        @click="editData()"
        >Simpan</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import Constant from '@/const';
import alertMixin from '@/mixin/alertMixin';

const axios = require('axios');

export default {
  name: 'EditSupplier',
  props: {
    selectedItem: {
      type: Object,
    },
  },
  mixins: [alertMixin],
  data() {
    return {
      input: {
        name: '',
        address: '',
        telp: '',
        email: '',
        dueDate: '',
      },
    };
  },
  mounted() {
    this.input = {
      ...this.selectedItem,
    };
  },
  methods: {
    editData() {
      if (this.$refs.form.validate()) {
        const payload = {
          name: this.input.name,
          detail: [
            {
              address: this.input.address,
              email: this.input.email,
              phone: this.input.telp,
              bill_due: this.input.dueDate,
            },
          ],
          timestamps: {
            created_by: this.$store.getters.userLoggedIn.id,
          },
        };
        axios
          .put(
            Constant.apiUrl.concat(`/master/supplier/${this.selectedItem.id}`),
            payload,
          )
          .then(() => {
            Swal.fire('Data Supplier Berhasil Diubah!', '', 'success');
            this.$emit('close-form');
          })
          .catch(err => {
            this.showErrorAxios(err);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-style {
  display: grid;
  grid-template-columns: 10vw 20vw;
  text-align: left;
  align-items: baseline;
}

.main-container {
  width: 35vw;
}
</style>
