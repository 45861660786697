<template>
  <div class="main-diagnose">
    <v-toolbar flat outlined>
      <v-container fluid>
        <v-row align="center">
          <v-col>
            <v-toolbar-title class="font-weight-bold float-left">
              Data Diagnosa
            </v-toolbar-title>
          </v-col>

          <v-spacer></v-spacer>

          <v-btn
            class="mr-2"
            color="grey"
            icon
            text
            @click="refreshAndAutoUpdate"
          >
            <v-icon>mdi-autorenew </v-icon>
          </v-btn>
          <v-text-field
            class="input"
            background-color="grey lighten-3"
            placeholder="Search"
            v-model="search"
            solo
            dense
            hide-details
            flat
            prepend-inner-icon="mdi-magnify"
            single-line
          ></v-text-field>
        </v-row>
      </v-container>
    </v-toolbar>

    <v-main>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-data-table
              :headers="table.headers"
              :items="diagnose"
              :expanded="expanded"
              :loading="loading"
              single-expand
              item-key="id"
              class="expand"
              :items-per-page="itemsPerRow"
              hide-default-footer
              @click:row="
                (item, slot) => {
                  slot.expand(!slot.isExpanded);
                  selectItem(item);
                }
              "
            >
              <template v-slot:expanded-item="{ item, headers }">
                <td
                  @click="selectItem(item)"
                  :colspan="headers.length"
                  class="expand"
                >
                  <v-card flat color="grey lighten-4" class="text-right">
                    <v-container>
                      <v-row no-gutters>
                        <v-col cols="12">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                x-small
                                icon
                                class="mx-2 hover"
                                @click="detailDialog = true"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon>mdi-clipboard-text</v-icon>
                              </v-btn>
                            </template>
                            <span>Detail Diagnosa</span>
                          </v-tooltip>
                          <v-dialog v-model="detailDialog" max-width="1200">
                            <detail-diagnose
                              @close-dialog="closeDialog"
                              v-if="selectedItem && detailDialog"
                              :diagnoseDetail="selectedItem"
                            />
                          </v-dialog>

                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                x-small
                                icon
                                class="mx-2 hover"
                                v-bind="attrs"
                                v-on="on"
                                @click="updateDialog = true"
                              >
                                <v-icon>mdi-clipboard-plus</v-icon>
                              </v-btn>
                            </template>

                            <span>Ubah Diagnosa</span>
                          </v-tooltip>

                          <v-dialog v-model="updateDialog" max-width="1200">
                            <update-diagnose
                              @close-dialog="closeDialog"
                              @update-data="getAllDiagnoseQueue"
                              v-if="selectedItem && updateDialog"
                              :diagnoseDetail="selectedItem"
                            />
                          </v-dialog>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                x-small
                                icon
                                class="mx-2 hover"
                                v-bind="attrs"
                                v-on="on"
                                @click="
                                  deleteUnit(item);
                                  openSnackbar();
                                "
                              >
                                <v-icon>mdi-close-thick</v-icon>
                              </v-btn>
                            </template>

                            <span>Hapus Diagnosa</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-spacer></v-spacer>

    <v-footer color="white">
      <v-container fluid>
        <v-row>
          <v-pagination
            v-model="table.pagination.page"
            :length="diagnosePages"
            total-visible="10"
            @input="getAllDiagnoseQueue()"
          ></v-pagination>
          <v-spacer></v-spacer>

          <template>
            <v-dialog v-model="dialog" max-width="1200px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" fab dark v-bind="attrs" v-on="on">
                  <v-icon dark>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </template>
              <InputNewDiagnose v-if="dialog" @close-dialog="closeDialog" />
            </v-dialog>
          </template>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import InputNewDiagnose from './components/Diagnose/InputNewDiagnose';
import Constant from '@/const';
import Swal from 'sweetalert2';
import UpdateDiagnose from './components/Diagnose/UpdateDiagnose.vue';
import DetailDiagnose from './components/Diagnose/DetailDiagnose.vue';
import jwtMixin from '@/mixin/jwtMixin';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'MasterdataDiagnose',
  mixins: [jwtMixin],
  components: {
    InputNewDiagnose,
    UpdateDiagnose,
    DetailDiagnose,
  },
  data() {
    return {
      loading: true,
      expanded: [],
      dialog: false,
      detailDialog: false,
      updateDialog: false,
      diagnose: [],
      diagnosePages: 0,
      selectedItem: null,
      table: {
        headers: [
          { text: 'Kode', value: 'code' },
          { text: 'Deskripsi', value: 'description' },
        ],
        pagination: {
          descending: false,
          page: 1,
          rowsPerPage: 7,
          sortBy: 'kode',
        },
      },
      search: '',
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshAndAutoUpdate();
    });
  },
  watch: {
    search() {
      this.searchData(this);
      this.table.pagination.page = 1;
    },
  },
  computed: {
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
  },
  methods: {
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.getAllDiagnoseQueue();
    }, 650),
    closeDialog() {
      this.dialog = false;
      this.detailDialog = false;
      this.updateDialog = false;
    },
    selectItem(data) {
      this.selectedItem = data;
    },

    deleteUnit(data) {
      Swal.fire({
        title: 'Yakin?',
        text: `Anda akan menghapus ${data.description}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak',
      }).then(result => {
        if (result.value) {
          axios
            .delete(`${Constant.apiUrl}/master/diagnose/${data.id}`)
            .then(response => {
              if (response.status === 200) {
                Swal.fire('Berhasil', `${response.data.pesan}`, 'success');
                this.getAllDiagnoseQueue();
                this.onCloseButtonClick();
              }
            });
        }
      });
    },

    getAllDiagnoseQueue() {
      const itemCount = this.itemsPerRow;
      const halaman = this.table.pagination.page;
      let sortBy = '';
      const keyword = this.search;
      this.loading = true;

      this.runApiCalls(() => {
        axios
          .get(
            `${Constant.apiUrl}/master/diagnose?page=${halaman}&itemCount=${itemCount}&sort=${sortBy}&search=${keyword}`,
          )
          .then(response => {
            const diagnoseDataGet = response.data.data;
            this.diagnosePages = Math.ceil(response.data.length / itemCount);

            this.diagnose = diagnoseDataGet.map(diagnose => {
              return {
                id: diagnose._id.toString(),
                code: diagnose.code.toString(),
                description: diagnose.description,
              };
            });
            this.loading = false;
          })
          .catch(() => {
            this.diagnose = [];
            this.diagnosePages = 1;
            this.loading = false;
          });
      });
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.getAllDiagnoseQueue();
      this.interval = setInterval(this.getAllDiagnoseQueue, 60000);
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.input {
  max-width: 30%;
}

.hover:hover {
  color: #2196f3;
}

::v-deep {
  .v-data-table
    > .v-data-table__wrapper
    tbody
    tr.v-data-table__expanded__content {
    box-shadow: none;
    background-color: #f4f5f5;
  }

  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border: none;
  }
}
</style>
