<template>
  <div class="main-drugs">
    <v-toolbar flat outlined>
      <v-container fluid>
        <v-row align="center">
          <v-col>
            <v-toolbar-title class="font-weight-bold float-left">
              Data Staf
            </v-toolbar-title>
          </v-col>

          <v-spacer></v-spacer>

          <v-btn class="mr-2" color="grey" icon text @click="getAllStaffQueue">
            <v-icon>mdi-autorenew</v-icon>
          </v-btn>
          <v-form autocomplete="off" onsubmit="return false;">
            <v-text-field
              class="input"
              background-color="grey lighten-3"
              placeholder="Search"
              v-model="search"
              solo
              dense
              hide-details
              flat
              prepend-inner-icon="mdi-magnify"
              single-line
            ></v-text-field>
          </v-form>
        </v-row>
      </v-container>
    </v-toolbar>

    <v-main>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-data-table
              :headers="table.headers"
              :items="items.dataStaff.data"
              :loading="items.dataStaff.isLoading"
              :expanded="expanded"
              single-expand
              item-key="nip"
              class="expand"
              :items-per-page="itemsPerRow"
              hide-default-footer
              @click:row="
                (item, slot) => {
                  slot.expand(!slot.isExpanded);
                  selectItem(item);
                }
              "
            >
              <template v-slot:item="{ item, expand, isExpanded }">
                <tr
                  class="text-left"
                  :key="item.index"
                  @click="expand(!isExpanded)"
                >
                  <td>{{ item.no }}</td>
                  <td>{{ item.nip }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.phone_no }}</td>
                  <td>{{ item.specialist }}</td>
                  <td class="text-capitalize">{{ item.role.role }}</td>
                  <td>
                    <v-switch
                      inset
                      dense
                      light
                      @click.stop="changeStaffStatus(item)"
                      :label="item.role.status ? 'Aktif' : 'Nonaktif'"
                      v-model="item.role.status"
                    ></v-switch>
                  </td>
                </tr>
              </template>
              <template v-slot:expanded-item="{ item, headers }">
                <td :colspan="headers.length" class="expand">
                  <v-card flat color="grey lighten-4" class="text-right">
                    <v-container class="py-0">
                      <v-row no-gutters>
                        <v-col cols="12">
                          <v-tooltip bottom>
                            <template #activator="{ on: tooltip }">
                              <v-btn
                                icon
                                class="mx-2 hover"
                                v-on="{ ...tooltip }"
                                @click="selectItem(0, item, 'detail')"
                              >
                                <v-icon small> mdi-account </v-icon>
                              </v-btn>
                            </template>
                            <span>Detail Staf</span>
                          </v-tooltip>

                          <v-tooltip bottom>
                            <template #activator="{ on: tooltip }">
                              <v-btn
                                icon
                                class="mx-2 hover"
                                v-on="{ ...tooltip }"
                                @click="selectItem(0, item, 'edit')"
                              >
                                <v-icon small> mdi-clipboard-text </v-icon>
                              </v-btn>
                            </template>
                            <span>Ubah Staf</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template #activator="{ on: tooltip }">
                              <v-btn
                                v-show="isAdmin"
                                icon
                                class="mx-2 hover"
                                v-on="{ ...tooltip }"
                                @click="selectItem(2, item)"
                              >
                                <v-icon small> mdi-shield-remove </v-icon>
                              </v-btn>
                            </template>
                            <span>Ubah Hak Akses</span>
                          </v-tooltip>

                          <v-tooltip bottom>
                            <template #activator="{ on: tooltip }">
                              <v-btn
                                icon
                                class="mx-2 hover"
                                v-on="{ ...tooltip }"
                                @click="selectItem(1, item)"
                              >
                                <v-icon small> mdi-lock </v-icon>
                              </v-btn>
                            </template>
                            <span>Ganti Kata Sandi</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-footer color="white">
      <v-container fluid>
        <v-row>
          <v-pagination
            v-model="table.pagination.page"
            :length="staffPages"
            @input="getAllStaffQueue()"
          ></v-pagination>
          <v-spacer></v-spacer>

          <v-btn
            @click="selectItem(0, null, 'add')"
            depressed
            color="primary"
            fab
            dark
          >
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
          <v-dialog
            v-model="dialog"
            :max-width="maxWidthDialog[selectedForm]"
            :fullscreen="selectedForm === 2"
            persistent
          >
            <form-master-data-staff
              v-if="dialog && selectedForm === 0"
              @close-dialog="closeDialog"
              @update-data="getAllStaffQueue"
            />
            <ChangePassword
              v-if="dialog && selectedForm === 1"
              :data-staff="selectedItem"
              @close-dialog="closeDialog"
              state="master"
            />
            <AccessRights
              @close-dialog="closeDialog"
              :staffDetail="selectedItem"
              v-if="dialog && selectedForm === 2"
            />
          </v-dialog>
        </v-row>
      </v-container>
    </v-footer>
    <v-snackbar timeout="2500" v-model="snackbar.show" :color="snackbar.color">
      <span class="pa-0 mr-15">{{ snackbar.text }}</span>
      <v-btn
        small
        depressed
        fab
        text
        color="primary"
        class="mr-n10"
        @click.native="snackbar.show = false"
        ><v-icon color="white" small>mdi-close</v-icon></v-btn
      >
    </v-snackbar>
  </div>
</template>

<script>
import FormMasterDataStaff from './components/Staff/FormMasterDataStaff';
import AccessRights from './components/Staff/AccessRights.vue';
import ChangePassword from './components/Staff/ChangePassword.vue';
import jwtMixin from '@/mixin/jwtMixin';
import putStatusStaff from '@/fetchApi/MasterData/Staff/putStatusStaff';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(
  'MasterStaff',
);

const _ = require('lodash');

export default {
  name: 'MasterDataStaff',
  mixins: [jwtMixin],
  components: {
    FormMasterDataStaff,
    AccessRights,
    ChangePassword,
  },
  data() {
    return {
      loading: true,
      selectedItem: null,
      selectedForm: null,
      snackbar: {
        color: '',
        text: '',
        show: false,
      },
      staffs: [],
      staffPages: 0,
      expanded: [],
      table: {
        headers: [
          {
            text: 'No',
            value: 'no',
            sortable: false,
            width: '1%',
          },
          {
            sortable: false,
            text: 'NIP',
            align: 'start',
            width: '10%',
            value: 'nip',
          },
          { width: '35%', sortable: false, text: 'Nama', value: 'name' },
          { sortable: false, text: 'Telepon', value: 'phone_no' },
          { sortable: false, text: 'Spesialis', value: 'specialist' },
          { sortable: false, text: 'Role', value: 'role' },
          { sortable: false, text: 'Status', value: 'status' },
        ],
        pagination: {
          descending: false,
          page: 1,
          rowsPerPage: 5,
          sortBy: '',
        },
      },
      search: '',
      maxWidthDialog: ['1200px', '390px'],
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.refreshAndAutoUpdate();
    });
  },
  watch: {
    search() {
      this.searchData(this);
      this.table.pagination.page = 1;
    },
  },
  computed: {
    ...mapGetters(['getItems', 'getDialog']),
    items: {
      get() {
        return this.getItems;
      },
    },
    dialog: {
      get() {
        return this.getDialog;
      },
      set(val) {
        return this.setDialog(val);
      },
    },
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
    isAdmin() {
      return (
        this.$store.getters.userLoggedIn.role.toLowerCase() === 'administrator'
      );
    },
  },
  methods: {
    ...mapMutations(['setDialog', 'setFormType']),
    ...mapActions(['resolveGetAllStaff', 'resolveGetStaffById']),
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.getAllStaffQueue();
    }, 650),
    closeDialog() {
      this.setDialog(false);
    },
    selectItem(form, item, type) {
      this.setFormType(type);
      if (type === 'detail' || type === 'edit')
        this.resolveGetStaffById({ id_staff: item.id });
      this.selectedForm = form;
      this.selectedItem = item;
      this.setDialog(true);
    },
    async changeStaffStatus(data) {
      try {
        const payload = {
          role: {
            status: data.role.status,
          },
        };
        await putStatusStaff(data.id, payload);
        this.snackbar = {
          color: 'success',
          text: 'Berhasil mengubah status staf',
          show: true,
        };
      } catch {
        this.snackbar = {
          color: 'error',
          text: 'Terjadi kesalahan, gagal mengubah status staf',
          show: true,
        };
      } finally {
        this.refreshAndAutoUpdate();
      }
    },
    getAllStaffQueue() {
      const itemCount = this.itemsPerRow;
      const halaman = this.table.pagination.page;
      let sortBy = '';
      let keyword = this.search;
      this.resolveGetAllStaff({
        halaman,
        itemCount,
        sortBy,
        keyword,
      }).then(item => {
        this.staffPages = Math.ceil(item / itemCount);
      });
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.getAllStaffQueue();
      this.interval = setInterval(this.getAllStaffQueue, 60000);
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.input {
  max-width: 100%;
}

.hover:hover {
  color: #2196f3;
}

::v-deep {
  .v-data-table
    > .v-data-table__wrapper
    tbody
    tr.v-data-table__expanded__content {
    box-shadow: none;
    background-color: #f4f5f5;
  }

  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border: none;
  }
}
</style>
