<template>
  <v-card class="pa-5 main-card">
    <div class="d-flex justify-space-between mb-2">
      <h2>Penjualan Obat Bebas</h2>
      <v-btn text fab width="35" height="35" @click="$emit('close-form')"
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </div>
    <v-row>
      <v-col cols="12">
        <v-tabs v-model="tab" color="primary" grow slider-color="primary">
          <v-tab class="text-capitalize">
            Penjualan Obat Non Racik
          </v-tab>
          <v-tab class="text-capitalize">
            Penjualan Obat Racik
          </v-tab>
          <v-tab class="text-capitalize">
            Penjualan Alkes dan BHP
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <div class="input-container">
              <v-row class="mb-5">
                <v-col cols="4" class="text-left pt-7 pl-7">
                  <span class="primary--text font-weight-medium"
                    >Data Informasi Pembeli</span
                  >
                  <div class="data-input pt-3">
                    <label class="font-weight-medium text-left"
                      >Nomor Registrasi</label
                    >
                    <label class="text-left">{{ registrationCode }}</label>
                    <label class="font-weight-medium text-left">Nama</label>
                    <v-text-field
                      dense
                      single-line
                      hide-details
                      :readonly="isDetail"
                      v-model="input.name"
                    >
                    </v-text-field>
                    <label class="font-weight-medium text-left"
                      >Jenis Kelamin</label
                    >
                    <div class="d-flex">
                      <v-chip
                        class="mr-2 font-weight-medium caption"
                        @click="onClickGender(true, 'nonmix')"
                        :outlined="!input.gender"
                        label
                        color="primary"
                        >LAKI-LAKI</v-chip
                      >
                      <v-chip
                        class="font-weight-medium caption"
                        label
                        color="primary"
                        :outlined="input.gender"
                        @click="onClickGender(false, 'nonmix')"
                        >PEREMPUAN</v-chip
                      >
                    </div>
                    <label class="font-weight-medium text-left">Usia</label>
                    <v-text-field
                      suffix="Tahun"
                      dense
                      type="number"
                      :readonly="isDetail"
                      hide-details
                      single-line
                      v-model="input.age"
                    >
                    </v-text-field>
                    <label class="font-weight-medium text-left"
                      >Nama Dokter</label
                    >
                    <v-text-field
                      dense
                      single-line
                      :readonly="isDetail"
                      hide-details
                      v-model="input.doctor"
                    >
                    </v-text-field>
                  </div>
                </v-col>
                <v-col cols="4" class="text-left pt-7"
                  ><span class="primary--text font-weight-medium"
                    >Pemesanan</span
                  >
                  <v-form ref="nonmix">
                    <div class="data-input pt-3">
                      <label class="font-weight-medium text-left"
                        >Nama Obat</label
                      ><v-combobox
                        append-icon="mdi-chevron-down"
                        single-line
                        dense
                        auto-select-first
                        :rules="isDetail ? [] : rules.nonmix.drug"
                        :readonly="isDetail"
                        :search-input.sync="searchDrug"
                        :items="resource.drugs"
                        v-model="input.nonmix.drug"
                        :loading="isLoading.nonmix"
                      >
                        <template v-slot:item="data">
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-layout>
                                <v-flex class="d-flex justify-start">
                                  <span class="text-left">
                                    {{ `${data.item.text}` }}
                                  </span>
                                </v-flex>
                              </v-layout>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <v-layout>
                                <span class="mr-4">{{
                                  `Sisa Stok : ${data.item.quantity} ${data.item.packaging}`
                                }}</span>
                                <span>{{
                                  currency(data.item.price || 0)
                                }}</span>
                              </v-layout>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template></v-combobox
                      >
                      <label class="font-weight-medium text-left"
                        >Jenis Sediaan</label
                      >
                      <label class="text-left">{{
                        input.nonmix.drug.preparation
                      }}</label>
                      <label class="font-weight-medium text-left">Dosis</label>
                      <label class="text-left">{{
                        `${input.nonmix.drug.strength || ''} ${input.nonmix.drug
                          .strength_unit || ''}`
                      }}</label>
                      <label class="font-weight-medium text-left">Jumlah</label>
                      <v-text-field
                        dense
                        type="number"
                        :readonly="isDetail"
                        :rules="isDetail ? [] : rules.nonmix.amount"
                        single-line
                        v-model="input.nonmix.amount"
                      >
                      </v-text-field>
                    </div>
                  </v-form>
                  <v-row>
                    <v-col cols="12" class="text-right pt-8">
                      <v-btn
                        color="primary"
                        class="font-weight-medium caption"
                        outlined
                        height="30"
                        @click="addToListTable('nonmix')"
                        v-if="!isDetail"
                        width="190"
                        ><v-icon small class="font-weight-bold">mdi-plus</v-icon
                        >TAMBAHKAN KE DAFTAR</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="4"
                  ><div class="price-box font-weight-medium">
                    <div class="d-flex">
                      <v-icon class="mr-2">mdi-shopping-outline</v-icon>
                      <span>Total Tagihan Penjualan</span>
                    </div>
                    <span>
                      {{ currency(totalBilling) }}
                    </span>
                  </div></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-data-table
                    hide-default-footer
                    :loading="isLoading.table"
                    :headers="table.nonmix.headers"
                    :items="table.nonmix.items"
                    :items-per-page="table.nonmix.items.length"
                  >
                    <template v-slot:item="{ item, index }">
                      <tr>
                        <td>{{ index + 1 }}</td>
                        <td class="text-left">{{ item.drugName }}</td>
                        <td class="text-center">{{ item.preparation }}</td>
                        <td class="text-center">{{ item.dosage }}</td>
                        <td class="text-center">
                          <v-text-field
                            dense
                            single-line
                            hide-details
                            v-if="!isDetail"
                            v-model="item.amount"
                          ></v-text-field>
                          <label v-else>{{ item.amount }}</label>
                        </td>
                        <td class="text-center">{{ item.unit }}</td>
                        <td class="text-left">
                          {{ currency(item.price * item.amount || 0) }}
                        </td>
                        <td class="text-center">
                          <v-icon
                            @click="deleteItem(item, 'nonmix')"
                            color="red"
                            v-if="!isDetail"
                            >mdi-delete</v-icon
                          >
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                  <v-row>
                    <v-col cols="12" class="d-flex justify-end px-9 pt-5">
                      <div class="d-flex justify-space-between subtotal">
                        <span>
                          Subtotal :
                        </span>
                        <!-- count subtotal (total from each item in nonmix table) -->
                        <span>
                          {{
                            currency(
                              table.nonmix.items.reduce(
                                (a, b) => +a + +b.price * b.amount,
                                0,
                              ),
                            )
                          }}
                        </span>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </v-tab-item>
          <v-tab-item>
            <div class="input-container">
              <v-row class="mb-5">
                <v-col cols="4" class="text-left pt-7 pl-7">
                  <span class="primary--text font-weight-medium"
                    >Data Informasi Pembeli</span
                  >
                  <div class="data-input pt-3">
                    <label class="font-weight-medium text-left"
                      >Nomor Registrasi</label
                    >
                    <label class="text-left">{{ registrationCode }}</label>
                    <label class="font-weight-medium text-left">Nama</label>
                    <v-text-field
                      dense
                      :readonly="isDetail"
                      single-line
                      hide-details
                      v-model="input.name"
                    >
                    </v-text-field>
                    <label class="font-weight-medium text-left"
                      >Jenis Kelamin</label
                    >
                    <div class="d-flex">
                      <v-chip
                        style="cursor: pointer;"
                        class="mr-2 font-weight-medium caption"
                        :outlined="!input.gender"
                        label
                        @click="onClickGender(true, 'mix')"
                        color="primary"
                        >LAKI-LAKI</v-chip
                      >
                      <v-chip
                        style="cursor: pointer;"
                        @click="onClickGender(false, 'mix')"
                        :outlined="input.gender"
                        class="font-weight-medium caption"
                        color="primary"
                        label
                        >PEREMPUAN</v-chip
                      >
                    </div>
                  </div>
                </v-col>
                <v-col cols="4">
                  <div class="data-input pt-12">
                    <label class="font-weight-medium text-left">Usia</label>
                    <v-text-field
                      suffix="Tahun"
                      type="number"
                      dense
                      hide-details
                      :readonly="isDetail"
                      single-line
                      v-model="input.age"
                    >
                    </v-text-field>
                    <label class="font-weight-medium text-left"
                      >Nama Dokter</label
                    >
                    <v-text-field
                      dense
                      single-line
                      hide-details
                      :readonly="isDetail"
                      v-model="input.doctor"
                    >
                    </v-text-field>
                  </div>
                  <v-row>
                    <v-col cols="12" class="text-right pt-8">
                      <v-btn
                        color="primary"
                        class="font-weight-medium caption"
                        outlined
                        height="30"
                        v-if="!isDetail"
                        width="130"
                        @click="openDialog()"
                        >BUAT OBAT RACIK</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="4" class="pt-8"
                  ><div class="price-box font-weight-medium">
                    <div class="d-flex">
                      <v-icon class="mr-2">mdi-shopping-outline</v-icon>
                      <span>Total Tagihan Penjualan</span>
                    </div>
                    <span>
                      {{ currency(totalBilling) }}
                    </span>
                  </div></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-data-table
                    hide-default-footer
                    :loading="isLoading.table"
                    :headers="table.mix.headers"
                    :items="table.mix.items"
                    :items-per-page="table.mix.items.length"
                  >
                    <template v-slot:item="{ item, index }">
                      <tr>
                        <td>{{ index + 1 }}</td>
                        <td class="text-left">{{ item.mixDrugName }}</td>
                        <td class="text-center">{{ item.amount }}</td>
                        <td class="text-center">{{ item.unit }}</td>
                        <td class="text-left py-1">
                          <p
                            class="ma-0 pa-0 mb-2"
                            v-for="(drug, index) in item.drugs"
                            :key="`drug-${index}`"
                          >
                            {{ drug.name }}
                          </p>
                        </td>
                        <td class="text-center py-1">
                          <p
                            class="ma-0 pa-0 mb-2"
                            v-for="(drug, index) in item.drugs"
                            :key="`drug-${index}`"
                          >
                            {{ drug.preparation }}
                          </p>
                        </td>
                        <td class="text-center py-1 mx-0 px-0">
                          <p
                            class="ma-0 pa-0 mb-2"
                            v-for="(drug, index) in item.drugs"
                            :key="`drug-${index}`"
                          >
                            {{ drug.dosage }}
                          </p>
                        </td>
                        <td class="text-center py-1">
                          <div
                            v-for="(drug, index) in item.drugs"
                            :key="`drug-${index}`"
                          >
                            <v-text-field
                              hide-details
                              single-line
                              dense
                              v-if="!isDetail"
                              class="ma-0 pa-0 mb-2"
                              v-model="drug.amountDrugTaken"
                            >
                            </v-text-field>
                            <label v-else>{{ drug.amountDrugTaken }}</label>
                          </div>
                        </td>
                        <td class="text-left">
                          {{
                            currency(
                              item.drugs.reduce(
                                (a, b) => +a + +b.price * b.amountDrugTaken,
                                0,
                              ),
                            )
                          }}
                        </td>
                        <td class="text-center">
                          <v-icon
                            v-if="!isDetail"
                            @click="deleteItem(item, 'mix')"
                            color="red"
                            >mdi-delete</v-icon
                          >
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                  <v-row>
                    <v-col cols="12" class="d-flex justify-end px-9 pt-5">
                      <div class="d-flex justify-space-between subtotal">
                        <span>
                          Subtotal :
                        </span>
                        <span>
                          {{ currency(mixDrugTotalPrice) }}
                        </span>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </v-tab-item>
          <v-tab-item>
            <div class="input-container">
              <v-row class="mb-2">
                <v-col cols="4" class="text-left pt-7 pl-7">
                  <span class="primary--text font-weight-medium"
                    >Data Informasi Pembeli</span
                  >
                  <div class="data-input pt-3">
                    <label class="font-weight-medium text-left"
                      >Nomor Registrasi</label
                    >
                    <label class="text-left">{{ registrationCode }}</label>
                    <label class="font-weight-medium text-left">Nama</label>
                    <v-text-field
                      dense
                      :readonly="isDetail"
                      single-line
                      hide-details
                      v-model="input.name"
                    >
                    </v-text-field>
                    <label class="font-weight-medium text-left"
                      >Jenis Kelamin</label
                    >
                    <div class="d-flex">
                      <v-chip
                        class="mr-2 font-weight-medium caption"
                        label
                        @click="onClickGender(true, 'medtool')"
                        :outlined="!input.gender"
                        color="primary"
                        >LAKI-LAKI</v-chip
                      >
                      <v-chip
                        class="font-weight-medium caption"
                        label
                        @click="onClickGender(false, 'medtool')"
                        color="primary"
                        :outlined="input.gender"
                        >PEREMPUAN</v-chip
                      >
                    </div>
                    <label class="font-weight-medium text-left">Usia</label>
                    <v-text-field
                      suffix="Tahun"
                      dense
                      hide-details
                      type="number"
                      :readonly="isDetail"
                      single-line
                      v-model="input.age"
                    >
                    </v-text-field>
                    <label class="font-weight-medium text-left"
                      >Nama Dokter</label
                    >
                    <v-text-field
                      dense
                      single-line
                      hide-details
                      :readonly="isDetail"
                      v-model="input.doctor"
                    >
                    </v-text-field>
                  </div>
                </v-col>
                <v-col cols="4" class="text-left pt-7"
                  ><span class="primary--text font-weight-medium"
                    >Pemesanan</span
                  >
                  <v-form ref="medtool">
                    <div class="data-input pt-3">
                      <label class="font-weight-medium text-left"
                        >Nama Alkes atau BHP</label
                      ><v-combobox
                        append-icon="mdi-chevron-down"
                        :rules="isDetail ? [] : rules.medtool.name"
                        :readonly="isDetail"
                        :items="resource.medtoolAndBHP"
                        :loading="isLoading.medtoolAndBHP"
                        :search-input.sync="searchMedtoolAndBHP"
                        single-line
                        dense
                        v-model="input.medtoolAndBHP.item"
                        ><template v-slot:item="data">
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-layout>
                                <v-flex class="d-flex justify-start">
                                  <span class="text-left">
                                    {{ `${data.item.text}` }}
                                  </span>
                                </v-flex>
                              </v-layout>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <v-layout>
                                <span class="mr-4">{{
                                  `Sisa Stok : ${data.item.quantity} ${data.item.packaging}`
                                }}</span>
                                <span>{{
                                  currency(data.item.price || 0)
                                }}</span>
                              </v-layout>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template></v-combobox
                      >
                      <label class="font-weight-medium text-left">Jumlah</label>
                      <v-text-field
                        dense
                        type="number"
                        :readonly="isDetail"
                        single-line
                        :rules="isDetail ? [] : rules.medtool.amount"
                        :suffix="input.medtoolAndBHP.unit"
                        v-model="input.medtoolAndBHP.amount"
                      >
                      </v-text-field>
                    </div>
                  </v-form>
                  <v-row>
                    <v-col cols="12" class="text-right pt-8">
                      <v-btn
                        color="primary"
                        class="font-weight-medium caption"
                        outlined
                        @click="addToListTable('medtool')"
                        height="30"
                        v-if="!isDetail"
                        width="190"
                        ><v-icon small class="font-weight-bold">mdi-plus</v-icon
                        >TAMBAHKAN KE DAFTAR</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="4" class="pt-10"
                  ><div class="price-box font-weight-medium">
                    <div class="d-flex">
                      <v-icon class="mr-2">mdi-shopping-outline</v-icon>
                      <span>Total Tagihan Penjualan</span>
                    </div>
                    <span>
                      {{ currency(totalBilling) }}
                    </span>
                  </div></v-col
                >
              </v-row>
              <v-row class="px-3">
                <v-col cols="12" class="px-15">
                  <v-data-table
                    hide-default-footer
                    :loading="isLoading.table"
                    :headers="table.medtoolAndBHP.headers"
                    :items="table.medtoolAndBHP.items"
                    :items-per-page="table.medtoolAndBHP.items.length"
                  >
                    <template v-slot:item="{ item, index }">
                      <tr>
                        <td>{{ index + 1 }}</td>
                        <td class="text-left">{{ item.name }}</td>
                        <td class="text-center">
                          <v-text-field
                            hide-details
                            single-line
                            dense
                            v-if="!isDetail"
                            v-model="item.amount"
                          >
                          </v-text-field>
                          <label v-else>{{ item.amount }}</label>
                        </td>
                        <td class="text-center">{{ item.unit }}</td>
                        <td class="text-left">
                          {{ currency(item.price * item.amount) }}
                        </td>
                        <td class="text-center">
                          <v-icon
                            @click="deleteItem(item, 'medtool')"
                            color="red"
                            v-if="!isDetail"
                            >mdi-delete</v-icon
                          >
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                  <v-row>
                    <v-col cols="12" class="d-flex justify-end px-9 pt-5">
                      <div class="d-flex justify-space-between subtotal">
                        <span>
                          Subtotal :
                        </span>
                        <span>
                          {{
                            currency(
                              table.medtoolAndBHP.items.reduce(
                                (a, b) => +a + +b.price * b.amount,
                                0,
                              ),
                            )
                          }}
                        </span>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </v-tab-item>
        </v-tabs-items>
        <v-footer class="d-flex justify-end pt-7" color="white">
          <div class="d-flex">
            <v-btn
              class="font-weight-medium mr-3"
              color="primary"
              height="30"
              width="80"
              outlined
              @click="$emit('close-form')"
              >KEMBALI</v-btn
            >
            <v-btn
              class="font-weight-medium"
              height="30"
              width="80"
              color="primary"
              @click="save()"
              :loading="isLoading.save"
              v-if="!isDetail"
              >SIMPAN</v-btn
            >
          </div>
        </v-footer>
      </v-col>
    </v-row>
    <v-dialog persistent v-model="dialog">
      <MixDrugManufacturing
        v-if="dialog"
        @save="addMixDrug"
        @close-dialog="closeDialog()"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import Constant from '@/const';
import axios from 'axios';
import Swal from 'sweetalert2';
import formatMixin from '@/mixin/formatMixin';
import MixDrugManufacturing from './MixDrugManufacturing.vue';

export default {
  name: 'inputOTC',
  mixins: [formatMixin],
  components: { MixDrugManufacturing },
  props: {
    isDetail: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tab: null,
      dialog: false,
      isLoading: {
        nonmix: false,
        medtoolAndBHP: false,
        save: false,
        table: false,
      },
      searchDrug: '',
      searchMedtoolAndBHP: '',
      registrationCode: '',

      resource: {
        drugs: [],
        medtoolAndBHP: [],
      },
      input: {
        name: '',
        age: '',
        gender: true,
        doctor: '',
        nonmix: {
          drugName: '',
          preparation: '',
          dosage: '',
          amount: '',
          drug: '',
        },
        medtoolAndBHP: {
          item: '',
          amount: '',
          unit: '',
        },
      },
      rules: {
        nonmix: {
          drug: [v => !!v || 'Masukkan nama obat'],
          amount: [v => !!v || 'Masukkan jumlah obat'],
        },
        medtool: {
          amount: [v => !!v || 'Masukkan jumlah alkes atau BHP'],
          name: [v => !!v || 'Masukkan nama alkes atau BHP'],
        },
      },
      table: {
        nonmix: {
          headers: [
            {
              text: 'No',
              value: 'no',
              width: '3%',
              sortable: false,
            },
            {
              text: 'Nama Obat',
              value: 'drugName',
              width: '30%',
              sortable: false,
            },
            {
              text: 'Jenis Sediaan',
              value: 'preparation',
              width: '10%',
              class: 'text-sm-center',
              sortable: false,
            },
            {
              text: 'Dosis',
              value: 'dosage',
              class: 'text-sm-center',
              width: '10%',
              sortable: false,
            },
            {
              text: 'Jumlah',
              value: 'amount',
              class: 'text-sm-center',
              width: '10%',
              sortable: false,
            },
            {
              text: 'Satuan',
              value: 'unit',
              width: '10%',
              class: 'text-sm-center',
              sortable: false,
            },
            {
              text: 'Harga',
              value: 'price',
              width: '20%',
              sortable: false,
            },
            {
              text: 'Action',
              value: 'action',
              width: '7%',
              class: 'text-sm-center',
              sortable: false,
            },
          ],
          items: [],
        },
        mix: {
          headers: [
            {
              text: 'No',
              value: 'no',
              width: '3%',
              sortable: false,
            },
            {
              text: 'Nama Obat Racik',
              value: 'mixDrugName',
              width: '20%',
              sortable: false,
            },
            {
              text: 'Jumlah',
              value: 'amount',
              class: 'text-sm-center',
              width: '5%',
              sortable: false,
            },
            {
              text: 'Satuan',
              value: 'unit',
              width: '4%',
              class: 'text-sm-center',
              sortable: false,
            },
            {
              text: 'Nama Obat',
              value: 'drugName',
              width: '20%',
              class: 'text-sm-left',
              sortable: false,
            },
            {
              text: 'Jenis Sediaan',
              value: 'preparation',
              width: '7%',
              class: 'text-sm-center px-0 mx-0',
              sortable: false,
            },
            {
              text: 'Dosis',
              value: 'dosage',
              class: 'text-sm-center',
              width: '5%',
              sortable: false,
            },
            {
              text: 'Jumlah Obat Diambil',
              value: 'amountDrugTaken',
              width: '10%',
              class: 'text-sm-center mx-0 px-0',
              sortable: false,
            },
            {
              text: 'Harga',
              value: 'price',
              width: '10%',
              sortable: false,
            },
            {
              text: 'Action',
              value: 'action',
              width: '5%',
              class: 'text-sm-center',
              sortable: false,
            },
          ],
          items: [],
        },
        medtoolAndBHP: {
          headers: [
            {
              text: 'No',
              value: 'no',
              sortable: false,
              width: '3%',
            },
            {
              text: 'Nama Alkes atau BHP',
              value: 'name',
              sortable: false,
              width: '60%',
            },
            {
              text: 'Jumlah',
              value: 'amount',
              sortable: false,
              width: '10%',
              class: 'text-sm-center',
            },
            {
              text: 'Satuan',
              value: 'unit',
              sortable: false,
              width: '10%',
              class: 'text-sm-center',
            },
            {
              text: 'Harga',
              value: 'price',
              sortable: false,
              width: '14%',
            },
            {
              text: 'Action',
              value: 'action',
              sortable: false,
              width: '4%',
              class: 'text-sm-center',
            },
          ],
          items: [],
        },
      },
    };
  },
  watch: {
    searchDrug(value) {
      if (value) {
        this.getDrugs(value);
      }
    },
    searchMedtoolAndBHP(value) {
      if (value) {
        this.getMedtoolAndBHP(value);
      }
    },
  },
  computed: {
    mixDrugTotalPrice() {
      let prices = [];
      let total;
      this.table.mix.items.forEach(item => {
        prices.push(
          item.drugs.reduce((a, b) => +a + +b.price * +b.amountDrugTaken, 0),
        );
      });
      total = prices.reduce((a, b) => a + b, 0);
      return total;
    },
    totalBilling() {
      const totalNonMix = this.table.nonmix.items.reduce(
        (a, b) => +a + +b.price * b.amount,
        0,
      );
      const totalMedtool = this.table.medtoolAndBHP.items.reduce(
        (a, b) => +a + +b.price * b.amount,
        0,
      );
      return totalNonMix + totalMedtool + this.mixDrugTotalPrice;
    },
  },
  mounted() {
    if (this.isDetail) {
      this.getDataById();
    } else {
      this.getRegistrationCode();
    }
  },
  methods: {
    // get recipe data by id
    async getDataById() {
      this.isLoading.table = true;
      try {
        const response = await axios.get(
          Constant.apiUrl.concat(`/drugssales/${this.id}`),
        );
        const { data } = response.data;
        this.registrationCode = data.transaction_code;
        this.input = {
          ...this.input,
          name: data.patient_name || '',
          age: data.patient_age || '',
          gender: data.patient_gender,
          doctor: data.doctor_name || '',
        };
        // map non mix data to table
        this.table.nonmix.items = data.non_mixed_medicine.map(drug => {
          return {
            drugName: `${drug.id_drugs?.name} ${drug.id_drugs?.strength} ${drug.id_drugs?.strength_unit} (${drug.id_drugs?.factory})`,
            amount: drug.amount || '-',
            unit:
              drug.id_drugs?.packaging?.filter(i => i.isDefault)[0]
                ?.package_unit || '-',
            price: drug.price || '-',
            preparation: drug.id_drugs?.preparation,
            dosage: drug.id_drugs?.strength,
          };
        });
        // map mix data to table
        this.table.mix.items = data.mixed_medicine.map(mix => {
          return {
            mixDrugName: mix.name || '-',
            amount: mix.amount || '-',
            unit: mix.amount_unit || '-',
            price: mix.price || '-',
            drugs: mix.drugs.map(i => {
              return {
                name: `${i.id_drugs?.name} ${i.id_drugs?.strength} ${i.id_drugs?.strength_unit} (${i.id_drugs?.factory})`,
                preparation: i.id_drugs?.preparation || '-',
                dosage: i.id_drugs?.strength,
                dosage_unit: i.id_drugs?.strength_unit,
                amountDrugTaken: i.amount,
                unit: i.id_drugs?.strength_unit,
                package:
                  i.id_drugs?.packaging?.filter(x => x.isDefault)[0]
                    ?.package_unit || '-',
                price: i.drug_price,
              };
            }),
          };
        });
        // map medtool data to table
        this.table.medtoolAndBHP.items = data.medical_goods.map(tool => {
          return {
            name: `${tool.id_drugs?.name} ${tool.id_drugs?.strength} ${tool.id_drugs?.strength_unit} (${tool.id_drugs?.factory})`,
            amount: tool.amount,
            unit:
              tool.id_drugs?.packaging?.filter(x => x.isDefault)[0]
                ?.package_unit || '-',
            price: tool.price || '-',
          };
        });
      } catch (error) {
        // eslint-disable-next-line
        if (error) {
          Swal.fire({
            icon: 'error',
            title: 'Terjadi Kesalahan',
            text: 'Gagal mendapatkan data resep',
          });
        }
      } finally {
        this.isLoading.table = false;
      }
    },
    // save OTC data to dashboard
    async save() {
      this.isLoading.save = true;
      // check if buyer data is filled correctly
      if (
        this.input.name === '' ||
        this.input.age === '' ||
        this.input.doctor === ''
      ) {
        Swal.fire({
          icon: 'warning',
          title: 'Tidak dapat menyimpan',
          text: 'Data informasi pembeli belum lengkap',
        });
        this.isLoading.save = false;
        return;
      }
      // check if there is drug, medtool, or consumable ready to saved
      if (
        this.table.nonmix.items.length === 0 &&
        this.table.mix.items.length === 0 &&
        this.table.medtoolAndBHP.items.length === 0
      ) {
        Swal.fire({
          icon: 'warning',
          title: 'Tidak dapat menyimpan',
          text: 'Tidak ada data obat, alkes, atau BHP yang disimpan',
        });
        this.isLoading.save = false;
        return;
      }
      const payload = {
        transaction_code: this.registrationCode,
        patient_name: this.input.name,
        patient_age: this.input.age,
        patient_gender: this.input.gender,
        doctor_name: this.input.doctor,
        non_mixed_medicine: this.table.nonmix.items.map(item => {
          return {
            id_drugs: item.id,
            id_stock: item.id_stock,
            name: item.drugName,
            dosage: item.dosage,
            dosage_unit: item.dosage_unit,
            unit: item.unit,
            amount: item.amount,
          };
        }),
        medical_goods: this.table.medtoolAndBHP.items.map(item => {
          return {
            id_drugs: item.id_drugs,
            id_goods: item.id_goods,
            id_stock: item.id_stock,
            name: item.name,
            amount: item.amount,
          };
        }),
        mixed_medicine: this.table.mix.items.map(item => {
          return {
            name: item.mixDrugName,
            amount: item.amount,
            amount_unit: item.unit,
            drugs: item.drugs.map(arr => {
              return {
                id_drugs: arr.id,
                id_stock: arr.id_stock,
                name: arr.name,
                dosage: arr.dosage,
                dosage_unit: arr.dosage_unit,
                amount: arr.amountDrugTaken,
              };
            }),
          };
        }),
        created_by: this.$store.getters.userLoggedIn.id,
      };
      try {
        const response = await axios.post(
          Constant.apiUrl.concat('/drugssales/add'),
          payload,
        );
        if (response) {
          Swal.fire(
            'Data Penjualan Obat Bebas berhasil disimpan!',
            '',
            'success',
          );
          this.$emit('close-form');
        }
      } catch (error) {
        if (error) {
          Swal.fire({
            icon: 'error',
            title: 'Terjadi Kesalahan',
            text: 'Gagal Menyimpan data penjualan obat bebas',
          });
        }
      } finally {
        this.isLoading.save = false;
      }
    },
    // change between gender
    onClickGender(gender, type) {
      if (!this.isDetail) {
        if (type === 'nonmix') this.input.gender = gender;
        if (type === 'mix') this.input.gender = gender;
        if (type === 'medtool') this.input.gender = gender;
      }
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    // function to save mix drugs from MixDrugManufacturing component
    addMixDrug(drug) {
      this.table.mix.items.push({
        mixDrugName: drug.mixDrugName,
        amount: drug.amount,
        unit: drug.unit,
        price: drug.table.reduce((a, b) => +a + b.price, 0),
        drugs: drug.table.map(item => {
          return {
            name: item.drugName,
            preparation: item.preparation,
            dosage: item.dosage,
            dosage_unit: item.strength_unit,
            amountDrugTaken: item.amountDrugTaken,
            unit: item.strength_unit,
            id: item.id,
            id_stock: item.id_stock,
            package: item.package,
            price: item.price,
            quantity: item.quantity,
            expiry: item.expiry,
          };
        }),
      });
    },
    // delete per item at nonmix table
    deleteItem(item, type) {
      if (type === 'nonmix') {
        const index = this.table.nonmix.items.indexOf(item);
        this.table.nonmix.items.splice(index, 1);
      }
      if (type === 'mix') {
        const index = this.table.mix.items.indexOf(item);
        this.table.mix.items.splice(index, 1);
      }
      if (type === 'medtool') {
        const index = this.table.medtoolAndBHP.items.indexOf(item);
        this.table.medtoolAndBHP.items.splice(index, 1);
      }
    },
    addToListTable(type) {
      if (type === 'nonmix') {
        if (this.$refs.nonmix.validate()) {
          if (this.input.nonmix.amount <= this.input.nonmix.drug.quantity) {
            this.table.nonmix.items.push({
              drugName: this.input.nonmix.drug.displayName,
              expiry: this.input.nonmix.drug.expiry,
              id: this.input.nonmix.drug.id,
              preparation: this.input.nonmix.drug.preparation,
              dosage: this.input.nonmix.drug.strength,
              dosage_unit: this.input.nonmix.drug.strength_unit,
              amount: this.input.nonmix.amount,
              unit: this.input.nonmix.drug.packaging,
              price: this.input.nonmix.drug.price,
              id_stock: this.input.nonmix.drug.id_stock,
              name: this.input.nonmix.drug.name,
              quantity: this.input.nonmix.drug.quantity,
            });
            this.input.nonmix.drug = '';
            this.input.nonmix.amount = '';
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Terjadi Kesalahan',
              text: `Stok Obat ${this.input.nonmix.drug.displayName} tidak mencukupi, tersisa ${this.input.nonmix.drug.quantity}`,
            });
          }
        }
      }
      if (type === 'medtool') {
        if (this.$refs.medtool.validate()) {
          if (
            this.input.medtoolAndBHP.amount <=
            this.input.medtoolAndBHP.item.quantity
          ) {
            this.table.medtoolAndBHP.items.push({
              name: this.input.medtoolAndBHP.item.displayName,
              amount: this.input.medtoolAndBHP.amount,
              unit: this.input.medtoolAndBHP.item.packaging,
              price: this.input.medtoolAndBHP.item.price,
              expiry: this.input.medtoolAndBHP.item.expiry,
              id_drugs: this.input.medtoolAndBHP.item.id_drugs,
              id_goods: this.input.medtoolAndBHP.item.id_goods,
              id_stock: this.input.medtoolAndBHP.item.id_stock,
              strength: this.input.medtoolAndBHP.item.strength,
              strength_unit: this.input.medtoolAndBHP.item.strength_unit,
              quantity: this.input.medtoolAndBHP.item.quantity,
            });
            this.input.medtoolAndBHP.item = '';
            this.input.medtoolAndBHP.amount = '';
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Terjadi Kesalahan',
              text: `Stok Alkes/BHP ${this.input.medtoolAndBHP.item.displayName} tidak mencukupi, tersisa ${this.input.medtoolAndBHP.item.quantity}`,
            });
          }
        }
      }
    },
    async getRegistrationCode() {
      try {
        const response = await axios.get(
          Constant.apiUrl.concat('/drugssales/transactioncode'),
        );
        this.registrationCode = response.data.data;
      } catch (error) {
        if (error) {
          Swal.fire({
            icon: 'error',
            title: 'Terjadi Kesalahan',
            text: 'Gagal mendapatkan kode registrasi',
          });
        }
      }
    },
    async getDrugs(keyword) {
      this.isLoading.nonmix = true;
      try {
        const response = await axios.get(
          Constant.apiUrl.concat(
            `/stock/all/names?page=1&itemCount=20&category=Obat&name=${
              !keyword ? '' : keyword
            }`,
          ),
        );
        const data = response.data.data;
        this.resource.drugs = data.map(arr => {
          return {
            id: arr.id_drugs,
            id_stock: arr._id,
            name: arr.drugs.detail.name,
            text: `${arr.drugs.detail.name} ${arr.drugs.detail.strength} ${arr.drugs.detail.strength_unit} (${arr.drugs.detail.factory})`,
            displayName: `${arr.drugs.detail.name} ${arr.drugs.detail.strength} ${arr.drugs.detail.strength_unit} (${arr.drugs.detail.factory})`,
            strength_unit: arr.drugs.detail.strength_unit,
            strength: arr.drugs.detail.strength,
            preparation: arr.drugs.detail.preparation,
            packaging: arr.package,
            price: arr.price,
            quantity: arr.quantity,
            expiry: arr.expiry_date,
          };
        });
      } catch (error) {
        if (error) {
          this.resource.drugs = [];
        }
      } finally {
        this.isLoading.nonmix = false;
      }
    },
    async getMedtoolAndBHP(keyword) {
      this.isLoading.medtoolAndBHP = true;
      try {
        const response = await axios.get(
          Constant.apiUrl.concat(
            `/stock/all/names?page=1&itemCount=20&category=alkesbhp&name=${
              !keyword ? '' : keyword
            }`,
          ),
        );
        const data = response.data.data;
        this.resource.medtoolAndBHP = data.map(arr => {
          return {
            id_drugs: arr.id_drugs,
            id_goods: arr.id_goods,
            id_stock: arr._id,
            name: arr.drugs.detail.name,
            text: `${arr.drugs.detail.name} ${arr.drugs.detail.strength} ${arr.drugs.detail.strength_unit} (${arr.drugs.detail.factory})`,
            displayName: `${arr.drugs.detail.name} ${arr.drugs.detail.strength} ${arr.drugs.detail.strength_unit} (${arr.drugs.detail.factory})`,
            strength_unit: arr.drugs.detail.strength_unit,
            strength: arr.drugs.detail.strength,
            preparation: arr.drugs.detail.preparation,
            packaging: arr.package,
            price: arr.price,
            quantity: arr.quantity,
            expiry: arr.expiry_date,
          };
        });
      } catch (error) {
        if (error) {
          this.resource.medtoolAndBHP = [];
        }
      } finally {
        this.isLoading.medtoolAndBHP = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.subtotal {
  background-color: #f4f5f5;
  height: 30px;
  width: 320px;
  border-radius: 3px;
  font-weight: 500;
  font-size: 0.9rem;
  color: #4b4d4d;
  padding: 5px 20px;
}
.input-container {
  max-height: 400px;
  height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 2px;
    background-color: #a3a3a3;
  }
  &::-webkit-scrollbar-thumb {
    width: 2px;
    background-color: black;
  }
}
.main-card {
  min-height: 600px;
  height: 100%;
}
.data-input {
  display: grid;
  grid-template-columns: 40% 60%;
  row-gap: 10px;
  font-size: 0.9rem;
}
.price-box {
  width: 95%;
  display: flex;
  justify-content: space-between;
  background-color: #f4faff;
  border-radius: 4px;
  border: 0.2px solid #bdbdbd;
  padding: 8px;
  font-size: 0.9rem;
}
</style>
