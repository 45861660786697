<template>
  <v-card>
    <v-card-title class="justify-center">
      <h3>Tambahkan Diagnosa Baru</h3>
      <v-btn absolute right @click="onCloseButtonClick()" small text>
        <v-icon dark>
          mdi-close
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row no-gutters justify="center">
          <v-col md="5">
            <v-form ref="form" lazy-validation>
              <div class="detail-input">
                <div class="detail-input__label">
                  <label for="name">Kode Diagnosa</label>
                </div>

                <v-text-field
                  v-model="input.code"
                  placeholder="Masukkan Kode Diagnosa"
                  class="detail-input__input-text"
                  required
                  :rules="[v => !!v || 'Wajib diisi']"
                />
              </div>
              <div class="detail-input">
                <div class="detail-input__label">
                  <label for="name">Deskripsi Diagnosa</label>
                </div>

                <v-textarea
                  v-model="input.description"
                  placeholder="Tambahkan Deskripsi Diagnosa"
                  class="detail-input__input-text"
                  name="deskripsidiagnosa"
                  outlined
                  height="200"
                  required
                  :rules="[v => !!v || 'Wajib diisi']"
                ></v-textarea>
              </div>
            </v-form>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="text-none"
                color="secondary"
                text
                @click="onCloseButtonClick()"
              >
                Kembali
              </v-btn>
              <v-btn
                depressed
                class="text-none"
                color="primary"
                @click="addNewDiagnose()"
              >
                Simpan
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import Constant from '@/const';

const axios = require('axios');

export default {
  methods: {
    onCloseButtonClick() {
      this.$emit('close-dialog');
    },
    addNewDiagnose() {
      if (this.$refs.form.validate()) {
        axios
          .post(Constant.apiUrl.concat('/master/diagnose/'), {
            code: this.input.code,
            description: this.input.description,
            severity: this.input.severity,
            inpatient: this.input.inpatient,
            outpatient: this.input.outpatient,
          })
          .then(response => {
            if (response.status === 200) {
              Swal.fire('Berhasil', 'Data Diagnosa Sudah Masuk', 'success');
              this.onCloseButtonClick();
            }
          })
          .catch(error => {
            if (error.response) {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Data diagnosa sudah tersedia',
              });
            } else if (error.request) {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Tidak dapat menyambung ke server',
              });
            }
          });
      }
    },
  },
  data: () => ({
    input: {
      code: '',
      description: '',
    },
  }),
};
</script>

<style lang="scss" scoped>
.column {
  margin: 0 auto;
}

.detail-radio {
  display: flex;
  justify-content: flex-end;
}

.detail-input {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  &__label {
    justify-content: flex-end;
    flex: 1;
    display: flex;
    color: #222;
  }

  &__input-text {
    margin-left: 10px;
    width: 150px;
  }
}

.padding {
  padding-left: 10px;
}

.radio {
  margin: 0;
}

.table-kemasan {
  width: 100%;
  margin-top: 20px;
}
</style>
