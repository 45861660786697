<template>
  <v-card class="main">
    <v-icon @click="closeForm()" class="close-btn">mdi-close</v-icon>
    <v-card-title class="justify-center">
      <h3>Pendaftaran Poli</h3>
    </v-card-title>
    <div class="grid-display">
      <label>Jenis Kunjungan</label>
      <v-radio-group
        row
        class="detail-input__input-radio"
        v-model="input.isSick"
      >
        <v-radio name="isSick" label="Sehat" :value="false"></v-radio>
        <v-radio name="isSick" label="Sakit" :value="true"></v-radio>
      </v-radio-group>
      <label>Poli Tujuan</label>
      <v-text-field
        :value="input.polyclinic.name"
        readonly
        dense
      ></v-text-field>
      <label>Pilih Dokter</label>
      <v-combobox
        :loading="loading.doctor"
        auto-select-first
        dense
        @change="getScheduleDoctor"
        placeholder="Pilih Dokter"
        :items="resource.doctors"
        item-value="doctorId"
        item-text="doctorName"
        append-icon="mdi-chevron-down"
        v-model="input.doctor"
        return-object
        clearable
      ></v-combobox>
      <label for="date">Hari, Tanggal</label>
      <v-text-field readonly v-model="input.visitDate" dense></v-text-field>
      <label v-if="input.doctor">Jam Praktik</label>
      <v-select
        v-if="input.doctor"
        append-icon="mdi-chevron-down"
        return-object
        dense
        :loading="loading.time"
        :items="resource.time"
        item-text="time"
        placeholder="Pilih Jam Praktik"
        v-model="input.time"
      ></v-select>
      <label v-if="input.doctor">Kuota Pasien</label>
      <v-text-field
        v-if="input.doctor"
        :loading="loading.time"
        dense
        placeholder="Sisa Kuota"
        v-model="input.time.quota"
        readonly
      ></v-text-field>
    </div>
    <v-card-actions class="action-footer d-flex justify-space-between">
      <div class="d-flex">
        <v-btn
          color="primary"
          class="mr-5 font-weight-bold"
          outlined
          @click="backToSocialData"
          ><v-icon left>mdi-arrow-left</v-icon> Data Pasien</v-btn
        >
        <v-btn
          color="primary"
          outlined
          v-if="$store.getters.mjknPatient.isInputTTV"
          @click="backToTtv"
          class="font-weight-bold"
          ><v-icon left>mdi-arrow-left</v-icon> TTV</v-btn
        >
        <!-- v-if="isSelectAnamnesis" -->
      </div>
      <v-btn
        class="text-none"
        :disabled="!isScheduleAvailable || !input.doctor"
        depressed
        :loading="loading.checkin"
        color="primary"
        @click="checkin()"
      >
        Check-in
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Constant from '@/const';
import alertMixin from '@/mixin/alertMixin';

const axios = require('axios');
const moment = require('moment-timezone');
export default {
  name: 'CheckinMJKN',
  mixins: [alertMixin],
  data() {
    return {
      loading: {
        schedule: false,
        doctor: false,
        time: false,
        checkin: false,
      },
      input: {
        isSick: true,
        visitDate: null,
        doctor: '',
        polyclinic: {
          id: '',
          name: '',
        },
        time: {
          quota: 0,
          time: '',
        },
      },
      resource: {
        polyclinic: [],
        doctors: [],
        days: [],
        time: [],
      },
    };
  },
  computed: {
    today() {
      return moment().format('YYYY/MM/DD');
    },
    isScheduleAvailable() {
      return (
        moment().diff(
          moment(this.input.time.time.split(' ')[2], 'HH:mm'),
          'minutes',
        ) <= 0
      );
    },
  },
  mounted() {
    this.input = {
      ...this.input,
      polyclinic: {
        name: this.$store.getters.selectedPatient.meta.poly[0],
        id: this.$store.getters.selectedPatient.meta.polyId,
      },
      visitDate: moment()
        .locale('id')
        .format('dddd, Do MMMM YYYY'),
    };
    this.getDoctorToday();
  },
  methods: {
    closeForm() {
      this.$emit('close-form');
    },
    backToTtv() {
      this.$emit('back-to-ttv');
    },
    backToSocialData() {
      this.$emit('back-to-social-data');
    },
    async checkin() {
      this.loading.checkin = true;
      const social = this.$store.getters.mjknPatient;
      let payload = {
        id_emr: social.idEmr,
        id_registration: social.idRegistration,
        isCheckIn: true,
        schedule: {
          id_unit: this.$store.getters.selectedPatient.meta.polyId,
          id_staff: this.input.doctor.doctorId,
          time: this.input.time.time,
          date: moment().format('YYYY-MM-DD'),
        },
        patient: {
          address: social.address,
          assurance: {
            name: social.assurance.name,
            number: social.assurance.number,
            isProlanis: social.assurance.isProlanis,
            type: 'bpjs',
          },
          age: social.age,
          name: social.name,
          nickname: social.nickname,
          gender: social.gender,
          birth: {
            date: social.birth.date,
            place: social.birth.place,
          },
          phone_number: social.phoneNumber,
          identity: social.identity,
          ethnicity: social.ethnicity,
          language_mastering: social.languange,
          communication_obstacles: social.communicationObstacles,
          disabilities: social.disabilites,
          religion: social.religion,
          blood_type: social.bloodType,
          education: social.education,
          profession: social.profession,
          father: social.father,
          mother: social.mother,
          martial_status: social.martialStatus,
          timestamps: {
            created_by: this.$store.getters.userLoggedIn.id,
          },
        },
      };
      if (social.isInputTTV) {
        payload = {
          ...payload,
          ttv: social.ttv,
        };
      }
      try {
        const response = await axios.post(
          Constant.apiUrl.concat(`/patient/registration/checkin`),
          payload,
        );
        if (response) {
          this.showFillSuccess('Pasien Berhasil Check-in');
          this.$emit('open-print-dialog');
          this.$store.commit('resetMjknPatient');
        }
      } catch (error) {
        this.showErrorAxios(error);
      } finally {
        this.loading.checkin = false;
      }
    },
    getDoctorToday() {
      this.loading.doctor = true;
      this.doctorToday = [];
      axios
        .post(Constant.apiUrl.concat('/schedule/'), {
          id_unit: this.input.polyclinic.id,
          startDate: this.today,
          endDate: this.today,
        })
        .then(response => {
          const data = response.data.data;
          data.forEach(doctor => {
            this.doctorToday.push(doctor.id_staff);
          });
        })
        .finally(() => {
          this.getDoctor();
        });
    },
    getDoctor() {
      axios
        .get(Constant.apiUrl.concat(`/master/staff?id_unit=`))
        .then(response => {
          if (response.status === 200) {
            var data = response.data.data;
            this.resource.doctors = data
              .filter(
                v =>
                  v.role.role.toLowerCase() === 'dokter' &&
                  v.role.status === true &&
                  this.doctorToday.includes(v.detail.name),
              )
              .map(doctor => {
                return {
                  doctorId: doctor._id.toString(),
                  doctorName: doctor.detail.name,
                };
              });
            this.loading.doctor = false;
          }
        });
    },
    getScheduleDoctor() {
      this.loading.schedule = true;
      this.resource.time = [];
      this.loading.time = true;
      axios
        .post(Constant.apiUrl.concat('/schedule/dropdown'), {
          id_unit: this.input.polyclinic?.id,
          id_staff: this.input.doctor?.doctorId,
        })
        .then(response => {
          if (response.status == 200) {
            const [data] = response.data.data;
            data.schedule
              .filter(item => item.date === moment().format('YYYY-MM-DD'))
              .forEach(item => {
                item.schedule
                  .filter(sch => sch.date === moment().format('YYYY-MM-DD'))
                  .forEach(v => {
                    this.resource.time.push({
                      date: v.date,
                      startTime: v.startTime,
                      endTime: v.endTime,
                      time: v.time,
                      quota: v.quota,
                    });
                  });
              });
            this.resource.time.sort((a, b) => {
              // Mengambil nilai jam dan menit dari string
              const aTime = a.endTime.split(':');
              const bTime = b.endTime.split(':');

              // Membandingkan nilai jam
              if (aTime[0] < bTime[0]) {
                return -1;
              } else if (aTime[0] > bTime[0]) {
                return 1;
              } else {
                // Jika nilai jam sama, bandingkan nilai menit
                if (aTime[1] < bTime[1]) {
                  return -1;
                } else if (aTime[1] > bTime[1]) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
            for (let i = 0; i < this.resource.time.length; i++) {
              if (
                moment().diff(
                  moment(
                    `${this.resource.time[i].date} ${this.resource.time[i].endTime}`,
                    'YYYY-MM-DD HH:mm',
                  ),
                  'minutes',
                ) <= 0
              ) {
                this.input.time = {
                  time: this.resource.time[i].time,
                  quota: this.resource.time[i].quota,
                };
                break;
              }
            }
          }
        })
        .catch(error => {
          this.showErrorAxios(error);
        })
        .finally(() => {
          this.loading.time = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  position: relative;
  min-height: 50vh;
  @media screen and (max-width: 1903px) {
    min-height: 60vh;
  }

  .grid-display {
    display: grid;
    padding: 0.5rem 3rem;
    grid-template-columns: 40% 60%;
    align-items: center;
    row-gap: 0.6rem;
    margin-bottom: 2rem;

    label {
      text-align: start;
    }
  }

  .register-container {
    height: 35vh;
  }

  .close-btn {
    position: absolute;
    left: 560px;
    top: 10px;
  }
  .action-footer {
    width: 100%;
    padding: 0px 2.7rem;
    position: absolute;
    bottom: 1rem;
  }
}
</style>
