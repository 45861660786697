import { render, staticRenderFns } from "./StorageRequest.vue?vue&type=template&id=90b8bf9e&scoped=true&"
import script from "./StorageRequest.vue?vue&type=script&lang=js&"
export * from "./StorageRequest.vue?vue&type=script&lang=js&"
import style0 from "./StorageRequest.vue?vue&type=style&index=0&id=90b8bf9e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90b8bf9e",
  null
  
)

export default component.exports