<template>
  <v-card class="pa-3">
    <v-form ref="form">
      <v-row class="px-15 mx-10 mt-0 mb-16 scrollbar">
        <v-col>
          <v-row>
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold">Nomor Surat</label>
            </v-col>
            <v-col cols="7" class="text-left">
              <label class="">{{ numberLetter }}</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold">Nama Pasien</label>
            </v-col>
            <v-col cols="7" class="text-left">
              <label class="">{{ patientData.meta.name }}</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold">No RM</label>
            </v-col>
            <v-col cols="7" class="text-left">
              <label class="">{{ patientData.rmNumber }}</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold">Tanggal Lahir</label>
            </v-col>
            <v-col cols="7" class="text-left">
              <label class="">{{ patientData.meta.birthDate }}</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold">Jenis Kelamin</label>
            </v-col>
            <v-col cols="7" class="text-left">
              <label class="">{{ patientData.meta.gender }}</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold">Alamat</label>
            </v-col>
            <v-col cols="7" class="text-left">
              <label class="">{{ patientData.meta.address }}</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold">Asal Klinik</label>
            </v-col>
            <v-col cols="7" class="text-left">
              <label class="">{{ patientData.meta.polyName }}</label>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold">Tipe Penjamin</label>
            </v-col>
            <v-col cols="7">
              <v-select
                :rules="[v => !!v || 'Wajib diisi']"
                dense
                class="pt-0 mt-0"
                placeholder="Pilih Tipe"
                :items="resource.guarantorType"
                v-model="input.insurance_type"
              ></v-select>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold">Jenis Kasus</label>
            </v-col>
            <v-col cols="7">
              <v-autocomplete
                :rules="[v => !!v || 'Wajib diisi']"
                dense
                clearable
                auto-select-first
                class="pt-0 mt-0"
                placeholder="Pilih Jenis"
                :items="resource.caseType"
                v-model="input.case_type"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <!-- <v-row align="center">
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold">Ruang Perawatan</label>
            </v-col>
            <v-col cols="7">
              <v-autocomplete
                clearable
                auto-select-first
                dense
                class="pt-0 mt-0"
                placeholder="Pilih Ruang"
                hide-details="false"
                v-model="input.ward"
                :items="resource.rooms"
                item-value="text"
              ></v-autocomplete>
            </v-col>
          </v-row> -->
        </v-col>
        <v-col cols="6">
          <template v-for="(doctor, index) in input.dpjp">
            <v-row align="center" :key="'dpjp-' + index">
              <v-col cols="4" class="text-left">
                <label class="font-weight-bold"
                  >Nama DPJP {{ index + 1 }}
                  {{ index === 0 ? '(Utama)' : '' }}</label
                >
              </v-col>
              <v-col cols="5">
                <v-autocomplete
                  dense
                  clearable
                  auto-select-first
                  class="pt-0 mt-0"
                  :placeholder="'Nama DPJP ' + (index + 1)"
                  :rules="index === 0 ? [v => !!v > 0 || 'Wajib diisi'] : []"
                  v-model="input.dpjp[index]"
                  :items="resource.doctor"
                  @change="addDoctor()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="1">
                <v-btn icon @click="deleteItem('doctor', index)">
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <v-row align="center">
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold">Jenis Pelayanan</label>
            </v-col>
            <v-col cols="5">
              <v-select
                class="pt-0"
                placeholder="Pilih jenis pelayanan"
                hide-details="false"
                dense
                :items="resource.serviceType"
                v-model="input.service_type"
              ></v-select>
            </v-col>
          </v-row>
          <template v-for="(diagnose, index) in input.diagnosis_in">
            <v-row align="center" :key="'diagnose-' + index">
              <v-col cols="4" class="text-left">
                <label class="font-weight-bold"
                  >Diagnosa Masuk {{ index + 1 }}</label
                >
              </v-col>
              <v-col cols="5">
                <v-combobox
                  dense
                  :placeholder="'Pilih Diagnosa Masuk ' + (index + 1)"
                  hide-details="false"
                  :items="resource.diagnose"
                  :loading="diagnoseLoading"
                  item-text="text"
                  v-model="input.diagnosis_in[index]"
                  @change="changeDiagnose($event, index)"
                  :search-input.sync="searchDiagnose[index]"
                  class="pt-0 mt-0"
                  :rules="index === 0 ? [v => !!v > 0 || 'Wajib diisi'] : []"
                  clearable
                ></v-combobox>
              </v-col>
              <v-col cols="1">
                <v-btn icon @click="deleteItem('diagnose', index)">
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </v-col>
      </v-row>
    </v-form>
    <v-card-actions class="pa-0">
      <v-btn class="text-none" plain @click="$emit('close-form')">
        Kembali
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        tile
        outlined
        color="primary"
        width="116"
        class="text-none"
        @click="printInpatientLetter()"
      >
        <font-awesome-icon icon="print" />
        Print
      </v-btn>
      <v-btn
        color="primary"
        tile
        class="text-none"
        width="108"
        @click="addInpatientLetter()"
        >Simpan</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import Constant from '@/const';
import Swal from 'sweetalert2';
import AlertMixin from '@/mixin/alertMixin';
import Letter from '@/mixin/Letter';
import 'jspdf-autotable';
import jwtMixin from '@/mixin/jwtMixin';
import printMixin from '@/mixin/printMixin';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'InpatientWarrant',
  mixins: [AlertMixin, jwtMixin, printMixin, Letter],
  props: {
    patientData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      clientData: this.$store.getters.userLoggedIn.clinic,
      doctorSip: '',
      date: null,
      resource: {
        guarantorType: ['Umum', 'BPJS', 'Asuransi'],
        caseType: [
          'Bedah',
          'Non Bedah',
          'Trauma',
          'Non- trauma',
          'Obsgyn',
          'Interna',
          'Anak',
          'Syaraf',
          'Jantung',
        ],
        serviceType: ['Preventif', 'Kuratif', 'Rehabilitatif', 'Paliatif'],
        diagnose: [],
        doctor: [],
        // rooms: [],
      },
      input: {
        insurance_type: '',
        case_type: '',
        ward: 'Umum',
        dpjp: [''],
        diagnosis_in: [''],
        service_type: '',
      },
      diagnoseLoading: false,
      searchDiagnose: [],
      timestamps: new Date(),
      numberLetter: '',
    };
  },
  computed: {
    letterData() {
      return Constant.client.find(
        item =>
          item.name.toLowerCase() ===
          this.$store.getters.userLoggedIn.clinic.name.toLowerCase(),
      );
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.runApiCalls(() => {
        // this.getRoom();
        this.getDoctor();
        // this.generateNumber();
        this.getInpatientLetter();
      });
      this.getDiagnose('');
    });
  },
  watch: {
    searchDiagnose(val) {
      const oldValues = this.input.diagnosis_in.map(diagnosa => {
        return diagnosa;
      });
      const index = val.findIndex((v, i) => {
        return v !== oldValues[i];
      });
      this.diagnoseLoading = true;
      // this.searchDataDiagnose(this, index);
      this.getDiagnose(val[index]);

      this.getDiagnose('');
    },
  },
  methods: {
    // searchDataDiagnose: _.debounce(
    //   (v, index) => v.getDiagnose(v.searchDiagnose[index]),
    //   650,
    // ),
    addDiagnose() {
      this.input.diagnosis_in.push('');

      // this.getDiagnose('');
    },
    changeDiagnose(event, index) {
      if (event.keyCode === 9) return;
      if (this.input.diagnosis_in[index] !== undefined) {
        this.addDiagnose();
      } else if (
        (this.input.diagnosis_in[index] === '' &&
          this.input.diagnosis_in[index] === null) &
        (this.input.diagnosis_in.length !== 1)
      ) {
        this.input.diagnosis_in.splice(index, 1);
      }
    },
    addDoctor() {
      this.input.dpjp.push('');
    },
    async getDiagnose(keyword) {
      this.diagnoseLoading = true;
      await axios
        .get(
          Constant.apiUrl.concat(
            `/master/diagnose?page=1&itemCount=100&search=${
              keyword === null ? '' : keyword
            }&sort=-code`,
          ),
        )
        .then(response => {
          let allDiagnose = response.data.data;
          this.resource.diagnose = allDiagnose.map(diagnose => {
            return {
              id: diagnose._id.toString(),
              code: diagnose.code,
              text: diagnose.description,
            };
          });
          this.diagnoseLoading = false;
        })
        .catch(() => {
          this.diagnoseLoading = false;
        });
    },
    deleteItem(type, index) {
      switch (type) {
        case 'diagnose':
          if (index !== 0) {
            this.input.diagnosis_in.splice(index, 1);
          } else {
            this.input.diagnosis_in = [''];
          }
          break;
        case 'doctor':
          if (index !== 0) {
            this.input.dpjp.splice(index, 1);
          } else {
            this.input.dpjp = [''];
          }
          break;
        default:
          break;
      }

      this.getDiagnose('');
    },
    getDoctor() {
      axios.get(Constant.apiUrl.concat('/master/staff')).then(response => {
        if (response.status === 200) {
          var data = response.data.data;
          this.resource.doctor = data
            .filter(
              v => v.role.role.toLowerCase() === 'dokter' && v.role.status,
            )
            .map(doctor => doctor.detail.name);
        }
      });
    },
    addInpatientLetter() {
      if (this.$refs.form.validate()) {
        const payload = {
          id_registration: this.patientData.id_registration,
          letter_number: this.numberLetter,
          detail: {
            ...this.input,
            dpjp: this.input.dpjp.filter(x => x),
            diagnosis_in: this.input.diagnosis_in
              .filter(x => x)
              .map(arr => {
                return arr.id;
              }),
            timestamps: {
              created_by: this.$store.getters.userLoggedIn.id,
            },
          },
        };

        axios
          .post(
            Constant.apiUrl.concat('/letter/control-inpatient-letter'),
            payload,
          )
          .then(() => {
            Swal.fire(
              'Surat Keterangan Rawat Inap Berhasil Dibuat',
              '',
              'success',
            );
            this.getInpatientLetter();
          })
          .catch(err => {
            this.showErrorAxios(err);
          });
      }
    },
    printInpatientLetter() {
      const doc = this.generateInpatientLetter(
        {
          ...this.patientData,
          numberLetter: this.numberLetter,
          doctorSip: this.doctorSip,
          timestamps: this.timestamps,
        },
        this.input,
      );
      this.printDocument(doc, {
        docName: 'Surat Perintah Rawat Inap',
        rmNumber: this.patientData.rmNumber,
      });
    },
    generateNumber() {
      axios
        .get(
          Constant.apiUrl.concat(`/letter/control-inpatient-letter/generate/`),
        )
        .then(response => {
          this.numberLetter = response.data.data;
        });
    },
    async getDataAssessment() {
      try {
        const response = await axios.get(
          Constant.apiUrl.concat(
            `/patient/checkup/regist/${this.patientData.id_registration}/service`,
          ),
        );
        const { data } = response.data;
        let diagnose;
        if (data.assesment?.main_diagnose) {
          diagnose = [
            {
              _id: data.assesment?.main_diagnose?.diagnose._id,
              description: data.assesment?.main_diagnose?.diagnose.description,
            },
          ];
          this.remapDiagnose(diagnose);
          this.addDiagnose();
        }
      } catch {
        this.input = {
          ...this.input,
        };
      }
    },
    remapDiagnose(diagnose) {
      let diagnosis_in = [''];
      diagnosis_in = diagnose.map(v => {
        return {
          id: v._id,
          text: v.description,
        };
      });
      this.input = {
        ...this.input,
        diagnosis_in,
      };
    },
    async getStaffByName(name) {
      const res = await axios.get(Constant.apiUrl.concat(`/master/staff`));
      const { data } = res.data;
      const filteredData = data.filter(d => d.detail.name === name);
      this.doctorSip = filteredData[0].detail.sip;
    },
    getInpatientLetter() {
      axios
        .get(
          Constant.apiUrl.concat(
            `/letter/control-inpatient-letter/registration/${this.patientData.id_registration}`,
          ),
        )
        .then(response => {
          const inpatient = response.data.data;
          this.input = {
            insurance_type: _.get(inpatient, 'detail.insurance_type', ''),
            case_type: _.get(inpatient, 'detail.case_type', ''),
            dpjp: _.get(inpatient, 'detail.dpjp', ['']).filter(x => x !== null),
            service_type: _.get(inpatient, 'detail.service_type', ''),
          };
          this.getStaffByName(this.input.dpjp[0]);
          this.numberLetter = inpatient.letter_number;
          this.remapDiagnose(inpatient.detail.diagnosis_in);
          this.addDiagnose();
        })
        .catch(err => {
          if (err.response.status === 404) {
            this.generateNumber();
            this.getDataAssessment();
            this.input.dpjp[0] = this.patientData.meta.doctor;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.body-container {
  padding-left: 150px;
}
.form-body {
  &__title {
    font-size: 14px;
    font-weight: 700;
    color: black;
    text-align: left;
  }
  &__data {
    height: 25px;
  }
}

.scrollbar {
  height: 400px;
  overflow-y: auto;

  /* Custom scrollbar */
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #9e9e9e;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: #c2c2c2;
  }
}
</style>
