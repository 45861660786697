import EventBus from '.';

export default {
  methods: {
    busListener() {
      EventBus.$on('message:call', payload => {
        this.$socket.client.emit('message:call', payload);
      });
      EventBus.$on('message:call-sound', payload => {
        this.$socket.client.emit('message:call-sound', payload);
      });
      EventBus.$on('message-update', async ({ refresh, isCalling, data }) => {
        await refresh(); // refresh data on page
        if (isCalling && data.queue_code) this.callPatient(data);
      });
    },
  },
};
