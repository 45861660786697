<template>
  <div>
    <v-container fluid>
      <v-row class="pb-3">
        <v-col cols="5" class="text-left pb-0">
          <h2>Laporan Pasien Rujukan BPJS</h2>
          <h5>Update: {{ timestamp }}</h5>
        </v-col>
        <v-col class="pt-4 pb-0 text-right">
          <v-btn tile elevation="0" @click="filtersClicked = !filtersClicked">
            <v-icon> mdi-filter-variant </v-icon>
            <span class="text-none">Filters</span>
          </v-btn>
        </v-col>
        <v-col class="pt-4 pb-0" style="max-width: 100px">
          <v-btn
            tile
            elevation="0"
            @click="exportBPJSPatient()"
            color="primary"
          >
            <span class="text-none">Export</span>
          </v-btn>
        </v-col>
        <v-col class="icon-head" align-self="center">
          <router-link :to="routeBack">
            <v-icon>mdi-home</v-icon>
          </router-link>
        </v-col>
        <v-col class="icon-head pl-0" align-self="center">
          <v-btn icon @click="refresh()">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="3" class="pl-0" align-self="center">
          <v-text-field
            class="input"
            background-color="grey lighten-3"
            placeholder="Search"
            solo
            dense
            hide-details="false"
            flat
            prepend-inner-icon="mdi-magnify"
            single-line
            v-model="search"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-show="!filtersClicked">
        <v-col cols="6"></v-col>
        <v-col cols="2" class="pt-0 pb-0">
          <p
            class="body-2 text-left"
            style="color: #828282; margin-bottom: 8px"
          >
            Tanggal Mulai Dirujuk
          </p>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateStart.formatted"
                :value="dateStart.formatted"
                class="mr-4 input-filter date"
                dense
                flat
                v-on="on"
                v-bind="attrs"
                readonly
                append-icon="mdi-chevron-down"
                placeholder="Rentang Tanggal"
                @change="getBPJSPatient()"
                @click:clear="clearDateStart()"
                single-line
                clearable
                hide-details
                outlined
              ></v-text-field>
            </template>
            <v-date-picker
              multiple
              v-model="selectedDateStart"
              @input="changeDateStart"
              ><v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu.save(dateStart);
                  getBPJSPatient();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="2" class="pt-0 pb-0">
          <p
            class="body-2 text-left"
            style="color: #828282; margin-bottom: 8px"
          >
            Tanggal Akhir Rujukan
          </p>
          <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateEnd.formatted"
                :value="dateEnd.formatted"
                class="mr-4 input-filter date"
                dense
                flat
                v-on="on"
                v-bind="attrs"
                readonly
                append-icon="mdi-chevron-down"
                placeholder="Rentang Tanggal"
                @change="getBPJSPatient()"
                @click:clear="clearDateEnd()"
                single-line
                clearable
                hide-details
                outlined
              ></v-text-field>
            </template>
            <v-date-picker
              multiple
              v-model="selectedDateEnd"
              @input="changeDateEnd"
              ><v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu.save(dateEnd);
                  getBPJSPatient();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="2" class="pt-0 pb-0">
          <p
            class="body-2 text-left"
            style="color: #828282; margin-bottom: 8px"
          >
            Instansi RS
          </p>
          <v-combobox
            append-icon="mdi-chevron-down"
            :items="resource.hospital"
            placeholder="Pilih RS"
            @change="getBPJSPatient()"
            hide-details
            single-line
            outlined
            class="input-filter mr-4"
            dense
            flat
            clearable
            return-object
            item-text="name"
            item-value="name"
            v-model="hospital"
          >
          </v-combobox>
        </v-col>
      </v-row>
      <p class="caption text-left mb-0 pt-3">
        Menampilkan {{ bpjs.items.length }} dari {{ dataLength }} data
      </p>
      <v-divider></v-divider>
      <v-data-table
        hide-default-footer
        :headers="bpjs.headers"
        :items="bpjs.items"
        item-key="index"
        :expanded="expanded"
        single-expand
        :search="search"
        :loading="isLoading"
        :items-per-page="itemsPerRow"
        :page.sync="page"
        @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
      >
        <template v-slot:[`item.no`]="{ item }">
          <tr class="text-left">
            <td>
              {{ (page - 1) * itemsPerRow + bpjs.items.indexOf(item) + 1 }}
            </td>
          </tr>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <tr class="text-left">
            <td>
              <v-btn @click="print(item.id_registration)" small fab text>
                <v-icon color="#848484">mdi-printer</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
        <template v-slot:expanded-item="{ item, headers }">
          <td :colspan="headers.length" class="expand px-0">
            <v-card flat color="#F4F5F5" class="pa-3">
              <v-row>
                <v-col cols="1" style="max-width: 70px"></v-col>
                <v-col cols="2" class="text-left" align-self="center">
                  <p class="expand__caption">No. RM : {{ item.no_rm }}</p>
                </v-col>
                <v-col cols="5"></v-col>
                <v-col cols="2" class="text-left pl-0" align-self="center">
                  <p class="expand__caption">
                    Poli Rujukan : Poli Penyakit Dalam
                  </p>
                </v-col>
              </v-row>
            </v-card>
          </td>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-pagination
          class="pagination"
          v-model="page"
          :length="lengthPage"
          total-visible="5"
          @input="getBPJSPatient()"
        ></v-pagination>
        <v-spacer></v-spacer>
        <router-link :to="showData" active>
          <v-btn color="primary" class="btn-show text-none" tile elevation="0">
            Lihat Keseluruhan
          </v-btn>
        </router-link>
      </v-card-actions>
    </v-container>
    <v-dialog v-model="dialog" max-width="300px">
      <print-BPJS-referral-dialog
        :id-registration="selectedPatientIdRegist"
        @close-form="dialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import Constant from '@/const';
import Swal from 'sweetalert2';
import moment from 'moment-timezone';
import alertMixin from '@/mixin/alertMixin';
import PrintBPJSReferralDialog from '../../SharedComponent/PrintBPJSReferralDialog.vue';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'BPJSPatient',
  mixins: [alertMixin],
  components: { PrintBPJSReferralDialog },
  data() {
    return {
      filtersClicked: true,
      // today: moment().format('YYYY-MM-DD'),
      dateStart: {
        raw: [],
        formatted: '',
      },
      selectedPatientIdRegist: '',
      dateEnd: {
        raw: [],
        formatted: '',
      },
      dialog: false,
      selectedDateStart: [],
      selectedDateEnd: [],
      isRefresh: false,
      expanded: [],
      hospital: '',
      resource: {
        hospital: [],
      },
      timestamp: '',
      menu: false,
      menu2: false,
      dataLength: 0,
      bpjs: {
        headers: [
          { text: 'No', value: 'no' },
          { text: 'Nama Pasien', value: 'patientName' },
          { text: 'No. BPJS', value: 'bpjs_no', sortable: false },
          { text: 'Tanggal Masuk', value: 'firstDate', sortable: false },
          { text: 'Tanggal Keluar', value: 'lastDate', sortable: false },
          { text: 'Diagnosa Rujukan', value: 'diagnoseRef', sortable: false },
          { text: 'Dokter Rujukan', value: 'doctorRef', sortable: false },
          {
            text: 'Instansi RS Rujukan',
            value: 'hospitalRef',
            sortable: false,
          },
          {
            text: '',
            value: 'action',
            sortable: false,
          },
        ],
        items: [],
      },
      search: '',
      page: 1,
      lengthPage: 0,
      isLoading: false,
    };
  },
  watch: {
    search() {
      this.searchData(this);
    },
    selectedDateStart() {
      // this.today = '';
      this.getBPJSPatient();
    },
    selectedDateEnd() {
      // this.today = '';
      this.getBPJSPatient();
    },
  },
  created() {
    setInterval(this.getNow, 500);
  },
  mounted() {
    this.$nextTick(() => {
      this.getBPJSPatient();
      this.getHospital();
    });
  },
  computed: {
    showData() {
      return '/reporting/laporan-pelayanan/pasien-rujukan-bpjs/lihat-keseluruhan';
    },
    routeBack() {
      return '/reporting/laporan-pelayanan';
    },
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
  },
  methods: {
    print(id_registration) {
      this.dialog = true;
      this.selectedPatientIdRegist = id_registration;
    },
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.page = 1;
      v.getBPJSPatient();
    }, 650),
    refresh() {
      this.isRefresh = true;
      this.search = '';
      this.page = 1;
      this.hospital = '';
      this.getBPJSPatient();
    },
    async exportBPJSPatient() {
      let startDatePlan = '';
      let endDatePlan = '';
      if (this.dateStart.raw.length > 0) {
        startDatePlan = moment(this.dateStart.raw[0]).format('DD-MM-YYYY');
        endDatePlan =
          this.dateStart.raw[1] == ''
            ? moment().format('DD-MM-YYYY')
            : moment(this.dateStart.raw[1]).format('DD-MM-YYYY');
      }
      let startDateExp = '';
      let endDateExp = '';
      if (this.dateEnd.raw.length > 0) {
        startDateExp = moment(this.dateEnd.raw[0]).format('DD-MM-YYYY');
        endDateExp =
          this.dateEnd.raw[1] == ''
            ? moment().format('DD-MM-YYYY')
            : moment(this.dateEnd.raw[1]).format('DD-MM-YYYY');
      }
      const hospital = this.hospital ? this.hospital.name : '';
      try {
        const res = await axios.get(
          Constant.apiUrl.concat(
            `/report/referral_pcare_export?search=${this.search}&hospital=${hospital}&startDatePlan=${startDatePlan}&endDatePlan=${endDatePlan}&startDateExp=${startDateExp}&endDateExp=${endDateExp}`,
          ),
        );
        if (res.status === 200) {
          this.showFillSuccess('Export Berhasil');
          const { url } = res.config;
          window.open(url, '_blank');
        }
      } catch (error) {
        if (error) {
          Swal.fire({
            icon: 'error',
            title: 'Terjadi Kesalahan',
            text: 'Tidak dapat mengexport dokumen',
          });
        }
      }
    },
    getNow() {
      const monthNames = [
        'Januari',
        'Februari',
        'Maret',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Agustus',
        'September',
        'Oktober',
        'November',
        'Desember',
      ];
      const today = new Date();
      const date =
        today.getDate() +
        ' ' +
        monthNames[today.getMonth()] +
        ' ' +
        today.getFullYear();
      this.timestamp = date;
    },
    getBPJSPatient() {
      this.isLoading = true;
      const itemCount = this.itemsPerRow;
      let startDatePlan = '';
      let endDatePlan = '';
      if (this.dateStart.raw.length > 0) {
        startDatePlan = moment(this.dateStart.raw[0]).format('DD-MM-YYYY');
        endDatePlan =
          this.dateStart.raw[1] == ''
            ? moment().format('DD-MM-YYYY')
            : moment(this.dateStart.raw[1]).format('DD-MM-YYYY');
      }
      let startDateExp = '';
      let endDateExp = '';
      if (this.dateEnd.raw.length > 0) {
        startDateExp = moment(this.dateEnd.raw[0]).format('DD-MM-YYYY');
        endDateExp =
          this.dateEnd.raw[1] == ''
            ? moment().format('DD-MM-YYYY')
            : moment(this.dateEnd.raw[1]).format('DD-MM-YYYY');
      }
      const hospital = this.hospital ? this.hospital.name : '';

      axios
        .get(
          Constant.apiUrl.concat(
            `/report/patient-referral-pcare?page=${this.page}&itemCount=${itemCount}&search=${this.search}&hospital=${hospital}&startDatePlan=${startDatePlan}&endDatePlan=${endDatePlan}&startDateExp=${startDateExp}&endDateExp=${endDateExp}`,
          ),
        )
        .then(response => {
          const data = response.data.data;
          this.dataLength = response.data.length;
          this.lengthPage = Math.ceil(this.dataLength / itemCount);
          this.bpjs.items = data.map((item, index) => {
            return {
              index: index,
              id_registration: item.id_registration,
              id: item.patient._id,
              firstDate: item.created_at,
              lastDate: item.created_at,
              patientName: item.patient.name,
              bpjs_no: item.patient.assurance.number,
              diagnoseRef: item.diagnose?.description,
              hospitalRef: item.detail.instansi_name,
              no_rm: item.medical_record_number,
              doctorRef: item.doctor.name,
            };
          });

          this.isLoading = false;
        })
        .catch(() => {
          this.bpjs.items = [];
          this.dataLength = 0;
          this.lengthPage = 0;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getHospital() {
      axios
        .get(Constant.apiUrl.concat('/integration/pcare/provider'))
        .then(response => {
          if (response.status === 200) {
            const { response: data } = response.data.data;
            this.resource.hospital = data.list.map(h => {
              return {
                code: h.kdProvider,
                name: h.nmProvider,
              };
            });
          }
        });
    },
    clearDateStart() {
      this.dateStart.raw = [];
      this.selectedDateStart = [];
      this.getBPJSPatient();
    },
    clearDateEnd() {
      this.dateEnd.raw = [];
      this.selectedDateEnd = [];
      this.getBPJSPatient();
    },
    changeDateStart(data) {
      // if user selected 2 date
      if (data.length >= 2) {
        const slicedDate = data.slice(0, 2);
        slicedDate.sort((a, b) => {
          return new Date(a).getTime() - new Date(b).getTime();
        });

        const formateddate = [];
        for (let i = 0; i < slicedDate.length; i++) {
          formateddate.push(
            moment(slicedDate[i], 'YYYY-MM-DD').format('DD-MM-YYYY'),
          );
        }
        this.selectedDate = slicedDate;
        this.dateStart = {
          raw: slicedDate,
          formatted: formateddate.join(' s/d '),
        };
        this.menu = false;
      }

      // if user selected 1 date
      if (data.length === 1) {
        this.dateStart = {
          raw: [data[0], ''],
          formatted: moment(data[0], 'YYYY-MM-DD').format('DD-MM-YYYY'),
        };
      }
    },
    changeDateEnd(data) {
      // if user selected 2 date
      if (data.length >= 2) {
        const slicedDate = data.slice(0, 2);
        slicedDate.sort((a, b) => {
          return new Date(a).getTime() - new Date(b).getTime();
        });

        const formateddate = [];
        for (let i = 0; i < slicedDate.length; i++) {
          formateddate.push(
            moment(slicedDate[i], 'YYYY-MM-DD').format('DD-MM-YYYY'),
          );
        }
        this.selectedDate = slicedDate;
        this.dateEnd = {
          raw: slicedDate,
          formatted: formateddate.join(' s/d '),
        };
        this.menu2 = false;
      }

      // if user selected 1 date
      if (data.length === 1) {
        this.dateEnd = {
          raw: [data[0], ''],
          formatted: moment(data[0], 'YYYY-MM-DD').format('DD-MM-YYYY'),
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-head {
  max-width: 50px;
}
.pagination {
  position: absolute;
  bottom: 50px;
}
.btn-show {
  position: absolute;
  bottom: 50px;
  right: 50px;
}
.expand {
  background-color: #f4f5f5;
  &__caption {
    color: #828282;
    font-size: 12px;
  }
}
::v-deep {
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border: none;
  }
}
</style>
