<template>
  <section class="pa-5 d-flex align-center justify-space-between">
    <div class="d-flex align-center" style="width: 40vw;">
      <img
        width="120vw"
        src="@/assets/image/NeuroviLogoSVG.svg"
        alt="clinic-logo"
        v-if="clinic === 'neurovi clinic medical system'"
      />
      <img
        v-if="clinic === 'klinik permata keluarga'"
        width="120vw"
        src="@/assets/image/PKLogo.png"
        alt="clinic-logo"
      />
      <img
        v-if="clinic === 'klinik bangun husada'"
        width="120vw"
        src="@/assets/image/bhlogo.png"
        alt="clinic-logo"
      />
      <img
        v-if="clinic === 'klinik jogja medical center'"
        width="120vw"
        src="@/assets/image/jmcLogo.png"
        alt="clinic-logo"
      />
      <h2 class="text-capitalize ml-5 font-weight-regular">
        {{ clinic }}
      </h2>
    </div>
    <div class="d-flex">
      <img
        max-width="6vw"
        src="@/assets/image/NeuroviLogoSVG.svg"
        alt="clinic-logo"
      />
      <div class="time">
        <h2>
          {{ currentTime }}
        </h2>
      </div>
    </div>
  </section>
</template>

<script>
const moment = require('moment-timezone');

export default {
  name: 'DashboardQueueHeader',
  props: {
    clinic: String,
  },
  data() {
    return {
      currentTime: '',
    };
  },
  mounted() {
    this.updateTime();
    this.startClock();
  },
  methods: {
    updateTime() {
      this.currentTime = moment().format('HH:mm');
    },
    startClock() {
      setInterval(this.updateTime, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.time {
  border: 2px solid $primary-color;
  color: $primary-color;
  border-radius: 7px;
  padding: 0.3vw 0.8vw;
  margin-left: 1vw;
}
</style>
