<template>
  <v-card>
    <v-card-title class="justify-center font-weight-bold"
      >Pengiriman Barang Gudang

      <v-btn depressed icon absolute right>
        <v-icon @click="onCloseButtonClick()">
          mdi-close-thick
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-container>
      <v-row>
        <v-col cols="6">
          <v-row class="pr-16">
            <v-col cols="6" class="text-left mt-2">
              <label for="Unit Asal">Gudang</label>
            </v-col>
            <v-col cols="6" class="text-left mt-2">
              <label for="Permintaan"> Pilih Unit</label>
            </v-col>
          </v-row>
          <v-row class="pr-16">
            <v-col cols="6" class="text-left mt-2">
              <label>Gudang Farmasi</label>
            </v-col>
            <v-col cols="6">
              <v-select
                dense
                hide-details="auto"
                :items="table.storage"
                v-model="input.storage"
                placeholder="Pilih Unit"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-row class="pr-16">
            <v-col cols="6" class="mt-14 text-left">
              <v-btn depressed color="primary" v-if="table.isTableDrugsShow">
                Tambah
              </v-btn>
            </v-col>
            <v-col cols="6" class="mt-14">
              <v-text-field
                placeholder="Nama Barang"
                append-icon="mdi-magnify"
                dense
                hide-details="auto"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-main>
      <v-container>
        <v-row>
          <v-col>
            <v-data-table
              :headers="goodheaders"
              :items="desserts"
              :items-per-page="5"
              hide-default-footer
            >
              <template v-slot:item="{ item }">
                <tr class="text-left" :key="item.index">
                  <td>{{ item.noQueue }}</td>
                  <td>{{ item.itemsName }}</td>
                  <td>{{ item.expiredDate }}</td>
                  <td>{{ item.batch }}</td>
                  <td>{{ item.itemsStockFrom }}</td>

                  <td>{{ item.package }}</td>
                  <td>
                    <v-simple-checkbox
                      v-model="item.correction"
                    ></v-simple-checkbox>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer color="white" class="pb-4">
      <v-container>
        <v-row>
          <v-col class="text-right">
            <v-spacer />
            <v-btn depressed color="primary">
              Simpan
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      tabs: true,

      input: {
        storage: '',
      },
      searchTo: '',
      filters: {
        search: '',
        itemsCategory: '',
      },

      goodheaders: [
        {
          text: 'No',

          value: 'noQueue',
        },
        { text: 'Nama Barang', value: 'itemsName', width: '350' },
        { text: 'Expired Date', value: 'expiredDate' },
        { text: 'Batch', value: 'batch' },
        { text: 'Sisa Gudang', value: 'itemsStockFrom' },

        { text: 'Kemasan', value: 'package' },
        { text: 'Koreksi', value: 'correction' },
      ],
      desserts: [
        {
          noQueue: 1,
          itemsName: 'Omeprazole',
          expiredDate: '31/12/2020',
          batch: 'P101',

          itemsStockFrom: '470',
          package: 'Fls',

          correction: false,
        },
      ],
      table: {
        isTableGoodsShow: false,
        isTableDrugsShow: false,
        storage: [
          { text: 'Gudang Farmasi', value: 'drugs' },
          { text: 'Gudang Rumah Tangga', value: 'goods' },
        ],
      },
    };
  },
  methods: {
    onCloseButtonClick() {
      this.$emit('close-dialog');
    },
  },
};
</script>

<style></style>
