<template>
  <v-card>
    <v-card-title class="justify-center">
      <span class="font-weight-bold">Tambahkan Unit Baru</span>
      <v-btn absolute right icon @click="onCloseButtonClick()">
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row no-gutters>
          <v-col>
            <v-row no-gutters>
              <v-form lazy-validation ref="form">
                <v-col cols="5">
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="name">Kode Unit</label>
                    </div>
                    <v-text-field
                      disabled
                      v-model="input.unit_number"
                      class="detail-input__input-text"
                      required
                      :rules="[v => !!v || 'Wajib diisi']"
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="barcode">Nama Unit</label>
                    </div>
                    <v-text-field
                      placeholder="Masukkan Nama Unit"
                      class="detail-input__input-text"
                      v-model="input.name"
                      :rules="[v => !!v || 'Wajib diisi']"
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="category">Instalasi</label>
                    </div>
                    <v-combobox
                      placeholder="Pilih Instalasi"
                      class="detail-input__input-text"
                      v-model="input.installation"
                      :items="resource.installation"
                      required
                      :rules="[v => !!v || 'Wajib diisi']"
                      dense
                    ></v-combobox>
                  </div>
                </v-col>
                <v-col cols="5">
                  <div class="detail-radio">
                    <div class="detail-input__label">
                      <label for="gudang">Gudang</label>
                    </div>
                    <v-radio-group
                      :mandatory="false"
                      v-model="input.isWarehouse"
                      row
                      class="mt-0 pt-0"
                      required
                      :rules="[v => !!v || v === false || 'Wajib diisi']"
                    >
                      <v-radio label="Ya" :value="true"></v-radio>
                      <v-radio label="Tidak" :value="false"></v-radio>
                    </v-radio-group>
                  </div>
                  <div class="detail-radio">
                    <div class="detail-input__label">
                      <label for="farmasi">Farmasi</label>
                    </div>
                    <v-radio-group
                      :mandatory="false"
                      v-model="input.isPharmacy"
                      row
                      :rules="[v => !!v || v === false || 'Wajib diisi']"
                      required
                      class="mt-0 pt-0"
                    >
                      <v-radio label="Ya" :value="true"></v-radio>
                      <v-radio label="Tidak" :value="false"></v-radio>
                    </v-radio-group>
                  </div>
                </v-col>
              </v-form>
              <v-col cols="12">
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="text-none"
                    width="100"
                    text
                    @click="onCloseButtonClick()"
                  >
                    Kembali
                  </v-btn>
                  <v-btn
                    class="text-none"
                    color="primary"
                    width="100"
                    depressed
                    @click="addNewUnit()"
                  >
                    Simpan
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import Constant from '@/const';
const axios = require('axios');
import Swal from 'sweetalert2';

export default {
  name: 'InputNewUnit',
  data() {
    return {
      isLoading: false,
      search: null,
      menu: false,
      resource: {
        installation: [],
      },
      input: {
        unit_number: '',
        name: '',
        isPharmacy: null,
        isWarehouse: null,
        installation: '',
      },
    };
  },
  mounted() {
    this.getInstallation();
    this.getLastUnit();
  },
  methods: {
    getInstallation() {
      axios
        .get(Constant.apiUrl.concat('/master/installation'))
        .then(response => {
          const installationData = response.data.data;
          this.resource.installation = installationData.map(inst => {
            return inst.name;
          });
        });
    },
    getLastUnit() {
      axios
        .get(Constant.apiUrl.concat('/master/unit?sorts=unit_number:-1'))
        .then(response => {
          const unitData = response.data.data;
          const maxNumber = unitData.map(u => {
            return Number(u.unit_number);
          });
          this.input.unit_number = Math.max(...maxNumber) + 1;
        });
    },
    addNewUnit() {
      if (this.$refs.form.validate()) {
        axios
          .post(Constant.apiUrl.concat('/master/unit/'), {
            unit_number: this.input.unit_number,
            name: this.input.name,
            detail: [
              {
                isPharmacy: this.input.isPharmacy,
                isWarehouse: this.input.isWarehouse,
                installation: this.input.installation,
              },
            ],
          })
          .then(response => {
            if (response.status === 200) {
              Swal.fire('Berhasil', 'Data Unit Sudah Masuk', 'success');
              this.getLastUnit();
              this.onCloseButtonClick();
              this.input.name = '';
              this.input.installation = '';
              this.input.isPharmacy = null;
              this.input.isWarehouse = null;
            }
          })
          .catch(error => {
            if (error.response) {
              const message = error.response.data.pesan;
              Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: message,
              });
            } else if (error.request) {
              Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: 'Tidak dapat menyambung ke server',
              });
            }
          });
      }
    },
    onCloseButtonClick() {
      this.$emit('close-dialog');
    },
  },
};
</script>

<style lang="scss" scoped>
.column {
  margin: 0 auto;
}

.detail-radio {
  display: flex;
  justify-content: flex-end;
  // align-items: center;
}

.detail-input {
  display: grid;
  grid-template-columns: 150px 500px;
  align-items: baseline;

  &__label {
    justify-content: flex-end;
    flex: 1;
    display: flex;
    color: #222;
    margin-right: 10px;

    &--position {
      position: relative;
      right: 140px;
    }
  }

  &__input-text {
    margin-left: 10px;
    max-width: 40%;
  }
}

.padding {
  padding-left: 10px;
}

.v-input__control {
  display: flex;
  align-self: center;
}

.radio {
  margin: 0;
}

.table-kemasan {
  width: 100%;
  margin-top: 20px;
}
</style>
