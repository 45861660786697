<template>
  <v-card class="card-layout">
    <v-card-title class="justify-center">
      <h4>Permintaan Barang Unit</h4>
    </v-card-title>
    <v-card-text class="text-left">
      <label class="font-weight-bold grey--text">DETAIL TRANSAKSI</label>
      <v-card color="#fafafa" flat class="card-detail pa-3 my-3">
        <div class="grid-detail">
          <v-row class="grid-row mr-5" no-gutters>
            <label class="caption grey--text">Kode</label>
            <label class="font-weight-bold body-2">MUTASI-0001</label>
          </v-row>
          <v-row class="grid-row mr-5" no-gutters>
            <label class="caption grey--text">Tanggal</label>
            <label class="font-weight-bold body-2">2021-02-02</label>
          </v-row>
          <v-row class="grid-row mr-5" no-gutters>
            <label class="caption grey--text">Dari</label>
            <label class="font-weight-bold body-2">Farmasi IGD</label>
          </v-row>
          <v-row style="display: grid;" no-gutters>
            <label class="caption grey--text">Ke</label>
            <label class="font-weight-bold body-2">Pelayanan Farmasi RJ</label>
          </v-row>
        </div>
      </v-card>

      <label class="font-weight-bold grey--text">DETAIL PERMINTAAN</label>
      <v-data-iterator
        :items="table.items"
        hide-default-footer
        style="height: 55vh;"
      >
        <template v-slot:header>
          <v-toolbar flat dense>
            <v-toolbar-items class="table-header grey--text text--darken-3">
              <template v-for="(item, index) in table.headers">
                <div
                  :key="'header-' + index"
                  :class="{ 'text-center': index !== 1 }"
                >
                  <span class="caption font-weight-bold">{{ item.text }} </span>
                </div>
              </template>
            </v-toolbar-items>
          </v-toolbar>
        </template>
        <template v-slot:item="props">
          <v-card max-width="900" class="mx-5">
            <v-card-title class="table-header pa-0 my-3 data-item">
              <span class="caption text-center">{{ props.item.no }}</span>
              <span class="caption">{{ props.item.goodsName }}</span>
              <span class="caption text-center">{{ props.item.remain }}</span>
              <span class="caption text-center">{{ props.item.request }}</span>
              <span class="caption text-center">{{ props.item.given }}</span>
              <span class="caption text-center">{{ props.item.unit }}</span>
              <v-row justify="center" no-gutters>
                <v-switch v-model="options"></v-switch>
              </v-row>
            </v-card-title>
          </v-card>
        </template>
      </v-data-iterator>
    </v-card-text>
    <v-card-actions>
      <v-btn text class="text-none" @click="$emit('close-form')">Kembali</v-btn>
      <v-spacer />
      <v-btn color="primary" outlined tile @click="isNew = !isNew"
        >Tambah Baru</v-btn
      >
      <v-btn color="primary" tile depressed>Simpan</v-btn>
    </v-card-actions>

    <v-bottom-sheet v-model="isNew" max-width="1300">
      <v-card height="300px" class="pa-5 text-left">
        <label class="font-weight-bold primary--text">Tambah Barang Baru</label>
        <v-row>
          <v-col class="text-left" cols="3">
            <label class="font-weight-bold body-2">Nama Barang</label>
            <v-combobox dense></v-combobox>

            <label class="font-weight-bold body-2">Tanggal ED</label>
            <v-combobox dense></v-combobox>
            <v-row>
              <v-col>
                <label class="font-weight-bold body-2">Jumlah Diberikan</label>
                <v-combobox dense hide-details="false"></v-combobox>
              </v-col>
              <v-col>
                <label class="font-weight-bold body-2">Sisa Stok</label><br />
                <label>0</label>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-data-table
              :headers="newTable.headers"
              hide-default-footer
            ></v-data-table>
          </v-col>
        </v-row>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" tile depressed>Simpan</v-btn>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>
  </v-card>
</template>

<script>
export default {
  name: 'DetailMutation',
  data() {
    return {
      table: {
        headers: [
          { text: 'No', value: 'no' },
          { text: 'Nama Barang', value: 'goodsName' },
          { text: 'Sisa Unit', value: 'remain' },
          { text: 'Jumlah Permintaan', value: 'request' },
          { text: 'Jumlah Diberikan', value: 'given' },
          { text: 'Satuan', value: 'unit' },
          { text: 'Beri Barang', value: 'options' },
        ],
        items: [
          {
            no: 1,
            goodsName: 'Lopivia',
            remain: 0,
            request: 0,
            given: 1,
            unit: 2,
          },
        ],
      },
      options: false,
      isNew: false,
      newTable: {
        headers: [
          { text: 'No', value: 'no' },
          { text: 'Nama Barang', value: 'goodsName' },
          { text: 'Batch', value: 'batch' },
          { text: 'Kadaluarsa', value: 'expired' },
          { text: 'Jumlah Diberikan', value: 'given' },
        ],
      },
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.card-layout {
  font-family: 'Roboto', sans-serif;
  .card-detail {
    height: 70px;
    width: 750px;
    border: 1.5px solid #eaeaea !important;
    border-radius: 5px;
    .grid-detail {
      display: grid;
      grid-template-columns: 150px 200px 200px 200px;
      .grid-row {
        display: grid;
        border-right: 1.5px solid #eaeaea;
      }
    }
  }

  .table-header {
    grid-template-columns: 50px 250px 100px 150px 150px 100px 100px;
    display: grid;
    background-color: #fcfcfc;
    align-content: center;
  }
}
</style>
