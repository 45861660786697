<template>
  <v-card flat>
    <v-container
      id="main-container"
      @scroll="onScroll()"
      class="container-layout"
      fluid
    >
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col lg="6" xl="5">
            <v-card-title
              ref="nurse"
              class="title font-weight-bold primary--text"
            >
              Data Subjektif
            </v-card-title>
            <v-row no-gutters align="baseline" class="grid-display">
              <v-col>
                <v-row no-gutters justify="start" class="mb-7 ml-8"
                  ><label
                    for="Kategori Imunisasi"
                    class="black--text font-weight-bold"
                    >Kategori Imunisasi</label
                  >
                </v-row></v-col
              >
              <v-col
                ><v-radio-group
                  row
                  :readonly="!isEditable || emrData !== null"
                  hide-details="false"
                  class="mt-0 pt-0"
                  v-model="input.vaccineCategory"
                >
                  <v-radio label="Anak" value="anak"></v-radio>
                  <v-radio
                    label="Dewasa"
                    value="dewasa"
                  ></v-radio> </v-radio-group
              ></v-col>
              <v-col>
                <v-row no-gutters justify="start" class="mb-7 ml-8">
                  <label
                    for="Riwayat Penyakit Sebelumnnya"
                    class="black--text font-weight-bold text-left"
                    >Riwayat Penyakit Sebelumnya</label
                  >
                </v-row>
              </v-col>
              <v-col>
                <template v-for="(disease, index) in input.priorDisease">
                  <v-text-field
                    :readonly="!isEditable || emrData !== null"
                    dense
                    :placeholder="'Riwayat Penyakit ' + (index + 1)"
                    :key="'disease-' + index"
                    v-model="input.priorDisease[index]"
                    @keyup="addDisease($event, index)"
                  />
                </template>
              </v-col>
              <v-col>
                <v-row no-gutters justify="start" class="mb-7 ml-8">
                  <label
                    for="Riwayat Alergi"
                    class="black--text font-weight-bold"
                    >Riwayat Alergi</label
                  >
                </v-row>
              </v-col>
              <v-col>
                <template v-for="(allergy, index) in input.allergy">
                  <v-text-field
                    :readonly="!isEditable || emrData !== null"
                    dense
                    :placeholder="'Riwayat Alergi ' + (index + 1)"
                    :key="'allergy-' + index"
                    v-model="input.allergy[index]"
                    @keyup="addAllergy($event, index)"
                  />
                </template>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-card-title
              ref="nurse"
              class="title font-weight-bold primary--text"
            >
              Data Imunisasi
            </v-card-title>
            <v-row no-gutters align="baseline" class="grid-display">
              <v-col>
                <v-row class="mb-7 ml-8">
                  <label
                    for="Nama Imunisasi"
                    class="black--text font-weight-bold text-left required"
                    >Nama Imunisasi</label
                  >
                </v-row>
              </v-col>
              <v-col>
                <v-select
                  :readonly="!isEditable || emrData !== null"
                  dense
                  placeholder="Pilih Imunisasi"
                  append-icon="mdi-chevron-down"
                  :items="resource.vaccineName"
                  v-model="input.vaccine.vaccineName"
                  :rules="isEditable ? rules : []"
                />
              </v-col>
              <v-col>
                <v-row no-gutters justify="start" class="mb-7 ml-8">
                  <label
                    for="Vaksinasi ke"
                    class="black--text font-weight-bold required"
                    >Vaksinasi ke</label
                  >
                </v-row>
              </v-col>
              <v-col>
                <v-select
                  :readonly="!isEditable || emrData !== null"
                  dense
                  append-icon="mdi-chevron-down"
                  placeholder="Pilih Vaksin ke"
                  :items="resource.vaccineNumber"
                  v-model="input.vaccine.vaccineNumber"
                  :rules="isEditable ? rules : []"
                />
              </v-col>
              <v-col v-if="input.vaccine.vaccineName === 'covid19'">
                <v-row no-gutters justify="start" class="mb-7 ml-8">
                  <label
                    for="Jenis Vaksin"
                    class="black--text font-weight-bold required"
                    >Jenis Vaksin</label
                  >
                </v-row>
              </v-col>
              <v-col v-if="input.vaccine.vaccineName === 'covid19'">
                <v-select
                  :readonly="!isEditable || emrData !== null"
                  append-icon="mdi-chevron-down"
                  dense
                  placeholder="Pilih Jenis Vaksin"
                  :items="resource.vaccineType"
                  v-model="input.vaccine.vaccineType"
                  :rules="
                    input.vaccine.vaccineName === 'covid19' || !isEditable
                      ? []
                      : rules
                  "
                />
              </v-col>
              <v-col>
                <v-row no-gutters justify="start" class="mb-7 ml-8">
                  <label
                    for="Dosis Vaksin"
                    class="black--text font-weight-bold required"
                    >Dosis Vaksin</label
                  >
                </v-row>
              </v-col>
              <v-col>
                <v-select
                  :readonly="!isEditable || emrData !== null"
                  append-icon="mdi-chevron-down"
                  dense
                  placeholder="Pilih Dosis Vaksin"
                  :items="resource.vaccineDosis"
                  v-model="input.vaccine.vaccineDosis"
                  :rules="isEditable ? rules : []"
                />
              </v-col>
              <v-col>
                <v-row no-gutters justify="start" class="mb-7 ml-8">
                  <label
                    for="Nama Operator"
                    class="black--text font-weight-bold"
                    >Nama Operator</label
                  >
                </v-row>
              </v-col>
              <v-col>
                <v-autocomplete
                  :readonly="!isEditable || emrData !== null"
                  placeholder="Nama Operator"
                  dense
                  append-icon="mdi-chevron-down"
                  auto-select-first
                  :items="resource.operator"
                  item-text="doctor"
                  :loading="loading"
                  v-model="input.vaccine.operatorName"
                  return-object
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-card-title class="title font-weight-bold primary--text text-left">
          Data Objektif TTV
        </v-card-title>
        <v-row>
          <v-col lg="4" xl="3">
            <v-row no-gutters align="baseline" class="grid-display-3">
              <label
                for="Tekanan Darah"
                class="black--text font-weight-bold text-left ml-8 required"
                >Tekanan Darah</label
              >
              <div class="d-flex">
                <v-text-field
                  :readonly="!isEditable || emrData !== null"
                  dense
                  placeholder="Sistolik"
                  v-model="input.ttv.sistole"
                  :rules="
                    isEditable
                      ? [...rules, rangeRules(input.ttv.sistole, 'sistole')]
                      : []
                  "
                  type="number"
                  id="sistole"
                />
                <span class="title mx-4 grey--text">/</span>
                <v-text-field
                  dense
                  :readonly="!isEditable || emrData !== null"
                  placeholder="Diastolik"
                  suffix="mmhg"
                  type="number"
                  v-model="input.ttv.diastole"
                  :rules="
                    isEditable
                      ? [...rules, rangeRules(input.ttv.diastole, 'diastole')]
                      : []
                  "
                  id="diastole"
                />
              </div>

              <label
                for="Nadi"
                class="black--text font-weight-bold text-left ml-8 required"
                >Nadi</label
              >
              <v-text-field
                :readonly="!isEditable || emrData !== null"
                dense
                placeholder="Nadi"
                suffix="x/menit"
                type="number"
                v-model="input.ttv.pulse"
                :rules="
                  isEditable
                    ? [...rules, rangeRules(input.ttv.pulse, 'heart-rate')]
                    : []
                "
                id="pulse"
              />
            </v-row>
          </v-col>
          <v-col lg="4" xl="3">
            <v-row no-gutters align="baseline" class="grid-display-3">
              <label
                for="Berat Badan"
                class="black--text font-weight-bold text-left ml-8 required"
                >Berat Badan</label
              >
              <v-text-field
                :readonly="!isEditable || emrData !== null"
                dense
                placeholder="Berat Badan"
                suffix="kg"
                type="number"
                v-model="input.ttv.weight"
                :rules="isEditable ? rules : []"
                id="weight"
              />
              <label
                for="Tinggi Badan"
                class="black--text font-weight-bold text-left ml-8 required"
                >Tinggi Badan</label
              >
              <v-text-field
                :readonly="!isEditable || emrData !== null"
                dense
                placeholder="Tinggi Badan"
                suffix="cm"
                type="number"
                v-model="input.ttv.height"
                :rules="
                  isEditable
                    ? [...rules, rangeRules(input.ttv.height, 'height')]
                    : []
                "
                id="height"
              />
              <label
                for="RR"
                class="black--text font-weight-bold text-left ml-8 required"
                >RR</label
              >
              <v-text-field
                :readonly="!isEditable || emrData !== null"
                dense
                placeholder="RR"
                type="number"
                suffix="x/menit"
                v-model="input.ttv.respiration"
                :rules="
                  isEditable
                    ? [...rules, rangeRules(input.ttv.respiration, 'rr')]
                    : []
                "
                id="respiration"
              />
            </v-row>
          </v-col>
          <v-col lg="4" xl="3">
            <v-row no-gutters align="baseline" class="grid-display-3">
              <label
                for="Saturasi 02"
                class="black--text font-weight-bold text-left ml-8"
                >Saturasi O<sub>2</sub></label
              >
              <v-text-field
                :readonly="!isEditable || emrData !== null"
                dense
                placeholder="Saturasi 02"
                type="number"
                suffix="%"
                v-model="input.ttv.saturation"
                id="saturation"
              />
              <label
                for="Suhu"
                class="black--text font-weight-bold text-left ml-8"
                >Suhu</label
              >
              <v-text-field
                :readonly="!isEditable || emrData !== null"
                dense
                placeholder="Suhu Tubuh"
                suffix="°C"
                type="number"
                v-model="input.ttv.temperature"
                :rules="
                  isEditable
                    ? [rangeRules(input.ttv.temperature, 'temperature')]
                    : []
                "
                id="temperature"
              />
              <label
                for="Kesadaran"
                class="black--text font-weight-bold text-left ml-8 required"
                >Kesadaran</label
              >
              <v-select
                :readonly="!isEditable || emrData !== null"
                dense
                placeholder="Pilih Kesadaran"
                :items="resource.awareness"
                item-value="code"
                item-text="name"
                append-icon="mdi-chevron-down"
                v-model="input.ttv.awareness"
                :rules="isEditable ? rules : []"
                id="awareness"
              />
            </v-row>
          </v-col>
        </v-row>

        <v-card-title
          ref="doctor"
          id="doctor-assesment"
          class="title font-weight-bold primary--text text-left"
        >
          Assesmen
        </v-card-title>
        <v-row class="pb-5">
          <v-col lg="8" xl="7">
            <v-row no-gutters align="baseline" class="grid-display-1">
              <label
                for="Diagnosa Utama"
                class="black--text font-weight-bold text-left ml-8 mb-2"
                >Diagnosa Utama</label
              >
              <v-col
                ><v-combobox
                  dense
                  :readonly="!isEditable || emrData !== null"
                  placeholder="Pilih Diagnosa Utama"
                  append-icon="mdi-chevron-down"
                  hide-details="auto"
                  :items="resource.diagnose"
                  item-text="displayName"
                  clearable
                  v-model="input.mainDiagnose.text"
                  :loading="diagnoseLoading"
                  :search-input.sync="searchDiagnose"
                  id="mainDiagnose"
              /></v-col>
            </v-row>
          </v-col>
          <v-col lg="8" xl="7">
            <template v-for="(diagnose, index) in input.secondaryDiagnose">
              <v-row
                no-gutters
                align="baseline"
                class="grid-display-1"
                :key="'diagnoseLabel-' + index"
              >
                <label
                  v-if="index === 0"
                  for="Diagnosa Sekunder"
                  class="black--text font-weight-bold text-left ml-8"
                  >Diagnosa Sekunder</label
                >
                <v-col xl="7" lg="8" v-else></v-col>
                <div class="d-flex pa-0 mb-4">
                  <v-combobox
                    :readonly="!isEditable || emrData !== null"
                    dense
                    append-icon="mdi-chevron-down"
                    placeholder="Pilih Diagnosa Sekunder"
                    hide-details="false"
                    :items="resource.diagnose"
                    item-text="displayName"
                    :loading="diagnoseLoading"
                    v-model="input.secondaryDiagnose[index].text"
                    @change="changeDiagnose($event, index)"
                    :search-input.sync="searchDiagnoseSecondary[index]"
                  />
                  <v-btn
                    :disabled="!isEditable"
                    icon
                    @click="deleteItem(index)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                  <v-btn :disabled="!isEditable" icon @click="addDiagnose()">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </div>
              </v-row>
            </template>
          </v-col>
        </v-row>

        <v-card-title class="title font-weight-bold primary--text mt-5">
          Planning
        </v-card-title>
        <v-row>
          <v-col xl="4" lg="5">
            <v-row no-gutters align="baseline" class="grid-display-2">
              <label
                for="Tindakan"
                class="black--text font-weight-bold text-left ml-8"
                >Tindakan</label
              >
              <v-col>
                <template v-for="(action, index) in input.actions">
                  <div class="d-flex" :key="'action-' + index">
                    <v-textarea
                      :readonly="!isEditable || emrData !== null"
                      dense
                      outlined
                      height="50"
                      :placeholder="'Tindakan ' + (index + 1)"
                      v-model="input.actions[index]"
                      :id="'action-' + index"
                    />
                    <v-btn
                      :disabled="!isEditable"
                      icon
                      @click="deleteActions(index)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn
                      :disabled="!isEditable"
                      icon
                      @click="addActions($event, index)"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                </template>
              </v-col>
            </v-row>
          </v-col>
          <v-col xl="4" lg="5">
            <v-row no-gutters align="baseline" class="grid-display-2">
              <label
                for="icd-9"
                class="black--text font-weight-bold text-left ml-8"
                >ICD-9</label
              >
              <v-col>
                <template v-for="(procedure, index) in input.procedure">
                  <div class="d-flex" :key="'item' + index">
                    <v-combobox
                      placeholder="Pilih Kode ICD 9 CM"
                      class="icd-input"
                      clearable
                      dense
                      hide-details="auto"
                      :items="resource.procedure"
                      item-text="displayName"
                      v-model="input.procedure[index]"
                      :loading="procedureLoading"
                      :readonly="!isEditable"
                      append-icon="mdi-chevron-down"
                      :search-input.sync="searchProcedure[index]"
                      :id="'procedure-' + index"
                      @change="getDiagnose('')"
                    />
                    <v-btn
                      :disabled="!isEditable"
                      icon
                      @click="deleteICD(index)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </template>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-card-actions class="d-flex justify-end">
      <v-btn
        @click="saveData()"
        width="108"
        height="36"
        color="primary"
        depressed
        tile
        class="text-none"
        v-show="!isEmr"
        :disabled="!isEditable || !valid"
      >
        Simpan
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import Constant from '@/const';
import moment from 'moment-timezone';
import AlertMixin from '@/mixin/alertMixin';
import calculationMixin from '@/mixin/calculationMixin';
import jwtMixin from '@/mixin/jwtMixin';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'VaccineInputAssesment',
  mixins: [AlertMixin, jwtMixin, calculationMixin],
  props: {
    patientData: {
      type: Object,
      default: null,
    },
    emrData: {
      type: Object,
      default: null,
    },
    isEmr: Boolean,
    tab: {
      type: Number,
      default: null,
    },
  },
  computed: {
    isEditable() {
      const differenceBetweenRegistrationDateAndToday = this.differenceBetweenTwoDates(
        this.$store.getters.selectedPatient.registered_at,
        moment(),
      );
      if (this.isBPJS) {
        return differenceBetweenRegistrationDateAndToday === 0;
      } else {
        return differenceBetweenRegistrationDateAndToday <= 1;
      }
    },
  },
  data() {
    return {
      value: true,
      dialog: false,
      scrollValue: 0,
      idUnit: '',
      unitName: '',
      input: {
        priorDisease: [''],
        allergy: [''],
        ttv: {
          sistole: '',
          diastole: '',
          pulse: '',
          weight: '',
          height: '',
          respiration: '',
          saturation: '',
          temperature: '',
          awareness: '',
        },
        vaccine: {
          vaccineName: '',
          vaccineNumber: '',
          vaccineType: '',
          vaccineDosis: '',
          operatorName: '',
        },
        procedure: [''],
        mainDiagnose: {
          text: '',
          detail: '',
        },
        secondaryDiagnose: [
          {
            text: '',
            detail: '',
          },
        ],
        vaccineCategory: '',
        actions: [''],
      },
      resource: {
        awareness: [],
        vaccineName: [
          { text: 'None', value: null },
          { text: 'BCG', value: 'bcg' },
          { text: 'Hepatitis', value: 'hepatitis' },
          { text: 'DPT', value: 'dpt' },
          { text: 'Polio', value: 'polio' },
          { text: 'Campak', value: 'campak' },
          { text: 'HIB', value: 'hib' },
          { text: 'IPD', value: 'ipd' },
          { text: 'Influenza', value: 'influenza' },
          { text: 'CacarAir', value: 'cacarair' },
          { text: 'MMR', value: 'mmr' },
          { text: 'Thypoid', value: 'thypoid' },
          { text: 'HepatitisA', value: 'hepatitisa' },
          { text: 'HepatitisB', value: 'hepatitisb' },
          { text: 'Rotavirus', value: 'rotavirus' },
          { text: 'Covid19', value: 'covid19' },
        ],
        vaccineType: [
          { text: 'None', value: null },
          { text: 'AstraZeneca', value: 'astrazeneca' },
          { text: 'Sinovac', value: 'sinovac' },
          { text: 'Sinopharm', value: 'sinopharm' },
          { text: 'Pfizer', value: 'pfizer' },
          { text: 'Moderna', value: 'moderna' },
          { text: 'JohnsonJohnson', value: 'johnsonjohnson' },
          { text: 'Zifivax', value: 'zifivax' },
          { text: 'Turkovax', value: 'turkovax' },
        ],
        vaccineNumber: ['1', '2', '3', '4', '5', '6', '7', '8'],
        vaccineDosis: ['1 ml', '2 ml', '3 ml', '1/2 ml', '1/4 ml'],
        diagnose: [],
        procedure: [],
        operator: [],
      },
      diagnoseLoading: false,
      procedureLoading: false,
      loading: false,
      searchDiagnose: '',
      searchProcedure: [],
      searchDiagnoseSecondary: [],
      valid: true,
      rules: [v => !!v || 'Wajib diisi'],
    };
  },
  // created() {
  //   this.$refs.form.resetValidation();
  // },
  mounted() {
    this.$nextTick(() => {
      this.input.vaccine.operatorName = {
        id: this.$store.getters.userLoggedIn.id,
        doctor: this.$store.getters.userLoggedIn.name,
      };
      this.getDataAssessment();
      this.getDiagnose('');
      this.getProcedure();
      this.getAwarness();
      this.getDoctor();
    });
  },
  watch: {
    searchProcedure(val) {
      if (val) {
        const oldValues = this.input.procedure.map(procedure => {
          return procedure.displayName;
        });
        const index = val.findIndex((v, i) => {
          return v !== oldValues[i];
        });
        this.getProcedure(val[index]);
      } else {
        this.getProcedure('');
      }
    },
    searchDiagnose(value) {
      if (!value) return;
      this.searchDataOne(this);
    },
    searchDiagnoseSecondary(val) {
      const oldValues = this.input.secondaryDiagnose.map(diagnosa => {
        return diagnosa.text.displayName;
      });
      const index = val.findIndex((v, i) => {
        return v !== oldValues[i];
      });

      this.diagnoseLoading = true;
      this.searchDataTwo(this, index);
      // this.getDiagnose(val[index]);
    },
  },
  methods: {
    searchDataOne: _.debounce(v => v.getDiagnose(v.searchDiagnose), 650),
    searchDataTwo: _.debounce(
      (v, index) => v.getDiagnose(v.searchDiagnoseSecondary[index]),
      650,
    ),
    rangeRules(value, type) {
      switch (type) {
        case 'sistole':
          if (value < 40 || value > 250) {
            return 'Angka tekanan darah tidak valid';
          }
          break;
        case 'diastole':
          if (value < 30 || value > 180) {
            return 'Angka tekanan darah tidak valid';
          }
          break;
        case 'heart-rate':
          if (value < 30 || value > 160) {
            return 'Angka nadi tidak valid';
          }
          break;
        case 'rr':
          if (value < 5 || value > 70) {
            return 'Angka RR tidak valid';
          }
          break;
        case 'temperature':
          if ((value < 20 || value > 45) && value !== '') {
            return 'Angka suhu tidak valid';
          }
          break;
        case 'height':
          if (value <= 1) {
            return 'Angka tinggi badan tidak valid';
          }
          break;
      }
    },
    onScroll() {
      const main = document.getElementById('main-container');
      this.scrollValue = main.scrollTop;
    },
    onCloseButtonClick() {
      this.$emit('close-form');
    },
    addDisease(event, index) {
      if (event.keyCode === 9) return;
      if (
        this.input.priorDisease[index] !== '' &&
        this.input.priorDisease.length - 1 === index
      ) {
        this.input.priorDisease.push('');
      } else if (
        this.input.priorDisease[index] === '' &&
        this.input.priorDisease.length !== 1
      ) {
        this.input.priorDisease.splice(index, 1);
      }
    },
    addAllergy(event, index) {
      if (event.keyCode === 9) return;
      if (
        this.input.allergy[index] !== '' &&
        this.input.allergy.length - 1 === index
      ) {
        this.input.allergy.push('');
      } else if (
        this.input.allergy[index] === '' &&
        this.input.allergy.length !== 1
      ) {
        this.input.allergy.splice(index, 1);
      }
    },
    addActions(event, index) {
      if (event.keyCode === 9) return;
      if (this.input.actions.length - 1 === index) {
        this.input.procedure.push('');
        this.input.actions.push('');
      } else if (
        this.input.actions[index] === '' &&
        this.input.actions.length !== 1
      ) {
        this.input.procedure.splice(index, 1);
        this.input.actions.splice(index, 1);
      }
      // this.input.actions.push('');
    },
    addDiagnose() {
      this.input.secondaryDiagnose.push({
        text: '',
        detail: '',
      });
    },
    getAwarness() {
      axios.get(Constant.apiUrl.concat('/master/awarness')).then(response => {
        const data = response.data.data;
        this.resource.awareness = data.map(v => {
          return {
            code: v.code,
            name: v.description,
          };
        });
      });
    },
    getDiagnose(keyword) {
      this.diagnoseLoading = true;
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/master/diagnose?page=1&itemCount=100&search=${
                keyword === null ? '' : keyword
              }&sort=-code`,
            ),
          )
          .then(response => {
            let allDiagnose = response.data.data;
            this.resource.diagnose = allDiagnose.map(diagnose => {
              return {
                id: diagnose._id.toString(),
                code: diagnose.code,
                text: diagnose.description,
                displayName: `${diagnose.code} ${diagnose.description}`,
              };
            });
            this.diagnoseLoading = false;
          })
          .catch(() => {
            this.diagnoseLoading = false;
          });
      });
    },
    getProcedure(keyword) {
      this.procedureLoading = true;
      axios
        .get(
          `${
            Constant.apiUrl
          }/master/procedure?page=1&itemCount=50&&search=${keyword || ''}`,
        )
        .then(response => {
          const { data } = response.data;
          this.resource.procedure = data.map(procedure => {
            return {
              id: procedure._id.toString(),
              code: procedure.code.toString(),
              text: procedure.description,
              displayName: `${procedure.code} ${procedure.description}`,
            };
          });
        })
        .catch(() => {
          this.resource.procedure = [];
        })
        .finally(() => {
          this.procedureLoading = false;
        });
    },
    getDoctor() {
      this.loading = true;
      this.runApiCalls(() => {
        axios
          .get(Constant.apiUrl.concat(`/master/staff`))
          .then(response => {
            const data = response.data.data;
            this.resource.operator = data
              .filter(doctor => doctor.role.status === true)
              .map(doctor => {
                return {
                  id: doctor._id.toString(),
                  doctor: doctor.detail.name,
                };
              });
            this.loading = false;
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    changeDiagnose(event, index) {
      if (event.keyCode === 9) return;
      if (this.input.secondaryDiagnose[index].text.view !== undefined) {
        this.addDiagnose();
      } else if (
        (this.input.secondaryDiagnose[index].text === '' &&
          this.input.secondaryDiagnose[index].text === null) &
        (this.input.secondaryDiagnose.length !== 1)
      ) {
        this.input.secondaryDiagnose.splice(index, 1);
      }
    },
    deleteItem(index) {
      if (index !== 0) {
        this.input.secondaryDiagnose.splice(index, 1);
      } else {
        this.input.secondaryDiagnose = [
          {
            text: '',
            detail: '',
          },
        ];
      }
    },
    deleteActions(index) {
      if (index !== 0) {
        this.input.actions.splice(index, 1);
      } else {
        this.input.actions = [''];
      }
    },
    deleteICD(index) {
      this.input.procedure.splice(index, 1);
    },
    remapDiagnoseProcedure(main, secondary, procedures) {
      let mainDiagnose = { text: '', description: '' };
      let secondaryDiagnose = [{ text: '', description: '' }];
      let procedure = [''];

      if (main) {
        mainDiagnose = {
          text: {
            id: main.diagnose?._id,
            code: main.diagnose?.code,
            text: main.diagnose?.description,
            displayName: `${main.diagnose?.code} ${main.diagnose?.description}`,
          },
          detail: main.description,
        };
      }
      if (secondary?.length > 0) {
        secondaryDiagnose = secondary.map(v => {
          return {
            text: {
              id: v.diagnose?._id,
              code: v.diagnose?.code,
              text: v.diagnose?.description,
              displayName: `${v.diagnose?.code} ${v.diagnose?.description} `,
            },
            detail: v.description,
          };
        });
      }
      if (procedures?.length > 0) {
        procedure = procedures.map(v => {
          return {
            id: v.id._id,
            code: v.id.code,
            text: v.id.description,
            displayName: `${v.id.code} ${v.id.description}`,
          };
        });
      }
      this.input = {
        ...this.input,
        mainDiagnose,
        secondaryDiagnose,
        procedure,
      };
    },
    saveData() {
      if (!this.$refs.form.validate()) {
        this.valid = false;
        this.checkRules();
      } else {
        const payload = {
          checkup: {
            id_registration: this.patientData.id_registration,
            subjective: {
              allergy_history: this.input.allergy.filter(x => x !== ''),
              disease_history: this.input.priorDisease.filter(x => x !== ''),
            },
            vaccination: {
              name: this.input.vaccine.vaccineName || '',
              category: this.input.vaccineCategory,
              vaccinationNumber: this.input.vaccine.vaccineNumber,
              vaccineType:
                this.input.vaccine.vaccineName === 'covid19'
                  ? ''
                  : this.input.vaccine.vaccineType || '',
              vaccineDoses: this.input.vaccine.vaccineDosis,
            },
            objective: {
              sistol: parseInt(this.input.ttv.sistole),
              diastol: parseInt(this.input.ttv.diastole),
              heart_rate: parseInt(this.input.ttv.pulse),
              weight: parseInt(this.input.ttv.weight),
              height: parseInt(this.input.ttv.height),
              respiration_rate: parseInt(this.input.ttv.respiration),
              saturation: parseInt(this.input.ttv.saturation),
              temperature: parseFloat(this.input.ttv.temperature),
              awarness: this.input.ttv.awareness,
            },
            assesment: {
              main_diagnose: this.input.mainDiagnose,
              other_diagnose: this.input.secondaryDiagnose
                .filter(x => x?.text?.id !== '' && x.text !== null)
                .map(arr => {
                  return {
                    detail: arr.detail,
                    text: {
                      id: arr.text.id,
                      text: arr.text.text,
                      value: arr.text.code,
                    },
                  };
                }),
            },
            planning: {
              treatment: this.input.actions,
              procedure: this.input.procedure
                .filter(x => x !== '' && x !== null)
                .map(procedure => {
                  return {
                    id: procedure.id,
                    text: procedure.text,
                  };
                }),
            },
            timestamps: {
              doctor_by:
                this.input.vaccine?.operatorName?.id ||
                this.$store.getters.userLoggedIn.id,
              doctor_created_at: moment().format(),
            },
          },
        };
        if (
          !(this.input.mainDiagnose.text || this.input.mainDiagnose.detail) &&
          !(
            this.input.secondaryDiagnose?.[0]?.detail ||
            this.input.secondaryDiagnose?.[0]?.text
          )
        ) {
          payload.checkup.assesment = {};
        } else {
          if (
            !(this.input.mainDiagnose.text || this.input.mainDiagnose.detail)
          ) {
            delete payload.checkup.assesment.main_diagnose;
          }
          if (
            !(
              this.input.secondaryDiagnose?.[0]?.detail ||
              this.input.secondaryDiagnose?.[0]?.text
            )
          ) {
            delete payload.checkup.assesment.other_diagnose;
          }
        }
        axios
          .post(
            Constant.apiUrl.concat(
              `/patient/emr/checkup/${this.patientData.id_registration}`,
            ),
            payload,
          )
          .then(() => {
            Swal.fire('Data Asesmen Berhasil Disimpan!', '', 'success');
            this.$emit('close-form');
          })
          .catch(err => {
            this.showErrorAxios(err);
          });
      }
    },
    getDataAssessment() {
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/patient/checkup/regist/${this.patientData.id_registration}/${
                this.isEmr ? 'history' : 'service'
              }`,
            ),
          )
          .then(response => {
            const data = response.data.data;
            this.input = {
              priorDisease: data.subjective.disease_history,
              allergy: data.subjective.allergy_history || [''],
              ttv: {
                sistole: data.objective.sistol || '',
                diastole: data.objective.diastol || '',
                pulse: data.objective.heart_rate || '',
                weight: data.objective.weight || '',
                height: data.objective.height || '',
                respiration: data.objective.respiration_rate || '',
                saturation: data.objective.saturation || '',
                temperature: data.objective.temperature || '',
                awareness: data.objective.awarness || '',
              },
              vaccine: {
                vaccineName: data.vaccination?.name || '',
                vaccineNumber: data.vaccination?.vaccinationNumber || '',
                vaccineType: data.vaccination?.vaccineType || '',
                vaccineDosis: data.vaccination?.vaccineDoses || '',
                operatorName: {
                  id:
                    data.timestamps?.doctor_by?._id ||
                    this.$store.getters.userLoggedIn.id,
                  doctor:
                    data.timestamps?.doctor_by?.detail?.name ||
                    this.$store.getters.userLoggedIn.name,
                },
              },
              actions: data.planning.treatment,
              vaccineCategory: data.vaccination?.category || '',
            };
            if (this.input.priorDisease) {
              this.input.priorDisease.push('');
            }
            if (this.input.allergy) {
              this.input.allergy.push('');
            }
            if (this.input.actions.length === 0) {
              this.input.actions.push('');
            }
            this.remapDiagnoseProcedure(
              data.assesment?.main_diagnose,
              data.assesment?.other_diagnose,
              data.planning?.procedure,
            );
            if (!this.isEmr) {
              this.addDiagnose();
              // this.input.priorDisease.push('');
              // this.input.allergy.push('');
              // this.input.actions.push('');
            }
          })
          .catch(error => {
            this.showErrorAxios(error);
          });
      });
    },
    checkRules() {
      if (this.input.ttv.sistole === '') {
        const el = document.querySelector('#sistole');
        el.scrollIntoView();
      } else if (this.input.ttv.diastole === '') {
        const el = document.querySelector('#diastole');
        el.scrollIntoView();
      } else if (this.input.ttv.pulse === null) {
        const el = document.querySelector('#pulse');
        el.scrollIntoView();
      } else if (this.input.ttv.weight === null) {
        const el = document.querySelector('#weight');
        el.scrollIntoView();
      } else if (this.input.ttv.height === null) {
        const el = document.querySelector('#height');
        el.scrollIntoView();
      } else if (this.input.ttv.respiration === null) {
        const el = document.querySelector('#respiration');
        el.scrollIntoView();
      } else if (this.input.ttv.saturation === null) {
        const el = document.querySelector('#saturation');
        el.scrollIntoView();
      } else if (this.input.ttv.temperature === null) {
        const el = document.querySelector('#temperature');
        el.scrollIntoView();
      } else if (this.input.ttv.awareness === '') {
        const el = document.querySelector('#awareness');
        el.scrollIntoView();
      } else if (this.input.mainDiagnose.text === '') {
        const el = document.querySelector('#mainDiagnose');
        el.scrollIntoView();
      } else if (this.input.actions[0] === '') {
        const el = document.querySelector('#action-0');
        el.scrollIntoView();
      }
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.left-min {
  margin-left: -30px;
}

.icd-input {
  margin-bottom: 50px;
}
.container-layout {
  height: 70vh;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 3px;
    background-color: rgb(163, 163, 163);
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: black;
  }
  .title {
    font-size: 1.4rem;
  }
  .grid-ttv {
    display: grid;
    grid-template-columns: 220px 280px;
  }
  .dental-stage {
    display: flex;
    justify-content: center;
  }
  .dental-input {
    display: grid;
    grid-template-columns: 190px 250px;
  }

  .grid-display {
    display: grid;
    grid-template-columns: 13rem 20rem;
  }
  .grid-display-1 {
    display: grid;
    grid-template-columns: 13rem 45rem;
  }
  .grid-display-2 {
    display: grid;
    grid-template-columns: 13rem 20rem;
  }
  .grid-display-3 {
    display: grid;
    grid-template-columns: 13rem 13rem;
  }
  .fixed-button {
    z-index: 10;
    width: 15vw;
    background-color: #deeef9 !important;
    position: fixed;
    bottom: 15vh;
    right: 5vw;
    color: #429ddb;
  }
}
.required:after {
  content: ' *';
  color: red;
}
</style>
