<template>
  <div>
    <v-toolbar flat outlined>
      <v-container fluid>
        <v-row align="center">
          <v-col cols="6">
            <v-toolbar-title class="font-weight-bold float-left">
              Data Bed
            </v-toolbar-title>
          </v-col>

          <v-spacer></v-spacer>

          <v-btn class="mr-2" color="grey" icon text @click="getAllBed()">
            <v-icon>mdi-autorenew</v-icon>
          </v-btn>
          <v-text-field
            class="input"
            background-color="grey lighten-3"
            placeholder="Search"
            v-model="search"
            solo
            dense
            hide-details
            flat
            prepend-inner-icon="mdi-magnify"
            single-line
          ></v-text-field>
        </v-row>
      </v-container>
    </v-toolbar>

    <v-main>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-data-table
              :headers="table.headers"
              :items="table.items"
              :loading="loading"
              single-expand
              :height="height"
              item-key="id"
              :items-per-page="itemsPerRow"
              hide-default-footer
              :page.sync="table.pagination.page"
              style="width: 60%"
            >
              <template v-slot:[`item.no`]="{ item }">
                <td class="text-left">{{ item.no + 1 }}</td>
              </template>
              <template v-slot:[`item.availability`]="{ item }">
                <td class="text-left">
                  {{ getAvailability(item.availability, item.gender) }}
                </td>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      :disabled="item.availability === 'Ditempati Pasien'"
                      v-on="on"
                      @click="deleteBed(item)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Hapus Data Bed</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      :disabled="item.availability === 'Ditempati Pasien'"
                      v-on="on"
                      @click="selectItem(0, item)"
                    >
                      <v-icon>mdi-square-edit-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Ubah Data Bed</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-spacer></v-spacer>

    <v-footer color="white">
      <v-container fluid>
        <v-row>
          <v-pagination
            v-model="table.pagination.page"
            @input="getAllBed()"
            :length="pageLength"
            total-visible="10"
          ></v-pagination>
          <v-spacer></v-spacer>
          <v-btn color="primary" fab dark @click="selectItem(1)">
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
        </v-row>
      </v-container>
    </v-footer>
    <v-dialog v-model="dialog" width="260">
      <EditBed
        v-if="selectedForm === 0 && dialog"
        :bed="bedData"
        @close-dialog="onCloseClick()"
      />
      <InputNewBed
        v-if="selectedForm === 1 && dialog"
        @close-dialog="onCloseClick()"
      />
    </v-dialog>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import Constant from '@/const';
import InputNewBed from './components/Bed/InputNewBed.vue';
import EditBed from './components/Bed/EditBed.vue';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'MasterDataBed',
  components: {
    InputNewBed,
    EditBed,
  },
  data() {
    return {
      loading: false,
      dialog: false,
      table: {
        headers: [
          { text: 'No', value: 'no' },
          { text: 'Nomor Bed', value: 'bedNumber' },
          { text: 'Ketersediaan', value: 'availability' },
          { text: '', value: 'action' },
        ],
        items: [],
        pagination: {
          descending: false,
          page: 1,
          rowsPerPage: 7,
        },
      },
      selectedForm: null,
      pageLength: 0,
      bedData: '',
      search: '',
      availability: [
        { text: 'Ditempati Pasien', value: 'occupied' },
        { text: 'Tidak Tersedia (Rusak)', value: 'broken' },
        { text: 'Tersedia', value: 'available' },
      ],
    };
  },
  mounted() {
    this.getAllBed();
  },
  watch: {
    search() {
      this.searchData(this);
      this.table.pagination.page = 1;
    },
  },
  computed: {
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
    height() {
      let height = 0;
      switch (this.$vuetify.breakpoint.name) {
        case 'md':
          height = '60vh';
          break;
        case 'lg':
          height = '63vh';
          break;
        case 'xl':
          height = '70vh';
          break;
      }
      return height;
    },
  },
  methods: {
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.getAllBed();
    }, 650),
    selectItem(index, val) {
      this.selectedForm = index;
      this.bedData = val;
      this.dialog = true;
    },
    getAvailability(availability, gender) {
      let result = '';
      if (availability === 'Tersedia') {
        result = 'Tersedia';
      } else if (availability === 'Tidak Tersedia (Rusak)') {
        result = 'Tidak Tersedia (Rusak)';
      } else if (availability === 'Ditempati Pasien' && gender === true) {
        result = 'Ditempati Pasien Laki-Laki';
      } else if (availability === 'Ditempati Pasien' && gender === false) {
        result = 'Ditempati Pasien Perempuan';
      } else if (availability === 'Ditempati Pasien' && gender === null) {
        result = 'Ditempati Pasien';
      } else {
        result = '';
      }

      return result;
    },
    getAllBed() {
      const halaman = this.table.pagination.page;
      const itemCount = this.itemsPerRow;
      const keyword = this.search;
      this.loading = true;

      axios
        .get(
          Constant.apiUrl.concat(
            `/master/bed?page=${halaman}&itemCount=${itemCount}&search=${keyword}`,
          ),
        )
        .then(response => {
          const { data } = response.data;
          this.pageLength = Math.ceil(response.data.length / itemCount);
          this.table.items = data.map((arr, idx) => {
            return {
              no: idx + itemCount * halaman - itemCount,
              id: arr._id,
              bedNumber: arr.bed_number,
              availability: this.availability.find(
                x => x.value === arr.availability,
              ).text,
              gender: arr.gender,
              id_room: arr.id_room,
              // roomName: arr.room.length !== 0 ? arr.room[0].room_name : '-',
            };
          });
          this.loading = false;
        })
        .catch(() => {
          this.pageLength = 1;
          this.table.items = [];
          this.loading = false;
        });
    },
    deleteBed(data) {
      Swal.fire({
        title: 'Yakin?',
        text: `Anda akan menghapus Bed '${data.bedNumber} '`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batal',
      }).then(result => {
        if (result.value) {
          axios
            .delete(`${Constant.apiUrl}/master/bed/${data.id}`)
            .then(response => {
              if (response.status === 200) {
                Swal.fire(
                  `Bed "${data.bedNumber}" Berhasil Dihapus!`,
                  '',
                  'success',
                );
                this.getAllBed();
              }
            });
        }
      });
    },
    onCloseClick() {
      this.dialog = false;
      this.bedData = '';
      this.getAllBed();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-data-table
    > .v-data-table__wrapper
    tbody
    tr.v-data-table__expanded__content {
    box-shadow: none;
    background-color: #f4f5f5;
  }

  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border: none;
  }
}
</style>
