<template>
  <v-card tile>
    <v-card-title class="justify-center">
      <h4>Ubah Data Bed</h4>
    </v-card-title>
    <v-row no-gutters class="px-6 py-3">
      <v-col cols="12" class="text-left">
        <label class="font-weight-bold body-2">Nomor Bed</label>
        <v-text-field
          dense
          outlined
          placeholder="Contoh : 101"
          :rules="[validateBedNumber(input.bed)]"
          type="number"
          v-model="input.bed"
        ></v-text-field>
      </v-col>
      <v-col cols="12" class="text-left pt-3">
        <label class="font-weight-bold body-2">Ketersediaan Bed</label>
        <v-radio-group row class="mt-0" v-model="input.availability">
          <v-radio label="Tersedia" value="available"></v-radio>
          <v-radio label="Rusak" value="broken"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-card-actions>
      <v-spacer />
      <v-btn
        outlined
        color="primary"
        class="text-none"
        @click="$emit('close-dialog')"
        >Batal</v-btn
      >
      <v-btn
        color="primary"
        class="text-none"
        depressed
        @click="isValidate ? saveData() : ''"
        >Simpan</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import Constant from '@/const';

const axios = require('axios');

export default {
  name: 'EditNewBed',
  props: {
    bed: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      input: {
        bed: null,
        availability: '',
      },
      bedNumber: [],
      loading: false,
      availability: [
        { text: 'Ditempati Pasien', value: 'occupied' },
        { text: 'Ditempati Pasien Laki-Laki', value: 'occupied' },
        { text: 'Ditempati Pasien Perempuan', value: 'occupied' },
        { text: 'Rusak', value: 'broken' },
        { text: 'Tidak Tersedia (Rusak)', value: 'broken' },
        { text: 'Tersedia', value: 'available' },
      ],
      isValidate: true,
    };
  },
  mounted() {
    this.getAllBedNumber();
    this.$nextTick(() => {
      this.input = {
        bed: this.bed.bedNumber,
        availability: this.availability.find(
          x => x.text === this.bed.availability,
        ).value,
      };
    });
  },
  methods: {
    validateBedNumber(val) {
      const indexDefault = this.bedNumber.indexOf(this.bed.bedNumber);
      let res = '';
      const bedNumberNew = [];
      let index = 0;
      for (const e of this.bedNumber) {
        if (index !== indexDefault) {
          bedNumberNew.push(e);
        }
        index++;
      }
      if (bedNumberNew.indexOf(parseInt(val)) >= 0) {
        this.isValidate = false;
        res = 'Nomor bed sudah ada';
        return res;
      } else {
        this.isValidate = true;
      }
    },
    getAllBedNumber() {
      axios
        .get(Constant.apiUrl.concat(`/master/bed`))
        .then(response => {
          const { data } = response.data;
          this.bedNumber = data.map(arr => arr.bed_number);
        })
        .catch(() => {
          this.bedNumber = [];
        });
    },
    saveData() {
      const payload = {
        bed_number: this.input.bed,
        availability: this.input.availability,
        timestamps: {
          created_by: this.$store.getters.userLoggedIn.id,
        },
      };
      axios
        .put(Constant.apiUrl.concat(`/master/bed/${this.bed.id}`), payload)
        .then(() => {
          Swal.fire('Data Bed Berhasil Diubah!', '', 'success');
          this.$emit('close-dialog');
        })
        .catch(error => {
          const message = error.response.data.pesan;
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: message,
          });
          this.$emit('close-dialog');
        });
    },
  },
};
</script>

<style></style>
