<template>
  <div class="main-lab-regist">
    <v-layout xs12 align-center py-3 px-8>
      <v-row align="center">
        <v-col cols="6">
          <h2 class="title">
            Pendaftaran Laboratorium
          </h2>
          <h5 class="subtitle caption">
            Pasien terdaftar hari ini
          </h5>
        </v-col>
        <v-col cols="1" class="text-end">
          <v-btn color="grey" icon text @click="getLabRegist">
            <v-icon>mdi-autorenew </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="5">
          <v-text-field
            v-model="filter.search"
            class="input"
            background-color="grey lighten-3"
            placeholder="Search"
            solo
            dense
            hide-details
            flat
            prepend-inner-icon="mdi-magnify"
            single-line
            @input="debouncedSearch"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-layout>
    <v-row no-gutters class="px-8">
      <v-col cols="6"></v-col>
      <v-col cols="3" class="text-start pe-4">
        <label for="date" class="subtitle-2 mb-4">Tanggal</label>
        <v-menu
          dense
          ref="menu"
          v-model="menu.date"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="380px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              placeholder="Pilih Tanggal"
              append-icon="mdi-chevron-down"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              dense
              clearable
              hide-details="false"
              v-model="filterDate"
            />
          </template>
          <v-date-picker
            width="380px"
            v-model="filter.date"
            no-title
            scrollable
            @input="getLabRegist"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="3" class="text-start ps-4">
        <label for="date" class="subtitle-2 mb-4">Status Permintaan</label>
        <v-select
          clearable
          dense
          append-icon="mdi-chevron-down"
          :items="items.requestStatus"
          ref="menu"
          v-model="filter.status"
          transition="scale-transition"
          offset-y
          min-width="380px"
          placeholder="Pilih Status Permintaan"
          outlined
          hide-details="false"
          item-text="label"
          item-value="key"
          @input="getLabRegist"
        />
      </v-col>
    </v-row>

    <v-layout class="px-8 py-5">
      <v-data-table
        :headers="table.headers"
        :items="labRegistration"
        item-key="orderCode"
        :expanded="expanded"
        single-expand
        hide-default-footer
        class="data-table"
        :items-per-page="itemsPerRow"
        :height="height"
        :loading="onLoading('tableRegistration')"
      >
        <template v-slot:item="{ item, index, expand, isExpanded }">
          <tr class="text-left" @click="expand(!isExpanded)">
            <td>
              {{
                (table.pagination.page - 1) * itemsPerRow +
                  labRegistration.indexOf(item) +
                  1
              }}
            </td>
            <td>{{ item.queye }}</td>
            <td>{{ item.orderCode }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.noRM }}</td>
            <td>
              {{
                item.assurance.slice(0, 1).toUpperCase() +
                  item.assurance.slice(1)
              }}
            </td>
            <td>
              <span
                label
                :class="
                  item.isNew === true
                    ? 'main-lab-regist__chipNew'
                    : 'main-lab-regist__chipOld'
                "
              >
                {{ item.isNew === true ? 'Baru' : 'Lama' }}
              </span>
            </td>
            <td>
              <v-chip
                :class="'main-lab-regist__chipStatus ' + item.status"
                label
                :color="getStatus(item.status).color"
              >
                {{ getStatus(item.status).text }}
              </v-chip>
            </td>
          </tr>
        </template>
        <template v-slot:no-data>
          <v-alert
            :value="true"
            color="blue"
            class="white--text p-0 no-data-alert"
          >
            Tidak ada pasien yang terdaftar
          </v-alert>
        </template>
        <template v-slot:expanded-item="{ item, headers }">
          <td :colspan="headers.length" class="my-border">
            <v-card flat>
              <v-row no-gutters style=" padding: 1rem 0rem;">
                <v-col cols="1" style="max-width: 13%;"></v-col>
                <v-col
                  cols="4"
                  class="text-left grey--text caption"
                  style="max-width: 27.5%"
                >
                  <span
                    >Terdaftar
                    {{ formatDate(item?.timestamps?.createdAt, false) }} WIB
                    {{ formatDate(item?.timestamps?.createdAt, true) }}</span
                  ></v-col
                >
                <v-col
                  cols="3"
                  class="text-left grey--text caption"
                  style="max-width: 26%"
                >
                  <span
                    >{{ item?.gender === true ? 'Laki - Laki' : 'Perempuan' }}
                    {{ item?.age || '-' }} Tahun</span
                  ></v-col
                >
              </v-row>
            </v-card>

            <v-card flat color="grey lighten-4" class="text-right">
              <v-container>
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-tooltip bottom>
                      <template #activator="{ on: tooltip }">
                        <v-btn
                          icon
                          class="mx-2 hover"
                          v-on="{ ...tooltip }"
                          @click="onClicked(2, item)"
                        >
                          <font-awesome-icon icon="user" />
                        </v-btn>
                      </template>
                      <span>Data Sosial</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          class="mx-2 hover"
                          v-bind="attrs"
                          v-on="on"
                          @click="onClicked(3, item)"
                        >
                          <font-awesome-icon icon="flask" />
                        </v-btn>
                      </template>
                      <span>Pemeriksaan Laboratorium</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template #activator="{ on: tooltip }">
                        <v-btn
                          icon
                          class="mx-2 hover"
                          v-on="{ ...tooltip }"
                          @click="onClicked(4, item)"
                        >
                          <v-icon size="21" style="padding-top: 2px"
                            >mdi-printer</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Print Dokumen</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          class="mx-2 hover"
                          v-bind="attrs"
                          v-on="on"
                          @click="deleteRegistLab(item.idLab)"
                          v-if="!item.isFinish"
                          :disabled="item.status !== 'unprocess'"
                        >
                          <v-icon color="red" size="21" style="padding-top: 2px"
                            >mdi-close-thick</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Hapus</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </td>
        </template>
      </v-data-table>
    </v-layout>

    <v-dialog
      v-model="showDialog"
      :max-width="selectedForm === null ? 0 : widthDialog[selectedForm].width"
      :persistent="selectedForm === 1"
      :fullscreen="selectedForm === 3"
    >
      <OldNewDialog
        v-if="selectedForm === 0 && showDialog"
        @on-clicked="getData"
        @close-form="closeShowDialog"
        @to-regist="onClicked(2)"
      />
      <SearchDataPatient
        v-else-if="selectedForm === 5 && showDialog"
        @close-form="closeShowDialog"
        @on-clicked="getData"
        @back="
          selectedForm = 0;
          searchPatient = '';
        "
        @to-regist="
          patientSelected = null;
          onClicked(2);
        "
      />
      <ExaminationDialogV2
        confirmationState="register"
        v-if="selectedForm === 1"
        @close-dialog="selectedForm = 0"
      />
      <SocialDataPatients
        v-if="selectedForm === 2 && showDialog"
        @close-form="closeShowDialog"
        :medical-record="selectedPatient.rmNumber"
      />
      <LabRequest
        v-else-if="selectedForm === 3 && showDialog"
        @close-dialog="closeShowDialog"
        :activityStatus="'detail-register'"
        :patient-data="selectedPatient"
        confirmationState="register"
      />
      <PrintDialog
        :hasGeneralConsent="selectedPatient.hasGeneralConsent"
        v-else-if="selectedForm === 4 && showDialog"
        @close-form="closeShowDialog"
        :selected-patient="selectedPatient"
        state="laboratorium"
      />
    </v-dialog>

    <v-snackbar
      absolute
      light
      content-class="font-weight-medium pr-0"
      :value="snackbar.show"
      elevation="0"
      :color="snackbar.color"
      height="15"
    >
      <div class="d-flex justify-space-between align-center">
        <span style="color: white">
          {{ snackbar.text }}
        </span>
        <v-btn
          style="color: white"
          text
          fab
          small
          right
          @click="snackbar.show = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>
    </v-snackbar>

    <v-footer color="white">
      <v-row align="center">
        <v-pagination
          v-model="table.pagination.page"
          :length="table.pageLength"
          total-visible="5"
          @input="getLabRegist()"
        />
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="mr-6"
          fab
          dark
          @click="onClicked(0)"
          elevation="0"
        >
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </v-row>
    </v-footer>
  </div>
</template>

<script>
const _ = require('lodash');
import Swal from 'sweetalert2';
import moment from 'moment-timezone';
import { createNamespacedHelpers } from 'vuex';
import OldNewDialog from './components/OldNewDialogV2.vue';
import SocialDataPatients from '../SharedComponent/SocialData.vue';
import PrintDialog from './components/PrintDialog.vue';
import LabRequest from '../Laboratorium/components/ExaminationOrder/ExaminationDialogV2.vue';
import ExaminationDialogV2 from '../Laboratorium/components/ExaminationOrder/ExaminationDialogV2.vue';
import SearchDataPatient from './components/SearchDataPatient.vue';

const {
  mapGetters: labGetters,
  mapActions: labActions,
  mapMutations: labMutations,
} = createNamespacedHelpers('ExaminationLaboratory');
const { mapMutations: patientMutations } = createNamespacedHelpers(
  'PatientRegistration',
);

export default {
  name: 'LaboratoriumRegist',
  components: {
    OldNewDialog,
    SocialDataPatients,
    ExaminationDialogV2,
    LabRequest,
    PrintDialog,
    SearchDataPatient,
  },
  computed: {
    ...labGetters([
      'getLabRegistration',
      'getIsLoading',
      'getShowDialog',
      'getSnackbar',
    ]),
    labRegistration: {
      get() {
        return this.getLabRegistration;
      },
    },
    filterDate: {
      get() {
        return this.filter.date !== ''
          ? moment(this.filter.date).format('L')
          : '';
      },
      set(val) {
        if (val === null) {
          this.filter.date = moment(new Date()).format('YYYY-MM-DD');
          this.getLabRegist();
        }
      },
    },
    snackbar: {
      get() {
        return this.getSnackbar;
      },
    },
    showDialog: {
      get() {
        return this.getShowDialog;
      },
      set(val) {
        this.setShowDialog(val);
      },
    },
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
    height() {
      let height = 0;
      switch (this.$vuetify.breakpoint.name) {
        case 'md':
          height = '51vh';
          break;
        case 'lg':
          height = '54vh';
          break;
        case 'xl':
          height = '61vh';
          break;
      }
      return height;
    },
  },
  methods: {
    ...labActions(['resolveGetLabRegistration', 'resolveDeleteRegisterLab']),
    ...labMutations(['setShowDialog', 'clearSnackbar', 'clearState']),
    ...patientMutations([
      'setPatientRegistration',
      'clearStatePatientRegistration',
    ]),
    formatDate(val, isDate = false) {
      if (isDate) {
        return moment(val)
          .locale('id')
          .format('LL');
      } else {
        return moment(val)
          .locale('id')
          .format('LT');
      }
    },
    closeShowDialog() {
      this.setShowDialog(false);
    },
    deleteRegistLab(id) {
      Swal.fire({
        title:
          '<label style="font-weight: 700; font-family: Roboto; font-size: 20px; color: #222222; line-height: 150%;">Yakin Ingin Membatalkan Pendaftaran Laboratorium Ini?</label>',
        iconHtml: '<span class="mdi mdi-help-circle-outline"></span>',
        showCancelButton: true,
        customClass: {
          confirmButton: 'button-confirm',
          cancelButton: 'button-cancel',
        },
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batal',
        reverseButtons: true,
      }).then(result => {
        if (result.isConfirmed) {
          this.resolveDeleteRegisterLab(id).finally(() => {
            this.getLabRegist();
          });
        }
      });
    },
    onClicked(val, item = null) {
      this.selectedPatient = {
        rmNumber: item?.noRM,
        id_registration: item?.idRegistration,
        meta: {
          name: item?.name,
          age: item?.age,
          gender: item?.gender,
          birthDate: item?.birthDate,
          polyName: item?.polyName,
          doctor: '',
        },
        queue: item?.queye,
        noRegist: item?.noRegistration,
        hasGeneralConsent: item?.hasGeneralConsent,
        guarantorType: item?.guarantorType,
      };
      this.statusLab = item?.status;
      this.selectedForm = val;
      this.setShowDialog(true);
    },
    getStatus(status) {
      switch (status) {
        case 'unprocess':
          return { color: '#FFF2F2', text: 'Belum Diproses' };
        case 'inprocess':
          return { color: '#FFF9E5', text: 'Sedang Diproses' };
        case 'finish':
          return { color: '#E5FFF0', text: 'Selesai Diproses' };
      }
    },
    onLoading(val) {
      return this.getIsLoading(val);
    },
    getData(val) {
      if (val === null) {
        this.setPatientRegistration({ label: 'rmNumber', value: '' });
        this.setPatientRegistration({ label: 'statusIsNew', value: true });
        this.onClicked(1);
      } else {
        this.setPatientRegistration({ label: 'rmNumber', value: val });
        this.setPatientRegistration({ label: 'statusIsNew', value: false });
        if (val !== null && this.selectedForm === 5) {
          this.onClicked(1);
        } else {
          this.onClicked(5);
        }
      }
    },
    getLabRegist() {
      const itemCount = this.itemsPerRow;
      this.resolveGetLabRegistration({
        search: this.filter.search,
        date: this.filter.date,
        status: this.filter.status,
        page: this.table.pagination.page,
        itemCount: itemCount,
      });

      const dataLength = this.labRegistration?.length;
      this.pageLength = Math.ceil(dataLength / itemCount);
      this.menu.date = false;
    },
    debouncedSearch: _.debounce(function() {
      this.getLabRegist();
    }, 700),
    setDefaultDate() {
      if (this.filter.date === '') {
        this.filter.date = moment().format('YYYY-MM-DD'); // format the current date as a string
      }
    },
  },
  mounted() {
    this.setDefaultDate();
    this.getLabRegist();
    this.clearSnackbar();
  },
  watch: {
    showDialog: {
      handler() {
        if (this.showDialog === false) {
          this.getLabRegist();
          this.clearStatePatientRegistration();
          this.clearState();
        }
      },
      deep: true,
    },
    'filter.status': {
      handler(newValue) {
        if (!newValue) {
          this.filter.status = '';
          this.getLabRegist();
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      date: null,
      dialog: false,
      selectedForm: null,
      generalConsentPatient: false,
      widthDialog: [
        { text: 'OldNewPatient', width: 600 },
        { text: 'SocialDataPatientsLaboratory', width: 1200 },
        { text: 'SocialDataPatients', width: 1200 },
        { text: 'LabRequest', width: 1200 },
        { text: 'PrintDocuments', width: 346 },
        { text: 'SearchDataPatient', width: 1200 },
      ],
      menu: {
        date: false,
      },
      expanded: [],
      items: {
        requestStatus: [
          { label: 'Belum Diproses', key: 'Unprocess' },
          { label: 'Sedang Diproses', key: 'Inprocess' },
          { label: 'Selesai Diproses', key: 'Finish' },
        ],
      },
      filter: {
        date: '',
        search: '',
        status: '',
      },
      selectedPatient: {
        rmNumber: '',
        idRegistration: '',
      },
      statusLab: '',
      table: {
        headers: [
          { text: 'No.', value: 'no' },
          { text: 'No. Antrian', value: 'queye' },
          { text: 'No. Order', value: 'orderCode' },
          { text: 'Nama', value: 'name' },
          { text: 'No. RM', value: 'noRM' },
          { text: 'Tipe Penjamin', value: 'assurance' },
          { text: 'Jenis Pasien', value: 'isNew' },
          { text: 'Status Permintaan', value: 'status' },
        ],
        pagination: {
          page: 1,
        },
        pageLength: 1,
      },
    };
  },
};
</script>

<style lang="scss">
.button-confirm {
  background-color: #137bc0 !important;
  border-radius: 4px !important;
}
.button-cancel {
  background-color: white !important;
  border: 1px solid #137bc0 !important;
  border-radius: 4px !important;
  color: #137bc0 !important;
}
.swal2-icon {
  border-color: rgba(156, 162, 165, 0.2) !important;
}
.swal2-icon .swal2-icon-content {
  color: #9ca2a5 !important;
}
.main-lab-regist {
  font-family: 'Roboto';
  position: relative;
  .title {
    text-align: left;
    font-weight: bold !important;
    padding-bottom: 0 !important;
  }
  .subtitle {
    text-align: left;
    color: #adb5bd !important;
    font-weight: 500;
  }

  .no-data-alert {
    opacity: 70%;
  }

  &__chipOld {
    color: #2f80ed;
    background-color: #f0f9ff;
    border-radius: 4px;
    padding: 6px 8px;
  }

  &__chipNew {
    color: #e5a82e;
    background-color: #fff9e5;
    border-radius: 4px;
    padding: 6px 8px;
  }

  .main-lab-regist__chipStatus.unprocess {
    border: 1px solid #eb4747 !important;
    color: #eb4747;
  }

  .main-lab-regist__chipStatus.inprocess {
    border: 1px solid #e5a82e !important;
    color: #e5a82e;
  }

  .main-lab-regist__chipStatus.finish {
    border: 1px solid #2d965a !important;
    color: #2d965a;
  }

  .data-table {
    width: 100%;
  }
  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}
</style>
