import { render, staticRenderFns } from "./PatientBilling.vue?vue&type=template&id=27c7d142&scoped=true&"
import script from "./PatientBilling.vue?vue&type=script&lang=js&"
export * from "./PatientBilling.vue?vue&type=script&lang=js&"
import style0 from "./PatientBilling.vue?vue&type=style&index=0&id=27c7d142&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27c7d142",
  null
  
)

export default component.exports