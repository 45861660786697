<template>
  <div>
    <v-layout pa-4 align-center>
      <v-flex xs12 md6 class="text-left">
        <h3>Pembuatan Obat</h3>
      </v-flex>
      <v-flex xs3>
        <v-menu
          v-model="datePicker"
          dense
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              outlined
              dense
              class="mr-4"
              prepend-inner-icon="mdi-calendar"
              append-icon="mdi-chevron-down"
              hide-details="auto"
              placeholder="Tanggal Permintaan Obat"
              v-bind="attrs"
              v-on="on"
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            width="400"
            v-model="selectedDate"
            no-title
            scrollable
            @input="datePicker = false"
          >
          </v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs2 md1>
        <router-link :to="routeBack" active>
          <font-awesome-icon
            style="color: grey; margin-right: 10px"
            icon="home"
          />
        </router-link>
        <v-btn @click="refresh()" icon>
          <font-awesome-icon icon="sync-alt" :class="{ refresh: isRefresh }" />
        </v-btn>
      </v-flex>
      <v-flex xs4 md4>
        <v-text-field
          class="input"
          background-color="grey lighten-3"
          placeholder="Cari Pasien"
          solo
          dense
          hide-details
          flat
          prepend-inner-icon="mdi-magnify"
          single-line
          v-model="search"
        ></v-text-field>
      </v-flex>
    </v-layout>
    <v-data-table
      :headers="headers"
      :items="data"
      item-key="id"
      :items-per-page="itemsPerRow"
      single-expand
      hide-default-footer
      @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
      class="px-2"
      :page.sync="page"
      :height="height"
      :loading="isLoading"
    >
      <template v-slot:[`item.no`]="{ item }">
        <tr class="text-left">
          <td>
            {{ (page - 1) * itemsPerRow + data.indexOf(item) + 1 }}
          </td>
        </tr>
      </template>
      <template v-slot:expanded-item="{ item, headers }">
        <td :colspan="headers.length" class="expand px-0">
          <v-card flat>
            <v-row no-gutters class="pb-2">
              <v-col cols="1"></v-col>
              <v-col
                cols="5"
                class="text-left pl-5 grey--text caption container-expand"
                style="max-width: 400px"
              >
                <span>
                  {{ item.gender }} <br />
                  {{ item.age }} Tahun
                </span>
                <span>
                  Diterima pada {{ item.timeStamps.date }} pukul
                  {{ item.timeStamps.time }} WIB
                </span>
              </v-col>
            </v-row>
          </v-card>

          <v-card flat color="grey lighten-4" class="text-right">
            <v-container class="py-0 px-1">
              <v-row no-gutters>
                <v-col>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        class="mx-2 hover"
                        v-bind="attrs"
                        v-on="on"
                        @click="onClick(item, 1)"
                      >
                        <v-icon>mdi-account</v-icon>
                      </v-btn>
                    </template>
                    <span>Data Sosial</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template #activator="{ on: tooltip }">
                      <v-btn
                        icon
                        class="mx-2 hover"
                        v-on="{ ...tooltip }"
                        @click="onClick(item, 2)"
                      >
                        <v-icon>mdi-file-edit</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit Dokumen</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </td>
      </template>
    </v-data-table>
    <v-footer color="white">
      <v-container>
        <v-row>
          <v-pagination
            v-model="page"
            :length="lengthPage"
            total-visible="7"
            @input="getAllPatient()"
          ></v-pagination>
          <v-spacer></v-spacer>
        </v-row>
      </v-container>
    </v-footer>

    <v-dialog persistent v-model="dialog1">
      <SocialData
        v-if="selectedDialog === 1 && dialog1"
        :medical-record="selectedPatient.rm_number"
        @close-form="dialog1 = false"
      ></SocialData>
    </v-dialog>
    <v-dialog fullscreen v-model="dialog2">
      <DrugManuEdit
        v-if="selectedDialog === 2 && dialog2"
        :patient="selectedPatient"
        @close-form="
          dialog2 = false;
          getAllPatient();
        "
      />
    </v-dialog>
  </div>
</template>

<script>
import DrugManuEdit from './DrugManufacturing/DrugManuEdit';
import SocialData from '../../SharedComponent/SocialData.vue';
import jwtMixin from '@/mixin/jwtMixin';
import { getPharmacyPatientByCategory } from '@/fetchApi/Pharmacy';

const _ = require('lodash');
const moment = require('moment-timezone');

export default {
  name: 'DrugManufacturing',
  mixins: [jwtMixin],
  components: {
    DrugManuEdit,
    SocialData,
  },
  data() {
    return {
      tab: null,
      isRefresh: false,
      isExpanded: false,
      date: moment().format('DD-MM-YYYY'),
      selectedDate: '',
      datePicker: false,
      isLoading: false,
      headers: [
        { text: 'No', align: 'start', value: 'no' },
        { text: 'Nama', value: 'name', width: '20%' },
        { text: 'No. RM.', value: 'rm_number' },
        { text: 'Tipe Penjaminan', value: 'assurance' },
        { text: 'Unit', value: 'unit' },
        { text: 'Nama Dokter', value: 'doctor' },
      ],
      data: [],
      dialog1: false,
      dialog2: false,
      search: '',
      page: 1,
      rowsPerPage: 5,
      lengthPage: 0,
      selectedPatient: null,
      selectedDialog: null,
    };
  },
  watch: {
    search() {
      this.searchData(this);
    },
    selectedDate() {
      this.date = moment(this.selectedDate).format('DD-MM-YYYY');
      this.getAllPatient();
    },
    date() {
      if (!this.date) {
        this.date = '';
        this.getAllPatient();
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshAndAutoUpdate();
    });
  },
  computed: {
    route() {
      return this.$route.path;
    },
    routeSplit() {
      return this.$route.path.split('/');
    },
    routeController() {
      return this.routeSplit[2];
    },
    routeBack() {
      return `/farmasi/${this.routeController}`;
    },
    routeName() {
      switch (this.routeController) {
        case 'rawat-inap':
          return 'rawat inap';

        case 'rawat-jalan':
          return 'rawat jalan';

        case 'igd':
          return 'IGD';

        default:
          return '';
      }
    },
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
    height() {
      let height = 0;
      switch (this.$vuetify.breakpoint.name) {
        case 'md':
          height = '61vh';
          break;
        case 'lg':
          height = '63vh';
          break;
        case 'xl':
          height = '70vh';
          break;
      }
      return height;
    },
  },
  methods: {
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.page = 1;
      v.getAllPatient();
    }, 650),
    refresh() {
      this.isRefresh = true;
      this.search = '';
      this.page = 1;
      this.getAllPatient();
    },
    async getAllPatient() {
      try {
        this.isLoading = true;
        const params = {
          date: this.date || '',
          category: 'production',
          type: this.routeName.toLowerCase(),
          page: this.page,
          itemCount: this.itemsPerRow,
          search: this.search || '',
        };
        const response = await getPharmacyPatientByCategory(params);
        const patient = response.data.data;
        const dataLength = response.data.length;
        this.lengthPage = Math.ceil(dataLength / this.itemsPerRow);
        this.data = patient.map(data => {
          return {
            id: data._id.toString(),
            name: data.name,
            rm_number: data.medical_record_number,
            assurance: data.assurance.toUpperCase(),
            unit: data.unit,
            gender: data.gender ? 'Laki-laki' : 'Perempuan',
            age: data.age,
            doctor: data.doctor,
            officer: data.staff_order,
            timeStamps: {
              date: data.timestamps?.production_at
                ? moment(data.timestamps?.production_at).format('DD-MM-YYYY')
                : '-',
              time: data.timestamps?.production_at
                ? moment(data.timestamps?.production_at).format('hh:mm')
                : '-',
            },
          };
        });
      } catch (error) {
        this.data = [];
        this.lengthPage = 1;
      } finally {
        this.isRefresh = false;
        this.isLoading = false;
      }
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.getAllPatient();
      this.interval = setInterval(this.getAllPatient, 60000);
    },
    onClick(item, val) {
      this.selectedDialog = val;
      this.selectedPatient = item;
      val === 1 ? (this.dialog1 = true) : (this.dialog2 = true);
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.header {
  border-bottom: 1px solid #e7e7e7;
}
.refresh {
  animation: rotation 0.23s infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.container-expand {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}
.icon {
  float: right;
  padding-left: 20px;
}

::v-deep {
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border: none;
  }
}
.pagination {
  position: absolute;
  bottom: 3vh;
}
</style>
