import Constant from '@/const';
import axios from 'axios';

const getRegion = async () => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat('/master/region/province?search='),
    );
    return response?.data?.data;
  } catch (error) {
    this.showErrorAxios(error);
  }
};

export default getRegion;
