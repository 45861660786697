<template>
  <div class="page-banner">
    <div class="detail flex-column justify-content-center">
      <h1 class="primary-header">{{ title }}</h1>
      <h3 class="secondary-header">{{ subtitle }}</h3>
    </div>
    <div class="illustration">
      <img :src="require(`@/assets/image/${image}`)" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: () => '',
    },
    subtitle: {
      type: String,
      required: true,
      default: () => '',
    },
    image: {
      type: String,
      required: true,
      default: () => 'illustration_card_payment.svg',
    },
  },
};
</script>

<style lang="scss" scoped>
.page-banner {
  display: flex;
  text-align: left;
  background: #f0f9ff;
  border-radius: 25px;
  padding: 30px;
  height: 48vh;

  .detail {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .illustration {
    img {
      width: 80%;
    }
  }

  .primary-header {
    font-size: 2.5em;
    color: #000000;
    font-weight: bold;
  }
  .secondary-header {
    font-size: 1.15em;
    font-weight: 500;
    color: #a6adb4;
  }
}
</style>
