<template>
  <v-card>
    <v-card-title class="justify-center">
      <span class="font-weight-bold">Detail Unit</span>
      <v-btn absolute right icon @click="onCloseButtonClick()">
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row no-gutters>
          <v-col>
            <v-row no-gutters>
              <v-col cols="5">
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="name">Kode Unit</label>
                  </div>
                  <v-text-field
                    disabled
                    v-model="input.unit_number"
                    class="detail-input__input-text"
                    required
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="barcode">Nama Unit</label>
                  </div>
                  <v-text-field
                    disabled
                    placeholder="Masukkan Nama Unit"
                    class="detail-input__input-text"
                    v-model="input.name"
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="category">Instalasi</label>
                  </div>
                  <v-text-field
                    disabled
                    placeholder="Pilih Instalasi"
                    class="detail-input__input-text"
                    v-model="input.installation"
                    dense
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="5">
                <div class="detail-radio">
                  <div class="detail-input__label">
                    <label for="gudang">Gudang</label>
                  </div>
                  <v-radio-group
                    disabled
                    :mandatory="false"
                    v-model="input.isWarehouse"
                    row
                    class="mt-0 pt-0"
                  >
                    <v-radio label="Ya" :value="true"></v-radio>
                    <v-radio label="Tidak" :value="false"></v-radio>
                  </v-radio-group>
                </div>
                <div class="detail-radio">
                  <div class="detail-input__label">
                    <label for="farmasi">Farmasi</label>
                  </div>
                  <v-radio-group
                    disabled
                    :mandatory="false"
                    v-model="input.isPharmacy"
                    row
                    class="mt-0 pt-0"
                  >
                    <v-radio label="Ya" :value="true"></v-radio>
                    <v-radio label="Tidak" :value="false"></v-radio>
                  </v-radio-group>
                </div>
              </v-col>
              <v-col cols="12">
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="text-none"
                    width="100"
                    text
                    @click="onCloseButtonClick()"
                  >
                    Kembali
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'DetailUnit',
  props: ['unitDetail'],
  data() {
    return {
      isLoading: false,
      search: null,
      menu: false,
      // resource: {
      //   installation: this.unitDetail.installation,
      // },
      input: {
        unit_number: this.unitDetail.unit_number,
        name: this.unitDetail.name,
        isPharmacy: this.unitDetail.isPharmacy === 'Ya' ? true : false,
        isWarehouse: this.unitDetail.isWarehouse === 'Ya' ? true : false,
        installation: this.unitDetail.installation,
      },
    };
  },
  methods: {
    onCloseButtonClick() {
      this.$emit('close-dialog');
    },
  },
};
</script>

<style lang="scss" scoped>
.column {
  margin: 0 auto;
}

.detail-radio {
  display: flex;
  justify-content: flex-end;
  // align-items: center;
}

.detail-input {
  display: grid;
  grid-template-columns: 150px 500px;
  align-items: baseline;

  &__label {
    justify-content: flex-end;
    flex: 1;
    display: flex;
    color: #222;
    margin-right: 10px;

    &--position {
      position: relative;
      right: 140px;
    }
  }

  &__input-text {
    margin-left: 10px;
    max-width: 40%;
  }
}

.padding {
  padding-left: 10px;
}

.v-input__control {
  display: flex;
  align-self: center;
}

.radio {
  margin: 0;
}

.table-kemasan {
  width: 100%;
  margin-top: 20px;
}
</style>
