import Constant from '@/const';
import axios from 'axios';

const getFactory = async params => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat('/master/factory'),
      { params },
    );
    return response.data.data;
  } catch (error) {
    this.showErrorAxios(error);
  }
};

export default getFactory;
