<template>
  <v-card class="px-7 pt-7 pb-4">
    <v-card-title class="justify-center pa-0">
      <v-col class="py-0">
        <h3>Selesaikan Pelayanan</h3>
      </v-col>
    </v-card-title>
    <v-col class="py-0 mt-4">
      <p>
        Anda akan menyelesaikan pelayanan untuk pasien
        {{ patientData.meta.name }}, silahkan pilih tujuan selanjutnya
      </p>
    </v-col>
    <v-col>
      <v-btn
        :color="returnStatus === '3' ? 'primary' : '#9E9E9E'"
        outlined
        tile
        elevation="0"
        class="text-none"
        :class="returnStatus === '3' ? 'backgroundBlue' : ''"
        style="border-width: 2px;"
        height="50"
        width="230"
        @click="returnStatus = '3'"
        >Pulangkan Pasien</v-btn
      >
    </v-col>
    <v-col>
      <v-btn
        :color="returnStatus === '6' ? 'primary' : '#9E9E9E'"
        outlined
        tile
        elevation="0"
        class="text-none"
        :class="returnStatus === '6' ? 'backgroundBlue' : ''"
        style="border-width: 2px;"
        height="50"
        width="230"
        @click="returnStatus = '6'"
        >Rujuk Eksternal</v-btn
      >
    </v-col>
    <v-col>
      <v-btn
        :color="returnStatus === '1' ? 'primary' : '#9E9E9E'"
        outlined
        tile
        elevation="0"
        class="text-none"
        :class="returnStatus === '1' ? 'backgroundBlue' : ''"
        style="border-width: 2px;"
        height="50"
        width="230"
        @click="returnStatus = '1'"
        >Meninggal</v-btn
      >
    </v-col>
    <v-card-actions>
      <v-col>
        <v-btn
          outlined
          color="error"
          class="btnClose mr-4"
          width="80"
          @click="close()"
          >Batal</v-btn
        >
        <v-btn
          outlined
          text
          color="white"
          class="btnSuccess"
          width="100"
          @click="addData()"
          >Lanjutkan</v-btn
        >
      </v-col>
    </v-card-actions>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import Constant from '@/const';
import jwtMixin from '@/mixin/jwtMixin';
import alertMixin from '@/mixin/alertMixin';

const axios = require('axios');

export default {
  name: 'FinishInpatient',
  mixins: [jwtMixin, alertMixin],
  props: {
    patientData: {
      type: Object,
      default: null,
    },
    // id_registration: {
    //   type: String,
    // },
  },
  data() {
    return {
      // oldReturnStatus: '',
      returnStatus: '',
      // isChanged: false,
      assuranceType: '',
    };
  },
  // watch: {
  //   returnStatus: function(newVal, oldVal) {
  //     if (oldVal && this.oldReturnStatus !== this.returnStatus) {
  //       this.isChanged = true;
  //     } else {
  //       this.isChanged = false;
  //     }
  //   },
  // },
  methods: {
    addData() {
      axios
        .post(
          Constant.apiUrl.concat(
            `/inpatient/finish/${this.patientData.id_inpatient}`,
          ),
        )
        .then(() => {
          Swal.fire('Pelayanan Telah Diselesaikan', '', 'success');
          this.close();
        })
        .catch(err => {
          this.showErrorAxios(err);
        });
    },
    close() {
      this.$emit('close-form');
    },
  },
};
</script>

<style>
.backgroundBlue {
  background: #deeef9;
}

.btnClose {
  border: 1px solid #eb4747;
  text-transform: unset;
  border-radius: 4px;
}

.btnSuccess {
  border: 1px solid #eb4747;
  border-radius: 4px;
  text-transform: unset;
  background: #137bc0;
  color: white;
}
</style>
