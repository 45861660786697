import { createNamespacedHelpers } from 'vuex';
import DashboardQueueHeader from '../DashboardQueueHeader.vue';
const { mapGetters, mapActions } = createNamespacedHelpers('PatientQueue');
import PatientQueueMixin from '@/mixin/PatientQueueMixin';

export default {
  name: 'DashboardQueueForPatient',
  mixins: [PatientQueueMixin],
  components: {
    DashboardQueueHeader,
  },
  data() {
    return {
      clinicName: 'neurovi clinic medical system',
      maxListContainerHeight: 330,
      isOverflow: false,
    };
  },
  computed: {
    ...mapGetters(['getPublicQueue', 'getMissedPatientsForPublicView']),
    publicQueue: {
      get() {
        return this.getPublicQueue;
      },
    },
    missedPatientsForPublicView: {
      get() {
        return this.getMissedPatientsForPublicView;
      },
    },
  },
  mounted() {
    this.$nextTick(async () => {
      this.maxListContainerHeight = window.innerHeight / 2.4;
      this.onUpdateBySocket();
      this.refresh();
    });
  },
  methods: {
    ...mapActions(['resolveGetPublicQueue']),
    checkOverflow() {
      const container = this.$el.querySelectorAll('.queue-list');
      let totalHeight = 0;
      container.forEach(node => {
        totalHeight += node.offsetHeight;
      });
      this.isOverflow = totalHeight > this.maxListContainerHeight;
    },
    // listen to message update socket
    async onUpdateBySocket() {
      this.onMessageUpdate({ refresh: this.refresh, isCalling: true });
    },
    async refresh() {
      await this.handleGetPublicQueue();
      this.checkOverflow();
      window.addEventListener('resize', this.checkOverflow);
    },
    async handleGetPublicQueue() {
      await this.resolveGetPublicQueue();
    },
  },
};
