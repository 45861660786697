<template>
  <v-card>
    <v-card-title class="justify-center">
      <h3>{{ onType().title }}</h3>
      <v-btn absolute right @click="onCloseButtonClick()" small icon>
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <div class="container-layout">
        <v-row no-gutters>
          <v-form v-model="valid" ref="formInputItemLaboratory" class="col-12">
            <v-col md="12">
              <v-row no-gutters>
                <v-col cols="6" class="text-start">
                  <h4 class="py-2">ITEM LABORATORIUM</h4>
                  <TemplateInput
                    id="checkingType"
                    propsLabel="Nama Pemeriksaan"
                    required
                  >
                    <v-combobox
                      id="checkingType"
                      dense
                      :items="services.map(item => item.name)"
                      @change="handleChangeService"
                      item-value="checkingTypeId"
                      item-text="checkingTypeName"
                      :clearable="!onType().disabled"
                      v-model="dataDetail.name"
                      placeholder="Jenis Pemeriksaan"
                      class="text-input-field"
                      append-icon="mdi-chevron-down"
                      :readonly="onType().disabled"
                      :rules="[v => !!v || 'Wajib diisi']"
                      :loading="loading"
                    />
                  </TemplateInput>

                  <TemplateInput
                    id="checkingCategory"
                    propsLabel="Kategori Pemeriksaan"
                  >
                    <v-text-field
                      id="checkingCategory"
                      dense
                      item-value="checkingCategoryId"
                      item-text="checkingCategoryName"
                      :clearable="!onType().disabled"
                      v-model="dataDetail.category"
                      placeholder="Kategori Pemeriksaan"
                      class="text-input-field"
                      :readonly="onType().disabled"
                    />
                  </TemplateInput>
                </v-col>
                <v-col cols="1" />
                <v-col cols="5">
                  <p class="py-3"></p>
                  <TemplateInput id="LISCode" propsLabel="Kode LIS">
                    <v-text-field
                      id="LISCode"
                      dense
                      item-value="LISCodeId"
                      item-text="LISCodeName"
                      :clearable="!onType().disabled"
                      v-model="dataDetail.lis_code"
                      placeholder="Kode LIS"
                      class="text-input-field"
                      @keydown="checkKeyDownAlphaNumeric($event)"
                    />
                  </TemplateInput>
                  <TemplateInput id="IHSCode" propsLabel="Kode IHS">
                    <v-combobox
                      id="IHSCode"
                      dense
                      :items="ihsCodes.map(item => item.loinc_long_common_name)"
                      @keyup="handleGetIHSCode"
                      @paste="handleGetIHSCode"
                      @change="handleChangeIHSCode"
                      item-value="IHSCodeId"
                      item-text="IHSCodeName"
                      :clearable="!onType().disabled"
                      v-model="dataDetail.ihs_code"
                      placeholder="Kode IHS"
                      class="text-input-field"
                      append-icon="mdi-chevron-down"
                      :loading="loading"
                    />
                  </TemplateInput>
                </v-col>
              </v-row>
            </v-col>
          </v-form>
        </v-row>

        <v-divider class="container-layout__separator"></v-divider>

        <v-row no-gutters>
          <v-form v-model="valid" ref="formInputItemData" class="col-12 my-6">
            <v-col md="12">
              <v-row no-gutters>
                <v-col cols="6" class="text-start">
                  <h4 class="py-2">ITEM DATA</h4>
                  <TemplateInput id="item" propsLabel="Nama Item" required>
                    <v-text-field
                      id="item"
                      dense
                      item-value="itemId"
                      item-text="itemName"
                      clearable
                      v-model="itemData.itemName"
                      placeholder="Nama Item"
                      class="text-input-field"
                      :rules="addItemsRules"
                      required
                    />
                  </TemplateInput>
                  <TemplateInput
                    id="gender"
                    propsLabel="Jenis Kelamin"
                    required
                  >
                    <v-radio-group
                      dense
                      row
                      v-model="itemData.gender"
                      class="text-input-field"
                      :rules="addItemsRules"
                      required
                    >
                      <v-radio
                        label="Semua"
                        value="both"
                        class="pr-3"
                      ></v-radio>
                      <v-radio
                        label="Laki-laki"
                        value="l"
                        class="pr-3"
                      ></v-radio>
                      <v-radio
                        label="Perempuan"
                        value="p"
                        class="pr-3"
                      ></v-radio>
                    </v-radio-group>
                  </TemplateInput>
                  <TemplateInput id="ageLimit" propsLabel="Batas Usia">
                    <div class="d-flex text-input-field">
                      <v-text-field
                        id="lowerAgeLimit"
                        dense
                        item-value="lowerAgeLimitId"
                        item-text="lowerAgeLimitName"
                        clearable
                        v-model="itemData.ageLimit.lowerAgeLimit"
                        placeholder="Bawah"
                        class="text-input-field-age-limit"
                        :rules="inputNumberRules"
                        type="number"
                      />

                      <v-text-field
                        id="upperAgeLimit"
                        dense
                        item-value="upperAgeLimitId"
                        item-text="upperAgeLimitName"
                        clearable
                        v-model="itemData.ageLimit.upperAgeLimit"
                        placeholder="Atas"
                        class="text-input-field-age-limit"
                        :rules="inputNumberRules"
                        type="number"
                      />

                      <v-combobox
                        id="age"
                        dense
                        item-value="ageId"
                        item-text="ageName"
                        :items="['Tahun', 'Bulan', 'Hari']"
                        v-model="itemData.ageLimit.age"
                        placeholder="Tahun"
                        class="text-input-field-age-limit"
                        append-icon="mdi-chevron-down"
                      />
                    </div>
                  </TemplateInput>
                  <TemplateInput id="unit" propsLabel="Unit" required>
                    <v-text-field
                      id="unit"
                      dense
                      item-value="unitId"
                      item-text="unitName"
                      clearable
                      v-model="itemData.unit"
                      placeholder="Unit"
                      class="text-input-field"
                      :rules="addItemsRules"
                      required
                    />
                  </TemplateInput>
                </v-col>
                <v-col cols="1" />
                <v-col cols="5">
                  <p class="py-2"></p>
                  <TemplateInput id="normalValue" propsLabel="Nilai Normal">
                    <v-radio-group
                      class="radio-group-normal-value"
                      v-model="normalValueRadio.value"
                      dense
                      row
                    >
                      <v-row no-gutters>
                        <v-radio
                          @click="handleNormalValueRadio('single')"
                          value="single"
                        >
                        </v-radio>
                        <v-text-field
                          id="normalValue"
                          dense
                          item-value="normalValueId"
                          item-text="normalValueName"
                          clearable
                          v-model="itemData.normalValue"
                          placeholder="Contoh : Negatif"
                          :disabled="normalValueRadio.value === 'double'"
                          class="radio-group-normal-value__text-input-field-normal-value-single"
                        />
                      </v-row>
                      <v-row no-gutters>
                        <v-radio
                          @click="handleNormalValueRadio('double')"
                          value="double"
                        >
                        </v-radio>
                        <div
                          class="d-flex radio-group-normal-value__text-input-field-normal-value"
                        >
                          <v-text-field
                            id="lowerNormalScore"
                            dense
                            item-value="lowerNormalScoreId"
                            item-text="lowerNormalScoreName"
                            clearable
                            v-model="itemData.lowerNormalScore"
                            placeholder="Bawah"
                            :rules="inputNumberRules"
                            :disabled="normalValueRadio.value === 'single'"
                            type="number"
                          />
                          <v-text-field
                            id="upperNormalScore"
                            dense
                            item-value="upperNormalScoreId"
                            item-text="upperNormalScoreName"
                            clearable
                            v-model="itemData.upperNormalScore"
                            placeholder="Atas"
                            :rules="inputNumberRules"
                            :disabled="normalValueRadio.value === 'single'"
                            type="number"
                          />
                        </div>
                      </v-row>
                    </v-radio-group>
                  </TemplateInput>
                  <TemplateInput id="method" propsLabel="Metode">
                    <v-text-field
                      id="method"
                      dense
                      item-value="methodId"
                      item-text="methodName"
                      clearable
                      v-model="itemData.method"
                      placeholder="Metode"
                      class="text-input-field"
                    />
                  </TemplateInput>
                </v-col>
              </v-row>
            </v-col>
            <div class="d-flex justify-end py-6 pr-lg-9">
              <v-btn
                v-if="isItemEdit"
                outlined
                color="error"
                class="btnClose mr-4"
                width="80"
                @click="
                  {
                    clearItemData();
                    type !== 'add' && onStateDetail();
                  }
                "
                >Batal</v-btn
              >
              <v-btn
                v-if="isItemEdit"
                color="primary"
                outlined
                @click="onEditItem()"
              >
                Simpan Perubahan
              </v-btn>
              <v-btn
                v-if="!isItemEdit"
                outlined
                color="error"
                class="btnClose mr-4"
                width="80"
                :disabled="isItemDataEmpty"
                @click="clearItemData()"
                >Reset</v-btn
              >
              <v-btn
                v-if="!isItemEdit"
                color="primary"
                outlined
                @click="onAddItem()"
              >
                Tambah Item Data
              </v-btn>
            </div>
          </v-form>
        </v-row>

        <v-row align="center">
          <v-col cols="6">
            <h4 class="float-left">
              TABEL ITEM
            </h4>
          </v-col>
          <v-spacer />
          <v-text-field
            class="input pr-3 pr-lg-11"
            placeholder="Search"
            v-model="search"
            outlined
            solo
            dense
            hide-details
            flat
            prepend-inner-icon="mdi-magnify"
            single-line
          />
        </v-row>
        <v-data-table
          :headers="table.headers"
          :items="isSearch ? itemsDataSearch : itemsData"
          :expanded="expanded"
          :loading="loading"
          single-expand
          item-key="id"
          class="expand mt-6 table-style"
          :items-per-page="itemsPerRow"
          hide-default-footer
        >
          <template v-slot:[`item.no`]="{ index }"> {{ index + 1 }}</template>
          <template v-slot:[`item.gender`]="{ item }">
            {{ mapGender(item.gender) }}
          </template>
          <template v-slot:[`item.lowerAgeLimit`]="{ item }">
            {{ item.lowerAgeLimit + ' ' + capitalizeFirstLetter(item.age) }}
          </template>
          <template v-slot:[`item.upperAgeLimit`]="{ item }">
            {{
              item.upperAgeLimit === null
                ? 'Tidak dibatasi'
                : item.upperAgeLimit + ' ' + capitalizeFirstLetter(item.age)
            }}
          </template>
          <template v-slot:[`item.normalValue`]="{ item }">
            {{
              item.normalValue === '' &&
              item.lowerNormalScore === null &&
              item.upperNormalScore === null
                ? '-'
                : item.normalValue ||
                  item.lowerNormalScore +
                    ' - ' +
                    (item.upperNormalScore === null
                      ? 'Tidak dibatasi'
                      : item.upperNormalScore)
            }}
          </template>
          <template v-slot:[`item.actions`]="{ item, index }">
            <div class="d-flex">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    x-small
                    icon
                    class="mx-2 hover"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="onStateEdit(item, index + 1)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Ubah Item Laboratorium</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-hover>
                    <v-btn
                      slot-scope="{ hover }"
                      :color="hover ? 'error' : ''"
                      x-small
                      icon
                      class="mx-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon small @click="onDeleteItem(item.id)">
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </v-hover>
                </template>
                <span>Hapus Item Laboratorium</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </div>
    </v-card-text>

    <v-card-actions class="card-footer">
      <v-spacer></v-spacer>
      <v-btn
        color="secondary"
        width="100"
        text
        class="text-none"
        @click="onCloseButtonClick()"
      >
        Kembali
      </v-btn>
      <v-btn
        color="primary"
        class="text-none"
        depressed
        @click="
          {
            onType().onAction();
          }
        "
        :disabled="onType().disabled"
      >
        {{ onType().textButton }}
      </v-btn>
    </v-card-actions>
    <v-snackbar
      absolute
      light
      content-class="font-weight-medium pr-0"
      :value="snackbar.show"
      elevation="0"
      :color="snackbar.color"
      height="15"
    >
      <div class="d-flex justify-space-between align-center">
        <span style="color: white">
          {{ snackbar.text }}
        </span>
        <v-btn
          style="color: white"
          text
          fab
          small
          right
          @click="snackbar.show = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>
    </v-snackbar>
  </v-card>
</template>

<script>
import TemplateInput from './TemplateInput.vue';
import Constant from '@/const';
import Swal from 'sweetalert2';

const _ = require('lodash');
const axios = require('axios');

export default {
  name: 'FormLaboratory',
  components: {
    TemplateInput,
  },
  props: {
    type: {
      type: String,
      default: 'add',
    },
    dataDetail: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      isItemEdit: false,
      addItemsRules: [],
      inputNumberRules: [
        v => {
          if (v === null || v === '' || v === 0 || v === '0') {
            return true;
          } else {
            if (v < 0) return 'Masukkan Angka Positif';
            return true;
          }
        },
      ],
      itemsData: [],
      itemsDataSearch: [],
      services: [],
      ihsCodes: [],
      ihsCode: '',
      search: '',
      tempLISCode: '',
      tempIHSCode: '',
      isSearch: false,
      isItemDataEmpty: true,
      normalValueRadio: {
        value: 'single',
      },
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
      itemData: {
        id: '',
        itemName: '',
        gender: 'both',
        ageLimit: {
          lowerAgeLimit: null,
          upperAgeLimit: null,
          age: 'Tahun',
        },
        unit: '',
        normalValue: '',
        method: '',
        upperNormalScore: null,
        lowerNormalScore: null,
      },
      table: {
        headers: [
          {
            text: 'No',
            align: 'start',
            value: 'no',
          },
          {
            text: 'Nama Item',
            align: 'start',
            value: 'itemName',
          },
          {
            text: 'Jenis Kelamin',
            value: 'gender',
            sortable: false,
          },
          {
            text: 'Batas Bawah Usia',
            value: 'lowerAgeLimit',
            sortable: false,
          },
          {
            text: 'Batas Atas Usia',
            value: 'upperAgeLimit',
            sortable: false,
          },
          {
            text: 'Unit',
            value: 'unit',
            sortable: false,
          },
          {
            text: 'Nilai Normal',
            value: 'normalValue',
            sortable: false,
          },
          {
            text: 'Metode',
            value: 'method',
            sortable: false,
          },
          {
            text: '',
            value: 'actions',
            sortable: false,
          },
        ],
      },
    };
  },
  watch: {
    search() {
      this.searchData(this);
    },
    dataDetail: {
      handler() {
        if (this.type === 'add') return;

        const lisCodeChanged = this.tempLISCode !== this.dataDetail.lis_code;
        const ihsCodeChanged = this.tempIHSCode !== this.dataDetail.ihs_code;
        if (lisCodeChanged || ihsCodeChanged) {
          this.type = 'edit';
        } else {
          this.type = 'detail';
        }
      },
      deep: true,
    },
    itemData: {
      handler() {
        if (
          this.itemData.itemName === '' &&
          this.itemData.gender === 'both' &&
          this.itemData.ageLimit.lowerAgeLimit === null &&
          this.itemData.ageLimit.upperAgeLimit === null &&
          this.itemData.ageLimit.age === 'Tahun' &&
          this.itemData.unit === '' &&
          this.itemData.normalValue === '' &&
          this.itemData.lowerNormalScore === null &&
          this.itemData.upperNormalScore === null &&
          this.itemData.method === ''
        ) {
          this.isItemDataEmpty = true;
        } else {
          this.isItemDataEmpty = false;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.tempLISCode = this.dataDetail.lis_code;
    this.tempIHSCode = this.dataDetail.ihs_code;
    this.ihsCode = this.dataDetail.ihs_code;
    this.$nextTick(() => {
      this.refreshAndAutoUpdate();
      this.getServiceName();
      this.getIHSCodes();
    });
  },
  methods: {
    searchData: _.debounce(v => {
      v.handleSearch();
    }, 100),
    checkKeyDownAlphaNumeric(event) {
      if (!/[a-zA-Z0-9\s]/.test(event.key)) {
        this.ignoredValue = event.key ? event.key : '';
        event.preventDefault();
      }
    },
    handleSearch() {
      this.isSearch = true;
      const keyword = this.search;
      const initItemsData = this.itemsData;
      if (keyword === '') {
        this.isSearch = false;
        return (this.itemsDataSearch = this.itemsData);
      } else {
        this.isSearch = true;
        return (this.itemsDataSearch = initItemsData.filter(item => {
          return item.itemName.toLowerCase().includes(keyword.toLowerCase());
        }));
      }
    },
    onType() {
      switch (this.type) {
        case 'add':
          return {
            title: 'Input Data Laboratorium',
            disabled: false,
            onAction: this.onAdd,
            textButton: 'Simpan',
          };
        case 'edit':
          return {
            title: 'Input Data Laboratorium',
            disabled: false,
            onAction: this.onEdit,
            textButton: 'Simpan Perubahan',
          };
        case 'detail':
          return {
            title: 'Detail Data Laboratorium',
            disabled: true,
            onAction: this.onEdit,
            textButton: 'Simpan Perubahan',
          };
      }
    },
    onStateDetail() {
      this.type = 'detail';
    },
    onCloseButtonClick() {
      this.$emit('close-form');
    },
    mapGender(val) {
      switch (val) {
        case 'l':
          return 'Laki-laki';
        case 'p':
          return 'Perempuan';
        case 'both':
          return 'Semua';
      }
    },
    handleNormalValueRadio(val) {
      this.normalValueRadio.value = val;
    },
    handleChangeService(item) {
      if (this.interval !== null) {
        this.interval === clearInterval(this.interval);
      }
      const { sub_category, _id, code_lis } = this.services.find(
        service => service.name === item,
      );
      this.dataDetail.category = sub_category;
      this.dataDetail.id_service = _id;
      this.dataDetail.lis_code = code_lis;
      let isAvail = false;
      axios
        .get(`${Constant.apiUrl}/master/laboratory/avail/${_id}`)
        .then(res => {
          isAvail = !res.data.data;
          if (isAvail) {
            this.dataDetail.name = '';
            this.dataDetail.category = '';
            this.dataDetail.lis_code = '';
          }
          this.snackbar = {
            show: isAvail,
            text: 'Pemeriksaan yang anda pilih sudah ada',
            color: 'error',
          };
        });
      this.interval = setInterval(() => {
        this.snackbar.show = false;
      }, 4000);
    },

    handleGetIHSCode($event) {
      this.loading = true;
      _.debounce(() => {
        axios
          .get(
            `${Constant.apiUrl}/master/laboratory/ihs?page=1&itemCount=10&search=${$event.target.value}`,
          )
          .then(response => {
            this.loading = false;
            const getResponses = response.data.data;
            this.ihsCodes = getResponses;
          })
          .catch(() => {
            this.loading = false;
          });
      }, 500)();
    },
    handleChangeIHSCode(item) {
      this.ihsCode = this.ihsCodes.find(
        ihsCode => ihsCode.loinc_long_common_name === item,
      ).loinc_number;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    onStateEdit(item, index) {
      if (item.normalValue === '' || '') {
        this.normalValueRadio.value = 'double';
      } else {
        this.normalValueRadio.value = 'single';
      }
      this.itemData = {
        id: index,
        itemName: item.itemName,
        gender: item.gender,
        ageLimit: {
          lowerAgeLimit: item.lowerAgeLimit,
          upperAgeLimit: item.upperAgeLimit,
          age:
            item.age === undefined
              ? 'Tahun'
              : this.capitalizeFirstLetter(item.age),
        },
        unit: item.unit,
        normalValue: item.normalValue,
        method: item.method,
        upperNormalScore: item.upperNormalScore,
        lowerNormalScore: item.lowerNormalScore,
      };
      this.isItemEdit = true;
    },
    clearItemData() {
      this.addItemsRules = [];
      this.itemData = {
        itemName: '',
        gender: 'both',
        ageLimit: {
          lowerAgeLimit: null,
          upperAgeLimit: null,
          age: 'Tahun',
        },
        unit: '',
        normalValue: '',
        upperNormalScore: null,
        lowerNormalScore: null,
        method: '',
      };
      this.normalValueRadio.value = 'single';
      this.isItemEdit = false;
    },
    onAddItem() {
      this.addItemsRules = [v => !!v || 'Wajib Diisi'];

      this.$nextTick(() => {
        if (!this.$refs.formInputItemData.validate()) {
          return;
        }

        const {
          itemName,
          gender,
          ageLimit: { lowerAgeLimit, upperAgeLimit, age },
          unit,
          normalValue,
          upperNormalScore,
          lowerNormalScore,
          method,
        } = this.itemData;

        const normalValueRadio = this.normalValueRadio.value;

        const data = {
          id: this.itemsData.length + 1,
          itemName,
          gender,
          lowerAgeLimit: lowerAgeLimit === null ? 0 : lowerAgeLimit,
          upperAgeLimit: upperAgeLimit === null ? null : upperAgeLimit,
          age,
          unit,
          normalValue: normalValueRadio === 'single' ? normalValue : '',
          upperNormalScore:
            normalValueRadio === 'double' ? upperNormalScore : null,
          lowerNormalScore:
            normalValueRadio === 'double' ? lowerNormalScore : null,
          method: method === '' ? '' : method,
        };

        this.itemsData.push(data);
        this.clearItemData();
        this.snackbar = {
          show: () => {
            this.snackbar.show = true;
            return setTimeout(() => {
              this.snackbar.show = false;
            }, 2000);
          },
          text: 'Item Data Berhasil Ditambahkan',
          color: 'success',
        };
        this.type = this.type === 'add' ? this.type : 'edit';
      });
    },
    onEditItem() {
      this.addItemsRules = [v => !!v || 'Wajib Diisi'];
      setTimeout(() => {
        if (this.$refs.formInputItemData.validate()) {
          const newArr = this.itemsData.map(item => {
            const itemNo =
              item.no === undefined ? Number(item.id) : Number(item.no);
            if (itemNo === this.itemData.id) {
              return {
                ...item,
                itemName: this.itemData.itemName,
                gender: this.itemData.gender,
                lowerAgeLimit:
                  this.itemData.ageLimit.lowerAgeLimit === null
                    ? 0
                    : this.itemData.ageLimit.lowerAgeLimit,
                upperAgeLimit:
                  this.itemData.ageLimit.upperAgeLimit === null
                    ? null
                    : this.itemData.ageLimit.upperAgeLimit,
                age: this.itemData.ageLimit.age,
                unit: this.itemData.unit,
                normalValue:
                  this.normalValueRadio.value === 'single'
                    ? this.itemData.normalValue
                    : '',
                upperNormalScore:
                  this.normalValueRadio.value === 'double'
                    ? this.itemData.upperNormalScore
                    : null,
                lowerNormalScore:
                  this.normalValueRadio.value === 'double'
                    ? this.itemData.lowerNormalScore
                    : null,
                method: this.itemData.method === '' ? '' : this.itemData.method,
              };
            }
            return item;
          });
          this.itemsData = newArr;
          this.itemsDataSearch = newArr;
          this.clearItemData();
          this.snackbar = {
            show: () => {
              this.snackbar.show = true;
              return setTimeout(() => {
                this.snackbar.show = false;
              }, 2000);
            },
            text: 'Item Data Berhasil Diubah',
            color: 'success',
          };
          this.type = this.type === 'add' ? this.type : 'edit';
        }
      });
    },
    onDeleteItem(id) {
      Swal.fire({
        title:
          '<div style="font-family: Roboto, Serif;">Apakah Anda yakin Ingin Menghapus Item Ini?</div>',
        icon: 'question',
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batal',
        reverseButtons: true,
      }).then(result => {
        if (result.value) {
          this.snackbar = {
            show: () => {
              this.snackbar.show = true;
              return setTimeout(() => {
                this.snackbar.show = false;
              }, 2000);
            },
            text: 'Item Data Berhasil Dihapus',
            color: 'success',
          };
          this.itemsData = this.itemsData.filter(item => item.id !== id);
          this.type = this.type === 'add' ? this.type : 'edit';
        }
      });
    },
    getIHSCodes() {
      this.loading = true;
      axios
        .get(
          `${Constant.apiUrl}/master/laboratory/ihs?page=1&itemCount=10&search=`,
        )
        .then(response => {
          this.loading = false;
          const getResponses = response.data.data;
          this.ihsCodes = getResponses;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getServiceName() {
      this.loading = true;
      axios
        .get(`${Constant.apiUrl}/master/service/laboratory`)
        .then(response => {
          this.loading = false;
          const getResponses = response.data.data;
          this.services = getResponses;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getDetailMasterDataLaboratory() {
      this.loading = true;
      axios
        .get(`${Constant.apiUrl}/master/laboratory/${this.dataDetail.id}`)
        .then(response => {
          this.loading = false;
          const getResponses = response.data.data;
          this.tempIHSCode = getResponses.code_ihs?.loinc_long_common_name;
          this.dataDetail.ihs_code =
            getResponses.code_ihs?.loinc_long_common_name;
          this.dataDetail.lis_code = getResponses.code_lis;
          this.itemsData = getResponses.items.map((item, idx) => {
            return {
              id: _.uniqueId(),
              no: idx + 1,
              itemName: item.name,
              gender: item.gender,
              lowerAgeLimit: item.age_limit.lower,
              upperAgeLimit: item.age_limit.upper,
              age: item.age_limit.age_unit,
              unit: item.unit,
              normalValue: item.normal_value === '' ? '' : item.normal_value,
              method: item.method === '' ? '' : item.method,
              lowerNormalScore: item.lower_normal_score,
              upperNormalScore: item.upper_normal_score,
            };
          });
        })
        .catch(error => {
          this.loading = false;
          this.$store.dispatch('showSnackbar', {
            color: 'error',
            text: error.response.data.message,
          });
        });
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      if (this.type === 'add') {
        return;
      } else {
        this.getDetailMasterDataLaboratory();
      }
      this.interval = setInterval(this.getDetailMasterDataLaboratory, 60000);
    },
    onAdd() {
      if (this.$refs.formInputItemLaboratory.validate()) {
        this.loading = true;
        const payload = {
          id_service: this.dataDetail.id_service,
          name: this.dataDetail.name,
          category: this.dataDetail.category,
          code_lis: this.dataDetail.lis_code,
          code_ihs: this.ihsCode,
          items: this.itemsData.map(item => ({
            name: item.itemName,
            gender: item.gender,
            age_limit: {
              lower: item.lowerAgeLimit ?? 0,
              upper: item.upperAgeLimit ?? 'Infinity',
              age_unit: item.age ? item.age.toLowerCase() : 'tahun',
            },
            unit: item.unit,
            normal_value:
              this.normalValueRadio.value === 'single' ? item.normalValue : '',
            lower_normal_score: item.lowerNormalScore ?? 0,
            upper_normal_score: item.upperNormalScore ?? 'Infinity',
            method: item.method,
          })),
        };
        axios
          .post(`${Constant.apiUrl}/master/laboratory`, payload)
          .then(() => {
            this.loading = false;
            Swal.fire(
              'Data Laboratorium Berhasil Disimpan',
              '',
              'success',
            ).then(() => {
              this.onCloseButtonClick();
            });
            this.onStateDetail();
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    onEdit() {
      this.loading = true;
      const payload = {
        id_service: this.dataDetail.id_service,
        name: this.dataDetail.name,
        category: this.dataDetail.category,
        code_lis: this.dataDetail.lis_code,
        code_ihs: this.ihsCode,
        items: this.itemsData.map(item => ({
          name: item.itemName,
          gender: item.gender,
          age_limit: {
            lower: item.lowerAgeLimit ?? 0,
            upper: item.upperAgeLimit ?? 'Infinity',
            age_unit: item.age ? item.age.toLowerCase() : 'tahun',
          },
          unit: item.unit,
          normal_value:
            this.normalValueRadio.value === 'single' ? item.normalValue : '',
          lower_normal_score: item.lowerNormalScore ?? 0,
          upper_normal_score: item.upperNormalScore ?? 'Infinity',
          method: item.method,
        })),
      };
      axios
        .put(
          `${Constant.apiUrl}/master/laboratory/${this.dataDetail.id}`,
          payload,
        )
        .then(() => {
          this.loading = false;
          this.onStateDetail();
          Swal.fire('Data Laboratorium Berhasil Diubah', '', 'success').then(
            () => {
              this.onCloseButtonClick();
            },
          );
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
$screen-md-min: 768px;
$screen-lg-min: 1264px;
$screen-xl-min: 1600px;
$primary-color: #2d9cdb;
$secondary-color: #ffffff;

// Tablet devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Desktop devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Large Desktop devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

.btnClose {
  border: none;
}
.text-input-field {
  margin-left: 10px;
  max-width: 60%;
  gap: 20px;
}
.text-input-field-age-limit {
  max-width: 30%;
}
.radio-group-normal-value {
  &__text-input-field-normal-value {
    gap: 20px;
    width: 77%;
  }
  &__text-input-field-normal-value-single {
    max-width: 77%;
  }
}
.container-layout {
  position: relative;
  height: 70vh;
  padding: 0px 40px;
  overflow-x: hidden;
  overflow-y: auto;
  &__separator {
    width: 97.8%;
    margin: 10px 0px 10px 10px;
    @include lg {
      width: 96%;
    }
  }
  &::-webkit-scrollbar {
    width: 3px;
    background-color: rgb(163, 163, 163);
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: black;
  }
}
.table-style {
  width: 100%;
  @include lg {
    width: 97.5%;
  }
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #e0e0e0;
}
::v-deep .v-data-table-header {
  opacity: 70%;
}
.card-footer {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.swal2-actions {
  display: flex;
  justify-content: center;
  gap: 20px;
}

button.swal2-confirm,
button.swal2-cancel {
  border-radius: 0.25em;
  padding: 10px 15px;
}

.swal2-confirm {
  background-color: $primary-color;
  color: white;
  border: none;

  &:hover {
    background-color: darken($primary-color, 10%);
  }
}

.swal2-cancel {
  background-color: $secondary-color;
  color: $primary-color;
  border: 1px solid $primary-color;
  border-radius: 0.25em;

  &:hover {
    background-color: darken($secondary-color, 10%);
  }
}
</style>
