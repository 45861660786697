import { render, staticRenderFns } from "./DataMatching.vue?vue&type=template&id=53cfe1d5&scoped=true&"
import script from "./DataMatching.vue?vue&type=script&lang=js&"
export * from "./DataMatching.vue?vue&type=script&lang=js&"
import style0 from "./DataMatching.vue?vue&type=style&index=0&id=53cfe1d5&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53cfe1d5",
  null
  
)

export default component.exports