<template>
  <v-card>
    <v-card-title class="justify-center">
      Pengiriman Barang Gudang
      <v-btn depressed icon absolute right>
        <v-icon @click="onCloseButtonClick()">
          mdi-close-thick
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-container>
      <v-row justify="center" class="px-16 ml-16">
        <v-col cols="12">
          <v-row class="pl-16">
            <v-col cols="6" class="text-left pl-16"
              ><label for="Kode">Kode</label></v-col
            >
            <v-col cols="6" class="text-left">
              <span>TRANSFER-000026</span>
            </v-col>
          </v-row>
          <v-row class="pl-16">
            <v-col cols="6" class="text-left pl-16"
              ><label for="Kode">Tanggal</label></v-col
            >
            <v-col cols="6" class="text-left">
              <span>5/1/2021</span>
            </v-col>
          </v-row>
          <v-row class="pl-16">
            <v-col cols="6" class="text-left pl-16"
              ><label for="Kode">Dari</label></v-col
            >
            <v-col cols="6" class="text-left">
              <span>Bagian Umum (P Nur)</span>
            </v-col>
          </v-row>
          <v-row class="pl-16">
            <v-col cols="6" class="text-left pl-16"
              ><label for="Kode">Ke</label></v-col
            >
            <v-col cols="6" class="text-left">
              <span>Gudang Farmasi</span>
            </v-col>
          </v-row>
          <v-row class="pl-16">
            <v-col cols="6" class="text-left pl-16"
              ><label for="Kode">Dikirim</label></v-col
            >
            <v-col cols="6" class="text-left" align-self="start">
              <v-checkbox class="pt-0 mt-0" hide-details />
            </v-col>
          </v-row>
          <v-row class="pl-16">
            <v-col cols="6" class="text-left pl-16"
              ><label for="Kode">Diterima</label></v-col
            >
            <v-col cols="6" class="text-left">
              <v-checkbox class="pt-0 mt-0" hide-details />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-main>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="desserts"
              class="px-10"
              :items-per-page="5"
              hide-default-footer
            ></v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer class="d-flex justify-center pa-4" color="white">
      <v-btn color="primary" depressed>
        Simpan
      </v-btn>
    </v-footer>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: 'No',

          value: 'noQueue',
        },
        { text: 'Nama Barang', value: 'itemName' },
        { text: 'Sisa Gudang', value: 'fat' },
        { text: 'Jumlah Pesanan', value: 'carbs' },
        { text: 'Kemasan', value: 'iron' },
      ],
      desserts: [
        {
          noQueue: 1,
          itemName: 'Omeprazole',
          fat: 4960,
          carbs: 2,
          iron: 'Butir',
        },
      ],
    };
  },
  methods: {
    onCloseButtonClick() {
      this.$emit('close-dialog');
    },
  },
};
</script>
<style></style>
