<template>
  <div>
    <v-toolbar flat outlined height="75">
      <v-col cols="7" class="text-left">
        <v-toolbar-title class="font-weight-bold float-left">
          Riwayat Ubah Transaksi
        </v-toolbar-title>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="2">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              hide-details="auto"
              v-model="date"
              class="input"
              outlined
              placeholder="Pilih Tanggal"
              prepend-inner-icon="mdi-calendar-month"
              readonly
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="filterDate()">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="3">
        <v-text-field
          class="input"
          background-color="grey lighten-3"
          placeholder="Search"
          solo
          dense
          hide-details
          flat
          prepend-inner-icon="mdi-magnify"
          single-line
          v-model="search"
        ></v-text-field>
      </v-col>
    </v-toolbar>
    <v-row no-gutters>
      <v-data-table
        :headers="historyData.headers"
        :items="historyData.items"
        class="cashTable"
        :page.sync="table.pagination.page"
        hide-default-footer
        :expanded.sync="expanded"
        single-expand
        @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
        show-expand
        item-key="id"
        :items-per-page="5"
        :search="search"
        fixed-header
        height="370"
      >
        <template v-slot:[`item.no`]="{ item }">
          <tr class="text-left" :key="index">
            <td>
              {{ historyData.items.indexOf(item) + 1 }}
            </td>
          </tr>
        </template>
        <template v-slot:expanded-item="{ item, headers }">
          <td :colspan="headers.length" class="expand">
            <v-card flat color="#F9F9F9">
              <v-container>
                <v-row no-gutters>
                  <v-col cols="2" class="text-left pl-11 grey--text caption">
                    <span v-for="data in item.inputDate" :key="data"
                      >{{ data.created_at }}<br />Pada {{ data.time
                      }}<br /><br />
                    </span>
                  </v-col>

                  <v-col cols="2" class="text-left pl-4 grey--text caption">
                    <span v-for="data in item.officerName" :key="data"
                      >{{ data.created_by }}<br /><br /><br /></span
                  ></v-col>

                  <v-col cols="2" class="text-left pl-2 grey--text caption"
                    ><span v-for="data in item.transName" :key="data"
                      >{{ data.name }}<br /><br /><br
                    /></span>
                  </v-col>
                  <v-col
                    cols="2"
                    class="text-left pl-3 grey--text caption"
                    style="max-width:13%;"
                    ><span v-for="data in item.cashType" :key="data"
                      >{{ data.type_fund }}<br /><br /><br /></span
                  ></v-col>
                  <v-col cols="2" class="text-left grey--text caption"
                    ><span v-for="data in item.nominal" :key="data"
                      >{{ data.total }}<br /><br /><br /></span
                  ></v-col>
                </v-row>
              </v-container>
            </v-card>
          </td>
        </template>
      </v-data-table>
    </v-row>

    <v-card-actions>
      <v-pagination
        class="pagination"
        v-model="table.pagination.page"
        :length="itemPages"
        total-visible="7"
      ></v-pagination>
      <v-spacer></v-spacer>
    </v-card-actions>
  </div>
</template>

<script>
import Constant from '@/const';
const moment = require('moment');
const axios = require('axios');
export default {
  components: {},
  data() {
    return {
      search: '',
      date: '',
      menu: false,
      expanded: [],
      singleExpand: false,
      table: {
        pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 5,
          totalItems: null,
        },
      },
      historyData: {
        headers: [
          { text: 'No', value: 'no' },
          { text: 'Tanggal Input', value: 'inputDate[0].created_at' },
          { text: 'Nama Petugas', value: 'officerName[0].created_by' },
          { text: 'Nama Transaksi', value: 'transName[0].name' },
          { text: 'Jenis Kas', value: 'cashType[0].type_fund' },
          { text: 'Nominal', value: 'nominal[0].total' },
          { text: 'Alasan', value: 'reason' },
          { text: 'Keterangan', value: 'detail' },
          { text: '', value: 'data-table-expand' },
        ],
        items: [],
      },
    };
  },
  computed: {
    history() {
      return '/keuangan/kas-masuk-keluar/riwayat-transaksi';
    },
  },

  mounted() {
    this.$nextTick(function() {
      this.getDataHistory();
    });
  },

  methods: {
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    },
    filterDate() {
      const date = this.date;
      const itemCount = 5;
      axios
        .get(Constant.apiUrl.concat('/finance/report?search=').concat(date))
        .then(response => {
          const getDataItem = response.data.data;
          this.itemPages = Math.ceil(response.data.data.length / itemCount);
          this.historyData.items = getDataItem.map(item => {
            return {
              id: item._id,
              inputDate: item.detail.map(item => {
                return {
                  created_at: moment(item.timestamps.created_at).format(
                    'DD/MM/YYYY',
                  ),
                  time: moment(item.timestamps.created_at).format('HH:mm'),
                };
              }),
              inputTime: item.detail.map(item => {
                return {
                  created_at: moment(item.timestamps.created_at).format(
                    'HH:mm',
                  ),
                };
              }),
              officerName: item.detail.map(item => {
                return {
                  created_by: item.timestamps.created_by,
                };
              }),
              transName: item.detail.map(item => {
                return {
                  name: item.name_transaction,
                };
              }),
              cashType: item.detail.map(item => {
                return {
                  type_fund: item.type_fund,
                };
              }),
              nominal: item.detail.map(item => {
                return {
                  total: this.formatNumber(`Rp. ${item.total}`),
                };
              }),
              reason: item.reason,

              detail:
                item.isUpdated === true
                  ? 'diedit'
                  : '' || item.isDeleted === true
                  ? 'didelete'
                  : '',
            };
          });
          this.menu = false;
        });
    },
    getDataHistory() {
      const itemCount = 5;
      axios.get(Constant.apiUrl.concat('/finance/report')).then(response => {
        const getDataItem = response.data.data;
        this.itemPages = Math.ceil(response.data.data.length / itemCount);
        this.historyData.items = getDataItem.map(item => {
          return {
            id: item._id,
            inputDate: item.detail.map(item => {
              return {
                created_at: moment(item.timestamps.created_at).format(
                  'DD/MM/YYYY',
                ),
                time: moment(item.timestamps.created_at).format('HH:mm'),
              };
            }),
            inputTime: item.detail.map(item => {
              return {
                created_at: moment(item.timestamps.created_at).format('HH:mm'),
              };
            }),
            officerName: item.detail.map(item => {
              return {
                created_by: item.timestamps.created_by,
              };
            }),
            transName: item.detail.map(item => {
              return {
                name: item.name_transaction,
              };
            }),
            cashType: item.detail.map(item => {
              return {
                type_fund:
                  item.type_fund === 'debit' ? 'Kas Masuk' : 'Kas Keluar',
              };
            }),
            nominal: item.detail.map(item => {
              return {
                total: this.formatNumber(`Rp. ${item.total}`),
              };
            }),
            reason: item.reason,

            detail:
              item.isUpdated === true
                ? 'Diedit'
                : '' || item.isDeleted === true
                ? 'Dihapus'
                : '',
          };
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cashTable {
  width: 100%;
}
.pagination {
  position: absolute;
  bottom: 50px;
}
.expand {
  border: none !important;
  background-color: #f9f9f9;
}
::v-deep
  .v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded {
  background-color: #6eafdb;
  color: white;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #6eafdb;
}
</style>
