<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Open Dialog
        </v-btn>
      </template>

      <v-card>
        <v-tabs vertical>
          <v-tab
            active-class="active"
            class="font-weight-bold justify-start black--text text-capitalize mt-9 mx-9"
            dark
          >
            <span>Assesmen Awal</span>
          </v-tab>
          <v-tab
            active-class="active"
            class="font-weight-bold justify-start black--text text-capitalize mx-9"
            dark
          >
            <span>CPPT</span>
          </v-tab>
          <v-tab
            active-class="active"
            class="font-weight-bold justify-start black--text text-capitalize mx-9"
            dark
          >
            <span>Observasi Pasien</span>
          </v-tab>
          <v-tab
            active-class="active"
            class="font-weight-bold justify-start black--text text-capitalize mx-9"
            dark
          >
            <span>Rekonsiliasi Obat</span>
          </v-tab>
          <v-tab
            active-class="active"
            class="font-weight-bold justify-start black--text text-capitalize mx-9"
            dark
          >
            <span class="text-left"
              >Catatan Permintaan <br />
              dan Pemberian Obat</span
            >
          </v-tab>
          <v-tab
            active-class="active"
            class="font-weight-bold justify-start black--text text-capitalize mx-9"
            dark
          >
            <span class="text-left"
              >Pemantauan Efek <br />
              Samping Obat</span
            >
          </v-tab>
          <v-tab
            active-class="active"
            class="font-weight-bold justify-start black--text text-capitalize mx-9"
            dark
          >
            <span class="text-left"
              >Implementasi Tindakan <br />
              Keperawatan</span
            >
          </v-tab>
          <v-tab
            active-class="active"
            class="font-weight-bold justify-start black--text text-capitalize mx-9"
            dark
          >
            <span>Aplikasi Restrain</span>
          </v-tab>
          <v-tab
            active-class="active"
            class="font-weight-bold justify-start black--text text-capitalize mx-9"
            dark
          >
            <span>Observasi Restrain</span>
          </v-tab>
          <v-tab
            active-class="active"
            class="font-weight-bold justify-start black--text text-capitalize mx-9"
            dark
          >
            <span>Resume Medis</span>
          </v-tab>
          <v-tab
            active-class="active"
            class="font-weight-bold justify-start black--text text-capitalize mx-9"
            dark
          >
            <span>Perencanaan Pulang</span>
          </v-tab>
          <v-tab
            active-class="active"
            class="font-weight-bold justify-start black--text text-capitalize mx-9"
            dark
          >
            <span>Transfer Internal</span>
          </v-tab>
          <v-tab
            active-class="active"
            class="font-weight-bold justify-start black--text text-capitalize mx-9"
            dark
          >
            <span>Assesmen Spiritual</span>
          </v-tab>

          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <p>
                  Morbi nec metus. Suspendisse faucibus, nunc et pellentesque
                  egestas, lacus ante convallis tellus, vitae iaculis lacus elit
                  id tortor. Sed mollis, eros et ultrices tempus, mauris ipsum
                  aliquam libero, non adipiscing dolor urna a orci. Curabitur
                  ligula sapien, tincidunt non, euismod vitae, posuere
                  imperdiet, leo. Nunc sed turpis.
                </p>

                <p>
                  Suspendisse feugiat. Suspendisse faucibus, nunc et
                  pellentesque egestas, lacus ante convallis tellus, vitae
                  iaculis lacus elit id tortor. Proin viverra, ligula sit amet
                  ultrices semper, ligula arcu tristique sapien, a accumsan nisi
                  mauris ac eros. In hac habitasse platea dictumst. Fusce ac
                  felis sit amet ligula pharetra condimentum.
                </p>

                <p>
                  Sed consequat, leo eget bibendum sodales, augue velit cursus
                  nunc, quis gravida magna mi a libero. Nam commodo suscipit
                  quam. In consectetuer turpis ut velit. Sed cursus turpis vitae
                  tortor. Aliquam eu nunc.
                </p>

                <p>
                  Etiam ut purus mattis mauris sodales aliquam. Ut varius
                  tincidunt libero. Aenean viverra rhoncus pede. Duis leo. Fusce
                  fermentum odio nec arcu.
                </p>

                <p class="mb-0">
                  Donec venenatis vulputate lorem. Aenean viverra rhoncus pede.
                  In dui magna, posuere eget, vestibulum et, tempor auctor,
                  justo. Fusce commodo aliquam arcu. Suspendisse enim turpis,
                  dictum sed, iaculis a, condimentum nec, nisi.
                </p>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <p>
                  Fusce a quam. Phasellus nec sem in justo pellentesque
                  facilisis. Nam eget dui. Proin viverra, ligula sit amet
                  ultrices semper, ligula arcu tristique sapien, a accumsan nisi
                  mauris ac eros. In dui magna, posuere eget, vestibulum et,
                  tempor auctor, justo.
                </p>

                <p class="mb-0">
                  Cras sagittis. Phasellus nec sem in justo pellentesque
                  facilisis. Proin sapien ipsum, porta a, auctor quis, euismod
                  ut, mi. Donec quam felis, ultricies nec, pellentesque eu,
                  pretium quis, sem. Nam at tortor in tellus interdum sagittis.
                </p>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <p>
                  Fusce a quam. Phasellus nec sem in justo pellentesque
                  facilisis. Nam eget dui. Proin viverra, ligula sit amet
                  ultrices semper, ligula arcu tristique sapien, a accumsan nisi
                  mauris ac eros. In dui magna, posuere eget, vestibulum et,
                  tempor auctor, justo.
                </p>

                <p class="mb-0">
                  Cras sagittis. Phasellus nec sem in justo pellentesque
                  facilisis. Proin sapien ipsum, porta a, auctor quis, euismod
                  ut, mi. Donec quam felis, ultricies nec, pellentesque eu,
                  pretium quis, sem. Nam at tortor in tellus interdum sagittis.
                </p>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <p>
                  Fusce a quam. Phasellus nec sem in justo pellentesque
                  facilisis. Nam eget dui. Proin viverra, ligula sit amet
                  ultrices semper, ligula arcu tristique sapien, a accumsan nisi
                  mauris ac eros. In dui magna, posuere eget, vestibulum et,
                  tempor auctor, justo.
                </p>

                <p class="mb-0">
                  Cras sagittis. Phasellus nec sem in justo pellentesque
                  facilisis. Proin sapien ipsum, porta a, auctor quis, euismod
                  ut, mi. Donec quam felis, ultricies nec, pellentesque eu,
                  pretium quis, sem. Nam at tortor in tellus interdum sagittis.
                </p>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-toolbar flat prominent height="180">
              <v-container>
                <v-row no-gutters justify="space-between" class="mt-4">
                  <v-col cols="9" align-self="center" class="text-left">
                    <div>
                      <span>No RM</span>
                      <span class="black--text font-weight-bold mx-6"
                        >091422</span
                      >

                      <span>Name</span>
                      <span class="black--text font-weight-bold mx-6"
                        >Panji Sekar (L)</span
                      >

                      <span>Tanggal Lahir</span>
                      <span class="black--text font-weight-bold mx-6"
                        >1/11/1998 (20)</span
                      >
                    </div>

                    <div class="mt-5">
                      <span>DRJP</span>
                      <span class="black--text font-weight-bold mx-6"
                        >dr. Deny Setiawan</span
                      >
                      <span>Riwayat Alergi</span>
                      <span class="red--text font-weight-bold mx-6"
                        >1. Amoxylin</span
                      >
                      <span class="red--text font-weight-bold mx-6"
                        >2. Ketorolac</span
                      >
                    </div>
                  </v-col>
                  <v-col cols="3" class="text-right"
                    ><span
                      class="black--text text-center font-weight-bold headline "
                      >Catatan Permintaan <br />
                      dan Pemberian Obat</span
                    ></v-col
                  >
                  <v-col cols="2" offset="10" class="text-right mt-8">
                    <v-btn
                      @click="sheet = true"
                      width="150"
                      tile
                      depressed
                      color="primary"
                    >
                      Tambah Obat
                    </v-btn>
                    <v-bottom-sheet v-model="sheet">
                      <v-sheet height="300px">
                        <h1
                          class="headline pa-4 ml-14 text-left font-weight-bold"
                        >
                          Entry Obat Baru
                        </h1>
                        <v-container>
                          <v-row align="center">
                            <v-col cols="2" class="text-left">
                              <label for="Nama Obat" class="font-weight-bold">
                                Nama Obat</label
                              >
                            </v-col>
                            <v-col cols="2">
                              <v-text-field
                                class="text-left"
                                placeholder="Masukkan Nama Obat"
                                dense
                                hide-details
                              />
                            </v-col>
                            <v-col cols="2" class="text-left">
                              <label for="Jenis Obat" class="font-weight-bold"
                                >Jenis Obat</label
                              >
                            </v-col>
                            <v-col cols="2" class="text-left">
                              <span>Oral</span>
                            </v-col>
                            <v-col cols="2" class="text-left">
                              <label for="Keterangan" class="font-weight-bold"
                                >Keterangan</label
                              >
                            </v-col>
                            <v-col cols="2" class="text-left">
                              <span>Menurunkan Demam</span>
                            </v-col>
                          </v-row>
                          <v-row align="center">
                            <v-col cols="2" class="text-left">
                              <label for="Nama Obat" class="font-weight-bold">
                                Tanggal</label
                              >
                            </v-col>
                            <v-col cols="2" class="text-left">
                              <v-select
                                placeholder="Pilih Tanggal"
                                dense
                                hide-details
                              />
                            </v-col>
                            <v-col cols="2" class="text-left">
                              <label for="Jenis Obat" class="font-weight-bold"
                                >Dosis</label
                              >
                            </v-col>
                            <v-col cols="2" class="text-left">
                              <span>3x500mg</span>
                            </v-col>
                            <v-col cols="2" class="text-left">
                              <label for="Keterangan" class="font-weight-bold"
                                >Rute</label
                              >
                            </v-col>
                            <v-col cols="2" class="text-left">
                              <span>Per Oral</span>
                            </v-col>
                          </v-row>
                          <v-row align="center">
                            <v-col cols="2" class="text-left">
                              <label for="Nama Obat" class="font-weight-bold">
                                Pukul</label
                              >
                            </v-col>
                            <v-col cols="2" class="text-left">
                              <v-select
                                placeholder="Pilih Jam"
                                dense
                                hide-details
                              />
                            </v-col>
                            <v-col cols="2" class="text-left">
                              <label for="Jenis Obat" class="font-weight-bold"
                                >Aturan Pakai</label
                              >
                            </v-col>
                            <v-col cols="2" class="text-left">
                              <span>3x500gr</span>
                            </v-col>
                          </v-row>
                        </v-container>
                        <v-footer color="white">
                          <v-spacer />
                          <v-btn
                            width="150"
                            tile
                            class="pa-4"
                            depressed
                            color="primary"
                          >
                            Simpan
                          </v-btn>
                        </v-footer>
                      </v-sheet>
                    </v-bottom-sheet>
                  </v-col>
                </v-row>
              </v-container>
            </v-toolbar>
            <v-main>
              <v-container>
                <v-row>
                  <v-col>
                    <v-data-table
                      :headers="table.headers"
                      :items="drugs"
                      :search="table.search"
                      :expanded="expanded"
                      :loading="loading"
                      single-expand
                      item-key="id"
                      :items-per-page="5"
                      hide-default-footer
                    >
                      <template v-slot:item="{ item, expand, isExpanded }">
                        <tr
                          class="text-left"
                          :key="item.index"
                          @click="expand(!isExpanded)"
                        >
                          <td class="border">{{ item.noQueue }}</td>
                          <td>{{ item.drugType }}</td>
                          <td>{{ item.drugName }}</td>
                          <td>{{ item.dose }}</td>
                          <td>{{ item.time }}</td>
                        </tr>
                      </template>

                      <template v-slot:expanded-item="{ item, headers }">
                        <td @click="selectItem(item)" :colspan="headers.length">
                          <v-card tile class="border" flat>
                            <v-row no-gutters>
                              <v-col cols="1"></v-col>
                              <v-col
                                cols="2"
                                class="text-left  grey--text text--darken-2 caption "
                                style="margin-left: 60px;"
                              >
                                <span class="item-expand"
                                  >Rute: per Oral
                                </span></v-col
                              >
                              <v-col
                                cols="3"
                                class="text-left grey--text text--darken-2 caption"
                                style="margin-left: 8px;"
                              >
                                <span class="item-expand"
                                  >1 gr <br />
                                  Keterangan : Menurunkan Demam <br />
                                  Diresepkan oleh dr. Ameera Anasya <br />
                                  Mulai treatment 29 Maret 12:00
                                </span></v-col
                              >
                              <v-col cols="2"></v-col>
                              <v-col
                                cols="2"
                                class="text-left  grey--text text--darken-2 caption"
                                style="margin-left: 60px;"
                              >
                                <span class="item-expand"
                                  >12.00 <br />
                                  Petugas: Nasyiya</span
                                >
                              </v-col>
                            </v-row>
                          </v-card>
                          <v-card tile flat color="grey lighten-4">
                            <v-row
                              no-gutters
                              justify="center"
                              align="center"
                              class="border"
                              ><v-switch v-model="switch1" inset></v-switch>
                              <span class="text--black font-weight-bold"
                                >Hentikan</span
                              >
                              <v-btn class="ml-2" icon>
                                <v-icon small>
                                  mdi-pencil
                                </v-icon>
                              </v-btn>
                            </v-row>
                          </v-card>
                        </td>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-main>
            <v-footer color="white">
              <v-container>
                <v-row>
                  <v-col class="text-right">
                    <v-btn
                      width="200"
                      class="ml-6"
                      tile
                      outlined
                      color="primary"
                    >
                      <v-icon>
                        mdi-plus-thick
                      </v-icon>
                      <span class="caption font-weight-bold text-capitalize"
                        >Input Permintaan Alkes</span
                      >
                    </v-btn>
                    <v-btn
                      width="200"
                      class="ml-6"
                      tile
                      outlined
                      color="primary"
                    >
                      <v-icon>
                        mdi-plus-thick
                      </v-icon>
                      <span class="caption font-weight-bold text-capitalize"
                        >Tambah Jenis Obat</span
                      >
                    </v-btn>
                    <v-btn
                      width="200"
                      class="ml-6"
                      tile
                      outlined
                      color="primary"
                    >
                      <v-icon>
                        mdi-plus-thick
                      </v-icon>
                      <span class="caption font-weight-bold text-capitalize"
                        >Order Obat Farmasi</span
                      >
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-footer>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      sheet: false,
      drugs: [
        {
          noQueue: 1,
          drugType: 'ORAL',
          drugName: 'Paracetamol',
          dose: '3x500g',
          time: '29 Maret 2020',
        },
      ],
      table: {
        search: '',
        headers: [
          {
            text: 'No',

            value: 'noQueue',
          },
          { text: 'Jenis Obat', value: 'drugType', sortable: false },
          { text: 'Nama Obat', value: 'drugName', sortable: false },
          { text: 'Dosis/Aturan Pakai', value: 'dose', sortable: false },
          { text: 'Waktu', value: 'time', sortable: false },
        ],
        pagination: {
          descending: false,
          page: 1,
          rowsPerPage: 5,
          sortBy: '',
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.active {
  background-color: #3498db;

  & > span {
    color: #fff;
  }
}

.headline {
  color: #3498db;
}

.border {
  border-left: 5px solid #3498db !important;
  border-radius: none;
}

.item-expand {
  color: #a6adb4;
}

::v-deep {
  thead tr th span {
    color: #1a1a1a;
  }

  tbody tr td {
    color: #272727;
    font-weight: bold;
  }

  .v-data-table
    > .v-data-table__wrapper
    tbody
    tr.v-data-table__expanded__content {
    box-shadow: none;
    background-color: #f4f5f5;
  }

  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border: none;
  }
}
</style>
