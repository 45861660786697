<template>
  <v-main>
    <dashboard-queue-header :clinic="clinicName" />
    <section>
      <v-row>
        <v-col cols="8" class="pa-10">
          <v-card
            v-for="item in publicQueue"
            :key="item._id"
            flat
            class="poly-card bordered-card mb-4"
          >
            <span>{{ item.id_unit?.name || '-' }}</span>
            <span>{{ item.id_staff?.detail?.[0]?.name || '-' }}</span>
            <span class="text-center">Ruang {{ item.room || '-' }}</span>
            <span class="primary--text font-weight-bold text-end">{{
              item.queueNormal?.[0]?.queue_code || '-'
            }}</span>
          </v-card>
        </v-col>
        <v-col class="pa-10">
          <v-card flat class="bordered-card pass-queue-card">
            <v-card-title class="mb-4 px-0">
              ANTREAN DILEWATI
            </v-card-title>
            <div class="container-limit">
              <div
                class="list-container"
                :class="{ animate: isOverflow }"
                id="list-container"
              >
                <div
                  v-for="(item, index) in missedPatientsForPublicView"
                  :key="`missed-queue-${index}`"
                >
                  <h3>{{ item.poly }} ({{ item.doctor }})</h3>
                  <div class="queue-list">
                    <span
                      v-for="(code, idx) in item.queueCodeList"
                      :key="`code-${idx}`"
                      class="primary--text"
                      >{{ code }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </section>
  </v-main>
</template>

<script>
import DashboardQueueForPatientScript from './DashboardQueueForPatient.js';

export default DashboardQueueForPatientScript;
</script>

<style lang="scss" scoped>
$primary-text-color: #616161;

section:nth-child(2) {
  height: 89vh;
  background-color: #fafafa;

  .bordered-card {
    border: 2px solid #e0e0e0;
    border-radius: 9px;
    background-color: white;
  }

  .poly-card {
    height: 4.5vw;
    padding: 1vw;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    text-align: start;
    color: $primary-text-color;
    font-size: 1.1vw;
    align-items: center;
  }

  .pass-queue-card {
    height: 80vh;
    color: $primary-text-color;
    text-align: start;
    padding: 0 1vw;
    font-weight: 500;

    .container-limit {
      max-height: 68vh;
      overflow: hidden;

      .animate {
        animation: loop-scroll 10s linear infinite;

        @keyframes loop-scroll {
          0% {
            transform: translate3d(0, 65vh, 0);
          }
          100% {
            transform: translate3d(0, -110%, 0);
          }
        }
      }

      .list-container {
        overflow-y: auto;
        max-height: 68vh;
        .queue-list {
          display: grid;
          margin-top: 0.7vw;
          margin-bottom: 2.5vw;
          gap: 1.2vw;
          font-size: 1.1vw;
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }
  }
}
</style>
