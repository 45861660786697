<template>
  <div class="main-unit">
    <v-toolbar flat outlined>
      <v-container fluid>
        <v-row align="center">
          <v-col>
            <v-toolbar-title class="font-weight-bold float-left">
              Data Unit
            </v-toolbar-title>
          </v-col>

          <v-spacer></v-spacer>

          <v-btn
            class="mr-2"
            color="grey"
            icon
            text
            @click="refreshAndAutoUpdate"
          >
            <v-icon>mdi-autorenew</v-icon>
          </v-btn>
          <v-text-field
            class="input"
            v-model="search"
            background-color="grey lighten-3"
            placeholder="Search"
            solo
            dense
            hide-details
            flat
            prepend-inner-icon="mdi-magnify"
            single-line
          ></v-text-field>
        </v-row>
      </v-container>
    </v-toolbar>
    <v-main>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :loading="loading"
              :headers="table.headers"
              :items="unit"
              :expanded="expanded"
              :items-per-page="itemsPerRow"
              single-expand
              item-key="id"
              @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
              :page.sync="table.pagination.page"
              hide-default-footer
            >
              <template v-slot:expanded-item="{ item, headers }">
                <td
                  @click="selectItem(item)"
                  :colspan="headers.length"
                  class="expand"
                >
                  <v-card flat color="grey lighten-4" class="text-right">
                    <v-container>
                      <v-row no-gutters>
                        <v-col cols="12">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                x-small
                                icon
                                class="mx-2 hover"
                                @click="detailDialog = true"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon>
                                  mdi-clipboard-text
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Detail Unit</span>
                          </v-tooltip>
                          <v-dialog v-model="detailDialog" max-width="900">
                            <detail-unit
                              @close-dialog="closeDialog"
                              v-if="selectedItem && detailDialog"
                              :unitDetail="selectedItem"
                            />
                          </v-dialog>

                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                x-small
                                icon
                                class="mx-2 hover"
                                v-bind="attrs"
                                v-on="on"
                                @click="updateDialog = true"
                              >
                                <v-icon>
                                  mdi-clipboard-plus
                                </v-icon>
                              </v-btn>
                            </template>

                            <span>Ubah Unit</span>
                          </v-tooltip>

                          <v-dialog v-model="updateDialog" max-width="900">
                            <update-unit
                              @close-dialog="closeDialog"
                              @update-data="getAllUnit()"
                              v-if="selectedItem && updateDialog"
                              :unitDetail="selectedItem"
                            />
                          </v-dialog>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                x-small
                                icon
                                class="mx-2 hover"
                                v-bind="attrs"
                                v-on="on"
                                @click="deleteUnit(item)"
                              >
                                <v-icon>
                                  mdi-close-thick
                                </v-icon>
                              </v-btn>
                            </template>

                            <span>Hapus Unit</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-footer color="white">
      <v-container fluid>
        <v-row>
          <v-pagination
            v-model="table.pagination.page"
            :length="unitPages"
            :total-visible="10"
            @input="getAllUnit()"
          ></v-pagination>
          <v-spacer></v-spacer>
          <template>
            <v-dialog v-model="dialog" max-width="900px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" fab dark v-bind="attrs" v-on="on">
                  <v-icon dark>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </template>
              <InputNewUnit v-if="dialog" @close-dialog="closeDialog" />
            </v-dialog>
          </template>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import InputNewUnit from './components/Unit/InputNewUnit';
import Swal from 'sweetalert2';
import Constant from '@/const';
import DetailUnit from './components/Unit/DetailUnit.vue';
import UpdateUnit from './components/Unit/UpdateUnit.vue';
import jwtMixin from '@/mixin/jwtMixin';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'MasterdataUnit',
  mixins: [jwtMixin],
  components: {
    InputNewUnit,
    DetailUnit,
    UpdateUnit,
  },
  data() {
    return {
      loading: true,
      expanded: [],
      dialog: false,
      detailDialog: false,
      updateDialog: false,

      selectedItem: null,

      unitPages: 0,
      unit: [],
      table: {
        headers: [
          { text: 'Nama', value: 'name' },
          { text: 'Nomor', value: 'unit_number' },
          { text: 'Instalasi', value: 'installation' },
          { text: 'Farmasi', value: 'isPharmacy' },
          { text: 'Gudang', value: 'isWarehouse' },
        ],
        pagination: {
          descending: false,
          page: 1,
          rowsPerPage: 7,
          sortBy: '',
        },
      },
      search: '',
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshAndAutoUpdate();
    });
  },
  watch: {
    search() {
      this.searchData(this);
      this.table.pagination.page = 1;
    },
  },
  computed: {
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
  },
  methods: {
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.getAllUnit();
    }, 650),
    closeDialog() {
      this.dialog = false;
      this.detailDialog = false;
      this.updateDialog = false;
      this.refreshAndAutoUpdate();
    },

    selectItem(unit) {
      this.selectedItem = unit;
    },

    deleteUnit(unit) {
      Swal.fire({
        title: 'Yakin?',
        text: `Anda akan menghapus ${unit.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak',
      }).then(result => {
        if (result.value) {
          axios
            .delete(`${Constant.apiUrl}/master/unit/${unit.id}`)
            .then(response => {
              if (response.status === 200) {
                Swal.fire(
                  'Berhasil',
                  `Berhasil Menghapus Unit ${unit.name}`,
                  'success',
                );
                this.getAllUnit();
              }
            });
        }
      });
    },

    getAllUnit() {
      const itemCount = this.itemsPerRow;
      const halaman = this.table.pagination.page;
      let sortBy = '';
      const keyword = this.search;
      this.loading = true;

      this.runApiCalls(() => {
        axios
          .get(
            `${Constant.apiUrl}/master/unit?page=${halaman}&itemCount=${itemCount}&sort=${sortBy}&search=${keyword}`,
          )
          .then(response => {
            const unitDataGet = response.data.data;

            this.unitPages = Math.ceil(response.data.length / itemCount);
            this.unit = unitDataGet.map(unit => {
              return {
                id: unit._id.toString(),
                name: unit.name,
                unit_number: unit.unit_number,
                // code: unit.detail.code,
                installation: unit.detail.installation,
                isPharmacy: unit.detail.isPharmacy ? 'Ya' : 'Tidak',
                isWarehouse: unit.detail.isWarehouse ? 'Ya' : 'Tidak',
              };
            });

            this.loading = false;
          })
          .catch(() => {
            this.unitPages = 1;
            this.unit = [];
            this.loading = false;
          });
      });
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.getAllUnit();
      this.interval = setInterval(this.getAllUnit, 60000);
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.input {
  max-width: 30%;
}

.hover:hover {
  color: #2196f3;
}

::v-deep {
  .v-data-table
    > .v-data-table__wrapper
    tbody
    tr.v-data-table__expanded__content {
    box-shadow: none;
    background-color: #f4f5f5;
  }

  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border: none;
  }
}
</style>
