<template>
  <v-container fluid>
    <v-card flat class="body-container px-5">
      <h3 class="title">History Permintaan dan Pemberian Obat</h3>
      <div class="banner rounded-xl mb-3">
        <div v-for="(groupDate, index) in date" :key="groupDate">
          <div class="date">
            <h3>{{ index }}</h3>
          </div>
          <!-- <v-layout wrap pa-3 style="height: auto"> -->
          <div class="card-list">
            <div v-for="history in groupDate" :key="history" pl-3>
              <v-card
                class="history-card rounded-lg"
                @click="history.expanded = !history.expanded"
                v-bind:class="{ active: history.expanded }"
              >
                <v-layout pt-1>
                  <v-flex xs4 mx-0>
                    <p
                      style="font-size: 30px; font-weight: bold; color: #3f484a"
                    >
                      {{ history.date }}
                    </p>
                  </v-flex>
                  <v-flex
                    xs8
                    class="text-md-left"
                    style="font-size: 15px; color: #9ca2a5"
                  >
                    {{ history.month }}
                    <br />
                    {{ history.year }}
                  </v-flex>
                </v-layout>
                <h4 class="pl-4" style="text-align: left">
                  {{
                    history.name[0].detail
                      ? history.name[0].detail
                      : history.name
                  }}
                </h4>
                <v-layout>
                  <v-flex class="text-md-left pl-4">
                    {{ history.dosage }} {{ history.unit_dosage }}
                    <br />
                    {{ history.route || '-' }}
                  </v-flex>
                  <v-flex pt-2>
                    <p style="font-weight: bold">{{ history.usage }}</p>
                  </v-flex>
                </v-layout>

                <v-card-actions v-show="history.expanded">
                  <v-flex
                    pl-2
                    mx-0
                    class="text-md-left"
                    style="font-size: 15px"
                  >
                    <p>
                      Jumlah obat diminta : {{ history.requested_quantity }}
                    </p>
                    <p>Jumlah obat diberikan : {{ history.quantity }}</p>
                  </v-flex>
                </v-card-actions>
              </v-card>
            </div>
          </div>
          <!-- </v-layout> -->
        </div>
      </div>
    </v-card>

    <v-card-action class="action-layout">
      <v-spacer />
      <div class="action-footer pr-6">
        <v-btn @click="$emit('before')" class="btn-entry ml-3" tile depressed>
          Sebelumnya
        </v-btn>
      </div>
    </v-card-action>
  </v-container>
</template>

<script>
// import Swal from 'sweetalert2';
import moment from 'moment-timezone';
import { groupBy } from 'lodash';

export default {
  props: {
    historyDrug: Object,
  },
  data() {
    return {
      expanded: false,
      drug: [],
      date: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.setData();
    });
  },
  methods: {
    setData() {
      const racik = this.historyDrug.mix_recipe.map(mixed => {
        return {
          id: mixed._id,
          name: mixed.name,
          drugs: mixed.drugs,
          requested_quantity: mixed.requested_quantity,
          quantity: mixed.quantity,
          packaging: mixed.packaging,
          description: mixed.description,
          usage: mixed.usage,
          route: mixed.route,
          date: moment(mixed.date)
            .locale('id')
            .format('DD'),
          month: moment(mixed.date)
            .locale('id')
            .format('MMM'),
          year: moment(mixed.date)
            .locale('id')
            .format('YYYY'),
          staff: mixed.staff,
          expanded: false,
          fullDate: moment(mixed.date)
            .locale('id')
            .format('ll'),
        };
      });
      const nonRacik = this.historyDrug.recipe.map(nonMixed => {
        return {
          id: nonMixed._id,
          name: nonMixed.id_drugs.detail.map(recipe => {
            return {
              detail: `${recipe.name} ${recipe.strength} ${recipe.strength_unit} ${recipe.factory}`,
            };
          }),
          drugs: nonMixed.id_drugs,
          quantity: nonMixed.quantity,
          requested_quantity: nonMixed.requested_quantity,
          packaging: nonMixed.packaging,
          description: nonMixed.description,
          usage: nonMixed.usage,
          dosage: nonMixed.dosage,
          unit_dosage: nonMixed.unit_dosage,
          route: nonMixed.route,
          date: moment(nonMixed.date)
            .locale('id')
            .format('DD'),
          month: moment(nonMixed.date)
            .locale('id')
            .format('MMM'),
          year: moment(nonMixed.date)
            .locale('id')
            .format('YYYY'),
          staff: nonMixed.staff,
          expanded: false,
          fullDate: moment(nonMixed.date)
            .locale('id')
            .format('ll'),
        };
      });
      this.drug = racik.concat(nonRacik);
      this.date = groupBy(this.drug, 'fullDate');
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  text-align: left;
}

.body-container {
  margin-top: 10px;
  width: 100%;
  height: 55vh;
  display: flex;
  flex-direction: column;
}

.banner {
  margin: 0 auto;
  padding-bottom: 1rem;
  width: 100%;
  background-color: #f4f5f5;
  min-height: 330px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 3px;
    background-color: rgb(163, 163, 163);
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: black;
  }
}

.history-card {
  width: auto;
  margin-bottom: 1rem;
  background: #ffffff;
  border-radius: 10px;
  font-family: Roboto;
  font-style: normal;
  cursor: pointer;
  &.active {
    background: linear-gradient(180deg, #ded9ff 0%, #a8a6ff 100%);
    color: #000;
  }
}

.date {
  text-align: left;
  padding-top: 5px;
  padding-left: 25px;
}

.action-layout {
  display: flex;
  .action-footer {
    justify-content: end;
    align-items: end;
    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
    }
    .btn-create {
      margin: 0 20px;
      min-width: 145px;
      height: 37px;
      font-weight: 700;
      background-color: #3498db !important;
      color: white;
    }
    .btn-entry {
      border: 1px solid #3498db;
      color: #3498db;
      outline: cadetblue;
      border-radius: 5px;
      background-color: white !important;
    }
  }
}
.card-list {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1rem;
  padding: 1rem;
}
</style>
