import Swal from 'sweetalert2';
import moment from 'moment-timezone';
import Documents from '@/mixin/documents';
import PrintMixin from '@/mixin/printMixin';

import { createNamespacedHelpers } from 'vuex';
const {
  mapGetters: opGetters,
  mapActions: opActions,
  mapMutations: opMutations,
} = createNamespacedHelpers('OperationMarking');

const _ = require('lodash');

export default {
  mixins: [Documents, PrintMixin],
  data() {
    return {
      stateForm: 'add',
      idOMs: '',
      isHasIC: true,
      table: {
        headers: [
          {
            text: 'Tanggal',
            value: 'date',
            sortable: false,
          },
          {
            text: 'Tindakan yang Dilakukan',
            value: 'action',
            sortable: false,
          },
          {
            text: '',
            width: '10%',
            value: 'menu',
            sortable: false,
          },
        ],
      },
      actionItems: [
        {
          label: 'Lihat Detail',
          action: idOM => {
            this.idOMs = idOM;
            this.resolveGetOperationMarkingById(idOM);
            this.handleOpenDialog('edit');
          },
        },
        {
          label: 'Print Dokumen Penandaan Operasi',
          action: idOM => {
            this.handlePrint(idOM);
          },
        },
      ],
      sharedRef: '',
    };
  },
  computed: {
    ...opGetters([
      'getForm',
      'getItems',
      'getDialog',
      'getSnackbar',
      'getPatientData',
      'getInitialState',
    ]),
    dialog: {
      get() {
        return this.getDialog;
      },
    },
    patientData: {
      get() {
        return this.getPatientData;
      },
    },
    form: {
      get() {
        return this.getForm;
      },
    },
    initialState: {
      get() {
        return this.getInitialState;
      },
    },
    items: {
      get() {
        return this.getItems;
      },
    },
    snackbar: {
      get() {
        return this.getSnackbar;
      },
    },
    isMarkEmpty: {
      get() {
        return (
          this.form.mark_body.length === 0 &&
          this.form.mark_face_front.length === 0 &&
          this.form.mark_face_side.length === 0 &&
          this.form.mark_palm_upper.length === 0 &&
          this.form.mark_palm_lower.length === 0 &&
          this.form.mark_foot_upper.length === 0 &&
          this.form.mark_foot_lower.length === 0
        );
      },
    },
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
  },
  methods: {
    ...opMutations([
      'setMark',
      'setDialog',
      'clearForm',
      'setAutoStaff',
      'clearSnackbar',
      'setInitialState',
    ]),
    ...opActions([
      'resolveGetStaff',
      'resolvePutOperationMarking',
      'resolveGetOperationMarking',
      'resolveGetInformConsentList',
      'resolvePostOperationMarking',
      'resolveGetOperationMarkingById',
    ]),
    handleConvertDate(string) {
      return moment(string)?.format('DD-MM-YYYY');
    },
    handleOpenDialog(state) {
      this.resolveGetInformConsentList();
      this.setDialog(true);
      this.stateForm = state;
    },
    handleCloseDialog() {
      if (!_.isEqual(this.form, this.initialState)) {
        Swal.fire({
          title:
            '<div style="font-family: Roboto, Serif; color: #222222; font-weight: 700; font-size: 20px; line-height: 30px;"><p>Formulir Penandaan Operasi</p><p>Belum Tersimpan</p></div>',
          iconHtml: '<span class="mdi mdi-alert-circle-outline"></span>',
          html:
            '<div style="font-family: Roboto; font-style: normal; font-weight: 400; font-size: 14px; color: #616161; line-height: 150%;   text-transform: none !important;"><p>Data anda akan hilang. Kembali dan simpan</p><p>formulir terlebih dahulu</p></div>',
          showCancelButton: true,
          customClass: {
            confirmButton: 'button-error',
            cancelButton: 'button-confirm',
          },
          confirmButtonText: 'Keluar',
          cancelButtonText: 'Kembali',
        }).then(result => {
          if (result.isConfirmed) {
            this.setDialog(false);
            this.clearForm();
            this.clearSnackbar();
            this.markState = 'detail';
          }
        });
      } else {
        this.$emit('close-form');
        this.setDialog(false);
        this.clearForm();
        this.clearSnackbar();
        this.markState = 'detail';
      }
    },
    handleSetAutoStaff(input) {
      this.setAutoStaff(input.staff);
    },
    handleInputMark(event, label) {
      if (this.markState === 'add') {
        const stage = event.target.getStage();
        const position = stage.getPointerPosition();
        const data = {
          position,
        };
        this.form[label].push(data);
        this.setMark({
          label,
          payload: this.form[label],
        });
      }
    },
    handleRemoveMark(item, label) {
      if (this.markState === 'remove') {
        const index = this.form[label].findIndex(i => i === item);
        this.form[label].splice(index, 1);
        this.setMark({
          label,
          payload: this.form[label],
        });
      }
    },
    imageConfig(item) {
      return {
        x: item.position.x - 6,
        y: item.position.y - 6,
        image: this.backgroundMark.redCross,
      };
    },
    handleAddMark() {
      if (this.markState === 'remove' || this.markState === 'detail') {
        this.markState = 'add';
      } else if (this.markState === 'add') {
        this.markState = 'detail';
      }
    },
    handleDeleteMark() {
      if (this.markState === 'add' || this.markState === 'detail') {
        this.markState = 'remove';
      } else if (this.markState === 'remove') {
        this.markState = 'detail';
      }
    },
    handleSubmit(idOM) {
      if (this.$refs.form.validate()) {
        switch (this.state) {
          case 'add':
            this.resolvePostOperationMarking().finally(() => {
              this.resolveGetOperationMarking();
            });
            break;
          case 'edit':
            this.resolvePutOperationMarking(idOM).finally(() => {
              this.resolveGetOperationMarking();
            });
            break;
        }
      }
    },
    handlePrint(idOM) {
      const markRef = {
        mark_body:
          this.state === 'emr'
            ? this.$refs.mark_body
            : this.$refs.formOperationMarking.$refs.mark_body,
        mark_face_front:
          this.state === 'emr'
            ? this.$refs.mark_face_front
            : this.$refs.formOperationMarking.$refs.mark_face_front,
        mark_face_side:
          this.state === 'emr'
            ? this.$refs.mark_face_side
            : this.$refs.formOperationMarking.$refs.mark_face_side,
        mark_foot_lower:
          this.state === 'emr'
            ? this.$refs.mark_foot_lower
            : this.$refs.formOperationMarking.$refs.mark_foot_lower,
        mark_foot_upper:
          this.state === 'emr'
            ? this.$refs.mark_foot_upper
            : this.$refs.formOperationMarking.$refs.mark_foot_upper,
        mark_palm_lower:
          this.state === 'emr'
            ? this.$refs.mark_palm_lower
            : this.$refs.formOperationMarking.$refs.mark_palm_lower,
        mark_palm_upper:
          this.state === 'emr'
            ? this.$refs.mark_palm_upper
            : this.$refs.formOperationMarking.$refs.mark_palm_upper,
      };
      if (idOM) {
        this.resolveGetOperationMarkingById(idOM)
          .then(item => {
            const doc = this.generateOperationMark(
              item,
              this.patientData,
              markRef,
            );
            this.printDocument(doc, {
              docName: 'Penandaan Operasi atau Tindakan Medis',
              rmNumber: this.patientData.noRM,
            });
          })
          .finally(() => this.clearForm());
      } else if (this.state === 'emr') {
        const doc = this.generateOperationMark(
          this.form,
          this.patientData,
          markRef,
        );
        this.printDocument(doc, {
          docName: 'Penandaan Operasi atau Tindakan Medis',
          rmNumber: this.patientData.noRM,
        });
      } else {
        const doc = this.generateOperationMark(
          undefined,
          this.patientData,
          markRef,
        );
        this.printDocument(doc, {
          docName: 'Penandaan Operasi atau Tindakan Medis',
          rmNumber: this.patientData.noRM,
        });
      }
    },
  },
};
