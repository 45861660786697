<template>
  <div>
    <v-layout>
      <v-flex>
        <v-layout align-center pa-3>
          <v-flex xs6 class="title-head">
            <h2>Laporan Kas Masuk dan Keluar</h2>
            <h5 class="title-head__sub">Update: {{ timestamp }}</h5>
          </v-flex>
          <v-flex>
            <v-btn icon>
              <router-link to="/keuangan/laporan-keuangan">
                <font-awesome-icon icon="home" class="btn-refresh" />
              </router-link>
            </v-btn>
          </v-flex>
          <v-flex>
            <v-btn icon @click="getAllCash()">
              <font-awesome-icon
                icon="sync-alt"
                class="btn-refresh"
                :class="{ loading: refreshLoad }"
              />
            </v-btn>
          </v-flex>
          <v-flex xs2 ml-3>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="startDate"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startDate"
                  label="Pilih tanggal awal"
                  v-bind="attrs"
                  v-on="on"
                  prepend-inner-icon="mdi-calendar-month"
                  outlined
                  dense
                  hide-details="false"
                  clearable
                  @click:clear="getAllCash()"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="startDate"
                no-title
                scrollable
                @input="
                  $refs.menu.save(startDate);
                  menu = false;
                  getAllCash();
                "
              />
            </v-menu>
          </v-flex>
          <v-flex xs2 mx-3>
            <v-menu
              ref="menuStop"
              v-model="menuStop"
              :close-on-content-click="false"
              :return-value.sync="endDate"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="endDate"
                  label="Pilih tanggal akhir"
                  v-bind="attrs"
                  v-on="on"
                  prepend-inner-icon="mdi-calendar-month"
                  outlined
                  dense
                  hide-details="false"
                  clearable
                  @click:clear="getAllCash()"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="endDate"
                no-title
                scrollable
                @input="
                  $refs.menuStop.save(endDate);
                  menuStop = false;
                  getAllCash();
                "
              />
            </v-menu>
          </v-flex>
          <v-flex xs1>
            <v-btn tile color="primary" depressed class="text-none"
              >Export</v-btn
            >
          </v-flex>
        </v-layout>
        <v-layout>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-flex pl-10>
                  <label>Kas Masuk</label>
                </v-flex>
                <v-flex class="text-center">
                  <label>Rp {{ formatNumber(cashData.totalDebit) }}</label>
                </v-flex>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout py-3 justify-center v-if="cashData === null">
                  <h3>Tidak ada data</h3>
                </v-layout>
                <template v-for="(debit, index) in cashData.debit" v-else>
                  <v-layout class="text-left pt-3" :key="'debit-' + index">
                    <v-flex pl-16 xs6>{{ debit.name }}</v-flex>
                    <v-flex xs2 class="text-right"
                      >Rp {{ formatNumber(debit.debit) }}</v-flex
                    >
                  </v-layout>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-layout>
        <v-layout class="pt-3">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-flex pl-10>
                  <label>Kas Keluar</label>
                </v-flex>
                <v-flex class="text-center">
                  <label>Rp {{ formatNumber(cashData.totalCredit) }}</label>
                </v-flex>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <template v-for="(credit, idx) in cashData.credit">
                  <v-layout class="text-left pt-3" :key="'credit-' + idx">
                    <v-flex pl-16 xs6>{{ credit.name }}</v-flex>
                    <v-flex xs2 class="text-right"
                      >Rp {{ formatNumber(credit.kredit) }}</v-flex
                    >
                  </v-layout>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-layout>
        <v-layout pt-3 ml-6 mr-6>
          <v-flex>
            <v-layout pt-3>
              <v-flex class="text-left">
                <label class="label-kas">Penambahan (Pengurangan) Kas</label>
              </v-flex>
              <v-flex class="label-kas text-right"
                >Rp {{ formatNumber(cashData.change) }}</v-flex
              >
            </v-layout>
            <v-layout pt-6>
              <v-flex class="text-left">
                <label class="label-kas">Saldo Kas Awal</label>
              </v-flex>
              <v-flex class="label-kas text-right"
                >Rp {{ formatNumber(cashData.beginningBalance) }}</v-flex
              >
            </v-layout>
            <v-layout pt-6>
              <v-flex class="text-left">
                <label class="label-kas">Saldo Kas Akhir</label>
              </v-flex>
              <v-flex class="label-kas text-right"
                >Rp {{ formatNumber(cashData.endBalance) }}</v-flex
              >
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import Constant from '@/const';
import jwtMixin from '@/mixin/jwtMixin';

const axios = require('axios');

export default {
  name: 'CashReport',
  mixins: [jwtMixin],
  data() {
    return {
      timestamp: '',
      cashData: null,
      startDate: '',
      endDate: '',
    };
  },
  created() {
    setInterval(this.getNow, 500);
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshAndAutoUpdate();
    });
  },
  methods: {
    getNow() {
      const dayNames = [
        'Minggu',
        'Senin',
        'Selasa',
        'Rabu',
        'Kamis',
        'Jumat',
        'Sabtu',
      ];
      const monthNames = [
        'Januari',
        'Februari',
        'Maret',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Agustus',
        'September',
        'Oktober',
        'November',
        'Desember',
      ];
      const today = new Date();
      const date =
        dayNames[today.getDay()] +
        ', ' +
        today.getDate() +
        ' ' +
        monthNames[today.getMonth()] +
        ' ' +
        today.getFullYear();
      this.timestamp = date;
    },
    async getAllCash() {
      await this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/finance/daily?startDate=${
                this.startDate === null ? '' : this.startDate
              }&endDate=${this.endDate === null ? '' : this.endDate}`,
            ),
          )
          .then(response => {
            const cash = response.data.data;
            this.cashData = {
              beginningBalance: cash.totalOut,
              endBalance: cash.totalIn,
              change: cash.total,
              debit: cash.daily_report_debit,
              credit: cash.daily_report_kredit,
            };

            this.cashData.totalDebit = Object.values(
              this.cashData.debit,
            ).reduce((a, { debit }) => a + debit, 0);
            this.cashData.totalCredit = Object.values(
              this.cashData.credit,
            ).reduce((a, { kredit }) => a + kredit, 0);
          })
          .catch(() => {
            this.cashData = null;
          });
      });
    },
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.getAllCash();
      this.interval = setInterval(this.getAllCash, 60000);
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.title-head {
  text-align: left;
  &__sub {
    color: #828282;
  }
}
::v-deep .v-item-group.v-expansion-panels {
  z-index: inherit;
}
::v-deep .v-expansion-panel-header {
  font-weight: 700;
  border: 1px solid #969696;
}
::v-deep .v-expansion-panel-header--active {
  background-color: #6eafdb;
  color: white;
  font-weight: 700;
  border: none;
}
.label-kas {
  font-weight: 700;
  font-size: 16px;
}
</style>
