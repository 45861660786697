<template>
  <v-form ref="form" lazy-validation>
    <v-row class="px-15 mb-3">
      <v-col class="grid">
        <label class="required">Waktu Pelaksanaan</label>
        <div class="d-flex">
          <v-text-field
            dense
            readonly
            class="mr-2"
            v-model="timeOut.time.date"
          />
          <v-text-field
            dense
            @input="formatTime(timeOut.time.time)"
            :readonly="isDetail || !isEditable"
            :rules="[...rules, ...timeRule]"
            v-model="timeOut.time.time"
            class="ml-2"
            placeholder="00:00"
          />
        </div>
        <label class="required">Petugas yang Mengisi</label>
        <v-combobox
          dense
          single-line
          :rules="rules"
          :clearable="isEditable"
          :readonly="!isEditable"
          :items="resource.doctor.concat(resource.staff)"
          item-text="name"
          append-icon="mdi-chevron-down"
          placeholder="Petugas yang Mengisi"
          v-model="timeOut.staffFill"
        />
      </v-col>
      <v-col class="grid">
        <label class="required">Dokter Pelaksana Tindakan</label>
        <v-combobox
          dense
          single-line
          :clearable="isEditable"
          :readonly="!isEditable"
          :rules="rules"
          :items="resource.doctor"
          item-text="name"
          append-icon="mdi-chevron-down"
          placeholder="Dokter Pelaksana Tindakan"
          v-model="timeOut.doctor"
        />
        <label class="required">Petugas Pendamping</label>
        <v-combobox
          dense
          single-line
          :clearable="isEditable"
          :readonly="!isEditable"
          :rules="rules"
          :items="resource.staff"
          v-model="timeOut.staff"
          item-text="name"
          append-icon="mdi-chevron-down"
          placeholder="Petugas Pendamping"
        />
      </v-col>
    </v-row>
    <div class="px-15 mb-5">
      <v-divider></v-divider>
    </div>
    <v-row class="px-15 mb-3">
      <v-col class="grid">
        <label class="required pr-15"
          >Konfirmasi semua anggota tim, memperkenalkan nama dan tugasnya</label
        >
        <v-radio-group
          :readonly="!isEditable"
          :rules="rules"
          row
          v-model="timeOut.introduceTeamMemberConfirmation"
        >
          <v-radio label="Ya" :value="true"></v-radio>
          <v-radio label="Tidak" :value="false"></v-radio>
        </v-radio-group>
        <label class="required pr-15"
          >Konfirmasi nama pasien, prosedur, dan dimana insisi akan
          dilakukan</label
        >
        <v-radio-group
          :readonly="!isEditable"
          :rules="rules"
          row
          v-model="timeOut.incisionDataConfirmation"
        >
          <v-radio label="Ya" :value="true"></v-radio>
          <v-radio label="Tidak" :value="false"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col class="grid">
        <label class="required pr-15"
          >Apakah antibiotik profilaksis sudah diberikan 60 menit
          sebelumnya?</label
        >
        <v-radio-group
          :readonly="!isEditable"
          :rules="rules"
          row
          v-model="timeOut.isAntibioticProphylaxisHasGiven"
        >
          <v-radio label="Ya" :value="true"></v-radio>
          <v-radio label="Tidak" :value="false"></v-radio> </v-radio-group
      ></v-col>
    </v-row>
    <div class="px-15 mb-5">
      <v-divider></v-divider>
    </div>
    <h4 class="grey--text pl-15 mb-3 text-start font-weight-regular">
      MENGANTISIPASI KEJADIAN KRITIS
    </h4>
    <v-row class="px-15">
      <v-col class="grid">
        <div class="d-flex align-center">
          <v-checkbox
            :readonly="!isEditable"
            v-model="timeOut.criticalStep.check"
          />
          <label class="pr-15"
            >Bagaimana langkah menghadapi keadaan kritis atau kejadian luar
            biasa?</label
          >
        </div>
        <v-textarea
          no-resize
          placeholder="Berikan penjelasan"
          dense
          light
          outlined
          counter="200"
          :readonly="!isEditable"
          v-model="timeOut.criticalStep.description"
          :rules="textarea"
        />
        <div class="d-flex align-center">
          <v-checkbox
            :readonly="!isEditable"
            v-model="timeOut.handlingDuration.check"
          />
          <label class="pr-15"
            >Berapa lama untuk menangani kejadian tersebut?</label
          >
        </div>
        <v-text-field
          v-model="timeOut.handlingDuration.description"
          :readonly="!isEditable"
          placeholder="Contoh: 20 menit"
          dense
          light
          hide-details
        />
        <div class="d-flex align-center">
          <v-checkbox
            :readonly="!isEditable"
            v-model="timeOut.bleedingHandleDuration.check"
          />
          <label class="pr-15"
            >Bagaimana langkah untuk mengatasi pendarahan yang terjadi?</label
          >
        </div>
        <v-textarea
          no-resize
          placeholder="Berikan penjelasan"
          dense
          light
          counter="200"
          outlined
          :readonly="!isEditable"
          v-model="timeOut.bleedingHandleDuration.description"
          :rules="textarea"
        />
      </v-col>
      <v-col class="grid" style="height: 10vw">
        <label class="pr-15">Apakah pasien membutuhkan perhatian khusus?</label>
        <v-radio-group
          :readonly="!isEditable"
          row
          v-model="timeOut.isPatientNeedSpecialAttention"
        >
          <v-radio label="Ya" :value="true"></v-radio>
          <v-radio label="Tidak" :value="false"></v-radio>
        </v-radio-group>
        <label class="pr-15"
          >Apakah sterilisasi (termasuk indikator hasil) sudah
          dikonfirmasi?</label
        >
        <v-radio-group
          :readonly="!isEditable"
          row
          v-model="timeOut.isSterilizationConfirmed"
        >
          <v-radio label="Sudah" :value="true"></v-radio>
          <v-radio label="Belum" :value="false"></v-radio>
        </v-radio-group>
        <label class="pr-15"
          >Apakah ada permasalahan pada alat atau ada yang perlu
          diperhatikan</label
        >
        <v-radio-group
          :readonly="!isEditable"
          row
          v-model="timeOut.isProblematicTool"
        >
          <v-radio label="Ya" :value="true"></v-radio>
          <v-radio label="Tidak" :value="false"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('SurgicalSafetyChecklist');

const moment = require('moment-timezone');
export default {
  name: 'TimeOutPhase',
  data() {
    return {
      rules: [v => !!v || v === false || 'Wajib diisi'],
      textarea: [v => v.length <= 200 || 'Melebihi 200 karakter'],
      timeRule: [
        v => this.checkTimeFormat(v) || 'Waktu Tidak Valid',
        v => this.isBeforeSignIn(v),
      ],
    };
  },
  computed: {
    ...mapGetters([
      'getSignIn',
      'getTimeOut',
      'getResource',
      'getIsEmr',
      'getIsEdit',
      'getIsDetail',
    ]),
    isEditable: {
      get() {
        if (this.isEmr) {
          return false;
        } else if (!this.isDetail) {
          return true;
        } else {
          return this.isEdit;
        }
      },
    },
    isEmr: {
      get() {
        return this.getIsEmr;
      },
    },
    timeOut: {
      get() {
        return this.getTimeOut;
      },
    },
    signIn: {
      get() {
        return this.getSignIn;
      },
    },
    resource: {
      get() {
        return this.getResource;
      },
    },
    isEdit: {
      get() {
        return this.getIsEdit;
      },
    },
    isDetail: {
      get() {
        return this.getIsDetail;
      },
    },
  },
  methods: {
    checkTimeFormat(time) {
      if (time.length !== 5) return false;
      const timeInput = moment(time, 'HH:mm');
      return timeInput.isValid();
    },
    isBeforeSignIn(time) {
      return (
        moment(time, 'HH:mm').isAfter(moment(this.signIn.time.time, 'HH:mm')) ||
        'Tidak boleh sebelum waktu fase Sign In'
      );
    },
    insertString(originalString, newString, index) {
      return (
        originalString.substr(0, index) +
        newString +
        originalString.substr(index)
      );
    },
    formatTime(timeString) {
      if (timeString.length > 5) {
        this.timeOut.time.time = timeString.slice(0, 5);
        return;
      }
      let time = timeString.replace(/\D/g, '').substr(0, 4);
      let size = time.length;

      if (size > 2) time = this.insertString(time, ':', 2);

      this.timeOut.time.time = time;
    },
  },
};
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  grid-template-columns: 40% 50%;
  row-gap: 3dvh;
  text-align: start;
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}
.required:after {
  content: ' *';
  color: red;
}
</style>
