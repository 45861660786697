<template>
  <v-card>
    <v-card-title class="justify-center">
      <h3>Ubah Diagnosa</h3>
      <v-btn absolute right @click="onCloseButtonClick()" small text>
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row no-gutters justify="center">
          <v-col md="5">
            <v-form lazy-validation ref="form">
              <div class="detail-input">
                <div class="detail-input__label">
                  <label for="name">Kode Diagnosa</label>
                </div>

                <v-text-field
                  v-model="diagnose.code"
                  placeholder="Masukkan Kode Diagnosa"
                  class="detail-input__input-text"
                  required
                  :rules="[v => !!v || 'Wajib diisi']"
                />
              </div>
              <div class="detail-input">
                <div class="detail-input__label">
                  <label for="name">Deskripsi Diagnosa</label>
                </div>

                <v-textarea
                  v-model="diagnose.description"
                  placeholder="Tambahkan Deskripsi Diagnosa"
                  class="detail-input__input-text"
                  name="deskripsidiagnosa"
                  outlined
                  height="200"
                  required
                  :rules="[v => !!v || 'Wajib diisi']"
                ></v-textarea>
              </div>
            </v-form>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="text-none"
                color="secondary"
                text
                @click="onCloseButtonClick()"
              >
                Kembali
              </v-btn>
              <v-btn
                class="text-none"
                depressed
                color="primary"
                @click="
                  changeDiagnose();
                  updateData();
                "
              >
                Simpan
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import Constant from '@/const';
const axios = require('axios');

export default {
  props: ['diagnoseDetail'],
  data() {
    return {
      diagnose: {
        _id: this.diagnoseDetail.id,
        code: this.diagnoseDetail.code,
        description: this.diagnoseDetail.description,
        severity: null,
        inpatient: [],
        outpatient: [],
      },
    };
  },
  methods: {
    onCloseButtonClick() {
      this.$emit('close-dialog');
    },
    updateData() {
      this.$emit('update-data');
    },

    changeDiagnose() {
      if (this.$refs.form.validate()) {
        axios
          .put(`${Constant.apiUrl}/master/diagnose/${this.diagnoseDetail.id}`, {
            code: this.diagnose.code,
            description: this.diagnose.description,
            severity: this.diagnose.severity,
            inpatient: this.diagnose.inpatient,
            outpatient: this.diagnose.outpatient,
          })
          .then(response => {
            if (response.status === 200) {
              Swal.fire('Berhasil', 'Data barang berhasil diubah', 'success');
              this.onCloseButtonClick();
            }
          })
          .catch(error => {
            if (error.response) {
              const message = error.response.data.pesan;
              Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: message,
              });
            } else if (error.request) {
              Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: 'Tidak dapat menyambung ke server',
              });
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.column {
  margin: 0 auto;
}

.detail-radio {
  display: flex;
  justify-content: flex-end;
}

.detail-input {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  &__label {
    justify-content: flex-end;
    flex: 1;
    display: flex;
    color: #222;
  }

  &__input-text {
    margin-left: 10px;
    width: 150px;
  }
}

.padding {
  padding-left: 10px;
}

.radio {
  margin: 0;
}

.table-kemasan {
  width: 100%;
  margin-top: 20px;
}
</style>
