<template>
  <div>
    <v-toolbar flat outlined style="height: 80px;">
      <v-container fluid>
        <v-row align="center">
          <v-col style="display: grid;" class="text-left">
            <v-toolbar-title class="font-weight-bold float-left">
              Rekapitulasi Piutang
            </v-toolbar-title>
            <span class="caption"
              >{{ voidCount }} transaksi void, {{ openCount }} transaksi
              open</span
            >
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="3">
            <v-text-field
              class="input"
              background-color="grey lighten-3"
              placeholder="Search"
              solo
              dense
              hide-details
              flat
              prepend-inner-icon="mdi-magnify"
              single-line
              v-model="search"
              @input="getAllData(search)"
            ></v-text-field>
          </v-col>
          <v-btn @click="show = !show" text>
            <span class="text-capitalize">Filters</span>
            <v-icon>mdi-filter-variant</v-icon>
          </v-btn>
          <router-link to="/keuangan/laporan-keuangan">
            <v-btn class="mr-2" color="grey" icon text>
              <v-icon>mdi-home</v-icon>
            </v-btn>
          </router-link>
          <v-btn color="primary" dark depressed tile class="text-none">
            Export
          </v-btn>
        </v-row>
      </v-container>
    </v-toolbar>
    <v-main>
      <v-container fluid>
        <v-row>
          <v-spacer></v-spacer>
          <div class="filter-menu mt-4" v-show="show">
            <v-row no-gutters align="center" justify="center">
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                :return-value.sync="startDate"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    dense
                    placeholder="Pilih Tanggal Awal"
                    class="input mr-4"
                    v-model="startDate"
                    label="Tanggal Awal"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hide-details="false"
                    clearable
                    @click:clear="
                      startDate = '';
                      getAllData();
                    "
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  no-title
                  scrollable
                  @input="
                    $refs.menu1.save(startDate);
                    menu1 = false;
                    getAllData('');
                  "
                />
              </v-menu>

              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="endDate"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    dense
                    placeholder="Pilih Tanggal Akhir"
                    class="input mr-4"
                    v-model="endDate"
                    label="Tanggal Akhir"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hide-details="false"
                    clearable
                    @click:clear="
                      endDate = '';
                      getAllData('');
                    "
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  no-title
                  scrollable
                  @input="
                    $refs.menu2.save(endDate);
                    menu2 = false;
                    getAllData('');
                  "
                />
              </v-menu>
            </v-row>
          </div>
        </v-row>
      </v-container>
      <v-container fluid>
        <v-data-table
          :headers="table.headers"
          :items="table.items"
          :items-per-page="5"
          hide-default-footer
          :loading="isLoading"
        >
          <template v-slot:[`item.no`]="{ item }">
            {{ table.items.indexOf(item) + 1 }}
          </template>
          <template v-slot:[`item.totalPrice`]="{ item }">
            Rp {{ formatNumber(item.totalPrice) }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip class="chip" label :color="getColor(item.status)" dark>
              {{ item.status }}
            </v-chip>
          </template>
          <template v-slot:[`body.append`]>
            <tr class="total-row">
              <td :colspan="1"></td>
              <td class="text-left font-weight-bold" :colspan="1">
                Jumlah
              </td>
              <td :colspan="3" class="text-left font-weight-bold">
                Rp {{ formatNumber(total) }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import Constant from '@/const';
import jwtMixin from '@/mixin/jwtMixin';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'ReceivableReport',
  mixins: [jwtMixin],
  data() {
    return {
      show: false,
      startDate: '',
      endDate: '',
      menu1: false,
      menu2: false,
      table: {
        headers: [
          {
            text: 'No',
            align: 'start',
            sortable: false,
            value: 'no',
          },
          { text: 'Nama Pasien', value: 'patientName' },
          { text: 'Total Biaya Tagian', value: 'totalPrice' },
          { text: 'Tipe Layanan', value: 'serviceType' },
          { text: 'Status', value: 'status' },
        ],
        items: [],
      },
      // total: null,
      search: '',
      voidCount: 0,
      openCount: 0,
      isLoading: false,
    };
  },
  computed: {
    total() {
      return this.formatNumber(
        this.table.items.reduce((a, { totalPrice }) => a + totalPrice, 0),
      );
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshAndAutoUpdate('');
    });
  },
  methods: {
    getColor(status) {
      if (status === 'Void') return 'red';
      else return 'green';
    },
    getAllData(keyword) {
      this.isLoading = true;

      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/billing/receivable/?startDate=${
                this.startDate === null ? '' : this.startDate
              }&endDate=${this.endDate === null ? '' : this.endDate}&search=${
                keyword === undefined ? '' : keyword
              }`,
            ),
          )
          .then(response => {
            const piutang = response.data.data;
            this.table.items = piutang.map(arr => {
              return {
                patientName: arr.patient_name,
                totalPrice: arr.remaining_bill,
                serviceType: arr.type,
                status: _.startCase(arr.status),
              };
            });
            this.voidCount = this.table.items.filter(
              x => x.status === 'Void',
            ).length;
            this.openCount = this.table.items.filter(
              x => x.status === 'Open',
            ).length;

            this.isLoading = false;
          })
          .catch(() => {
            this.table.items = [];
            this.isLoading = false;
          });
      });
    },
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.getAllData();
      this.interval = setInterval(this.getAllData, 60000);
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-toolbar__content,
.v-toolbar__extension {
  height: 80px !important;
}
</style>
