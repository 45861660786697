<template>
  <v-card class="input-container">
    <v-card-title>
      <h3>Pendaftaran Pasien</h3>
    </v-card-title>
    <v-card-text class="pb-1">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row class="form-container">
          <v-row class="pa-5" no-gutters>
            <v-col cols="5" offset="1">
              <v-row>
                <v-col cols="4" class="black--text text-left font-weight-bold">
                  <label for="name">No. RM</label>
                </v-col>
                <v-col class="text-left pl-0">
                  <label class="text-left black--text">
                    {{
                      selectedPatient === null ||
                      selectedPatient.status === 'igd-new'
                        ? rmNumber
                        : selectedPatient.rmNumber
                    }}
                  </label>
                </v-col>
              </v-row>
              <v-row align="baseline">
                <v-col
                  cols="4"
                  class="required black--text text-left font-weight-bold pr-0"
                >
                  <label for="name">Nama Lengkap</label>
                </v-col>
                <v-col cols="6" class="pl-0">
                  <v-tooltip
                    color="primary"
                    dark
                    v-model="isBPJSMinorlyUnmatched"
                    top
                  >
                    <template v-slot:activator>
                      <v-text-field
                        id="name"
                        dense
                        placeholder="Masukkan Nama Pasien"
                        v-model="patient.name"
                        :rules="
                          patient.assurance.type === 'bpjs'
                            ? rules.nameBPJS
                            : rules.name
                        "
                        @blur="checkUnmatched()"
                        required
                      ></v-text-field>
                    </template>
                    <span>{{ this.patient?.bpjsData?.name }} </span></v-tooltip
                  >
                </v-col>
              </v-row>
              <v-row align="baseline">
                <v-col
                  cols="4"
                  class="required black--text text-left font-weight-bold"
                >
                  <label for="status">Status Pasien</label>
                </v-col>
                <v-col cols="6" class="pl-0">
                  <v-select
                    dense
                    placeholder="Pilih Status"
                    id="status"
                    :items="input.status"
                    v-model="patient.nickname"
                    :rules="rules.status"
                    required
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="4"
                  class="required black--text text-left font-weight-bold"
                >
                  <label for="name">Jenis Kelamin</label>
                </v-col>
                <v-col class="pl-0">
                  <v-radio-group
                    required
                    row
                    v-model="patient.gender"
                    :rules="rules.gender"
                    class="mt-0 pt-0"
                    id="gender"
                  >
                    <v-radio label="Laki-laki" :value="true"></v-radio>
                    <v-radio label="Perempuan" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-row align="baseline">
                <v-col
                  cols="4"
                  class="required black--text text-left font-weight-bold font-weight-bold"
                >
                  <label for="name">Tempat Lahir</label>
                </v-col>
                <v-col cols="6" class="pl-0">
                  <v-text-field
                    dense
                    placeholder="Masukkan Tempat Lahir"
                    class="mt-0"
                    id="birthplace"
                    v-model="patient.birth.place"
                    :rules="rules.birthPlace"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row align="baseline">
                <v-col
                  cols="4"
                  class="required black--text text-left font-weight-bold"
                >
                  <label for="status">Tanggal Lahir</label>
                </v-col>
                <v-col cols="6" class="pl-0">
                  <v-text-field
                    @input="formatDate(patient.birth.date)"
                    v-model="patient.birth.date"
                    @blur="checkUnmatched()"
                    placeholder="dd/mm/yyyy"
                    id="birthdate"
                    class="mt-0 pt-0"
                    maxlength="10"
                    :rules="rules.birthDate"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row align="baseline">
                <v-col cols="4" class="black--text text-left font-weight-bold">
                  <label for="status">Usia</label>
                </v-col>
                <v-col cols="6" class="pl-0">
                  <v-text-field
                    v-model="patient.age"
                    readonly
                    class="mt-0 pt-0"
                    maxlength="10"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" class="pl-10">
              <v-row align="baseline">
                <v-col
                  cols="4"
                  class="black--text text-left font-weight-bold pr-0"
                >
                  <label for="status">Jenis Identitas</label>
                </v-col>
                <v-col cols="6" class="pl-0">
                  <v-select
                    @blur="checkUnmatched()"
                    dense
                    placeholder="Pilih Identitas"
                    :items="input.identityType"
                    v-model="patient.identity.type"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row align="baseline">
                <v-col cols="4" class="black--text text-left font-weight-bold">
                  <label for="name">No. Identitas</label>
                </v-col>
                <v-col cols="6" class="pl-0">
                  <v-text-field
                    dense
                    @blur="checkUnmatched()"
                    placeholder="Masukkan Nomor Identitas"
                    type="number"
                    v-model="patient.identity.number"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="baseline">
                <v-col
                  cols="4"
                  class="required black--text text-left font-weight-bold pr-0"
                >
                  <label for="name">Jenis Jaminan</label>
                </v-col>
                <v-col>
                  <v-radio-group
                    v-model="patient.assurance.type"
                    id="assurance"
                    row
                    class="mt-0 pt-0"
                    :rules="rules.guarantorType"
                    required
                  >
                    <v-radio label="BPJS" value="bpjs"></v-radio>
                    <v-radio label="Asuransi" value="asuransi"></v-radio>
                    <v-radio label="Umum" value="umum"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-row v-if="patient.assurance.type === 'bpjs'" align="baseline">
                <v-col
                  cols="4"
                  class="required black--text text-left font-weight-bold pr-0"
                >
                  <label for="name">Status Pendaftaran</label>
                </v-col>
                <v-col cols="6">
                  <v-radio-group
                    v-model="patient.assurance.referBack"
                    row
                    class="mt-0 pt-0"
                    required
                  >
                    <v-radio label="Baru" :value="false"></v-radio>
                    <v-radio label="Rujuk Balik" :value="true"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-row v-if="patient.assurance.type === 'bpjs'" align="baseline">
                <v-col
                  cols="4"
                  class="required black--text text-left font-weight-bold pr-0"
                >
                  <label for="name">Cek Status Berdasarkan</label>
                </v-col>
                <v-col cols="6">
                  <v-radio-group
                    v-model="patient.assurance.isBpjs"
                    row
                    class="mt-0 pt-0"
                    required
                  >
                    <v-radio label="No BPJS" :value="true"></v-radio>
                    <v-radio label="NIK" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-form ref="noNik" v-model="nikValid" lazy-valiadation>
                <v-row
                  v-if="
                    !patient.assurance.isBpjs &&
                      patient.assurance.type === 'bpjs'
                  "
                  align="baseline"
                >
                  <v-col
                    cols="4"
                    class="required black--text text-left font-weight-bold"
                  >
                    <label>Nomor NIK</label>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      placeholder="Masukkan Nomor NIK"
                      v-model="patient.identity.number"
                      class="mt-0"
                      :error-messages="nik_error"
                      required
                      type="number"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>

              <v-form ref="noBpjs" v-model="bpjsValid" lazy-valiadation>
                <v-row
                  v-if="patient.assurance.type === 'bpjs'"
                  align="baseline"
                >
                  <v-col
                    cols="4"
                    class="required black--text text-left font-weight-bold"
                  >
                    <label>Nomor BPJS</label>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      placeholder="Masukkan Nomor BPJS"
                      type="number"
                      v-model="patient.assurance.number"
                      class="mt-0"
                      required
                      :error-messages="bpjs_error"
                      :append-icon="
                        checkNo.bpjs ? 'mdi-check-circle' : undefined
                      "
                      :readonly="!patient.assurance.isBpjs"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-form>

              <v-row v-if="patient.assurance.type === 'bpjs'" align="baseline">
                <v-col cols="4" class="black--text text-left font-weight-bold">
                  <label>Nama Provider</label>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    dense
                    class="mt-0"
                    v-model="patient.assurance.provider"
                    readonly
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row v-if="patient.assurance.type == 'bpjs'" align="baseline">
                <v-col
                  cols="4"
                  class="required black--text text-left font-weight-bold"
                >
                  <label for="name">PROLANIS</label>
                </v-col>
                <v-col cols="6">
                  <v-radio-group
                    v-model="patient.assurance.isProlanis"
                    row
                    class="mt-0 pt-0"
                    hide-details="false"
                  >
                    <v-radio label="Ya" :value="true"></v-radio>
                    <v-radio label="Bukan" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row
                v-if="patient.assurance.type === 'asuransi'"
                align="baseline"
              >
                <v-col
                  cols="4"
                  class="black--text text-left font-weight-bold pr-0"
                >
                  <label>Nama Asuransi</label>
                </v-col>
                <v-col cols="6">
                  <v-select
                    dense
                    placeholder="Pilih Nama Asuransi"
                    class="mt-0 pt-0"
                    v-model="patient.assurance.name"
                    :items="assurance"
                    return-object
                    :item-text="assurance.name"
                    hide-details="false"
                  ></v-select>
                </v-col>
                <v-col
                  cols="4"
                  class="black--text text-left font-weight-bold pr-0"
                >
                  <label for="name">Nomor Polis Asuransi</label>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    dense
                    placeholder="Masukkan Nomor Polis Asuransi"
                    class="mt-0"
                    v-model="patient.assurance.number"
                    hide-details="false"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <div class="divider-line"></div>
          <v-row class="pa-5" no-gutters>
            <v-col cols="5" offset="1">
              <v-row>
                <v-col
                  cols="4"
                  class="required black--text text-left font-weight-bold"
                >
                  <label for="name">Alamat</label>
                </v-col>
                <v-col class="pl-0">
                  <v-textarea
                    outlined
                    id="address"
                    name="input-7-4"
                    placeholder="Masukkan Alamat"
                    v-model="patient.address.text"
                    :rules="rules.address"
                    required
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row align="baseline">
                <v-col cols="4" class="black--text text-left font-weight-bold">
                  <label for="status">Provinsi</label>
                </v-col>
                <v-col cols="6" class="pl-0">
                  <v-autocomplete
                    v-model="patient.address.province"
                    :items="input.province"
                    @input="getCity"
                    dense
                    clearable
                    item-text="name"
                    placeholder="Pilih Provinsi"
                    return-object
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row align="baseline">
                <v-col cols="4" class="black--text text-left font-weight-bold">
                  <label for="status">Kabupaten</label>
                </v-col>
                <v-col cols="6" class="pl-0">
                  <v-autocomplete
                    v-model="patient.address.city"
                    :items="input.city"
                    :loading="isLoading"
                    @change="getDistrict"
                    dense
                    clearable
                    item-text="name"
                    placeholder="Pilih Kabupaten"
                    return-object
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row align="baseline">
                <v-col cols="4" class="black--text text-left font-weight-bold">
                  <label for="status">Kecamatan</label>
                </v-col>
                <v-col cols="6" class="pl-0">
                  <v-autocomplete
                    v-model="patient.address.district"
                    :items="input.district"
                    :loading="isLoading"
                    @change="getSubDistrict"
                    dense
                    clearable
                    item-text="name"
                    placeholder="Pilih Kecamatan"
                    return-object
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row align="baseline">
                <v-col cols="4" class="black--text text-left font-weight-bold">
                  <label for="status">Kelurahan</label>
                </v-col>
                <v-col cols="6" class="pl-0">
                  <v-autocomplete
                    v-model="patient.address.sub_district"
                    :items="input.sub_district"
                    :loading="isLoading"
                    dense
                    clearable
                    item-text="name"
                    placeholder="Pilih Kelurahan"
                    return-object
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row align="baseline">
                <v-col cols="4" class="black--text text-left font-weight-bold">
                  <label for="status">No.Telepon</label>
                </v-col>
                <v-col cols="6" class="pl-0">
                  <v-text-field
                    v-model="patient.phone_number"
                    dense
                    type="number"
                    placeholder="Masukkan Nomor Telepon"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="baseline">
                <v-col cols="4" class="black--text text-left font-weight-bold">
                  <label for="status">Agama</label>
                </v-col>
                <v-col cols="6">
                  <v-select
                    dense
                    placeholder="Pilih Agama"
                    class="mt-0"
                    :items="input.religion"
                    v-model="patient.religion"
                    required
                  ></v-select>
                </v-col>
              </v-row>

              <v-row align="baseline">
                <v-col
                  cols="4"
                  class="black--text text-left font-weight-bold pr-0"
                >
                  <label for="status">Golongan Darah</label>
                </v-col>
                <v-col cols="6">
                  <v-select
                    dense
                    placeholder="Pilih Golongan Darah"
                    class="mt-0"
                    :items="input.bloodType"
                    v-model="patient.blood_type"
                    required
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="6" class="pl-10">
              <v-row align="baseline">
                <v-col cols="4" class="black--text text-left font-weight-bold">
                  <label for="status">Pendidikan</label>
                </v-col>
                <v-col cols="6">
                  <v-select
                    dense
                    placeholder="Pilih Pendidikan"
                    class="mt-0"
                    :items="input.education"
                    v-model="patient.education"
                    required
                  ></v-select>
                </v-col>
              </v-row>

              <v-row align="baseline">
                <v-col cols="4" class="black--text text-left font-weight-bold">
                  <label for="status">Pekerjaan</label>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    dense
                    placeholder="Pilih Pekerjaan"
                    class="mt-0"
                    v-model="patient.profession"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row align="baseline">
                <v-col cols="4" class="black--text text-left font-weight-bold">
                  <label for="status">Status Kawin</label>
                </v-col>
                <v-col cols="6">
                  <v-select
                    dense
                    placeholder="Pilih Status Kawin"
                    class="mt-0"
                    :items="input.maritalStatus"
                    v-model="patient.martial_status"
                    required
                  ></v-select>
                </v-col>
              </v-row>

              <v-row align="baseline">
                <v-col cols="4" class="black--text text-left font-weight-bold">
                  <label for="name">Nama Ayah</label>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    dense
                    placeholder="Masukkan Nama Ayah"
                    class="mt-0"
                    v-model="patient.father"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row align="baseline">
                <v-col cols="4" class="black--text text-left font-weight-bold">
                  <label for="name">Nama Ibu</label>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    dense
                    placeholder="Masukkan Nama Ibu"
                    class="mt-0"
                    v-model="patient.mother"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row align="baseline">
                <v-col cols="4" class="black--text text-left font-weight-bold">
                  <label for="status">Etnis</label>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    auto-select-first
                    dense
                    placeholder="Pilih Etnis"
                    class="mt-0"
                    :items="input.ethnicity"
                    v-model="patient.ethnicity"
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row align="baseline">
                <v-col cols="4" class="black--text text-left font-weight-bold">
                  <label for="name">Bahasa yang dikuasai</label>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    dense
                    placeholder="Bahasa yang dikuasai"
                    class="mt-0"
                    v-model="patient.language_mastering"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row align="baseline">
                <v-col cols="4" class="black--text text-left font-weight-bold">
                  <label for="name">Hambatan dalam Berkomunikasi</label>
                </v-col>
                <v-col cols="6">
                  <v-select
                    dense
                    placeholder="Hambatan dalam Berkomunikasi"
                    class="mt-0"
                    v-model="patient.communication_obstacles"
                    :items="input.communicationObstacles"
                    clearable
                  ></v-select>
                </v-col>
              </v-row>

              <v-row align="baseline">
                <v-col cols="4" class="black--text text-left font-weight-bold">
                  <label for="name">Penyandang Disabilitas</label>
                </v-col>
                <v-col cols="6">
                  <v-select
                    dense
                    placeholder="Disabilitas"
                    class="mt-0"
                    v-model="patient.disabilities"
                    :items="input.disabilities"
                    clearable
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-row>
      </v-form>
      <v-row no-gutters>
        <v-col cols="12">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn large color="secondary" text @click="$emit('back-dialog')">
              Kembali
            </v-btn>
            <v-btn
              large
              outlined
              class="action-button"
              color="primary"
              :disabled="!valid"
              @click="validate()"
            >
              Lanjut
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card-text>
    <v-snackbar
      absolute
      light
      content-class="font-weight-medium pr-0"
      :value="matchedSnackbar"
      :timeout="2000"
      elevation="0"
      color="#E5FFF0"
      height="15"
    >
      <v-icon color="success" class="mr-1">mdi-check-circle</v-icon> Data pasien
      sudah sesuai dengan BPJS
      <v-btn text fab small class="ml-4" @click="matchedSnackbar = false"
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </v-snackbar>
    <v-snackbar
      absolute
      content-class="font-weight-medium pr-0"
      light
      :value="unmatchedSnackbar"
      :timeout="-1"
      elevation="0"
      color="#FFF3F3"
      height="15"
    >
      <v-icon color="red" class="mr-1">mdi-alert</v-icon> Data pasien tidak
      sesuai dengan BPJS.
      <span
        @click="openBPJSCorrection()"
        style="color: blue; cursor: pointer"
        class="font-weight-bold"
        ><u>Lihat Detail</u></span
      >
      <v-btn
        text
        fab
        small
        class="ml-4"
        @click="
          unmatchedSnackbar = false;
          isBPJSMajorlyUnmatched = '';
        "
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </v-snackbar>
    <v-dialog
      v-model="dialog"
      persistent
      :max-width="selectedForm === null ? 0 : width[selectedForm].width"
    >
      <v-card class="small-dialog" v-if="selectedForm === 0">
        <v-icon @click="closeForm('close')" class="close-btn">mdi-close</v-icon>
        <v-card-title class="justify-center">
          <h3 class="title">Pendaftaran Poli</h3>
        </v-card-title>
        <v-card-text>
          <v-container class="pa-0 ma-0">
            <v-row no-gutters justify="center" class="register-container">
              <v-col cols="10">
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="name">Jenis Kunjungan</label>
                  </div>

                  <v-radio-group
                    row
                    class="detail-input__input-radio"
                    v-model="registration.isSick"
                  >
                    <v-radio
                      name="isSick"
                      label="Sehat"
                      :value="false"
                    ></v-radio>
                    <v-radio
                      name="isSick"
                      label="Sakit"
                      :value="true"
                    ></v-radio>
                  </v-radio-group>
                </div>

                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="name">Poli Tujuan</label>
                  </div>
                  <v-select
                    label="Pilih Poli Tujuan"
                    class="detail-input__input-text"
                    :items="input.polyclinic"
                    item-value="unitId"
                    item-text="unitName"
                    append-icon="mdi-chevron-down"
                    v-model="registration.polyclinic"
                    return-object
                    dense
                    clearable
                    @change="
                      getScheduleDoctor;
                      registration.doctor = '';
                      registration.time.time = null;
                    "
                  ></v-select>
                </div>

                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="name">Pilih Dokter</label>
                  </div>
                  <v-combobox
                    :loading="doctorLoading"
                    auto-select-first
                    :disabled="!registration.polyclinic"
                    dense
                    placeholder="Pilih Dokter"
                    :items="doctor"
                    item-value="doctorId"
                    item-text="doctorName"
                    append-icon="mdi-chevron-down"
                    v-model="registration.doctor"
                    return-object
                    clearable
                  ></v-combobox>
                </div>
                <div class="detail-input mb-4">
                  <div class="detail-input__label">
                    <label for="date">Hari, Tanggal</label>
                  </div>
                  <v-select
                    :readonly="!registration.doctor || !isBooking"
                    auto-select-first
                    v-model="registration.visitDate"
                    return-object
                    item-text="text"
                    :append-icon="isBooking ? 'mdi-chevron-down' : ''"
                    :items="days"
                    hide-details="false"
                    :clearable="isBooking"
                    dense
                    placeholder="Pilih Tanggal Kunjungan"
                    @change="onChangeVisitDate"
                  ></v-select>
                </div>
                <div
                  class="detail-input"
                  v-if="registration.doctor && registration.visitDate"
                >
                  <div class="detail-input__label">
                    <label for="name">Jam Praktik</label>
                  </div>
                  <v-select
                    :loading="scheduleLoading"
                    :disabled="!registration.doctor"
                    append-icon="mdi-chevron-down"
                    return-object
                    dense
                    :items="registration.visitDate.time"
                    item-text="time"
                    placeholder="Pilih Jam Praktik"
                    v-model="registration.time"
                  ></v-select>
                </div>
                <div
                  class="detail-input"
                  v-if="registration.doctor && registration.visitDate"
                >
                  <div class="detail-input__label">
                    <label for="name">Kuota</label>
                  </div>
                  <v-text-field
                    :disabled="!registration.time.time"
                    dense
                    placeholder="Sisa Kuota"
                    v-model="registration.time.quota"
                    :loading="quotaLoading"
                    readonly
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>

            <v-row class="action-footer">
              <v-col cols="12">
                <v-card-actions class="d-flex justify-space-between">
                  <div class="d-flex">
                    <v-btn
                      color="primary"
                      class="mr-5 font-weight-bold"
                      outlined
                      @click="closeForm('')"
                      ><v-icon left>mdi-arrow-left</v-icon> Data Pasien</v-btn
                    >
                    <v-btn
                      color="primary"
                      outlined
                      @click="selectedForm = 3"
                      v-if="isSelectAnamnesis"
                      class="font-weight-bold"
                      ><v-icon left>mdi-arrow-left</v-icon> TTV</v-btn
                    >
                  </div>
                  <v-btn
                    class="text-none"
                    depressed
                    color="primary"
                    :disabled="!isScheduleAvailable || isRegistered"
                    :loading="loadingButton"
                    @click="addData()"
                  >
                    Daftar
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <!-- for booking feature -->
      <v-card v-else-if="selectedForm === 1">
        <v-card-title class="justify-center">
          <h3>Buat Jadwal Pendaftaran Pasien</h3>
        </v-card-title>
        <v-card-text>
          <v-container class="pt-6">
            <v-row align="baseline" justify="center">
              <v-col cols="3" class="black--text text-left">
                <label>Tanggal Kunjungan</label>
              </v-col>
              <v-col cols="3">
                <v-menu
                  ref="menuEmpty"
                  v-model="menuEmpty"
                  :close-on-content-click="false"
                  :return-value.sync="registration.visitDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="registration.visitDate"
                      append-icon="mdi-chevron-down"
                      v-bind="attrs"
                      v-on="on"
                      hide-details="false"
                      dense
                      placeholder="Pilih Tanggal Kunjungan"
                      @change="getScheduleDoctor"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="registration.visitDate"
                    :min="today"
                    no-title
                    scrollable
                    @input="
                      $refs.menuEmpty.save(registration.visitDate);
                      menuEmpty = false;
                    "
                  />
                </v-menu>
              </v-col>
            </v-row>
            <v-row align="baseline" justify="center">
              <v-col cols="3" class="black--text text-left">
                <label>Klinik Tujuan</label>
              </v-col>
              <v-col cols="3">
                <v-select
                  dense
                  placeholder="Pilih Unit"
                  :items="input.polyclinic"
                  item-value="unitId"
                  item-text="unitName"
                  return-object
                  v-model="registration.polyclinic"
                  @change="
                    getScheduleDoctor;
                    registration.doctor = '';
                    registration.time.time = null;
                  "
                  hide-details="false"
                ></v-select>
              </v-col>
            </v-row>
            <v-row align="baseline" justify="center">
              <v-col cols="3" class="black--text text-left">
                <label>Nama Dokter</label>
              </v-col>
              <v-col cols="3">
                <v-select
                  :disabled="!registration.polyclinic"
                  dense
                  placeholder="Pilih Dokter"
                  clearable
                  :items="doctor"
                  item-value="doctorId"
                  item-text="doctorName"
                  v-model="registration.doctor"
                  return-object
                ></v-select>
              </v-col>
            </v-row>
            <v-row align="baseline" justify="center">
              <v-col cols="3" class="black--text text-left">
                <label>Jam Praktik</label>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  :disabled="!registration.doctor"
                  dense
                  placeholder="Pilih Jam Praktik"
                  v-model="registration.time.time"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row align="baseline" justify="center">
              <v-col cols="3" class="black--text text-left">
                <label>Sisa Kuota</label>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  :disabled="!registration.time.time"
                  dense
                  placeholder="Sisa Kuota"
                  v-model="remainQuota"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            large
            color="secondary"
            text
            @click="closeForm('')"
            class="text-none"
          >
            Kembali
          </v-btn>
          <v-btn
            tile
            class="text-none"
            color="primary"
            @click="addData()"
            width="100"
            elevation="0"
          >
            Simpan
          </v-btn>
        </v-card-actions>
      </v-card>

      <PrintDialog
        :hasGeneralConsent="hasGeneralConsent"
        :selected-patient="printData"
        v-else-if="selectedForm === 2"
        @close-form="closeForm('close')"
        state="rawat-jalan"
      />
      <AnamnesisForm
        v-else-if="selectedForm === 3"
        @back="closeForm('')"
        @next="saveAnamnesis($event)"
        :data="input.ttv"
      />
      <BPJSCorrectionData
        @close-form="closeBPJSCorrection()"
        v-else-if="selectedForm === 4"
        :data="patient.bpjsData"
      />
    </v-dialog>
    <div
      v-if="snackbar.show"
      class="warning-pcare d-flex flex-row rounded-lg text-left"
    >
      <v-icon class="pa-1 align-start" color="red" dense>
        mdi-alert
      </v-icon>
      <label class="ml-1 warning-pcare-text">
        <strong style="font-size: 16px; line-height: 24px;">{{
          snackbar.title
        }}</strong>
        <br />
        {{ snackbar.text }}
      </label>
      <v-icon
        class="pa-1 align-start"
        style="cursor: pointer; width: 27px; height: 27px;"
        @click="handleSnackbar(false)"
        dense
      >
        mdi-close
      </v-icon>
    </div>
  </v-card>
</template>

<script>
import Constant from '@/const';
import Swal from 'sweetalert2';
import PrintDialog from './PrintDialog';
import alertMixin from '@/mixin/alertMixin';
import AnamnesisForm from './AnamnesisForm';
import PatientQueueMixin from '@/mixin/PatientQueueMixin';
import BPJSCorrectionData from './BPJSCorrectionData.vue';

const axios = require('axios');
const moment = require('moment-timezone');
const _ = require('lodash');
axios.defaults.headers.post['Content-Type'] = 'application/json';

export default {
  name: 'SocialDataPatient',
  components: { PrintDialog, AnamnesisForm, BPJSCorrectionData },
  mixins: [alertMixin, PatientQueueMixin],
  props: {
    selectedPatient: {
      type: Object,
      default: null,
    },
    patientStatus: {
      type: String,
    },
    triase: {
      type: Number,
      default: null,
    },
    isBooking: {
      type: Boolean,
      default: false,
    },
    getTriasePatient: Function,
  },
  data() {
    return {
      timestamp: '',
      isBPJSMajorlyUnmatched: '',
      isBPJSMinorlyUnmatched: '',
      unmatchedSnackbar: false,
      matchedSnackbar: false,
      hasGeneralConsent: false,
      isSelectAnamnesis: false,
      menu: false,
      isPrint: false,
      checked: '',
      PrintDialog: false,
      goTo: '',
      dialog: false,
      isLoading: false,
      isRegistered: false,
      doctorLoading: false,
      scheduleLoading: false,
      date: null,
      menuEmpty: false,
      pcareServerError: false,
      doctorToday: [],
      days: [],
      printData: {},
      remainQuota: 0,
      bpjs_error: [],
      nik_error: [],
      checkNo: {
        bpjs: false,
        nik: false,
      },
      patient: {
        name: '',
        nickname: '',
        gender: '',
        ethnicity: '',
        age: '',
        language_mastering: '',
        communication_obstacles: null,
        disabilities: null,
        birth: {
          date: '',
          place: '',
        },
        address: {
          province: '',
          city: '',
          district: '',
          sub_district: '',
          text: '',
        },
        identity: {
          type: '',
          number: '',
        },
        religion: '',
        blood_type: '',
        education: '',
        profession: '',
        martial_status: '',
        father: '',
        mother: '',
        bpjsData: {
          no: '',
          name: '',
          birthDate: '',
          ktpNo: '',
        },
        assurance: {
          type: '',
          name: '',
          number: '',
          isProlanis: false,
          isBpjs: true,
          referBack: false,
          provider: '',
        },
        phone_number: '',
      },
      rmNumber: '',
      input: {
        ttv: {
          anamnesis: '',
          sistol: '',
          diastol: '',
          heart_rate: '',
          weight: '',
          height: '',
          belly_circumference: '',
          respiration_rate: '',
          saturation: '',
          temperature: '',
          awarness: '',
        },
        queueNumber: '',
        status: [
          { text: 'Nyonya', value: 'nyonya' },
          { text: 'Tuan', value: 'tuan' },
          { text: 'Nona', value: 'nona' },
          { text: 'Saudara', value: 'saudara' },
          { text: 'Anak', value: 'anak' },
          { text: 'Bayi', value: 'bayi' },
        ],
        identityType: ['Tidak Ada Identitas', 'KTP', 'SIM', 'Passport'],
        religion: [
          { text: 'Islam', value: 'islam' },
          { text: 'Kristen', value: 'kristen' },
          { text: 'Budha', value: 'budha' },
          { text: 'Hindu', value: 'hindu' },
          { text: 'Katolik', value: 'katolik' },
        ],
        bloodType: [
          { text: 'A', value: 'a' },
          { text: 'AB', value: 'ab' },
          { text: 'B', value: 'b' },
          { text: 'O', value: 'o' },
        ],
        education: [
          { text: 'Tidak Sekolah', value: '-' },
          { text: 'TK', value: 'tk' },
          { text: 'SD', value: 'sd' },
          { text: 'SMP', value: 'smp' },
          { text: 'SMA', value: 'sma' },
          { text: 'Diploma I', value: 'diploma1' },
          { text: 'Diploma II', value: 'diploma2' },
          { text: 'Diploma III', value: 'diploma3' },
          { text: 'Diploma IV', value: 'diploma4' },
          { text: 'Sarjana', value: 'sarjana' },
          { text: 'Magister', value: 'magister' },
          { text: 'Doktor', value: 'doktor' },
        ],
        maritalStatus: [
          { text: 'Belum Kawin', value: 'belum kawin' },
          { text: 'Kawin', value: 'kawin' },
          { text: 'Duda', value: 'duda' },
          { text: 'Janda', value: 'janda' },
        ],
        ethnicity: [
          { text: 'Suku Bali', value: 'bali' },
          { text: 'Suku Banjar', value: 'banjar' },
          { text: 'Suku Batak', value: 'batak' },
          { text: 'Suku Betawi', value: 'betawi' },
          { text: 'Suku Bugis', value: 'bugis' },
          { text: 'Suku Cina, RRC, Taiwan', value: 'cina' },
          { text: 'Suku Cirebon', value: 'cirebon' },
          { text: 'Suku Dayak', value: 'dayak' },
          { text: 'Suku Gorontalo', value: 'gorontalo' },
          { text: 'Suku Jawa', value: 'jawa' },
          { text: 'Suku Madura', value: 'madura' },
          { text: 'Suku Makassar', value: 'makassar' },
          { text: 'Suku Melayu', value: 'melayu' },
          { text: 'Suku Minahasa', value: 'minahasa' },
          { text: 'Suku Minangkabau', value: 'minangkabau' },
          { text: 'Suku Nias', value: 'nias' },
          { text: 'Suku Sasak', value: 'sasak' },
          { text: 'Suku Asal Aceh', value: 'aceh' },
          { text: 'Suku Asal Banten', value: 'banten' },
          { text: 'Suku Asal Kalimantan lainnya', value: 'kalimantan' },
          { text: 'Suku Asal Maluku', value: 'maluku' },
          { text: 'Suku Asal Nusa Tenggara Timur', value: 'ntt' },
          { text: 'Suku Asal Papua', value: 'papua' },
          { text: 'Suku Asal Sulawesi lainnya', value: 'sulawesi' },
          { text: 'Suku Asal Sumatera lainnya', value: 'sumatera' },
          { text: 'Suku Luar Negeri', value: 'ln' },
          { text: 'Suku Asal Jambi', value: 'jambi' },
          { text: 'Suku Asal Lampung', value: 'lampung' },
          { text: 'Suku Asal Sumatera Selatan', value: 'sumatra selatan' },
          { text: 'Suku Nusa Tenggara Barat lainnya', value: 'ntb' },
          { text: 'Suku Sunda', value: 'sunda' },
        ],
        communicationObstacles: [
          { text: 'Tidak Ada', value: '' },
          { text: 'Bahasa', value: 'bahasa' },
          { text: 'Lingkungan', value: 'lingkungan' },
          { text: 'Fisik', value: 'fisik' },
          { text: 'Psikologi', value: 'psikologi' },
        ],
        disabilities: [
          { text: 'Tidak ada', value: 0 },
          { text: 'Fisik', value: 1 },
          { text: 'Mental', value: 2 },
          { text: 'Fisik dan Mental', value: 3 },
        ],
        polyclinic: [],
        doctor: [],
        time: null,
        province: [],
        city: [],
        district: [],
        sub_district: [],
      },
      registration: {
        polyclinic: '',
        doctor: '',
        time: {
          time: null,
          quota: 0,
        },
        isSick: true,
        visitDate: null,
      },
      doctor: [],
      time: [],
      rules: {
        name: [v => !!v || 'Perlu mengisi nama'],
        nameBPJS: [
          v => !!v || 'Perlu mengisi nama',
          v => {
            const nameToCompare =
              this.patient?.bpjsData?.name || this.patient.name;
            const formattedV = v
              .toLowerCase()
              .trim()
              .replace(/ +/g, ' ');
            const formattedName = nameToCompare
              .toLowerCase()
              .trim()
              .replace(/ +/g, ' ');
            return (
              formattedV === formattedName ||
              'Nama tidak sesuai dengan data BPJS'
            );
          },
        ],
        gender: [v => v !== '' || 'Perlu memilih jenis kelamin'],
        status: [v => !!v || 'Perlu memilih status pasien'],
        birthPlace: [
          v => !!v || 'Perlu mengisi tempat lahir',
          v => (v && v.length <= 30) || 'Tempat lahir maksimal 30 karakter',
        ],
        birthDate: [
          v => !!v || 'Perlu mengisi tanggal lahir',
          v => this.checkDateFormat(v) || 'Tanggal Tidak Valid',
          v => {
            const range = v.split('/');
            const tanggal = parseInt(range[0]);
            const bulan = parseInt(range[1]);
            const tahun = parseInt(range[2]);
            const hariIni = new Date();
            return (
              tahun < hariIni.getFullYear() ||
              (tahun == hariIni.getFullYear() &&
                bulan < hariIni.getMonth() + 1) ||
              (tahun == hariIni.getFullYear() &&
                bulan == hariIni.getMonth() + 1 &&
                tanggal <= hariIni.getDate()) ||
              'Tanggal tidak boleh lebih besar dari hari ini'
            );
          },
        ],
        address: [
          v => !!v || 'Perlu mengisi alamat',
          v => (v && v.length <= 300) || 'Alamat maksimal 300 karakter',
        ],
        religion: [v => !!v || 'Perlu memilih agama'],
        guarantorType: [v => !!v || 'Perlu memilih jenis jaminan'],
      },
      valid: true,
      bpjsValid: true,
      nikValid: true,
      bpjsNotFound: false,
      loadingButton: false,
      quotaLoading: false,
      selectedForm: null,
      width: [
        { text: 'Schedule', width: 600 },
        { text: 'Booking', width: 800 },
        { text: 'Print', width: 485 },
        { text: 'Anamnesis', width: 1200 },
        { text: 'BPJSCorrection', width: 520 },
      ],
      snackbar: {
        show: false,
        title: '',
        text: '',
      },
    };
  },
  computed: {
    isScheduleAvailable() {
      return (
        moment().diff(
          moment(
            `${this.registration?.visitDate?.value} ${
              this.registration?.time?.time?.split(' ')[2]
            }`,
            'YYYY/MM/DD HH:mm',
          ),
          'minutes',
        ) <= 0
      );
    },
    today() {
      return moment().format('YYYY/MM/DD');
    },
    isBpjs() {
      return this.patient.assurance.type === 'bpjs';
    },
  },
  watch: {
    isBPJSMajorlyUnmatched(val) {
      if (val) {
        this.matchedSnackbar = !val;
        this.unmatchedSnackbar = val;
      }
      if (!val && val !== '') {
        this.matchedSnackbar = !val;
        this.unmatchedSnackbar = val;
      }
    },
    dialog() {
      this.updateData();
    },
    'registration.time.time'() {
      this.getQueueRemain();
    },
    'registration.polyclinic'(value) {
      if (value) {
        if (this.isBooking) this.resetSchedule();
        this.getDoctorToday();
      } else {
        this.resetSchedule();
      }
    },
    'patient.birth.date'(val) {
      if (val.length > 10) {
        this.patient.birth.date = this.patient.birth.date.slice(0, 10);
      }
    },
    'registration.doctor'(value) {
      if (value) {
        this.getScheduleDoctor();
      } else {
        this.resetSchedule();
        this.registration.time = {
          time: '',
          quota: 0,
        };
      }
    },
    'patient.nickname'(val) {
      switch (val) {
        case 'nyonya':
          this.patient.gender = false;
          break;
        case 'nona':
          this.patient.gender = false;
          break;
        case 'tuan':
          this.patient.gender = true;
          break;
        case 'saudara':
          this.patient.gender = true;
          break;
        default:
          break;
      }
    },
    'patient.assurance.isBpjs'() {
      this.$refs.noBpjs.resetValidation();
      this.$refs.noNik.resetValidation();
    },
    'patient.assurance.number'(value) {
      this.bpjs_error = [];
      this.checkNo.bpjs = false;
      if (value.length == 13 && this.patient.assurance.type == 'bpjs') {
        this.checkActivation(this.patient.assurance.type, value);
      }
      if (value.length > 13 && this.patient.assurance.type == 'bpjs') {
        this.bpjs_error.push('Nomer BPJS tidak dapat lebih dari 13');
      }
    },
    'patient.identity.number'(value) {
      if (
        this.patient.assurance.type === 'bpjs' &&
        this.patient.assurance.isBpjs
      )
        return;
      this.nik_error = [];
      if (this.patient.assurance.type == 'bpjs' && value.length == 16) {
        this.checkActivation('nik', value);
      } else if (this.patient.assurance.type == 'bpjs' && value.length < 16) {
        this.patient.assurance.number = '';
        this.patient.assurance.provider = '';
      } else if (this.patient.assurance.type == 'bpjs' && value.length > 16) {
        this.nik_error.push('NIK tidak dapat lebih dari 16');
      }
    },
    'patient.assurance.type'(val) {
      this.mapInitialDate();
      if (val !== 'bpjs') this.isBooking = true;
      if (val === 'bpjs') this.isBooking = false;
    },
  },
  mounted() {
    this.getAllInsurance();
    this.timestamp = moment().format();
    if (this.selectedPatient === null) {
      this.getNoRM();
    } else if (this.selectedPatient.status !== 'igd-new') {
      this.getSocialByRM();
    } else {
      this.getNoRM();
      this.patient.name = this.selectedPatient.name;
      this.patient.gender = this.selectedPatient.gender !== 'Perempuan';
      this.patient.address.text = this.selectedPatient.address;
    }
    this.getUnit(this.patientStatus);
    this.getRegion();
  },
  methods: {
    checkRules() {
      if (!this.patient.name) {
        const el = document.querySelector('#name');
        el.scrollIntoView();
      } else if (!this.patient.nickname) {
        const el = document.querySelector('#status');
        el.scrollIntoView();
      } else if (typeof this.patient.gender !== 'boolean') {
        const el = document.querySelector('#gender');
        el.scrollIntoView();
      } else if (!this.patient.nickname) {
        const el = document.querySelector('#status');
        el.scrollIntoView();
      } else if (!this.patient.birth.place) {
        const el = document.querySelector('#birthplace');
        el.scrollIntoView();
      } else if (
        !this.patient.birth.date ||
        !this.checkDateFormat(this.patient.birth.date)
      ) {
        const el = document.querySelector('#birthdate');
        el.scrollIntoView();
      } else if (!this.patient.assurance.type) {
        const el = document.querySelector('#assurance');
        el.scrollIntoView();
      } else if (!this.patient.address.text) {
        const el = document.querySelector('#address');
        el.scrollIntoView();
      }
    },
    saveAnamnesis(data) {
      this.input.ttv = data;
      this.selectedForm = 0;
    },
    checkDateFormat(date) {
      const dateInput = moment(date, 'DD/MM/YYYY');
      return dateInput.isValid();
    },
    checkUnmatched() {
      const patientName = this.patient.name
        .toLowerCase()
        .trim()
        .replace(/  +/g, ' ');
      const bpjsName = this.patient.bpjsData.name
        .toLowerCase()
        .trim()
        .replace(/  +/g, ' ');
      if (this.patient.assurance.type !== 'bpjs') return;
      if (
        !patientName ||
        !this.patient.bpjsData.no ||
        !this.patient.birth.date
      ) {
        return;
      }
      if (
        patientName !== bpjsName &&
        (moment(this.patient.birth.date, 'DD/MM/YYYY').format() !==
          this.patient.bpjsData.birthDateFormatted ||
          (this.patient.bpjsData.ktpNo &&
            this.patient.identity.type === 'KTP' &&
            this.patient.identity.number !== this.patient.bpjsData.ktpNo))
      ) {
        this.isBPJSMajorlyUnmatched = true;
        return;
      }
      if (
        patientName === bpjsName &&
        (moment(this.patient.birth.date, 'DD/MM/YYYY').format() !==
          this.patient.bpjsData.birthDateFormatted ||
          (this.patient.identity.type === 'KTP' &&
            this.patient.identity.number !== this.patient.bpjsData.ktpNo))
      ) {
        this.isBPJSMajorlyUnmatched = true;
        return;
      }
      if (patientName !== bpjsName) {
        this.isBPJSMinorlyUnmatched = true;
        return;
      }
      this.isBPJSMinorlyUnmatched = false;
      this.isBPJSMajorlyUnmatched = false;
    },
    openBPJSCorrection() {
      this.dialog = true;
      this.selectedForm = 4;
    },
    closeBPJSCorrection() {
      this.dialog = false;
    },
    mapInitialDate() {
      if (this.isBpjs) {
        this.registration.visitDate = {
          text: moment()
            .locale('id')
            .format('dddd, Do MMMM YYYY'),
          value: moment().format('YYYY/MM/DD'),
          quota: 0,
          time: '',
        };
        this.days = [
          {
            text: moment()
              .locale('id')
              .format('dddd, Do MMMM YYYY'),
            value: moment().format('YYYY/MM/DD'),
            quota: 0,
            time: '',
          },
        ];
      }
      if (!this.isBpjs) {
        this.registration.visitDate = null;
      }
    },
    resetSchedule() {
      this.days = [];
      this.registration.visitDate = '';
    },
    onCloseButtonClick() {
      this.$emit('close-form');
    },
    closeForm(type) {
      if (type === 'close') {
        this.dialog = false;
      } else {
        this.dialog = false;
        this.$emit('back-to-form');
      }
    },
    updateData() {
      this.$emit('update-data');
    },
    insertString(originalString, newString, index) {
      return (
        originalString.substr(0, index) +
        newString +
        originalString.substr(index)
      );
    },
    formatDate(dateString) {
      if (dateString.length > 10) {
        this.patient.birth.date = dateString.slice(0, 10);
        return;
      }
      let date = dateString.replace(/\D/g, '').substr(0, 10);
      let size = date.length;

      if (size > 4) date = this.insertString(date, '/', 4);

      if (size > 2) date = this.insertString(date, '/', 2);

      this.patient.birth.date = date;
      if (date.length === 10) {
        this.patient.age = moment().diff(moment(date, 'DD/MM/YYYY'), 'years');
      } else {
        this.patient.age = '';
      }
    },
    validate() {
      if (!this.$refs.form.validate()) {
        this.checkRules();
        return;
      }
      if (this.patient.assurance.type == 'bpjs') {
        if (this.patient.assurance.isBpjs) {
          if (this.patient.assurance.number.toString().length != 13) {
            this.bpjs_error.push('Nomer BPJS harus 13 digit');
            return;
          }
        } else {
          if (this.patient.identity.number.toString().length != 16) {
            this.nik_error.push('NIK harus 16 digit');
            return;
          }
        }

        if (this.bpjsNotFound) {
          this.handleSnackbar(
            true,
            'No BPJS Tidak Ditemukan',
            'Anda tidak dapat melakukan pendaftaran dengan No BPJS yang anda input. Silahkan cek kembali atau ubah jenis jaminan',
          );
          return;
        }
        if (!this.checkNo.bpjs && !this.pcareServerError) {
          this.handleSnackbar(
            true,
            'No BPJS Tidak Aktif',
            'Anda tidak dapat melakukan pendaftaran dengan No BPJS yang anda input. Silahkan ubah jenis jaminan',
          );
          return;
        }
      }
      if (this.patientStatus === 'rawat jalan') {
        this.selectedForm = null;
        Swal.fire({
          title: 'Ingin Menginput Anamnesis & TTV Terlebih Dahulu?',
          text:
            'Jika tidak, Anda bisa menginputnya di form asesmen setelah pendaftaran ini selesai',
          icon: 'warning',
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak',
        }).then(result => {
          if (!result.value) {
            this.selectedForm = 0;
            this.isSelectAnamnesis = false;
          } else {
            this.selectedForm = 3;
            this.isSelectAnamnesis = true;
          }
        });

        this.dialog = true;
        setInterval(this.onCloseButtonClick(), 6000);
      } else if (this.patientStatus === 'igd') {
        this.registration.polyclinic = this.input.polyclinic[0];
        this.addData().finally(() => {
          this.getTriasePatient();
        });
        this.onCloseButtonClick();
      } else {
        this.registration.polyclinic = {
          unitId: this.selectedPatient.meta.polyId,
        };
        // this.addData();
        this.$emit('on-clicked', 2);
      }
    },
    generatesComma(text) {
      if (text) return ', ';
      if (!text) return '';
    },
    getRegion() {
      this.isLoading = true;
      axios
        .get(Constant.apiUrl.concat(`/master/region/province?search=`))
        .then(response => {
          var province = response.data.data;
          this.input.province = province.map(prov => {
            return {
              id: prov._id.toString(),
              name: prov.name,
            };
          });
        })
        .finally(() => (this.isLoading = false));
    },
    async getRegistrationById(idRegistration, id_emr) {
      // data used for print dialog
      try {
        const response = await axios.get(
          Constant.apiUrl.concat(`/patient/registration/${idRegistration}`),
        );
        if (response.status === 200) {
          const { data } = response.data;
          this.printData = {
            id_emr: id_emr,
            id_registration: idRegistration,
            rmNumber: data.medical_record.medical_record_number,
            queue: data.queue_code,
            guarantorType: data.assurance.type,
            noRegist: data.registration.registration_number,
            meta: {
              name: data.medical_record.name,
              birthDate: data.medical_record.birth.date,
              gender: data.medical_record.gender ? 'Laki-laki' : 'Perempuan',
              age: data.medical_record.age,
              address: data.address.text,
              identityNumber: data.identity.number,
              doctor: data.registration.id_staff.name,
              poly: [data.registration.id_unit.name],
              phoneNumber: this.patient.phone_number,
            },
          };
        }
      } catch (error) {
        this.showErrorAxios(error);
      }
    },
    getAllInsurance() {
      axios.get(Constant.apiUrl.concat('/insurance/')).then(response => {
        const { data } = response.data;
        this.assurance = data.map(insurance => insurance.name);
      });
    },
    getDoctor() {
      axios
        .get(
          Constant.apiUrl.concat(
            `/master/staff?role[]=Dokter&role[]=Bidan&role[]=dokter&role[]=bidan&id_unit=`,
          ),
        )
        .then(response => {
          if (response.status === 200) {
            var data = response.data.data;
            this.doctor = data
              .filter(
                v => v.role.status && this.doctorToday.includes(v.detail.name),
              )
              .map(doctor => {
                return {
                  doctorId: doctor._id.toString(),
                  doctorName: doctor.detail.name,
                };
              });
            this.doctorLoading = false;
          }
        });
    },
    getUnit(type) {
      let params = '';
      switch (type) {
        case 'rawat jalan':
          params = 'Instalasi Rawat Jalan';
          break;
        case 'igd':
          params = 'Instalasi Gawat Darurat';
          break;
        case 'rawat inap':
          params = 'Instalasi Rawat Inap';
          break;
      }
      axios
        .get(
          Constant.apiUrl.concat(
            `/master/unit?page=1&itemCount=8&search=&sort=name&installation=${params}`,
          ),
        )
        .then(response => {
          if (response.status === 200) {
            this.input.polyclinic = response.data.data.map(unit => {
              return {
                unitId: unit._id.toString(),
                unitName: unit.name,
                code: unit.code,
              };
            });
          }
        });
    },
    addData() {
      this.loadingButton = true;
      let registration = {
        registration: {
          id_unit: this.registration.polyclinic.unitId,
          code_poly: this.registration.polyclinic.code,
          id_staff: this.registration.doctor.doctorId || '',
          time: this.registration.time.time || '',
          isSick: this.registration.isSick || true,
          date: this.isBooking
            ? this.registration.visitDate?.value
            : this.timestamp,
          status: {
            isNew:
              this.selectedPatient === null ||
              this.selectedPatient.status === 'igd-new',
            type: this.patientStatus,
          },
          isBooking:
            this.registration.visitDate?.value != moment().format('YYYY/MM/DD'),
        },
        patient: {
          name: this.patient.name.trim().replace(/  +/g, ' '),
          nickname: this.patient.nickname,
          phone_number: this.patient.phone_number,
          gender: this.patient.gender,
          birth: {
            date: moment(this.patient.birth.date, 'DD/MM/YYYY').format(
              'YYYY-MM-DD',
            ),
            place: this.patient.birth.place,
          },
          address: {
            district: this.patient.address.district.name || '',
            province: this.patient.address.province.name || '',
            sub_district: this.patient.address.sub_district.name || '',
            text: this.patient.address.text || '',
            city: this.patient.address.city.name || '',
          },
          identity: {
            type: this.patient.identity.type,
            number: this.patient.identity.number,
          },
          religion: this.patient.religion,
          blood_type: this.patient.blood_type,
          education: this.patient.education,
          ethnicity:
            this.patient.ethnicity == null ? '' : this.patient.ethnicity,
          language_mastering: this.patient.language_mastering,
          disabilities:
            this.patient.disabilities === null ? 0 : this.patient.disabilities,
          communication_obstacles:
            this.patient.communication_obstacles === null
              ? ''
              : this.patient.communication_obstacles,
          profession: this.patient.profession,
          martial_status: this.patient.martial_status,
          father: this.patient.father,
          mother: this.patient.mother,
          assurance: {
            type: this.patient.assurance.type,
            name: this.patient.assurance.name,
            number: this.patient.assurance.number,
            isProlanis: this.patient.assurance.isProlanis,
          },
        },
        timestamps: {
          created_by: this.$store.getters.userLoggedIn.id,
        },
      };
      if (this.patientStatus === 'igd') {
        registration = {
          ...registration,
          registration: {
            ...registration.registration,
            isBooking: false,
          },
        };
      }
      let payload = this.patient.id_emr
        ? {
            id_emr: this.patient.id_emr,
            ...registration,
          }
        : {
            ...registration,
          };
      if (this.isSelectAnamnesis) {
        payload = {
          ttv: this.input.ttv,
          ...payload,
        };
      }
      axios
        .post(
          Constant.apiUrl.concat('/patient/registration'),
          this.triase !== null
            ? {
                triase_number: this.triase,
                ...payload,
              }
            : payload,
        )
        .then(async response => {
          if (response.status === 200) {
            this.isRegistered = true;
            if (this.patientStatus !== 'rawat inap') {
              Swal.fire('Pasien berhasil terdaftar', '', 'success');
            }
            this.loadingButton = false;
            if (this.patientStatus !== 'igd') {
              await this.getRegistrationById(
                response.data.data.id_registration,
                response.data.data.id_emr,
              );
            }
            if (this.isBooking) {
              this.onCloseButtonClick();
            }
            this.registration = {
              polyclinic: '',
              doctor: '',
              time: {
                time: null,
                quota: 0,
              },
              isSick: '',
            };
            this.patient = {
              name: '',
              nickname: '',
              gender: '',
              birth: {
                date: '',
                place: '',
              },
              address: {
                city: '',
                district: '',
                province: '',
                sub_district: '',
                text: '',
              },
              identity: {
                type: '',
                number: '',
              },
              religion: '',
              blood_type: '',
              education: '',
              profession: '',
              martial_status: '',
              father: '',
              mother: '',
              phone_number: '',
              assurance: {
                type: '',
                name: '',
                number: '',
                isProlanis: '',
              },
            };
            this.hasGeneralConsent = response.data.data.generalConsentCreated;
            this.selectedForm = 2;
          }
        })
        .catch(error => {
          this.showErrorAxios(error);
          this.loadingButton = false;
        })
        .finally(() => {
          this.emitMessageCall({ queue_code: null, room: null });
        });
    },
    async getCity() {
      const provId = this.patient.address.province.id;
      this.isLoading = true;
      await axios
        .get(
          `${Constant.apiUrl}/master/region/regency?province=${provId}&search=`,
        )
        .then(response => {
          var cityLoc = response.data.data;
          this.input.city = cityLoc.map(city => {
            return {
              id: city._id.toString(),
              name: city.name,
            };
          });
        })
        .catch(() => {
          this.input.city = [];
        })
        .finally(() => (this.isLoading = false));
    },
    async getDistrict() {
      const cityId = this.patient.address.city.id;
      this.isLoading = true;
      await axios
        .get(`${Constant.apiUrl}/master/region/district?regency=${cityId}`)
        .then(response => {
          if (response.status === 200) {
            var districtLoc = response.data.data;
            this.input.district = districtLoc.map(district => {
              return {
                id: district._id.toString(),
                name: district.name,
              };
            });
          }
        })
        .finally(() => (this.isLoading = false));
    },
    async getSubDistrict() {
      const districtId = this.patient.address.district.id;
      this.isLoading = true;
      await axios
        .get(
          `${Constant.apiUrl}/master/region/subdistrict?district=${districtId}`,
        )
        .then(response => {
          if (response.status === 200) {
            var subdistrictLoc = response.data.data;
            this.input.sub_district = subdistrictLoc.map(subdistrict => {
              return {
                id: subdistrict._id.toString(),
                name: subdistrict.name,
              };
            });
          }
        })
        .finally(() => (this.isLoading = false));
    },
    async getQueueRemain() {
      if (this.selectedForm !== 0) return;
      let total = 0;
      this.quotaLoading = true;
      await axios
        .post(Constant.apiUrl.concat('/queue/quota'), {
          unit: this.registration.polyclinic?.unitId,
          doctor: this.registration.doctor?.doctorId,
          time: this.registration.time.time,
          date: this.registration.visitDate.date,
        })
        .then(res => {
          const { data } = res.data;
          total = data;
        })
        .finally(() => {
          this.registration.time.quota = total;
          this.quotaLoading = false;
        });
    },
    onChangeVisitDate() {
      this.registration.time.time = this.registration.visitDate.time[0].time;
    },
    getScheduleDoctor() {
      this.scheduleLoading = true;
      this.time = [];
      axios
        .post(Constant.apiUrl.concat('/schedule/dropdown'), {
          id_unit: this.registration.polyclinic?.unitId,
          id_staff: this.registration.doctor?.doctorId,
        })
        .then(response => {
          if (response.status == 200) {
            let [allSchedule] = response.data.data;
            let [schedule] = allSchedule.schedule.filter(
              x =>
                moment(x.date, 'YYYY-MM-DD').format('YYYY/MM/DD') ===
                this.today,
            );
            this.days = allSchedule.schedule.map(i => {
              return {
                text: moment(i.date, 'YYYY-MM-DD')
                  .locale('id')
                  .format('dddd, Do MMMM YYYY'),
                value: moment(i.date, 'YYYY-MM-DD').format('YYYY/MM/DD'),
                date: i.date,
                time: i.schedule
                  .sort((a, b) => {
                    return a.startTime.localeCompare(b.startTime);
                  })
                  .map(date => {
                    return {
                      time: `${date.startTime} - ${date.endTime}`,
                    };
                  }),
              };
            });
            if (schedule) {
              this.registration.visitDate = {
                text: moment(schedule.date, 'YYYY-MM-DD')
                  .locale('id')
                  .format('dddd, Do MMMM YYYY'),
                value: moment(schedule.date, 'YYYY-MM-DD').format('YYYY/MM/DD'),
                date: schedule.date,
                time: schedule.schedule
                  .sort((a, b) => {
                    return a.startTime.localeCompare(b.startTime);
                  })
                  .map(date => {
                    return {
                      time: `${date.startTime} - ${date.endTime}`,
                    };
                  }),
              };
              for (let i = 0; i < schedule.schedule.length; i++) {
                if (
                  moment().diff(
                    moment(
                      `${schedule.schedule[i].date} ${schedule.schedule[i].endTime}`,
                      'YYYY-MM-DD HH:mm',
                    ),
                    'minutes',
                  ) <= 0
                ) {
                  this.registration.time = {
                    time: `${schedule.schedule[i].startTime} - ${schedule.schedule[i].endTime}`,
                  };
                  break;
                }
              }
            }
            if (!schedule) {
              Swal.fire({
                icon: 'warning',
                title: 'Perhatian',
                text: `${
                  this.registration.doctor.doctorName
                } Tidak Praktik Hari ini, Silakan ${
                  this.isBooking
                    ? 'Cari Jadwal Di lain hari'
                    : 'pilih dokter lain'
                }`,
              });
              this.registration.time = {
                time: '',
                quota: 0,
              };
              this.registration.visitDate = null;
              if (!this.isBooking) this.registration.doctor = '';
            }
          }
        })
        .catch(error => {
          this.showErrorAxios(error);
        })
        .finally(() => {
          this.scheduleLoading = false;
        });
    },
    getDoctorToday() {
      this.doctorLoading = true;
      this.doctorToday = [];
      axios
        .post(Constant.apiUrl.concat('/schedule/'), {
          id_unit: this.registration.polyclinic.unitId,
          startDate: null,
          endDate: this.registration.visitDate?.value || this.today,
          isActive: true,
        })
        .then(response => {
          const data = response.data.data;
          data.map(doctor => {
            this.doctorToday.push(doctor.id_staff);
          });
        })
        .finally(() => {
          this.getDoctor();
          // this.scheduleLoading = false;
        });
    },
    getNoRM() {
      axios
        .get(Constant.apiUrl.concat('/patient/emr/generate'))
        .then(response => {
          this.rmNumber = response.data.data;
        });
    },
    getSocialByRM() {
      try {
        axios
          .get(
            Constant.apiUrl.concat(
              `/patient/emr/social/${this.selectedPatient.rmNumber}`,
            ),
          )
          .then(response => {
            const patient = response.data.data;
            this.patient = {
              ...patient,
              birth: {
                date: moment(patient.birth.date, 'YYYY-MM-DD').format(
                  'DD/MM/YYYY',
                ),
                place: patient.birth.place,
              },
              address: {
                text: patient.address.text,
                province: _.get(patient, 'address.province', ''),
                city: _.get(patient, 'address.city', ''),
                district: _.get(patient, 'address.district', ''),
                sub_district: _.get(patient, 'address.sub_district', ''),
              },
              assurance: {
                ...patient.assurance,
                isBpjs: true,
                referBack: false,
              },
            };
            this.getCity();
            this.getDistrict();
            this.getSubDistrict();
            this.mapInitialDate();
          });
      } catch (error) {
        this.showErrorAxios(error);
      }
    },
    checkActivation(type, no) {
      this.bpjsNotFound = false;
      axios
        .get(Constant.apiUrl.concat(`/integration/pcare/patient/${type}/${no}`))
        .then(response => {
          const dataBPJS = response.data.data;
          this.patient.bpjsData = {
            ...this.patient.bpjsData,
            no: dataBPJS.noKartu,
            name: dataBPJS.nama,
            birthDate: dataBPJS.tglLahir,
            birthDateFormatted: moment(
              dataBPJS.tglLahir,
              'DD-MM-YYYY',
            ).format(),
            age:
              moment().diff(moment(dataBPJS.tglLahir, 'DD/MM/YYYY'), 'years') +
              ' Tahun',
            ktpNo: dataBPJS.noKTP,
          };
          if (type == 'nik') {
            const data = response.data.data;
            this.patient.assurance.number = data.noKartu;
            this.patient.assurance.provider = data.kdProviderPst.nmProvider;
            if (!data.aktif) {
              this.bpjs_error.push('Status Keanggotaan: Tidak Aktif');
              this.handleSnackbar(
                true,
                'No BPJS Tidak Aktif',
                'Anda tidak dapat melakukan pendaftaran dengan No BPJS yang anda input. Silahkan ubah jenis jaminan',
              );
              return;
            } else {
              this.checkNo.bpjs = true;
            }
          } else {
            const data = response.data.data;
            this.patient.assurance.provider = data.kdProviderPst.nmProvider;
            if (!data.aktif) {
              this.bpjs_error.push('Status Keanggotaan: Tidak Aktif');
              this.handleSnackbar(
                true,
                'No BPJS Tidak Aktif',
                'Anda tidak dapat melakukan pendaftaran dengan No BPJS yang anda input. Silahkan ubah jenis jaminan',
              );
              return;
            } else {
              this.checkNo.bpjs = true;
            }
            // }
          }
          this.checkUnmatched();
        })
        .catch(error => {
          if (error.response?.status < 500) {
            if (type == 'nik') {
              this.nik_error.push('Nomer NIK tidak ditemukan');
            } else {
              this.handleSnackbar(
                true,
                'No BPJS Tidak Ditemukan',
                'Anda tidak dapat melakukan pendaftaran dengan No BPJS yang anda input. Silahkan cek kembali atau ubah jenis jaminan',
              );
              this.bpjs_error.push('Nomer BPJS tidak ditemukan');
            }
            this.bpjsNotFound = true;
          } else {
            this.handleSnackbar(
              true,
              'Bridging BPJS Error',
              'Anda masih bisa melanjutkan pendaftaran. Silahkan kirim ulang setelah menyelesaikannya',
            );
            this.pcareServerError = true;
          }
        });
    },
    handleSnackbar(isShow, title = '', text = '') {
      this.snackbar = {
        show: isShow,
        title: title,
        text: text,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.warning-pcare {
  position: absolute;
  top: 7vh;
  background-color: #fff3f3;
  border: 0.5px solid #bdbdbd;
  max-width: 570px;
  padding: 16px 16px 16px 20px;
}

.warning-pcare-text {
  color: #404040;
  font-size: 14px;
  font-style: normal;
  line-height: 21px;
}
.small-dialog {
  position: relative;
  min-height: 50vh;
  .register-container {
    height: 35vh;
  }

  .close-btn {
    position: absolute;
    left: 560px;
    top: 10px;
  }
  .action-footer {
    width: 100%;
  }
}
.input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  font-family: 'Roboto';
  position: relative;

  .form-container {
    height: 85vh;
    overflow: auto;
    width: 100%;
    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 4px;
      background-color: rgb(242, 242, 242);
    }
    &::-webkit-scrollbar-thumb {
      width: 5px;
      border-radius: 4px;
      background-color: rgb(207, 207, 207);
    }
    .divider-line {
      width: 82%;
      height: 1px;
      background-color: rgb(218, 218, 218);
      margin: 1rem auto;
    }
    .title-input {
      font-size: 1.4rem;
      width: 100%;
      margin-left: -15px;
    }
    .grid-display-3 {
      display: grid;
      grid-template-columns: 10rem 15rem;
      row-gap: 10px;
    }
  }
  .action-button {
    height: 38px;
    font-weight: 700;
    background-color: #fff !important;
    color: #3498db;
    border: 2px solid $primary-color;
  }

  .snackbar {
    border: 1px solid green !important;
  }
}
.detail-input {
  display: flex;
  align-items: baseline;
  &__label {
    text-align: left;
    display: grid;
    grid-template-columns: 180px;
    color: #222;
  }
  &__input-text {
    width: 40px;
    justify-content: flex-end;
    font-size: 0.9rem;
  }
  &__input-radio {
    justify-content: flex-start;
    margin-right: 0;
  }
}
::v-deep .v-dialog:not(.v-dialog--fullscreen) {
  max-height: 95%;
}
.required:after {
  content: ' *';
  color: red;
}

@media screen and (min-width: 1024px) {
  .input-container {
    .row-layout {
      .grid-display-3 {
        grid-template-columns: 8rem 20rem;
      }
    }
  }
}

@media screen and (min-width: 1366px) {
  .input-container {
    .row-layout {
      .grid-display-3 {
        grid-template-columns: 8rem 17.5rem;
      }
    }
  }
}

@media screen and (min-width: 1703px) {
  .input-container {
    .row-layout {
      .grid-display-3 {
        grid-template-columns: 10rem 18rem;
      }
    }
  }
}

@media screen and (min-width: 1904px) {
  .input-container {
    .row-layout {
      .title-input {
        width: 50%;
        padding-left: 40px;
      }
      .col-layout {
        display: flex;
        justify-content: space-around;
        margin-left: -50px;
      }
      .grid-display-3 {
        grid-template-columns: 8rem 17rem;
      }
    }
  }
}
</style>
