import Constant from '@/const';
import axios from 'axios';

const addFactory = async payload => {
  try {
    const response = await axios.post(
      Constant.apiUrl.concat('/master/factory'),
      payload,
    );
    return response.data.data;
  } catch (error) {
    throw new Error(error);
  }
};

export default addFactory;
