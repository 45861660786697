<template>
  <div class="patientbilling">
    <v-toolbar flat min-height="110">
      <v-container fluid class="pa-0">
        <v-row no-gutters align="center" class="d-flex justify-space-between">
          <!-- <div> -->
          <v-toolbar-title class="text-h5 font-weight-bold text-left">
            Tagihan Pasien
          </v-toolbar-title>
          <div class="d-flex flex-row">
            <div class="d-flex justify-center">
              <v-btn
                medium
                outlined
                color="primary"
                class="pa-2 caption font-weight-bold"
                @click="exportBilling()"
              >
                <v-icon medium>mdi-download</v-icon>
                Export
              </v-btn>
            </div>
            <div class="ml-4">
              <div class="caption font-weight-medium text-right">
                {{ patientRegistered }} Pasien terdaftar,
                {{ patientServed }} Pasien terlayani
              </div>
              <div class="caption font-weight-medium text-right">
                {{ paid.paid_off }} Tagihan lunas, {{ paid.not_paid }} Tagihan
                belum lunas
              </div>
            </div>
          </div>
          <!-- </div> -->
        </v-row>
        <div class="filter-header mt-4" :class="{ 'mb-6': tabs === 2 }">
          <div>
            <v-autocomplete
              placeholder="Tipe Penjamin"
              v-model="assuranceType"
              append-icon="mdi-chevron-down"
              outlined
              :items="assurance"
              v-if="tabs !== 2"
              dense
              clearable
              hide-details="false"
              @change="showAllBilling"
            >
            </v-autocomplete>
          </div>
          <div>
            <v-autocomplete
              hide-details="false"
              dense
              outlined
              item-text="name"
              return-object
              placeholder="Pilih Unit"
              append-icon="mdi-chevron-down"
              v-model="unitType"
              :items="unit"
              v-if="tabs !== 2"
              @change="showAllBilling"
              clearable
            >
            </v-autocomplete>
            <v-autocomplete
              hide-details="false"
              dense
              outlined
              return-object
              placeholder="Status Pembayaran"
              v-model="status"
              :items="resourceStatus"
              append-icon="mdi-chevron-down"
              v-if="tabs === 2"
              @click:clear="showAllBilling()"
              @change="showAllBilling"
              clearable
            >
            </v-autocomplete>
          </div>
          <div class="d-flex flex-direction-column">
            <v-menu
              class="ma-0 pa-0"
              ref="menu"
              v-if="tabs !== 2"
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              min-width="290px"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  append-icon="mdi-chevron-down"
                  v-model="dateRange.billing.formatted"
                  :value="dateRange.billing.formatted"
                  outlined
                  placeholder="Rentang Tanggal"
                  @click:clear="clearDate()"
                  class="input text-left"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                :max="today"
                multiple
                v-model="selectedDateBilling"
                @input="changedate"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="
                    menu2 = false;
                    datepicker = false;
                    menu2 = false;
                  "
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="
                    menu2 = false;
                    showAllBilling();
                    getPaidOff();
                    getServedCount();
                    $refs.menu.save(date);
                  "
                >
                  OK
                </v-btn></v-date-picker
              >
            </v-menu>
            <v-menu
              v-if="tabs === 2"
              offset-y
              :close-on-content-click="false"
              v-model="menu2"
              ref="menu"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="mr-4 input-filter date"
                  dense
                  flat
                  v-on="on"
                  v-bind="attrs"
                  readonly
                  append-icon="mdi-chevron-down"
                  placeholder="Rentang Tanggal"
                  v-model="dateRange.otc.formatted"
                  :value="dateRange.otc.formatted"
                  single-line
                  clearable
                  @change="getOtcData()"
                  @click:clear="clearDate()"
                  hide-details
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker
                multiple
                :max="today"
                v-model="selectedDate"
                @input="changedate"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="
                    datepicker = false;
                    menu2 = false;
                  "
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="
                    menu2 = false;
                    showAllBilling();
                    $refs.menu.save(date);
                  "
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
            <v-btn small text fab>
              <router-link :to="routeBack" active>
                <v-icon>mdi-home</v-icon>
              </router-link>
            </v-btn>
            <v-btn class="ma-1" color="grey" icon text small @click="refresh()">
              <v-icon :class="{ refresh: isRefresh }">mdi-autorenew</v-icon>
            </v-btn>
            <v-text-field
              background-color="grey lighten-3"
              placeholder="Search"
              solo
              dense
              hide-details
              flat
              prepend-inner-icon="mdi-magnify"
              single-line
              v-model="search"
            ></v-text-field>
          </div>
        </div>
      </v-container>
    </v-toolbar>
    <v-main>
      <v-tabs v-model="tabs" center-active grow>
        <v-tab
          class="font-weight-bold text-capitalize border-bottom"
          @click="selectedTab = 'wadiah'"
          @change="showAllBilling()"
          >Wadiah</v-tab
        >
        <v-tab
          class="font-weight-bold text-capitalize border-bottom"
          @click="selectedTab = 'tagihan'"
          @change="showAllBilling()"
          >Tagihan Pasien</v-tab
        >
        <v-tab
          class="font-weight-bold text-capitalize border-bottom"
          @click="selectedTab = 'otc'"
          @change="showAllBilling()"
          >Penjualan Obat Bebas</v-tab
        >
      </v-tabs>
      <v-tabs-items v-model="tabs">
        <!-- wadiah -->
        <v-tab-item>
          <template>
            <v-data-table
              :headers="wadiah.headers"
              :items="patientBill.item"
              :items-per-page="itemsPerRow"
              :expanded="expanded"
              single-expand
              item-key="registNumber"
              @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
              hide-default-footer
              :page.sync="page"
              :loading="isLoading"
              class="mt-1"
            >
              <template v-slot:[`item.no`]="{ item, index }">
                <tr class="text-left" :key="index">
                  <td>
                    {{ item.no + 1 }}
                  </td>
                </tr>
              </template>

              <template v-slot:[`item.assurance`]="{ item, index }">
                <tr class="text-left" :key="index">
                  <td>{{ item.assurance.toUpperCase() }}</td>
                </tr>
              </template>

              <template v-slot:[`item.balance`]="{ item, index }">
                <tr class="text-left" :key="index">
                  <td>{{ currency(item.balance || 0) }}</td>
                </tr>
              </template>

              <template v-slot:[`item.isPaid`]="{ item, index }">
                <tr class="text-left" :key="index">
                  <td>
                    <v-chip
                      small
                      caption
                      class="text-sm-center status-chip"
                      :class="{
                        paid: item.isPaid === true,
                        unpaid: item.isPaid === false,
                      }"
                      >{{ item.isPaid ? 'Lunas' : 'Belum Lunas' }}</v-chip
                    >
                  </td>
                </tr>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="expand px-0">
                  <v-card flat>
                    <v-row no-gutters class="detail-info">
                      <div class="text-left pl-11 ml-6 grey--text caption">
                        <span
                          >Terdaftar {{ item.time }} WIB <br />
                          {{ item.date }}</span
                        >
                      </div>
                      <div class="text-left ml-4 grey--text caption">
                        <span>{{ item.gender }}</span>
                      </div>
                      <div class="text-left grey--text caption">
                        <span> • {{ item.age }} Tahun</span>
                      </div>
                    </v-row>
                  </v-card>

                  <v-card flat color="grey lighten-4" class="text-right">
                    <v-container>
                      <v-row no-gutters>
                        <v-col cols="12">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                x-small
                                icon
                                class="mx-2"
                                v-bind="attrs"
                                v-on="on"
                                @click="socialDataDialog = true"
                              >
                                <v-icon>mdi-account</v-icon>
                              </v-btn>
                            </template>
                            <span>Data Sosial</span>
                          </v-tooltip>
                          <v-dialog
                            v-model="socialDataDialog"
                            hide-overlay
                            transition="scale-transition"
                          >
                            <social-data
                              v-if="socialDataDialog && item"
                              :medicalRecord="item.rm_number"
                              @close-form="closeForm"
                            />
                          </v-dialog>

                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                x-small
                                icon
                                class="mx-2"
                                v-bind="attrs"
                                v-on="on"
                                @click="billingDialog = true"
                              >
                                <v-icon>mdi-receipt</v-icon>
                              </v-btn>
                            </template>
                            <span>Tagihan Pasien</span>
                          </v-tooltip>
                          <!-- :persistent="isPersistent" -->
                          <v-dialog
                            persistent
                            v-model="billingDialog"
                            fullscreen
                            transition="scale-transition"
                            hide-overlay
                          >
                            <dialog-billing
                              v-if="billingDialog && item"
                              :billingData="item"
                              @close-form="closeForm()"
                              @close-detail="closeForm()"
                            />
                          </v-dialog>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </td>
              </template>
            </v-data-table>
          </template>
        </v-tab-item>
        <!-- tagihan pasien -->
        <v-tab-item>
          <v-data-table
            :headers="patientBill.headers"
            :items="patientBill.item"
            :items-per-page="itemsPerRow"
            hide-default-footer
            @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
            item-key="registNumber"
            :expanded="expanded"
            single-expand
            :loading="isLoading"
            style="overflow: hidden"
            class="mt-1"
          >
            <template v-slot:[`item.no`]="{ item, index }">
              <tr class="text-left" :key="index">
                <td>
                  {{ item.no + 1 }}
                </td>
              </tr>
            </template>
            <template v-slot:[`item.assurance`]="{ item, index }">
              <tr class="text-left" :key="index">
                <td>{{ item.assurance.toUpperCase() }}</td>
              </tr>
            </template>

            <template v-slot:[`item.bill`]="{ item }">
              <tr>
                <td>
                  {{
                    Math.sign(item.bill) === -1 ? 0 : currency(item.bill || 0)
                  }}
                </td>
              </tr>
            </template>
            <template v-slot:[`item.isPaid`]="{ item, index }">
              <tr class="text-left" :key="index">
                <td>
                  <v-chip
                    small
                    caption
                    class="text-sm-center status-chip"
                    :class="{
                      paid: item.isPaid === true,
                      unpaid: item.isPaid === false,
                    }"
                    >{{ item.isPaid ? 'Lunas' : 'Belum Lunas' }}</v-chip
                  >
                </td>
              </tr>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="expand px-0">
                <v-card flat>
                  <v-row align="start" no-gutters>
                    <v-col
                      cols="2"
                      class="text-left pl-11 ml-6 grey--text caption"
                    >
                      <span
                        >Terdaftar {{ item.time }} WIB <br />
                        {{ item.date }}</span
                      >
                    </v-col>
                    <v-col
                      cols="2"
                      class="text-left ml-3 pl-9 grey--text caption"
                    >
                      <span>{{ item.gender }} {{ item.age }} Tahun</span>
                    </v-col>
                    <!-- <v-col cols="7" class="text-right">
                      <v-chip
                        small
                        class="ma-2"
                        :color="item.isPaid ? 'green' : 'red'"
                        text-color="white"
                      >
                        {{ item.isPaid ? 'Lunas' : 'Belum Lunas' }}
                      </v-chip>
                    </v-col> -->
                  </v-row>
                </v-card>

                <v-card flat color="grey lighten-4" class="text-right">
                  <v-container>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              x-small
                              icon
                              class="mx-2"
                              v-bind="attrs"
                              v-on="on"
                              @click="socialDataDialog = true"
                            >
                              <v-icon>mdi-account</v-icon>
                            </v-btn>
                          </template>
                          <span>Data Sosial</span>
                        </v-tooltip>
                        <v-dialog
                          v-model="socialDataDialog"
                          transition="scale-transition"
                          hide-overlay
                        >
                          <social-data
                            v-if="socialDataDialog && item"
                            :medicalRecord="item.rm_number"
                            @close-form="closeForm"
                          />
                        </v-dialog>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              x-small
                              icon
                              class="mx-2"
                              v-bind="attrs"
                              v-on="on"
                              @click="billingDialog = true"
                            >
                              <v-icon>mdi-receipt</v-icon>
                            </v-btn>
                          </template>
                          <span>Tagihan Pasien</span>
                        </v-tooltip>
                        <!-- :persistent="isPersistent" -->
                        <v-dialog
                          v-model="billingDialog"
                          fullscreen
                          persistent
                          transition="scale-transition"
                          hide-overlay
                        >
                          <dialog-billing
                            v-if="billingDialog && item"
                            :billingData="item"
                            @close-form="closeForm()"
                            @close-detail="closeForm()"
                          />
                        </v-dialog>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </td>
            </template>
          </v-data-table>
        </v-tab-item>
        <!-- penjualan obat bebas -->
        <v-tab-item>
          <template>
            <v-data-table
              :headers="otc.headers"
              :items="otc.items"
              :items-per-page="itemsPerRow"
              :expanded="expanded"
              single-expand
              item-key="registNumber"
              @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
              hide-default-footer
              :page.sync="page"
              :loading="isLoading"
              class="mt-1"
            >
              <template v-slot:item="{ item, expand, isExpanded }">
                <tr @click="expand(!isExpanded)">
                  <td>{{ item.no + 1 }}</td>
                  <td>{{ item.registNumber }}</td>
                  <td class="text-left">{{ item.name }}</td>
                  <td>{{ item.gender }}</td>
                  <td class="text-left">{{ item.age }} Tahun</td>
                  <td class="text-left">{{ currency(item.amountBilling) }}</td>
                  <td>
                    <v-chip
                      small
                      caption
                      class="text-sm-center status-chip"
                      :class="{
                        unpaid: !item.isPaid,
                        paid: item.isPaid,
                      }"
                      >{{ item.isPaid ? 'Lunas' : 'Belum Lunas' }}</v-chip
                    >
                  </td>
                </tr>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="expand px-0">
                  <v-card class="py-3" flat>
                    <v-row no-gutters>
                      <div class="text-left pl-11 ml-6 grey--text caption">
                        <span
                          >Terdaftar {{ item.date }} {{ item.time }} WIB</span
                        >
                      </div>
                    </v-row>
                  </v-card>

                  <v-card flat color="grey lighten-4" class="text-right">
                    <v-container>
                      <v-row no-gutters>
                        <v-col cols="12">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                x-small
                                icon
                                class="mx-2"
                                v-bind="attrs"
                                v-on="on"
                                @click="overCounterDialog = true"
                              >
                                <v-icon>mdi-receipt</v-icon>
                              </v-btn>
                            </template>
                            <span>Tagihan Pasien</span>
                          </v-tooltip>
                          <v-dialog
                            v-model="overCounterDialog"
                            fullscreen
                            transition="scale-transition"
                            hide-overlay
                          >
                            <OverCounterBilling
                              v-if="overCounterDialog && tabs === 2"
                              @close-form="closeForm"
                              :idBilling="item.id"
                              :isPaid="item.isPaid"
                            />
                          </v-dialog>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </td>
              </template>
            </v-data-table>
          </template>
        </v-tab-item>
      </v-tabs-items>
    </v-main>
    <v-footer color="white">
      <div class="text-center">
        <v-pagination
          v-model="patientBill.pagination.page"
          :length="lengthPage"
          :total-visible="5"
          v-if="tabs !== 2"
          @input="showAllBilling()"
        ></v-pagination>
        <v-pagination
          v-else
          v-model="otc.pagination.page"
          :length="lengthPageOTC"
          :total-visible="5"
          @input="showAllBilling()"
        ></v-pagination>
      </div>
    </v-footer>
  </div>
</template>

<script>
import DialogBilling from '../SharedComponent/Dialog/DialogBilling.vue';
import SocialData from '../SharedComponent/SocialData.vue';
import OverCounterBilling from './OverCounter/OverCounterBilling.vue';
import Constant from '@/const';
import moment from 'moment-timezone';
import alertMixin from '@/mixin/alertMixin';
import Swal from 'sweetalert2';
import formatMixin from '@/mixin/formatMixin';
import jwtMixin from '@/mixin/jwtMixin';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'PatientBilling',
  mixins: [jwtMixin, alertMixin, formatMixin],
  components: { DialogBilling, OverCounterBilling, SocialData },
  data() {
    return {
      isPersistent: true,
      isRefresh: false,
      expanded: [],
      menu2: false,
      socialDataDialog: false,
      billingDialog: false,
      overCounterDialog: false,
      tabs: null,
      paid: {
        paid_off: 0,
        not_paid: 0,
      },
      assuranceType: '',
      status: '',
      resourceStatus: [
        {
          text: 'Lunas',
          value: 1,
        },
        {
          text: 'Belum Lunas',
          value: 0,
        },
      ],
      assurance: [
        { text: 'Umum', value: 'umum' },
        { text: 'BPJS', value: 'bpjs' },
        { text: 'Asuransi', value: 'asuransi' },
      ],
      unitType: {
        name: '',
        id: '',
      },
      unit: [],
      patientBill: {
        headers: [
          {
            text: 'No',
            align: 'start',
            sortable: false,
            value: 'no',
          },
          { text: 'No. Registrasi', value: 'registNumber' },
          { text: 'Nama', value: 'name' },
          { text: 'No. RM', value: 'rm_number' },
          { text: 'Tipe Penjamin', value: 'assurance' },
          { text: 'Unit', value: 'unit' },
          { text: 'Tagihan', value: 'bill' },
          { text: 'Status', value: 'isPaid' },
        ],
        item: [],
        pagination: {
          descending: false,
          page: 1,
          rowsPerPage: 7,
          sortBy: '',
        },
      },
      wadiah: {
        headers: [
          {
            text: 'No',
            align: 'start',
            sortable: false,
            value: 'no',
          },
          { text: 'No. Registrasi', value: 'registNumber' },
          { text: 'Nama', value: 'name' },
          { text: 'No. RM', value: 'rm_number' },
          { text: 'Tipe Penjamin', value: 'assurance' },
          { text: 'Unit', value: 'unit' },
          { text: 'Saldo', value: 'balance' },
          { text: 'Status', value: 'isPaid' },
        ],
        item: [],
        pagination: {
          descending: false,
          page: 1,
          rowsPerPage: 7,
          sortBy: '',
        },
      },
      otc: {
        headers: [
          {
            text: 'No',
            align: 'center',
            sortable: false,
            value: 'no',
            width: '5%',
          },
          {
            text: 'No. Registrasi',
            value: 'registNumber',
            sortable: false,
            width: '10%',
          },
          {
            text: 'Nama',
            align: 'start',
            sortable: false,
            value: 'name',
            width: '30%',
          },
          {
            text: 'Jenis Kelamin',
            value: 'gender',
            sortable: false,
            align: 'center',
          },
          { text: 'Usia', sortable: false, value: 'age', align: 'start' },
          {
            text: 'Jumlah Tagihan',
            value: 'amountBilling',
            sortable: false,
            align: 'start',
          },
          {
            text: 'Status',
            value: 'isPaid',
            align: 'center',
            sortable: false,
          },
        ],
        items: [],
        pagination: {
          descending: false,
          page: 1,
          rowsPerPage: 7,
          sortBy: '',
        },
      },
      lengthPage: 0,
      lengthPageOTC: 0,
      page: 1,
      selectedTab: 'tagihan',
      search: '',
      isLoading: false,
      patientRegistered: '',
      patientServed: '',
      dateRange: {
        otc: {
          raw: [moment().format('DD-MM-YYYY'), ''],
          formatted: moment().format('DD-MM-YYYY'),
        },
        billing: {
          raw: [moment().format('DD-MM-YYYY'), ''],
          formatted: moment().format('DD-MM-YYYY'),
        },
      },
      selectedDate: [],
      selectedDateBilling: [],
      isPaid: null,
    };
  },
  watch: {
    date() {
      this.showAllBilling();
      this.getPaidOff();
      this.getServedCount();
    },
    search() {
      this.searchData(this);
    },
    status(value) {
      if (!value) {
        this.showAllBilling();
      }
    },
  },
  beforeMount() {
    this.tabs = 1;
  },
  mounted() {
    this.getPaidOff();
    this.getServedCount();
    this.getUnit();
    this.$nextTick(() => {
      this.refreshAndAutoUpdate();
    });
  },
  computed: {
    routeBack() {
      return '/keuangan/billing';
    },
    today() {
      return moment().format();
    },
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
  },
  methods: {
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.showAllBilling();
    }, 650),
    refresh() {
      this.isRefresh = true;
      this.showAllBilling();
      this.getServedCount();
      this.getPaidOff();
    },
    clearDate() {
      this.dateRange.otc.raw = [];
      this.selectedDate = [];
      this.dateRange.billing.raw = [];
      this.selectedDateBilling = [];
      this.showAllBilling();
      this.getServedCount();
      this.getPaidOff();
    },
    changedate(data) {
      // if user selected 2 date
      if (data.length >= 2) {
        const slicedDate = data.slice(0, 2);
        slicedDate.sort((a, b) => {
          return new Date(a).getTime() - new Date(b).getTime();
        });

        const formateddate = [];
        for (let i = 0; i < slicedDate.length; i++) {
          formateddate.push(
            moment(slicedDate[i], 'YYYY-MM-DD').format('DD-MM-YYYY'),
          );
        }
        if (this.tabs === 2) {
          this.selectedDate = slicedDate;
          this.dateRange.otc = {
            raw: [
              moment(slicedDate[0], 'YYYY-MM-DD').format('DD-MM-YYYY'),
              moment(slicedDate[1], 'YYYY-MM-DD').format('DD-MM-YYYY'),
            ],
            formatted: formateddate.join(' - '),
          };
        }
        if (this.tabs !== 2) {
          this.selectedDateBilling = slicedDate;
          this.dateRange.billing = {
            raw: [
              moment(slicedDate[0], 'YYYY-MM-DD').format('DD-MM-YYYY'),
              moment(slicedDate[1], 'YYYY-MM-DD').format('DD-MM-YYYY'),
            ],
            formatted: formateddate.join(' - '),
          };
        }
        // this.datepicker = false;
      }

      // if user selected 1 date
      if (this.tabs === 2) {
        if (data.length === 1) {
          this.dateRange.otc = {
            raw: [moment(data[0], 'YYYY-MM-DD').format('DD-MM-YYYY'), ''],
            formatted: moment(data[0], 'YYYY-MM-DD').format('DD-MM-YYYY'),
          };
        }
      }
      if (this.tabs !== 2) {
        if (data.length === 1) {
          this.dateRange.billing = {
            raw: [moment(data[0], 'YYYY-MM-DD').format('DD-MM-YYYY'), ''],
            formatted: moment(data[0], 'YYYY-MM-DD').format('DD-MM-YYYY'),
          };
        }
      }
    },
    async showAllOverCounterBilling(itemCount, page) {
      try {
        const response = await axios.get(
          Constant.apiUrl.concat(
            `/billing/drugssales?page=${page}&itemCount=${itemCount}&search=${this
              .search || ''}&isPaid=${
              this.status?.value === 0 || this.status?.value === 1
                ? this.status?.value
                : ''
            }&startDate=${this.dateRange.otc.raw[0] || ''}&endDate=${this
              .dateRange.otc.raw[1] || ''}`,
          ),
        );
        const { data } = response.data;
        const { length } = response.data;
        this.lengthPageOTC = Math.ceil(length / this.itemsPerRow);
        this.otc.items = data.map((bill, index) => {
          return {
            no: index + this.itemsPerRow * page - this.itemsPerRow,
            id: bill._id,
            name: _.get(bill, 'buyer_data.buyer_name', '-'),
            gender: _.get(bill, `buyer_data.buyer_gender`, '-')
              ? 'Laki-laki'
              : 'Perempuan',
            age: _.get(bill, 'buyer_data.buyer_age', '-'),
            amountBilling: _.get(bill, 'remaining_bill', '-'),
            registNumber: _.get(bill, 'transaction_code', '-'),
            isPaid: _.get(bill, 'is_paid', '-'),
            date: moment(_.get(bill, 'order_date', '-')).format('DD/MM/YYYY'),
            time: moment(_.get(bill, 'order_date', '-')).format('HH:mm'),
          };
        });
      } catch {
        this.otc.items = [];
      } finally {
        this.isLoading = false;
        this.isRefresh = false;
      }
    },
    async exportBilling() {
      try {
        const res = await axios.get(
          Constant.apiUrl.concat(
            `/billing/billing_component?startDate=${this.dateRange.billing
              .raw[0] || ''}&endDate=${this.dateRange.billing.raw[1] || ''}`,
          ),
        );
        if (res.status === 200) {
          this.showFillSuccess('Export Berhasil');
          const { url } = res.config;
          window.open(url, '_blank');
        }
      } catch (error) {
        if (error) {
          Swal.fire({
            icon: 'error',
            title: 'Terjadi Kesalahan',
            text: 'Tidak dapat mengexport dokumen',
          });
        }
      }
    },
    closeForm() {
      this.socialDataDialog = false;
      this.billingDialog = false;
      this.overCounterDialog = false;
      setTimeout(() => this.showAllBilling(), 500);
      this.getServedCount();
      this.getPaidOff();
    },
    getUnit() {
      axios
        .get(
          Constant.apiUrl.concat(
            '/master/unit?page=1&itemCount=&search=&sort=name&installation=Instalasi Rawat Jalan',
          ),
        )
        .then(response => {
          if (response.status === 200) {
            this.unit = response.data.data.map(unit => {
              return { name: unit.name, id: unit._id };
            });
          }
        });
    },
    getServedCount() {
      let end;
      const start = this.dateRange?.billing?.raw[0]
        ? moment(this.dateRange.billing.raw[0], 'DD-MM-YYYY').format(
            'YYYY-MM-DD',
          )
        : '';
      if (this.dateRange?.billing?.raw[0] && !this.dateRange?.billing?.raw[1]) {
        end = moment(this.dateRange.billing.raw[0], 'DD-MM-YYYY').format(
          'YYYY-MM-DD',
        );
      } else {
        end = this.dateRange?.billing?.raw[1]
          ? moment(this.dateRange.billing.raw[1], 'DD-MM-YYYY').format(
              'YYYY-MM-DD',
            )
          : '';
      }
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/patient/registration/count?startDate=${start}&endDate=${end}`,
            ),
          )
          .then(response => {
            const data = response.data.data;
            this.patientRegistered =
              data.servicedPatient + data.unservicedPatient;
            this.patientServed = data.servicedPatient;
          })
          .catch(() => {
            this.patientRegistered = 0;
            this.patientServed = 0;
          })
          .finally(() => {
            this.serveLoading = false;
          });
      });
    },
    getPaidOff() {
      let end;
      const start = this.dateRange?.billing?.raw[0]
        ? moment(this.dateRange.billing.raw[0], 'DD-MM-YYYY').format(
            'YYYY-MM-DD',
          )
        : '';
      if (this.dateRange?.billing?.raw[0] && !this.dateRange?.billing?.raw[1]) {
        end = moment(this.dateRange.billing.raw[0], 'DD-MM-YYYY').format(
          'YYYY-MM-DD',
        );
      } else {
        end = this.dateRange?.billing?.raw[1]
          ? moment(this.dateRange.billing.raw[1], 'DD-MM-YYYY').format(
              'YYYY-MM-DD',
            )
          : '';
      }
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/billing/paid-off?startDate=${start || ''}&endDate=${end || ''}`,
            ),
          )
          .then(response => {
            const data = response.data.data;
            this.paid = {
              paid_off: data.isPaid,
              not_paid: data.notPaid,
            };
          })
          .catch(() => {
            this.paid = {
              paid_off: 0,
              not_paid: 0,
            };
          });
      });
    },
    showAllBilling() {
      this.isLoading = true;
      const itemCount = this.itemsPerRow;
      const halaman = this.patientBill.pagination.page;
      const halamanotc = this.otc.pagination.page;
      if (this.selectedTab === 'otc') {
        this.showAllOverCounterBilling(itemCount, halamanotc); // get over counter billing
        return;
      }
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/billing/all?page=${halaman}&itemCount=${itemCount}&search=${
                this.search
              }&startDate=${this.dateRange.billing.raw[0] || ''}&endDate=${this
                .dateRange.billing.raw[1] || ''}&assurance=${this
                .assuranceType || ''}&unit_name=${this.unitType?.name || ''}`,
            ),
          )
          .then(response => {
            const data = response.data.data;
            const { length: dataLength } = response.data;
            this.lengthPage = Math.ceil(dataLength / itemCount);

            this.patientBill.item = data.map((bill, idx) => {
              return {
                no: idx + this.itemsPerRow * halaman - this.itemsPerRow,
                id: bill._id,
                name: _.get(bill, 'id_emr.social[0].name', '-'),
                registNumber: _.get(
                  bill,
                  'id_registration.registration_number',
                  '-',
                ),
                rm_number: _.get(bill, 'id_emr.medical_record_number', '-'),
                assurance: _.get(bill, 'id_registration.assurance.type', '-'),
                balance: _.get(bill, 'total_wadiah', '-'),
                bill: _.get(bill, 'remaining_bill', '-'),
                unit: _.get(bill, 'id_registration.id_unit.name', '-'),
                status: bill.id_registration ? 'Pasien Terdaftar' : '',
                isPaid: bill.isPaid,
                gender: _.get(bill, `id_emr.social[0].gender`, '-')
                  ? 'Laki-laki'
                  : 'Perempuan',
                age: _.get(bill, 'id_emr.social[0].age', '-'),
                date: bill.id_registration
                  ? moment(bill.id_registration.timestamps.created_at)
                      .locale('id')
                      .format('l')
                  : '-',
                time: bill.id_registration
                  ? moment(bill.id_registration.timestamps.created_at)
                      .locale('id')
                      .format('HH:mm')
                  : '-',
                isWadiah: this.selectedTab === 'wadiah',
              };
            });
            this.isPaid = this.patientBill.item.filter(x => x.isPaid).length;
          })
          .catch(() => {
            this.patientBill.item = [];
          })
          .finally(() => {
            this.isLoading = false;
            this.isRefresh = false;
          });
      });
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.showAllBilling();
      this.getServedCount();
      this.getPaidOff();
      this.interval = setInterval(this.showAllBilling, 60000);
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.refresh {
  animation: rotation 0.23s infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.detail-info {
  max-width: 400px;
  grid-template-columns: 0.7fr 0.3fr 0.4fr;
  display: grid;
}

.patientbilling {
  padding: 3px 24px;
}

.status-chip {
  min-width: 100px;
  font-weight: 400;
  font-size: 14px;
  justify-content: center;
  border-radius: 2px;

  &.paid {
    background-color: #e5fff0 !important;
    border: 0.5px solid #2d965a !important;
    color: #2d965a !important;
  }

  &.unpaid {
    background-color: #fff2f2 !important;
    border: 0.5px solid #eb4747 !important;
    color: #eb4747 !important;
  }
}

.border-bottom {
  border-bottom: 2px solid #f5f5f5 !important;
}

::v-deep .v-data-table-header {
  background-color: #f4f5f5;
  border-bottom-color: #9ca2a5;

  span {
    color: black;
  }
}

.filter-header {
  margin: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  column-gap: 16px;
}

::v-deep {
  .v-toolbar__content {
    height: 140px !important;
    padding: 0px;
  }
}

::v-deep {
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border-bottom: 2 px solid #ececec;
  }
}

::v-deep {
  .v-data-table__wrapper {
    overflow: hidden;
  }
}

::v-deep .v-dialog--active.dialogPrint {
  width: 450px;
  box-shadow: none !important;
}

::v-deep .v-dialog:not(.v-dialog--fullscreen) {
  max-height: 95%;
}
</style>
