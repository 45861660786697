<template>
  <div>
    <v-toolbar flat class="pb-3">
      <v-col lg="7" md="5" class="text-left">
        <h2 class="font-weight-bold pt-2">Jadwal Dokter</h2>
      </v-col>
      <v-col md="2">
        <v-menu
          ref="menuDate"
          v-model="menuDate"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="300px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dateFormatted"
              outlined
              prepend-inner-icon="mdi-calendar-month"
              dense
              hide-details
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" scrollable @input="menuDate = false">
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col md="1" class="px-0">
        <v-btn outlined depressed @click="filterOpen()" color="#3F484A">
          <img src="@/assets/image/ic_filter.png" class="mr-2" />
          <span class="black--text text-capitalize">Filters</span>
        </v-btn>
      </v-col>
      <v-col class="d-flex justify-end">
        <v-btn
          to="jadwal-dokter/pengaturan/ubah-jadwal"
          dark
          color="primary"
          depressed
        >
          <span class="font-weight-medium text-capitalize">Reschedule</span>
        </v-btn>
        <v-btn
          outlined
          color="primary"
          depressed
          class="setting-btn"
          tile
          to="jadwal-dokter/pengaturan"
        >
          <v-icon class="pa-0" color="primary">mdi-cog</v-icon>
        </v-btn>
      </v-col>
    </v-toolbar>
    <template v-if="filter_open === true">
      <v-row style="max-width: 1270px" class="pl-6 pr-3 pt-2">
        <v-col cols="1" class="pb-0 pt-0">
          <v-checkbox
            label="Senin"
            class="pa-0 mr-1"
            color="success"
            value="Senin"
            v-model="filter.monday"
          ></v-checkbox>
        </v-col>
        <v-col cols="1" class="pb-0 pt-0">
          <v-checkbox
            label="Selasa"
            class="pa-0 mr-1"
            color="success"
            value="Selasa"
            v-model="filter.tuesday"
          ></v-checkbox>
        </v-col>
        <v-col cols="1" class="pb-0 pt-0">
          <v-checkbox
            label="Rabu"
            class="pa-0 mr-1"
            color="success"
            value="Rabu"
            v-model="filter.wednesday"
          ></v-checkbox>
        </v-col>
        <v-col cols="1" class="pb-0 pt-0">
          <v-checkbox
            label="Kamis"
            class="pa-0 mr-1"
            color="success"
            value="Kamis"
            v-model="filter.thursday"
          ></v-checkbox>
        </v-col>
        <v-col cols="1" class="pb-0 pt-0">
          <v-checkbox
            label="Jumat"
            class="pa-0 mr-1"
            color="success"
            value="Jumat"
            v-model="filter.friday"
          ></v-checkbox>
        </v-col>
        <v-col cols="1" class="pb-0 pt-0">
          <v-checkbox
            label="Sabtu"
            class="pa-0 mr-1"
            color="success"
            value="Sabtu"
            v-model="filter.saturday"
          ></v-checkbox>
        </v-col>
        <v-col cols="1" class="pb-0 pt-0">
          <v-checkbox
            label="Minggu"
            class="pa-0 mr-1"
            color="success"
            value="Minggu"
            v-model="filter.sunday"
          ></v-checkbox>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="2" class="pb-0 pt-0">
          <v-combobox
            class="line-long mt-0 pt-0"
            :items="resource.poly"
            label="Pilih Poli"
            clearable
            outlined
            dense
            item-value="unitId"
            item-text="unitName"
            v-model="input.poly"
            @change="showAllSchedule"
          ></v-combobox>
        </v-col>
        <v-col cols="2" class="pb-0 pt-0">
          <v-combobox
            class="line-long mt-0 pt-0"
            :items="resource.doctors"
            label="Pilih Dokter"
            outlined
            dense
            item-value="doctorId"
            item-text="doctorName"
            clearable
            v-model="input.doctor"
            @change="showAllSchedule"
          ></v-combobox>
        </v-col>
      </v-row>
    </template>
    <v-row no-gutters class="px-6">
      <v-col cols="5" class="grid">
        <div class="d-flex align-center pa-1">
          <v-icon color="primary">mdi-square-rounded</v-icon>
          <span class="ml-3 mt-1" style="font-size: 0.9rem;">Jadwal Aktif</span>
        </div>
        <div class="d-flex align-center pa-1">
          <v-icon color="green">mdi-square-rounded</v-icon>
          <span class="ml-3 mt-1" style="font-size: 0.9rem;"
            >Jadwal Pengganti</span
          >
        </div>
        <div class="d-flex align-center pa-1">
          <v-icon color="grey">mdi-square-rounded</v-icon>
          <span class="ml-3 mt-1" style="font-size: 0.9rem;"
            >Jadwal Diubah / Libur</span
          >
        </div>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-data-table
      :headers="headerDates"
      :items="resource.schedule"
      :items-per-page="5"
      class="igdTable"
      :loading="isLoading"
    >
      <template slot="item" slot-scope="props">
        <tr>
          <td class="text-left">
            <v-row class="py-1 px-3">
              <label class="font-weight-bold">
                {{ props.item.doctorName }}
              </label>
            </v-row>
            <v-row class="py-1 px-3">
              <label class="caption">{{ props.item.polyclinic }}</label>
            </v-row>
          </td>
          <template v-for="(item, index) in headerDates.slice(1)">
            <td v-bind:key="index" class="pt-2 pb-1">
              <template v-for="(iter, indexTime) in props.item.docSchedule">
                <template v-if="iter.day === item.text">
                  <div
                    :class="
                      iter.isActive && iter.isReschedule
                        ? 'green white--text rounded-lg'
                        : !iter.isActive
                        ? 'grey white--text rounded-lg'
                        : 'blue white--text rounded-lg'
                    "
                    :key="'time' + indexTime"
                  >
                    {{ iter.time }}
                  </div>
                  <div
                    v-if="!iter.isActive"
                    :key="'quota-' + indexTime"
                    class="grey--text"
                  >
                    <span
                      >{{ iter.isReschedule ? 'Jadwal diubah' : 'Libur' }}
                    </span>
                  </div>
                  <div v-else class="red--text" :key="'quota-' + indexTime">
                    {{ iter.patient }}
                  </div>
                </template>
                <template v-else>
                  <div :key="'time-null' + indexTime"></div>
                  <div
                    class="red--text font-weight-bold"
                    :key="'quota-null-' + indexTime"
                  ></div>
                </template>
              </template>
            </td>
          </template>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Constant from '@/const';
import jwtMixin from '@/mixin/jwtMixin';
//import Swal from 'sweetalert2';

const axios = require('axios');
axios.defaults.headers.post['Content-Type'] = 'application/json';

export default {
  name: 'DoctorSchedule',
  mixins: [jwtMixin],
  data() {
    return {
      menuDate: false,
      date: new Date().toISOString().substr(0, 10),
      dateFormatted: '',
      nextWeekdate: '',
      dateArray: [],
      filter_open: false,
      resource: {
        doctor: [],
        poly: [],
        doctors: [],
        schedule: [],
        pagination: {
          descending: false,
          page: 1,
          rowsPerPage: 5,
        },
      },
      input: {
        doctor: '',
        poly: '',
      },
      filter: {
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
      },
      schedulePages: 0,
      isLoading: false,
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.runApiCalls(() => {
        this.nextWeekdate = this.getNextSevenDay(this.date);

        this.dateFormatted = this.formatedDate(this.date, this.nextWeekdate);
        this.dateArray = this.getDates(
          new Date(this.date),
          new Date(this.nextWeekdate),
        );
        this.getPoly();
        this.getDoctor();
        this.showAllSchedule();
        this.watchCollection(
          [
            'filter.monday',
            'filter.tuesday',
            'filter.wednesday',
            'filter.thursday',
            'filter.friday',
            'filter.saturday',
            'filter.sunday',
          ],
          this.filterCheckbox,
        );
      });
    });
  },
  watch: {
    date(newVal) {
      this.nextWeekdate = this.getNextSevenDay(newVal);

      this.dateFormatted = this.formatedDate(newVal, this.nextWeekdate);

      this.dateArray = this.getDates(
        new Date(newVal),
        new Date(this.nextWeekdate),
      );
      this.showAllSchedule();
    },
  },
  computed: {
    headerDates() {
      const headerDefault = [{ text: 'Nama Dokter', value: 'doctorName' }];
      return headerDefault.concat(
        this.dateArray.map(date => {
          return {
            text: date,
            sortable: false,
            align: 'center',
            // width: '120px',
          };
        }),
      );
    },
  },
  methods: {
    filterOpen() {
      this.filter_open = !this.filter_open;
    },
    watchCollection(arr, cb) {
      arr.forEach(val => this.$watch(val, cb));
    },
    getNextSevenDay(date) {
      let startDate = date;
      if (Object.prototype.toString.call(startDate) !== '[object Date]') {
        startDate = new Date(date);
      }
      return new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate() + 7,
      )
        .toISOString()
        .slice(0, 10);
    },
    formatedDate(startDate, finishDate) {
      const monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'Mei',
        'Jun',
        'Jul',
        'Ags',
        'Sep',
        'Okt',
        'Nov',
        'Des',
      ];
      const dateBegin = new Date(startDate);
      const dateEnd = new Date(finishDate);

      const dayBegin = dateBegin.getDate();
      const dayEnd = dateEnd.getDate();

      const monthIndexBegin = dateBegin.getMonth();
      const monthIndexEnd = dateEnd.getMonth();
      const monthNameBegin = monthNames[monthIndexBegin];
      const monthNameEnd = monthNames[monthIndexEnd];

      const yearBegin = dateBegin.getFullYear();
      const yearEnd = dateEnd.getFullYear();

      return `${dayBegin} ${monthNameBegin} ${yearBegin} - ${dayEnd} ${monthNameEnd} ${yearEnd}`;
    },
    getDates(startDate, endDate) {
      const dayInterval = 1000 * 60 * 60 * 24;
      const duration = endDate - startDate;
      const steps = duration / dayInterval;
      const dateArr = Array.from(
        { length: steps + 1 },
        (v, i) => new Date(startDate.valueOf() + dayInterval * i),
      );
      return dateArr.map(date =>
        this.getDayName(date.toISOString().slice(0, 10)),
      );
    },
    getDayName(datetime) {
      const date = new Date(datetime);
      const days = new Array(7);
      days[0] = 'Minggu';
      days[1] = 'Senin';
      days[2] = 'Selasa';
      days[3] = 'Rabu';
      days[4] = 'Kamis';
      days[5] = 'Jumat';
      days[6] = 'Sabtu';
      return `(${date.getDate()}) ${days[date.getDay()]}`;
    },
    getPoly() {
      axios
        .get(Constant.apiUrl.concat('/master/unit?search=&sort=name'))
        .then(response => {
          if (response.status === 200) {
            this.resource.poly = response.data.data
              .filter(v => v.detail.installation === 'Instalasi Rawat Jalan')
              .map(unit => {
                return {
                  unitId: unit._id.toString(),
                  unitName: unit.name,
                };
              });
          }
        });
    },

    getDoctor() {
      axios
        .get(
          Constant.apiUrl.concat(
            '/master/staff?role[]=Dokter&role[]=Bidan&role[]=dokter&role[]=bidan',
          ),
        )
        .then(response => {
          if (response.status === 200) {
            var data = response.data.data;
            this.resource.doctors = data
              .filter(v => v.role.status)
              .map(doctor => {
                return {
                  doctorId: doctor._id.toString(),
                  doctorName: doctor.detail.name,
                };
              });
          }
        });
    },
    filterCheckbox() {
      if (
        this.filter.monday !== null ||
        this.filter.tuesday !== null ||
        this.filter.wednesday !== null ||
        this.filter.thursday !== null ||
        this.filter.friday !== null ||
        this.filter.saturday !== null ||
        this.filter.sunday !== null
      ) {
        this.dateArray = this.getDates(
          new Date(this.date),
          new Date(this.nextWeekdate),
        ).filter(
          day =>
            day.includes(this.filter.monday) ||
            day.includes(this.filter.tuesday) ||
            day.includes(this.filter.wednesday) ||
            day.includes(this.filter.thursday) ||
            day.includes(this.filter.friday) ||
            day.includes(this.filter.saturday) ||
            day.includes(this.filter.sunday),
        );
      } else {
        this.dateArray = this.getDates(
          new Date(this.date),
          new Date(this.nextWeekdate),
        );
      }
    },
    showAllSchedule() {
      const itemCount = 5;
      this.isLoading = true;
      axios
        .post(Constant.apiUrl.concat('/schedule/'), {
          startDate: this.date,
          endDate: this.nextWeekdate,
          id_unit: this.input.poly?.unitId || '',
          id_staff: this.input.doctor?.doctorId || '',
        })
        .then(response => {
          this.schedulePages = Math.ceil(response.data.length / itemCount);
          if (response.status == 200) {
            let allSchedule = response.data.data;
            this.resource.schedule = allSchedule.map(schedule => {
              return {
                doctorName: schedule.id_staff,
                polyclinic: schedule.id_unit,
                docSchedule: schedule.schedule.map(docSchedule => {
                  return {
                    time: `${docSchedule.startTime} - ${docSchedule.endTime}`,
                    patient: `${docSchedule.quota} Pasien`,
                    date: docSchedule.date,
                    isActive: docSchedule.isActive,
                    isCancel: docSchedule.isCancel,
                    isReschedule: docSchedule.isReschedule,
                    day: this.getDayName(docSchedule.date),
                  };
                }),
              };
            });
            this.isLoading = false;
          }
        })
        .catch(() => {
          this.resource.schedule = [];
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.igdTable {
  ::v-deep table.v-table tbody td .rounded-box {
    border-radius: 5px;
    background-color: #2d9cdb;
    color: white;
    font-weight: bold;
    font-size: 14px;
  }

  ::v-deep.v-datatable thead th.column.sortable {
    padding: 5px 10px;
    color: inherit;
    font-weight: bold;
  }
  ::v-deep table.v-table tbody td:first-child,
  table.v-table tbody td:not(:first-child),
  table.v-table tbody th:first-child,
  table.v-table tbody th:not(:first-child),
  table.v-table thead td:first-child,
  table.v-table thead td:not(:first-child),
  table.v-table thead th:first-child,
  table.v-table thead th:not(:first-child) {
    padding: 5px 10px;
  }
}

.setting-btn {
  border-radius: 8px;
  border-width: 1px;
  margin-left: 1rem;
}

.grid {
  display: grid;
  grid-template-columns: 0.8fr 1fr 1.2fr;
}
</style>
