<template>
  <div class="inventory">
    <v-toolbar flat outlined>
      <v-container fluid>
        <v-row align="center">
          <v-col>
            <v-toolbar-title class="font-weight-bold float-left">
              Tarif Layanan
            </v-toolbar-title>
          </v-col>

          <v-spacer></v-spacer>

          <v-btn
            class="mr-2"
            color="grey"
            icon
            text
            @click="refreshAndAutoUpdate"
          >
            <v-icon>mdi-autorenew </v-icon>
          </v-btn>
          <v-text-field
            class="input"
            v-model="search"
            background-color="grey lighten-3"
            placeholder="Search"
            solo
            dense
            hide-details
            flat
            prepend-inner-icon="mdi-magnify"
            single-line
          ></v-text-field>
        </v-row>
      </v-container>
    </v-toolbar>
    <v-main>
      <v-container fluid>
        <v-col class="pa-0">
          <v-data-table
            class="flex-grow-1"
            :headers="table.headers"
            :items="table.dataItem"
            :loading="loading"
            :items-per-page="itemsPerRow"
            @click:row="
              (item, slot) => {
                selectItem(item);
              }
            "
            hide-default-footer
          >
            <template v-slot:item="{ item, index }">
              <tr class="text-left" :key="index">
                <td>
                  {{ (table.pagination.page - 1) * itemsPerRow + index + 1 }}
                </td>
                <td>{{ item.servicesName }}</td>
                <td>{{ item.subCategories }}</td>
                <td>{{ item.category }}</td>
                <!-- <td>{{ item.unit.name }}</td> -->
                <td class="text-uppercase">{{ item.assurance }}</td>
                <td>Rp. {{ formatNumber(item.total) }}</td>
                <td>
                  <v-switch
                    v-model="item.status"
                    :label="`${item.status ? 'Aktif' : 'Nonaktif'}`"
                    color="info"
                    @click="
                      handleChangeStatus(item.id, item.status, item.assurance)
                    "
                    inset
                    dense
                  ></v-switch>
                </td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        icon
                        class="mx-2 hover"
                        v-bind="attrs"
                        v-on="on"
                        @click.stop="
                          detailDialog = true;
                          selectItem(item);
                        "
                      >
                        <v-icon>mdi-clipboard-text</v-icon>
                      </v-btn>
                    </template>
                    <span>Detail Layanan</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        icon
                        class="mx-2 hover"
                        v-bind="attrs"
                        v-on="on"
                        @click.stop="
                          updateDialog = true;
                          selectItem(item);
                        "
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Ubah Layanan</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        icon
                        class="mx-2 hover"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon small @click="deleteServices(item)">
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Hapus Layanan</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
        <template>
          <v-dialog
            v-model="detailDialog"
            max-width="1200"
            persistent
            fullscreen
          >
            <detail-services
              @close-dialog="closeDialog()"
              v-if="selectedItem && detailDialog"
              @update-data="getAllServices"
              :servicesDetail="selectedItem"
            />
          </v-dialog>
        </template>
        <template>
          <v-dialog
            v-model="updateDialog"
            max-width="1200"
            persistent
            fullscreen
          >
            <update-services
              @close-dialog="closeDialog()"
              v-if="selectedItem && updateDialog"
              @update-data="getAllServices"
              :servicesDetail="selectedItem"
            />
          </v-dialog>
        </template>
      </v-container>
    </v-main>

    <v-footer color="white">
      <v-container fluid>
        <v-row>
          <v-pagination
            v-model="table.pagination.page"
            :length="servicesPages"
            :total-visible="10"
            @input="getAllServices()"
          ></v-pagination>
          <v-spacer></v-spacer>

          <template>
            <v-dialog v-model="dialog" max-width="1400px" persistent fullscreen>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" fab dark v-bind="attrs" v-on="on">
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </template>
              <InputNewServices
                v-if="dialog"
                @close-dialog="closeDialog"
                @update-data="getAllServices"
              />
            </v-dialog>
          </template>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import InputNewServices from './components/Services/InputNewServices';
import Constant from '@/const';
import Swal from 'sweetalert2';
import UpdateServices from './components/Services/UpdateServices.vue';
import DetailServices from './components/Services/DetailServices.vue';
import jwtMixin from '@/mixin/jwtMixin';
import AlertMixin from '@/mixin/alertMixin';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'MasterdataService',
  mixins: [jwtMixin, AlertMixin],
  components: {
    InputNewServices,
    UpdateServices,
    DetailServices,
  },

  data() {
    return {
      dialog: false,
      updateDialog: false,
      detailDialog: false,
      selectedItem: null,
      loading: true,
      servicesPages: 0,
      table: {
        pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 7,
          totalItems: null,
        },
        headers: [
          { text: 'No.', value: 'noQueue', width: '7%' },
          { text: 'Nama Layanan', value: 'servicesName' },
          { text: 'Layanan Induk', value: 'subCategories' },
          { text: 'Kategori', value: 'category' },
          // { text: 'Unit', value: 'unit', width: '10%' },
          { text: 'Tipe Penjamin', value: 'assurance', width: '12%' },
          { text: 'Biaya', value: 'total', width: '10%' },
          { text: 'Status', value: 'status', width: '10%' },
          { text: '', value: 'actions', sortable: false, width: '13%' },
        ],
        dataItem: [],
      },
      search: '',
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshAndAutoUpdate();
    });
  },
  watch: {
    search() {
      this.searchData(this);
      this.table.pagination.page = 1;
    },
  },
  computed: {
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
  },
  methods: {
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.getAllServices();
    }, 650),
    closeDialog() {
      this.dialog = false;
      this.updateDialog = false;
      this.detailDialog = false;
      this.getAllServices();
    },
    selectItem(service) {
      this.selectedItem = service;
    },
    deleteServices(data) {
      Swal.fire({
        title: 'Yakin?',
        text: `Anda akan menghapus Data ${data.servicesName}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak',
      }).then(result => {
        if (result.value) {
          axios
            .delete(
              `${Constant.apiUrl}/master/service/${data.id}?insurance_type=${data.assurance}`,
            )
            .then(response => {
              if (response.status === 200) {
                Swal.fire(
                  'Berhasil',
                  `Data Layanan ${data.servicesName} Berhasil Dihapus`,
                  'success',
                );
                this.getAllServices();
              }
            })
            .catch(err => {
              this.showErrorAxios(err);
            });
        }
      });
    },
    handleChangeStatus(id, status, assurance) {
      axios.put(`${Constant.apiUrl}/master/service/status`, {
        id: id,
        isActive: status,
        assurance: assurance,
      });
    },
    getAllServices() {
      const itemCount = this.itemsPerRow;
      const halaman = this.table.pagination.page;
      let sortBy = '';
      const keyword = this.search;
      this.loading = true;

      this.runApiCalls(() => {
        axios
          .get(
            `${Constant.apiUrl}/master/service?page=${halaman}&itemCount=${itemCount}&sort=${sortBy}&search=${keyword}`,
          )
          .then(response => {
            const getServices = response.data.data;
            // this.servicesPages = Math.ceil(response.data.length / itemCount);
            const { length: dataLength } = response.data;
            this.servicesPages = Math.ceil(dataLength / this.itemsPerRow);
            this.table.dataItem = getServices.map((service, idx) => {
              return {
                no: idx + 7 * halaman - 7,
                id: service._id.toString(),
                servicesName: _.get(service, 'name', ''),
                category: _.get(service, 'category', ''),
                codeLis: _.get(service, 'code_lis', ''),
                subCategories: _.get(service, 'sub_category', ''),
                unit: _.get(service, 'unit', ''),
                assurance: _.get(service, 'cost.assurance', ''),
                total: _.get(service, 'cost.total', 0),
                cost: _.get(service, 'cost', ''),
                status: _.get(service, 'cost.isActive', ''),
              };
            });
            this.loading = false;
          })
          .catch(() => {
            this.servicesPages = 1;
            this.table.dataItem = [];
            this.loading = false;
          });
      });
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.getAllServices();
      this.interval = setInterval(this.getAllServices, 60000);
    },
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.input {
  max-width: 30%;
}

.hover:hover {
  color: #2196f3;
}

::v-deep {
  .v-data-table
    > .v-data-table__wrapper
    tbody
    tr.v-data-table__expanded__content {
    box-shadow: none;
    background-color: #f4f5f5;
  }

  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border: none;
  }
}
</style>
