<template>
  <div class="container-wrap pe-10">
    <v-form ref="form">
      <v-row no-gutters align="baseline">
        <div v-if="activityStatusLab === 'confirmed'">
          <v-alert outlined text type="info">
            Anda tidak dapat melakukan perubahan karena permintaan ini sudah
            dikonfirmasi oleh pihak Laboratorium
          </v-alert>
        </div>
        <v-col cols="6" class="py-4">
          <div
            v-for="item in [
              { label: 'No Order', value: noOrder },
              { label: 'Tanggal Order', value: today },
            ]"
            :key="item.label"
          >
            <v-row class="text-left">
              <v-col cols="4">
                <label>
                  {{ item.label }}
                </label>
              </v-col>
              <v-col cols="8">
                <div>
                  {{ item.value }}
                </div>
              </v-col>
            </v-row>
          </div>
          <v-row class="text-left">
            <v-col cols="4">
              <label
                :class="
                  activityStatusLab === 'scheduled' ||
                  (confirmationState === 'confirmation' && isConfirmed !== true)
                    ? 'required'
                    : ''
                "
              >
                {{
                  confirmationState === 'confirmation'
                    ? 'Diagnosa'
                    : 'Diagnosa Sementara'
                }}
              </label>
            </v-col>
            <v-col cols="8">
              <template
                v-if="
                  dataLab?.isConfirmed === undefined
                    ? activityStatusLab !== 'scheduled' &&
                      activityStatusLab !== 'registration'
                    : dataLab.isConfirmed
                "
              >
                <div
                  class="pb-2"
                  v-for="(item, index) in provisionalDiagnosis"
                  :key="index"
                >
                  {{
                    item.description === undefined
                      ? '' + item
                      : item.code + ' - ' + item.description
                  }}
                </div>
              </template>
              <template v-else>
                <div v-for="(item, index) in provisionalDiagnosis" :key="index">
                  <div class="d-flex">
                    <v-autocomplete
                      :items="getAllDiagnose"
                      :item-value="getAllDiagnose => getAllDiagnose"
                      @keyup="handleChangeDiagnose"
                      @change="val => handleIsChanged(val, index)"
                      item-text="display_name"
                      id="Diagnosa Sementara"
                      append-icon="mdi-chevron-down"
                      placeholder="Pilih Diagnosis"
                      dense
                      :loading="onLoading('diagnoseCheckup')"
                      v-model="provisionalDiagnosis[index]"
                      :rules="[index === 0 ? v => !!v || 'Wajib Diisi' : true]"
                    />
                    <div class="ml-2 d-flex justify-center align-baseline">
                      <v-btn :disabled="index === 0" icon>
                        <v-icon @click="handleRemoveDiagnoseArr(index)"
                          >mdi-delete</v-icon
                        >
                      </v-btn>
                      <v-btn
                        :disabled="provisionalDiagnosis[0] === ''"
                        @click="handleAddDiagnoseArr()"
                        icon
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </template>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6"></v-col>
      </v-row>
      <div class="table-wrap">
        <v-data-table
          single-expand
          hide-default-footer
          :headers="table.headers"
          :items="inputOrderCheckedItems"
          disable-pagination
        >
          <template v-slot:[`item.no`]="{ index }">
            {{ index + 1 }}
          </template>
          <template v-slot:[`item.name`]="{ item, index }" class="check-wrap">
            <div
              v-if="
                dataLab?.isConfirmed === undefined
                  ? activityStatusLab !== 'scheduled' &&
                    activityStatusLab !== 'registration'
                  : dataLab.isConfirmed
              "
            >
              {{ item.name + '' }}
            </div>
            <div v-else class="service-name">
              <v-autocomplete
                :items="laboratoryServices.flatMap(service => service.items)"
                :item-value="
                  laboratoryServices => {
                    return {
                      id_service:
                        laboratoryServices.id_service || laboratoryServices.id,
                      name: laboratoryServices.name,
                      category: laboratoryServices.category,
                    };
                  }
                "
                v-model="checkedItems[index]"
                @change="handleIsChanged"
                :item-disabled="a => a.id === item.id_service"
                item-text="name"
                dense
                append-icon="mdi-chevron-down"
                :rules="[index === 0 ? v => !!v || 'Wajib Diisi' : true]"
              />
            </div>
          </template>
          <template v-slot:[`item.category`]="{ item }">
            <div
              v-if="
                dataLab?.isConfirmed === undefined
                  ? activityStatusLab !== 'scheduled' &&
                    activityStatusLab !== 'registration'
                  : dataLab.isConfirmed
              "
            >
              {{ item.category + '' }}
            </div>
            <div v-else>
              {{ item.category === undefined ? '' : item.category + '' }}
            </div>
          </template>
          <template v-slot:[`item.action`]="{ item, index }">
            <div
              v-if="
                (dataLab?.isConfirmed === undefined
                  ? activityStatusLab === 'scheduled'
                  : !dataLab.isConfirmed) ||
                  activityStatusLab === 'registration'
              "
            >
              <div v-if="index !== 0">
                <v-btn icon>
                  <v-icon @click="handleRemoveCheckedItemsArr(index)"
                    >mdi-delete</v-icon
                  >
                </v-btn>
              </div>
            </div>
          </template>
        </v-data-table>
      </div>
    </v-form>
    <div
      class="check-wrap__button"
      v-if="
        (dataLab?.isConfirmed === undefined
          ? activityStatusLab === 'scheduled'
          : !dataLab.isConfirmed) || activityStatusLab === 'registration'
      "
    >
      <button class="button-add" @click="handleAddCheckedItemsArr">
        <v-icon>mdi-plus</v-icon> Tambah Item
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers(
  'ExaminationLaboratory',
);

const _ = require('lodash');

export default {
  name: 'DetailExaminationTab',
  props: {
    confirmationState: String,
    isConfirmed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      'getIsLoading',
      'getLaboratoryServices',
      'getActivityStatusLab',
      'getInputOrderCheckedItems',
      'getProvisionalDiagnosis',
      'getPatientData',
      'getTodayDate',
      'getNoOrder',
      'getLabReqById',
      'getDiagnose',
      'getDiagnoseCheckup',
      'getDataLab',
    ]),
    labByReqId: {
      get() {
        return this.getLabReqById;
      },
    },
    activityStatusLab: {
      get() {
        return this.getActivityStatusLab;
      },
    },
    today: {
      get() {
        if (
          this.activityStatusLab === 'not_scheduled' &&
          this.dataLab === undefined
        ) {
          return moment(this.getTodayDate)
            .locale('id')
            .format('DD MMMM YYYY');
        } else {
          return moment(this.labByReqId?.timestamps?.created_at)
            .locale('id')
            .format('DD MMMM YYYY');
        }
      },
    },
    noOrder: {
      get() {
        if (
          this.activityStatusLab === 'not_scheduled' ||
          this.activityStatusLab === 'registration'
        ) {
          return this.getNoOrder;
        } else {
          return this.labByReqId.code;
        }
      },
    },
    inputOrderCheckedItems: {
      get() {
        if (this.activityStatusLab === 'not_scheduled') {
          return this.getInputOrderCheckedItems.map(item => {
            return {
              name: this.getLaboratoryServices
                .filter(
                  service =>
                    service.items.filter(itemService => itemService.id === item)
                      .length > 0,
                )
                .map(service => {
                  return service.items
                    .filter(itemService => itemService.id === item)
                    .map(itemService => itemService.name);
                }),
              category: this.getLaboratoryServices
                .filter(
                  service =>
                    service.items.filter(itemService => itemService.id === item)
                      .length > 0,
                )
                .map(service => {
                  return service.name;
                }),
            };
          });
        }
        if (this.activityStatusLab === 'registration') {
          return this.getInputOrderCheckedItems.map(item => {
            return {
              name: item.name,
              category: item.category,
            };
          });
        } else {
          return this.labByReqId?.test_item;
        }
      },
    },
    checkedItems: {
      get() {
        return this.getInputOrderCheckedItems;
      },
      set(value) {
        this.setInputOrderCheckedItems(value);
      },
    },
    provisionalDiagnosis: {
      get() {
        return this.getProvisionalDiagnosis;
      },
      set(value) {
        this.setProvisionalDiagnosis(value);
      },
    },
    patientData: {
      get() {
        return this.getPatientData;
      },
    },
    getAllDiagnose: {
      get() {
        return this.getDiagnose;
      },
    },
    diagnoseCheckup: {
      get() {
        return this.getDiagnoseCheckup;
      },
    },
    laboratoryServices: {
      get() {
        return this.getLaboratoryServices;
      },
    },
    dataLab: {
      get() {
        return this.getDataLab;
      },
    },
  },
  mounted() {
    if (this.dataLab?.id_registration === undefined) {
      switch (this.activityStatusLab) {
        case 'not_scheduled':
          this.resolveGetNoOrder();
          break;
        case 'registration':
          this.resolveGetNoOrder();
          this.resolveGetDiagnose({ keyword: '' });
          this.resolveGetLaboratoryServices('');
          this.setInputOrderCheckedItems(
            this.getInputOrderCheckedItems.map(item => {
              return {
                id_service: item,
                name: this.getLaboratoryServices
                  .filter(
                    service =>
                      service.items.filter(
                        itemService => itemService.id === item,
                      ).length > 0,
                  )
                  .map(service => {
                    return service.items
                      .filter(itemService => itemService.id === item)
                      .map(itemService => itemService.name);
                  })[0][0],
                category: this.getLaboratoryServices
                  .filter(
                    service =>
                      service.items.filter(
                        itemService => itemService.id === item,
                      ).length > 0,
                  )
                  .map(service => {
                    return service.name;
                  })[0],
              };
            }),
          );
          break;
        case 'confirmed':
        case 'scheduled':
          this.resolveGetLabRequestById();
          this.resolveGetDiagnose({ keyword: '' });
          this.resolveGetLaboratoryServices('');
          break;
        case 'detail-register': {
          this.resolveGetLabRequestById();
          this.resolveGetDiagnose({ keyword: '' });
          this.resolveGetLaboratoryServices('');
        }
      }
    } else {
      this.resolveGetLabRequestById();
      this.resolveGetDiagnose({ keyword: '' });
      this.resolveGetLaboratoryServices('');
    }
  },
  methods: {
    ...mapActions([
      'resolveGetNoOrder',
      'resolveGetLabRequestById',
      'resolveGetDiagnose',
      'resolveGetLaboratoryServices',
    ]),
    ...mapMutations([
      'setInputOrderCheckedItems',
      'setProvisionalDiagnosis',
      'setIsChanged',
    ]),
    onLoading(val) {
      return this.getIsLoading(val);
    },
    handleAddDiagnoseArr() {
      this.provisionalDiagnosis.push('');
    },
    handleRemoveDiagnoseArr(index) {
      this.setIsChanged(true);
      this.provisionalDiagnosis.splice(index, 1);
    },
    handleAddCheckedItemsArr() {
      this.checkedItems.push('');
    },
    handleRemoveCheckedItemsArr(index) {
      this.setIsChanged(true);
      this.checkedItems.splice(index, 1);
    },
    handleIsChanged() {
      this.setIsChanged(true);
    },
    handleChangeDiagnose($event) {
      _.debounce(() => {
        this.resolveGetDiagnose({ keyword: $event?.target?.value });
      }, 500)();
    },
  },
  data() {
    return {
      table: {
        headers: [
          {
            text: 'No',
            value: 'no',
            sortable: false,
          },
          {
            text: 'Nama Pemeriksaan',
            value: 'name',
            sortable: false,
          },
          {
            text: 'Kategori Pemeriksaan',
            value: 'category',
            sortable: false,
          },
          {
            text: '',
            value: 'action',
            sortable: false,
          },
        ],
      },
      category: [],
    };
  },
};
</script>

<style lang="scss" scoped>
.container-wrap {
  overflow-y: auto;
  height: 55vh;
  &::-webkit-scrollbar {
    width: 3px;
    background-color: grey;
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: black;
  }
}
::v-deep .v-data-table-header {
  opacity: 100%;
  color: #9e9e9e;
  background-color: rgba(194, 194, 194, 0.3);
}
::v-deep .v-alert {
  background-color: #ebf3f9;
  &__content {
    color: #404040;
  }
}
::v-deep tr:hover {
  background-color: transparent !important;
}
.required::after {
  content: '*';
  color: #eb4747;
}
.service-name {
  width: 70%;
  margin-top: 10px;
}

.button-add {
  width: 140px;
  padding: 5px 12px;
  display: flex;
  border-radius: 10rem;
  justify-content: space-between;
  align-items: center;
  color: #137bc0;
  i.v-icon.v-icon {
    color: #137bc0;
  }
}
.button-add:hover {
  background-color: #deeef9;
}

.check-wrap__button {
  margin: auto;
  width: 81%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>
