import Swal from 'sweetalert2';

const _ = require('lodash');
const axios = require('axios');

export default {
  methods: {
    showErrorJwt() {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Token sudah tidak aktif',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Log out',
        allowEscapeKey: false,
        allowOutsideClick: false,
      }).then(result => {
        if (result.value) {
          delete axios.defaults.headers.Authorization;
          localStorage.removeItem('token');
          this.$store.commit('removeToken');
          this.$router.push('/login');
          document.location.reload();
        }
      });
    },
    showFinishPatientModal(data) {
      Swal.fire({
        title: 'Proses ini tidak dapat dibatalkan!',
        text: `Apakah ${data.text}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, sudah selesai',
      }).then(result => {
        if (result.value) {
          this.changePatientStatus(data.patient, data.status);
          Swal.fire('Berhasil!', `${_.startCase(data.text)}.`, 'success');
        }
      });
    },
    showErrorAxios(error) {
      if (error.response) {
        const { pesan } = error.response.data;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: pesan,
        });
      } else if (error.request) {
        Swal.fire({
          icon: 'error',
          title: 'Tidak dapat menyambung ke server',
          text: 'Periksa koneksi internet anda',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error tidak diketahui',
        });
      }
    },
    getResourceError(error) {
      if (!error.response) {
        if (error.request) {
          Swal.fire({
            icon: 'error',
            title: 'Tidak dapat menyambung ke server',
            text: 'Periksa koneksi internet anda',
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Detail Error tidak diketahui',
          });
        }
      }
    },
    showFillWarning(hightlight, message) {
      Swal.fire({
        icon: 'warning',
        title: hightlight,
        text: message,
      });
    },
    showFillSuccess(hightlight, message = '') {
      Swal.fire({
        icon: 'success',
        title: hightlight,
        text: message,
      });
    },
    showFailedPrint() {
      Swal.fire({
        icon: 'error',
        title: 'Terjadi Kesalahan',
        text: 'Tidak dapat mencetak pdf',
      });
    },
  },
};
