<template>
  <div class="wrap-order">
    <v-row no-gutters>
      <v-col cols="6">
        <v-col cols="6" class="pa-0 pt-2 pb-8">
          <v-text-field
            background-color="grey lighten-3"
            label="Temukan Item Disini"
            solo
            dense
            hide-details
            flat
            prepend-inner-icon="mdi-magnify"
            single-line
            v-model="searchQuery"
        /></v-col>
      </v-col>
      <v-col cols="6">
        <v-row no-gutters align="baseline">
          <v-col cols="6">
            <div class="text-right mr-4">
              <label
                :class="[
                  state === 'register' ? '' : 'required',
                  'font-weight-medium',
                  'black--text',
                ]"
                for="diagnosa-sementara"
              >
                Diagnosa Sementara
              </label>
            </div>
          </v-col>
          <v-col cols="6" class="d-flex">
            <v-form ref="form">
              <v-autocomplete
                :items="getAllDiagnose"
                :item-value="getAllDiagnose => getAllDiagnose"
                :loading="onLoading('allDiagnose')"
                item-text="display_name"
                @keyup="handleChangeDiagnose"
                id="Diagnosa Sementara"
                append-icon="mdi-chevron-down"
                placeholder="Pilih Diagnosis"
                dense
                v-model="provisionalDiagnosis[0]"
                :rules="state === 'register' ? [] : [v => !!v || 'Wajib Diisi']"
              />
            </v-form>
            <div class="ml-2 d-flex justify-center align-baseline">
              <v-btn disabled icon>
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-btn @click="handleAddDiagnoseArr()" icon>
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <div
          v-for="({ name }, index) in provisionalDiagnosis.slice(1)"
          :key="index"
        >
          <v-row no-gutters align="baseline">
            <v-col cols="6"></v-col>
            <v-col cols="6" class="d-flex">
              <v-form>
                <v-autocomplete
                  :items="getAllDiagnose"
                  :item-value="getAllDiagnose => getAllDiagnose"
                  item-text="display_name"
                  @keyup="handleChangeDiagnose"
                  id="Diagnosa Sementara"
                  append-icon="mdi-chevron-down"
                  placeholder="Pilih Diagnosis"
                  v-model="provisionalDiagnosis[index + 1]"
                  dense
                />
              </v-form>
              <div class="ml-2 d-flex justify-center align-baseline">
                <v-btn @click="handleRemoveDiagnoseArr(index + 1)" icon>
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn @click="handleAddDiagnoseArr()" icon>
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <div>
      <div
        style="height: 40vh;"
        class="d-flex justify-center align-center"
        v-if="onLoading('laboratoryServices') === true"
      >
        <v-progress-circular
          indeterminate
          :size="50"
          :width="5"
        ></v-progress-circular>
      </div>
      <div
        class="container-examination"
        v-for="({ id, name, items }, index) in searchQuery === ''
          ? getLaboratoryServices
          : getSearchResult"
        :key="id"
      >
        <h2 class="container-examination__label">{{ name }}</h2>
        <div class="container-examination__container-checkbox">
          <span v-for="item in items" :key="item.id" class="checkbox-item">
            <input
              :id="item.id"
              class="checkbox-item__input"
              type="checkbox"
              hide-details="auto"
              color="#27AE60"
              dense
              :label="item.name"
              :value="item.id"
              v-model="checkedItems"
            />
            <label class="checkbox-item__label" :for="item.id">{{
              item.name
            }}</label>
          </span>
        </div>
        <div
          v-if="
            index !== getLaboratoryServices?.length - 1 &&
              index !== getSearchResult?.length - 1
          "
        >
          <v-divider></v-divider>
        </div>
      </div>
      <div v-if="getSearchResult?.length === 0 && searchQuery !== ''">
        <h3 class="d-flex justify-center align-center empty-text-state">
          Item yang anda cari tidak ditemukan
        </h3>
      </div>
    </div>
    <v-row v-if="searchQuery === '' && !onLoading('laboratoryServices')">
      <v-col cols="3">
        <div class="text-information">
          <p>Keterangan</p>
          <span>DR: Hb, RBC, WBC, PLT, HCT, MCV, MCH, MCHG</span>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const {
  mapGetters: labGetters,
  mapActions: labActions,
  mapMutations: labMutations,
} = createNamespacedHelpers('ExaminationLaboratory');
const { mapGetters: patientGetters } = createNamespacedHelpers(
  'PatientRegistration',
);

const _ = require('lodash');

export default {
  name: 'OrderExaminationTab',
  props: {
    state: String,
  },
  computed: {
    ...labGetters([
      'getIsLoading',
      'getLaboratoryServices',
      'getSearchQuery',
      'getSearchResult',
      'getInputOrderCheckedItems',
      'getProvisionalDiagnosis',
      'getDiagnose',
      'getPatientData',
    ]),
    ...patientGetters(['getPatientRegistration']),

    // Search
    searchQuery: {
      get() {
        return this.getSearchQuery;
      },
      set(value) {
        this.setSearchQuery(value);
      },
    },

    // Input order
    checkedItems: {
      get() {
        return this.getInputOrderCheckedItems;
      },
      set(value) {
        this.setInputOrderCheckedItems(value);
      },
    },
    provisionalDiagnosis: {
      get() {
        return this.getProvisionalDiagnosis;
      },
      set(value) {
        this.setProvisionalDiagnosis(value);
      },
    },

    // Api
    getAllDiagnose: {
      get() {
        return this.getDiagnose;
      },
    },
  },
  methods: {
    ...labMutations([
      'setSearchQuery',
      'setInputOrderCheckedItems',
      'setProvisionalDiagnosis',
    ]),
    ...labActions([
      'searchItem',
      'resolveGetLaboratoryServices',
      'resolveGetDiagnoseCheckup',
      'resolveGetDiagnose',
    ]),

    // Local methods
    handleAddDiagnoseArr() {
      this.provisionalDiagnosis.push('');
    },
    handleRemoveDiagnoseArr(index) {
      this.provisionalDiagnosis.splice(index, 1);
    },
    onLoading(val) {
      return this.getIsLoading(val);
    },
    handleChangeDiagnose: _.debounce(function(event) {
      this.resolveGetDiagnose({ keyword: event.target.value });
    }, 600),
  },
  mounted() {
    if (this.state !== 'register') {
      this.resolveGetDiagnoseCheckup();
    }
    this.resolveGetLaboratoryServices(
      this.getPatientData?.meta?.guarantorType?.toLowerCase() === undefined
        ? this.getPatientRegistration.assuranceType?.toLowerCase()
        : this.getPatientData?.meta?.guarantorType?.toLowerCase() ===
          'assurance'
        ? 'asuransi'
        : this.getPatientData?.meta?.guarantorType?.toLowerCase(),
    );
    this.resolveGetDiagnose({ keyword: '' });
  },
  watch: {
    searchQuery(newQuery) {
      this.searchItem(newQuery);
    },
  },
};
</script>

<style lang="scss" scoped>
$blackText: #404040;
$greyText: #9e9e9e;

.wrap-order {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 60vh;
  &::-webkit-scrollbar {
    width: 3px;
    background-color: grey;
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: black;
  }
}

.container-examination {
  padding-bottom: 2.5rem;
  width: 100%;
  &__label {
    text-align: left;
    color: $greyText;
    font-size: 16px;
    text-transform: uppercase;
  }
  &__container-checkbox {
    padding-top: 1rem;
    padding-bottom: 2.5rem;
    display: grid;
    grid-template-columns: repeat(4, 4fr);
  }
  .checkbox-item {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    margin: 10px;
    &__input {
      min-width: 16px;
      min-height: 16px;
      cursor: pointer;
    }
    &__input:checked {
      accent-color: #2d965a;
      cursor: pointer;
    }
    &__label {
      cursor: pointer;
      text-align: start;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #404040;
    }
  }
}
.empty-text-state {
  height: 50vh;
  color: $greyText;
}
.text-information {
  text-align: left;
  background-color: #f5f5f5;
  font-size: 14px;
  padding: 1rem;
}

.required:after {
  content: ' *';
  color: red;
}
</style>
