<template>
  <v-container fluid>
    <v-row row wrap class="px-3" no-gutters>
      <v-col cols="12" class="mb-3 pr-4">
        <v-row row align-center no-gutters>
          <h2 class="mr-4">
            Dashboard {{ $store.getters.userLoggedIn.clinic.name }}
          </h2>
          <v-progress-circular
            v-if="isLoading.all"
            color="primary lighten-2"
            size="30"
            indeterminate
          />
          <span v-if="isLoading.all" class="blue--text lighten-2 ml-2"
            >refresh data...</span
          >
          <v-spacer></v-spacer>
          <h4 class="grey--text">Data per {{ now }}</h4>
        </v-row>
      </v-col>
      <v-col
        v-for="(item, index) in visit"
        md="4"
        class=""
        :key="'item-' + index"
        ><skeleton
          :class="{ 'mr-4': isLoading.all }"
          :isLoading="skeletonLoadingState(index)"
        >
          <visit-card
            @open-dialog="dialog = true"
            :data="item"
            :index="index"
          />
        </skeleton>
      </v-col>
      <v-col md="8" class="mt-3 graph-container">
        <dashboard-chart
          @on-change-filter="changeMainChartFilter"
          :data="{ filterType, chartOptions, series }"
        />
      </v-col>
      <v-col md="4" class="px-3 pt-3">
        <income-card v-if="isManager" :data="{ isLoading, income }" />
        <pie-chart-dashboard
          v-else
          :data="{
            series: pieChart.series,
            chartOptions: pieChart.chartOptions,
            isLoading,
          }"
        />
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" v-if="dialog" persistent>
      <AlmostExpiredDrugsVue v-if="dialog" @close-form="closeForm" />
    </v-dialog>
  </v-container>
</template>

<script>
import totalVisit from '@/assets/image/visit-inpatient.svg';
import todayVisit from '@/assets/image/visit-outpatient.svg';
import drugsales from '@/assets/image/visit-igd.svg';
import jwtMixin from '@/mixin/jwtMixin';
import alertMixin from '@/mixin/alertMixin';
import AlmostExpiredDrugsVue from './Components/AlmostExpiredDrugs.vue';
import VisitCard from './Components/VisitCard.vue';
import DashboardChart from './Components/DashboardChart.vue';
import IncomeCard from './Components/IncomeCard.vue';
import PieChartDashboard from './Components/PieChartDashboard.vue';
import Skeleton from '../../components/SharedComponent/Skeleton.vue';
import {
  getHomeData,
  getTotalData,
  getIncomeData,
  getAlmostExpiredDrugData,
} from '../../fetchApi/DashboardPage';

const moment = require('moment-timezone');

export default {
  name: 'DashboardPage',
  mixins: [jwtMixin, alertMixin],
  components: {
    AlmostExpiredDrugsVue,
    VisitCard,
    DashboardChart,
    PieChartDashboard,
    IncomeCard,
    Skeleton,
  },
  data() {
    return {
      isLoading: {
        total: false,
        income: false,
        almostExpiredDrug: false,
        all: false,
      },
      dialog: false,
      filterType: 'all',
      interval: '',
      income: {
        current: 0,
        lastMonth: 0,
        diff: 0,
        trend: null,
      },
      pieChart: {
        series: [],
        chartOptions: {
          labels: [],
          colors: ['#31b057', '#59abe1', '#fbaf18', '#7c59e1', '#e1d359'],
          chart: {
            width: 300,
            type: 'donut',
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  total: {
                    show: true,
                    showAlways: true,
                    color: '#757575',
                    fontSize: '20px',
                    fontWeight: '600',
                  },
                  value: {
                    show: true,
                    showAlways: true,
                    fontSize: '35px',
                    fontWeight: '600',
                  },
                },
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  show: false,
                },
              },
            },
          ],
          legend: {
            position: 'right',
            offsetY: 0,
            height: 230,
            show: false,
          },
        },
      },
      visit: {
        total: {
          class: 'total',
          text: 'Total Kunjungan Pasien',
          amount: 0,
          outward: 0,
          drugsales: 0,
          icon: totalVisit,
        },
        today: {
          class: 'today',
          text: 'Kunjungan Hari ini',
          amount: 0,
          outward: 0,
          drugsales: 0,
          icon: todayVisit,
        },
        drug: {
          class: 'drugsales',
          text: 'Obat Hampir Kadaluarsa',
          amount: 0,
          icon: drugsales,
        },
      },
      legend: [],

      chartOptions: {
        chart: {
          id: 'realtimeChart',
          width: '730px',
          height: 300,
        },
        xaxis: {
          categories: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'Mei',
            'Jun',
            'Jul',
            'Ags',
            'Sep',
            'Okt',
            'Nov',
            'Des',
          ],
        },
        stroke: {
          curve: 'smooth',
          width: 2,
        },
        dataLabels: {
          enabled: false,
        },
        colors: ['#B080FF', '#59ABE1', '#F2994A', '#2d965a'],
        legend: {
          show: true,
          horizontalAlign: 'left',
        },
      },
      series: [
        {
          name: 'Rawat Jalan',
          data: [],
        },
        {
          name: 'Rawat Inap',
          data: [],
        },
        {
          name: 'IGD',
          data: [],
        },
      ],
    };
  },
  computed: {
    isManager() {
      return (
        this.$store.getters.userLoggedIn.role.toLowerCase() ===
          'administrator' ||
        this.$store.getters.userLoggedIn.role.toLowerCase() === 'manager'
      );
    },
    now() {
      return moment()
        .locale('id')
        .format('dddd, DD MMMM YYYY');
    },
  },
  watch: {
    filterType(value) {
      if (value) {
        this.getData();
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshAndAutoUpdate();
    });
  },
  methods: {
    closeForm() {
      this.dialog = false;
    },
    skeletonLoadingState(type) {
      switch (type) {
        case 'total':
          return this.isLoading.total;
        case 'drug':
          return this.isLoading.almostExpiredDrug;
        case 'today':
          return this.isLoading.all;
      }
    },
    changeMainChartFilter(data) {
      this.filterType = data;
    },
    async getTotalData() {
      try {
        this.isLoading.total = true;
        const response = await getTotalData();
        const { outward, drugsales } = response.data.data.total;
        this.visit.total = {
          ...this.visit.total,
          amount: outward + drugsales,
          outward,
          drugsales,
        };
      } catch {
        this.isLoading.total = false;
      } finally {
        this.isLoading.total = false;
      }
    },
    async getIncomeData() {
      try {
        this.isLoading.income = true;
        const response = await getIncomeData();
        const {
          diff,
          total_current_month,
          total_last_month,
          trend,
        } = response.data.data.income;
        this.income = {
          ...this.income,
          current: total_current_month,
          lastMonth: total_last_month,
          diff,
          trend,
        };
      } catch {
        this.isLoading.incocme = false;
      } finally {
        this.isLoading.incocme = false;
      }
    },
    async getAlmostExpiredDrugData() {
      try {
        this.isLoading.almostExpiredDrug = true;
        const response = await getAlmostExpiredDrugData();
        const { almost_expired_drug } = response.data.data;
        this.visit.drug = {
          ...this.visit.drug,
          amount: almost_expired_drug,
        };
      } catch {
        this.isLoading.almostExpiredDrug = false;
      } finally {
        this.isLoading.almostExpiredDrug = false;
      }
    },
    async getData() {
      try {
        this.isLoading.all = true;
        const response = await getHomeData(this.filterType);
        const { today, graph, unit_today } = response.data.data;
        this.visit.today = {
          ...this.visit.today,
          amount: today.outward + today.drugsales,
          outward: today.outward,
          drugsales: today.drugsales,
        };
        // data for main chart
        this.series = [
          {
            name: 'Rawat Jalan',
            data: graph.outward,
          },
          {
            name: 'Rawat Inap',
            data: graph.inward,
          },
          {
            name: 'IGD',
            data: graph.igd,
          },
          {
            name: 'Apotek',
            data: graph.drugsales,
          },
        ];
        // data for pie chart
        this.pieChart.series = unit_today.map(value => value.length);
        this.pieChart.chartOptions = {
          ...this.pieChart.chartOptions,
          labels: unit_today.map(value => value.unit),
        };
      } catch {
        this.isLoading.all = false;
      } finally {
        this.isLoading.all = false;
      }
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.cancelAutoUpdate();
      }
      this.getData();
      this.getTotalData();
      this.getIncomeData();
      this.getAlmostExpiredDrugData();
      this.interval = setInterval(() => {
        this.getData();
        this.getTotalData();
        this.getIncomeData();
        this.getAlmostExpiredDrugData();
      }, 60000);
    },
    cancelAutoUpdate() {
      this.interval = clearInterval(this.interval);
    },
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  width: 100%;
  h1 {
    text-align: left;
  }

  .graph-container {
    border: 2.5px solid #e0e0e0;
    border-radius: 8px;
    width: 100%;
    padding: 10px;
  }
}
</style>
