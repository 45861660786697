<template>
  <v-card>
    <v-card-title class="justify-center">
      <span class="font-weight-bold">Pemesanan Barang ke Supplier</span>
      <v-btn absolute right icon @click="onCloseButtonClick()">
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form lazy-validation ref="form">
        <v-container>
          <v-row class="mb-5" no-gutters>
            <v-col cols="4" offset="1">
              <v-row no-gutters>
                <v-col cols="12" class="label">
                  <v-row align="start" class="font-weight-bold">
                    <v-toolbar-title class="black--text">
                      Data Barang
                    </v-toolbar-title>
                  </v-row>
                  <v-row align="center" justify="space-between">
                    <div class="label">
                      <label
                        for="nama barang"
                        class="black--text flex-grow-1 float-left"
                      >
                        Nama Barang
                      </label>
                    </div>
                    <v-combobox
                      :loading="itemLoad"
                      item-text="displayName"
                      item-value="id"
                      clearable
                      return-object
                      :search-input.sync="searchGood"
                      :rules="rules.name"
                      placeholder="Pilih Nama Barang"
                      class="input"
                      :items="selectBarang"
                      dense
                      v-model="input.name"
                      @change="getPackage()"
                    />
                  </v-row>
                  <v-row align="center" justify="space-between">
                    <div class="label">
                      <label
                        for="Jumlah Pesanan"
                        class="black--text flex-grow-1 float-left"
                      >
                        Jumlah Pesanan
                      </label>
                    </div>
                    <v-text-field
                      placeholder="Masukkan Jumlah Barang"
                      class="input"
                      dense
                      :rules="rules.quantity"
                      v-model.number="input.totalItem"
                    />
                  </v-row>
                  <v-row align="center" justify="space-between">
                    <div class="label">
                      <label
                        for="paket"
                        class="black--text flex-grow-1 float-left"
                      >
                        Package
                      </label>
                    </div>
                    <v-select
                      placeholder="Pilih Jenis Kemasan"
                      class="input"
                      dense
                      :items="packageItem"
                      item-text="name"
                      v-model="input.package"
                      :rules="rules.package"
                      return-object
                    />
                  </v-row>

                  <v-row justify="end">
                    <v-btn
                      class="my-2"
                      outlined
                      color="primary"
                      @click="addNewList()"
                    >
                      Tambah Ke List
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4" offset="1">
              <v-row no-gutters>
                <v-col cols="12" class="label">
                  <v-row align="start" class="font-weight-bold">
                    <v-toolbar-title class="black--text">
                      Data Supplier
                    </v-toolbar-title>
                  </v-row>

                  <v-row align="center" justify="space-between">
                    <div class="label">
                      <label
                        for="Nomor Permintaan"
                        class="black--text flex-grow-1 float-left"
                      >
                        Nomor Permintaan
                      </label>
                    </div>

                    <v-text-field
                      placeholder="Masukan Nomor Permintaan"
                      class="input"
                      :loading="isLoadingSpNumber"
                      dense
                      readonly
                      v-model="newReceiptNumber"
                    />
                  </v-row>
                  <v-row align="center" justify="space-between">
                    <div class="label">
                      <label
                        for="name"
                        class="black--text flex-grow-1 float-left"
                      >
                        Tanggal Pemesanan
                      </label>

                      <v-menu
                        :close-on-content-click="false"
                        v-model="menu"
                        :nudge-right="40"
                        lazy
                        transition="scale-transition"
                        offset-y
                        full-width
                        min-width="290px"
                      >
                      </v-menu>
                    </div>
                    <v-text-field
                      placeholder="Masukan Tanggal Pemesanan"
                      class="input"
                      readonly
                      dense
                      v-model="supplier.date"
                    />
                    <!-- <v-date-picker v-model="supplier.inputDate" @input="menu = false" /> -->
                  </v-row>
                  <v-row align="center" justify="space-between">
                    <div class="label">
                      <label
                        for="Nama Supplier"
                        class="black--text flex-grow-1 float-left"
                      >
                        Nama Supplier
                      </label>
                    </div>
                    <v-combobox
                      placeholder="Pilih Nama Supplier"
                      class="input"
                      :rules="rules.supplier"
                      item-text="name"
                      item-value="id"
                      :items="selectSupplier"
                      return-object
                      dense
                      v-model="supplier.supplierName"
                    />
                  </v-row>
                  <v-row align="center" justify="space-between">
                    <div class="label">
                      <label class="black--text flex-grow-1 float-left">
                        Perkiraan Total Biaya
                      </label>
                    </div>
                    <v-text-field
                      class="input"
                      prefix="Rp."
                      :loading="isLoadingSpNumber"
                      dense
                      readonly
                      v-model="totalAll"
                    />
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2"></v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="headers"
                :items="table.detail"
                :items-per-page="table.detail.length"
                class="table-item"
                height="240"
                fixed-header
                hide-default-footer
              >
                <template v-slot:item="{ item, index }">
                  <tr class="row-datatable" :key="index">
                    <td class="text-left">
                      {{
                        (table.pagination.page - 1) *
                          table.pagination.rowsPerPage +
                          index +
                          1
                      }}
                    </td>
                    <td class="text-left">{{ item.name }}</td>
                    <td class="text-left">{{ item.quantity }}</td>
                    <td class="text-left">{{ item.package }}</td>

                    <td class="text-left">{{ item.category }}</td>
                    <td class="text-left">
                      Rp. {{ formatNumber(item.price) }}
                    </td>
                    <td class="text-left">
                      Rp. {{ formatNumber(item.subTotal) }}
                    </td>
                    <td>
                      <v-icon small @click="deleteItem(item)"
                        >mdi-delete</v-icon
                      >
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row>
            <v-spacer></v-spacer>

            <v-btn
              @click="printSp()"
              v-if="table.detail.length > 0"
              color="primary"
              width="100"
              outlined
            >
              <v-icon>mdi-printer</v-icon>
              <span>Print</span>
            </v-btn>
            <v-btn
              class="mx-4"
              width="100"
              depressed
              color="primary"
              @click="addNewItemOrder()"
            >
              <span>Simpan</span>
            </v-btn>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Constant from '@/const';
const moment = require('moment-timezone');
import PrintMixin from '@/mixin/printMixin';
import PrintItemOrder from '@/mixin/printItemOrder';
import Swal from 'sweetalert2';
const axios = require('axios');
const _ = require('lodash');

export default {
  mixins: [PrintMixin, PrintItemOrder],
  data() {
    return {
      itemLoad: false,
      expand: false,
      isLoadingSpNumber: false,
      menu: false,
      newReceiptNumber: '',
      rules: {
        name: [v => !!v || 'Perlu mengisi nama barang'],
        package: [v => !!v || 'Perlu mengisi kemasan barang'],
        quantity: [v => !!v || 'Perlu mengisi jumlah barang'],
        supplier: [v => !!v || 'Perlu mengisi nama supplier'],
      },
      headers: [
        {
          text: 'No',
          align: 'start',
          sortable: false,
          value: 'index',
        },
        { text: 'Nama Barang', value: 'name.name' },
        { text: 'Jumlah Pesanan', value: 'totalItem' },
        { text: 'Package', value: 'name.package' },
        { text: 'Kategori Barang', value: 'name.category' },
        { text: 'Harga', value: 'name.hna' },
        { text: 'Sub Total', value: 'subTotal' },
        { text: '', value: 'delete' },
      ],

      table: {
        detail: [],
        pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 5,
        },
      },

      selectSupplier: [],
      selectBarang: [],
      selectpackage: [],
      getdrugs: [],
      getgoods: [],
      supplier: {
        date: moment().format('YYYY-MM-DD'),
        inputDate: '',
        totalprice: '',
        supplierName: '',
        orderNumber: '',
      },
      input: {
        name: '',
        totalItem: '',
        goods: null,
        idSupplier: '',
        package: '',
      },
      item: [],
      packageItem: [],
      searchGood: '',
    };
  },
  computed: {
    totalAll: {
      get() {
        const total = this.table.detail.reduce((a, b) => +a + +b.subTotal, 0);
        return this.formatNumber(total);
      },
      set(value) {
        this.supplier.totalprice = value;
      },
    },
    letterData() {
      return Constant.client.find(
        item =>
          item.name.toLowerCase() ===
          this.$store.getters.userLoggedIn.clinic.name.toLowerCase(),
      );
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.getItem();
      this.getDataSupllier();
      this.getNewSpNumber();
    });
  },
  watch: {
    'supplier.inputDate': val => {
      this.supplier.date = this.formatDate(val);
    },
    searchGood() {
      this.searchData(this);
    },
  },
  methods: {
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.getItem();
    }, 650),
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return year.length === 4
        ? `${day}/${month}/${year}`
        : `${year}-${month}-${day}`;
    },

    // inputDate(item) {
    //   item.itemsExpDate = this.formatDate(item.inputItemsExpDate);
    //   item.menu = false;
    // },
    async getNewSpNumber() {
      this.isLoadingSpNumber = true;
      try {
        const response = await axios.get(
          Constant.apiUrl.concat('/supply/spnumber'),
        );
        this.newReceiptNumber = response.data.data;
      } catch (error) {
        Swal.fire('Gagal mendapatkan nomor SP', '', 'error');
      } finally {
        this.isLoadingSpNumber = false;
      }
    },
    printSp() {
      this.supplier = { ...this.supplier, orderNumber: this.newReceiptNumber };
      const doc = this.printItemOrder(
        this.$store.getters.userLoggedIn.clinic,
        this.supplier,
        this.letterData,
        this.table,
        this.$store.getters.userLoggedIn,
      );
      this.printDocument(doc);
    },

    addNewItemOrder() {
      if (this.table.detail.length > 0) {
        const itemArr = this.table.detail.map(item => {
          return {
            id_drugs: item.id_drugs,
            id_goods: item.id_goods,
            quantity: item.quantity,
            package: item.package,
            sub_total: item.subTotal,
            name: item.name,
            price: item.price,
            category: item.category,
            itemsPerPackage: +item.itemsPerPackage,
          };
        });

        const payload = {
          order_date: this.supplier.date,
          total_price: parseInt(this.totalAll.replace('.', '')),
          id_supplier: this.supplier.supplierName.id,
          item: itemArr,
          timestamps: {
            created_by: this.$store.getters.userLoggedIn.id,
          },
        };

        axios
          .post(Constant.apiUrl.concat('/supply/request'), payload)
          .then(response => {
            if (response.status === 200) {
              Swal.fire('Berhasil', 'Data Barang Sudah Masuk', 'success');
              this.onCloseButtonClick();
            }
          })
          .catch(error => {
            if (error.response) {
              const message = error.response.data.pesan;
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: message,
              });
            } else if (error.request) {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Tidak dapat menyambung ke server',
              });
            }
          });
      }
    },
    getItem() {
      this.itemLoad = true;
      const keyword = this.searchGood;
      axios
        .get(
          Constant.apiUrl.concat(
            `/master/drugs/goods/all?page=1&itemCount=10&search=${keyword ||
              ''}`,
          ),
        )
        .then(response => {
          const data = response.data.data;

          this.selectBarang = data.map(v => {
            return {
              id_drugs: v?.id_drug,
              id_goods: v?.id_good,
              name: v.name,
              displayName: v.display_name,
              hna: v.hna,
              category: v.category,
              package: v.packaging,
            };
          });
        })
        .catch(() => {
          this.selectBarang = [];
        })
        .finally(() => (this.itemLoad = false));
    },
    getPackage() {
      this.packageItem = this.input.name.package.map(v => {
        return {
          name: v.package,
          quantity: v.quantity,
        };
      });
    },
    getDataSupllier() {
      axios.get(Constant.apiUrl.concat('/master/supplier')).then(response => {
        const getSupplier = response.data.data;
        this.selectSupplier = getSupplier.map(selectSupplier => {
          return {
            id: selectSupplier._id.toString(),
            name: selectSupplier.name,
            address: selectSupplier.detail[0].address,
          };
        });
      });
    },
    onCloseButtonClick() {
      this.$emit('close-form');
    },
    addNewList() {
      if (this.$refs.form.validate()) {
        const data = this.table.detail.find(
          v => v.name == this.input.name.displayName,
        );
        if (data) {
          Swal.fire(
            'Barang sudah ditambahkan',
            'Silahkan hapus terlebih dahulu untuk menambahkan barang yang sama',
            'warning',
          );
        } else {
          this.table.detail.push({
            name: this.input.name.displayName,
            id_drugs: this.input.name.id_drugs,
            id_goods: this.input.name.id_goods,
            quantity: this.input.totalItem,
            package: this.input.package.name,
            itemsPerPackage: this.input.package.quantity,
            category: this.input.name.category,
            price: this.input.name.hna * this.input.package.quantity,
            subTotal:
              this.input.name.hna *
              this.input.package.quantity *
              this.input.totalItem,
          });
          // this.totalPrice();
          this.afterAdd();
          this.$refs.form.resetValidation();
        }
      }
    },
    deleteItem(item) {
      const index = this.table.detail.indexOf(item);
      this.table.detail.splice(index, 1);
      this.totalPrice();
    },
    afterAdd() {
      this.input = {
        name: '',
        totalItem: '',
        orderNumber: '',
        supplierName: '',
        goods: null,
        idSupplier: '',
      };
      this.packageItem = [];
    },
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    },
  },
};
</script>

<style lang="scss" scoped>
.input {
  max-width: 50%;
}

.total-all {
  width: 50%;
}

.underline {
  border-bottom: 1px grey solid;
}
</style>
