import Constant from '@/const';
import axios from 'axios';

const getPharmacotherapy = async () => {
  try {
    const response = await axios.get(Constant.apiUrl.concat('/master/farmaco'));
    return response.data.data;
  } catch (error) {
    this.showErrorAxios(error);
  }
};

export default getPharmacotherapy;
