<template>
  <div class="main-unit">
    <v-toolbar flat outlined>
      <v-container fluid>
        <v-row align="center">
          <v-col>
            <v-toolbar-title class="font-weight-bold float-left">
              Data Supplier
            </v-toolbar-title>
          </v-col>

          <v-spacer></v-spacer>

          <v-btn
            class="mr-2"
            color="grey"
            icon
            text
            @click="refreshAndAutoUpdate"
          >
            <v-icon>mdi-autorenew</v-icon>
          </v-btn>
          <v-text-field
            class="input"
            v-model="search"
            background-color="grey lighten-3"
            placeholder="Search"
            solo
            dense
            hide-details
            flat
            prepend-inner-icon="mdi-magnify"
            single-line
          ></v-text-field>
        </v-row>
      </v-container>
    </v-toolbar>
    <v-main>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :loading="loading"
              :headers="table.headers"
              :items="table.items"
              :expanded="expanded"
              :items-per-page="itemsPerRow"
              single-expand
              item-key="id"
              @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
              :page.sync="table.pagination.page"
              hide-default-footer
            >
              <template v-slot:[`item.no`]="{ item }">
                <td>{{ item.no + 1 }}</td>
              </template>
              <template v-slot:expanded-item="{ item, headers }">
                <td
                  @click="selectItem(item)"
                  :colspan="headers.length"
                  class="expand"
                >
                  <v-card flat color="grey lighten-4" class="text-right">
                    <v-container>
                      <v-row no-gutters>
                        <v-col cols="12">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                x-small
                                icon
                                class="mx-2 hover"
                                v-bind="attrs"
                                v-on="on"
                                @click="onClicked(1, item)"
                              >
                                <v-icon>
                                  mdi-clipboard-plus
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Ubah Supplier</span>
                          </v-tooltip>

                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                x-small
                                icon
                                class="mx-2 hover"
                                v-bind="attrs"
                                v-on="on"
                                @click="deleteItem(item)"
                              >
                                <v-icon>
                                  mdi-close-thick
                                </v-icon>
                              </v-btn>
                            </template>

                            <span>Hapus Supplier</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-footer color="white">
      <v-container fluid>
        <v-row>
          <v-pagination
            v-model="table.pagination.page"
            @input="getAllSupplier()"
            :length="allPages"
            :total-visible="7"
          ></v-pagination>
          <v-spacer></v-spacer>

          <v-btn color="primary" fab dark @click="onClicked(0, null)">
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-row>
      </v-container>
    </v-footer>
    <v-dialog v-model="dialog" width="auto">
      <InputNewSupplier
        v-if="selectedForm === 0 && dialog"
        @close-form="
          dialog = false;
          getAllSupplier();
        "
      />
      <EditSupplier
        v-else-if="selectedForm === 1 && dialog"
        :selected-item="selectedItem"
        @close-form="
          dialog = false;
          getAllSupplier();
        "
      />
    </v-dialog>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import Constant from '@/const';
import jwtMixin from '@/mixin/jwtMixin';
import InputNewSupplier from './components/Supplier/InputNewSupplier.vue';
import EditSupplier from './components/Supplier/EditSupplier.vue';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'MasterdataUnit',
  mixins: [jwtMixin],
  components: {
    InputNewSupplier,
    EditSupplier,
  },
  data() {
    return {
      loading: true,
      expanded: [],
      dialog: false,
      search: '',
      selectedItem: null,
      allPages: 0,
      table: {
        headers: [
          { text: 'No', value: 'no', width: '6%' },
          { text: 'Nama', value: 'name', width: '20%' },
          { text: 'Alamat', value: 'address', width: '30%' },
          { text: 'Telp', value: 'telp' },
          { text: 'Email', value: 'email' },
          { text: 'Jatuh Tempo (Hari)', value: 'dueDate' },
        ],
        items: [],
        pagination: {
          descending: false,
          page: 1,
          rowsPerPage: 7,
          sortBy: '',
        },
      },
      selectedForm: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshAndAutoUpdate();
    });
  },
  watch: {
    search() {
      this.searchData(this);
      this.table.pagination.page = 1;
    },
  },
  computed: {
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
  },
  methods: {
    searchData: _.debounce(v => {
      v.getAllSupplier();
    }, 650),
    closeDialog() {
      this.dialog = false;
      this.detailDialog = false;
      this.updateDialog = false;
    },

    onClicked(idx, item) {
      if (item) {
        this.selectedItem = item;
      }
      this.selectedForm = idx;
      this.dialog = true;
    },
    deleteItem(item) {
      Swal.fire({
        title: 'Yakin?',
        text: `Anda akan menghapus ${item.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak',
      }).then(result => {
        if (result.value) {
          axios
            .delete(`${Constant.apiUrl}/master/supplier/${item.id}`)
            .then(response => {
              if (response.status === 200) {
                Swal.fire('Berhasil', `${response.data.pesan}`, 'success');
                this.getAllSupplier();
              }
            });
        }
      });
    },

    getAllSupplier() {
      const itemCount = this.itemsPerRow;
      const halaman = this.table.pagination.page;
      const keyword = this.search;
      this.loading = true;

      axios
        .get(
          Constant.apiUrl.concat(
            `/master/supplier?page=${halaman}&itemCount=${itemCount}&search=${keyword}&sort=${this.search}`,
          ),
        )
        .then(response => {
          const { data } = response.data;

          this.allPages = Math.ceil(response.data.length / this.itemsPerRow);
          this.table.items = data.map((arr, idx) => {
            return {
              no: idx + 7 * halaman - 7,
              id: arr._id,
              name: arr.name,
              address: arr.detail.length
                ? arr.detail.reverse()[0].address
                : arr.detail[0].address,
              telp:
                arr.detail.length > 1
                  ? arr.detail.reverse()[0].phone
                  : arr.detail[0].phone,
              email:
                arr.detail.length > 1
                  ? arr.detail.reverse()[0].email
                  : arr.detail[0].email,
              dueDate:
                arr.detail.length > 1
                  ? arr.detail.reverse()[0].bill_due
                  : arr.detail[0].bill_due,
            };
          });
          this.loading = false;
        })
        .catch(() => {
          this.allPages = 1;
          this.table.items = [];
          this.loading = false;
        });
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.getAllSupplier();
      this.interval = setInterval(this.getAllSupplier, 60000);
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.input {
  max-width: 30%;
}

.hover:hover {
  color: #2196f3;
}

::v-deep {
  .v-data-table
    > .v-data-table__wrapper
    tbody
    tr.v-data-table__expanded__content {
    box-shadow: none;
    background-color: #f4f5f5;
  }

  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border: none;
  }
}
</style>
