import Constant from '@/const';
import axios from 'axios';

const getAssurance = async () => {
  try {
    const response = await axios.get(Constant.apiUrl.concat('/insurance/'));
    return response?.data?.data;
  } catch (error) {
    this.showErrorAxios(error);
  }
};

export default getAssurance;
