import { getPatientRegistration } from '../../../fetchApi/OldNewPatient/index';
const moment = require('moment-timezone');
const state = {
  patient: {
    loading: false,
    data: [],
    length: 0,
  },
};
const getters = {
  // API
  getPatient: state => state.patient,
};
const mutations = {
  setPatientLoading(state, payload) {
    state.patient.loading = payload;
  },
  setPatientData(state, payload) {
    state.patient.data = payload;
  },
  setPatientLength(state, payload) {
    state.patient.length = payload;
  },
  clearState(state) {
    state.patient = {
      loading: false,
      data: [],
      length: 0,
    };
  },
};
const actions = {
  // Resolve get generated rm number
  async resolveGetPatientRegistration(
    { commit },
    { page = 1, itemCount, name = '', address = '' } = {},
  ) {
    commit('setPatientLoading', true);
    try {
      const response = await getPatientRegistration({
        page,
        itemCount,
        name,
        address,
      });
      const data = response?.data?.map(item => {
        return {
          rmNumber: item.medical_record_number,
          name: item.social.name,
          birthDate: moment(item.social.birth.date)
            .locale('id')
            .format('L'),
          age: item.social.age,
          gender: item.social.gender === true ? 'Laki - laki' : 'Perempuan',
          address: item.social.address.text,
          lastVisit:
            item.last_visit === '-'
              ? '-'
              : moment(item.last_visit)
                  .locale('id')
                  .format('L'),
        };
      });
      const length = response.length;
      commit('setPatientData', data);
      commit('setPatientLength', length);
      commit('setPatientLoading', false);
    } catch (error) {
      commit('setPatientData', []);
      commit('setPatientLoading', false);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
