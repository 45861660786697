<template>
  <div style="width: 100vw">
    <!-- <v-container class="ma-0"> -->
    <div class="d-flex justify-space-between">
      <v-row>
        <v-col cols="8">
          <h3 class="pl-2 text-left primary--text font-weight-medium">
            Daftar Alkes Pasien
          </h3>
        </v-col>
        <v-col cols="4">
          <div class="d-flex justify-space-between">
            <h3 class="pl-2 text-left primary--text font-weight-medium">
              Tambah Alkes Baru
            </h3>
            <v-btn
              class="white--text caption"
              max-height="30"
              elevation="0"
              color="primary"
              @click="inputNewMedTool()"
            >
              <v-icon small center>mdi-plus</v-icon>
              <span class="font-weight-medium text-capitalize"
                >Tambah Alkes Baru</span
              >
              <v-icon small>mdi-arrow-right</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-row class="pt-2" style="height: 73vh">
      <br />
      <v-col cols="8" v-if="isLoadingList">
        <v-progress-circular
          indeterminate
          color="primary"
          size="120"
        ></v-progress-circular>
      </v-col>
      <v-col v-if="!isLoadingList" cols="8" class="medtool-list">
        <span
          v-if="!isLoadingList && input.length === 0"
          class="grey--text pt-5 body-1 text-center"
          style="padding-left: 63%; width: 131%"
          >Tidak ada permintaan alkes</span
        >
        <v-card
          :color="item.isActive && !isNew ? '#deeef9' : '#F4F5F5'"
          class="med-item rounded-lg"
          flat
          light
          :ripple="false"
          v-for="(item, index) in input"
          @click="onClick(item, index)"
          :key="item.id"
        >
          <v-card-actions>
            <v-card-title class="py-0 text-left body-2 font-weight-bold d-flex">
              <p class="ma-0 pa-0">
                {{ item.name }}
                <v-icon
                  small
                  color="red"
                  v-show="!item.available_stock"
                  class="pl-2"
                  >mdi-alert</v-icon
                >
              </p>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.stop="deleteItem(item)">
              <v-icon medium>mdi-delete </v-icon>
            </v-btn>
          </v-card-actions>
          <v-card-subtitle class="py-4 pb-3 subtitle">
            <v-row class="d-flex align-center">
              <v-col cols="8" class="py-0 pl-5">
                <span class="mr-1 caption">{{
                  `Jumlah Diminta ${item.requested_quantity || '-'}`
                }}</span>
                <span class="mr-1 caption">•</span>
                <span class="mr-3 caption">{{
                  `Jumlah Diberi ${item.quantity || '-'}`
                }}</span>
              </v-col>
              <v-col cols="4" class="text-right pa-0 pr-3 font-weight-medium">{{
                currency(item.price)
              }}</v-col>
            </v-row>
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="4" class="input-container">
        <v-card
          flat
          elevation="0"
          rounded="3"
          color="#DEEEF9"
          min-height="232px"
          class="pr-3"
          style="margin-bottom: 100px"
        >
          <v-form ref="form">
            <v-card-text>
              <v-row>
                <v-col
                  cols="5"
                  class="text-left pa-0 ma-0 font-weight-medium mt-2 pl-3"
                >
                  <label for="medtool-name">Nama Alkes</label>
                </v-col>
                <v-col cols="7" class="pa-0 ma-0 mt-1">
                  <v-combobox
                    :items="resource.medicalTools"
                    dense
                    :rules="rules.name"
                    :loading="isLoading"
                    single-line
                    :search-input.sync="searchTool"
                    append-icon="mdi-chevron-down"
                    id="medtool-name"
                    return-object
                    item-text="displayName"
                    item-value="id"
                    v-model="newMedTool.tool"
                  >
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-layout>
                            <v-flex class="d-flex justify-start">
                              <span class="text-left">
                                {{ `${data.item.displayName}` }}
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <v-layout>
                            <span class="mr-4">{{
                              `Sisa Stok : ${data.item.stock} ${data.item.packaging}`
                            }}</span>
                            <span>{{ `Rp. ${data.item.hpp || 0}` }}</span>
                          </v-layout>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="5"
                  class="text-left pa-0 ma-0 font-weight-medium mt-2 pl-3"
                >
                  <label for="unit">Satuan</label>
                </v-col>
                <v-col cols="7" class="pa-0 ma-0"
                  ><v-text-field
                    dense
                    single-line
                    id="unit"
                    :rules="rules.unit"
                    readonly
                    v-model="newMedTool.tool.packaging"
                /></v-col>
                <v-col
                  cols="5"
                  class="text-left pa-0 ma-0 font-weight-medium pl-3"
                >
                  <label for="reqQuantity">Jumlah Diminta</label>
                </v-col>
                <v-col cols="7" class="pa-0 ma-0"
                  ><v-text-field
                    :rules="rules.requested"
                    :suffix="newMedTool.tool.packaging"
                    dense
                    single-line
                    :value="newMedTool.requestedQuantity"
                    id="reqQuantity"
                    readonly
                    type="number"
                /></v-col>
                <!-- v-model="newMedTool.requestedQuantity" -->
                <v-col
                  cols="5"
                  class="text-left pa-0 ma-0 font-weight-medium pl-3"
                >
                  <label for="given">Jumlah Diberikan</label>
                </v-col>
                <v-col cols="7" class="pa-0 ma-0"
                  ><v-text-field
                    type="number"
                    dense
                    :rules="rules.given"
                    single-line
                    :suffix="newMedTool.tool.packaging"
                    id="given"
                    v-model="newMedTool.givenQuantity"
                /></v-col>
                <v-col cols="12" class="d-flex justify-center pr-0 py-0">
                  <div class="price-box font-weight-medium">
                    <div class="d-flex">
                      <v-icon class="mr-2">mdi-shopping-outline</v-icon>
                      <span>Total Harga Alkes</span>
                    </div>
                    <span>
                      {{
                        currency(
                          (newMedTool.tool.hpp || 0) *
                            (newMedTool.givenQuantity || 1),
                        )
                      }}
                    </span>
                  </div>
                </v-col>
                <v-col cols="12" class="pl-3 text-left">
                  <v-btn
                    class="primary--text caption"
                    style="border: 0.5px solid #137bc0 !important"
                    max-height="40"
                    elevation="0"
                    :loading="isNew ? loadingAdd : loadingEdit"
                    color="white"
                    @click="isNew ? addNewMedTool() : updateMedicalTools()"
                  >
                    <v-icon small center>mdi-plus</v-icon>
                    <span class="font-weight-medium px-2 text-capitalize">{{
                      isNew ? 'Tambahkan Alkes' : 'Ubah Alkes'
                    }}</span>
                    <v-icon small>mdi-arrow-right</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
        </v-card>
        <div class="d-flex pr-4 justify-end action-button">
          <v-btn
            v-if="totalMedtool"
            @click="save()"
            class="text-capitalize"
            :color="hadReviewed ? '#b8b6b6' : '#137BC0'"
            :dark="!hadReviewed"
            >Simpan</v-btn
          >
          <v-btn
            v-else
            @click="$emit('before')"
            class="text-capitalize"
            outlined
            color="#137BC0"
            >Sebelumnya</v-btn
          >
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Constant from '@/const';
import Swal from 'sweetalert2';
import moment from 'moment-timezone';
import AlertMixin from '@/mixin/alertMixin';
import formatMixin from '@/mixin/formatMixin';
import {
  getRecipeByIdAndCategory,
  updatePatientPharmacy,
} from '@/fetchApi/Pharmacy';

const _ = require('lodash');
const axios = require('axios');
export default {
  mixins: [AlertMixin, formatMixin],
  props: {
    consumable: {
      type: Array,
      default: () => [],
    },
    recipe: {
      type: Object,
    },
    id_recipe: {
      type: String,
    },
    totalData: {
      type: Number,
    },
    id_registration: {
      type: String,
    },
    totalMedtool: {
      type: Number,
    },
    idPharmacy: {
      type: String,
    },
    drugReview: { type: Object },
  },
  data() {
    return {
      input: [],
      isLoading: false,
      isLoadingList: false,
      hadReviewed: false,
      previousIndex: 0,
      loadingAdd: false,
      loadingEdit: false,
      isNew: true,
      data: null,
      loading: false,
      dataEdited: '',
      editedDataId: '',
      resource: {
        medicalTools: [],
      },
      newMedTool: {
        tool: '',
        unit: '',
        requestedQuantity: '',
        givenQuantity: '',
        packaging: '',
        price: 0,
      },
      snackbar: {
        failed: false,
        waiting: false,
        success: false,
      },
      inputAmount: '',
      searchTool: '',
      rules: {
        name: [v => !!v || 'Masukkan nama alkes'],
        unit: [v => !!v || 'Masukkan unit'],
        requested: [v => !!v || 'Masukkan jumlah diminta'],
        given: [v => !!v || 'Masukkan jumlah diberikan'],
      },
    };
  },
  watch: {
    consumable(val) {
      if (val) {
        this.setData();
      }
    },
    'newMedTool.givenQuantity'(val) {
      if (val);
      {
        if (this.isNew) this.newMedTool.requestedQuantity = val;
      }
    },
    searchTool(value) {
      if (value) {
        this.searchData(this, value);
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getmedicalToolsRequest();
      this.getMedicalTools();
    });
  },
  methods: {
    searchData: _.debounce((v, keyword) => v.getMedicalTools(keyword), 650),
    async save() {
      try {
        if (!this.hadReviewed) {
          const payload = {
            status: {
              type: 'production',
              id_staff: this.drugReview.id_staff,
            },
            administrative: this.$store.getters.recipeReview.administrative,
            pharmaceutical: this.$store.getters.recipeReview.pharmaceuticals,
            clinical: this.$store.getters.recipeReview.clinical,
            recipe_verifier: this.$store.getters.userLoggedIn.id,
          };
          const response = await updatePatientPharmacy({
            payload,
            id: this.idPharmacy,
          });
          if (response.status === 200) {
            Swal.fire(
              'Data Permintaan Obat dan Alkes Tersimpan',
              '',
              'success',
            );
            this.hadReviewed = true;
            this.$emit('review');
          }
          this.$store.commit('removeRecipeReview');
        }
      } catch (error) {
        const data = error.response.data;
        const messageData = data.pesan.replace('Bad Request: ', '');
        const showMessage = messageData.split(',');
        Swal.fire({
          title: `<small>${showMessage[0]}</small>`,
          text: showMessage[1],
          icon: 'error',
        });
      }
    },
    async getmedicalToolsRequest() {
      try {
        this.isLoadingList = true;
        const params = {
          category: 'requested',
          id: this.id_recipe,
        };

        const response = await getRecipeByIdAndCategory(params);
        const { medical_tools: medTools } = response.data.data;
        this.input = medTools.map((medtool, index) => {
          return {
            ...medtool,
            name: medtool.id_drugs.name,
            id: medtool.id_drugs._id,
            no: index + 1,
            amountDemanded: medtool.requested_quantity,
            amountGiven: medtool.quantity
              ? medtool.quantity
              : medtool.requested_quantity,
            unit: medtool.packaging,
            isMenu: false,
            isActive: false,
          };
        });
      } catch {
        this.input = [];
      } finally {
        this.isLoadingList = false;
      }
    },
    addNewMedTool() {
      if (this.$refs.form.validate()) {
        this.loadingAdd = true;
        const payload = {
          isNew: true,
          id_drugs: this.newMedTool.tool.id,
          id_stock: this.newMedTool.tool._id,
          availability_stock: this.newMedTool.tool.stock,
          recipe_number: this.totalData + 1,
          name: this.newMedTool.tool.name,
          requested_quantity: this.newMedTool.givenQuantity,
          quantity: this.newMedTool.givenQuantity,
          price: this.newMedTool.tool.hpp,
          // expiry: this.newMedTool.tool.expiry,
          packaging: this.newMedTool.tool.packaging,
          date: moment(new Date())
            .locale('id')
            .format(),
        };
        axios
          .put(Constant.apiUrl.concat(`/patient/recipe/${this.id_recipe}`), {
            medical_tools: payload,
            timestamps: {
              created_by: this.$store.getters.userLoggedIn.id,
            },
          })
          .then(response => {
            if (response.status === 200) {
              Swal.fire('Berhasil Menambahkan Alkes', '', 'success');
            }
          })
          .catch(error => {
            this.showErrorAxios(error);
          })
          .finally(() => {
            this.getmedicalToolsRequest();
            this.$emit('get-medtool');
            this.resetInput();
            this.$refs.form.resetValidation();
            this.loadingAdd = false;
          });
      }
    },
    inputNewMedTool() {
      this.isNew = true;
      this.$refs.form.resetValidation();
      this.newMedTool = {
        tool: '',
        unit: '',
        requestedQuantity: '',
        givenQuantity: '',
        packaging: '',
        price: 0,
      };
    },
    resetInput() {
      this.newMedTool = {
        tool: '',
        unit: '',
        requestedQuantity: '',
        givenQuantity: '',
        packaging: '',
        price: 0,
      };
    },
    onClick(item, index) {
      this.editedDataId = item._id;
      this.newMedTool = {
        tool: {
          hpp: item.pricePerItem,
          id: item.id,
          name: item.name,
          displayName: item.name,
          packaging: item.packaging,
        },
        price: item.price,
        recipe_number: item.recipe_number,
        packaging: item.packaging,
        requestedQuantity: item.requested_quantity,
        givenQuantity: item.quantity,
      };
      this.isNew = false;
      this.input[this.previousIndex] = {
        ...this.input[this.previousIndex],
        isActive: false,
      };
      this.input[index] = {
        ...this.input[index],
        isActive: true,
      };
      this.previousIndex = index;
    },
    getMedicalTools(keyword) {
      this.isLoading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/stock/all/names?page=1&itemCount=30&category=Alat Kesehatan&name=${
              !keyword ? '' : keyword
            }`,
          ),
        )
        .then(response => {
          const tools = response.data.data;
          this.resource.medicalTools = tools.map(arr => {
            return {
              id: arr.id_drugs,
              _id: arr._id,
              name: arr.drugs.detail.name,
              displayName: `${arr.drugs.detail.name} (${arr.drugs.detail.factory})`,
              packaging: arr.package,
              hpp: arr.price,
              expiry: arr.expiry_date,
              stock: arr.quantity,
            };
          });
        })
        .catch(() => {
          this.resource.medicalTools = [];
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    deleteItem(tool) {
      Swal.fire({
        text: 'Apakah Anda Yakin Menghapus Alkes ini?',
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: 'Batal',
        confirmButtonText: 'Lanjutkan',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        icon: 'warning',
      }).then(result => {
        if (result.isConfirmed) {
          axios
            .put(
              Constant.apiUrl.concat(
                `/patient/recipe/${this.id_recipe}/${tool._id}`,
              ),
            )
            .then(response => {
              if (response) {
                if (result.isConfirmed) {
                  this.showFillSuccess(
                    'Berhasil Menghapus',
                    'Alkes berhasil dihapus',
                  );
                }
              }
            })
            .catch(error => {
              this.showErrorAxios(error);
            })
            .finally(() => {
              this.getmedicalToolsRequest();
              this.$emit('get-medtool');
            });
        }
      });
    },
    updateConsumable() {
      const id_consumable = this.consumable.id;
      const payload = this.input.map(data => {
        return {
          _id: data.id,
          quantity: parseInt(data.amountGiven),
          staff_approve: this.$store.getters.userLoggedIn.name,
        };
      });

      axios
        .put(Constant.apiUrl.concat(`/patient/consumable/${id_consumable}`), {
          item: payload,
        })
        .then(response => {
          if (response.status === 200) {
            Swal.fire(
              'Data Permintaan Obat dan Alkes Tersimpan',
              '',
              'success',
            );
          }
        });
    },

    getDrugById(id, index) {
      axios
        .get(Constant.apiUrl.concat('/master/drugs/').concat(id))
        .then(response => {
          const drugs = response.data.data;
          this.input[index] = {
            ...this.input[index],
            name: `${drugs.detail.name}  ${
              drugs.detail.factory ? drugs.detail.factory : ''
            }`,
            id: drugs._id,
          };
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    setData() {
      this.input = this.consumable.item.map((drug, index) => {
        this.getDrugById(drug.id_drugs._id, index);
        return {
          ...drug,
          no: index + 1,
          name: `${drug.name} `,
          amountDemanded: drug.requested_quantity,
          amountGiven: drug.quantity ? drug.quantity : drug.requested_quantity,
          unit: drug.packaging,
          isMenu: false,
          isActive: false,
        };
      });
    },
    updateMedicalTools() {
      if (this.$refs.form.validate()) {
        this.snackbar.waiting = true;
        const payload = {
          _id: this.editedDataId,
          id_drugs: this.newMedTool.tool.id,
          id_stock: this.newMedTool.tool._id,
          quantity: +this.newMedTool.givenQuantity,
          requested_quantity: +this.newMedTool.requestedQuantity,
          packaging: this.newMedTool.packaging,
          recipe_number: this.newMedTool.recipe_number,
          name: this.newMedTool.tool.name,
          price: this.newMedTool.price,
          date: moment(new Date())
            .locale('id')
            .format(),
          staff_approve: this.$store.getters.userLoggedIn.name,
        };
        axios
          .put(Constant.apiUrl.concat(`/patient/recipe/${this.id_recipe}`), {
            medical_tools: payload,
          })
          .then(response => {
            if (response.status === 200) {
              Swal.fire('Data Permintaan Alkes Berhasil Diubah', '', 'success');
            }
          })
          .catch(error => {
            this.showErrorAxios(error);
          })
          .finally(() => {
            this.getmedicalToolsRequest();
            this.$emit('get-medtool');
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-container {
  position: relative;

  .action-button {
    position: absolute;
    width: 100%;
    bottom: 2rem;
    right: 1rem;
  }
}
.price-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #f0f9ff;
  border-radius: 4px;
  border: 0.5px solid #bdbdbd;
  padding: 8px;
  font-size: 0.9rem;
}
.medtool-list {
  height: 100%;
  overflow-y: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(auto-fill, 100px);
  grid-column-gap: 5px;
  grid-row-gap: 10px;
  &::-webkit-scrollbar {
    width: 3px;
    background-color: rgb(163, 163, 163);
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: rgb(85, 84, 84);
  }

  .med-item {
    width: 90%;
    max-height: 90px;
    margin: 0 auto;
    cursor: pointer;

    .subtitle {
      line-height: 5px;
      font-size: 15px;
      text-align: left;

      .icon {
        margin-top: -30px;
        float: right;
        margin-right: 0px;
      }
    }
  }
}
.change-amount-given {
  width: 250px;
  height: 150px;
  background-color: white;
}

.input-amount {
  ::v-deep .v-input__slot {
    min-height: 0px;
    height: 30px;
  }
}

.body-container {
  margin-top: 10px;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.btn-create-amount {
  min-width: 100px;
  height: 20px;
  min-height: 0px;
  font-weight: 500;
  background-color: #3498db !important;
  color: white;
}

.btn-entry {
  border: 2px solid #3498db;
  color: #3498db;
  outline: cadetblue;
  background-color: white !important;
}

.action-layout {
  display: flex;

  .action-footer {
    justify-content: end;
    align-items: end;
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
    }

    .btn-create {
      margin: 0 20px;
      min-width: 145px;
      height: 37px;
      font-weight: 700;
      background-color: #3498db !important;
      color: white;
    }

    .btn-entry {
      border: 2px solid #3498db;
      color: #3498db;
      outline: cadetblue;
      background-color: white !important;
    }
  }
}
</style>
