<template>
  <v-card>
    <v-card-title class="d-flex justify-center black--text headline"
      >Ubah Tarif Layanan</v-card-title
    >
    <v-container fluid class="container-layout">
      <!-- Tarif Layanan -->

      <v-row>
        <v-col cols="6" lg="4">
          <v-form ref="inputService" lazy-validation>
            <v-row no-gutters align="baseline" class="px-7">
              <v-col cols="4">
                <div class="text-left">
                  <label
                    class="required font-weight-medium black--text"
                    for="Nama Layanan"
                  >
                    Nama Layanan</label
                  >
                </div>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="input.service"
                  :rules="[
                    v => !!v || 'Nama Layanan Harus diisi',
                    v => v.length >= 3 || 'Nama Layanan Minimal 3 Karakter',
                    v =>
                      v.length <= 100 || 'Nama Layanan Maksimal 100 Karakter',
                  ]"
                  placeholder="Masukkan Nama Layanan"
                  dense
                />
              </v-col>
            </v-row>
            <v-row no-gutters align="baseline" class="px-7">
              <v-col cols="4">
                <div class="text-left">
                  <label
                    class="required font-weight-medium black--text"
                    for="Kategori"
                  >
                    Kategori Layanan</label
                  >
                </div>
              </v-col>
              <v-col cols="8">
                <v-select
                  v-model="input.category"
                  :items="resource.category"
                  placeholder="Pilih Kategori Layanan"
                  dense
                  @change="getSubCategory()"
                  clearable
                  :rules="[v => !!v || 'Kategori Layanan Harus diisi']"
                  append-icon="mdi-chevron-down"
                />
              </v-col>
            </v-row>
            <v-row
              v-if="this.input.category === 'Laboratorium'"
              no-gutters
              align="baseline"
              class="px-7"
            >
              <v-col cols="4">
                <div class="text-left">
                  <label class="font-weight-medium black--text">
                    Kode LIS</label
                  >
                </div>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="input.codeLis"
                  @keydown="checkKeyDownAlphaNumeric($event)"
                  dense
                  clearable
                />
              </v-col>
            </v-row>
            <v-row no-gutters align="baseline" class="px-7">
              <v-col cols="4">
                <div class="text-left">
                  <label
                    class="font-weight-medium black--text "
                    for="Layanan Induk"
                  >
                    Layanan Induk</label
                  >
                </div>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-if="this.input.category !== 'Laboratorium'"
                  v-model="input.sub_category"
                  clearable
                  placeholder="Masukkan Layanan Induk"
                  dense
                  :rules="[
                    v => !!v || true,
                    v =>
                      v.length >= 3 ||
                      v.length <= 0 ||
                      'Nama Layanan Induk Minimal 3 Karakter',
                    v =>
                      v.length <= 100 ||
                      'Nama Layanan Induk Maksimal 100 Karakter',
                  ]"
                />
                <v-autocomplete
                  v-if="this.input.category === 'Laboratorium'"
                  :loading="subcategory_loading"
                  :items="[
                    'Pemeriksaan Hematologi',
                    'Pemeriksaan Kimia Klinik',
                    'Pemeriksaan Imuno - Serologi',
                    'Pemeriksaan Urinalisa',
                    'Pemeriksaan Faeces',
                    'Pemeriksaan Elektrolit',
                  ]"
                  v-model="input.sub_category"
                  placeholder="Masukkan Layanan Induk"
                  clearable
                  dense
                  append-icon="mdi-chevron-down"
                />
              </v-col>
            </v-row>
            <v-row no-gutters align="baseline" class="px-7">
              <v-col cols="4">
                <div class="text-left">
                  <label
                    class="required font-weight-medium black--text"
                    for="Unit"
                  >
                    Unit</label
                  >
                </div>
              </v-col>
              <v-col cols="8">
                <!-- <v-select
                  v-model="input.unit"
                  :items="resource.unit"
                  item-text="name"
                  return-object
                  placeholder="Pilih Unit"
                  dense
                /> -->

                <v-select
                  class="hide-input"
                  v-model="input.unit"
                  :items="resource.unit"
                  :rules="[v => !!v || 'Unit Harus diisi']"
                  @change="getSubCategory()"
                  item-text="name"
                  placeholder="Pilih Unit"
                  dense
                  multiple
                  single-line
                  hide-selected
                  return-object
                  append-icon="mdi-chevron-down"
                />
              </v-col>
            </v-row>
            <v-row no-gutters align="baseline" class="px-7 mb-8">
              <v-col cols="4">
                <div class="text-left">
                  <label class="font-weight-medium black--text ">
                    Tipe Penjamin</label
                  >
                </div>
              </v-col>
              <v-col cols="8" class="text-left">
                <label class="text-uppercase">{{
                  servicesDetail.assurance
                }}</label>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col class="input-main text-left" cols="6" lg="8">
          <div class="text-left ml-1 mb-2">
            <label> Unit</label>
          </div>
          <v-chip
            v-for="(unit, index) in input.unit"
            :key="unit.id"
            class="ma-1"
            @click:close="input.unit.splice(index, 1)"
            close
          >
            {{ unit.name }}
          </v-chip>
          <v-btn
            @click="deleteAllUnit()"
            class="text-none"
            outlined
            color="error"
            absolute
            left
            bottom
          >
            Hapus semua unit
          </v-btn>
        </v-col>
      </v-row>
      <v-spacer></v-spacer>
      <v-card-title class="pl-7 text-h6 blue--text pt-8"
        >Jasa Medis</v-card-title
      >
      <v-row>
        <v-col cols="4">
          <v-row no-gutters align="baseline" class="px-7">
            <v-col cols="6">
              <div class="text-left">
                <label
                  class="text-subtitle-2 black--text"
                  for="Jasa Medis Dokter Umum"
                >
                  Jasa Medis Dokter Umum</label
                >
              </div>
            </v-col>
            <v-col cols="6">
              <v-currency-field
                v-model.number="input.cost.medical_service.staff"
                prefix="Rp."
                :rules="medicalServiceRules"
              />
            </v-col>
          </v-row>
          <v-row no-gutters align="baseline" class="px-7">
            <v-col cols="6">
              <div class="text-left">
                <label
                  class="text-subtitle-2 black--text "
                  for="Jasa Medis Dokter Spesialis"
                >
                  Jasa Medis Dokter Spesialis</label
                >
              </div>
            </v-col>
            <v-col cols="6">
              <v-currency-field
                v-model.number="input.cost.medical_service.specialize"
                prefix="Rp."
                :rules="medicalServiceRules"
              />
            </v-col>
          </v-row>
          <v-row no-gutters align="baseline" class="px-7">
            <v-col cols="6">
              <div class="text-left">
                <label
                  class="text-subtitle-2 black--text "
                  for="Jasa Medis Perawat"
                >
                  Jasa Medis Perawat</label
                >
              </div>
            </v-col>
            <v-col cols="6">
              <v-currency-field
                v-model.number="input.cost.medical_service.nurse"
                prefix="Rp."
                :rules="medicalServiceRules"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4">
          <v-row no-gutters align="baseline" class="px-7">
            <v-col cols="6">
              <div class="text-left">
                <label
                  class="text-subtitle-2 black--text "
                  for="Jasa Medis Bidan"
                >
                  Jasa Medis Bidan</label
                >
              </div>
            </v-col>
            <v-col cols="6">
              <v-currency-field
                v-model.number="input.cost.medical_service.midwife"
                prefix="Rp."
                :rules="medicalServiceRules"
              />
            </v-col>
          </v-row>
          <v-row no-gutters align="baseline" class="px-7">
            <v-col cols="6">
              <div class="text-left">
                <label
                  class="text-subtitle-2 black--text"
                  for="Jasa Medis Psikologi"
                >
                  Jasa Medis Psikologi</label
                >
              </div>
            </v-col>
            <v-col cols="6">
              <v-currency-field
                v-model.number="input.cost.medical_service.psychologist"
                prefix="Rp."
                :rules="medicalServiceRules"
              />
            </v-col>
          </v-row>
          <v-row no-gutters align="baseline" class="px-7">
            <v-col cols="6">
              <div class="text-left">
                <label
                  class="text-subtitle-2 black--text "
                  for="Jasa Medis Ahli Gizi"
                >
                  Jasa Medis Ahli Gizi</label
                >
              </div>
            </v-col>
            <v-col cols="6">
              <v-currency-field
                v-model.number="input.cost.medical_service.nutritionists"
                prefix="Rp."
                :rules="medicalServiceRules"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4">
          <v-row no-gutters align="baseline" class="px-7">
            <v-col cols="6">
              <div class="text-left">
                <label
                  class="text-subtitle-2 black--text "
                  for="Jasa Medis Analis Kesehatan"
                >
                  Jasa Medis Analis Kesehatan</label
                >
              </div>
            </v-col>
            <v-col cols="6">
              <v-currency-field
                v-model.number="input.cost.medical_service.health_analyst"
                prefix="Rp."
                :rules="medicalServiceRules"
              />
            </v-col>
          </v-row>
          <v-row no-gutters align="baseline" class="px-7">
            <v-col cols="6">
              <div class="text-left">
                <label
                  class="text-subtitle-2 black--text "
                  for="Jasa Medis Radiografer"
                >
                  Jasa Medis Radiografer</label
                >
              </div>
            </v-col>
            <v-col cols="6">
              <v-currency-field
                v-model.number="input.cost.medical_service.radiographer"
                prefix="Rp."
                :rules="medicalServiceRules"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- Bahan Medis Habis Pakai -->
      <v-card-title class="pl-7 text-h6 blue--text"
        >Alat Medis Habis Pakai</v-card-title
      >
      <v-row class="mb-7">
        <v-col cols="4">
          <v-row no-gutters align="baseline" class="px-7">
            <v-col cols="6">
              <div class="text-left">
                <label
                  class="text-subtitle-2 black--text"
                  for="Alat Medis Habis Pakai"
                >
                  Total Alat Medis Habis Pakai</label
                >
              </div>
            </v-col>
            <v-col cols="6">
              <v-currency-field v-model="totalMedicalTools" prefix="Rp." />
            </v-col>
          </v-row>
          <v-form ref="formMedicalTools" lazy-validation>
            <v-row no-gutters align="baseline" class="px-7">
              <v-col cols="6">
                <div class="text-left">
                  <label
                    class="text-subtitle-2 black--text "
                    for="Alat Medis Habis Pakai"
                  >
                    Alat Medis Habis Pakai</label
                  >
                </div>
              </v-col>
              <v-col cols="6">
                <v-combobox
                  placeholder="Pilih Alat Medis"
                  dense
                  :items="resource.medicalTools"
                  :rules="[v => !!v || 'Alat Medis Habis Pakai Harus diisi']"
                  item-text="name"
                  v-model="input.cost.medicalTools.detail"
                  clearable
                />
              </v-col>
            </v-row>
            <v-row no-gutters align="baseline" class="px-7">
              <v-col cols="6">
                <div class="text-left">
                  <label class="text-subtitle-2 black--text " for="Jumlah">
                    Jumlah</label
                  >
                </div>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  placeholder="Masukkan Jumlah"
                  suffix="pcs"
                  type="number"
                  v-model.number="input.cost.medicalTools.quantity"
                  :rules="[v => !!v || 'Jumlah Harus diisi']"
                />
              </v-col>
            </v-row>
          </v-form>
          <v-row no-gutters align="baseline">
            <v-col cols="7" offset="5">
              <v-btn
                color="primary"
                tile
                depressed
                class="text-none"
                @click="addMedicalTools()"
                outlined
              >
                Tambahkan Ke List
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="8">
          <!-- <v-data-table
            :headers="table.medicalTools.headers"
            :items="table.medicalTools.items"
            :items-per-page="5"
          >
          </v-data-table> -->
          <v-data-table
            :headers="table.medicalTools.headers"
            :items="table.medicalTools.items"
            :items-per-page="table.medicalTools.length"
          >
            <template v-slot:[`item.no`]="{ item }">
              <td class="text-left">
                {{ table.medicalTools.items.indexOf(item) + 1 }}
              </td>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <td class="text-left">{{ item.name }}</td>
            </template>
            <template v-slot:[`item.quantity`]="{ item }">
              <td class="text-left">{{ item.quantity }}</td>
            </template>
            <template v-slot:[`item.unit`]="{ item }">
              <td class="text-left">{{ item.unit }}</td>
            </template>
            <template v-slot:[`item.price`]="{ item }">
              <td class="text-left">{{ currency(item.price) }}</td>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-4 icon-style"
                    dense
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteMedicalTools(item)"
                    color="red"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span> Hapus </span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <!-- Alat Dan Bahan Medis Habis Pakai -->

      <v-card-title class="pl-7 text-h6 blue--text"
        >Bahan Medis Habis Pakai</v-card-title
      >
      <v-row class="mb-7">
        <v-col cols="4">
          <v-row no-gutters align="baseline" class="px-7">
            <v-col cols="6">
              <div class="text-left">
                <label
                  class="text-subtitle-2 black--text"
                  for="Bahan Medis Habis Pakai"
                >
                  Total Bahan Medis Habis Pakai</label
                >
              </div>
            </v-col>
            <v-col cols="6">
              <v-currency-field v-model="totalConsumables" prefix="Rp." />
            </v-col>
          </v-row>
          <v-form ref="formConsumables" lazy-validation>
            <v-row no-gutters align="baseline" class="px-7">
              <v-col cols="6">
                <div class="text-left">
                  <label
                    class="text-subtitle-2 black--text "
                    for="Bahan Medis Habis Pakai"
                  >
                    Bahan Medis Habis Pakai</label
                  >
                </div>
              </v-col>
              <v-col cols="6">
                <v-combobox
                  placeholder="Pilih Bahan Medis"
                  dense
                  :items="resource.consumables"
                  item-text="name"
                  v-model="input.cost.consumables.detail"
                  :loading="isLoading"
                  :rules="[v => !!v || 'Bahan Medis Habis Pakai Harus diisi']"
                />
              </v-col>
            </v-row>
            <v-row no-gutters align="baseline" class="px-7">
              <v-col cols="6">
                <div class="text-left">
                  <label class="text-subtitle-2 black--text " for="Jumlah">
                    Jumlah</label
                  >
                </div>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  placeholder="Masukkan Jumlah"
                  suffix="pcs"
                  v-model.number="input.cost.consumables.quantity"
                  type="number"
                  :rules="[v => !!v || 'Jumlah Harus diisi']"
                />
              </v-col>
            </v-row>
          </v-form>
          <v-row no-gutters align="baseline">
            <v-col cols="7" offset="5">
              <v-btn
                color="primary"
                tile
                depressed
                class="text-none"
                @click="addConsumables()"
                outlined
              >
                Tambahkan Ke List
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="8">
          <!-- <v-data-table
            :headers="table.consumables.headers"
            :items="table.consumables.items"
            :items-per-page="5"
          >
            
          </v-data-table> -->
          <v-data-table
            :headers="table.consumables.headers"
            :items="table.consumables.items"
            :items-per-page="table.consumables.length"
          >
            <template v-slot:[`item.no`]="{ item }">
              <td class="text-left">
                {{ table.consumables.items.indexOf(item) + 1 }}
              </td>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <td class="text-left">{{ item.name }}</td>
            </template>
            <template v-slot:[`item.quantity`]="{ item }">
              <td class="text-left">{{ item.quantity }}</td>
            </template>
            <template v-slot:[`item.unit`]="{ item }">
              <td class="text-left">{{ item.unit }}</td>
            </template>
            <template v-slot:[`item.price`]="{ item }">
              <td class="text-left">{{ currency(item.price) }}</td>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-4 icon-style"
                    dense
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteComsumables(item)"
                    color="red"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span> Hapus </span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-row no-gutters align="baseline" class="px-16">
            <v-col cols="6">
              <div class="text-left">
                <label class="font-weight-medium black--text " for="Beban Unit">
                  Beban Unit</label
                >
              </div>
            </v-col>
            <v-col cols="6">
              <v-currency-field
                v-model.number="input.cost.unit_load"
                prefix="Rp."
              />
            </v-col>
          </v-row>

          <v-row no-gutters align="baseline" class="px-16">
            <v-col cols="6">
              <div class="text-left">
                <label class="font-weight-medium black--text " for="Margin">
                  Margin</label
                >
              </div>
            </v-col>
            <v-col cols="6">
              <v-currency-field
                v-model.number="input.cost.margin"
                prefix="Rp."
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-row>
            <v-col cols="12">
              <v-row no-gutters align="baseline" class="px-16">
                <v-col cols="12">
                  <v-row
                    class="primary total white--text mt-8"
                    align="baseline"
                  >
                    <span class="pr-3">Total Biaya Layanan</span>
                    <label>{{ totalPrice }}</label>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" outlined width="100" @click="onCloseButtonClick()">
        Kembali
      </v-btn>
      <v-btn
        color="primary"
        width="100"
        depressed
        @click="
          addNewServices();
          updateData();
        "
      >
        Simpan
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Constant from '@/const';
import jwtMixin from '@/mixin/jwtMixin';
import formatMixin from '@/mixin/formatMixin';
import Swal from 'sweetalert2';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'UpdateServices',
  mixins: [jwtMixin, formatMixin],
  props: ['servicesDetail'],
  data() {
    return {
      isLoading: false,
      search: null,
      menu: false,
      subcategory_loading: false,
      medicalTools: [],
      consumables: [],
      resource: {
        unit: [],
        category: [
          'Administrasi',
          'Konsultasi/ Tindakan Medis',
          'Pemeriksaan Penunjang',
          'Pelayanan Penunjang',
          'Laboratorium',
        ],
        sub_category: [],
        room: [],
        medicalTools: [],
        consumables: [],
      },

      input: {
        service: this.servicesDetail.servicesName,
        category: this.servicesDetail.category,
        codeLis: this.servicesDetail.codeLis,
        sub_category: this.servicesDetail.subCategories,
        unit: this.servicesDetail.unit.map(unit => {
          return {
            id: unit._id,
            name: unit.name,
          };
        }),
        totalPrice: this.servicesDetail.total,
        room: '',
        cost: {
          assurance: this.servicesDetail.assurance,
          medical_service: {
            staff: _.get(this.servicesDetail, 'cost.medical_service.staff', 0),
            specialize: _.get(
              this.servicesDetail,
              'cost.medical_service.specialize',
              0,
            ),
            nurse: _.get(this.servicesDetail, 'cost.medical_service.nurse', 0),
            midwife: _.get(
              this.servicesDetail,
              'cost.medical_service.midwife',
              0,
            ),
            psychologist: _.get(
              this.servicesDetail,
              'cost.medical_service.psychologist',
              0,
            ),
            nutritionists: _.get(
              this.servicesDetail,
              'cost.medical_service.nutritionists',
              0,
            ),
            health_analyst: _.get(
              this.servicesDetail,
              'cost.medical_service.health_analyst',
              0,
            ),
            radiographer: _.get(
              this.servicesDetail,
              'cost.medical_service.radiographer',
              0,
            ),
          },
          consumables: {
            detail: '',
            quantity: null,
          },
          medicalTools: {
            detail: '',
            quantity: null,
          },
          unit_load: _.get(this.servicesDetail, 'cost.unit_load', 0),
          margin: _.get(this.servicesDetail, 'cost.margin', 0),
        },
      },
      table: {
        medicalTools: {
          headers: [
            {
              text: 'No',
              align: 'start',
              sortable: false,
              value: 'no',
            },
            { text: 'Alat Medis Habis Pakai', value: 'name' },
            { text: 'Jumlah', value: 'quantity' },
            { text: 'Satuan', value: 'unit' },
            { text: 'Harga', value: 'price' },
            { text: '', value: 'actions', sortable: false },
          ],
          items: [],
        },
        consumables: {
          headers: [
            {
              text: 'No',
              align: 'start',
              sortable: false,
              value: 'no',
            },
            { text: 'Bahan Medis Habis Pakai', value: 'name' },
            { text: 'Jumlah', value: 'quantity' },
            { text: 'Satuan', value: 'unit' },
            { text: 'Harga', value: 'price' },
            { text: '', value: 'actions', sortable: false },
          ],
          items: [],
        },
      },
      medicalServiceRules: [
        v => !!v || 'Tidak bisa kosong',
        v =>
          v === '0'
            ? true
            : v.length >= 3 || v.length <= 0 || 'Minimal 3 Karakter',
        v => v.length <= 100 || 'Maksimal 100 Karakter',
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.runApiCalls(() => {
        this.getUnit();
        this.getSubCategory();
        this.getConsumables('');
        this.getMedicalTools('');
        this.getCostMaterialTools();
      });
    });
  },
  computed: {
    isSavedReady() {
      return (
        this.input.cost.medical_service.staff > 0 ||
        this.input.cost.medical_service.specialize > 0 ||
        this.input.cost.medical_service.nurse > 0 ||
        this.input.cost.medical_service.midwife > 0 ||
        this.input.cost.medical_service.psychologist > 0 ||
        this.input.cost.medical_service.nutritionists > 0 ||
        this.input.cost.medical_service.health_analyst > 0 ||
        this.input.cost.medical_service.radiographer > 0 ||
        this.input.cost.unit_load > 0 ||
        this.input.cost.margin > 0
      );
    },
    totalPrice() {
      const biaya =
        Number(this.input.cost.medical_service.staff) +
        Number(this.input.cost.medical_service.specialize) +
        Number(this.input.cost.medical_service.nutritionists) +
        Number(this.input.cost.medical_service.health_analyst) +
        Number(this.input.cost.medical_service.psychologist) +
        Number(this.input.cost.medical_service.nurse) +
        Number(this.input.cost.medical_service.radiographer) +
        Number(this.input.cost.medical_service.midwife) +
        Number(this.input.cost.margin) +
        Number(this.input.cost.unit_load);
      const totalFromTables = this.totalConsumables + this.totalMedicalTools;
      return this.currency(biaya + totalFromTables);
    },
    totalConsumables() {
      return this.table.consumables.items.reduce(
        (a, { price }) => a + price,
        0,
      );
    },
    totalMedicalTools() {
      return this.table.medicalTools.items.reduce(
        (a, { price }) => a + price,
        0,
      );
    },
  },
  methods: {
    onCloseButtonClick() {
      this.$emit('close-dialog');
    },
    updateData() {
      this.$emit('update-data');
    },
    deleteAllUnit() {
      this.input.unit = null;
    },
    checkKeyDownAlphaNumeric(event) {
      if (!/[a-zA-Z0-9\s]/.test(event.key)) {
        this.ignoredValue = event.key ? event.key : '';
        event.preventDefault();
      }
    },
    getCostMaterialTools() {
      this.table.medicalTools.items = this.servicesDetail.cost.consumable_tools.map(
        item => {
          return {
            quantity: item.quantity,
            id: item.id_drugs,
            name: item.detail.detail[0].name,
            unit:
              item.detail.detail[0].packaging.length === 0
                ? '-'
                : item.detail.detail[0].packaging[0].package,
            price: item.detail.detail[0].hna * item.quantity,
          };
        },
      );
      this.table.consumables.items = this.servicesDetail.cost.consumable_material.map(
        item => {
          return {
            quantity: item.quantity,
            id: item.id_drugs,
            name: item.detail.detail[0].name,
            unit:
              item.detail.detail[0].packaging.length === 0
                ? '-'
                : item.detail.detail[0].packaging[0].package,
            price: item.detail.detail[0].hna * item.quantity,
          };
        },
      );
    },
    getSubCategory() {
      this.is_subcategory_loading = true;
      axios
        .get(`${Constant.apiUrl}/master/service`)
        .then(res => {
          const { data } = res.data;
          if (this.input.unit.name && !this.input.category) {
            this.resource.sub_category = data
              .filter(i => {
                return (
                  i.unit.length > 0 && i.unit[0].name === this.input.unit.name
                );
              })
              .map(item => {
                return item.sub_category;
              });
          } else if (!this.input.unit.name && this.input.category) {
            this.resource.sub_category = data
              .filter(i => {
                return i.category === this.input.category;
              })
              .map(item => {
                return item.sub_category;
              });
          } else if (this.input.unit.name && this.input.category) {
            this.resource.sub_category = data
              .filter(i => {
                return (
                  i.category === this.input.category &&
                  i.unit[0].name === this.input.unit.name
                );
              })
              .map(item => {
                return item.sub_category;
              });
          } else {
            this.resource.sub_category = data.map(item => {
              return item.sub_category;
            });
          }
        })
        .catch(() => {
          this.resource.sub_category = [];
        })
        .finally(() => (this.is_subcategory_loading = false));
    },
    getUnit() {
      axios.get(`${Constant.apiUrl}/master/unit`).then(response => {
        const unitData = response.data.data;
        this.resource.unit = unitData.map(unit => {
          return {
            name: unit.name,
            id: unit._id,
          };
        });
      });
    },
    addNewServices() {
      if (!this.isSavedReady) {
        Swal.fire(
          'Gagal Menyimpan',
          'Anda harus mengisi minimal salah satu dari data Jasa Medis, Beban Unit, atau Margin',
          'warning',
        );
        return;
      }
      if (this.$refs.inputService.validate()) {
        const payload = {
          name: this.input.service,
          category: this.input.category,
          code_lis:
            this.input.category !== 'Laboratorium' ? '' : this.input.codeLis,
          sub_category: this.input.sub_category,
          id_room: this.input.room.id,
          unit: this.input.unit.map(arr => arr.id),
          cost: [
            {
              assurance: this.input.cost.assurance || 0,
              medical_service: this.input.cost.medical_service || 0,
              consumable_material: this.table.consumables.items.map(item => {
                return {
                  id_drugs: item.id,
                  quantity: item.quantity,
                };
              }),
              consumable_tools: this.table.medicalTools.items.map(item => {
                return {
                  id_drugs: item.id,
                  quantity: item.quantity,
                };
              }),
              unit_load: this.input.cost.unit_load,
              margin: this.input.cost.margin,
            },
          ],
        };
        axios
          .put(
            `${Constant.apiUrl}/master/service/${this.servicesDetail.id}?insurance_type=${this.servicesDetail.assurance}`,
            payload,
          )
          .then(response => {
            if (response.status === 200) {
              Swal.fire(
                'Berhasil',
                'Data Tarif Layanan Berhasil Diubah',
                'success',
              );
              this.onCloseButtonClick();
            }
          })
          .catch(error => {
            if (error.response) {
              const message = error.response.data.pesan;
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: message,
              });
            } else if (error.request) {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Tidak dapat menyambung ke server',
              });
            }
          });
      }
    },
    getConsumables(keyword) {
      this.isLoading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/master/drugs?page=&itemCount=&search=${keyword}&sort=&category=BHP`,
          ),
        )
        .then(response => {
          const consumables = response.data.data;
          this.resource.consumables = consumables.map(arr => {
            let defaultPackage = '';

            arr.detail.packaging.find(v => {
              if (v.isDefault === true) {
                defaultPackage = v.package;
              }
            });
            return {
              id: arr._id,
              name: arr.detail.name,
              hpp: arr.detail.hpp,
              hna: arr.detail.hna,
              strength: arr.detail.strength,
              strength_unit: arr.detail.strength_unit,
              packaging:
                arr.detail.packaging.length !== 0 ? defaultPackage : '-',
            };
          });
          this.isLoading = false;
        })
        .catch(() => {
          this.resource.consumables = [];
        });
    },
    getMedicalTools(keyword) {
      this.isLoading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/master/drugs?page=&itemCount=&search=${keyword}&sort=&category=Alat Kesehatan`,
          ),
        )
        .then(response => {
          const medicalTools = response.data.data;
          this.resource.medicalTools = medicalTools.map(arr => {
            let defaultPackage = '';
            arr.detail.packaging.find(v => {
              if (v.isDefault === true) {
                defaultPackage = v.package;
              }
            });
            return {
              id: arr._id,
              name: arr.detail.name,
              hpp: arr.detail.hpp,
              hna: arr.detail.hna,
              packaging:
                arr.detail.packaging.length !== 0 ? defaultPackage : '-',
            };
          });
          this.isLoading = false;
        })
        .catch(() => {
          this.resource.medicalTools = [];
        });
    },
    addConsumables() {
      if (this.$refs.formConsumables.validate()) {
        this.table.consumables.items.push({
          no: this.table.consumables.items.length + 1,
          id: this.input.cost.consumables.detail.id,
          name: this.input.cost.consumables.detail.name,
          quantity: this.input.cost.consumables.quantity,
          unit: this.input.cost.consumables.detail.packaging,
          price:
            this.input.cost.consumables.detail.hna *
            this.input.cost.consumables.quantity,
        });
      }

      this.input.cost.consumables = {
        detail: '',
        quantity: '',
      };
      this.$refs.formConsumables.resetValidation();
    },
    addMedicalTools() {
      if (this.$refs.formMedicalTools.validate()) {
        this.table.medicalTools.items.push({
          no: this.table.medicalTools.items.length + 1,
          id: this.input.cost.medicalTools.detail.id,
          name: this.input.cost.medicalTools.detail.name,
          quantity: this.input.cost.medicalTools.quantity,
          unit: this.input.cost.medicalTools.detail.packaging,
          price:
            this.input.cost.medicalTools.detail.hna *
            this.input.cost.medicalTools.quantity,
        });
      }

      this.input.cost.medicalTools = {
        detail: '',
        quantity: '',
      };
      this.$refs.formMedicalTools.resetValidation();
    },
    deleteMedicalTools(item) {
      const index = this.table.medicalTools.items.indexOf(item);
      this.table.medicalTools.items.splice(index, 1);
    },
    deleteComsumables(item) {
      const index = this.table.consumables.items.indexOf(item);
      this.table.consumables.items.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-main {
  position: relative;
  width: 100%;
  height: 250px;
}
.hide-input ::v-deep .v-select__selection.v-select__selection--comma {
  display: none;
}
.container-layout {
  height: 82vh;
  overflow: auto;
}
.total {
  border-radius: 8px;
  padding: 20px;
}
.required:after {
  content: ' *';
  color: red;
}
</style>
