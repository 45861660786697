<template>
  <v-container fluid class="dashboard">
    <v-row no-gutters fill-height class="inner-dashboard">
      <dashboard-sidenav :window-width="windowWidth"></dashboard-sidenav>
      <v-snackbar
        :timeout="-1"
        style="z-index: 99 !important"
        :value="offlineSnackbar"
        content-class="d-flex justify-space-between align-center"
        absolute
        centered
        tile
        top
        color="#CF212A"
      >
        <b class="mr-3">KONEKSI INTERNET ANDA TERPUTUS</b>
        <v-btn @click="offlineSnackbar = false" small text fab
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-snackbar>
      <v-snackbar
        :timeout="4000"
        style="z-index: 99 !important"
        :value="onlineSnackbar"
        content-class="d-flex justify-space-between align-center"
        absolute
        centered
        tile
        top
        color="#31B057"
      >
        <b class="mr-3">KONEKSI INTERNET ANDA BERHASIL TERHUBUNG</b>
        <v-btn small text fab @click="onlineSnackbar = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-snackbar>
      <v-snackbar
        style="z-index: 99 !important"
        :value="timeLimitSnackbar"
        content-class="d-flex justify-space-between align-center"
        absolute
        centered
        tile
        top
        color="red"
      >
        <b class="mr-3">
          Sesi anda akan habis setelah 5 menit. Simpan pekerjaan anda dan logout
          kemudian login kembali untuk lanjut mengakses platform ini
        </b>
        <v-btn small text fab @click="timeLimitSnackbar = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-snackbar>
      <v-col
        style="min-width: 200px;"
        class="content"
        :class="{ 'blue-background': routeSplit[1] === 'emr' }"
      >
        <v-app-bar
          class="header"
          :class="{ 'blue-background no-shadow': routeSplit[1] === 'emr' }"
        >
          <v-row justify-space-between align="center">
            <v-breadcrumbs
              :items="breadcrumbs"
              divider=">"
              class="breadcrumbs"
              :style="{ 'max-width': windowWidth <= 1200 ? '300px' : '600px' }"
            />
            <v-spacer></v-spacer>
            <span class="red--text font-weight-medium caption mr-3"
              >Sisa Waktu Login Anda: {{ timer || 'Habis' }}</span
            >
            <v-menu
              offset-y
              :close-on-content-click="false"
              v-model="menu"
              ref="menu"
              nudge-left="80"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  @mouseenter="hoverFunction()"
                  @mouseleave="hoverFunction()"
                  v-on="on"
                  v-bind="attrs"
                  class="profile d-flex align-end flex-column"
                  :class="{ active: isDropdownProfileActive }"
                  @click="toggleDropdownProfile"
                >
                  <!-- v-click-outside="hideDropdownProfile" -->
                  <div class="d-flex align-center">
                    <v-avatar size="32" color="#137BC0" class="mr-2">
                      <span
                        style="font-size: 14px;"
                        class="white--text font-weight-bold"
                        >{{ user.initials }}</span
                      >
                    </v-avatar>
                    <span>Halo, {{ user.name }}</span>
                  </div>
                  <transition name="fade">
                    <div v-show="showHover" class="hover text-start">
                      <p class="hover__name">{{ user.name }}</p>
                      <p class="hover__text">{{ user.nip }}</p>
                      <p class="hover__text">{{ user.unit }}</p>
                    </div>
                  </transition>
                </div>
              </template>
              <div class="account-menu">
                <v-list dense light class="py-0">
                  <v-subheader
                    style="font-size: 10px;"
                    class="grey--text ml-6"
                    >{{ $store.getters.userLoggedIn.clinic.name }}</v-subheader
                  >
                  <v-list-item-group v-model="unitActive" color="primary">
                    <v-list-item
                      v-for="(item, i) in units"
                      :key="i"
                      class="pa-0 px-2"
                      @click="changeUnit(item.id)"
                    >
                      <v-list-item-icon class="mx-0">
                        <v-icon color="primary" small v-if="unitActive === i"
                          >mdi-check-circle</v-icon
                        >
                      </v-list-item-icon>
                      <v-list-item-content
                        class="pa-0"
                        style="font-size: 12px;"
                      >
                        <v-list-item-subtitle
                          class="button"
                          v-text="item.text"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
                <v-divider class="mb-1" />
                <div class="d-flex flex-column">
                  <span
                    @click="dialogCP = true"
                    class="caption font-weight-bold action-footer"
                    >Ganti Password</span
                  >
                  <span
                    @click="logout"
                    class="caption font-weight-bold action-footer logout"
                    >Keluar</span
                  >
                </div>
              </div>
            </v-menu>
          </v-row>
        </v-app-bar>
        <v-container fluid class="inner-content ma-0 pa-0">
          <router-view />
        </v-container>
      </v-col>
    </v-row>
    <template>
      <v-dialog v-model="dialogCP" max-width="390px" persistent>
        <ChangePassword state="dashboard" @close-dialog="closeDialog" />
      </v-dialog>
    </template>
    <v-snackbar
      absolute
      light
      class="mb-5"
      content-class="font-weight-medium pr-0"
      :value="snackbar.show"
      elevation="0"
      :color="snackbar.color"
      height="15"
      timeout="3000"
    >
      <div class="d-flex justify-space-between align-center">
        <span style="color: white">
          {{ snackbar.text }}
        </span>
        <v-btn
          style="color: white"
          text
          fab
          small
          right
          @click="snackbar.show = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>
    </v-snackbar>
  </v-container>
</template>

<script>
import DashboardSidenav from '../components/Dashboard/DashboardSideNav.vue';
import moment from 'moment-timezone';
import AlertMixin from '@/mixin/alertMixin';
import Constant from '@/const';
import ChangePassword from '../components/Master/components/Staff/ChangePassword.vue';
import { createNamespacedHelpers } from 'vuex';
const {
  mapGetters: staffGetters,
  mapActions: staffActions,
} = createNamespacedHelpers('MasterStaff');

const axios = require('axios');

const _ = require('lodash');
let interval;

export default {
  name: 'DashboardPage',
  mixins: [AlertMixin],
  components: {
    DashboardSidenav,
    ChangePassword,
  },
  data() {
    return {
      limittime: 0,
      isOnline: true,
      onlineSnackbar: false,
      offlineSnackbar: false,
      timeLimitSnackbar: false,
      user: {
        initials: this.$store.getters.userLoggedIn.name
          ?.trim()
          ?.split(' ')
          ?.map(word => word.charAt(0).toUpperCase())
          ?.join('')
          ?.slice(0, 2),
        name: this.$store.getters.userLoggedIn.name,
        nip: this.$store.getters.userLoggedIn.nip,
        unit: this.$store.getters.userLoggedIn.unit_name,
      },
      showHover: false,
      isDropdownProfileActive: false,
      menu: false,
      isChangeUnit: false,
      timer: '',
      windowWidth: window.innerWidth,
      dialogCP: false,
    };
  },
  mounted() {
    if (!navigator.onLine) {
      this.offlineSnackbar = true;
      this.isOnline = false;
    }
    window.addEventListener('offline', () => {
      this.isOnline = false;
    });
    window.addEventListener('online', () => {
      this.isOnline = true;
    });
    this.$nextTick(() => {
      // this.getInsuranceCompany();
      // this.getDiagnoseBpjs();
      // this.getBpjsCompany();
      // this.getDiagnose();
      if (this.isLoggedIn) {
        this.refresh();
      } else {
        this.logout();
      }
    });
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  watch: {
    isOnline(newValue, oldValue) {
      if (newValue && !oldValue) {
        this.onlineSnackbar = true;
        this.offlineSnackbar = false;
      }
      if (!newValue && oldValue) {
        this.onlineSnackbar = false;
        this.offlineSnackbar = true;
      }
    },
  },
  computed: {
    ...staffGetters(['getSnackbar']),
    snackbar: {
      get() {
        return this.getSnackbar;
      },
    },
    isLoggedIn() {
      const now = moment();
      return now < moment(this.$store.getters.tokenTime * 1000);
    },
    route() {
      return this.$route.path;
    },
    routeSplit() {
      return this.$route.path.split('/');
    },
    routeController() {
      return this.routeSplit[1];
    },
    unitActive() {
      return this.$store.getters.userLoggedIn.unit.findIndex(
        item => item.id === this.$store.getters.userLoggedIn.unit_id,
      );
    },
    units() {
      return this.$store.getters.userLoggedIn.unit.map(unit => {
        return {
          text: unit.name,
          id: unit.id,
        };
      });
    },
    breadcrumbs() {
      if (this.routeSplit[1] === 'emr') {
        return [
          {
            text: this.$store.getters.userLoggedIn.clinic.altName,
            disabled: false,
            to: '/',
          },
          {
            text: this.routeSplit[1].toUpperCase(),
            disabled: true,
          },
        ];
      } else {
        if (this.route === '/') {
          return [
            {
              text: this.$store.getters.userLoggedIn.clinic.altName,
              disabled: false,
              to: '/',
            },
            {
              text: '',
              disabled: false,
            },
          ];
        } else if (
          this.routeSplit.length === 2 ||
          this.routeSplit.length === 3
        ) {
          if (this.routeSplit[2] === 'igd') {
            return [
              {
                text: this.$store.getters.userLoggedIn.clinic.altName,
                disabled: false,
                to: '/',
              },
              {
                text: _.startCase(this.routeSplit[1]),
                disabled: true,
              },
              {
                text: this.routeSplit[2].toUpperCase(),
                disabled: true,
              },
            ];
          } else {
            return [
              {
                text: this.$store.getters.userLoggedIn.clinic.altName,
                disabled: false,
                to: '/',
              },
              {
                text: _.startCase(this.routeSplit[1]),
                disabled: true,
              },
              {
                text: _.startCase(this.routeSplit[2]),
                disabled: true,
              },
            ];
          }
        } else if (this.routeSplit.length === 4) {
          let route = `/${this.routeSplit[1]}/${this.routeSplit[2]}`;
          return [
            {
              text: this.$store.getters.userLoggedIn.clinic.altName,
              disabled: false,
              to: '/',
            },
            {
              text: _.startCase(this.routeSplit[1]),
              disabled: true,
            },
            {
              text: _.startCase(this.routeSplit[2]),
              disabled: false,
              exact: true,
              to: route,
            },
            {
              text: _.startCase(this.routeSplit[3]),
              disabled: true,
            },
          ];
        }
      }
      return [
        {
          text: this.$store.getters.userLoggedIn.clinic.altName,
          disabled: false,
          to: '/',
        },
      ];
    },
  },
  methods: {
    ...staffActions(['setSnackbar']),
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    async changeUnit(id) {
      if (id === this.$store.getters.userLoggedIn.unit_id) return;
      this.isChangeUnit = true;
      const payload = {
        nip: this.$store.getters.userLoggedIn.nip,
        unit: id,
      };
      delete axios.defaults.headers.Authorization;
      localStorage.removeItem('token');
      this.$store.commit('removeToken');
      try {
        const response = await axios.post(
          Constant.apiUrl.concat('/master/staff/login/unit'),
          payload,
        );
        if (response.status === 200) {
          this.$store.commit('saveToken', response.data.token);
          axios.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;
          document.location.reload(true);
        }
      } catch (error) {
        this.showErrorAxios(error);
      }
    },
    toggleDropdownProfile() {
      this.isDropdownProfileActive = !this.isDropdownProfileActive;
    },
    hideDropdownProfile() {
      this.isDropdownProfileActive = false;
    },
    logout() {
      delete axios.defaults.headers.Authorization;
      localStorage.removeItem('token');
      this.$store.commit('removeToken');
      this.$router.push('/login');
      document.location.reload(true);
    },
    resetPassword() {},
    refresh() {
      if (interval !== null) {
        interval = clearInterval(interval);
      }
      this.recount();
      interval = setInterval(this.recount, 1000);
    },
    recount() {
      const now = moment();
      if (now < moment(this.$store.getters.tokenTime * 1000)) {
        const diff = moment(this.$store.getters.tokenTime * 1000).diff(now);
        this.timer = moment.utc(diff).format('HH:mm:ss');
        if (moment.duration(diff).asMinutes() <= 5 && this.limittime === 0) {
          this.timeLimitSnackbar = true;
          this.limittime = moment().add(5, 'minutes');
        }
      } else {
        this.timer = false;
        interval = clearInterval(interval);
        if (this.isChangeUnit) return;
        this.showErrorJwt();
      }
    },
    hoverFunction() {
      this.showHover = !this.showHover;
    },
    closeDialog() {
      this.dialogCP = false;
    },
  },
  beforeDestroy() {
    interval = clearInterval(interval);
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.account-menu {
  border-radius: 4px;
  width: 16vw;
  background-color: white;
  text-align: start;

  .clinic-name {
    border-bottom: 1px solid blue;
  }

  .action-footer {
    background-color: white;
    padding: 0.6rem 2rem;
    cursor: pointer;
    color: #616161;
    font-size: 12px;

    &:hover {
      background-color: #fafafa;
    }

    &.logout {
      padding: 0.6rem 2rem;
      color: #eb4747;

      &:hover {
        background-color: #fafafa;
      }
    }
  }
}
.dashboard {
  background-color: $background-dashboard;
  min-height: 100vh;

  padding: 0.8em 0em 0em 0.8em;

  .inner-dashboard {
    width: 100%;
    flex-wrap: 0;
    margin: 0;
    .content {
      background-color: white;
      &.blue-background {
        background-color: #f6f9fb;
      }
    }
    .inner-content {
      max-height: 87vh;
      min-height: 87vh;
      overflow-y: auto;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 10px;
        background-color: #9ca2a5;
      }
      &::-webkit-scrollbar-thumb {
        width: 10px;
        background-color: #86bcdb;
      }
    }
    a {
      text-decoration: none;
    }
  }
  .header {
    padding-right: 15px;
    background-color: white !important;
    box-shadow: 0px 4px 3px 0px rgb(219, 219, 219) !important;
    font-weight: 500;
    position: relative;
    z-index: 98;
    font-family: Roboto, sans-serif !important;

    &.blue-background {
      background-color: #f6f9fb !important;
    }

    &.no-shadow {
      box-shadow: none !important;
    }

    // &.blue-background {
    //   background-color: #f6f9fb;
    // }

    .breadcrumbs {
      color: #3498db;
      text-align: left;
      flex-grow: 2;
    }
    .profile {
      cursor: pointer;
      position: relative;
      border-bottom: 2px solid #fff;
      .dropdown {
        z-index: 1;
        position: absolute;
        width: 100%;
        display: none;
        &.active {
          display: block;
        }
        & > * {
          color: white;
        }
      }
      & > * {
        margin: 5px 0px;
      }
      &:hover,
      &:focus,
      &.active {
        transition: opacity 0.2s;
        border-bottom: 2px solid $primary-color;
      }
    }
  }
  .hover {
    left: 0;
    top: 40px;
    font-size: 12px;
    position: absolute;
    border-radius: 4px;
    background: rgba(64, 64, 64, 0.95);
    min-width: 120px;
    max-width: 200px;
    padding: 10px 15px;

    &__name {
      margin: 0px;
      padding: 2px 0px;
      line-height: 90%;
      color: var(--neurovi-web-color-primary-white, #fff);
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.2px;
      text-transform: capitalize;
    }

    &__text {
      margin: 0px;
      padding: 2px 0px;
      color: var(--neurovi-web-color-black-30, #ededed);
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.2px;
    }
  }
}
</style>
