<template>
  <v-card elevation="0">
    <v-card-title
      class="justify-space-between font-weight-medium primary--text py-0 pl-0 mb-2"
    >
      <span class="heading-5">Tambah Obat Baru</span>
      <div>
        <v-btn
          outlined
          @click="resetAll()"
          class="red--text caption"
          max-height="30"
        >
          <font-awesome-icon icon="sync-alt" class="mt-0 mr-2" />
          <span class="font-weight-medium text-capitalize">Reset Form</span>
        </v-btn>
        <v-btn
          color="primary"
          class="ml-3 caption"
          disabled
          depressed
          max-height="30"
        >
          <v-icon left small>mdi-plus</v-icon>
          <span class="font-weight-medium text-capitalize">Form Obat Baru</span>
        </v-btn>
      </div>
    </v-card-title>
    <v-card-text class="main-body">
      <v-container class="container-layout" fluid>
        <v-row no-gutters>
          <v-col md="12">
            <v-row no-gutters>
              <v-col cols="6" class="detail-col black--text pt-0 pr-9">
                <v-row align="baseline">
                  <v-col cols="5" class="text-left py-0">
                    <label>Jenis Obat</label>
                  </v-col>
                  <v-col cols="7" class="pa-0">
                    <v-radio-group
                      v-model="input.isRacik"
                      row
                      hide-details="false"
                    >
                      <v-radio :value="true" label="Racik"></v-radio>
                      <v-radio :value="false" label="Non Racik"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col class="text-left pb-0" cols="5">
                    <label for="name">Nama Obat</label>
                  </v-col>
                  <v-col v-if="!input.isRacik" cols="7" class="pt-2 pb-0 px-0">
                    <v-combobox
                      placeholder="Masukkan Nama Obat"
                      dense
                      v-model="input.recipe.id_drugs"
                      :items="resource.drugs"
                      item-text="text"
                      item-value="id_stock"
                      append-icon="mdi-chevron-down"
                      :search-input.sync="searchDrug"
                      return-object
                      @change="
                        getAllDrugs();
                        mapPackaging();
                      "
                      :loading="isLoading"
                    >
                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-layout>
                              <v-flex d-flex justify-start>
                                <span class="text-left">
                                  {{ `${data.item.text}` }}
                                </span>
                              </v-flex>
                            </v-layout>
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            <v-layout>
                              <span class="mr-4">
                                Exp:
                                {{ data.item.expiryDateFormatted }}
                              </span>
                              <span class="mr-4">
                                No Batch: {{ data.item.batchNumber }}
                              </span>
                              <span class="mr-4">{{
                                `Sisa Stok : ${data.item.stock} ${data.item.packaging}`
                              }}</span>
                              <span>{{ currency(data.item.price || 0) }}</span>
                            </v-layout>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-combobox>
                  </v-col>
                  <v-col v-else cols="7" class="pt-2 px-0 pb-0 mb-2">
                    <v-text-field
                      dense
                      single-line
                      v-model="input.name"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="input.isRacik" class="justify-end">
                  <v-card
                    color="#F0F9FF"
                    style="border: 0.5px solid #bdbdbd"
                    class="pa-0 pt-2 pr-2 mb-3 drugs-card"
                    flat
                  >
                    <v-flex
                      v-for="(drug, index) in input.drugs"
                      :key="'drug-' + index"
                      v-show="index + 1 === page"
                      pa-3
                    >
                      <v-row>
                        <v-col class="text-left py-1" cols="5">
                          <label>Nama Obat</label>
                        </v-col>
                        <v-col class="py-1 pr-0 pl-5">
                          <v-combobox
                            :loading="isLoading"
                            placeholder="Masukkan Nama Obat"
                            dense
                            single-line
                            item-value="id_stock"
                            v-model="drug.detail"
                            append-icon="mdi-chevron-down"
                            :items="resource.drugs"
                            return-object
                            @change="
                              updateDrugQuantity({
                                dosage: drug.detail.dosage_needed,
                                index,
                              })
                            "
                            :search-input.sync="searchDrugs[index]"
                          >
                            <template v-slot:item="data">
                              <v-list-item-content>
                                <v-list-item-title>
                                  <v-layout>
                                    <v-flex d-flex justify-start>
                                      <span class="text-left">
                                        {{ `${data.item.text}` }}
                                      </span>
                                    </v-flex>
                                  </v-layout>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  <v-layout>
                                    <span class="mr-4">
                                      Exp:
                                      {{ data.item.expiryDateFormatted }}
                                    </span>
                                    <span class="mr-4">
                                      No Batch: {{ data.item.batchNumber }}
                                    </span>
                                    <span class="mr-4">{{
                                      `Sisa Stok : ${data.item.stock} ${data.item.packaging}`
                                    }}</span>
                                    <span>{{
                                      currency(data.item.price || 0)
                                    }}</span>
                                  </v-layout>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-combobox>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="text-left py-0" cols="5">
                          <label>Dosis</label>
                        </v-col>
                        <v-col class="pl-5 pr-0 py-0">
                          <v-text-field
                            id="dosage"
                            dense
                            single-line
                            @keyup="
                              updateDrugQuantity({
                                dosage: drug.detail.dosage_needed,
                                index,
                              })
                            "
                            v-model.number="drug.detail.dosage_needed"
                            :suffix="drug.detail.strength_unit"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="text-left py-1" cols="5">
                          <label for="demanded">Jumlah Digunakan</label>
                        </v-col>
                        <v-col class="py-0 px-0 pl-5">
                          <v-text-field
                            id="demanded"
                            v-model="drug.amount_requested"
                            :readonly="!isRecipeManual"
                            :suffix="drug.detail.preparation"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="text-left py-1" cols="5">
                          <label for="given">Jumlah Ditagihkan</label>
                        </v-col>
                        <v-col class="py-0 px-0 pl-5">
                          <v-text-field
                            id="given"
                            type="number"
                            :readonly="!isRecipeManual"
                            v-model="drug.amount_given"
                            :suffix="drug.detail.preparation"
                          />
                        </v-col>
                      </v-row>
                    </v-flex>
                    <v-card-actions style="position: relative">
                      <v-btn
                        outlined
                        class="primary--text caption pa-0"
                        max-height="30"
                        style="min-width: 10%"
                        @click="addDrugs()"
                      >
                        <v-icon size="1" dense small center>mdi-plus</v-icon>
                      </v-btn>
                      <div v-if="input.drugs.length > 1" class="delete-card">
                        <v-icon
                          @click="deleteItem()"
                          class="delete-icon"
                          color="red"
                          center
                          small
                          >mdi-delete</v-icon
                        >
                      </div>
                      <v-spacer></v-spacer>
                      <v-btn
                        outlined
                        class="primary--text caption pa-0"
                        max-height="30"
                        style="min-width: 9%"
                        @click="previous()"
                        :disabled="page === 1"
                      >
                        <v-icon center>mdi-chevron-left</v-icon>
                      </v-btn>
                      <span
                        class="font-weight-bold mx-2"
                        style="font-size: 0.8rem"
                        >{{ page }} / {{ input.drugs.length }}</span
                      >
                      <v-btn
                        outlined
                        class="primary--text caption pa-0"
                        max-height="30"
                        style="min-width: 9%"
                        @click="next()"
                        :disabled="page === input.drugs.length"
                      >
                        <v-icon center>mdi-chevron-right</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-row>
                <v-row v-if="!input.isRacik">
                  <v-col class="text-left pt-2" cols="5">
                    <label for="dosage">Dosis</label>
                  </v-col>
                  <v-col cols="7" class="pa-0 pt-1">
                    <v-text-field
                      dense
                      single-line
                      id="dosage"
                      v-model="input.recipe.id_drugs.strength"
                      :suffix="input.recipe.id_drugs.strength_unit"
                    />
                  </v-col>
                </v-row>
                <v-row v-if="!input.isRacik">
                  <v-col class="text-left pt-2" cols="5">
                    <label for="demanded">Jumlah Diminta</label>
                  </v-col>
                  <v-col cols="7" class="pt-1 pa-0">
                    <v-text-field
                      id="demanded"
                      dense
                      type="number"
                      single-line
                      v-model="input.amount_requested"
                      :suffix="this.input.recipe.id_drugs.packaging"
                      readonly
                      required
                    />
                  </v-col>
                </v-row>
                <v-row v-if="!input.isRacik">
                  <v-col class="text-left pt-2" cols="5">
                    <label for="given">Jumlah Diberikan</label>
                  </v-col>
                  <v-col cols="7" class="pt-1 pa-0">
                    <v-text-field
                      id="given"
                      required
                      type="number"
                      dense
                      single-line
                      v-model="input.amount_given"
                      :suffix="this.input.recipe.id_drugs.packaging"
                    />
                  </v-col>
                </v-row>

                <v-row v-if="input.isRacik" class="mt-4">
                  <v-col class="text-left py-0" cols="5">
                    <label for="rules">Jumlah Diminta</label>
                  </v-col>
                  <v-col cols="3" class="pa-0">
                    <v-text-field
                      class="form-body__data mt-0"
                      dense
                      v-model="input.mix_recipe.amount_requested"
                      readonly
                    />
                  </v-col>
                  <v-col></v-col>
                  <v-col cols="3" class="pa-0">
                    <v-select
                      class="form-body__data mt-0"
                      dense
                      append-icon="mdi-chevron-down"
                      v-model="input.mix_recipe.packaging"
                      :items="resource.packaging.racik"
                      readonly
                    ></v-select
                  ></v-col>
                </v-row>
                <v-row v-if="input.isRacik" class="mt-4">
                  <v-col class="text-left py-4" cols="5">
                    <label for="rules">Jumlah Diberikan</label>
                  </v-col>
                  <v-col cols="3" class="pa-0 py-3">
                    <v-text-field
                      @keyup="updateDrugQuantity({ index: undefined })"
                      class="form-body__data mt-0"
                      dense
                      v-model="input.mix_recipe.quantity"
                    />
                  </v-col>
                  <v-col></v-col>
                  <v-col cols="3" class="pa-0 py-3">
                    <v-select
                      class="form-body__data mt-0"
                      dense
                      append-icon="mdi-chevron-down"
                      v-model="input.mix_recipe.packaging"
                      :items="resource.packaging.racik"
                    ></v-select
                  ></v-col>
                </v-row>
                <v-row v-if="!input.isRacik" class="mt-4">
                  <v-col class="text-left" cols="4">
                    <label for="rules">Aturan Pakai</label>
                  </v-col>
                  <v-col cols="8" class="pa-0">
                    <!-- <v-text-field id="rules" required v-model="input.usage" /> -->
                    <v-row no-gutters>
                      <v-col class="input-usage__checkbox" cols="2">
                        <v-radio-group v-model="input.radio_usage">
                          <v-radio class="mb-5" :value="0"></v-radio>
                          <v-radio :value="1"></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col class="input-usage__data" cols="10">
                        <div class="usage-rule">
                          <v-combobox
                            :disabled="input.radio_usage !== 0"
                            class="form-body__data pt-0 mt-0"
                            append-icon="mdi-chevron-down"
                            auto-select-first
                            dense
                            v-model="input.recipe.usage.dropdownInput.day"
                            :items="resource.usageOne"
                          ></v-combobox>
                          <span>x</span>
                          <v-combobox
                            :disabled="input.radio_usage !== 0"
                            append-icon="mdi-chevron-down"
                            class="form-body__data pt-0 mt-0 mb-5"
                            dense
                            auto-select-first
                            :items="resource.usageTwo"
                            v-model="input.recipe.usage.dropdownInput.usage"
                          ></v-combobox>
                          <v-combobox
                            :disabled="input.radio_usage !== 0"
                            class="form-body__data pt-0 mt-0 mb-5"
                            dense
                            auto-select-first
                            append-icon="mdi-chevron-down"
                            clearable
                            :items="resource.packaging.nonRacik"
                            v-model="input.recipe.usage.dropdownInput.package"
                          >
                          </v-combobox>
                        </div>
                        <div class="usage-rule2">
                          <v-text-field
                            :disabled="input.radio_usage !== 1"
                            class="form-body__data mt-0"
                            dense
                            v-model="input.recipe.usage.manualInput.usage"
                          ></v-text-field>
                          <v-combobox
                            :disabled="input.radio_usage !== 1"
                            class="form-body__data pt-0 mt-0 mb-5"
                            dense
                            auto-select-first
                            append-icon="mdi-chevron-down"
                            clearable
                            :items="resource.packaging.nonRacik"
                            v-model="input.recipe.usage.manualInput.package"
                          >
                          </v-combobox>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row v-else class="mt-4">
                  <v-col class="text-left py-0" cols="5">
                    <label for="rules">Aturan Pakai</label>
                  </v-col>
                  <v-col cols="7" class="pa-0">
                    <!-- <v-text-field id="rules" required v-model="input.usage" /> -->
                    <v-col class="input-usage__data" cols="12">
                      <div class="usage-rule">
                        <v-combobox
                          :disabled="input.radio_usage !== 0"
                          class="form-body__data pt-0 mt-0"
                          append-icon="mdi-chevron-down"
                          dense
                          auto-select-first
                          v-model="input.mix_recipe.usage.day"
                          :items="resource.usageOne"
                        ></v-combobox>
                        <span>x</span>
                        <v-combobox
                          :disabled="input.radio_usage !== 0"
                          append-icon="mdi-chevron-down"
                          class="form-body__data pt-0 mt-0 mb-5"
                          dense
                          auto-select-first
                          :items="resource.usageTwo"
                          v-model="input.mix_recipe.usage.usage"
                        ></v-combobox>
                        <v-combobox
                          :disabled="input.radio_usage !== 0"
                          class="form-body__data pt-0 mt-0 mb-5"
                          dense
                          auto-select-first
                          clearable
                          append-icon="mdi-chevron-down"
                          :items="resource.packaging.racik"
                          v-model="input.mix_recipe.usage.package"
                        >
                        </v-combobox>
                      </div>
                    </v-col>
                  </v-col>
                </v-row>

                <v-row v-if="!input.isRacik">
                  <v-col class="text-left pt-2" cols="5">
                    <label for="rules">Aturan Umum</label>
                  </v-col>
                  <v-col cols="7" class="pt-1 pa-0">
                    <v-autocomplete
                      append-icon="mdi-chevron-down"
                      placeholder="Pilih Aturan Umum"
                      class="form-body__data mt-0"
                      dense
                      :items="resource.general_rules"
                      v-model="input.recipe.general_rule"
                    />
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col class="text-left pt-3" cols="5">
                    <label for="rules">Aturan Umum</label>
                  </v-col>
                  <v-col cols="7" class="pt-2 pa-0">
                    <v-autocomplete
                      append-icon="mdi-chevron-down"
                      placeholder="Pilih Aturan Umum"
                      class="form-body__data mt-0"
                      dense
                      :items="resource.general_rules"
                      v-model="input.mix_recipe.general_rule"
                    />
                  </v-col>
                </v-row>

                <v-row v-if="!input.isRacik">
                  <v-col class="text-left pt-4" cols="5">
                    <label for="route">Rute</label>
                  </v-col>
                  <v-col cols="7" class="pt-3 pa-0">
                    <v-combobox
                      append-icon="mdi-chevron-down"
                      :items="resource.route"
                      single-line
                      auto-select-first
                      placeholder="Pilih Rute"
                      dense
                      v-model="input.recipe.route"
                    ></v-combobox>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col class="text-left pt-4" cols="5">
                    <label for="route">Rute</label>
                  </v-col>
                  <v-col cols="7" class="pt-3 px-0">
                    <v-combobox
                      append-icon="mdi-chevron-down"
                      :items="resource.route"
                      placeholder="Pilih Rute"
                      dense
                      auto-select-first
                      v-model="input.mix_recipe.route"
                    ></v-combobox>
                  </v-col>
                </v-row>

                <v-row v-if="!input.isRacik">
                  <v-col class="text-left pt-2" cols="5">
                    <label for="detail">Keterangan</label>
                  </v-col>
                  <v-col cols="7" class="pt-1 pa-0">
                    <v-text-field
                      dense
                      single-line
                      id="detail"
                      v-model="input.recipe.id_drugs.description"
                    />
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col class="text-left py-0" cols="5">
                    <label for="detail">Keterangan</label>
                  </v-col>
                  <v-col cols="7" class="pa-0 px-0">
                    <v-text-field
                      id="detail"
                      v-model="input.mix_recipe.description"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6" class="detail-col text-left black--text pt-0">
                <div class="d-flex">
                  <span>Resep ODD</span
                  ><v-switch
                    class="ml-5 mt-n1"
                    v-model="input.isOdd"
                  ></v-switch>
                </div>
                <div class="d-flex">
                  <div>
                    <v-row
                      v-for="(time, index) in resource.schedule.today"
                      :key="'time-' + index"
                    >
                      <v-col class="py-0">
                        <v-checkbox
                          dense
                          single-line
                          :label="time.time"
                          v-model="input.time_delivery.today[index].check"
                          :disabled="!input.isOdd"
                          class="mt-0 pt-0"
                          :class="{ 'pt-4': index === 0 }"
                        ></v-checkbox>
                      </v-col>
                      <v-col class="py-0" :class="{ 'pt-4': index === 0 }">
                        <v-text-field
                          dense
                          single-line
                          :placeholder="time.placeHolder"
                          v-model="input.time_delivery.today[index].time"
                          :disabled="!input.time_delivery.today[index].check"
                          :readonly="input.time_delivery.today[index].check"
                        />
                      </v-col>
                      <!-- <v-col class="py-0">
                    <v-checkbox
                      dense
                      single-line
                      :label="time.time"
                      v-model="input.time_delivery[index].check"
                      class="mt-0 pt-0"
                      :class="{ 'pt-4': index === 0 }"
                    ></v-checkbox>
                  </v-col>
                  <v-col class="py-0" :class="{ 'pt-4': index === 0 }">
                    <v-text-field
                      dense
                      single-line
                      :placeholder="time.placeHolder"
                      v-model="input.time_delivery[index].value"
                      :disabled="!input.time_delivery[index].check"
                    />
                  </v-col> -->
                    </v-row>
                  </div>
                  <div>
                    <v-row
                      v-for="(time, index) in resource.schedule.nextDay"
                      :key="'time-' + index"
                    >
                      <v-col class="py-0">
                        <v-checkbox
                          dense
                          single-line
                          :label="time.time"
                          v-model="input.time_delivery.nextDay[index].check"
                          :disabled="!input.isOdd"
                          class="mt-0 pt-0"
                          :class="{ 'pt-4': index === 0 }"
                        ></v-checkbox>
                      </v-col>
                      <v-col class="py-0" :class="{ 'pt-4': index === 0 }">
                        <v-text-field
                          dense
                          single-line
                          :placeholder="time.placeHolder"
                          v-model="input.time_delivery.nextDay[index].time"
                          :disabled="!input.time_delivery.nextDay[index].check"
                          :readonly="input.time_delivery.nextDay[index].check"
                        />
                      </v-col>
                      <!-- <v-col class="py-0">
                    <v-checkbox
                      dense
                      single-line
                      :label="time.time"
                      v-model="input.time_delivery[index].check"
                      class="mt-0 pt-0"
                      :class="{ 'pt-4': index === 0 }"
                    ></v-checkbox>
                  </v-col>
                  <v-col class="py-0" :class="{ 'pt-4': index === 0 }">
                    <v-text-field
                      dense
                      single-line
                      :placeholder="time.placeHolder"
                      v-model="input.time_delivery[index].value"
                      :disabled="!input.time_delivery[index].check"
                    />
                  </v-col> -->
                    </v-row>
                  </div>
                </div>
                <v-row class="mb-2">
                  <v-col cols="12" class="py-2">
                    <span class="py-0 my-0">Alasan Konfirmasi</span>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-textarea
                      placeholder="Isi Alasan Konfirmasi"
                      no-resize
                      dense
                      single-line
                      disabled
                      background-color="white"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
                <v-row class="px-3 mb-4">
                  <v-col class="drug-price" cols="12">
                    <div class="d-flex">
                      <v-icon class="mr-2">mdi-shopping-outline</v-icon>
                      <span>Harga Obat</span>
                    </div>
                    <span>{{
                      !input.isRacik
                        ? currency(input.recipe.id_drugs.price || 0)
                        : currency(
                            input.drugs.reduce(
                              (a, { detail }) => a + detail.price,
                              0,
                            ) || 0,
                          )
                    }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="5">
                    <v-btn
                      v-if="!input.isRacik"
                      color="primary"
                      outlined
                      class="text-capitalize button"
                      width="153"
                      @click="saveData()"
                      depressed
                      :disabled="!checkStock"
                    >
                      <v-icon small>mdi-plus</v-icon><span>Tambahkan Obat</span>
                    </v-btn>
                    <v-btn
                      v-if="input.isRacik"
                      color="primary"
                      outlined
                      class="text-capitalize button"
                      width="153"
                      @click="saveData()"
                      depressed
                      :disabled="!checkStockMix"
                    >
                      <v-icon small>mdi-plus</v-icon><span>Tambahkan Obat</span>
                    </v-btn>
                  </v-col>
                  <span
                    v-if="checkStock === false && !input.isRacik"
                    class="red--text font-weight-medium d-flex align-center"
                    >Stok obat tidak mencukupi !</span
                  >
                  <span
                    v-if="checkStockMix === false && input.isRacik"
                    class="red--text font-weight-medium d-flex align-center"
                    >Stok obat tidak mencukupi !</span
                  >
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import Constant from '@/const';
import Swal from 'sweetalert2';
import moment from 'moment-timezone';
import formatMixin from '@/mixin/formatMixin';

const axios = require('axios');
const _ = require('lodash');

export default {
  mixins: [formatMixin],
  props: {
    patient: Object,
    drugDetail: Number,
    id_recipe: String,
  },
  data() {
    return {
      isLoading: false,
      checkStock: true,
      checkStockMix: true,
      dialog: false,
      resource: {
        usageOne: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
        usageTwo: ['1/4', '1/3', '1/2', '2/3', '3/4', '1', '2', '3', '4', '5'],
        packaging: {
          nonRacik: [
            'Bungkus',
            'Hisap',
            'Kapsul',
            'Kumur',
            'ml',
            'mg',
            'Oleskan',
            'Semprot',
            'Sendok Makan',
            'Sendok Obat',
            'Sendok Sup',
            'Sendok Teh',
            'Tablet',
            'Tetes',
            'Ampul',
            'gr',
            'Ovula',
            'Patch',
            'Rectal Tube',
            'pcs',
            'TPM',
            'Unit',
            'Flexpen',
            'Vial',
          ],
          racik: [
            'Sirup',
            'Salep',
            'Bungkus',
            'Kapsul',
            'Tube',
            'Botol',
            'Tablet',
          ],
        },
        general_rules: [
          'Sebelum makan',
          '1 jam setelah makan',
          '½ jam sebelum makan',
          '2 jam  sesudah makan',
          'Sebelum suapan pertama',
          'Bersama atau tanpa makan',
          'Bersama makan',
          'Kunyah ',
          'Kunyah bersama makan',
          'Sebelum atau sesudah makan',
          'Sesudah makan',
          'Setelah suapan pertama',
          'Taruh diatas lidah',
          'Taruh dibawah lidah ',
        ],
        route: [
          'Per Oral',
          'Sublingual',
          'Per Vagina',
          'Per Rectal',
          'Intravena',
          'Intramuscular',
          'Subcutan',
          'Okular (mata)',
          'Otic (telinga)',
          'Nasal (hidung)',
          'Inhalasi',
          'Transdermal',
          'Intratekal',
          'Topikal',
          'Intrakutan',
        ],
        schedule: {
          today: [
            { time: 'Pagi', placeHolder: '05:30' },
            { time: 'Siang', placeHolder: '13:30' },
            { time: 'Sore', placeHolder: '17:30' },
            { time: 'Malam', placeHolder: '21:30' },
          ],
          nextDay: [
            { time: 'Pagi', placeHolder: '05:30' },
            { time: 'Siang', placeHolder: '13:30' },
            { time: 'Sore', placeHolder: '17:30' },
            { time: 'Malam', placeHolder: '21:30' },
          ],
        },
        drugs: [],
      },
      page: 1,
      input: {
        radio_usage: 0,
        isOdd: false,
        recipe: {
          id_drugs: '',
          preparation: '',
          dosage: '',
          unit_dosage: '',
          quantity: '',
          amount_requested: 0,
          packaging: '',
          description: '',
          usage: {
            dropdownInput: {
              usage: '',
              day: '',
              package: '',
            },
            manualInput: {
              usage: '',
              day: '',
              package: '',
            },
          },
          price: '',
        },
        mix_recipe: {
          name: '',
          drugs: {
            id_drugs: '',
            preparation: '',
            dosage: '',
            dosage_needed: '',
            unit_dosage: '',
            price: '',
          },
          quantity: '',
          packaging: '',
          description: '',
          usage: {
            usage: '',
            day: '',
            package: '',
          },
          price: '',
          amount_requested: 0,
        },
        name: '',
        dosage: '',
        drugs: [
          {
            detail: '',
            dosage: '',
            unit_dosage: '',
            amount_requested: '',
            amount_given: '',
          },
        ],
        amount_requested: 0,
        amount_given: '',
        usage: '',
        route: '',
        description: '',
        change_note: '',
        isRacik: false,
        time_delivery: {
          today: [
            {
              check: false,
              menu: false,
              isNextDay: false,
              name: 'pagi',
              time: '05:30',
            },
            {
              check: false,
              menu: false,
              isNextDay: false,
              name: 'siang',
              time: '13:30',
            },
            {
              check: false,
              menu: false,
              name: 'sore',
              isNextDay: false,
              time: '17:30',
            },
            {
              check: false,
              menu: false,
              name: 'malam',
              isNextDay: false,
              time: '21:30',
            },
          ],
          nextDay: [
            {
              check: false,
              menu: false,
              isNextDay: true,
              name: 'pagi',
              time: '05:30',
            },
            {
              check: false,
              menu: false,
              name: 'siang',
              isNextDay: true,
              time: '13:30',
            },
            {
              check: false,
              menu: false,
              isNextDay: true,
              name: 'sore',
              time: '17:30',
            },
            {
              check: false,
              menu: false,
              isNextDay: true,
              name: 'malam',
              time: '21:30',
            },
          ],
        },
      },
      drug: {
        amount_requested: 0,
        amount_given: 0,
      },
      searchDrug: null,
      searchDrugs: [],
      schedule: [],
    };
  },
  computed: {
    route() {
      return this.$route.path;
    },
    routeSplit() {
      return this.$route.path.split('/');
    },
    routeController() {
      return this.routeSplit[2];
    },
    routeName() {
      switch (this.routeController) {
        case 'rawat-inap':
          return 'rawat_inap';

        case 'rawat-jalan':
          return 'rawat_jalan';

        case 'igd':
          return 'igd';

        default:
          return '';
      }
    },
    isRecipeManual() {
      return (
        this.$store.getters.userLoggedIn.config.pharmacy_mix_recipe_input ===
        'manual'
      );
    },
  },
  watch: {
    'input.recipe.id_drugs'(value) {
      if (value.stock < this.input.amount_given) {
        this.checkStock = false;
      } else {
        this.checkStock = true;
      }
    },
    'input.amount_given'(value) {
      if (value > this.input.recipe.id_drugs.stock) {
        this.checkStock = false;
      } else {
        this.checkStock = true;
      }
    },
    searchDrug(value) {
      if (value) {
        this.searchDataDrug(this);
      }
    },
    searchDrugs(values) {
      if (values) {
        this.getAllDrugs(values[this.page - 1]);
      }
    },
  },
  methods: {
    searchDataDrug: _.debounce(v => v.getAllDrugs(v.searchDrug), 650),
    updateDrugQuantity({ dosage, index }) {
      if (isNaN(index)) {
        const newArray = this.input.drugs.map(drug => {
          return {
            ...drug,
            amount_requested:
              (drug.detail.dosage_needed * +this.input.mix_recipe.quantity) /
              +drug.detail.strength,
            amount_given: Math.ceil(
              (drug.detail.dosage_needed * +this.input.mix_recipe.quantity) /
                +drug.detail.strength,
            ),
          };
        });
        this.input.drugs = newArray;
      } else {
        const newObjectValue = {
          ...this.input.drugs[index],
          amount_requested:
            (dosage * +this.input.mix_recipe.quantity) /
            +this.input.drugs[index].detail.strength,
          amount_given: Math.ceil(
            (dosage * +this.input.mix_recipe.quantity) /
              +this.input.drugs[index].detail.strength,
          ),
        };
        this.input.drugs[index] = newObjectValue;
      }
      this.input = {
        ...this.input,
        drugs: this.input.drugs,
      };
    },
    deleteItem() {
      this.input.drugs.splice(this.page - 1, 1);
      if (this.page === 1) {
        this.page = 1;
      } else {
        this.page -= 1;
      }
    },
    isAvailable() {
      if (this.input.id_drugs) {
        if (
          this.input.id_drugs.stock < this.input.amount_requested &&
          !this.input.isRacik
        ) {
          this.isOutofStock = true;
        }
      }
    },
    resetAll() {
      this.input = {
        radio_usage: 0,
        recipe: {
          id_drugs: '',
          preparation: '',
          dosage: '',
          unit_dosage: '',
          quantity: '',
          amount_requested: '',
          packaging: '',
          description: '',
          usage: {
            dropdownInput: {
              usage: '',
              day: '',
              package: '',
            },
            manualInput: {
              usage: '',
              day: '',
              package: '',
            },
          },
          price: '',
        },
        mix_recipe: {
          name: '',
          drugs: {
            id_drugs: '',
            preparation: '',
            dosage: '',
            dosage_needed: '',
            unit_dosage: '',
            price: '',
          },
          quantity: '',
          packaging: '',
          description: '',
          usage: {
            usage: '',
            day: '',
            package: '',
          },
          price: '',
        },
        name: '',
        dosage: '',
        drugs: [
          {
            detail: '',
            dosage: '',
            unit_dosage: '',
            amount_requested: '',
            amount_given: '',
          },
        ],
        amount_requested: '',
        amount_given: '',
        usage: '',
        route: '',
        description: '',
        change_note: '',
        isRacik: false,
        time_delivery: {
          today: [
            {
              check: false,
              menu: false,
              isNextDay: false,
              name: 'pagi',
              time: '05:30',
            },
            {
              check: false,
              menu: false,
              isNextDay: false,
              name: 'siang',
              time: '13:30',
            },
            {
              check: false,
              menu: false,
              name: 'sore',
              isNextDay: false,
              time: '17:30',
            },
            {
              check: false,
              menu: false,
              name: 'malam',
              isNextDay: false,
              time: '21:30',
            },
          ],
          nextDay: [
            {
              check: false,
              menu: false,
              isNextDay: true,
              name: 'pagi',
              time: '05:30',
            },
            {
              check: false,
              menu: false,
              name: 'siang',
              isNextDay: true,
              time: '13:30',
            },
            {
              check: false,
              menu: false,
              isNextDay: true,
              name: 'sore',
              time: '17:30',
            },
            {
              check: false,
              menu: false,
              isNextDay: true,
              name: 'malam',
              time: '21:30',
            },
          ],
        },
      };
    },
    next() {
      this.page += 1;
    },
    previous() {
      this.page -= 1;
    },
    mapPackaging() {
      this.input.recipe.usage.dropdownInput.package = this.input.recipe.id_drugs.preparation;
    },
    getAllDrugs(keyword) {
      this.isLoading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/stock/all/names?page=1&itemCount=30&category=Obat&name=${
              !keyword ? '' : keyword
            }`,
          ),
        )
        .then(response => {
          const { data } = response.data;
          this.resource.drugs = data.map(arr => {
            return {
              id: arr.id_drugs,
              batchNumber: arr.batch_number,
              id_stock: arr._id,
              name: arr.drugs.detail.name,
              text: `${arr.drugs.detail.name} ${arr.drugs.detail.strength} ${arr.drugs.detail.strength_unit} (${arr.drugs.detail.factory})`,
              displayName: `${arr.drugs.detail.name} ${arr.drugs.detail.strength} ${arr.drugs.detail.strength_unit} (${arr.drugs.detail.factory})`,
              strength_unit: arr.drugs.detail.strength_unit,
              strength: arr.drugs.detail.strength,
              dosage_needed: arr.drugs.detail.strength,
              preparation: arr.drugs.detail.preparation,
              packaging: arr.package,
              price: arr.price,
              expiry: arr.expiry_date,
              expiryDateFormatted: moment(arr.expiry_date, 'YYYY-MM-DD').format(
                'DD-MM-YYYY',
              ),
              stock: arr.quantity,
            };
          });
        })
        .catch(() => {
          this.resource.drugs = [];
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    saveData() {
      if (this.input.isRacik) {
        let isUsageFilled = false;
        if (this.input.radio_usage === 0) {
          if (
            this.input.mix_recipe.usage.day &&
            this.input.mix_recipe.usage.usage &&
            this.input.mix_recipe.usage.package
          ) {
            isUsageFilled = true;
          }
        } else {
          if (
            this.input.mix_recipe.usage.usage &&
            this.input.mix_recipe.usage.package
          ) {
            isUsageFilled = true;
          }
        }
        if (
          !this.input.name ||
          !this.input.mix_recipe.quantity ||
          !isUsageFilled
        ) {
          Swal.fire('Oops...', 'Data obat racik belum lengkap', 'error');
          return;
        }
        if (this.input.drugs.length === 0) {
          Swal.fire('Oops...', 'Obat racik harus tidak boleh kosong', 'error');
          return;
        }
        const payload = {
          isNew: true,
          name: this.input.name,
          isOdd: this.input.isOdd,
          drugs: this.input.drugs.map(drug => {
            return {
              id_drugs: drug.detail.id,
              preparation: drug.detail.preparation,
              name: drug.detail.text,
              dosage: drug.detail.strength,
              dosage_needed: drug.detail.dosage_needed,
              unit_dosage: drug.detail.strength_unit,
              price: drug.detail.price,
              expiry: drug.detail.expiry,
              packaging: drug.detail.packaging,
              id_stock: drug.detail.id_stock,
              availability_stock: drug.detail.stock,
              requested_quantity: drug.amount_requested,
              quantity: drug.amount_given,
            };
          }),
          quantity: parseInt(this.input.mix_recipe.quantity) || 0,
          isOrdered: true,
          requested_quantity: this.input.mix_recipe.amount_requested,
          packaging: this.input.mix_recipe.packaging,
          general_rule: this.input.mix_recipe.general_rule,
          route: this.input.mix_recipe.route,
          description: this.input.mix_recipe.description,
          usage:
            this.input.radio_usage === 0
              ? `${this.input.mix_recipe.usage.day} x ${this.input.mix_recipe.usage.usage} ${this.input.mix_recipe.usage.package}`
              : `${this.input.recipe.usage.manualInput.usage} ${this.input.recipe.usage.manualInput.package}`,
          price: 0,
          date: moment(new Date())
            .locale('id')
            .format(),
          staff: this.$store.getters.userLoggedIn.id,
          time_delivery: this.input.time_delivery.today.concat(
            this.input.time_delivery.nextDay,
          ),
          recipe_number: parseInt(this.drugDetail + 1),
        };
        axios
          .put(
            Constant.apiUrl.concat(
              `/patient/recipe/${this.id_recipe}?type=${this.routeName}`,
            ),
            {
              calculation_type: this.$store.getters.userLoggedIn.config
                .pharmacy_mix_recipe_input,
              mix_recipe: payload,
              timestamps: {
                created_by: this.$store.getters.userLoggedIn.id,
              },
            },
          )
          .then(response => {
            if (response.status === 200) {
              Swal.fire('Obat Berhasil Ditambahkan', '', 'success');
              this.resetAll();
              this.$emit('close-form');
            }
          })
          .catch(error => {
            if (error) {
              Swal.fire({
                icon: 'error',
                title: 'Terjadi kesalahan',
                text: 'Gagal Menambahkan Obat Baru',
              });
            }
          });
      } else {
        let isUsageFilled = false;
        if (this.input.radio_usage === 0) {
          if (
            this.input.recipe.usage.dropdownInput.day &&
            this.input.recipe.usage.dropdownInput.usage &&
            this.input.recipe.usage.dropdownInput.package
          ) {
            isUsageFilled = true;
          }
        } else {
          if (
            this.input.recipe.usage.manualInput.usage &&
            this.input.recipe.usage.manualInput.package
          ) {
            isUsageFilled = true;
          }
        }
        if (
          !this.input.recipe.id_drugs ||
          !this.input.amount_given ||
          !isUsageFilled
        ) {
          Swal.fire('Oops...', 'Data obat non racik belum lengkap', 'error');
          return;
        }
        const payload = {
          id_drugs: this.input.recipe.id_drugs.id,
          id_stock: this.input.recipe.id_drugs.id_stock,
          isOdd: this.input.isOdd,
          availability_stock: this.input.recipe.id_drugs.stock,
          preparation: this.input.recipe.id_drugs.preparation || '-',
          dosage: this.input.recipe.id_drugs.strength,
          unit_dosage: this.input.recipe.id_drugs.strength_unit,
          quantity: parseInt(this.input.amount_given),
          packaging: this.input.recipe.id_drugs.packaging || '-',
          expiry: this.input.recipe.id_drugs.expiry,
          description: this.input.recipe.id_drugs.description,
          isOrdered: true,
          usage:
            this.input.radio_usage === 0
              ? `${this.input.recipe.usage.dropdownInput.day} x ${this.input.recipe.usage.dropdownInput.usage} ${this.input.recipe.usage.dropdownInput.package}`
              : `${this.input.recipe.usage.manualInput.usage} ${this.input.recipe.usage.manualInput.package}`,
          name: this.input.recipe.id_drugs.name,
          price: this.input.recipe.id_drugs.price,
          general_rule: this.input.recipe.general_rule,
          date: moment(new Date())
            .locale('id')
            .format(),
          staff: this.$store.getters.userLoggedIn.id,
          route: this.input.recipe.route,
          time_delivery: this.input.time_delivery.today.concat(
            this.input.time_delivery.nextDay,
          ),
          recipe_number: parseInt(this.drugDetail + 1),
          isNew: true,
        };
        axios
          .put(
            Constant.apiUrl.concat(
              `/patient/recipe/${this.id_recipe}?type=${this.routeName}`,
            ),
            {
              recipe: payload,
              timestamps: {
                created_by: this.$store.getters.userLoggedIn.id,
              },
            },
          )
          .then(response => {
            if (response.status === 200) {
              Swal.fire('Obat Berhasil Ditambahkan', '', 'success');
              this.resetAll();
              this.$emit('close-form');
            }
          });
      }
    },
    addDrugs() {
      this.input.drugs.push({
        detail: '',
        dosage: '',
        unit_dosage: '',
        amount_requested: '',
        amount_given: '',
      });
      this.page = this.input.drugs.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.drugs-card {
  width: 50vw !important;
}
.form-body {
  &__data {
    height: 20px;
  }
}

.main-body {
  background-color: #deeef9;
}
.btn-head-group {
  font-size: 0.5rem;
}

.usage-rule {
  display: grid;
  grid-template-columns: 1fr 0.5fr 1fr 2fr;
  column-gap: 10px;
  span {
    padding-top: 9px;
  }
}

.usage-rule2 {
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 10px;
}

.drug-price {
  display: flex;
  justify-content: space-between;
  background-color: #f0f9ff;
  border-radius: 4px;
  border: 0.5px solid #bdbdbd;
}

.input-usage {
  &__data {
    padding: 0px 0px;
  }
  &__checkbox {
    padding-left: 0px;
  }
}

.container-layout {
  max-height: auto;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 3px;
    background-color: rgb(163, 163, 163);
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: black;
  }
}
.detail-col {
  padding-right: 12px;
  padding-top: 5px;

  h3 {
    text-align: left;
  }
}

.confirmation {
  padding-left: 10px;
  padding-top: 20px;

  h3 {
    padding-bottom: 30px;
    text-align: left;
  }
}

.delete-card {
  border: solid 1px red;
  padding-top: 2px;
  margin-left: 2%;
  min-height: 30px;
  min-width: 10%;
  border-radius: 0.3rem;
  cursor: pointer;
}
.delete-icon:hover {
  animation: vibrate 0.25s ease-in-out;
}

@keyframes vibrate {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(40deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-40deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>
