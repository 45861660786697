import Constant from '@/const';
import axios from 'axios';

const getAllUnit = async () => {
  try {
    const response = await axios.get(Constant.apiUrl.concat('/master/unit'));
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export default getAllUnit;
