<template>
  <div>
    <v-toolbar flat min-height="84">
      <v-container fluid>
        <v-row align="center" class="pt-3">
          <v-col>
            <v-toolbar-title class="font-weight-bold text-left h2">
              Laporan Morbiditas
            </v-toolbar-title>
            <div class="caption grey--text text-left">10 Penyakit Terbesar</div>
          </v-col>
          <v-btn color="grey" icon text @click="getDisease()">
            <v-icon>mdi-autorenew</v-icon>
          </v-btn>
          <v-col cols="2" align-self="center">
            <v-select
              v-model="input.months"
              placeholder="Pilih bulan"
              outlined
              prepend-inner-icon="mdi-calendar-month"
              dense
              :items="resource.months"
              hide-details="false"
              clearable
              @change="getDisease()"
            ></v-select>
          </v-col>

          <v-divider style="max-width: 20px"></v-divider>

          <v-col cols="2">
            <v-select
              v-model="input.year"
              placeholder="Pilih tahun"
              outlined
              prepend-inner-icon="mdi-calendar-month"
              dense
              hide-details="false"
              :items="yearRange"
              clearable
              @change="getDisease()"
            ></v-select>
          </v-col>

          <v-btn tile color="primary" elevation="0" @click="download()"
            >Export</v-btn
          >
        </v-row>
      </v-container>
    </v-toolbar>

    <v-main class="pt-3 px-6">
      <v-container>
        <v-row>
          <v-col cols="7">
            <v-card class="card-graphic pa-3" elevation="0">
              <v-row>
                <v-col cols="6">
                  <h4 class="text-left" style="color: #4d5658">
                    Grafik Laporan Morbiditas
                  </h4>
                  <h5 class="text-left" style="color: #adb5bd">
                    10 daerah dengan penderita terbanyak
                  </h5>
                </v-col>
                <v-col cols="6">
                  <v-select
                    outlined
                    dense
                    :items="resource.disease"
                    placeholder="Pilih Penyakit"
                    v-model="input.diagnose"
                    @change="getDisease()"
                    clearable
                  ></v-select>
                </v-col>
              </v-row>
              <div id="chart">
                <!-- <v-progress-circular
                  v-show="isLoading"
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular> -->
                <apexcharts
                  ref="morbidity"
                  type="bar"
                  height="350"
                  :options="morbidGraph.chartOptions"
                  :series="morbidGraph.series"
                ></apexcharts>
              </div>
            </v-card>
          </v-col>
          <v-col cols="5">
            <v-card color="#F0F9FF" elevation="0" class="pa-3">
              <v-card-title class="text-left pa-2 font-weight-bold"
                >10 Penyakit Terbesar</v-card-title
              >
              <v-progress-circular
                v-if="loading"
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
              <div v-for="(disease, index) in tenBigDisease" :key="index">
                <v-divider></v-divider>
                <v-row justify-center align-center class="py-3 body-2">
                  <v-col cols="2" align-self="center">
                    <label>{{ index + 1 }}</label>
                  </v-col>

                  <v-col cols="7" align-self="center"
                    ><label>{{ disease.name }}</label></v-col
                  >
                  <v-col cols="3" align-self="center"
                    ><label>{{ disease.count }} pasien</label></v-col
                  >
                </v-row>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import Constant from '@/const';
import FileSaver from 'file-saver';
import jwtMixin from '@/mixin/jwtMixin';
import VueApexCharts from 'vue-apexcharts';
import Swal from 'sweetalert2';

const axios = require('axios');
const moment = require('moment-timezone');
const ExcelJS = require('exceljs');

export default {
  name: 'MorbidityReport',
  mixins: [jwtMixin],
  components: {
    apexcharts: VueApexCharts,
  },
  data() {
    return {
      input: {
        year: new Date().getFullYear(),
        month: '',
        diagnose: '',
      },
      resource: {
        months: [],
        disease: [],
      },
      morbidGraph: {
        series: [
          {
            name: 'Pasien',
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            id: 'morbidity',
            type: 'bar',
            height: 350,
          },
          colors: ['#F2994A'],
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: [],
            tickAmount: undefined,
            labels: {
              formatter: function(val) {
                return parseInt(val);
              },
            },
          },
          yaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
        },
      },

      diseaseItem: [],
      tenBigDisease: [],
      month: '',
      year: '',
      count: [],
      loading: false,
      isLoading: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      for (let i = 0; i < 12; i++) {
        this.resource.months.push({
          text: moment()
            .startOf('year')
            .add(i, 'month')
            .locale('id')
            .format('MMMM'),
          value: i + 1,
        });
      }
      this.refreshAndAutoUpdate();
    });
  },
  computed: {
    yearRange() {
      let startYear = 2020;
      const currentYear = new Date().getFullYear();
      const years = [];
      while (startYear <= currentYear) {
        years.push(startYear++);
      }
      return years;
    },
  },
  methods: {
    getDisease() {
      this.morbidGraph.chartOptions.xaxis.categories = [];
      const thisYear = new Date().getFullYear();
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/report/morbiditas?year=${this.input.year ||
                thisYear}&month=${this.input.months || ''}&diagnose=${this.input
                .diagnose || ''}`,
            ),
          )
          .then(response => {
            const {
              topDisease,
              diseaseByRegion,
              listDisease,
            } = response.data.data;
            this.resource.disease = listDisease.map(x => {
              return x === null ? '' : x;
            });
            this.tenBigDisease = topDisease;
            const region = [];
            const count = [];
            diseaseByRegion.forEach((item, index) => {
              if (index < 10) {
                region.push(item._id === null ? '-' : item._id);
                count.push(item.count);
              }
            });

            this.$refs.morbidity.updateOptions({
              xaxis: {
                categories: region,
                tickAmount: Math.min(...count),
              },
            });
            this.$refs.morbidity.updateSeries(
              [
                {
                  name: 'Pasien',
                  data: count,
                },
              ],
              false,
              true,
            );
          });
      });

      // eslint-disable-next-line no-unused-vars
    },

    async download() {
      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet('Sheet1');

      sheet.columns = [
        {},
        {
          header: 'No',
          key: 'no',
          width: 8,
          style: { alignment: { horizontal: 'center' } },
        },
        { header: 'Nama Diagnosa', key: 'diagnoseName', width: 50 },
        { header: 'Total Pasien', key: 'totalPatient', width: 14 },
      ];

      this.tenBigDisease.forEach((disease, index) => {
        sheet.addRow({
          no: index + 1,
          diagnoseName: disease.name,
          totalPatient: `${disease.count} pasien`,
        });
      });

      const today = new Date();
      const month = this.resource.months[today.getMonth()].text;
      const year = today.getFullYear();
      sheet.getRow(1).font = { bold: true };
      sheet.insertRow(1, '');
      sheet.insertRow(1, '');
      sheet.insertRow(1, ['', `Bulan ${month} Tahun ${year}`]);
      sheet.insertRow(1, ['', 'Laporan Morbiditas 10 Besar Data Penyakit']);
      sheet.insertRow(1, ['', this.$store.getters.userLoggedIn.clinic.name]);
      sheet.mergeCells('B1:C1');
      sheet.mergeCells('B2:C2');
      sheet.mergeCells('B3:C3');
      sheet.getCell('B1').alignment = { horizontal: 'left' };
      sheet.getCell('B2').alignment = { horizontal: 'left' };
      sheet.getCell('B3').alignment = { horizontal: 'left' };
      sheet.getRow(1).font = { bold: true, size: 16 };
      sheet.getRow(2).font = { bold: true, size: 12 };
      await workbook.xlsx
        .writeBuffer()
        .then(buffer =>
          FileSaver.saveAs(
            new Blob([buffer]),
            'Laporan Morbiditas 10 Besar Data Penyakit.xlsx',
          ),
        )
        .catch(err => {
          if (err) {
            Swal.fire({
              icon: 'error',
              title: 'Terjadi Kesalahan',
              text: 'Tidak dapat mengexport dokumen',
            });
          }
        });
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.getDisease();
      this.interval = setInterval(this.getDisease, 60000);
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.title-head {
  text-align: left;
  &__sub {
    color: #828282;
  }
}
.card-graphic {
  background-color: #f0f9ff;
}
::v-deep .v-text-field--outlined > .v-input__control > .v-input__slot {
  background: white;
}
.label-text {
  font-size: 14px;
  font-weight: 500;
  display: grid;
  text-align: left;
  padding-top: 10px;
  &.data-grid {
    grid-template-columns: 50px 70px 180px 100px;
    grid-row-gap: 2px;
    grid-auto-rows: 50px;
  }
}
</style>
