<template>
  <div class="storage-request">
    <v-toolbar flat outlined>
      <v-container fluid>
        <v-row align="center">
          <v-col>
            <v-toolbar-title class="font-weight-bold float-left">
              Pengiriman Barang Gudang
            </v-toolbar-title>
          </v-col>

          <v-spacer></v-spacer>
          <v-btn @click="show = !show" text>
            <span class="text-capitalize">Filters</span>
            <v-icon>mdi-filter-variant</v-icon>
          </v-btn>
          <v-btn class="mr-2" color="grey" icon text>
            <v-icon>mdi-autorenew</v-icon>
          </v-btn>
          <v-text-field
            class="input"
            background-color="grey lighten-3"
            placeholder="Search"
            solo
            dense
            hide-details
            flat
            prepend-inner-icon="mdi-magnify"
            single-line
          ></v-text-field>
        </v-row>
      </v-container>
    </v-toolbar>
    <v-main>
      <div class="filter-menu mt-4" v-show="show">
        <v-row no-gutters>
          <v-col cols="2">
            <v-btn text>Reset</v-btn>
          </v-col>
          <v-col cols="2"
            ><v-menu
              dense
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              :return-value.sync="date1"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dense
                  clearable
                  class="mr-4"
                  hide-details="auto"
                  v-model="date1"
                  label="Tanggal Awal"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date1" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu1 = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu1.save(date1)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu></v-col
          >
          <v-col cols="2"
            ><v-menu
              dense
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              :return-value.sync="date2"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dense
                  clearable
                  class="mr-4"
                  hide-details="auto"
                  v-model="date2"
                  label="Tanggal Akhir"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date2" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu2 = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu2.save(date2)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu></v-col
          >
          <v-col cols="2"
            ><v-menu
              dense
              ref="menu3"
              class="mr-4"
              v-model="menu3"
              :close-on-content-click="false"
              :return-value.sync="date3"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dense
                  clearable
                  hide-details="auto"
                  v-model="date3"
                  class="mr-4"
                  label="Tanggal Konfirmasi"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date3" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu3 = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu3.save(date3)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu></v-col
          >
          <v-col cols="2"
            ><v-menu
              dense
              ref="menu4"
              v-model="menu4"
              :close-on-content-click="false"
              :return-value.sync="date4"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dense
                  hide-details="auto"
                  v-model="date4"
                  clearable
                  class="mr-4"
                  label="Tanggal Konfirmasi"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date4" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu4 = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu4.save(date4)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu></v-col
          >
        </v-row>
      </div>

      <v-container fluid>
        <v-row>
          <v-col>
            <v-data-table
              class="flex-grow-1"
              :headers="table.headers"
              :items="table.Items"
              :items-per-page="5"
              hide-default-footer
            >
              <template v-slot:[`item.actions`]>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      icon
                      class="mx-2 hover"
                      @click="detailDialog = true"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <span>Detail Permintaan</span>
                </v-tooltip>
                <v-dialog v-model="detailDialog" max-width="900" persistent>
                  <detail-storage-delivery @close-dialog="closeDialog" />
                </v-dialog>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-footer color="white">
      <v-container>
        <v-row>
          <v-pagination
            v-model="table.pagination.page"
            :length="5"
            total-visible="15"
          ></v-pagination>
          <v-spacer></v-spacer>

          <template>
            <v-dialog v-model="dialog" max-width="1400px" persistent>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" fab dark v-bind="attrs" v-on="on">
                  <v-icon dark>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </template>
              <input-storage-delivery @close-dialog="closeDialog" />
            </v-dialog>
          </template>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import DetailStorageDelivery from './StorageDeliveryComponent/DetailStorageDelivery.vue';
import InputStorageDelivery from './StorageDeliveryComponent/InputStorageDelivery.vue';

export default {
  components: { InputStorageDelivery, DetailStorageDelivery },

  data() {
    return {
      dialog: false,
      detailDialog: false,
      show: false,
      date1: null,
      date2: null,
      date3: null,
      date4: null,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      table: {
        pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 5,
          totalItems: null,
        },
        headers: [
          { text: 'No.', value: 'noQueue' },
          { text: 'Tanggal Permintaan', value: 'requestDate' },
          { text: 'Tanggal Konfirmasi', value: 'confirmDate' },
          { text: 'Dari', value: 'from' },
          { text: 'Ke', value: 'to' },
          { text: 'User', value: 'user' },
          { text: 'Status', value: 'status' },
          { text: '', value: 'actions', sortable: false },
        ],
        Items: [
          {
            noQueue: 1,
            requestDate: '05/05/2020',
            confirmDate: '06/06/2020',
            from: 'Makmur Tani Jaya',
            to: 'Makmur Tani Jaya',
            user: 'Muhammad Fardi',
            status: 'Accepted',
          },
        ],
      },
    };
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.detailDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.input {
  max-width: 30%;
}
</style>
