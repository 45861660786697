<template>
  <v-card class="main">
    <v-card-title>
      <h4>Pencarian Pasien</h4>
    </v-card-title>
    <v-card-text class="container-content text-left">
      <p>Temukan Data Pasien Lama</p>
      <div class="filter-wrapper">
        <v-text-field
          placeholder="Masukkan Nama/NoRM"
          ref="nameInput"
          outlined
          dense
          clearable
          class="filter-wrapper__name"
          @input="debouncedSearch"
          v-model="filter.name"
          :rules="filter.rules.name"
          maxlength="300"
        />
        <v-text-field
          placeholder="Masukkan Alamat"
          outlined
          dense
          clearable
          class="filter-wrapper__address"
          @input="debouncedSearch"
          v-model="filter.address"
          :disabled="filter.name.length < 3"
        />
        <v-btn
          depressed
          color="red"
          class="text-capitalize white--text filter-wrapper__reset"
          outlined
          :disabled="filter.name === '' && filter.address === ''"
          @click="reset()"
          >Reset</v-btn
        >
      </div>
      <div class="table-wrapper pr-4">
        <v-data-table
          :headers="table.headers"
          :items="patientData"
          :items-per-page="table.pagination.rowsPerPage"
          item-key="noRM"
          single-expand
          :loading="patientLoading"
          hide-default-footer
          class="data-table"
        >
          <template v-slot:item="{ item, index }">
            <tr>
              <td class="text-center">
                {{
                  (table.pagination.page - 1) * table.pagination.rowsPerPage +
                    index +
                    1
                }}
              </td>
              <td class="text-left">{{ item.rmNumber }}</td>
              <td class="text-left">{{ item.name }}</td>
              <td class="text-left">{{ item.birthDate }}</td>
              <td class="text-left">{{ item.age }}</td>
              <td class="text-left">{{ item.gender }}</td>
              <td class="text-left">{{ item.address }}</td>
              <td class="text-left">{{ item.lastVisit }}</td>
              <td class="text-left">
                <v-btn
                  color="primary"
                  class="text-capitalize"
                  depressed
                  @click="$emit('on-clicked', item.rmNumber)"
                  >Daftar</v-btn
                >
              </td>
            </tr>
          </template>
          <template v-slot:no-data>
            <div class="no-data">
              <p v-if="filter.name === '' && filter.address === ''">
                Silahkan Masukan Nama/No RM Untuk Memulai Menemukan Data Pasien
                Lama
              </p>
              <p v-else>
                Maaf Data Pasien Tidak Ditemukan.
                <br />
                Silahkan Masukan Data Pencarian Yang Berbeda atau
                <a @click="handleRegist">
                  Daftar Pasien Baru
                </a>
              </p>
            </div>
          </template>
        </v-data-table>
      </div>
    </v-card-text>
    <div>
      <div class="footer-wrapper">
        <div class="footer-wrapper__left">
          <v-pagination
            v-model="table.pagination.page"
            :length="pageLength"
            :total-visible="5"
            @input="handlePagination()"
          ></v-pagination>
          <label for="item-row">Pasien Per Halaman</label>
          <v-select
            id="item-row"
            :items="[
              { value: 5, label: '5' },
              { value: 10, label: '10' },
              { value: 15, label: '15' },
              { value: patientDataLength, label: 'Semua' },
            ]"
            @input="handleGetPatientData()"
            style="width: 100px;"
            append-icon="mdi-chevron-down"
            item-text="label"
            item-value="value"
            v-model="table.pagination.rowsPerPage"
          />
        </div>
        <v-btn depressed class="text-capitalize" @click="handleBack"
          >Kembali</v-btn
        >
      </div>
    </div>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const {
  mapGetters: oldNewpatientGetters,
  mapActions: oldNewpatientActions,
  mapMutations: oldNewpatientMutations,
} = createNamespacedHelpers('OldNewPatientRegistration');

const _ = require('lodash');

export default {
  name: 'SearchDataPatient',
  computed: {
    ...oldNewpatientGetters(['getPatient']),
    patientLoading: {
      get() {
        return this.getPatient.loading;
      },
    },
    patientData: {
      get() {
        return this.getPatient.data;
      },
    },
    patientDataLength: {
      get() {
        return this.getPatient?.length;
      },
    },
  },
  methods: {
    ...oldNewpatientActions(['resolveGetPatientRegistration']),
    ...oldNewpatientMutations([
      'clearState',
      'setPatientData',
      'setPatientLength',
    ]),
    debouncedSearch: _.debounce(function() {
      const { filter } = this;
      if (filter.name?.length < 3 && filter.address?.length < 3) {
        return;
      }
      if (filter.name?.length >= 300) {
        return;
      }
      this.handleGetPatientData();
    }, 700),
    reset() {
      this.filter.name = '';
      this.filter.address = '';
    },
    handleBack() {
      this.$emit('back');
    },
    handleRegist() {
      this.$emit('to-regist');
    },
    handlePagination() {
      const { name, address } = this.filter;
      const { page, rowsPerPage } = this.table.pagination;
      this.resolveGetPatientRegistration({
        ...this,
        name: name,
        address: address,
        page: page,
        itemCount: rowsPerPage,
      }).finally(() => {
        this.pageLength = Math.ceil(
          this.patientDataLength / this.table.pagination.rowsPerPage,
        );
      });
    },
    handleGetPatientData() {
      const { name, address } = this.filter;
      const { page, rowsPerPage } = this.table.pagination;
      if (name === '' && address === '') {
        this.setPatientLength(0);
        this.setPatientData([]);
        this.pageLength = Math.ceil(
          this.patientDataLength / this.table.pagination.rowsPerPage,
        );
        this.table.pagination.page = 1;
      } else {
        this.resolveGetPatientRegistration({
          ...this,
          name: name,
          address: address,
          page: page,
          itemCount: rowsPerPage,
        }).finally(() => {
          this.pageLength = Math.ceil(
            this.patientDataLength / this.table.pagination.rowsPerPage,
          );
          this.table.pagination.page = 1;
        });
      }
    },
  },
  watch: {
    'filter.name'(newValue) {
      if (!newValue) {
        this.filter.name = '';
        this.filter.address = '';
        this.handleGetPatientData();
      }
    },
    'filter.address'(newValue) {
      if (!newValue) {
        this.filter.address = '';
        this.handleGetPatientData();
      }
    },
    deep: true,
  },
  mounted() {
    this.$refs.nameInput.focus();
    this.clearState();
  },
  data() {
    return {
      filter: {
        name: '',
        address: '',
        rules: {
          name: [
            v => v?.length <= 300 || 'Nama maksimal 300 karakter',
            v => v?.length === 0 || v?.length >= 3 || 'Nama minimal 3 karakter',
          ],
        },
      },
      table: {
        headers: [
          { text: 'No', value: 'no' },
          { text: 'No RM', value: 'rmNumber' },
          { text: 'Nama', value: 'name', width: '15%' },
          { text: 'Tanggal Lahir', value: 'birthDate' },
          { text: 'Usia', value: 'age' },
          { text: 'Jenis Kelamin', value: 'gender' },
          { text: 'Alamat', value: 'address', width: '20%' },
          { text: 'Kunjungan Terakhir', value: 'lastVisit' },
          { text: '', value: 'action' },
        ],
        pagination: {
          descending: false,
          page: 1,
          rowsPerPage: 10,
          totalItems: null,
        },
      },
      pageLength: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.main {
  height: 89dvh;
  padding: 1rem 1.5rem;
  color: #000000;
  h4 {
    color: #616161;
  }
  .container-content {
    height: 72dvh;
  }
  .filter-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 2rem;
    padding-bottom: 0rem;
    &__name {
      width: 40%;
    }
    &__address {
      width: 50%;
    }
    &__reset {
      width: 10%;
    }
  }
  .table-wrapper {
    height: 58dvh;
    overflow-y: scroll;
    /* Custom scrollbar */
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #9e9e9e;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      background-color: #c2c2c2;
    }
  }
  .no-data {
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 2rem;
      label {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
}
</style>
