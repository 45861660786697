<template>
  <v-container fluid class="px-5 py-4 main-container">
    <!-- header -->
    <v-row class="d-flex justify-space-between px-1 py-2">
      <v-col class="pa-0 text-start">
        <h2>General Consent</h2>
      </v-col>
      <v-col class="pa-0 d-flex" cols="4">
        <v-combobox
          single-line
          clearable
          outlined
          dense
          :items="resource.status"
          v-model="status"
          item-text="text"
          @change="showAllGeneralConsent()"
          append-icon="mdi-chevron-down"
          placeholder="Semua Status"
        />
        <v-btn class="mx-5" color="grey" icon text small @click="refresh()">
          <v-icon :class="{ refresh: isRefresh }">mdi-autorenew</v-icon>
        </v-btn>
        <v-text-field
          background-color="grey lighten-3"
          placeholder="Search"
          style="width:30vh"
          solo
          dense
          hide-details
          flat
          prepend-inner-icon="mdi-magnify"
          single-line
          v-model="search"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <!-- end of header -->

    <!-- table -->
    <v-data-table
      :headers="table.headers"
      :items="table.items"
      :items-per-page="table.items.length"
      hide-actions
      :loading="table.isLoading"
      hide-default-footer
    >
      <template v-slot:[`item.no`]="{ item }">
        {{ item.no + '.' }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <div
          class="text-capitalize text-center chip"
          :class="{
            unprocessed: item.status === 'unprocess',
            signed: item.status === 'finished',
            processed: item.status === 'inprocess',
          }"
        >
          {{ generateStatus(item.status) }}
        </div>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <div class="d-flex">
          <v-tooltip bottom color="#3f484a">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                @click="previewFile(item.filePath)"
                style="cursor: pointer"
                v-bind="attrs"
                v-on="on"
                class="mx-3"
                :color="item.isSigned ? 'primary' : 'grey'"
                >mdi-file-document-check</v-icon
              >
            </template>
            <span
              >{{
                item.filePath ? 'Lihat Dokumen' : 'Belum Ada Dokumen'
              }}
              General Consent</span
            >
          </v-tooltip>
          <v-tooltip bottom color="#3f484a">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                style="cursor: pointer"
                @click="openGeneralConsentForm(item)"
                v-bind="attrs"
                v-on="on"
                class="mx-3"
                :color="item.isHasDocument ? 'primary' : 'grey'"
                >mdi-file-document-edit</v-icon
              >
            </template>
            <span>Form General Consent</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <v-dialog width="1700" v-model="dialog" persistent>
      <GeneralConsentForm
        v-if="dialog"
        :id_emr="selectedIdEMR"
        :social-data="socialData"
        @close-form="dialog = false"
      />
    </v-dialog>
    <v-pagination
      total-visible="12"
      @input="showAllGeneralConsent()"
      class="pagination"
      :length="table.pagination.paginationLength"
      v-model="table.pagination.page"
    ></v-pagination>
  </v-container>
</template>

<script>
import Constant from '@/const';
import GeneralConsentForm from '../../Registration/components/GeneralConsentForm.vue';

const moment = require('moment-timezone');
const _ = require('lodash');
const axios = require('axios');
export default {
  name: 'GeneralConsentReport',
  components: { GeneralConsentForm },
  data() {
    return {
      isRefresh: false,
      dialog: false,
      search: '',
      status: '',
      selectedIdEMR: '',
      socialData: {}, // social data for general consent form props
      resource: {
        status: [
          {
            text: 'Sudah Ditandatangani',
            value: 'finished',
          },
          {
            text: 'Sudah Diproses',
            value: 'inprocess',
          },
          {
            text: 'Belum Diproses',
            value: 'unprocess',
          },
        ],
      },
      table: {
        isLoading: false,
        pagination: {
          page: 1,
          paginationLength: 0,
        },
        headers: [
          {
            text: 'No',
            align: 'start',
            sortable: false,
            value: 'no',
            width: '2%',
          },
          {
            text: 'No. RM',
            align: 'start',
            sortable: false,
            value: 'rmNumber',
            width: '5%',
          },
          {
            text: 'Nama',
            align: 'start',
            sortable: false,
            value: 'name',
            width: '17%',
          },
          {
            text: 'Alamat',
            align: 'start',
            sortable: false,
            value: 'address',
            width: '35%',
          },
          {
            text: 'Status',
            align: 'start',
            sortable: false,
            value: 'status',
            width: '17%',
          },
          {
            text: '',
            align: 'start',
            sortable: false,
            value: 'action',
            width: '5%',
          },
        ],
        items: [],
      },
    };
  },
  watch: {
    search() {
      this.table.pagination.page = 1;
      this.searchData(this);
    },
    // refresh data when General Consent form is closed
    dialog(value) {
      if (!value) {
        this.showAllGeneralConsent();
      }
    },
    // refresh data when status filter is cleared
    status(value) {
      if (!value) {
        this.showAllGeneralConsent();
      }
    },
  },
  computed: {
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
  },
  mounted() {
    this.refreshAndAutoUpdate();
  },
  methods: {
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.refreshAndAutoUpdate();
    }, 650),
    refresh() {
      this.isRefresh = true;
      this.showAllGeneralConsent();
    },
    async showAllGeneralConsent() {
      this.table.isLoading = true;
      try {
        const response = await axios.get(
          Constant.apiUrl.concat(
            `/report/patient-general-consent?page=${this.table.pagination
              .page || 1}&itemCount=${this.itemsPerRow}&search=${this.search ||
              ''}&status=${this.status?.value || ''}`,
          ),
        );
        const { data } = response.data;
        const { length: dataLength } = response.data;
        this.table.pagination.paginationLength = Math.ceil(
          dataLength / this.itemsPerRow,
        );
        this.table.items = data.map((item, index) => {
          return {
            no:
              index +
              this.itemsPerRow * this.table.pagination.page -
              this.itemsPerRow +
              1,
            rmNumber: item.no_rm,
            name: item.name,
            address: item.address,
            status: item.status,
            filePath: item.file?.files?.path,
            id_emr: item.id_emr,
            isSigned: item.status === 'finished',
            isHasDocument:
              item.status === 'finished' || item.status === 'inprocess',
            // social data
            telp: item.telp,
            identityNumber: item.identityNumber,
            age: item.age,
            gender: item.gender,
            birthDate: item.birth.date,
          };
        });
      } catch (error) {
        if (error) {
          this.table.items = [];
        }
      } finally {
        this.table.isLoading = false;
        this.isRefresh = false;
      }
    },
    async previewFile(path) {
      if (!path) return;
      window.open(Constant.apiUrl.concat(`/${path}`));
    },
    generateStatus(status) {
      switch (status) {
        case 'inprocess':
          return 'sudah diproses';
        case 'unprocess':
          return 'belum diproses';
        case 'finished':
          return 'sudah ditandatangani';
      }
    },
    openGeneralConsentForm(data) {
      this.selectedIdEMR = data.id_emr;
      this.socialData = {
        name: data.name,
        address: data.address,
        telp: data.telp,
        identityNumber: data.identityNumber,
        age: data.age,
        gender: data.gender,
        rmNumber: data.rmNumber,
        birthDate: moment(data.birthDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      };
      this.dialog = true;
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.showAllGeneralConsent();
      this.interval = setInterval(this.showAllGeneralConsent, 60000);
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style scoped lang="scss">
.main-container {
  position: relative;
  height: 80vh;
  .chip {
    width: fit-content;
    border-radius: 4px;
    padding: 0.25rem 0.5rem;
    &.unprocessed {
      border: 1px solid #eb4747;
      color: #eb4747;
      background-color: #fff2f2;
    }
    &.processed {
      border: 1px solid #e5a82e;
      color: #e5a82e;
      background-color: #fff9e6;
    }
    &.signed {
      border: 1px solid #2d965a;
      color: #2d965a;
      background-color: #e6fff0;
    }
  }

  .refresh {
    animation: rotation 0.23s infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(90deg);
    }
    50% {
      transform: rotate(180deg);
    }
    75% {
      transform: rotate(270deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .pagination {
    position: absolute;
    bottom: 5px;
    left: 5px;
  }
}
</style>
