import { render, staticRenderFns } from "./VaccinePoly.vue?vue&type=template&id=3677b12d&scoped=true&"
import script from "./VaccinePoly.vue?vue&type=script&lang=js&"
export * from "./VaccinePoly.vue?vue&type=script&lang=js&"
import style0 from "./VaccinePoly.vue?vue&type=style&index=0&id=3677b12d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3677b12d",
  null
  
)

export default component.exports