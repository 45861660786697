<template>
  <v-card>
    <v-card-title class="justify-center">
      <h3>Data Sosial Pasien</h3>
    </v-card-title>
    <v-card-text>
      <v-row class="offset-2">
        <v-col>
          <v-row class="text-left">
            <v-col cols="3" class="body-2 black--text font-weight-bold">
              <label>No. RM</label>
            </v-col>
            <v-col cols="5" class="black--text">{{ medicalRecord }}</v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="3" class="body-2 black--text font-weight-bold">
              <label>Nama</label>
            </v-col>
            <v-col cols="5" class="black--text">{{ dataSocial.name }}</v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="3" class="body-2 black--text font-weight-bold">
              <label>Status Pasien</label>
            </v-col>
            <v-col cols="5" class="black--text text-capitalize">{{
              dataSocial.nickname
            }}</v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="3" class="body-2 black--text font-weight-bold">
              <label>Jenis Kelamin</label>
            </v-col>
            <v-col cols="5" class="black--text">{{ dataSocial.gender }}</v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="3" class="body-2 black--text font-weight-bold">
              <label>Tempat Lahir</label>
            </v-col>
            <v-col cols="5" class="black--text">{{
              dataSocial.birth.place
            }}</v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="3" class="body-2 black--text font-weight-bold">
              <label>Tanggal Lahir</label>
            </v-col>
            <v-col cols="5" class="black--text">{{
              dataSocial.birth.date
            }}</v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="3" class="body-2 black--text font-weight-bold">
              <label>Alamat</label>
            </v-col>
            <v-col cols="6" class="black--text"
              >{{ dataSocial.address.text }},
              {{ dataSocial.address.sub_district.name }},
              {{ dataSocial.address.district.name }},
              {{ dataSocial.address.city.name }},
              {{ dataSocial.address.province.name }}</v-col
            >
          </v-row>
          <v-row class="text-left">
            <v-col cols="3" class="body-2 black--text font-weight-bold">
              <label>Jenis Identitas</label>
            </v-col>
            <v-col cols="5" class="black--text">{{
              dataSocial.identity.type
            }}</v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="3" class="body-2 black--text font-weight-bold">
              <label>No. Identitas</label>
            </v-col>
            <v-col cols="5" class="black--text">{{
              dataSocial.identity.number
            }}</v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row class="text-left">
            <v-col cols="3" class="body-2 black--text font-weight-bold">
              <label>Agama</label>
            </v-col>
            <v-col cols="5" class="black--text text-capitalize">{{
              dataSocial.religion
            }}</v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="3" class="body-2 black--text font-weight-bold">
              <label>Golongan Darah</label>
            </v-col>
            <v-col cols="5" class="black--text text-uppercase">{{
              dataSocial.blood_type
            }}</v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="3" class="body-2 black--text font-weight-bold">
              <label>Pendidikan</label>
            </v-col>
            <v-col cols="5" class="black--text text-capitalize">{{
              dataSocial.education
            }}</v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="3" class="body-2 black--text font-weight-bold">
              <label>Pekerjaan</label>
            </v-col>
            <v-col cols="5" class="black--text">{{
              dataSocial.profession
            }}</v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="3" class="body-2 black--text font-weight-bold">
              <label>Status Kawin</label>
            </v-col>
            <v-col cols="5" class="black--text text-capitalize">{{
              dataSocial.marital_status
            }}</v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="3" class="body-2 black--text font-weight-bold">
              <label>Nama Ayah</label>
            </v-col>
            <v-col cols="5" class="black--text">{{ dataSocial.father }}</v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="3" class="body-2 black--text font-weight-bold">
              <label>Nama Ibu</label>
            </v-col>
            <v-col cols="5" class="black--text">{{ dataSocial.mother }}</v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="3" class="body-2 black--text font-weight-bold">
              <label>Jenis Jaminan</label>
            </v-col>
            <v-col cols="5" class="black--text text-uppercase">{{
              dataSocial.assurance.type
            }}</v-col>
          </v-row>
          <v-row class="text-left">
            <v-col cols="3" class="body-2 black--text font-weight-bold">
              <label>Nomor</label>
            </v-col>
            <v-col cols="5" class="black--text">{{
              dataSocial.assurance.number
            }}</v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0 pb-0">
          <v-card-actions class="pt-0">
            <v-spacer></v-spacer>
            <v-btn
              large
              color="secondary"
              text
              @click="onCloseButtonClick()"
              class="text-none"
            >
              Kembali
            </v-btn>
            <v-btn
              outlined
              tile
              class="action-button text-none"
              color="primary"
              @click="next()"
              width="100"
            >
              Lanjut
            </v-btn>
            <v-dialog persistent v-model="dialog">
              <div>
                <v-card>
                  <v-card-title class="justify-center">
                    <h3>Ubah Jadwal Pendaftaran Pasien</h3>
                  </v-card-title>
                  <v-card-text>
                    <v-container class="pt-6">
                      <v-row align="baseline" justify="center">
                        <v-col cols="3" class="black--text text-left">
                          <label>Tanggal Kunjungan</label>
                        </v-col>
                        <v-col cols="3">
                          <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :return-value.sync="patientData.meta.visitDate"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="patientData.meta.visitDate"
                                append-icon="mdi-chevron-down"
                                v-bind="attrs"
                                v-on="on"
                                hide-details="false"
                                dense
                                placeholder="Pilih Tanggal Kunjungan"
                                @change="getScheduleDoctor"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="patientData.meta.visitDate"
                              :allowed-dates="val => val > today"
                              no-title
                              scrollable
                              @input="
                                $refs.menu.save(patientData.meta.visitDate);
                                menu = false;
                              "
                            />
                          </v-menu>
                        </v-col>
                      </v-row>
                      <v-row align="baseline" justify="center">
                        <v-col cols="3" class="black--text text-left">
                          <label>Klinik Tujuan</label>
                        </v-col>
                        <v-col cols="3">
                          <v-select
                            dense
                            placeholder="Pilih Unit"
                            :items="polyclinic"
                            item-value="id"
                            item-text="name"
                            return-object
                            v-model="registration.unit"
                            @change="
                              getScheduleDoctor;
                              registration.doctor = '';
                              registration.time.time = null;
                            "
                            hide-details="false"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row align="baseline" justify="center">
                        <v-col cols="3" class="black--text text-left">
                          <label>Nama Dokter</label>
                        </v-col>
                        <v-col cols="3">
                          <v-select
                            :disabled="!registration.unit"
                            dense
                            placeholder="Pilih Dokter"
                            :items="doctor"
                            item-value="id"
                            item-text="name"
                            hide-details="false"
                            @change="getScheduleDoctor"
                            v-model="registration.doctor"
                            return-object
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row align="baseline" justify="center">
                        <v-col cols="3" class="black--text text-left">
                          <label>Jam Praktik</label>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :disabled="!registration.doctor"
                            dense
                            placeholder="Pilih Jam Praktik"
                            v-model="registration.time.time"
                            readonly
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row align="baseline" justify="center" offset="2">
                        <v-col cols="6" class="black--text text-right">
                          <label
                            >Sisa kuota :
                            {{ registration.time.quota }} pasien</label
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      large
                      color="secondary"
                      text
                      @click="dialog = false"
                      class="text-none"
                    >
                      Kembali
                    </v-btn>
                    <v-btn
                      tile
                      class="text-none"
                      color="primary"
                      @click="saveData()"
                      width="100"
                      elevation="0"
                    >
                      Simpan
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </div>
            </v-dialog>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import Constant from '@/const';
import moment from 'moment-timezone';
import AlertMixin from '@/mixin/alertMixin';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'SocialDataShow',
  mixins: [AlertMixin],
  props: {
    medicalRecord: {
      type: String,
      default: null,
    },
    patientData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      date: null,
      menu: false,
      doctor: [],
      polyclinic: [],
      registration: {
        time: {
          time: null,
          quota: 0,
        },
        doctor: '',
        unit: '',
      },
      dataSocial: null,
      today: moment(new Date())
        .locale('id')
        .format('YYYY-MM-DD'),
    };
  },
  mounted() {
    this.getSocialData();
    this.registration = {
      time: {
        time: this.patientData.registration.time,
        quota: 0,
      },
      doctor: {
        id: this.patientData.registration.id_staff._id,
        name: this.patientData.registration.id_staff.name,
      },
      unit: {
        id: this.patientData.registration.id_unit._id,
        name: this.patientData.registration.id_unit.name,
      },
    };
    this.getDoctor();
    this.getUnit();
    this.getScheduleDoctor();
  },
  methods: {
    onCloseButtonClick() {
      this.$emit('close-form');
    },
    next() {
      this.dialog = true;
      this.onCloseButtonClick();
    },
    saveData() {
      const payload = {
        id_emr: this.patientData.id_emr || null,
        registration: {
          id_unit: this.registration.unit.id,
          id_staff: this.registration.doctor.id,
          time: this.registration.time.time,
          isSick: true,
          date: this.patientData.registration.date,
          status: {
            isNew: this.patientData.registration.isNew,
            type: 'rawat jalan',
          },
        },
        patient: {
          name: this.dataSocial.name,
          nickname: this.dataSocial.nickname,
          birth: {
            date: this.dataSocial.birth.date,
            place: this.dataSocial.birth.place,
          },
          gender: this.dataSocial.gender === 'Laki-laki',
          address: {
            city: this.dataSocial.address.city.name || '',
            district: this.dataSocial.address.district.name || '',
            province: this.dataSocial.address.province.name || '',
            sub_district: this.dataSocial.address.sub_district.name || '',
            text: this.dataSocial.address.text,
          },
          martial_status: this.dataSocial.marital_status,
          identity: {
            type: this.dataSocial.identity.type,
            number: this.dataSocial.identity.number,
          },
          religion: this.dataSocial.religion,
          blood_type: this.dataSocial.blood_type,
          education: this.dataSocial.education,
          profession: this.dataSocial.profession,
          father: this.dataSocial.father,
          mother: this.dataSocial.mother,
          assurance: {
            type: this.dataSocial.assurance.type,
            name: this.dataSocial.assurance.name,
            number: this.dataSocial.assurance.number,
            isProlanis: this.dataSocial.assurance.isProlanis,
          },
        },
        timestamps: {
          created_by: this.$store.getters.userLoggedIn.id,
        },
      };
      axios
        .post(Constant.apiUrl.concat('/patient/registration'), payload)
        .then(() => {
          this.dialog = false;
          Swal.fire({
            icon: 'success',
            title: 'Jadwal berhasil diubah',
            html: `Nomor Antrian Pasien : <br> <p style="font-size:30px; font-weight:700;">${this.patientData.noQueue}<p>`,
            confirmButtonText: 'Selesai',
          });
          this.onCloseButtonClick();
        });
    },
    getDoctor() {
      axios
        .get(
          Constant.apiUrl.concat(
            '/master/staff?id_unit=',
            this.registration.unit.id,
          ),
        )
        .then(response => {
          if (response.status === 200) {
            var data = response.data.data;
            this.doctor = data
              .filter(v => v.role.role.toLowerCase() === 'dokter')
              .map(doctor => {
                return {
                  id: doctor._id.toString(),
                  name: doctor.detail.name,
                };
              });
          }
        });
    },
    getUnit() {
      axios
        .get(
          Constant.apiUrl.concat(
            '/master/unit?page=1&itemCount=8&search=&sort=name&installation=Instalasi Rawat Jalan',
          ),
        )
        .then(response => {
          if (response.status === 200) {
            this.polyclinic = response.data.data.map(unit => {
              return {
                id: unit._id.toString(),
                name: unit.name,
              };
            });
          }
        });
    },
    getScheduleDoctor() {
      this.time = [];
      axios
        .post(Constant.apiUrl.concat('/schedule/'), {
          id_unit: this.registration.unit.id,
          id_staff: this.registration.doctor.id,
        })
        .then(response => {
          if (response.status == 200) {
            let [allSchedule] = response.data.data;

            if (allSchedule === undefined) {
              this.registration.time = {
                time: '',
                quota: 0,
              };
            } else {
              let [schedule] = allSchedule.schedule.filter(
                x => x.date === this.patientData.meta.visitDate,
              );

              this.registration.time = {
                time:
                  schedule !== undefined
                    ? `${schedule.startTime} - ${schedule.endTime}`
                    : '',
                quota: schedule !== undefined ? schedule.quota : 0,
              };
            }
          }
        });
    },
    getSocialData() {
      axios
        .get(
          Constant.apiUrl.concat(`/patient/emr/social/${this.medicalRecord}`),
        )
        .then(response => {
          const social = response.data.data;
          this.dataSocial = {
            assurance: {
              name: _.get(social, 'assurance.name', 'Tidak ada data'),
              number: _.get(social, 'assurance.number', 'Tidak ada data'),
              isProlanis: _.get(
                social,
                'assurance.isProlanis',
                'Tidak ada data',
              ),
              type: _.get(social, 'assurance.type', 'Tidak ada data'),
            },
            age: _.get(social, 'age', 'Tidak ada data'),
            name: _.get(social, 'name', 'Tidak ada data'),
            nickname: _.get(social, 'nickname', 'Tidak ada data'),
            gender: _.get(social, 'gender', 'Tidak ada data')
              ? 'Laki-laki'
              : 'Perempuan',
            birth: {
              date: _.get(social, 'birth.date', 'Tidak ada data'),
              place: _.get(social, 'birth.place', 'Tidak ada data'),
            },
            address: {
              city: _.get(social, 'address.city', 'Tidak ada data'),
              district: _.get(social, 'address.district', 'Tidak ada data'),
              province: _.get(social, 'address.province', 'Tidak ada data'),
              sub_district: _.get(
                social,
                'address.sub_district',
                'Tidak ada data',
              ),
              text: _.get(social, 'address.text', 'Tidak ada data'),
            },
            identity: {
              type: _.get(social, 'identity.type', 'Tidak ada data'),
              number: _.get(social, 'identity.number', 'Tidak ada data'),
            },
            religion: _.get(social, 'religion', 'Tidak ada data'),
            blood_type: _.get(social, 'blood_type', 'Tidak ada data'),
            education: _.get(social, 'education', 'Tidak ada data'),
            profession: _.get(social, 'profession', 'Tidak ada data'),
            marital_status: _.get(social, 'martial_status', 'Tidak ada data'),
            father: _.get(social, 'father', 'Tidak ada data'),
            mother: _.get(social, 'mother', 'Tidak ada data'),
          };
        })
        .catch(err => {
          this.showErrorAxios(err);
          this.onCloseButtonClick();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  text-align: center;
}
::v-deep .v-dialog.v-dialog--active {
  width: 900px;
}
</style>
