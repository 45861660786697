<template>
  <v-card height="auto">
    <section class="py-5 mx-8 card-header">
      <div class="d-flex justify-space-between">
        <h2 class="text-left">Riwayat Telaah Obat</h2>
        <v-btn @click="$emit('close-dialog')" small fab text>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </section>
    <section class="d-flex py-1 mx-8 mb-4 table-scroll">
      <v-data-table
        :headers="table.nurse.headers"
        :items="table.constant"
        hide-default-footer
        item-key="nurseData"
        class="constant-table"
      >
        <template v-slot:item="{ item }">
          <tr class="data-row text-left d-flex align-center">
            <span class="pl-4">
              {{ item.data }}
            </span>
          </tr>
        </template>
        <template v-slot:footer>
          <div class="text-left font-weight-medium pa-4 footer">
            Nama Petugas
          </div>
        </template>
      </v-data-table>
      <table class="table-grid">
        <thead>
          <tr>
            <th
              v-for="header in table.nurse.headerDate"
              :key="header.date"
              :colspan="header.time.length"
              style="color: #137BC0;"
            >
              {{ header.date }}
            </th>
          </tr>
          <tr>
            <th v-for="(header, index) in table.nurse.items" :key="index">
              {{ header.time }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-for="(item, index) in table.nurse.items" :key="index">
              <v-icon medium :color="item.true_patient ? '#219653' : '#EB4747'">
                {{
                  item.true_patient ? 'mdi-check-circle' : 'mdi-close-circle'
                }}
              </v-icon>
            </td>
          </tr>
          <tr>
            <td v-for="(item, index) in table.nurse.items" :key="index">
              <v-icon medium :color="item.true_drug ? '#219653' : '#EB4747'">
                {{ item.true_drug ? 'mdi-check-circle' : 'mdi-close-circle' }}
              </v-icon>
            </td>
          </tr>
          <tr>
            <td v-for="(item, index) in table.nurse.items" :key="index">
              <v-icon medium :color="item.true_dosage ? '#219653' : '#EB4747'">
                {{ item.true_dosage ? 'mdi-check-circle' : 'mdi-close-circle' }}
              </v-icon>
            </td>
          </tr>
          <tr>
            <td v-for="(item, index) in table.nurse.items" :key="index">
              <v-icon medium :color="item.true_gift ? '#219653' : '#EB4747'">
                {{ item.true_gift ? 'mdi-check-circle' : 'mdi-close-circle' }}
              </v-icon>
            </td>
          </tr>
          <tr>
            <td v-for="(item, index) in table.nurse.items" :key="index">
              <v-icon
                medium
                :color="item.true_time_gift ? '#219653' : '#EB4747'"
              >
                {{
                  item.true_time_gift ? 'mdi-check-circle' : 'mdi-close-circle'
                }}
              </v-icon>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td v-for="(item, index) in table.nurse.items" :key="index">
              <p class="px-2">{{ item.created_by }}</p>
            </td>
          </tr>
        </tfoot>
      </table>
    </section>
    <section class="d-flex py-1 mx-8 table-scroll">
      <v-data-table
        :headers="table.pharmacy.headers"
        :items="table.constant"
        hide-default-footer
        item-key="pharmacyData"
        class="constant-table"
      >
        <template v-slot:item="{ item }">
          <tr class="data-row text-left d-flex align-center">
            <span class="pl-4">
              {{ item.data }}
            </span>
          </tr>
        </template>
        <template v-slot:footer>
          <div class="text-left font-weight-medium pa-4 footer">
            Nama Petugas
          </div>
        </template>
      </v-data-table>
      <table class="table-grid">
        <thead>
          <tr>
            <th
              v-for="header in table.pharmacy.headerDate"
              :key="header.date"
              :colspan="header.time.length"
              style="color: #137BC0;"
            >
              {{ header.date }}
            </th>
          </tr>
          <tr>
            <th v-for="(header, index) in table.pharmacy.items" :key="index">
              {{ 'R' + index }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-for="(item, index) in table.pharmacy.items" :key="index">
              <v-icon medium :color="item.true_patient ? '#219653' : '#EB4747'">
                {{
                  item.true_patient ? 'mdi-check-circle' : 'mdi-close-circle'
                }}
              </v-icon>
            </td>
          </tr>
          <tr>
            <td v-for="(item, index) in table.pharmacy.items" :key="index">
              <v-icon medium :color="item.true_drug ? '#219653' : '#EB4747'">
                {{ item.true_drug ? 'mdi-check-circle' : 'mdi-close-circle' }}
              </v-icon>
            </td>
          </tr>
          <tr>
            <td v-for="(item, index) in table.pharmacy.items" :key="index">
              <v-icon medium :color="item.true_dosage ? '#219653' : '#EB4747'">
                {{ item.true_dosage ? 'mdi-check-circle' : 'mdi-close-circle' }}
              </v-icon>
            </td>
          </tr>
          <tr>
            <td v-for="(item, index) in table.pharmacy.items" :key="index">
              <v-icon medium :color="item.true_gift ? '#219653' : '#EB4747'">
                {{ item.true_gift ? 'mdi-check-circle' : 'mdi-close-circle' }}
              </v-icon>
            </td>
          </tr>
          <tr>
            <td v-for="(item, index) in table.pharmacy.items" :key="index">
              <v-icon
                medium
                :color="item.true_time_gift ? '#219653' : '#EB4747'"
              >
                {{
                  item.true_time_gift ? 'mdi-check-circle' : 'mdi-close-circle'
                }}
              </v-icon>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td v-for="(item, index) in table.pharmacy.items" :key="index">
              <p class="px-2">{{ item.created_by }}</p>
            </td>
          </tr>
        </tfoot>
      </table>
    </section>
    <div class="table-footer">
      <v-btn
        style="border-radius: 2px"
        tile
        height="30"
        width="120"
        @click="$emit('open-detail')"
        elevation="0"
        color="primary"
        >DETAIL RESEP</v-btn
      >
    </div>
  </v-card>
</template>

<script>
import Constant from '@/const';
import moment from 'moment-timezone';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'DrugReviewHistory',
  props: {
    // patientData: {
    //   type: Object,
    //   default: null,
    // },
    idDrugAdministration: {
      type: String,
    },
  },
  data() {
    return {
      table: {
        constant: [
          {
            data: 'Benar Pasien',
          },
          {
            data: 'Benar Obat',
          },
          {
            data: 'Benar Dosis',
          },
          {
            data: 'Benar Cara Pemberian',
          },
          {
            data: 'Benar Waktu Pemberian',
          },
        ],
        nurse: {
          headers: [
            {
              text: 'Data Perawat',
              value: 'data',
              width: '100%',
              sortable: false,
              class: 'header-item',
            },
          ],
          headerDate: [],
          items: [],
        },
        pharmacy: {
          headers: [
            {
              text: 'Data Farmasi',
              value: 'data',
              width: '100%',
              sortable: false,
              class: 'header-item',
            },
          ],
          headerDate: [],
          items: [],
        },
      },
    };
  },
  mounted() {
    this.getReviewReceipt();
  },
  methods: {
    row() {
      return 'row-table';
    },
    getReviewReceipt() {
      this.loading = true;
      axios
        .get(
          Constant.apiUrl
            .concat('/inpatient/drugadministration/')
            .concat(this.idDrugAdministration),
        )
        .then(response => {
          const [data] = response.data.data;
          const dataNurse = data.review_receipt;
          const dataPharmacy = data.review_pharmacy
            .filter(d => d.drugs_review.patient !== null)
            .map(pharmacy => {
              return {
                date: moment(pharmacy.timestamps.created_at).format(
                  'DD-MM-YYYY',
                ),
                drugs_review: {
                  true_patient: pharmacy.drugs_review.patient,
                  true_drug: pharmacy.drugs_review.drugs,
                  true_dosage: pharmacy.drugs_review.dosage,
                  true_gift: pharmacy.drugs_review.administer,
                  true_time_gift: pharmacy.drugs_review.time,
                  created_by: pharmacy.timestamps.created_by,
                },
              };
            });

          this.table.nurse.items = dataNurse;
          this.table.pharmacy.items = dataPharmacy.map(
            pharmacy => pharmacy.drugs_review,
          );

          this.table.nurse.headerDate = _.chain(dataNurse)
            .groupBy('date')
            .map((value, key) => ({
              date: key,
              time: _.map(value, 'time'),
            }))
            .value();

          this.table.pharmacy.headerDate = _.chain(dataPharmacy)
            .groupBy('date')
            .map((value, key) => ({
              date: key,
              time: _.map(value, 'time'),
            }))
            .value();

          this.loading = false;
        })
        .catch(error => {
          if (error) {
            this.table.items = [];
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.card-header {
  top: 0;
  position: sticky;
  background-color: white;
  z-index: 2;
}
.table-footer {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
}
.constant-table {
  width: 20vw;
  min-width: 350px;
  border-radius: 0;
  border-top: 1px solid #bcc4c9;
  border-bottom: 1px solid #bcc4c9;
  border-right: 1px solid #bcc4c9;
  & ::v-deep thead {
    height: 69px;
  }
  .data-row {
    height: 50px;
    font-size: 0.95rem;
    align-items: center;
    font-weight: 400;
  }
  .footer {
    border-top: 0.5px solid #bcc4c9;
  }
}
.v-data-table::v-deep th {
  font-size: 1.1rem !important;
  color: #137bc0 !important;
}

.table-grid {
  border: 1px solid #bcc4c9;
  border-collapse: collapse;
  //position: relative;
  overflow-x: auto;
  display: block;

  thead {
    height: 69px;
    background: #f4f5f5;
    border: 1px solid #bcc4c9;
    font-size: 14px;

    th {
      border: 1px solid #bcc4c9;
    }
  }

  td {
    border: 1px solid #9ca2a5;
    min-width: 100px;
    height: 50px;
  }

  tfoot {
    height: 58px;
    border: 1px solid #9ca2a5;
  }
}
</style>
