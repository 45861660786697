<template>
  <v-card>
    <v-toolbar flat>
      <div>
        <span class="grey--text">No RM</span>
        <span class="black--text font-weight-bold mx-6">{{
          patientData.rmNumber
        }}</span>
      </div>
      <div>
        <span class="grey--text">Name</span>
        <span class="black--text font-weight-bold mx-6"
          >{{ patientData.meta.name }} ({{
            patientData.meta.gender === 'Laki-laki' ? 'L' : 'P'
          }})</span
        >
      </div>
      <div>
        <span class="grey--text">Tanggal Lahir</span>
        <span class="black--text font-weight-bold mx-6"
          >{{ patientData.meta.birthDate }} ({{
            patientData.meta.age
          }}
          Tahun)</span
        >
      </div>

      <v-spacer></v-spacer>

      <span class="black--text font-weight-bold headline">Form Odontogram</span>
      <v-btn text @click="$emit('close-form')" fab class="ml-4"
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </v-toolbar>
    <v-tabs grow v-model="tabs">
      <v-tab class="font-weight-bold text-capitalize">
        Input Form Odontogram
      </v-tab>
    </v-tabs>
    <div class="main-form">
      <v-tabs-items v-model="tabs">
        <v-tab-item class="d-flex justify-center">
          <v-container class="px-xl-15 mx-lg-0">
            <v-form ref="form">
              <img
                :src="dentalBackground.src"
                alt="odontogram-image"
                width="800"
                class="mb-5"
              />
              <v-row class="mb-3">
                <v-col cols="2" class="text-start"
                  ><label for="dental-number" class="font-weight-bold required"
                    >No Gigi</label
                  ></v-col
                >
                <v-col cols="10"
                  ><v-text-field
                    id="dental-number"
                    v-model="input.no"
                    @change="teethCheck($event)"
                    type="number"
                    :maxLength="2"
                    no-resize
                    :readonly="isDetail"
                    outlined
                    dense
                    :rules="teethRules"
                    placeholder="Tulis No Gigi"
                  ></v-text-field
                ></v-col>
                <v-col cols="2" class="text-start"
                  ><label for="subjective" class="font-weight-bold"
                    >Pemeriksaan Subjektif</label
                  ></v-col
                >
                <v-col
                  ><v-textarea
                    id="subjective"
                    no-resize
                    v-model="input.subjective"
                    outlined
                    dense
                    :readonly="isDetail && !isEdit"
                    height="100"
                    hide-details
                    placeholder="Tulis Keterangan di sini"
                  ></v-textarea
                ></v-col>
              </v-row>
              <v-row class="mb-5">
                <v-col class="text-start">
                  <label for="objective" class="font-weight-bold"
                    >Pemeriksaan Objective</label
                  >
                </v-col>
                <v-col cols="12" class="d-flex justify-end mb-15">
                  <div class="grid-display">
                    <div class="grid-display-data">
                      <label for="sondasi">Sondasi</label>
                      <div>
                        <v-radio-group
                          v-model="input.objective.sondasi.plus"
                          row
                          :readonly="isDetail && !isEdit"
                          hide-details="false"
                          class="mt-0 pt-0 mb-3"
                        >
                          <v-radio label="-" :value="false"></v-radio>
                          <v-radio label="+" :value="true"></v-radio>
                        </v-radio-group>
                        <v-textarea
                          v-model="input.objective.sondasi.description"
                          outlined
                          height="100"
                          :readonly="isDetail && !isEdit"
                          no-resize
                          dense
                          hide-details
                          :disabled="!input.objective.sondasi.plus"
                          :class="{ dark: !input.objective.sondasi.plus }"
                          placeholder="Tulis keterangan di sini"
                        >
                        </v-textarea>
                      </div>
                    </div>
                    <div class="grid-display-data">
                      <label for="perkusi">Perkusi</label>
                      <div>
                        <v-radio-group
                          v-model="input.objective.perkusi.plus"
                          :readonly="isDetail && !isEdit"
                          row
                          hide-details="false"
                          class="mt-0 pt-0 mb-3"
                        >
                          <v-radio label="-" :value="false"></v-radio>
                          <v-radio label="+" :value="true"></v-radio>
                        </v-radio-group>
                        <v-textarea
                          v-model="input.objective.perkusi.description"
                          outlined
                          height="100"
                          :readonly="isDetail && !isEdit"
                          no-resize
                          :disabled="!input.objective.perkusi.plus"
                          :class="{ dark: !input.objective.perkusi.plus }"
                          dense
                          hide-details
                          placeholder="Tulis keterangan di sini"
                        >
                        </v-textarea>
                      </div>
                    </div>

                    <div class="grid-display-data">
                      <label for="palpasi">Palpasi</label>
                      <div>
                        <v-radio-group
                          v-model="input.objective.palpasi.plus"
                          row
                          :readonly="isDetail && !isEdit"
                          hide-details="false"
                          class="mt-0 pt-0 mb-3"
                        >
                          <v-radio label="-" :value="false"></v-radio>
                          <v-radio label="+" :value="true"></v-radio>
                        </v-radio-group>
                        <v-textarea
                          v-model="input.objective.palpasi.description"
                          :readonly="isDetail && !isEdit"
                          outlined
                          height="100"
                          :disabled="!input.objective.palpasi.plus"
                          :class="{ dark: !input.objective.palpasi.plus }"
                          no-resize
                          dense
                          hide-details
                          placeholder="Tulis keterangan di sini"
                        >
                        </v-textarea>
                      </div>
                    </div>
                    <div class="grid-display-data">
                      <label for="vitalitas">Vitalitas</label>
                      <div>
                        <v-radio-group
                          v-model="input.objective.vitalitas.plus"
                          :readonly="isDetail && !isEdit"
                          row
                          hide-details="false"
                          class="mt-0 pt-0 mb-3"
                        >
                          <v-radio label="-" :value="false"></v-radio>
                          <v-radio label="+" :value="true"></v-radio>
                        </v-radio-group>
                        <v-textarea
                          v-model="input.objective.vitalitas.description"
                          outlined
                          :readonly="isDetail && !isEdit"
                          height="100"
                          no-resize
                          :disabled="!input.objective.vitalitas.plus"
                          :class="{ dark: !input.objective.vitalitas.plus }"
                          dense
                          hide-details
                          placeholder="Tulis keterangan di sini"
                        >
                        </v-textarea>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" class="d-flex justify-end">
                  <div class="grid-diagnose">
                    <div class="grid-display-data">
                      <label for="diagnosa" class="font-weight-bold required"
                        >Diagnosa</label
                      >
                      <v-combobox
                        id="diagnose"
                        v-model="input.diagnose"
                        append-icon="mdi-chevron-down"
                        :rules="rules"
                        :search-input.sync="searchDiagnose"
                        :readonly="isDetail && !isEdit"
                        dense
                        :items="resource.diagnose"
                        :loading="diagnoseLoading"
                        item-text="displayName"
                        placeholder="Masukkan diagnosa"
                      ></v-combobox>
                    </div>
                    <div class="grid-display-data">
                      <label for="description" class="font-weight-bold"
                        >Keterangan</label
                      >
                      <v-textarea
                        id="description"
                        v-model="input.description"
                        dense
                        :readonly="isDetail && !isEdit"
                        outlined
                        height="100"
                        no-resize
                        hide-details
                        placeholder="Tulis keterangan di sini"
                      ></v-textarea>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row class="mb-3">
                <v-col cols="2" class="text-start"
                  ><label for="treatment-plan" class="font-weight-bold"
                    >Rencana Perawatan</label
                  ></v-col
                >
                <v-col cols="10" class="mb-5"
                  ><v-textarea
                    id="treatment-plan"
                    height="100"
                    v-model="input.treatmentPlan"
                    no-resize
                    outlined
                    :readonly="isDetail && !isEdit"
                    dense
                    hide-details
                    placeholder="Tulis keterangan di sini"
                  ></v-textarea
                ></v-col>
                <v-col cols="12" class="d-flex justify-end">
                  <div class="grid-diagnose">
                    <div class="grid-display-data">
                      <label for="action" class="font-weight-bold"
                        >Tindakan</label
                      >
                      <v-textarea
                        id="description"
                        v-model="input.action"
                        dense
                        outlined
                        :readonly="isDetail && !isEdit"
                        height="100"
                        no-resize
                        placeholder="Tulis keterangan di sini"
                      ></v-textarea>
                    </div>
                    <div class="grid-display-data">
                      <label for="description" class="font-weight-bold"
                        >ICD-9</label
                      >
                      <v-combobox
                        id="diagnose"
                        :loading="procedureLoading"
                        v-model="input.procedure"
                        append-icon="mdi-chevron-down"
                        :search-input.sync="searchProcedure"
                        item-text="displayName"
                        :readonly="isDetail && !isEdit"
                        :items="resource.procedure"
                        dense
                        hide-details
                        placeholder="Masukkan keterangan di sini"
                      ></v-combobox>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <v-toolbar>
      <v-btn @click="$emit('close-form')" text class="text-capitalize"
        >Kembali</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn color="primary" depressed @click="save()" v-if="!isDetail"
        >Simpan</v-btn
      >
      <v-btn
        color="primary"
        depressed
        @click="isEdit = true"
        :disabled="isEdit"
        v-if="isDetail && isEditable"
        >Edit</v-btn
      >
      <v-btn
        class="ml-4"
        color="primary"
        depressed
        @click="update()"
        :disabled="!isEdit"
        v-if="isDetail && isEditable"
        >Update</v-btn
      >
    </v-toolbar>
  </v-card>
</template>

<script>
import dentalBackgroundSvg from '@/assets/image/OdontogramNew.svg';
import Constant from '@/const';

const axios = require('axios');
export default {
  name: 'OdontogramForm',
  props: {
    patientData: {
      type: Object,
      default: null,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    inputtedTooth: Array,
    isDetail: Boolean,
    dataDetail: Object,
  },
  data() {
    return {
      tabs: 0,
      dentalBackground: null,
      searchDiagnose: '',
      searchProcedure: '',
      isEdit: false,
      resource: {
        diagnose: [],
        procedure: [],
        teethNumber: [
          18,
          17,
          16,
          15,
          14,
          13,
          12,
          11,
          21,
          22,
          23,
          24,
          25,
          26,
          27,
          28,
          55,
          54,
          53,
          52,
          51,
          61,
          62,
          63,
          64,
          65,
          85,
          84,
          83,
          82,
          81,
          71,
          72,
          73,
          74,
          75,
          48,
          47,
          46,
          45,
          44,
          43,
          42,
          41,
          31,
          32,
          33,
          34,
          35,
          36,
          37,
          38,
        ],
      },
      input: {
        no: '',
        subjective: '',
        diagnose: '',
        description: '',
        treatmentPlan: '',
        action: '',
        procedure: '',
        objective: {
          sondasi: {
            description: '',
            plus: false,
          },
          perkusi: {
            description: '',
            plus: false,
          },
          palpasi: {
            description: '',
            plus: false,
          },
          vitalitas: {
            description: '',
            plus: false,
          },
        },
      },
      diagnoseLoading: false,
      procedureLoading: false,
      rules: [v => !!v || 'Wajib diisi'],
      teethRules: [v => this.teethCheck(v)],
    };
  },
  watch: {
    searchDiagnose(val) {
      if (val !== '') {
        this.getDiagnose(val);
      }
    },
    searchProcedure() {
      this.getProcedure('');
    },
  },
  created() {
    const dentalBackground = new window.Image();
    dentalBackground.src = dentalBackgroundSvg;
    dentalBackground.width = 800;
    dentalBackground.height = 225;
    dentalBackground.onload = () => {
      // set image only when it is loaded
      this.dentalBackground = dentalBackground;
    };
  },
  mounted() {
    this.getDiagnose('');
    this.getProcedure();
    this.detailMaping();
    if (this.isDetail) {
      this.inputtedTooth = this.inputtedTooth.filter(
        value => value !== +this.dataDetail.no,
      );
    }
  },
  methods: {
    teethCheck(value) {
      if (!value) return 'Wajib diisi';
      if (!this.resource.teethNumber.includes(+value)) {
        return 'Nomor gigi tidak tersedia';
      }
      if (this.inputtedTooth.includes(+value)) {
        return 'Nomor gigi sudah diinputkan';
      }
    },
    detailMaping() {
      if (!this.isDetail) return;
      this.input = {
        ...this.input,
        no: this.dataDetail.no,
        subjective: this.dataDetail.subjective,
        diagnose: this.dataDetail.diagnose,
        description: this.dataDetail.description,
        treatmentPlan: this.dataDetail.treatmentPlan,
        action: this.dataDetail.action,
        procedure: this.dataDetail.procedure,
        objective: {
          sondasi: {
            description: this.dataDetail.objective.sondasi.description,
            plus: this.dataDetail.objective.sondasi.plus,
          },
          palpasi: {
            description: this.dataDetail.objective.palpasi.description,
            plus: this.dataDetail.objective.palpasi.plus,
          },
          perkusi: {
            description: this.dataDetail.objective.perkusi.description,
            plus: this.dataDetail.objective.perkusi.plus,
          },
          vitalitas: {
            description: this.dataDetail.objective.vitalitas.description,
            plus: this.dataDetail.objective.vitalitas.plus,
          },
        },
      };
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$emit('save', this.input);
        this.$emit('close-form');
      }
    },
    update() {
      if (this.$refs.form.validate()) {
        this.$emit('update', this.input);
        this.$emit('close-form');
      }
    },
    async getDiagnose(keyword) {
      this.diagnoseLoading = true;
      await axios
        .get(
          Constant.apiUrl.concat(
            `/master/diagnose?page=1&itemCount=100&search=${
              keyword === null ? '' : keyword
            }&sort=-code`,
          ),
        )
        .then(response => {
          let allDiagnose = response.data.data;
          this.resource.diagnose = allDiagnose.map(diagnose => {
            return {
              id: diagnose._id.toString(),
              code: diagnose.code,
              text: diagnose.description,
              isNonSpecialist:
                diagnose.nonSpesialis === undefined
                  ? ''
                  : diagnose.nonSpesialis,
              displayName: `${diagnose.code} ${diagnose.description}`,
            };
          });
          this.diagnoseLoading = false;
        })
        .catch(() => {
          this.diagnoseLoading = false;
        });
    },
    getProcedure(keyword) {
      this.procedureLoading = true;
      axios
        .get(
          `${
            Constant.apiUrl
          }/master/procedure?page=1&itemCount=50&&search=${keyword || ''}`,
        )
        .then(response => {
          const { data } = response.data;
          this.resource.procedure = data.map(procedure => {
            return {
              id: procedure._id.toString(),
              code: procedure.code.toString(),
              text: procedure.description,
              displayName: `${procedure.code} ${procedure.description}`,
            };
          });
        })
        .catch(() => {
          this.resource.procedure = [];
        })
        .finally(() => {
          this.procedureLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-form {
  max-height: 70vh;
  overflow-y: auto;
}
.grid-display {
  display: grid;
  width: 95%;
  column-gap: 5rem;
  row-gap: 2rem;
  padding-left: 0.8rem;
  grid-auto-flow: column;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
}

.grid-display-data {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 3fr;
  label {
    font-weight: 600;
    text-align: start;
  }
  .dark {
    background-color: #ededed;
  }
}

.grid-diagnose {
  display: grid;
  width: 95%;
  column-gap: 5rem;
  row-gap: 2rem;
  padding-left: 0.8rem;
  grid-auto-flow: column;
  grid-template-columns: repeat(2, 1fr);
}

.required:after {
  content: ' *';
  color: red;
}
</style>
