<template>
  <v-card class="d-flex asessment-card">
    <history-sidebar v-if="!isEmr" />
    <v-container fluid>
      <header-assessment
        :data="headerData"
        pl="2vw"
        pr="2vw"
        title="Assesmen Poli Umum"
      />
      <v-card-text class="pb-0">
        <InputAssessment
          v-if="!emrData"
          @close-form="$emit('close-form')"
          :patient-data="patientData"
          :is-emr="isEmr"
        />

        <InputAssessment
          v-else
          @close-form="$emit('close-form')"
          :patient-data="patientData"
          :is-emr="isEmr"
          :emr-data="emrData"
        />
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
import HistorySidebar from '../AssessmentComponents/HistorySidebar.vue';
import InputAssessment from './InputAssestment.vue';
import HeaderAssessment from '../AssessmentComponents/HeaderAssessment.vue';

const moment = require('moment');

export default {
  name: 'GeneralPolyAssestment',
  components: {
    InputAssessment,
    HistorySidebar,
    HeaderAssessment,
  },
  data() {
    return {
      headerData: '',
    };
  },
  props: {
    patientData: Object,
    isEmr: Boolean,
    emrData: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    this.headerData = [
      {
        label: 'No.RM',
        text: this.patientData.rmNumber,
      },
      {
        label: 'Nama',
        text: `${this.patientData.meta.name} (${
          this.patientData.meta.gender === 'Laki-laki' ? 'L' : 'P'
        })`,
      },
      {
        label: 'Tanggal Lahir',
        text: `${moment(this.patientData.meta.birthDate, 'DD MMMM YYYY').format(
          'DD/MM/YYYY',
        )} (${this.patientData.meta.age} Tahun)`,
      },
      {
        label: 'Hambatan dalam Berkomunikasi',
        text: this.patientData.commmunicationObstacles || 'Tidak Ada',
      },
    ];
  },
};
</script>

<style lang="scss" scoped>
.asessment-card {
  height: 100%;
}
</style>
