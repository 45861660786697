import {
  putGoods,
  postGoods,
  getAllGoods,
  deleteGoods,
  getGoodsById,
  putStatusGoods,
} from '../../../../fetchApi/MasterData/Goods';
import { getCategoryByRT } from '../../../../fetchApi/Resource/Goods';
import {
  getFactory,
  getPackagesByMenu,
} from '../../../../fetchApi/Resource/Drugs';

import formatMixin from '../../../../mixin/formatMixin';
import globalStore from '../../../index';

const state = {
  dashboard: {
    dialog: false,
  },
  form: {
    id: '',
    name: '',
    barcode: '',
    hna: 0,
    hpp: 0,
    min_stock: 0,
    auto_stock: 0,
    category: '',
    shelf: '',
    isActive: false,
    factory: '',
    packaging: [],
    timestamps: {
      created_by: '',
    },
  },
  items: {
    dataGoods: {
      isLoading: false,
      data: [],
    },
    category: {
      isLoading: false,
      data: [],
    },
    packages: {
      isLoading: false,
      data: [],
    },
    factory: {
      isLoading: false,
      data: [],
    },
  },
};

const getters = {
  // Get State Dialog
  getDialog: state => state.dashboard.dialog,

  // Get State Items
  getItems: state => state.items,

  // Get State Form
  getForm: state => state.form,
};

const mutations = {
  // Set State Dialog
  setDialog(state, payload) {
    state.dashboard.dialog = payload;
  },

  // Set State Items
  setItems(state, payload) {
    const { label, value, isLoading } = payload;
    state.items[label] = {
      ...state[label],
      data: value,
      isLoading,
    };
  },

  // Set State Form
  setForm(state, payload) {
    state.form = payload;
  },

  // Clear Form
  clearForm(state) {
    state.form = {
      id: '',
      name: '',
      barcode: '',
      hna: 0,
      hpp: 0,
      min_stock: 0,
      auto_stock: 0,
      category: '',
      shelf: '',
      isActive: false,
      factory: '',
      packaging: [],
      timestamps: {
        created_by: '',
      },
    };
  },
};

const actions = {
  // Get Data
  // Gett All Data Goods
  async resolveGetAllGoods(
    { commit, state },
    { halaman, itemCount, sortBy, keyword },
  ) {
    commit('setItems', {
      label: 'dataGoods',
      value: [...state.items.dataGoods.data],
      isLoading: true,
    });
    try {
      const goodsResponse = await getAllGoods({
        halaman,
        itemCount,
        sortBy,
        keyword,
      });

      const goodsMapper = goodsResponse?.data?.map(item => ({
        id: item._id.toString(),
        name: item.detail.name,
        barcode: item.detail.barcode,
        min_stock: item.detail.min_stock,
        hpp: item.detail.hpp,
        hppDisplay: formatMixin.methods.currency(item.detail.hpp),
        hna: item.detail.hna,
        category: item.detail.category,
        isActive: item.detail.isActive,
        factory: item.detail.factory,
        packaging: item.detail.packaging.map(p => {
          return {
            isDefault: p.isDefault,
            package: p.package,
            package_unit: p.package_unit,
            quantity: p.quantity,
          };
        }),
      }));

      commit('setItems', {
        label: 'dataGoods',
        value: goodsMapper,
        isLoading: false,
      });
      return goodsResponse.length;
    } catch (error) {
      commit('setItems', {
        label: 'dataGoods',
        value: [],
        isLoading: false,
      });
      return error && 0;
    }
  },

  // Get Data Goods By Id
  async resolveGetGoodsById({ state, commit }) {
    const responseGetGoodsById = await getGoodsById(state.form.id);
    try {
      commit('setForm', { ...state.form, ...responseGetGoodsById });
      return responseGetGoodsById;
    } catch (error) {
      return error;
    }
  },

  // Get Data Resource
  async fetchDataResource({ commit }, { label, apiFunction }) {
    commit('setItems', {
      label,
      value: [],
      isLoading: true,
    });
    try {
      const response = await apiFunction();
      const mapper = response.map(item => item.name);
      commit('setItems', {
        label,
        value: mapper,
        isLoading: false,
      });
    } catch (error) {
      commit('setItems', {
        label,
        value: [],
        isLoading: false,
      });
      return error;
    }
  },
  // Get Data Factory
  async resolveGetFactory({ dispatch }) {
    await dispatch('fetchDataResource', {
      label: 'factory',
      apiFunction: getFactory,
    });
  },
  // Get Data Category By Rumah Tangga
  async resolveGetCategoryByRT({ dispatch }) {
    await dispatch('fetchDataResource', {
      label: 'category',
      apiFunction: getCategoryByRT,
    });
  },
  // Get Data PackagesByMenu
  async resolveGetPackagesByMenu({ dispatch }) {
    await dispatch('fetchDataResource', {
      label: 'packages',
      apiFunction: getPackagesByMenu,
    });
  },

  // Post Data
  async resolvePostGoods({ state, commit }, packaging) {
    const payload = {
      detail: [
        {
          ...state.form,
          timestamps: {
            created_by: globalStore?.getters?.userLoggedIn?.id,
          },
          packaging,
        },
      ],
    };
    delete payload.detail[0].id;
    try {
      const postGoodsRes = await postGoods(payload);
      if (postGoodsRes.status === 200) {
        commit('setDialog', false);
      }
      return postGoodsRes.status;
    } catch (error) {
      return error;
    }
  },
  // End Post Data

  // Put Data
  async resolvePutGoods({ state, commit }, packaging) {
    const payload = {
      detail: [
        {
          ...state.form,
          timestamps: {
            created_by: globalStore?.getters?.userLoggedIn?.id,
          },
          packaging,
        },
      ],
    };
    delete payload.detail[0].id;
    try {
      const putGoodsRes = await putGoods(state.form.id, payload);
      if (putGoodsRes.status === 200) {
        commit('setDialog', false);
      }
      return putGoodsRes.status;
    } catch (error) {
      return error;
    }
  },

  async resolvePutStatusGoods(_, { id, payload }) {
    try {
      await putStatusGoods(id, payload);
    } catch (error) {
      return error;
    }
  },

  // Delete Data
  async resolveDeleteGoods(_, id) {
    try {
      const responseDeleteGoods = await deleteGoods(id);
      return responseDeleteGoods;
    } catch (error) {
      return error;
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
