<template>
  <v-card class="px-7 pt-7 pb-4">
    <v-card-title class="justify-center pa-0">
      <v-col class="py-0">
        <h3>Selesaikan Pelayanan</h3>
      </v-col>
    </v-card-title>
    <v-col class="py-0 mt-4">
      <p>
        Anda akan menyelesaikan pelayanan untuk pasien
        {{ patientData.meta.name }}, silahkan pilih tujuan selanjutnya
      </p>
    </v-col>
    <v-col>
      <v-btn
        :color="returnStatus === '3' ? 'primary' : '#9E9E9E'"
        outlined
        tile
        elevation="0"
        class="text-none"
        :class="returnStatus === '3' ? 'backgroundBlue' : ''"
        style="border-width: 2px;"
        height="50"
        width="230"
        @click="returnStatus = '3'"
        >Pulangkan Pasien</v-btn
      >
    </v-col>
    <v-col>
      <v-btn
        :color="
          returnStatus === '6' || returnStatus === '4' ? 'primary' : '#9E9E9E'
        "
        outlined
        tile
        elevation="0"
        class="text-none"
        :class="
          returnStatus === '6' || returnStatus === '4' ? 'backgroundBlue' : ''
        "
        style="border-width: 2px;"
        height="50"
        width="230"
        @click="returnStatus = '4'"
        >Rujuk Eksternal</v-btn
      >
    </v-col>
    <v-col>
      <v-btn
        :color="returnStatus === '2' ? 'primary' : '#9E9E9E'"
        outlined
        tile
        elevation="0"
        class="text-none"
        :class="returnStatus === '2' ? 'backgroundBlue' : ''"
        style="border-width: 2px;"
        height="50"
        width="230"
        @click="returnStatus = '2'"
        >Rawat Inap</v-btn
      >
    </v-col>
    <v-col>
      <v-btn
        :color="returnStatus === '1' ? 'primary' : '#9E9E9E'"
        outlined
        tile
        elevation="0"
        class="text-none"
        :class="returnStatus === '1' ? 'backgroundBlue' : ''"
        style="border-width: 2px;"
        height="50"
        width="230"
        @click="returnStatus = '1'"
        >Meninggal</v-btn
      >
    </v-col>
    <v-card-actions>
      <v-col>
        <v-btn
          outlined
          color="error"
          class="btnClose mr-4"
          width="80"
          @click="close()"
          >Batal</v-btn
        >
        <v-btn
          outlined
          text
          color="white"
          :disabled="
            (patientData.isFinish && assuranceType == 'bpjs') ||
              (patientData.isFinish && !isChanged)
          "
          class="btnSuccess"
          :width="!patientData.isFinish ? 100 : 80"
          @click="addData()"
          >{{ patientData.isFinish ? 'Ubah' : 'Lanjutkan' }}</v-btn
        >
      </v-col>
    </v-card-actions>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import Constant from '@/const';
import jwtMixin from '@/mixin/jwtMixin';
import alertMixin from '@/mixin/alertMixin';

const axios = require('axios');
// const moment = require('moment-timezone');
// const _ = require('lodash');

export default {
  name: 'FinishService',
  mixins: [jwtMixin, alertMixin],
  props: {
    patientData: {
      type: Object,
      default: null,
    },
    id_registration: {
      type: String,
    },
  },
  data() {
    return {
      oldReturnStatus: '',
      returnStatus: '',
      isChanged: false,
      assuranceType: '',
    };
  },
  watch: {
    returnStatus: function(newVal, oldVal) {
      if (oldVal && this.oldReturnStatus !== this.returnStatus) {
        this.isChanged = true;
      } else {
        this.isChanged = false;
      }
    },
  },
  mounted() {
    this.getDataAssessment();
  },
  methods: {
    addData() {
      axios
        .post(
          Constant.apiUrl.concat(
            `/patient/registration/finish/${this.patientData.id_registration}/${
              this.$store.getters.userLoggedIn.id
            }?return_status=${this.returnStatus || ''}`,
          ),
        )
        .then(() => {
          Swal.fire('Pelayanan Telah Diselesaikan', '', 'success');
          this.close();
        })
        .catch(err => {
          this.showErrorAxios(err);
        });
    },
    close() {
      this.$emit('close-form');
    },
    getDataAssessment() {
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/patient/checkup/regist/${this.patientData.id_registration}/${
                this.isEmr ? 'history' : 'service'
              }`,
            ),
          )
          .then(response => {
            const data = response.data.data;
            this.oldReturnStatus =
              data.assesment.return_status || this.patientData.returnStatus;
            this.returnStatus =
              data.assesment.return_status || this.patientData.returnStatus;
            this.assuranceType = data.id_registration.assurance.type;
          })
          .catch(() => {
            this.oldReturnStatus = '';
            this.returnStatus = '';
            this.assuranceType = '';
          });
      });
    },
  },
};
</script>

<style>
.backgroundBlue {
  background: #deeef9;
}

.btnClose {
  border: 1px solid #eb4747;
  text-transform: unset;
  border-radius: 4px;
}

.btnSuccess {
  border: 1px solid #eb4747;
  border-radius: 4px;
  text-transform: unset;
  background: #137bc0;
  color: white;
}
</style>
