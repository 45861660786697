<template>
  <v-card class="main-container">
    <v-card-title class="justify-center">
      <span class="font-weight-bold">Tambahkan Prosedur Baru</span>
      <v-btn absolute right icon @click="$emit('close-dialog')">
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row justify="center">
          <v-col cols="10">
            <v-form lazy-validation ref="form">
              <div class="detail-input">
                <div class="detail-input__label">
                  <label for="name">Kode Prosedur</label>
                </div>

                <v-text-field
                  v-model="input.code"
                  placeholder="Masukkan Kode Prosedur"
                  class="detail-input__input-text"
                  required
                  :rules="[v => !!v || 'Wajib diisi']"
                />
              </div>
              <div class="detail-input">
                <div class="detail-input__label">
                  <label for="name">Deskripsi Prosedur</label>
                </div>

                <v-textarea
                  v-model="input.description"
                  placeholder="Tambahkan Deskripsi Prosedur"
                  class="detail-input__input-text"
                  name="deskripsiprosedur"
                  outlined
                  height="200"
                  required
                  :rules="[v => !!v || 'Wajib diisi']"
                ></v-textarea>
              </div>
            </v-form>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="text-none" text @click="$emit('close-dialog')">
                Kembali
              </v-btn>
              <v-btn
                class="text-none"
                depressed
                color="primary"
                @click="addNewProcedure()"
              >
                Simpan
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import Constant from '@/const';

const axios = require('axios');

export default {
  methods: {
    addNewProcedure() {
      if (this.$refs.form.validate()) {
        axios
          .post(Constant.apiUrl.concat('/master/procedure/'), {
            code: this.input.code,
            description: this.input.description,
          })
          .then(response => {
            if (response.status === 200) {
              Swal.fire('Berhasil', 'Data Prosedur Sudah Masuk', 'success');
              this.onCloseButtonClick();
            }
          })
          .catch(error => {
            if (error.response) {
              const message = error.response.data.pesan;
              Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: message,
              });
            } else if (error.request) {
              Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: 'Tidak dapat menyambung ke server',
              });
            }
          });
      }
    },
    onCloseButtonClick() {
      this.$emit('close-dialog');
    },
  },
  data: () => ({
    input: {
      code: '',
      description: '',
    },
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'Kemasan',
        align: 'start',
        sortable: false,
        value: 'kemasan',
      },
      { text: 'Jumlah', value: 'jumlah' },
      { text: 'Satuan', value: 'satuan' },
      { text: 'Kemasan Terkecil', value: 'kemasanterkecil' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
  }),
};
</script>

<style lang="scss" scoped>
.main-container {
  width: 30vw;
}

.column {
  margin: 0 auto;
}

.detail-radio {
  display: flex;
  justify-content: flex-end;
}

.detail-input {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  &__label {
    justify-content: flex-end;
    flex: 1;
    display: flex;
    color: #222;
  }

  &__input-text {
    margin-left: 10px;
    width: 150px;
  }
}

.padding {
  padding-left: 10px;
}

.radio {
  margin: 0;
}

.table-kemasan {
  width: 100%;
  margin-top: 20px;
}
</style>
