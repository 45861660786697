<template>
  <div>
    <v-container fluid class="container-main">
      <div class="d-flex align-center justify-space-between">
        <span class="container-main__head-text">
          <Skeleton :isLoading="items.informConsentList.loading">
            Daftar Formulir Informed Consent
          </Skeleton>
        </span>
        <Skeleton :isLoading="items.informConsentList.loading">
          <v-btn
            class="text-capitalize"
            color="primary"
            dark
            dense
            depressed
            @click="handleSelectDialog(true, 'add', '')"
          >
            <v-icon left>
              mdi-plus
            </v-icon>
            Buat Formulir Baru
          </v-btn>
        </Skeleton>
      </div>
      <v-skeleton-loader
        v-if="items.informConsentList.loading"
        type="table"
      ></v-skeleton-loader>
      <v-data-table
        v-else
        :headers="table.headers"
        :items-per-page="itemsPerRow"
        :items="items.informConsentList?.items"
        class="elevation-0 container-main__data-table scrollbar"
        single-expand
        hide-default-footer
        disable-pagination
      >
        <template slot="no-data">
          <span>Tidak ada riwayat informed consent</span>
        </template>
        <template v-slot:item="{ item }">
          <tr class="text-left" :key="item.index">
            <td>{{ formatDate(item.created_at) }}</td>
            <td>{{ item.action }}</td>
            <td>{{ item.signer_name }}</td>
            <td>{{ item.staff_information }}</td>
            <td>
              <v-chip
                :class="'chipType ' + item.patient_action_agreement"
                label
                :color="getStatementType(item.patient_action_agreement).color"
              >
                {{ getStatementType(item.patient_action_agreement).text }}
              </v-chip>
            </td>
            <td>
              <v-menu top :offset-x="true" :close-on-content-click="true">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon>
                      mdi-dots-vertical
                    </v-icon>
                  </span>
                </template>

                <v-list
                  v-for="(itemList, index) in actionItems"
                  :key="index"
                  style="padding: 1px;"
                >
                  <v-list-item
                    @click="itemList.action(item.id, item.created_at)"
                    class="container-main__data-table__dropdown"
                  >
                    <v-list-item-title>
                      <span class="container-main__data-table__dropdown-item">
                        {{ itemList.label }}
                      </span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog v-model="showDialog" :max-width="1400" persistent>
      <FormInformedConsent :state="this.stateForm" />
    </v-dialog>
    <v-snackbar
      absolute
      light
      content-class="font-weight-medium pr-0"
      :value="snackbar.show"
      elevation="0"
      :color="snackbar.color"
      height="15"
      timeout="4100"
    >
      <div class="d-flex justify-space-between align-center">
        <span style="color: white">
          {{ snackbar.text }}
        </span>
        <v-btn
          style="color: white"
          text
          fab
          small
          right
          @click="snackbar.show = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import InformedConsent from './InformedConsent.js';
import FormInformedConsent from './FormInformedConsent.vue';
import Skeleton from '../../../SharedComponent/Skeleton.vue';

export default {
  ...InformedConsent,
  components: { FormInformedConsent, Skeleton },
  props: { unit: String },
  name: 'ListInformedConsent',
  mounted() {
    this.resolveGetCheckupRegist({
      idRegist: this.patientData.id_registration,
      unit: this.unit,
    });
    this.resolveGetInformConsentList(this.patientData.id_emr);
  },
};
</script>

<style lang="scss" scoped>
.header {
  color: #404040;
}

.scrollbar {
  &::-webkit-scrollbar {
    width: 0.4rem;
    border-radius: 1rem;
    background-color: rgb(214, 214, 214);
  }
  &::-webkit-scrollbar-thumb {
    width: 0.4rem;
    border-radius: 1rem;
    background-color: rgb(146, 146, 146);
  }
}
.container-main {
  margin-top: 40px;
  padding: 0 3vw;
  &__head-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #404040;
  }
  &__data-table {
    margin-top: 20px;
    height: 75vh;
    overflow: scroll;
    overflow-x: hidden;
    padding: 0 15px 0 0;
    .chipType.true {
      border: 1px solid #2f80ed !important;
      color: #2f80ed;
    }

    .chipType.false {
      border: 1px solid #eb4747 !important;
      color: #eb4747;
    }
    &__dropdown {
      min-height: 40px;
      border: 1px solid black;
      margin: 6px 12px;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
    }
    &__dropdown:hover {
      background-color: #e6f6fe;
      cursor: pointer;
    }
    &__dropdown-item {
      color: #404040;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
    }
  }
}

::v-deep .v-data-table-header {
  background-color: rgba(194, 194, 194, 0.3);
}
</style>
