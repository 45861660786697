import EventBus from '@/eventBus';
import bus from '@/eventBus/bus.js';

export default {
  mixins: [bus],
  created() {
    this.busListener();
  },
  methods: {
    emitMessageCall(payload) {
      EventBus.$emit('message:call', payload);
    },
    async onMessageUpdate({ refresh, isCalling }) {
      this.$socket.client.on('message:update', data => {
        EventBus.$emit('message-update', { refresh, isCalling, data });
      });
    },
    emitIsCalling(data) {
      EventBus.$emit('message:call', data);
    },
    callPatient(data) {
      const methods = this;
      let speak = [];
      const word1 = 'Nomor antrean';
      speak.push(word1);
      const word2 = data.queue_code;
      speak.push(word2);
      const word3 = 'ke Ruangan';
      speak.push(word3);
      const word4 = data.room;
      speak.push(word4);
      const speech = new SpeechSynthesisUtterance(speak);
      speech.rate = 0.9;
      speech.lang = 'id-ID';
      speech.onstart = function() {
        methods.emitIsCalling(true);
      };
      speech.onend = function() {
        methods.emitIsCalling(false);
      };
      window.speechSynthesis.speak(speech);
    },
  },
};
