import DashboardQueueHeader from '../DashboardQueueHeader.vue';
import { createNamespacedHelpers } from 'vuex';
import PatientQueueMixin from '@/mixin/PatientQueueMixin';
const { mapGetters, mapActions } = createNamespacedHelpers('PatientQueue');

export default {
  name: 'DetailPatientQueue',
  mixins: [PatientQueueMixin],
  components: {
    DashboardQueueHeader,
  },
  data() {
    return {
      isCalling: false,
    };
  },
  computed: {
    ...mapGetters([
      'getRoom',
      'getRemainingCount',
      'getTotal',
      'getQueue',
      'getIsMissedCount',
      'getUnit',
      'getStaff',
      'getMissedPatients',
      'getIsLoading',
    ]),
    clinicName() {
      return this.$store.getters.userLoggedIn.clinic.name.toLowerCase();
    },
    isUserDoctor() {
      return this.$store.getters.userLoggedIn.role.toLowerCase() === 'dokter';
    },
    room: {
      get() {
        return this.getRoom;
      },
    },
    isLoading: {
      get() {
        return this.getIsLoading;
      },
    },
    totalQueue: {
      get() {
        return this.getTotal;
      },
    },
    remainingQueue: {
      get() {
        return this.getRemainingCount;
      },
    },
    queue: {
      get() {
        return this.getQueue;
      },
    },
    isMissedCount: {
      get() {
        return this.getIsMissedCount;
      },
    },
    staff: {
      get() {
        return this.getStaff;
      },
    },
    unit: {
      get() {
        return this.getUnit;
      },
    },
    missedPatients: {
      get() {
        return this.getMissedPatients;
      },
    },
  },
  methods: {
    ...mapActions(['resolveGetPatientQueue', 'resolveModifyQueue']),
    async handleModifyQueue(data, isHold = false) {
      const payload = localStorage.getItem('detailQueuePayload');
      await this.resolveModifyQueue({
        ...JSON.parse(payload),
        id_item: data._id,
        isHold,
      });
      this.emitMessageCall({ queue_code: null, room: null });
      this.handleGetPatientQueue();
    },
    async handleGetPatientQueue() {
      const payload = localStorage.getItem('detailQueuePayload');
      await this.resolveGetPatientQueue({
        payload: JSON.parse(payload),
      });
    },
    recall(queue_code) {
      const payload = {
        queue_code,
        room: this.room,
      };
      this.emitMessageCall(payload);
    },
  },
  mounted() {
    this.handleGetPatientQueue();
    this.onMessageUpdate({
      refresh: this.handleGetPatientQueue,
      isCalling: false, // update data without calling patient
    });
    this.$socket.client.on('message:update-sound', data => {
      this.isCalling = data;
    });
  },
};
