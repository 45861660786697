<template>
  <v-card style="height: 100%">
    <v-card-title class="justify-center">
      <h3>Input Data Kas</h3>
    </v-card-title>
    <v-card-body>
      <v-row class="row-edit">
        <v-col class="ml-6 pl-6 pt-6">
          <v-row class="pl-6">
            <v-col cols="5" class="text-left pb-0">
              <label>Tanggal Transaksi</label>
            </v-col>
            <v-col cols="5" class="pt-2 pb-0">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="input.date"
                    append-icon="mdi-chevron-down"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  @input="menu = false"
                  v-model="date"
                  no-title
                  scrollable
                >
                  <!-- <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(input.date)"
                  >
                    OK
                  </v-btn> -->
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row class="pl-6">
            <v-col cols="5" class="text-left">
              <label>Nama Transaksi</label>
            </v-col>
            <v-col cols="5" class="pt-2">
              <v-text-field
                v-model="input.transName"
                placeholder="Masukkan Nama Transaksi"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="pt-6">
          <v-row>
            <v-col cols="5" class="text-left pl-10 pb-0">
              <label>Sub Kategori</label>
            </v-col>
            <v-col cols="5" class="pt-2 pb-0">
              <v-select
                v-model="input.subCategory"
                return-object
                item-text="name"
                :items="selectSub"
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="text-left pl-10 pb-0">
              <label>Jenis Kas</label>
            </v-col>
            <v-col cols="6" class="pt-0 pb-0">
              <v-radio-group row>
                <v-radio
                  label="Kas Masuk"
                  @click="radioSelected = 'kredit'"
                ></v-radio>
                <v-radio
                  label="Kas Keluar"
                  @click="radioSelected = 'debit'"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="text-left pl-10">
              <label>Nominal</label>
            </v-col>
            <v-col cols="5" class="pt-1">
              <v-text-field
                placeholder="Masukkan Nominal"
                v-model.number="input.nominal"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-body>
    <v-card-actions class="pr-16 pb-6">
      <v-spacer></v-spacer>
      <v-btn color="primary" tile elevation="0" @click="save()" width="108">
        Simpan
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Constant from '@/const';
const axios = require('axios');
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      selectSub: [],
      select: {
        subCategory: [],
      },
      subCategory1: [],
      subCategory2: [],
      radioSelected: '',
      input: {
        date: this.formatDate(new Date().toISOString().substr(0, 10)),
        nominal: '',
        subCategory: '',
        transName: '',
      },
      menu: false,
    };
  },

  mounted() {
    this.getSubCategory();
  },

  watch: {
    date(val) {
      if (val) {
        this.input.date = this.formatDate(this.date);
      }
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    closeButton() {
      this.$emit('close-form');
    },
    save() {
      axios
        .post(Constant.apiUrl.concat('/finance/report'), {
          sub_category: this.input.subCategory.name,
          detail: [
            {
              name_transaction: this.input.transName,
              total: this.input.nominal,
              type_fund: this.radioSelected,
              timestamps: {
                created_by: this.$store.getters.userLoggedIn.name,
              },
            },
          ],
          timestamps: {
            created_by: this.$store.getters.userLoggedIn.name,
          },
        })
        .then(response => {
          if (response.status === 200) {
            Swal.fire('Data Transaksi berhasil disimpan', '', 'success');
            this.closeButton();
          }
        })
        .catch(error => {
          if (error.response) {
            const message = error.response.data.pesan;
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: message,
            });
          } else if (error.request) {
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: 'Tidak dapat menyambung ke server',
            });
          }
        });
    },

    getSubCategory() {
      axios
        .get(Constant.apiUrl.concat('/finance/category/sub'))
        .then(response => {
          const getItemSub1 = response.data.data;
          this.subCategory1 = getItemSub1.map(item => {
            return {
              sub: item.sub.map(item => {
                return {
                  name: item.name,
                };
              }),
            };
          });

          for (var i = 0; i < this.subCategory1.length; i++) {
            if (this.subCategory1[i].sub !== undefined) {
              this.select.subCategory.push(...this.subCategory1[i].sub);
            }
          }

          const arr = this.select.subCategory;
          const result = arr.filter(item => item.name !== undefined);
          this.selectSub.push(...result);
        });
    },
  },
};
</script>

<style>
.v-text-field {
  padding-top: 0 !important;
  margin-top: 0 !important;
}
.row-edit {
  width: 1200px;
  margin-top: 8px;
  height: 370px;
}
.text__label {
  text-align: left;
}
.v-input--selection-controls {
  margin-top: 8px !important;
}
</style>
