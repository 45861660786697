<template>
  <v-card style="overflow-x: hidden; padding-right: 3rem !important;">
    <v-toolbar flat prominent class="mb-5">
      <v-container fluid class="px-0 mx-0">
        <div class="d-flex justify-space-between">
          <h2 class="text-left mb-7">Catatan Pemberian Obat</h2>
          <v-btn @click="$emit('close-form')" small fab text>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-row>
          <v-col cols="8">
            <v-row class="header-grid">
              <span class="font-weight-bold body-2 text-left">No RM</span>
              <span class="black--text mx-6 body-2 text-left">{{
                patientData.rmNumber
              }}</span>

              <span class=" font-weight-bold body-2 text-left">Name</span>
              <span class="black--text mx-6 body-2 text-left"
                >{{ patientData.meta.name }} ({{
                  patientData.meta.gender ? 'L' : 'P'
                }})</span
              >

              <span class=" font-weight-bold body-2 text-left"
                >Tanggal Lahir</span
              >
              <span class="black--text mx-6 body-2 text-left"
                >{{ patientData.meta.birthDate }} ({{
                  patientData.meta.age
                }}
                tahun)</span
              >
              <span class=" font-weight-bold body-2 text-left">DPJP</span>
              <span class="black--text body-2 text-left mx-6 ">{{
                patientData.meta.doctor
              }}</span>
            </v-row>
          </v-col>
          <v-col cols="4">
            <v-row class="mb-4">
              <span class="text-left font-weight-bold body-2"
                >Riwayat Alergi</span
              >
            </v-row>
            <v-row class="mt-n3">
              <span
                class="red--text font-weight-bold body-2"
                v-if="allergy?.length === 0"
                >Tidak ada alergi</span
              >
              <template v-for="(allergy, idx) in allergy">
                <v-chip
                  :key="idx"
                  color="#EB4747"
                  class="allergy mr-2 mb-2"
                  label
                  small
                  caption
                  >{{ allergy }}</v-chip
                >
              </template>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-toolbar>
    <v-main>
      <v-progress-circular
        v-if="loading"
        indeterminate
        size="100"
        color="primary"
      ></v-progress-circular>
      <v-container v-else fluid class="table-layout">
        <v-row class="header-container">
          <v-col
            cols="6"
            class="pa-0"
            :class="{ 'border-right': headerDate?.length === 0 }"
          >
            <div class="grid header px-2">
              <label class="text-left body-2 font-weight-medium">No</label>
              <label class="text-left body-2 font-weight-medium"
                >Lanjut Obat</label
              >
              <label class="text-left body-2 font-weight-medium"
                >Nama Obat</label
              >
              <label class="text-left body-2 font-weight-medium"
                >Aturan Pakai</label
              >
              <label class="text-left body-2 font-weight-medium">Rute</label>
              <label class="text-left body-2 font-weight-medium"
                >Diberikan di</label
              >
            </div>
          </v-col>
          <v-col
            @scroll="scrollingHeader()"
            cols="6"
            class="pa-0 scrollable"
            ref="header"
          >
            <div v-if="headerDate?.length === 0" class="header-default">
              <span>
                Waktu Pemberian Obat Belum Ada
              </span>
            </div>
            <table class="table-header">
              <thead>
                <tr>
                  <th
                    style="min-width: 100px;"
                    v-for="header in headerDate"
                    :key="header.date"
                    :colspan="header.time.length"
                  >
                    {{ header.date }}
                  </th>
                </tr>
                <tr>
                  <template v-for="header in headerDate">
                    <th v-for="(time, index) in header.time" :key="index">
                      {{ time.time }}
                    </th>
                  </template>
                </tr>
              </thead>
            </table>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="6"
            class="pa-0"
            :class="{ 'border-right': headerDate?.length === 0 }"
          >
            <v-data-iterator
              :items="table.items"
              hide-default-footer
              item-key="no"
              id="scroll-target"
              :items-per-page="table.items.length"
              disable-sort
              :loading="loading"
              single-expand
              v-scroll:#scroll-target="onScrollX"
            >
              <template v-slot:default="{ items }">
                <v-card flat tile>
                  <v-expansion-panels
                    accordion
                    flat
                    light
                    tile
                    class="elevation-0"
                  >
                    <v-expansion-panel
                      class="elevation-0"
                      v-for="(arr, index) in items"
                      :key="`item-${index}`"
                      @click="onClick(arr, index)"
                    >
                      <v-expansion-panel-header
                        class="grid data pa-0"
                        :class="{ disable: !arr.isContinued }"
                        expand-icon=""
                        :color="arr.isContinued ? '' : '#F4F5F5'"
                      >
                        <label class="text-center body-2">{{ arr.no }}</label>
                        <v-switch
                          inset
                          dense
                          flat
                          light
                          @click="confirmOff(arr)"
                          v-model="arr.isContinued"
                        ></v-switch>
                        <div class="d-flex flex-column">
                          <label class="text-left body-2 pr-3">{{
                            arr.nonracik.drugName || arr.racik.drugName
                          }}</label>
                          <span
                            class="mt-1"
                            style="font-size: 13px; color: grey"
                            >{{
                              arr.nonracik.id_drugs
                                ? (arr.nonracik.unit_dosage
                                    ? arr.nonracik.dosage
                                    : '') +
                                  ' ' +
                                  (arr.nonracik.unit_dosage || '')
                                : ''
                            }}</span
                          >
                        </div>
                        <label class="text-left body-2">{{
                          arr.racik.usageRule || arr.nonracik.usageRule
                        }}</label>
                        <label class="text-left body-2">{{
                          arr.racik.route || arr.nonracik.route
                        }}</label>
                        <label class="text-left body-2 pr-2 ">{{
                          arr.racik.given?.toUpperCase() ||
                            arr.nonracik.given?.toUpperCase()
                        }}</label>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="py-3" color="#F4F5F5">
                        <div class="expand-content">
                          <span></span>
                          <span
                            class="grey--text font-weight-medium caption text-left d-flex align-center"
                            >Diresepkan oleh
                            {{ arr.racik.staff || arr.nonracik.staff }}</span
                          >
                          <v-btn
                            color="primary"
                            class="caption action-button edit font-weight-medium"
                            height="30"
                            outlined
                            :disabled="
                              arr.history.length > 0 ||
                                arr.time_delivery.length > 0
                            "
                            v-if="isDoctor"
                            @click="isDoctor ? selectItem(3, arr) : ''"
                            ><v-icon small>mdi-square-edit-outline</v-icon>Edit
                            Obat <v-icon small>mdi-arrow-right</v-icon></v-btn
                          >
                          <v-btn
                            color="red"
                            outlined
                            v-if="isDoctor"
                            :disabled="
                              arr.history.length > 0 ||
                                arr.time_delivery.length > 0
                            "
                            class="caption action-button delete font-weight-medium"
                            height="30"
                            @click="isDoctor ? deleteItem(arr, index) : ''"
                            ><v-icon small>mdi-delete</v-icon>Hapus Obat
                            <v-icon small>mdi-arrow-right</v-icon></v-btn
                          >
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card>
              </template>
            </v-data-iterator>
          </v-col>
          <v-col
            cols="6"
            class="pa-0 table-time-container scrollable"
            ref="time"
            @scroll="scrolling()"
          >
            <table class="table-grid">
              <tbody>
                <tr v-for="(props, index) in table.items" :key="index">
                  <template v-for="(item, index) in headerDate">
                    <td
                      v-for="(time, timeIndex) in item.time"
                      :key="'date' + index + 'time-' + timeIndex"
                      :class="{
                        'width-grid-2': headerDate[index]?.time?.length === 1,
                        'width-grid-1': headerDate[index]?.time?.length > 1,
                      }"
                    >
                      <v-flex class="highalert">
                        <v-icon
                          medium
                          :color="setIcon(props, item.date, time.time).color"
                        >
                          {{ setIcon(props, item.date, time.time).icon }}
                        </v-icon>
                        <div
                          :class="setIcon(props, item.date, time.time).class"
                        ></div>

                        <v-icon
                          :size="12"
                          class="alert-inner"
                          v-if="
                            (props.isPotentNonRacik || props.isPotentRacik) &&
                              setIcon(props, item.date, time.time).icon ===
                                'mdi-check-circle' &&
                              setIcon(props, item.date, time.time).color ===
                                '#219653'
                          "
                          color="#EB4747"
                        >
                          mdi-circle
                        </v-icon>
                      </v-flex>
                    </td>
                  </template>
                </tr>
              </tbody>
            </table>
          </v-col>
        </v-row>
        <v-row class="footer-container">
          <v-col cols="6" class="pa-0">
            <v-toolbar
              color="#f4f5f5"
              class="d-flex justify-end table-footer"
              light
              flat
            >
              <v-toolbar-title class="subtitle-1 font-weight-medium text-right">
                Nama Petugas
              </v-toolbar-title>
            </v-toolbar>
          </v-col>

          <v-col
            @scroll="scrollingFooter()"
            ref="footer"
            cols="6"
            class="pa-0 scrollable visible"
          >
            <table class="footer-table">
              <tfoot>
                <tr>
                  <template v-for="(item, index) in headerDate">
                    <td
                      v-for="(time, timeIndex) in item.time"
                      class="staff-col"
                      :key="'date' + index + 'time-' + timeIndex"
                      :class="{
                        'width-grid-2': headerDate[index]?.time?.length === 1,
                        'width-grid-1': headerDate[index]?.time?.length > 1,
                      }"
                    >
                      <div class="d-flex flex-column">
                        <v-chip
                          small
                          color="#B2FFD3"
                          text-color="#219653"
                          label
                          class="chip-center"
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <h5 v-bind="attrs" v-on="on">
                                {{ getNameInitial(time.created_by) }}
                              </h5>
                            </template>
                            <span>{{ time.created_by }}</span>
                          </v-tooltip>
                        </v-chip>
                        <v-chip
                          small
                          color="#fff9e6"
                          text-color="#e5a82e"
                          label
                          v-if="time.verified_by"
                          class="chip-center mt-1"
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <h5 v-bind="attrs" v-on="on">
                                {{ getNameInitial(time.verified_by) }}
                              </h5>
                            </template>
                            <span>{{ time.verified_by }}</span>
                          </v-tooltip>
                        </v-chip>
                      </div>
                    </td>
                  </template>
                </tr>
              </tfoot>
            </table>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer color="white">
      <div class="description">
        <v-icon class="mr-1" small color="success">mdi-check-circle</v-icon>
        <span class="font-weight-medium mr-3 caption">Lanjut dan berikan</span>
        <v-icon class="mr-1" small color="error">mdi-close-circle</v-icon>
        <span class="font-weight-medium mr-3 caption">Hentikan pemberian</span>
        <v-icon class="mr-1" small>mdi-clock-time-four-outline</v-icon>
        <span class="font-weight-medium caption">Lanjut dan belum diberi</span>
      </div>
      <v-spacer></v-spacer>
      <v-btn
        :width="isDoctor ? '200' : '220'"
        class="ml-6"
        outlined
        v-if="isDoctor || isNurse"
        color="primary"
        @click="selectItem(2, null)"
      >
        <span class="font-weight-bold">{{
          isDoctor ? 'ORDER OBAT FARMASI' : 'INPUT WAKTU PEMBERIAN'
        }}</span>
      </v-btn>
      <v-btn
        width="200"
        class="ml-6"
        outlined
        color="primary"
        v-if="isDoctor || isNurse"
        @click="selectItem(0, null)"
      >
        <span class="font-weight-bold">INPUT ALKES DAN BHP</span>
      </v-btn>
      <v-btn
        v-if="isDoctor || isNurse || isPharmacy"
        class="ml-6"
        depressed
        color="primary"
        @click="selectItem(1, null)"
      >
        <span class="font-weight-bold">{{
          isDoctor ? 'TAMBAH OBAT' : 'TELAAH OBAT'
        }}</span>
      </v-btn>
    </v-footer>
    <v-dialog v-model="dialog" width="1400" persistent>
      <BHPOrder
        v-if="selectedForm === 0 && dialog"
        :allergy="allergy"
        :idEmr="idEmr"
        :patientStatus="patientStatus"
        :patient-data="patientData"
        @close-dialog="dialog = false"
      />
      <DrugInput
        v-else-if="selectedForm === 1 && dialog && isDoctor"
        :patient-data="patientData"
        :allergy="allergy"
        @close-dialog="closeDialog()"
      />
      <DrugReviewHistory
        v-else-if="selectedForm === 1 && dialog && isNurse"
        :patient-data="patientData"
        :allergy="allergy"
        :idDrugAdministration="idDrugAdministration"
        @open-detail="openDetailRecipe()"
        @close-dialog="closeDialog()"
      />
      <PharmacyOrder
        v-else-if="selectedForm === 2 && dialog && isDoctor"
        :patient-data="patientData"
        :allergy="allergy"
        :drugs="orderPharmacy"
        :idEmr="idEmr"
        :idStaffMixRecipe="idStaffMixRecipe"
        :idStaffRecipe="idStaffRecipe"
        :patientStatus="patientStatus"
        @close-dialog="closeDialog()"
      />
      <InputDrugSchedule
        v-else-if="selectedForm === 2 && dialog && isNurse"
        :patient-data="patientData"
        :idRegistration="idRegistration"
        :patientStatus="patientStatus"
        :idDrugAdministration="idDrugAdministration"
        @close-dialog="closeDialog()"
      />
      <DrugEdit
        v-else-if="selectedForm === 3 && dialog"
        :patient-data="patientData"
        :drug-item="drugItem"
        :idDrugAdministration="idDrugAdministration"
        :allergy="allergy"
        @close-dialog="closeDialog()"
      />
      <DetailRecipe
        v-else-if="selectedForm === 5 && dialog && isNurse"
        :patient-data="patientData"
        @close-dialog="closeDialog()"
        :allergy="allergy"
        :idEmr="idEmr"
        @back="back()"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import PharmacyOrder from './PharmacyOrder.vue';
import DrugInput from './DrugInput.vue';
import DrugReviewHistory from './DrugReviewHistory.vue';
import InputDrugSchedule from './InputDrugSchedule.vue';
import DrugEdit from './DrugEdit.vue';
import DetailRecipe from './DetailRecipe.vue';
import BHPOrder from './BHPOrder.vue';
import Constant from '@/const';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import alertMixin from '../../../../mixin/alertMixin';

const axios = require('axios');

export default {
  name: 'DrugRecord',
  props: {
    patientData: {
      type: Object,
      default: null,
    },
  },
  mixins: [alertMixin],
  components: {
    PharmacyOrder,
    InputDrugSchedule,
    DrugReviewHistory,
    DrugInput,
    DrugEdit,
    DetailRecipe,
    BHPOrder,
  },
  data() {
    return {
      sheet: false,
      search: '',
      menu: false,
      scrollTop: 0,
      menu2: false,
      previousIndex: 0,
      table: {
        headers: [
          {
            text: 'No',
            value: 'no',
            width: '5%',
            fixed: true,
          },
          {
            text: 'Jenis Obat',
            value: 'drugType',
            sortable: false,
            width: '10%',
            fixed: true,
          },
          {
            text: 'Nama Obat',
            value: 'drugName',
            sortable: false,
            width: '15%',
            fixed: true,
          },
          {
            text: 'Dosis/Aturan Pakai',
            value: 'dose',
            sortable: false,
            width: '10%',
            fixed: true,
          },
          {
            text: 'Waktu',
            value: 'time',
            sortable: false,
          },
        ],
        items: [],
        tableHardCode: [],
        pagination: {
          descending: false,
          page: 1,
          rowsPerPage: 5,
          sortBy: '',
        },
      },
      headerDate: [],
      timetableWidth: 20,
      selectedForm: null,
      dialog: false,
      loading: false,
      idEmr: '',
      idDrugAdministration: '',
      idRegistration: '',
      idStaffMixRecipe: '',
      idStaffRecipe: '',
      patientStatus: '',
      expanded: [],
      tableInput: {
        headers: [
          { text: '', value: 'action' },
          { text: 'R/', value: 'no' },
          { text: 'Nama Obat', value: 'name' },
          { text: 'Jenis Sediaan', value: 'preparation' },
          { text: 'Dosis', value: 'dosage' },
          { text: 'Rute', value: 'route' },
          { text: 'Aturan Pakai', value: 'usage' },
          { text: 'Keterangan', value: 'note' },
        ],
        items: [],
      },
      drugItem: null,
      allergy: [],
      schedule: [],
      drugSchedule: [],
      inputSchedule: {
        drugSchedule: '',
        dateDrug: moment(new Date()).format('YYYY-MM-DD'),
        time: moment(new Date()).format('HH:mm'),
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshTable();
    });
  },
  computed: {
    poly() {
      let result;
      const routeArray = this.$route.path.split('/');
      switch (routeArray[routeArray.length - 1]) {
        case 'klinik-igd':
          result = 'igd';
          break;
        case 'rawat-inap':
          result = 'rawat_inap';
          break;
      }
      return result;
    },
    orderPharmacy() {
      return this.table.items.filter(x => x.isContinued);
    },
    isDoctor() {
      return this.$store.getters.userLoggedIn.role.toLowerCase() === 'dokter';
    },
    isNurse() {
      return this.$store.getters.userLoggedIn.role.toLowerCase() === 'perawat';
    },
    isPharmacy() {
      return this.$store.getters.userLoggedIn.role.toLowerCase() === 'farmasi';
    },
  },
  methods: {
    scrolling() {
      const time = this.$refs.time;
      const header = this.$refs.header;
      const footer = this.$refs.footer;

      footer.scrollLeft = time.scrollLeft;
      header.scrollLeft = time.scrollLeft;
    },
    scrollingFooter() {
      const time = this.$refs.time;
      const header = this.$refs.header;
      const footer = this.$refs.footer;

      time.scrollLeft = footer.scrollLeft;
      header.scrollLeft = footer.scrollLeft;
    },
    scrollingHeader() {
      const time = this.$refs.time;
      const header = this.$refs.header;
      const footer = this.$refs.footer;

      time.scrollLeft = header.scrollLeft;
      footer.scrollLeft = header.scrollLeft;
    },
    getNameInitial(name) {
      return name
        ? name
            .split(' ')
            .splice(0, 3)
            .map(s => s[0])
            .join('')
            .toUpperCase()
        : '';
    },
    setIcon(log, date, time) {
      const entry = log.history.filter(
        arr => arr.date === date && arr.time === time,
      );
      if (entry.length > 0) {
        if (
          entry.findIndex(arr => arr.drug_status === 'given') !== -1 &&
          log.nonracik?.isHighAlert
        )
          return { icon: 'mdi-check-circle', color: '#219653', class: 'high' };
        if (entry.findIndex(arr => arr.drug_status === 'given') !== -1)
          return { icon: 'mdi-check-circle', color: '#219653', class: '' };
        if (entry.findIndex(arr => arr.drug_status === 'discontinued') !== -1)
          return { icon: 'mdi-close-circle', color: '#EB4747', class: '' };
        if (
          entry.findIndex(
            arr =>
              arr.drug_status === 'pending' || arr.drug_status === 'postponed',
          ) !== -1
        )
          return { icon: 'mdi-clock-time-four-outline', color: '' };
        return { icon: '', color: '' };
      }
      return false;
    },
    onScrollX(e) {
      const element = document.getElementById('time-target');
      element.scrollTop = e.target.scrollTop;
    },
    onClick(item, index) {
      this.selectedData = item;
      this.isNew = false;
      this.table.items[this.previousIndex] = {
        ...this.table.items[this.previousIndex],
        isActive: false,
      };
      this.table.items[index] = {
        ...this.table.items[index],
        isActive: true,
      };
      this.previousIndex = index;
    },
    deleteItem(drug, index) {
      if (this.previousIndex === index) {
        this.previousIndex = 0;
      }
      axios
        .put(
          Constant.apiUrl.concat(
            `/inpatient/drop/${this.idDrugAdministration}/${drug._id}`,
          ),
        )
        .then(response => {
          if (response) {
            this.refreshTable();
          }
        });
    },
    confirmOff(arr) {
      if (!arr.isContinued) {
        Swal.fire({
          title: 'Hentikan Obat?',
          text: `Anda akan menghentikan pemberian obat ${arr.nama} Pada Pasien`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#137BC0',
          cancelButtonColor: '#EB4747',
          confirmButtonText: 'Lanjut',
          cancelButtonText: 'Batal',
        }).then(willSwitch => {
          if (willSwitch.value) {
            const payload = {
              drugs: [
                {
                  history: [],
                  is_continued: arr.isContinued,
                  is_extra_drugs: arr.isExtra,
                  _id: arr._id,

                  nonracik:
                    arr.nonracik?.type === 'nonracik'
                      ? {
                          ...arr.nonracik,
                          name: arr.nonracik.drugName,
                          description: arr.nonracik.note,
                          general_rule: arr.nonracik.generalRules,
                          requested_quantity: arr.nonracik.requestedQuantity,
                          usage: arr.nonracik.usageRule,
                        }
                      : { time_delivery: [] },
                  racik:
                    arr.racik?.type === 'racik'
                      ? {
                          ...arr.racik,
                          name: arr.racik.drugName,
                          description: arr.racik.note,
                          general_rule: arr.racik.generalRules,
                          usage: arr.racik.usageRule,
                        }
                      : {
                          drugs: [],
                          time_delivery: [],
                        },
                  timestamps: {
                    created_by: this.$store.getters.userLoggedIn.id,
                  },
                },
              ],
            };
            axios
              .put(
                Constant.apiUrl.concat(
                  `/inpatient/drugadministration/${this.idDrugAdministration}`,
                ),
                payload,
              )
              .then(response => {
                if (response) {
                  this.refreshTable();
                }
              });
          } else {
            // eslint-disable-next-line no-param-reassign
            arr.isContinued = !arr.isContinued;
          }
        });
      } else {
        const payload = {
          drugs: [
            {
              history: [],
              is_continued: arr.isContinued,
              is_extra_drugs: arr.isExtra,

              nonracik: arr.nonracik || { time_delivery: [] },
              racik: arr.racik || { drugs: [], time_delivery: [] },
              timestamps: {
                created_by: this.$store.getters.userLoggedIn.id,
              },
              _id: arr._id,
            },
          ],
        };
        axios
          .put(
            Constant.apiUrl.concat(
              `/inpatient/drugadministration/${this.idDrugAdministration}`,
            ),
            payload,
          )
          .then(response => {
            if (response) {
              this.refreshTable();
            }
          });
      }
    },
    openDetailRecipe() {
      this.selectedForm = 5;
    },
    back() {
      this.selectedForm = 1;
    },
    selectItem(val, item) {
      if (item !== null) {
        this.drugItem = item;
      }
      this.selectedForm = val;
      this.dialog = true;
    },
    closeDialog() {
      this.refreshTable();
      this.dialog = false;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    refreshTable() {
      switch (this.poly) {
        case 'igd':
          this.showAllDrugsIgd();
          break;
        case 'rawat_inap':
          this.showAllDrugsInpatient();
          break;
      }
    },
    async showAllDrugsIgd() {
      this.loading = true;
      try {
        const response = await axios.get(
          Constant.apiUrl.concat(
            `/patient/igd/cpo/${this.patientData.id_registration}`,
          ),
        );
        const [data] = response.data.data;
        const index = data.drug_administration.length - 1;
        const dataItem = data.drug_administration[index].drugs;
        const dataAdmin = data.drug_administration[index];
        this.idDrugAdministration = data.id_drugAdministration;
        this.idStaffMixRecipe = data.mix_staff_name[index]?._id || '';
        this.idStaffRecipe = data.recipe_staff_name[index]?._id || '';
        this.patientStatus = data.patient_status;
        this.idEmr = data.id_emr;
        this.headerDate =
          dataAdmin.distinct_date?.map(arr => ({
            ...arr,
            time: arr.time.filter((t, i) => {
              return arr.time.findIndex(o => o.time === t.time) === i;
            }),
          })) || [];
        this.table.items = dataItem
          .sort((a, b) => b.is_continued - a.is_continued)
          .map((drug, index) => {
            const drugDisplayName = drug.nonracik.name
              ? drug.nonracik.name
              : drug.racik.name;

            return {
              no: index + 1,
              isContinued: drug.is_continued,
              isExtra: drug.is_extra_drugs,
              isPotentNonRacik: drug.nonracik.isPotent || false,
              isPotentRacik: drug.racik.drugs[0]?.isPotent || false,
              patient_status: data.patient_status,
              _id: drug._id,
              nama: drugDisplayName,
              time_delivery: drug.time_delivery
                ? drug.time_delivery.map(arr => ({
                    ...arr,
                    text: `${arr.date} ${arr.time}`,
                  }))
                : [],
              racik:
                drug.racik.drugs.length > 0
                  ? {
                      ...drug.racik,
                      drugName: drug.racik.name,
                      quantity: drug.racik.quantity,
                      drugType: 'Obat Racik',
                      dosage: +drug.racik.dosage,
                      dose: drug.racik.usage,
                      price: drug.racik.price,
                      note: drug.racik.description,
                      date: moment(drug.racik.date)
                        .locale('id')
                        .format('DD MMMM YYYY hh:mm [WIB]'),
                      drugs: drug.racik.drugs.map(i => {
                        return {
                          ...i,
                          dosage: +i.dosage,
                        };
                      }),
                      route: drug.racik.route,
                      packaging: drug.racik.packaging,
                      isConfirmed: drug.racik.isApproved,
                      generalRules: drug.racik.general_rule,
                      usageRule: drug.racik.usage,
                      staff: drug.timestamps.created_by,
                      time_delivery: drug.racik.time_delivery,
                      type: drug.racik.drugs.length > 0 ? 'racik' : '',
                      given: data.patient_status
                        .toLowerCase()
                        .split(' ')
                        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
                        .join(' '),
                    }
                  : {},
              nonracik: drug.nonracik.id_drugs
                ? {
                    ...drug.nonracik,
                    note: drug.nonracik.description,
                    drugType: 'Obat Non Racik',
                    dosage: +drug.nonracik.dosage,
                    generalRules: drug.nonracik.general_rule,
                    id_drugs: drug.nonracik.id_drugs,
                    drugName: drug.nonracik.name,
                    packaging: drug.nonracik.packaging,
                    preparation: drug.nonracik.preparation,
                    quantity: drug.nonracik.quantity,
                    requestedQuantity: drug.nonracik.requested_quantity,
                    route: drug.nonracik.route || '',
                    dose: `${drug.nonracik.dosage} ${drug.nonracik.unit_dosage}`,
                    usageRule: drug.nonracik.usage,
                    id: drug._id,
                    isHighAlert: drug.nonracik.isHighAlert,
                    staff: drug.timestamps.created_by,
                    date: moment(drug.nonracik.date)
                      .locale('id')
                      .format('DD MMMM YYYY hh:mm [WIB]'),
                    isConfirmed: drug.nonracik.isApproved,
                    unit_dosage: drug.nonracik.unit_dosage,
                    type: drug.nonracik.id_drugs ? 'nonracik' : '',
                    time_delivery: drug.nonracik.time_delivery,
                    given: data.patient_status
                      .toLowerCase()
                      .split(' ')
                      .map(s => s.charAt(0).toUpperCase() + s.substring(1))
                      .join(' '),
                  }
                : {},
              history: drug.history.map(unit => {
                return {
                  ...unit,
                };
              }),
              created_at: drug.timestamps.created_at,
            };
          });

        this.allergy = data.social[0].allergy;
      } catch (error) {
        this.showErrorAxios(error);
        if (error) {
          this.table.items = [];
        }
      } finally {
        this.loading = false;
      }
    },
    showAllDrugsInpatient() {
      this.loading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/inpatient/${this.patientData.id_registration}`,
          ),
        )
        .then(response => {
          const [data] = response.data.data;
          const index = data.drug_administration.length - 1;
          const dataItem = data.drug_administration[index].drugs;
          const dataAdmin = data.drug_administration[index];
          this.idRegistration = data.id_registration;
          this.idDrugAdministration = data.id_drugAdministration;
          this.idStaffMixRecipe = data.mix_staff_name[index]?._id || '';
          this.idStaffRecipe = data.recipe_staff_name[index]?._id || '';
          this.patientStatus = data.patient_status;
          this.idEmr = data.id_emr;

          this.headerDate =
            dataAdmin.distinct_date?.map(arr => ({
              ...arr,
              time: arr.time.filter((t, i) => {
                return arr.time.findIndex(o => o.time === t.time) === i;
              }),
            })) || [];

          this.table.items = dataItem
            .sort((a, b) => b.is_continued - a.is_continued)
            .map((drug, index) => {
              const drugDisplayName = drug.nonracik.name
                ? drug.nonracik.name
                : drug.racik.name;

              return {
                no: index + 1,
                isContinued: drug.is_continued,
                isExtra: drug.is_extra_drugs,
                isPotentNonRacik: drug.nonracik.isPotent || false,
                isPotentRacik: drug.racik.drugs[0]?.isPotent || false,
                patient_status: data.patient_status,
                _id: drug._id,
                nama: drugDisplayName,
                time_delivery: drug.time_delivery
                  ? drug.time_delivery.map(arr => ({
                      ...arr,
                      text: `${arr.date} ${arr.time}`,
                    }))
                  : [],
                racik:
                  drug.racik.drugs.length > 0
                    ? {
                        ...drug.racik,
                        drugName: drug.racik.name,
                        quantity: drug.racik.quantity,
                        drugType: 'Obat Racik',
                        dosage: +drug.racik.dosage,
                        dose: drug.racik.usage,
                        price: drug.racik.price,
                        note: drug.racik.description,
                        date: moment(drug.racik.date)
                          .locale('id')
                          .format('DD MMMM YYYY hh:mm [WIB]'),
                        drugs: drug.racik.drugs.map(i => {
                          return {
                            ...i,
                            dosage: +i.dosage,
                          };
                        }),
                        route: drug.racik.route,
                        packaging: drug.racik.packaging,
                        isConfirmed: drug.racik.isApproved,
                        generalRules: drug.racik.general_rule,
                        usageRule: drug.racik.usage,
                        staff: drug.timestamps.created_by,
                        time_delivery: drug.racik.time_delivery,
                        type: drug.racik.drugs.length > 0 ? 'racik' : '',
                        given: data.patient_status
                          .toLowerCase()
                          .split(' ')
                          .map(s => s.charAt(0).toUpperCase() + s.substring(1))
                          .join(' '),
                      }
                    : {},
                nonracik: drug.nonracik.id_drugs
                  ? {
                      ...drug.nonracik,
                      note: drug.nonracik.description,
                      drugType: 'Obat Non Racik',
                      dosage: +drug.nonracik.dosage,
                      generalRules: drug.nonracik.general_rule,
                      id_drugs: drug.nonracik.id_drugs,
                      drugName: drug.nonracik.name,
                      packaging: drug.nonracik.packaging,
                      preparation: drug.nonracik.preparation,
                      quantity: drug.nonracik.quantity,
                      requestedQuantity: drug.nonracik.requested_quantity,
                      route: drug.nonracik.route || '',
                      dose: `${drug.nonracik.dosage} ${drug.nonracik.unit_dosage}`,
                      usageRule: drug.nonracik.usage,
                      id: drug._id,
                      isHighAlert: drug.nonracik.isHighAlert,
                      staff: drug.timestamps.created_by,
                      date: moment(drug.nonracik.date)
                        .locale('id')
                        .format('DD MMMM YYYY hh:mm [WIB]'),
                      isConfirmed: drug.nonracik.isApproved,
                      unit_dosage: drug.nonracik.unit_dosage,
                      type: drug.nonracik.id_drugs ? 'nonracik' : '',
                      time_delivery: drug.nonracik.time_delivery,
                      given: data.patient_status
                        .toLowerCase()
                        .split(' ')
                        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
                        .join(' '),
                    }
                  : {},
                history: drug.history.map(unit => {
                  return {
                    ...unit,
                  };
                }),
                created_at: drug.timestamps.created_at,
              };
            });
          this.allergy = data.social[0]?.allergy || [];
          this.loading = false;
        })
        .catch(error => {
          this.showErrorAxios(error);
          if (error) {
            this.table.items = [];
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.table-time-container {
  overflow-x: auto !important;
}
.border-right {
  border-right: 1px solid #9ca2a5;
}
.expand-content {
  display: grid;
  grid-template-columns: 20% 35% 20% 25%;
  .action-button {
    width: 120px;
    height: 28px;
    background-color: white !important;
  }
}
.grid {
  display: grid;
  width: 100%;
  &.header {
    align-content: center;
    position: sticky;
    z-index: 10;
    top: -13px;
    color: black;
    height: 70px;
    background-color: #f4f5f5;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 1.2px solid #9ca2a5;
    grid-template-columns: 5% 15% 31% 15% 15% 19%;
  }
  &.disable {
    color: #a3a5a5;
  }
  &.data {
    grid-template-columns: 5% 15% 31% 15% 13% 21%;
  }
}
.table-footer {
  z-index: 10;
  position: sticky;
  bottom: -11px;
  border-bottom: none !important;
  border-right: none !important;
  border-top: 1px #9ca2a5 solid !important;
  border-left: none !important;
}
.description {
  padding: 5px 10px;
  background-color: #f0f9ff;
  border: 0.5px solid #bdbdbd;
  border-radius: 3px;
}
.header-grid {
  padding-left: 12px;
  display: grid;
  grid-template-columns: repeat(2, 1fr 2fr);
  row-gap: 5px;
}
.allergy {
  background-color: #fff2f2 !important;
  font-weight: 400;
  font-size: 13px;
  justify-content: center;
  border: 0.5px solid #eb4747 !important;
  color: #eb4747;
}
.headline {
  color: #3498db;
}

.border {
  border-left: 5px solid #3498db !important;
  border-radius: none;
}

.item-expand {
  color: #a6adb4;
}

.table-layout {
  height: 70vh;
  margin-bottom: 20px;
  margin-left: 20px;
  overflow-y: auto;
  overflow-x: hidden;
}

.header-container {
  background: #f4f5f5;
  position: sticky;
  top: -12.6px;
  height: 71px;
  z-index: 100;

  .header-default {
    width: 100%;
    background: #f4f5f5;
    position: fixed;
    top: 149px;
    z-index: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 70px;
    border-bottom: 1.2px solid #9ca2a5;
  }

  .header-default span {
    margin-left: 20%;
    font-size: 14px;
    font-weight: 500;
  }
}

.footer-container {
  background: #f4f5f5;
  position: sticky;
  bottom: -10px;
  z-index: 100;
}

.staff-col {
  border-bottom: 1.2px solid #9ca2a5 !important;
  border-top: 1.2px solid #9ca2a5 !important;
  background-color: #f4f5f5;
}

.table-layout::-webkit-scrollbar {
  background: #c2c2c2;
  height: 8px;
}
.table-layout::-webkit-scrollbar-thumb {
  background-color: darkgray;
}

.drug-input-layout {
  display: grid;
  grid-template-columns: 20vh 40vh;
  align-items: baseline;
  grid-row-gap: 2vh;
}

::v-deep {
  thead tr th span {
    color: #1a1a1a;
  }

  tbody tr td {
    color: #272727;
    font-weight: bold;
  }

  .v-data-table
    > .v-data-table__wrapper
    tbody
    tr.v-data-table__expanded__content {
    box-shadow: none;
    background-color: #f4f5f5;
  }

  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border: none;
  }
}

.table-grid {
  border: 1px solid #9ca2a5;
  border-top: none;
  border-collapse: collapse;
  position: relative;
  height: 100% !important;

  & ::v-deep td {
    border-bottom: none !important;
    border-top: none !important;
    border-left: none !important;
  }

  td {
    border-right: 1px solid #9ca2a5;
    height: 56px;
  }
}

.width-grid-1 {
  min-width: 70px;
}

.width-grid-2 {
  min-width: 100px;
}

@media screen and (max-width: 1703px) {
  .table-grid {
    td {
      height: 56px;
    }
  }
}

@media screen and (min-width: 1703px) {
  .table-grid {
    td {
      height: 56px;
    }
  }
}

@media screen and (min-width: 1904px) {
  .table-grid {
    td {
      height: 60px;
    }
  }
}

.scrollable {
  overflow-x: auto;
  position: relative;
  &::-webkit-scrollbar {
    background: #c2c2c2;
    height: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgray;
  }

  &.visible {
    &::-webkit-scrollbar {
      background: #c2c2c2;
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(140, 138, 138);
    }
  }
}

.table-header {
  border-collapse: collapse;
  position: relative;

  &::v-deep thead tr th {
    border-bottom: 1px solid #9ca2a5 !important;
    border-top: none !important;

    border-right: 1px solid #9ca2a5 !important;
    border-left: 1px solid #9ca2a5 !important;
  }

  tr {
    border-top: none !important;
  }

  thead {
    height: 70px;
    background: #f4f5f5;
    position: sticky;
    top: -11px;
    z-index: 100;
    font-size: 14px;

    th {
      min-width: 70px;
    }
  }
}

.footer-table {
  width: auto;
  border-collapse: collapse;
  td {
    border-left: 1px solid #9ca2a5;
    border-right: 1px solid #9ca2a5;
    border-bottom: none !important;
    height: 64px;
  }
}

.chip-center {
  margin: auto;
}

.highalert {
  position: relative;
  .high {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: red;
    position: absolute;
    top: 5%;
    right: 28.5%;
  }
  .alert {
    position: absolute;
    top: 5%;
    right: 21.5%;
  }
  .alert-inner {
    position: absolute;
    top: 10%;
    right: 25%;
  }
}
</style>
