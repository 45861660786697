import { render, staticRenderFns } from "./ResultExaminationTab.vue?vue&type=template&id=5cfd70d8&scoped=true&"
import script from "./ResultExaminationTab.vue?vue&type=script&lang=js&"
export * from "./ResultExaminationTab.vue?vue&type=script&lang=js&"
import style0 from "./ResultExaminationTab.vue?vue&type=style&index=0&id=5cfd70d8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cfd70d8",
  null
  
)

export default component.exports