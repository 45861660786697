<template>
  <v-card class="pa-3">
    <v-row class="px-15 mx-10 mt-0 mb-16 scrollbar">
      <v-col>
        <v-row>
          <v-col cols="4" class="text-left">
            <label class="font-weight-bold">Nomor Surat</label>
          </v-col>
          <v-col cols="7" class="text-left">
            <label class="">{{ numberLetter }}</label>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="text-left">
            <label class="font-weight-bold">Nama Pasien</label>
          </v-col>
          <v-col cols="7" class="text-left">
            <label class="">{{ patientData.meta.name }}</label>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="text-left">
            <label class="font-weight-bold">No RM</label>
          </v-col>
          <v-col cols="7" class="text-left">
            <label class="">{{ patientData.rmNumber }}</label>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="text-left">
            <label class="font-weight-bold">Jenis Kelamin</label>
          </v-col>
          <v-col cols="7" class="text-left">
            <label class="">{{ patientData.meta.gender }}</label>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="text-left">
            <label class="font-weight-bold">Tanggal Lahir</label>
          </v-col>
          <v-col cols="7" class="text-left">
            <label class="">{{ patientData.meta.birthDate }}</label>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="text-left">
            <label class="font-weight-bold">Alamat</label>
          </v-col>
          <v-col cols="7" class="text-left">
            <label class="">{{ patientData.meta.address }}</label>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="text-left">
            <label class="font-weight-bold">Pekerjaan</label>
          </v-col>
          <v-col cols="7" class="text-left">
            <label class="">{{ patientData.meta.profession }}</label>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="4" class="text-left">
            <label class="font-weight-bold">Dokter Pemeriksa</label>
          </v-col>
          <v-col cols="7" class="">
            <v-combobox
              :rules="[v => !!v || 'Wajib diisi']"
              dense
              class="pt-0 mt-0"
              placeholder="Pilih Dokter"
              clearable
              auto-select-first
              :items="resource.doctor"
              item-text="doctorName"
              return-object
              v-model="input.doctor"
            ></v-combobox>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6" class="pt-2">
        <v-form ref="form">
          <v-row align="center">
            <v-col cols="5" class="text-left">
              <label class="font-weight-bold">Keadaan Pasien</label>
            </v-col>
            <v-col cols="6" class="text-left">
              <v-select
                :rules="[v => !!v || 'Wajib diisi']"
                dense
                placeholder="Pilih Keadaan"
                :items="resource.patientCondition"
                v-model="input.patient_condition"
              ></v-select>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="5" class="text-left">
              <label class="font-weight-bold">Tujuan Pembuatan Surat</label>
            </v-col>
            <v-col cols="6" class="text-left">
              <v-text-field
                dense
                placeholder="Masukkan Tujuan"
                v-model="input.purpose_letter"
                :rules="[v => !!v || 'Wajib diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="5" class="text-left">
              <label class="font-weight-bold">Tinggi badan</label>
            </v-col>
            <v-col cols="6" class="text-left">
              <v-text-field
                suffix="cm"
                dense
                v-model="input.height"
                :rules="[v => !!v || 'Wajib diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="5" class="text-left">
              <label class="font-weight-bold">Berat badan</label>
            </v-col>
            <v-col cols="6" class="text-left">
              <v-text-field
                suffix="kg"
                dense
                v-model="input.weight"
                :rules="[v => !!v || 'Wajib diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="5" class="text-left">
              <label class="font-weight-bold">Tensi Meter</label>
            </v-col>
            <v-col cols="3">
              <v-text-field
                dense
                v-model="input.sistole"
                :rules="[v => !!v || 'Wajib diisi']"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="px-0" style="max-width: 10px">/</v-col>
            <v-col cols="3">
              <v-text-field
                dense
                suffix="mmHg"
                v-model="input.diastole"
                :rules="[v => !!v || 'Wajib diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="5" class="text-left">
              <label class="font-weight-bold">Nadi</label>
            </v-col>
            <v-col cols="6" class="text-left">
              <v-text-field
                dense
                suffix="x/menit"
                v-model="input.pulse"
                :rules="[v => !!v || 'Wajib diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="5" class="text-left">
              <label class="font-weight-bold">Respirasi</label>
            </v-col>
            <v-col cols="6" class="text-left">
              <v-text-field
                hide-details="false"
                dense
                suffix="x/menit"
                v-model="input.respiration"
                :rules="[v => !!v || 'Wajib diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="start">
            <v-col cols="5" class="text-left">
              <label class="font-weight-bold">Catatan</label>
            </v-col>
            <v-col cols="6" class="text-left">
              <v-textarea
                hide-details="false"
                dense
                outlined
                placholder="Masukkan Catatan"
                v-model="input.notes"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-card-actions class="pa-0">
      <v-btn class="text-none" plain @click="$emit('close-form')">
        Kembali
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        tile
        outlined
        color="primary"
        class="text-none"
        width="116"
        :disabled="disabled"
        @click="printHealthLetter()"
      >
        <font-awesome-icon icon="print" />
        Print
      </v-btn>
      <v-btn
        color="primary"
        tile
        elevation="0"
        class="text-none"
        width="108"
        @click="addHealthLetter()"
        >Simpan</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import Constant from '@/const';
import Swal from 'sweetalert2';
import AlertMixin from '@/mixin/alertMixin';
import printMixin from '@/mixin/printMixin';
import 'jspdf-autotable';
import Letter from '@/mixin/Letter';
import jwtMixin from '@/mixin/jwtMixin';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'HealthyLetter',
  mixins: [AlertMixin, printMixin, Letter, jwtMixin],
  props: {
    patientData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      clientData: this.$store.getters.userLoggedIn.clinic,
      doctorSip: '',
      resource: {
        patientCondition: ['Sakit', 'Sehat'],
        doctor: [],
      },
      input: {
        patient_condition: '',
        purpose_letter: '',
        height: null,
        weight: null,
        doctor: '',
        sistole: null,
        diastole: null,
        pulse: null,
        respiration: null,
        notes: null,
      },
      timestamps: new Date(),
      numberLetter: '',
      disabled: false,
    };
  },
  computed: {
    letterData() {
      return Constant.client.find(
        item =>
          item.name.toLowerCase() ===
          this.$store.getters.userLoggedIn.clinic.name.toLowerCase(),
      );
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.runApiCalls(() => {
        this.getHealthyLetter();
        this.getDoctor();
      });
    });
  },
  methods: {
    getDoctor() {
      axios
        .get(Constant.apiUrl.concat('/master/staff'))
        .then(response => {
          if (response.status === 200) {
            var data = response.data.data;
            this.resource.doctor = data
              .filter(
                v => v.role.role.toLowerCase() === 'dokter' && v.role.status,
              )
              .map(doctor => {
                return {
                  doctorId: doctor._id.toString(),
                  doctorName: doctor.detail.name,
                };
              });

            // this.getOutpatientLetter();
          }
        })
        .catch(() => {
          this.resource.doctor = [];
        });
    },
    addHealthLetter() {
      if (this.$refs.form.validate()) {
        axios
          .post(Constant.apiUrl.concat('/letter/healthy-patient-letter'), {
            id_registration: this.patientData.id_registration,
            letter_number: this.numberLetter,
            detail: {
              ...this.input,
              doctor: this.input.doctor.doctorId,
              patient_condition: this.input.patient_condition.toLowerCase(),
              timestamps: {
                created_by: this.$store.getters.userLoggedIn.id,
              },
            },
          })
          .then(() => {
            Swal.fire('Surat Keterangan Sehat Berhasil Dibuat', '', 'success');
            this.getHealthyLetter();

            // this.$emit('close-form');
          })
          .catch(err => {
            this.showErrorAxios(err);
          });
      }
    },
    printHealthLetter() {
      const doc = this.generateHealthLetter(this.patientData, this.input);
      this.printDocument(doc, {
        docName: 'Surat Keterangan Dokter',
        rmNumber: this.patientData.rmNumber,
      });
    },
    generateNumber() {
      axios
        .get(Constant.apiUrl.concat('/letter/healthy-patient-letter/generate'))
        .then(response => {
          this.numberLetter = response.data.data;
        });
    },
    getHealthyLetter() {
      axios
        .get(
          Constant.apiUrl.concat(
            `/letter/healthy-patient-letter/registration/${this.patientData.id_registration}`,
          ),
        )
        .then(response => {
          this.disabled = false;
          const healthy = response.data.data;
          (this.numberLetter = healthy.letter_number),
            (this.input = {
              patient_condition: _.startCase(healthy.detail.patient_condition),
              purpose_letter: _.get(healthy, 'detail.purpose_letter', ''),
              height: _.get(healthy, 'detail.height', ''),
              weight: _.get(healthy, 'detail.weight', ''),
              doctor: {
                doctorId: healthy.detail.doctor._id,
                doctorName: healthy.detail.doctor.detail[0].name,
                // doctorName: healthy.detail
              },
              sistole: _.get(healthy, 'detail.sistole', ''),
              diastole: _.get(healthy, 'detail.diastole', ''),
              pulse: _.get(healthy, 'detail.pulse', ''),
              respiration: _.get(healthy, 'detail.respiration', ''),
              notes: _.get(healthy, 'detail.notes', ''),
            });
          // this.input.doctor = healthy.detail.doctor.id
          //   ? {
          //       doctorId: healthy.detail.doctor.id,
          //       doctorName: healthy.detail.doctor.name,
          //       // doctorName: healthy.detail
          //     }
          //   : {
          //       doctorId: this.patientData.meta.doctorId,
          //       doctorName: this.patientData.meta.doctor,
          //       // doctorName: outpatient.detail
          //     };
          this.getStaffById(this.input.doctor.doctorId);
          // this.getDataAssessment();
        })
        .catch(err => {
          if (err.response.status == 404) {
            this.disabled = true;
            this.input.doctor = {
              doctorId: this.patientData.meta.doctorId,
              doctorName: this.patientData.meta.doctor,
            };
            this.generateNumber();
            this.getDataAssessment();
          } else this.showErrorAxios(err);
        });
    },
    async getDataAssessment() {
      try {
        const response = await axios.get(
          Constant.apiUrl.concat(
            `/patient/checkup/regist/${this.patientData.id_registration}/service`,
          ),
        );
        const { data } = response.data;
        this.input = {
          ...this.input,
          diastole:
            data.objective.diastol === 0 ? null : data.objective.diastol,
          height: data.objective.height === 0 ? null : data.objective.height,
          weight: data.objective.weight === 0 ? null : data.objective.weight,
          sistole: data.objective.sistol === 0 ? null : data.objective.sistol,
          pulse:
            data.objective.heart_rate === 0 ? null : data.objective.heart_rate,
          respiration:
            data.objective.respiration_rate === 0
              ? null
              : data.objective.respiration_rate,
        };
      } catch {
        this.input = {
          ...this.input,
        };
      }
    },
    async getStaffById(id) {
      const res = await axios.get(
        Constant.apiUrl.concat(`/master/staff/find/${id}`),
      );
      const { data } = res.data;
      this.doctorSip = data.detail.sip;
    },
  },
};
</script>

<style lang="scss" scoped>
.scrollbar {
  height: 400px;
  overflow-y: auto;

  /* Custom scrollbar */
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #9e9e9e;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: #c2c2c2;
  }
}
</style>
