import { render, staticRenderFns } from "./ListInformedConsent.vue?vue&type=template&id=792c954d&scoped=true&"
import script from "./ListInformedConsent.vue?vue&type=script&lang=js&"
export * from "./ListInformedConsent.vue?vue&type=script&lang=js&"
import style0 from "./ListInformedConsent.vue?vue&type=style&index=0&id=792c954d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "792c954d",
  null
  
)

export default component.exports