<template>
  <div class="main">
    <v-layout xs12 align-center py-3 px-8>
      <v-flex xs8>
        <h2 class="title">Pendaftaran Pasien Rawat Jalan</h2>
        <h5 class="subtitle caption">
          {{ registPatient }} Pasien terdaftar hari ini
        </h5>
      </v-flex>
      <v-flex xs1>
        <v-btn @click="refreshAndAutoUpdate" icon mt-3>
          <font-awesome-icon icon="sync-alt" class="btn-refresh" />
        </v-btn>
      </v-flex>
      <v-flex xs3>
        <v-text-field
          class="input"
          background-color="grey lighten-3"
          placeholder="Search"
          solo
          dense
          hide-details
          flat
          prepend-inner-icon="mdi-magnify"
          single-line
          v-model="search"
        ></v-text-field>
      </v-flex>
    </v-layout>
    <v-row class="px-8">
      <v-col class="text-start">
        <label for="date" class="subtitle-2 mb-4">Tanggal</label>
        <v-menu
          dense
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="380px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              placeholder="Pilih Tanggal"
              append-icon="mdi-chevron-down"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              dense
              v-model="date"
              clearable
              hide-details="false"
            ></v-text-field>
          </template>
          <v-date-picker
            width="380px"
            v-model="date"
            no-title
            scrollable
            @input="
              $refs.menu.save(date);
              outpatient.pagination.page = 1;
              showAllPatients();
            "
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="text-start"
        ><label for="doctor" class="subtitle-2">Dokter</label
        ><v-combobox
          dense
          outlined
          :items="resource.doctor"
          v-model="filter.doctor"
          :loading="isDoctorLoading"
          @change="
            outpatient.pagination.page = 1;
            showAllPatients();
          "
          item-value="id"
          item-text="doctor"
          append-icon="mdi-chevron-down"
          clearable
          placeholder="Semua Dokter"
        >
        </v-combobox
      ></v-col>
      <v-col class="text-start"
        ><label for="poly" class="subtitle-2">Poli</label>
        <v-combobox
          dense
          outlined
          :items="resource.poly"
          :loading="isPolyLoading"
          v-model="filter.poly"
          @change="
            outpatient.pagination.page = 1;
            showAllPatients();
          "
          item-text="unitName"
          return-object
          append-icon="mdi-chevron-down"
          clearable
          placeholder="Semua Poli"
        >
        </v-combobox
      ></v-col>
      <v-col class="text-start"
        ><label for="assurance" class="subtitle-2">Tipe Penjamin</label>
        <v-combobox
          dense
          outlined
          :items="resource.assurance"
          v-model="filter.assurance"
          @change="
            outpatient.pagination.page = 1;
            showAllPatients();
          "
          item-text="text"
          return-object
          append-icon="mdi-chevron-down"
          clearable
          placeholder="Pilih Tipe Penjamin"
        >
        </v-combobox
      ></v-col>
    </v-row>
    <v-layout class="px-8">
      <v-data-table
        :headers="outpatient.headers"
        :items="outpatient.data"
        :items-per-page="itemsPerRow"
        :expanded="expanded"
        hide-default-footer
        item-key="idRegistration"
        single-expand
        @click:row="
          (item, slot) => {
            slot.expand(!slot.isExpanded);
            selectItem(item);
            selectedForm = null;
          }
        "
        :loading="loading"
        class="data-table"
      >
        <template v-slot:[`item.no`]="{ item, index }">
          <tr class="text-left" :key="index">
            <td>
              {{ item.no + 1 }}
            </td>
          </tr>
        </template>
        <template v-slot:[`item.guarantorType`]="{ item, index }">
          <tr class="text-left" :key="index">
            <td>
              {{ item.guarantorType.toUpperCase() }}
            </td>
          </tr>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <span>{{ item.name }}</span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-bind="attrs"
                v-on="on"
                v-if="item.pcare && !item.pcare.registration.isSuccess"
                color="red"
                light
                small
                class="send-btn"
                height="500"
                @click="reRegistrationPcare(item)"
                ><v-icon left class="icon" color="white" x-small
                  >mdi-send-outline</v-icon
                >
              </v-chip>
            </template>
            <span>{{ item?.pcare?.registration?.message }}</span>
          </v-tooltip>
          <v-icon
            v-if="item.pcare && item.pcare.registration.isSuccess"
            color="green"
            >mdi-check-circle</v-icon
          >
        </template>
        <template v-slot:[`item.isNew`]="{ item }">
          <v-chip
            label
            class="isnew-chip"
            :class="{ new: item.isNew }"
            :color="item.isNew ? '#fff9e5' : '#f0f9ff'"
            >{{ item.isNew ? 'Baru' : 'Lama' }}</v-chip
          >
        </template>
        <template v-slot:[`item.mjkn`]="{ item }">
          <v-icon>{{ item.isMjkn ? 'mdi-check' : 'mdi-minus-thick' }}</v-icon>
        </template>
        <template v-slot:[`item.isCheckIn`]="{ item }">
          <tr class="text-left">
            <td>
              <v-tooltip v-if="!item.isCheckIn" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="px-2 text-capitalize font-weight-regular action-btn"
                    @click.stop="checkInBooking(item)"
                    depressed
                    color="primary"
                    >Belum Check-in
                    <v-icon color="white">mdi-arrow-right</v-icon></v-btn
                  >
                </template>
                <span>Klik untuk check-in</span>
              </v-tooltip>
              <v-tooltip v-else bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="px-2 text-capitalize font-weight-regular action-btn"
                    dark
                    @mouseover="item.hover = true"
                    @mouseleave="item.hover = false"
                    depressed
                    :color="item.hover ? 'red' : 'success'"
                    @click.stop="checkInBooking(item)"
                    >{{ item.hover ? 'Batalkan' : 'Sudah Check-in' }}
                    <v-icon color="white">{{
                      item.hover ? 'mdi-close' : 'mdi-check'
                    }}</v-icon></v-btn
                  >
                </template>
                <span>Batalkan Check-in</span></v-tooltip
              >
            </td>
          </tr>
        </template>
        <template v-slot:no-data>
          <v-alert :value="true" type="info" tile>
            Maaf tidak ada pasien yang terdaftar
          </v-alert>
        </template>
        <template v-slot:expanded-item="{ item, headers }">
          <td
            :colspan="headers.length"
            @click="selectItem(item)"
            class="expand px-0"
          >
            <v-card flat>
              <v-container class="pt-0">
                <v-row no-gutters>
                  <v-col cols="1" style="max-width: 70px"></v-col>
                  <v-col
                    cols="4"
                    class="pl-3 text-left grey--text caption"
                    style="max-width: 340px"
                  >
                    <span
                      >Terdaftar {{ item.timestamps.created_at }} WIB <br />{{
                        item.date
                      }}</span
                    ></v-col
                  >
                  <v-col
                    cols="3"
                    class="text-left ml-3 pl-5 grey--text caption"
                  >
                    <span>{{ item.gender }} {{ item.age }} Tahun</span></v-col
                  >
                  <v-col cols="2" class="text-left pl-9 grey--text caption"
                    >Pasien {{ item.isNew ? 'Baru' : 'Lama' }}</v-col
                  >
                </v-row>
              </v-container>
            </v-card>

            <v-card flat color="grey lighten-4" class="text-right">
              <v-container class="py-1">
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          class="hover mx-2"
                          v-bind="attrs"
                          v-on="on"
                          @click="onClicked(1)"
                        >
                          <v-icon>mdi-account</v-icon>
                        </v-btn>
                      </template>
                      <span>Data Sosial</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template #activator="{ on: tooltip }">
                        <v-btn
                          icon
                          class="hover mx-2"
                          v-on="{ ...tooltip }"
                          @click="onClicked(2)"
                        >
                          <v-icon>mdi-printer</v-icon>
                        </v-btn>
                      </template>
                      <span>Print</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" class="mx-2">
                          <v-icon @click="deletePatient(item)"
                            >mdi-delete</v-icon
                          >
                        </v-btn>
                      </template>

                      <span>Hapus</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </td>
        </template>
      </v-data-table>
    </v-layout>

    <v-footer color="white">
      <v-container fluid>
        <v-row align="center">
          <v-pagination
            v-model="outpatient.pagination.page"
            :length="OutpatientPages"
            v-if="outpatient.data.length > 0"
            :total-visible="5"
            @input="showAllPatients()"
          ></v-pagination>
          <v-spacer></v-spacer>
          <v-btn color="primary" fab dark @click="onClicked(0)" elevation="0">
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
          <v-dialog
            v-model="dialog"
            :max-width="
              selectedForm === null ? 0 : widthDialog[selectedForm].width
            "
            :persistent="selectedForm === 3 || selectedForm === 4"
          >
            <OldNewDialog
              v-if="selectedForm === 0 && dialog"
              @on-clicked="getData"
              @close-form="closeForm"
              @to-regist="onClicked(3)"
            />
            <SearchDataPatient
              v-else-if="selectedForm === 4 && dialog"
              @close-form="closeForm"
              @on-clicked="getData"
              @back="
                selectedForm = 0;
                searchPatient = '';
              "
              @to-regist="
                patientSelected = null;
                onClicked(3);
              "
            />
            <SocialData
              v-else-if="selectedForm === 1 && dialog"
              @close-form="closeForm"
              :medical-record="selectedPatient.rmNumber"
            />
            <PrintDialog
              :hasGeneralConsent="selectedPatient.hasGeneralConsent"
              v-else-if="selectedForm === 2"
              :selected-patient="selectedPatient"
              @close-form="closeForm"
              state="rawat-jalan"
            />
            <SocialDataPatients
              v-else-if="selectedForm === 3"
              :selected-patient="patientSelected"
              :patient-status="'rawat jalan'"
              @close-form="closeForm"
              @update-data="refreshAndAutoUpdate"
              @back-to-form="dialog = true"
              @back-dialog="
                selectedForm = 0;
                searchPatient = '';
              "
            />
            <social-data-mjkn
              v-else-if="selectedForm === 7 && dialog"
              @close-form="closeForm"
              @back="backToMjkn"
              @mjkn-next="mjknNext"
              :rm="selectedPatient.rmNumber"
            />
            <anamnesis-form
              @back="backToMjkn"
              @next="nextToCheckinMJKN"
              :data="ttvInput"
              v-else-if="selectedForm === 5 && dialog"
            />
            <checkin-mjkn
              v-else-if="selectedForm === 6 && dialog"
              @close-form="closeForm"
              @back-to-ttv="backToTtv"
              @back-to-social-data="backToMjkn"
              @open-print-dialog="selectedForm = 2"
            />
          </v-dialog>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import SocialData from '../SharedComponent/SocialData.vue';
import SocialDataPatients from './components/SocialDataPatients.vue';
import PrintDialog from './components/PrintDialog';
import SocialDataMjkn from './components/MJKN/SocialDataMJKN.vue';
import CheckinMjkn from './components/MJKN/CheckinMJKN.vue';
import OldNewDialog from './components/OldNewDialogV2.vue';
import AnamnesisForm from './components/AnamnesisForm.vue';
import SearchDataPatient from './components/SearchDataPatient.vue';
import Constant from '@/const';
import moment from 'moment-timezone';
import jwtMixin from '@/mixin/jwtMixin';
import alertMixin from '@/mixin/alertMixin';
import Swal from 'sweetalert2';
import formatMixin from '../../mixin/formatMixin';

const axios = require('axios');
const _ = require('lodash');
axios.defaults.headers.post['Content-Type'] = 'application/json';

export default {
  name: 'OutpatientRegist',
  mixins: [jwtMixin, alertMixin, formatMixin, SocialDataMjkn],
  components: {
    SocialDataPatients,
    PrintDialog,
    SocialData,
    OldNewDialog,
    SocialDataMjkn,
    AnamnesisForm,
    CheckinMjkn,
    SearchDataPatient,
  },
  data() {
    return {
      dialog: false,
      dialogPrint: false,
      clicked: false,
      expanded: [],
      loading: false,
      isDoctorLoading: false,
      isPolyLoading: false,
      selectedItem: null,
      menu: false,
      OutpatientPages: 0,
      registPatient: 0,
      selectedForm: null,
      date: '',
      filter: {
        doctor: '',
        poly: '',
        assurance: '',
      },
      resource: {
        doctor: [],
        poly: [],
        assurance: [
          { text: 'Umum', value: 'umum' },
          { text: 'BPJS', value: 'bpjs' },
          { text: 'Asuransi', value: 'asuransi' },
        ],
      },
      outpatient: {
        headers: [
          { text: 'No.', value: 'no' },
          { text: 'No. Pendaftaran', value: 'noRegist' },
          { text: 'No. Antrian', value: 'queue' },
          { text: 'Nama', value: 'name' },
          { text: 'No. RM', value: 'noRM' },
          { text: 'Tipe Penjamin', value: 'guarantorType' },
          { text: 'Poli', value: 'poly' },
          { text: 'Jenis Pasien', value: 'isNew' },
          { text: 'MJKN', value: 'mjkn' },
          { text: 'Check-in', value: 'isCheckIn' },
        ],
        data: [],
        pagination: {
          descending: false,
          page: 1,
          rowsPerPage: 7,
          totalItems: null,
        },
      },
      search: '',
      widthDialog: [
        { text: 'OldNewPatient', width: 600 },
        { text: 'SocialDataPatients', width: 1200 },
        { text: 'PrintDialog', width: 485 },
        { text: 'SocialData', width: 1200 },
        { text: 'SocialDataMjkn', width: 1200 },
        { text: 'Anamnesis', width: 1200 },
        { text: 'Checkin', width: 600 },
        { text: 'SearchDataPatient', width: 1200 },
      ],
      ttvInput: {
        anamnesis: '',
        sistol: '',
        diastol: '',
        heart_rate: '',
        weight: '',
        height: '',
        belly_circumference: '',
        respiration_rate: '',
        saturation: '',
        temperature: '',
        awarness: '',
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshAndAutoUpdate();
      this.getDoctor();
      this.getPoly();
    });
  },
  watch: {
    search() {
      this.outpatient.pagination.page = 1;
      this.searchData(this);
    },
    date(val) {
      if (val === '' || !val) {
        this.showAllPatients();
      }
    },
  },
  computed: {
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
    selectedPatient() {
      if (this.$store.getters.selectedPatient !== null) {
        return this.$store.getters.selectedPatient;
      }
      return null;
    },
  },
  methods: {
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.refreshAndAutoUpdate();
    }, 650),
    backToMjkn() {
      this.selectedForm = 7;
    },
    backToTtv() {
      this.selectedForm = 5;
    },
    nextToCheckinMJKN(anamnesisData) {
      this.dialog = false;
      this.$store.commit('addMjknPatient', {
        ...this.$store.getters.mjknPatient,
        ttv: {
          ...anamnesisData,
        },
      });
      this.dialog = true;
      this.selectedForm = 6;
    },
    mjknNext(dataPatient) {
      this.dialog = false;
      Swal.fire({
        title: 'Ingin Menginput Anamnesis & TTV Terlebih Dahulu?',
        text:
          'Jika tidak, Anda bisa menginputnya di form asesmen setelah pendaftaran ini selesai',
        icon: 'warning',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak',
      }).then(result => {
        if (!result.value) {
          this.$store.commit('addMjknPatient', {
            ...dataPatient,
            isInputTTV: false,
          });
          this.dialog = true;
          this.selectedForm = 6;
        } else {
          this.$store.commit('addMjknPatient', {
            ...dataPatient,
            isInputTTV: true,
          });
          this.dialog = true;
          this.selectedForm = 5;
        }
      });
    },
    closeForm() {
      this.dialog = false;
      this.showAllPatients();
    },
    clickedRow(value) {
      if (this.expanded.length && this.expanded[0].number == value.number) {
        this.expanded = [];
      } else {
        this.expanded = [];
        this.expanded.push(value);
      }
    },
    onClicked(val) {
      this.selectedForm = val;
      this.dialog = true;
    },
    getDoctor() {
      this.runApiCalls(() => {
        this.isDoctorLoading = true;
        axios
          .get(Constant.apiUrl.concat(`/master/staff?`))
          .then(response => {
            const data = response.data.data;
            this.resource.doctor = data
              .filter(doctor => doctor.role.status === true)
              .map(doctor => {
                return {
                  id: doctor._id,
                  doctor: doctor.detail.name,
                };
              });
          })
          .catch(error => {
            this.showErrorAxios(error);
          })
          .finally(() => {
            this.isDoctorLoading = false;
          });
      });
    },
    getPoly() {
      this.isPolyLoading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            '/master/unit?installation=Instalasi Rawat Jalan',
          ),
        )
        .then(response => {
          if (response.status === 200) {
            this.resource.poly = response.data.data.map(unit => {
              return {
                unitId: unit._id.toString(),
                unitName: unit.name,
                code: unit.code,
              };
            });
          }
        })
        .catch(error => {
          this.showErrorAxios(error);
        })
        .finally(() => {
          this.isPolyLoading = false;
        });
    },
    checkInBooking(item) {
      const isToday = this.date
        ? moment(this.date).isSame(moment().format('YYYY-MM-DD'))
        : true;
      this.selectItem(item);
      if (item.isCheckIn) {
        Swal.fire({
          title: `Yakin Ingin Membatalkan Check-In Pasien ${item.name}?`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yakin',
          cancelButtonText: 'Batal',
          reverseButtons: true,
        }).then(result => {
          if (result.value) {
            axios
              .post(Constant.apiUrl.concat(`/patient/registration/checkin`), {
                id_emr: item.idPatient,
                id_registration: item.idRegistration,
                isCheckIn: item.isCheckIn ? false : true,
              })
              .then(() => {
                Swal.fire(
                  `Check-in Pasien ${item.name} Berhasil Dibatalkan`,
                  '',
                  'success',
                );
                this.showAllPatients();
              })
              .catch(error => {
                this.showErrorAxios(error);
              });
          }
        });
        return;
      } else {
        if (!isToday) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Tidak Bisa CheckIn Di Hari Yang Berbeda',
          });
          return;
        }
        if (item.isMjkn && isToday) {
          this.onClicked(7);
          return;
        }
        if (!item.isMjkn) {
          axios
            .post(Constant.apiUrl.concat(`/patient/registration/checkin`), {
              id_emr: item.idPatient,
              id_registration: item.idRegistration,
              isCheckIn: item.isCheckIn ? false : true,
            })
            .then(() => {
              Swal.fire(`Check-in Pasien ${item.name} Berhasil`, '', 'success');
              this.showAllPatients();
            })
            .catch(error => {
              this.showErrorAxios(error);
            });
        }
      }
    },
    reRegistrationPcare(item) {
      axios
        .post(
          Constant.apiUrl.concat(
            `/patient/reregistration/pcare/${item.idRegistration}`,
          ),
        )
        .then(() =>
          Swal.fire('Registrasi Berhasil dikirim ulang', '', 'success'),
        )
        .catch(error => {
          this.showErrorAxios(error);
        });
    },
    showAllPatients() {
      this.loading = true;
      const itemCount = this.itemsPerRow;
      const halaman = this.outpatient.pagination.page;
      const date = moment(new Date())
        .locale('id')
        .format('YYYY-MM-DD');

      axios
        .get(
          Constant.apiUrl.concat(
            `/patient/registration/unit?page=${halaman}&itemCount=${itemCount}&search=${this
              .search || ''}&type=rawat jalan&date=${
              this.date === '' || !this.date ? date : this.date
            }&doctor=${this.filter?.doctor?.id || ''}&unit_name=${this.filter
              ?.poly?.unitName || ''}&assurance=${this.filter?.assurance
              ?.text || ''}`,
          ),
        )
        .then(response => {
          const data = response.data.data;
          const { length: dataLength } = response.data;
          this.OutpatientPages = Math.ceil(dataLength / this.itemsPerRow);

          this.outpatient.data = data.map((patient, idx) => {
            return {
              no: idx + itemCount * halaman - itemCount,
              idRegistration: patient._id.toString(),
              noRegist: patient.registration_number.toString(),
              polyId: patient.id_unit,
              poly: patient.unit[0].name,
              id: patient?.doctor[0]?._id,
              doctor: patient?.doctor[0]?.detail[0]?.name,
              isMjkn: patient.isMjkn,
              isCheckIn: patient?.isCheckIn,
              noQueue: patient?.queue?.number
                ? patient.queue.number.toString()
                : '',
              pcare: patient?.pcare,
              hasGeneralConsent: patient.emr?.document?.generalConsentCreated,
              queue:
                patient.queue_code != ''
                  ? `${
                      patient.queue_code
                    }-${patient.queue.number.toString().padStart(3, '0')}`
                  : '-',
              isFinish: patient.queue?.isFinish ? 'Ya' : 'Tidak',
              time: patient.time,
              date: moment(patient.date)
                .locale('id')
                .format('LL'),
              //medical record
              idPatient: patient.id_emr.toString(),
              noRM: patient.emr.medical_record_number,
              name: patient.emr.social[0].name,
              birthDate: moment(patient.emr.social[0].birth.date)
                .locale('id')
                .format('DD/MM/YYYY'),
              birthPlace: patient.emr.social[0].birth.place,
              age: patient.emr.social[0].age,
              gender: patient.emr.social[0].gender ? 'Laki-laki' : 'Perempuan',
              address: this.formatAddress(patient.emr.social[0].address),
              identityType: patient.emr.social[0].identity.type,
              identityNumber: patient.emr.social[0].identity.number,
              profession: patient.emr.social[0].profession,
              //assurance
              guarantorType: _.startCase(patient.assurance.type),
              guarantorName: patient.emr.social[0].assurance.name,
              guarantorNumber: patient.emr.social[0].assurance.number,
              isNew: patient.status.isNew,
              isProlanis: patient.emr.social[0].assurance.isProlanis,
              phoneNumber: patient.emr.social[0].phone_number,
              queue_code: patient.queue_code,
              hovered: false,
              timestamps: {
                created_at: moment(patient.timestamps.created_at)
                  .locale('id')
                  .format('LT'),
              },
            };
          });
          this.registPatient = dataLength;
          this.loading = false;
        })
        .catch(() => {
          this.outpatient.data = [];
          this.loading = false;
        });
    },
    deletePatient(patient) {
      const id = patient.idRegistration;
      Swal.fire({
        title: 'Hapus Pasien',
        text: 'Apakah anda yakin menghapus pasien?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak',
      }).then(result => {
        if (result.value) {
          axios
            .delete(
              Constant.apiUrl.concat(`/patient/registration/delete/${id}`),
            )
            .then(response => {
              if (response.status === 200) {
                Swal.fire('Pasien berhasil dihapus!', '', 'success');
                this.showAllPatients();
              }
            })
            .catch(error => {
              if (error) {
                Swal.fire(`Pasien tidak berhasil dihapus`, '', 'error');
              }
            });
        }
      });
    },
    selectItem(patient) {
      let polyName = [];
      axios
        .get(Constant.apiUrl.concat(`/master/unit/${patient.polyId}`))
        .then(response => {
          polyName.push(response.data.data.name);
        });

      const patientData = {
        isNew: patient.isNew,
        id_emr: patient.idPatient,
        hasGeneralConsent: patient.hasGeneralConsent,
        id_registration: patient.idRegistration,
        rmNumber: patient.noRM,
        noQueue: patient.noQueue,
        queue_code: patient.queue_code,
        queue: patient.queue,
        guarantorType: patient.guarantorType,
        noRegist: patient.noRegist,
        meta: {
          name: patient.name,
          birthDate: patient.birthDate,
          birthPlace: patient.birthPlace,
          gender: patient.gender,
          age: patient.age,
          address: patient.address,
          identityType: patient.identityType,
          identityNumber: patient.identityNumber,
          doctor: patient.doctor,
          polyId: patient.polyId,
          poly: polyName,
          profession: patient.profession,
          phoneNumber: patient.phoneNumber,
        },
      };
      this.$store.commit('changeSelectedPatient', patientData);
      this.selectedItem = patient;
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.showAllPatients('');
      this.interval = setInterval(this.showAllPatients, 60000);
    },
    getData(val) {
      if (val === null) {
        this.patientSelected = val;
        this.onClicked(3);
      } else {
        this.patientSelected = {
          status: 'old',
          rmNumber: val,
        };
        if (val !== null && this.selectedForm === 4) {
          this.onClicked(3);
        } else {
          this.onClicked(4);
        }
      }
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.main {
  font-family: 'Roboto';
  .title {
    text-align: left;
    font-weight: bold !important;
    padding-bottom: 0 !important;
  }
  .subtitle {
    text-align: left;
    color: #adb5bd !important;
    font-weight: 500;
  }
  .data-table {
    width: 100%;

    .send-btn {
      cursor: pointer;
      width: 25px !important;
      padding-bottom: 3px;
      padding-left: 14px;
      border-radius: 7px !important;
      margin-left: 5px;
      .icon {
        rotate: -45deg;
      }
    }
  }

  .btn-refresh {
    cursor: pointer;
  }

  ::v-deep .v-dialog--active.dialogPrint {
    width: 450px;
    box-shadow: none !important;
  }

  #print,
  #printQueue {
    display: none;
  }

  ::v-deep {
    .theme--light.v-data-table
      > .v-data-table__wrapper
      > table
      > tbody
      > tr:not(:last-child)
      > td:not(.v-data-table__mobile-row),
    .theme--light.v-data-table
      > .v-data-table__wrapper
      > table
      > tbody
      > tr:not(:last-child)
      > th:not(.v-data-table__mobile-row) {
      border: none;
    }
  }
}
::v-deep .v-dialog:not(.v-dialog--fullscreen) {
  max-height: 95%;
}

.isnew-chip {
  &.new {
    border: 1px solid #e5a82e !important;
    color: #e5a82e;
  }
  border: 1px solid #2f80ed !important;
  color: #2f80ed;
}

.action-btn {
  width: 10rem;
  display: flex;
  justify-content: space-between;
}
</style>
