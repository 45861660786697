<template>
  <div>
    <v-toolbar v-if="!showBPJSBilling" flat>
      <v-container fluid>
        <v-row align="center">
          <transition name="slide-fade">
            <v-col>
              <v-toolbar-title class="font-weight-bold float-left">
                {{
                  showBPJSBilling ? 'Tagihan Pasien BPJS' : 'Laporan Pendapatan'
                }}
              </v-toolbar-title>
            </v-col>
          </transition>

          <v-spacer></v-spacer>
          <v-btn v-if="tabs === 0" @click="show = !show" text>
            <span class="text-capitalize">Filters</span>
            <v-icon>mdi-filter-variant</v-icon>
          </v-btn>
          <v-btn class="mr-2" color="grey" icon text>
            <router-link to="/keuangan/laporan-keuangan">
              <v-icon>mdi-home</v-icon>
            </router-link>
          </v-btn>
          <v-btn
            @click="exportIncomeReport()"
            class="text-none"
            color="primary"
            tile
            depressed
            >Export</v-btn
          >
        </v-row>
      </v-container>
    </v-toolbar>
    <template v-if="isLoading">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
      <h4>Loading...</h4>
    </template>

    <v-main v-else>
      <v-container class="pt-0">
        <v-row>
          <v-spacer></v-spacer>
          <div class="filter-menu mt-4" v-show="show && tabs === 0">
            <v-row no-gutters align="center" justify="center">
              <v-combobox
                v-model="selectShift"
                clearable
                append-icon="mdi-chevron-down"
                :items="resource"
                placeholder="Pilih Shift"
                class="input mr-4"
                dense
                label="Shift"
                outline
                item-text="text"
                @change="getIncomeData()"
              />
              <v-menu
                dense
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                :return-value.sync="startDate"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    dense
                    placeholder="Pilih Tanggal Awal"
                    class="input mr-4"
                    v-model="startDate"
                    label="Tanggal Awal"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                  ></v-text-field>
                  <!-- @click:clear="getIncomeData()" -->
                </template>
                <v-date-picker
                  v-model="startDate"
                  no-title
                  scrollable
                  @input="
                    $refs.menu1.save(startDate);
                    menu1 = false;
                    getIncomeData();
                  "
                />
              </v-menu>

              <v-menu
                dense
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="endDate"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    dense
                    placeholder="Pilih Tanggal Akhir"
                    class="input mr-4"
                    v-model="endDate"
                    label="Tanggal Akhir"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                  ></v-text-field>
                  <!-- @click:clear="getIncomeData()" -->
                </template>
                <v-date-picker
                  v-model="endDate"
                  no-title
                  scrollable
                  @input="
                    $refs.menu2.save(endDate);
                    menu2 = false;
                    getIncomeData();
                  "
                />
              </v-menu>
            </v-row>
          </div>
        </v-row>
      </v-container>
      <template v-if="!showBPJSBilling">
        <v-tabs v-model="tabs" grow centered>
          <v-tab class="font-weight-bold text-capitalize">
            Pendapatan dari Pelayanan
          </v-tab>
          <v-tab class="font-weight-bold text-capitalize">
            Pendapatan Kapitasi BPJS
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabs">
          <v-tab-item>
            <v-container class="mx-7 mb-4" style="max-width: 95%">
              <v-row class="py-2 pr-9">
                <label class="float-left font-weight-medium black--text pl-6">
                  Pendapatan dari pelayanan
                </label>
              </v-row>

              <IncomeReportAccordion :data-income="income" />
            </v-container>

            <div class="d-flex justify-start" style="background-color: #d9edff">
              <v-container class="mx-7 px-0 py-2 justify-start">
                <v-row>
                  <v-col>
                    <label
                      class="mb-0 float-left font-weight-bold black--text pl-6"
                    >
                      Total Pendapatan
                    </label>
                  </v-col>
                  <spacer></spacer>
                  <v-col class="text-right font-weight-bold pr-15">
                    <span>{{ currency(totalAll || 0) }}</span>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-tab-item>
          <v-tab-item>
            <v-container fluid class="ma-0 pa-0 bpjs-container">
              <v-data-table
                :headers="table.headers"
                :items="table.items"
                :items-per-page="pagination.rowPerPage"
                :loading="isLoadingCapitation"
                max-height="100"
                fixed-header
                hide-actions
                hide-default-footer
              >
                <template v-slot:[`item.income`]="{ item }">
                  <span>{{ currency(item.income || 0) }}</span>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <div class="d-flex">
                    <v-icon
                      @click="
                        isEdit = true;
                        onClick(0, item);
                      "
                      color="grey"
                      class="mr-3"
                      >mdi-square-edit-outline</v-icon
                    >
                    <v-icon @click="warningDeleteItem(item)" color="grey"
                      >mdi-delete</v-icon
                    >
                  </div>
                </template>
              </v-data-table>
              <v-card rounded="9" elevation="0" class="result-card">
                <div class="result-grid">
                  <h4 class="text-start">Perbandingan Pendapatan Kapitasi</h4>
                  <v-menu
                    offset-y
                    :close-on-content-click="false"
                    v-model="mainRatio.monthPickerRatio"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        class="result-filter text-end"
                        readonly
                        v-model="mainRatio.dateTextRatio"
                        v-on="on"
                        placeholder="Bulan, tahun"
                        outlined
                        dense
                        single-line
                        required
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      type="month"
                      v-model="mainRatio.selectedMonthRatio"
                      @input="changeDate"
                    ></v-date-picker>
                  </v-menu>
                  <span class="text-start">Pendapatan Kapitasi BPJS</span>
                  <span class="text-end">{{
                    currency(ratioCapitationInc.capitationIncome || 0)
                  }}</span>
                  <span class="text-start">Tagihan Pasien BPJS</span>
                  <span class="text-end">{{
                    currency(ratioCapitationInc.serviceIncome || 0)
                  }}</span>
                </div>
                <v-divider></v-divider>
                <v-row class="pa-4">
                  <v-col class="d-flex justify-space-between">
                    <span class="black--text font-weight-bold"
                      >Total Selisih</span
                    >
                    <span class="black--text font-weight-bold">{{
                      currency(ratioCapitationInc.difference || 0)
                    }}</span>
                  </v-col>
                </v-row>
              </v-card>
              <v-btn
                color="primary"
                fab
                dark
                @click="
                  isEdit = false;
                  onClick(0);
                "
                depressed
                class="btn-add"
              >
                <v-icon dark> mdi-plus </v-icon>
              </v-btn>
            </v-container>
            <v-footer color="white">
              <v-pagination
                :length="pagination.totalPage"
                v-model="pagination.page"
                @input="showAllCapitationIncome()"
              ></v-pagination>
              <v-btn
                color="primary"
                @click="showBPJSBilling = true"
                text
                class="text-capitalize"
                >Tagihan Pasien BPJS
                <v-icon color="primary" right>mdi-arrow-right</v-icon></v-btn
              >
            </v-footer>
          </v-tab-item>
        </v-tabs-items>
      </template>
      <template v-if="showBPJSBilling">
        <PatientBillingBPJS />
        <v-footer style="background-color: white" class="d-flex justify-end">
          <v-btn
            color="primary"
            @click="showBPJSBilling = false"
            text
            class="text-capitalize"
            >Pendapatan Kapitasi BPJS
            <v-icon color="primary" right>mdi-arrow-right</v-icon></v-btn
          >
        </v-footer>
      </template>
    </v-main>
    <v-dialog
      v-model="dialog"
      max-width="400px"
      transition="dialog-transition"
      persistent
    >
      <InputBPJSCapitation
        :is-edit="isEdit"
        :data="selectedData"
        @close-dialog="closeDialog"
        v-if="selectedForm === 0"
      />
    </v-dialog>
  </div>
</template>

<script>
import IncomeReportAccordion from './IncomeReportAccordion.vue';
import InputBPJSCapitation from './Modal/InputBPJSCapitation.vue';
import PatientBillingBPJS from '../PatientBillingBPJS.vue';
import Constant from '@/const';
import jwtMixin from '@/mixin/jwtMixin';
import alertMixin from '@/mixin/alertMixin';
import formatMixin from '@/mixin/formatMixin';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';

const axios = require('axios');

export default {
  name: 'IncomeReport',
  mixins: [jwtMixin, alertMixin, formatMixin],
  components: {
    IncomeReportAccordion,
    InputBPJSCapitation,
    PatientBillingBPJS,
  },

  data() {
    return {
      // For Main Dashboard
      mainRatio: {
        monthPickerRatio: false,
        selectedMonthRatio: '',
        dateTextRatio: '',
      },
      // For Secondary in detail
      secRatio: {
        monthPickerRatio: false,
        selectedMonthRatio: '',
        dateTextRatio: '',
      },
      totalBillBpjs: {
        monthPickerRatio: false,
        selectedMonthRatio: '',
        dateTextRatio: '',
      },
      show: false,
      selectShift: null,
      startDate: null,
      endDate: null,
      menu1: false,
      menu2: false,
      showBPJSBilling: false,
      dialog: false,
      isEdit: false,
      isLoadingCapitation: false,
      selectedForm: '',
      selectedData: '',
      page: 1,
      tabs: 0,
      income: [],
      resource: [
        { text: 'Shift Pagi', value: 1 },
        { text: 'Shift Siang', value: 2 },
        { text: 'Shift Malam', value: 3 },
        { text: 'Semua Shift', value: 0 },
      ],
      pagination: {
        page: 1,
        rowPerPage: 7,
        totalItems: null,
        totalPage: 0,
      },
      ratioCapitationInc: {
        difference: 0,
        capitationIncone: 0,
        serviceIncome: 0,
      },
      secRatioCapitationInc: {
        difference: 0,
        capitationIncone: 0,
        serviceIncome: 0,
      },
      table: {
        headers: [
          {
            text: 'No',
            align: 'center',
            sortable: false,
            value: 'no',
            width: '5%',
            class: 'pr-0',
          },
          {
            text: 'Bulan',
            align: 'start',
            sortable: false,
            value: 'month',
            width: '25%',
            class: 'pr-0',
          },
          {
            text: 'Total Pasien BPJS',
            align: 'start',
            sortable: false,
            value: 'total',
            width: '25%',
            class: 'pr-0',
          },
          {
            text: 'Jumlah Pendapatan',
            align: 'start',
            sortable: false,
            value: 'income',
            width: '25%',
            class: 'pr-0',
          },
          {
            text: '',
            align: 'center',
            sortable: false,
            value: 'action',
            width: '10%',
            class: 'pr-0',
          },
        ],
        items: [],
      },
      bpjsBilling: [],
      totalBpjsBilling: 0,
      isLoading: true,
    };
  },
  mounted() {
    this.getIncomeData();
    this.showAllCapitationIncome();
    this.showTotalCapitationIncome();
    // this.$nextTick(() => {
    //   this.refreshAndAutoUpdate();
    // });
  },
  watch: {
    startDate() {
      this.getIncomeData();
    },
    endDate() {
      this.getIncomeData();
    },
    dialog: function(newValue) {
      if (!newValue) this.selectedForm = '';
    },
    showBPJSBilling() {
      this.showTotalCapitationIncome();
      this.showIncomeDataBPJS();
    },
  },
  computed: {
    totalAll() {
      return Object.values(this.income).reduce((a, { total }) => a + total, 0);
    },
    totalIncome() {
      return this.othersIncome + this.totalAll;
    },
  },
  methods: {
    onClick(value, item) {
      this.selectedData = '';
      if (item) {
        this.selectedData = {
          id: item.id,
          total_income: item.income,
          total_patient: item.total,
          date: item.date,
        };
      }
      this.dialog = true;
      this.selectedForm = value;
    },
    closeDialog() {
      setTimeout(this.showAllCapitationIncome, 200);
      this.dialog = false;
      this.selectedForm = '';
    },
    changeDate() {
      if (this.showBPJSBilling) {
        this.secRatio.dateTextRatio = moment(
          this.secRatio.selectedMonthRatio,
          'YYYY-MM',
        )
          .locale('id')
          .format('MMMM, YYYY');
        this.secRatio.monthPickerRatio = false;
      } else {
        this.mainRatio.dateTextRatio = moment(
          this.mainRatio.selectedMonthRatio,
          'YYYY-MM',
        )
          .locale('id')
          .format('MMMM, YYYY');
        this.mainRatio.monthPickerRatio = false;
      }
      this.showTotalCapitationIncome();
    },
    changeDateBpjs() {
      this.totalBillBpjs.dateTextRatio = moment(
        this.totalBillBpjs.selectedMonthRatio,
        'YYYY-MM',
      )
        .locale('id')
        .format('MMMM, YYYY');
      this.totalBillBpjs.monthPickerRatio = false;
      this.showIncomeDataBPJS();
    },
    warningDeleteItem(item) {
      const dateText = moment(item.date, 'MM/YYYY')
        .locale('id')
        .format('MMMM YYYY');
      Swal.fire({
        title: `Apakah anda yakin ingin menghapus pendapatan kapitasi ${dateText} ?`,
        text: ``,
        icon: 'question',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batal',
      }).then(result => {
        if (!result.value) return;
        else this.dropCapitationIncome(item.id);
      });
    },
    async exportIncomeReport() {
      try {
        const response = await axios.get(
          Constant.apiUrl.concat(
            `/report/billing/income_component?start_date=${
              this.startDate === null ? '' : this.startDate
            }&last_date=${this.endDate === null ? '' : this.endDate}`,
          ),
        );
        if (response.status === 200) {
          this.showFillSuccess('Export Berhasil');
          const { url } = response.config;
          window.open(url, '_blank');
        }
      } catch (error) {
        if (error) {
          Swal.fire({
            icon: 'error',
            title: 'Terjadi Kesalahan',
            text: 'Tidak dapat mengexport dokumen',
          });
        }
      }
    },
    getIncomeData() {
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/report/billing/income?start_date=${
                this.startDate === null ? '' : this.startDate
              }&last_date=${this.endDate === null ? '' : this.endDate}&shift=${
                this.selectShift === null ? '' : this.selectShift.value
              }&type[]=umum&type[]=asuransi`,
            ),
          )
          .then(response => {
            const income = response.data.data;
            this.income = [];
            for (let v in income) {
              let data = {
                type: income[v].type.replace(/^\w/, c => c.toUpperCase()),
                detail:
                  income[v].detail.length > 0
                    ? income[v].detail.map(arr => {
                        return {
                          unit: arr.unit,
                          totalAmount: arr.total_amount,
                        };
                      })
                    : [],
              };
              this.income.push(data);
            }
            this.income.forEach((item, idx) => {
              if (item.detail !== undefined) {
                this.income[idx].total = Object.values(item.detail).reduce(
                  (a, { totalAmount }) => a + totalAmount,
                  0,
                );
              } else {
                this.income[idx].total = 0;
              }
            });
            this.othersIncome = 0;
          })
          .catch(() => {
            this.income = [
              { type: 'Tunai', detail: [] },
              { type: 'Asuransi', detail: [] },
              { type: 'Lain-Lain', detail: [] },
            ];
          })
          .finally(() => (this.isLoading = false));
      });
    },
    dropCapitationIncome(id) {
      this.runApiCalls(() => {
        axios
          .delete(Constant.apiUrl.concat(`/finance/captincome/${id}`))
          .then(response => {
            const { pesan } = response.data;
            Swal.fire({
              icon: 'success',
              title: pesan,
            });
            this.showAllCapitationIncome();
          })
          .catch(() => {
            Swal.fire({
              icon: 'error',
              title: 'Terjadi Kesalahan',
              text: 'Tidak dapat menghapus data',
            });
          });
      });
    },
    showAllCapitationIncome() {
      const page = this.pagination.page;
      const itemCount = this.pagination.rowPerPage;
      this.isLoadingCapitation = true;

      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/finance/captincome?page=${page}&itemCount=${itemCount}&date=`,
            ),
          )
          .then(response => {
            const { data, length } = response.data;
            this.pagination.totalItems = length;
            this.pagination.totalPage = Math.ceil(
              length / this.pagination.rowPerPage,
            );
            this.table.items = data.map((v, idx) => {
              return {
                no: idx + itemCount * page - itemCount + 1,
                id: v._id,
                date: v.date,
                month: moment(v.date, 'MM/YYYY')
                  .locale('id')
                  .format('MMMM, YYYY'),
                total: v.total_patient,
                income: v.total_income,
              };
            });
          })
          .catch(() => {
            this.table.items = [];
          })
          .finally(() => {
            this.isLoadingCapitation = false;
          });
      });
    },
    showTotalCapitationIncome() {
      let date = '';
      if (this.showBPJSBilling) {
        if (this.secRatio.selectedMonthRatio != '')
          date = moment(this.secRatio.selectedMonthRatio, 'YYYY-MM').format(
            'MM/YYYY',
          );
      } else {
        if (this.mainRatio.selectedMonthRatio != '')
          date = moment(this.mainRatio.selectedMonthRatio, 'YYYY-MM').format(
            'MM/YYYY',
          );
      }
      this.runApiCalls(() => {
        axios
          .get(Constant.apiUrl.concat(`/finance/captincome/total?date=${date}`))
          .then(response => {
            const { data } = response.data;
            if (this.showBPJSBilling) {
              this.secRatioCapitationInc = {
                difference: data.total_difference,
                capitationIncome: data.total_capitation_income,
                serviceIncome: data.total_billing_income,
              };
              this.secRatio = {
                ...this.secRatio,
                dateTextRatio: data.date
                  ? moment(data.date, 'MM/YYYY')
                      .locale('id')
                      .format('MMMM, YYYY')
                  : '',
              };
            }
            if (!this.showBPJSBilling) {
              this.mainRatio = {
                ...this.mainRatio,
                dateTextRatio: data.date
                  ? moment(data.date, 'MM/YYYY')
                      .locale('id')
                      .format('MMMM, YYYY')
                  : '',
              };
              this.ratioCapitationInc = {
                difference: data.total_difference,
                capitationIncome: data.total_capitation_income,
                serviceIncome: data.total_billing_income,
              };
            }
          });
      });
    },
    showIncomeDataBPJS() {
      let date = '';
      if (this.totalBillBpjs.selectedMonthRatio == '')
        date = moment().format('YYYY-MM');
      else date = this.totalBillBpjs.selectedMonthRatio;

      let startDate = moment(date, 'YYYY-MM')
        .startOf('month')
        .format('YYYY-MM-DD');
      let endDate = moment(date, 'YYYY-MM')
        .endOf('month')
        .format('YYYY-MM-DD');

      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/report/billing/income?start_date=${startDate}&last_date=${endDate}&type[]=bpjs`,
            ),
          )
          .then(response => {
            const { data } = response.data;

            this.bpjsBilling = data[0].detail.map(v => {
              return {
                text: `Tagihan ${v.unit}`,
                billing: this.currency(v.total_amount || 0),
              };
            });
            this.totalBpjsBilling = data[0].detail.reduce(
              (acc, item) => acc + item.total_amount,
              0,
            );
          });
      });
    },
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    },
    // refreshAndAutoUpdate() {
    //   if (this.interval !== null) {
    //     this.interval = clearInterval(this.interval);
    //   }
    //   this.getIncomeData();
    //   this.showAllCapitationIncome();
    //   this.interval = setInterval(this.getIncomeData, 60000);
    // },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.bpjs-container {
  height: 68vh;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 4px;
    background-color: #bfbfbf;
  }
  &::-webkit-scrollbar-thumb {
    width: 4px;
    background-color: rgb(99, 99, 99);
  }
}

.billing-container {
  max-height: 30vh;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 9px;
    background-color: #dbdbdb;
  }
  &::-webkit-scrollbar-thumb {
    width: 4px;
    border-radius: 9px;
    background-color: rgb(161, 161, 161);
  }
}

.btn-add {
  position: absolute;
  right: 2rem;
  bottom: 5rem;
}

.result-card {
  background-color: #f0f9ff;
  width: 98%;
  margin: auto;

  &.bpjs {
    margin: 0;
    width: 100%;
  }

  .result-grid {
    display: grid;
    width: 100%;
    padding: 1rem;
    row-gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 2.5rem repeat(2, 1fr);
    span {
      color: #616161;
    }

    .result-filter {
      background-color: white;
      width: 30%;
      margin-left: auto;
    }
  }
}

.bpjs-billing-container {
  height: 73.5vh;

  .grid-billing {
    background-color: #f5f5f5;
    padding: 0.7rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;

    &.item {
      border: 1px solid #ededed;
      background-color: white;
      color: #2d9cdb;
      border-radius: 4px;
    }

    &.total {
      background-color: #deeef9;
    }

    .result-filter {
      background-color: white;
      width: 30%;
      margin-left: auto;
    }
  }
}

footer {
  display: flex;
  justify-content: space-between;
}
</style>
