<template>
  <v-main>
    <dashboard-queue-header :clinic="clinicName" />
    <section>
      <v-row>
        <v-col class="d-flex justify-space-between" cols="12">
          <h1>Dashboard Antrean {{ unit }}</h1>
          <div class="doctor bordered">
            {{ staff }}
          </div>
        </v-col>
        <v-col cols="6">
          <v-row>
            <v-col cols="12">
              <v-card class="bordered main-card" flat>
                <span class="card-title font-weight-regular mb-10"
                  >Antrean Saat Ini</span
                >
                <span>{{ queue[0]?.queue_code || '-' }}</span>
                <span class="font-weight-regular">{{
                  queue[0]?.patient_name || '-'
                }}</span>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card flat class="small-card bordered">
                <span class="card-title">No Ruangan</span>
                <span class="card-content">{{ room || '-' }}</span>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card flat class="small-card bordered">
                <span class="card-title">Antrean Selanjutnya</span>
                <span class="card-content">{{
                  queue[1]?.queue_code || '-'
                }}</span>
              </v-card></v-col
            >
            <v-col cols="6">
              <v-card flat class="small-card bordered">
                <span class="card-title">Jumlah Sisa Antrean</span>
                <span class="card-content">{{ remainingQueue || 0 }}</span>
              </v-card></v-col
            >
            <v-col cols="6">
              <v-card flat class="small-card bordered">
                <span class="card-title">Jumlah Pasien Hari Ini</span>
                <span class="card-content">{{ totalQueue || 0 }}</span>
              </v-card></v-col
            >
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-card flat class="bordered pass-queue-card">
            <span class="card-title"
              >Antrean Dilewati ({{ isMissedCount || 0 }})</span
            >
            <v-progress-linear
              v-if="isLoading"
              indeterminate
            ></v-progress-linear>
            <div class="table-header">
              <span>No Antrian</span>
              <span>Nama Pasien</span>
            </div>
            <div
              v-if="missedPatients?.length < 1"
              class="grey--text d-flex justify-center pa-5"
            >
              Tidak ada antrean dilewati
            </div>
            <template v-else>
              <div
                v-for="patient in missedPatients"
                :key="patient._id"
                class="table-row"
              >
                <span>{{ patient.queue_code }}</span>
                <span>{{ patient.patient_name }}</span>
                <v-btn
                  v-if="isUserDoctor"
                  :disabled="isCalling"
                  @click="recall(patient.queue_code)"
                  small
                  color="primary"
                  outlined
                  class="text-capitalize"
                  >Panggil Lagi</v-btn
                >
                <v-btn
                  v-if="isUserDoctor"
                  @click="handleModifyQueue(patient)"
                  small
                  text
                  class="text-capitalize"
                  ><v-icon class="mr-1" color="primary">mdi-check</v-icon> Sudah
                  Diperiksa</v-btn
                >
              </div>
            </template>
          </v-card>
        </v-col>
      </v-row>
    </section>
  </v-main>
</template>

<script>
import DetailPatientQueueScript from './DetailPatientQueue.js';

export default DetailPatientQueueScript;
</script>

<style lang="scss" scoped>
$primary-color: #137bc0;
$grey-text: #9e9e9e;

.v-main {
  overflow: auto;
  height: 100vh;

  .card-title {
    color: $grey-text;
    font-size: 1vw;
  }
  .card-content {
    font-weight: 500;
    color: black;
    font-size: 2.8vw;
  }
  .bordered {
    border: 2px solid #e0e0e0;
    border-radius: 9px;
    background-color: white;
  }

  section:nth-child(2) {
    height: 89vh;
    background-color: #fafafa;
    padding: 3vw 9vw;

    .doctor {
      padding: 0.5vw 1.2vw;
      font-size: 1vw;
      color: black;
    }

    .main-card {
      min-height: 18vw;
      max-height: fit-content;
      display: flex;
      flex-direction: column;
      text-align: start;
      padding: 2vw;

      span:nth-child(2) {
        font-size: 3.5vw;
        font-weight: 500;
      }
      span:nth-child(3) {
        font-size: 1.1vw;
      }
    }

    .small-card {
      display: flex;
      flex-direction: column;
      align-content: center;
      text-align: start;
      min-height: minmax(8vw, 9vw);
      padding: 1vw 2vw;
      justify-content: space-between;
    }

    .pass-queue-card {
      height: 100%;
      text-align: start;
      padding: 1vw 2vw;

      .table-header {
        display: grid;
        grid-template-columns: 8vw 27vw;
        padding: 0.5vw;
        color: #757575;
        background-color: #f5f5f5;
        border-radius: 1px;
        border-bottom: 1px solid #e0e0e0;
        margin-top: 1vw;
      }

      .table-row {
        padding: 0.5vw 0.6vw;
        display: grid;
        width: 34vw;
        grid-template-columns: 7.5vw auto repeat(2, 7vw);
        row-gap: 0.7vw;
        column-gap: 0.3vw;
        font-size: 0.9vw;
        max-height: 30vw;
        overflow-y: auto;
        &::-webkit-scrollbar {
          display: none !important;
        }

        button {
          letter-spacing: 0.001vw;
          width: fit-content;
          font-size: 0.7vw !important;
        }
      }
    }
  }
}
</style>
