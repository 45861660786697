<template>
  <v-card>
    <v-toolbar flat prominent class="mb-5 pa-3">
      <v-container fluid>
        <div class="d-flex justify-space-between">
          <h2 class="text-left mb-7">Edit Obat</h2>
          <v-btn @click="$emit('close-dialog')" small fab text>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-row>
          <v-col cols="8">
            <v-row class="header-grid">
              <span class="font-weight-bold text-left">No RM</span>
              <span class="black--text mx-6 text-left">{{
                patientData.rmNumber
              }}</span>

              <span class=" font-weight-bold text-left">Name</span>
              <span class="black--text mx-6  text-left"
                >{{ patientData.meta.name }} ({{
                  patientData.meta.gender ? 'L' : 'P'
                }})</span
              >

              <span class=" font-weight-bold text-left">Tanggal Lahir</span>
              <span class="black--text mx-6  text-left"
                >{{ patientData.meta.birthDate }} ({{
                  patientData.meta.age
                }}
                tahun)</span
              >
              <span class=" font-weight-bold text-left">DPJP</span>
              <span class="black--text text-left mx-6 ">{{
                patientData.meta.doctor
              }}</span>
            </v-row>
          </v-col>
          <v-col cols="4">
            <v-row class="mb-4">
              <span class="text-left font-weight-bold">Riwayat Alergi</span>
            </v-row>
            <v-row class="mt-n4">
              <span
                class="red--text font-weight-bold body-2"
                v-if="allergy.length === 0"
                >Tidak ada alergi</span
              >
              <template v-for="(allergy, idx) in allergy">
                <v-chip
                  :key="idx"
                  color="#EB4747"
                  class="allergy mr-2 mb-2"
                  label
                  small
                  caption
                  >{{ allergy }}</v-chip
                >
              </template>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-toolbar>

    <v-container fluid v-if="drugItem.racik.type !== 'racik'">
      <v-row no-gutters>
        <v-col cols="4">
          <div class="col-layout py-5 px-10">
            <label class="body-2 font-weight-bold text-left">Nama Obat</label>
            <v-combobox
              placeholder="Pilih nama obat"
              dense
              hide-details="false"
              :loading="loading"
              :items="resource.drugs"
              item-value="name"
              item-text="displayName"
              append-icon="mdi-chevron-down"
              :search-input.sync="searchDrug"
              v-model="input.nonracik.drugName"
              @change="
                input.nonracik.drugName
                  ? (input.nonracik.dosage = input.nonracik.drugName.strength)(
                      (input.nonracik.usage.dropdownInput.package =
                        input.nonracik.drugName.preparation),
                    )
                  : ''
              "
              clearable
              @click:clear="onClear()"
            ></v-combobox>

            <v-spacer></v-spacer>
            <v-checkbox
              v-model="input.nonracik.isExtra"
              class="padding mt-n7"
              dense
              label="Obat Extra"
            ></v-checkbox>

            <label class="body-2 mt-n10 font-weight-bold text-left"
              >Jenis Sediaan</label
            >
            <label class="text-left body-2 mt-n10">{{
              recipePreparation
            }}</label>

            <label class="body-2 mt-n10 font-weight-bold text-left"
              >Dosis</label
            >
            <v-text-field
              placeholder="Masukkan dosis"
              dense
              class=" mt-n10"
              hide-details="false"
              v-model.number="input.nonracik.dosage"
              :suffix="
                input.nonracik.drugName
                  ? input.nonracik.drugName.strength_unit
                  : ''
              "
            ></v-text-field>

            <label class="body-2 mt-n10 font-weight-bold text-left"
              >Jumlah</label
            >
            <v-text-field
              placeholder="Masukkan jumlah"
              dense
              class=" mt-n10 pb-0"
              hide-details="false"
              v-model.number="input.nonracik.amount"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="4">
          <v-row>
            <div class="col-layout usage py-5 px-10 mt-2">
              <label class="body-2 font-weight-bold text-left"
                >Aturan Pakai</label
              >
              <v-row>
                <v-col cols="2" class="pa-0 mr-2">
                  <v-radio-group v-model="input.radio_usage">
                    <v-radio class="mb-5" :value="0"></v-radio>
                    <v-radio :value="1"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="10" class="pa-0" style="margin-left: -20px">
                  <div class="usage-rules">
                    <v-combobox
                      :disabled="input.radio_usage !== 0"
                      class="form-body__data pt-0 mt-0 mb-5 pt-2"
                      append-icon=""
                      auto-select-first
                      dense
                      v-model="input.nonracik.usage.dropdownInput.day"
                      :items="[
                        '1',
                        '2',
                        '3',
                        '4',
                        '5',
                        '6',
                        '7',
                        '8',
                        '9',
                        '10',
                      ]"
                    ></v-combobox>
                    <span>x</span>
                    <v-combobox
                      :disabled="input.radio_usage !== 0"
                      append-icon=""
                      class="form-body__data pt-0 mt-0 mb-5 pt-2"
                      dense
                      auto-select-first
                      :items="[
                        '1/4',
                        '1/3',
                        '1/2',
                        '2/3',
                        '3/4',
                        '1',
                        '2',
                        '3',
                        '4',
                        '5',
                      ]"
                      v-model="input.nonracik.usage.dropdownInput.usage"
                    ></v-combobox>
                    <v-combobox
                      :disabled="input.radio_usage !== 0"
                      class="form-body__data pt-0 mt-0 mb-5 pt-2"
                      dense
                      auto-select-first
                      append-icon="mdi-chevron-down"
                      :items="resource.packaging.nonracik"
                      v-model="input.nonracik.usage.dropdownInput.package"
                    >
                    </v-combobox>
                  </div>
                  <div class="usage-rules2">
                    <v-text-field
                      :disabled="input.radio_usage !== 1"
                      class="form-body__data pt-2 mt-n6"
                      dense
                      v-model="input.nonracik.usage.manualInput.usage"
                    ></v-text-field>
                    <v-combobox
                      :disabled="input.radio_usage !== 1"
                      class="form-body__data pt-0 mt-n6 mb-5 pt-2"
                      dense
                      auto-select-first
                      append-icon="mdi-chevron-down"
                      :items="resource.packaging.nonracik"
                      v-model="input.nonracik.usage.manualInput.package"
                    >
                    </v-combobox>
                  </div>
                </v-col>
              </v-row>
              <!-- <v-text-field
            placeholder="Masukkan aturan pakai"
            dense
            hide-details="false"
            v-model="input.nonracik.usageRule"
          ></v-text-field> -->
            </div>
          </v-row>
          <v-row>
            <div class="col-layout py-5 px-10">
              <label class="body-2 font-weight-bold text-left">Rute</label>
              <v-select
                dense
                placeholder="Pilih Rute"
                :items="resource.route"
                v-model="input.nonracik.route"
                hide-details="false"
                append-icon="mdi-chevron-down"
              ></v-select>

              <label class="body-2 font-weight-bold text-left"
                >Aturan Umum</label
              >
              <v-select
                dense
                placeholder="Aturan Umum"
                :items="resource.generalRules"
                append-icon="mdi-chevron-down"
                v-model="input.nonracik.generalRules"
                hide-details="false"
              ></v-select>
            </div>
          </v-row>
        </v-col>
        <v-col cols="4">
          <div class="col-layout py-5 px-10">
            <label class="body-2 font-weight-bold text-left"
              >Instruksi Khusus</label
            >
            <v-text-field
              placeholder="Instruksi khusus"
              dense
              hide-details="false"
              v-model.number="input.nonracik.special_instruction"
            ></v-text-field>
            <label class="body-2 font-weight-bold text-left">Keterangan</label>
            <v-textarea
              placeholder="Masukkan keterangan"
              dense
              no-resize
              outlined
              hide-details="false"
              v-model="input.nonracik.note"
            ></v-textarea>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid style="height: 63vh; overflow: auto" v-else>
      <v-row no-gutters>
        <v-col cols="4">
          <div class="col-layout table-col py-5 px-10">
            <label class="body-2 font-weight-bold text-left">Nama Obat</label>
            <v-combobox
              placeholder="Pilih nama obat"
              dense
              hide-details="false"
              :loading="loading"
              :items="resource.drugs"
              append-icon="mdi-chevron-down"
              item-text="displayName"
              :search-input.sync="searchDrugRacik"
              v-model="input.racik.drugName"
              clearable
              @change="
                input.racik.drugName
                  ? (input.racik.dosage = input.racik.drugName.strength)
                  : '';
                input.racik.drugName
                  ? (mixDosageValue = input.racik.drugName.strength)
                  : '';
              "
              @click:clear="onClear()"
            ></v-combobox>

            <label class="body-2 font-weight-bold text-left"
              >Jenis Sediaan</label
            >
            <label class="text-left body-2">{{
              input.racik.drugName ? input.racik.drugName.preparation : ''
            }}</label>

            <label class="body-2 font-weight-bold text-left">Dosis</label>
            <v-text-field
              placeholder="Masukkan dosis"
              dense
              hide-details="false"
              v-model.number="input.racik.dosage"
              :suffix="
                input.racik.drugName ? input.racik.drugName.strength_unit : ''
              "
            ></v-text-field>
          </div>
          <v-row class="mb-3 ml-1">
            <v-col cols="12">
              <v-chip-group
                class="d-flex justify-space-between"
                active-class="white--text blue"
                v-model="selectedMultiple"
              >
                <v-chip
                  style="cursor: pointer; min-width: 20px"
                  class="d-flex justify-center"
                  v-for="(x, i) in multiple"
                  :value="x.value"
                  :key="i"
                  ><strong>{{ x.text }}</strong></v-chip
                >
              </v-chip-group>
            </v-col>
          </v-row>
          <v-row class="pl-13 mb-5 mt-3">
            <v-btn
              outlined
              height="30"
              width="220"
              color="primary"
              style="margin-top: -20px"
              class="d-flex justify-space-between text-none caption font-weight-medium"
              @click="addRacik()"
              ><v-icon small color="primary" class="mr-2">mdi-plus</v-icon
              >Tambah ke Bahan Obat Racik</v-btn
            >
          </v-row>
          <!-- <div class="mix-header">
            <label>R/</label>
            <label class="text-left pl-2">Nama Obat</label>
            <label>Jenis Sediaan</label>
            <label>Dosis</label>
            <label>Action</label>
          </div>
          <div class="items-container">
            <template v-if="table.items.length > 0">
              <div
                v-for="(item, index) in table.items"
                :key="`row-${index}`"
                class="mix-item"
              >
                <span>{{ index + 1 }}</span>
                <span class="text-left pl-2">{{ item.drugName }}</span>
                <span>{{ item.preparation }}</span>
                <span>{{
                  `${item.dosage_needed} ${
                    item.strength_unit === '-' ? '' : item.strength_unit
                  }`
                }}</span>
                <v-btn icon @click="deleteItem(item)">
                  <v-icon small>mdi-close</v-icon>
                </v-btn>
              </div>
            </template>
            <template v-else>
              <div class="text-center item-no-content">
                <span>Belum ada obat yang ditambahkan</span>
              </div>
            </template>
          </div> -->
        </v-col>
        <v-col cols="4">
          <div class="col-layout py-5 px-10">
            <label class="body-2 font-weight-bold text-left"
              >Nama Racikan</label
            >
            <v-text-field
              placeholder="Tulis nama racikan"
              dense
              hide-details="false"
              v-model="input.racik.recipeName"
            ></v-text-field>

            <v-spacer></v-spacer>
            <v-checkbox
              v-model="input.racik.isExtra"
              class="padding mt-n7"
              dense
              label="Obat Extra"
            ></v-checkbox>

            <label class="body-2 mt-n10 font-weight-bold text-left "
              >Jumlah</label
            >
            <div class="d-flex mt-n10">
              <v-text-field
                placeholder="Masukkan jumlah"
                dense
                hide-details="false"
                v-model.number="input.racik.amount"
              ></v-text-field>
              <v-combobox
                class="form-body__data mt-0 ml-3"
                clearable
                auto-select-first
                dense
                item-text="name"
                v-model="input.racik.packaging"
                :items="resource.packaging.packageRacik"
                append-icon="mdi-chevron-down"
                @change="
                  input.racik.usage.dropdownInput.package =
                    input.racik.packaging.value
                "
              ></v-combobox>
            </div>

            <label class="body-2 mt-n10 font-weight-bold text-left"
              >Aturan Pakai</label
            >
            <!-- <v-text-field
              placeholder="Masukkan aturan pakai"
              dense
              hide-details="false"
              v-model="input.racik.usageRule"
            ></v-text-field> -->
            <div class="usage-rules mt-n10">
              <v-combobox
                class="form-body__data pt-0 mt-0 mb-5 pt-2"
                append-icon=""
                auto-select-first
                dense
                v-model="input.racik.usage.dropdownInput.day"
                :items="['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']"
              ></v-combobox>
              <span>x</span>
              <v-combobox
                :disabled="input.radio_usage !== 0"
                append-icon=""
                class="form-body__data pt-0 mt-0 mb-5 pt-2"
                dense
                auto-select-first
                :items="[
                  '1/4',
                  '1/3',
                  '1/2',
                  '2/3',
                  '3/4',
                  '1',
                  '2',
                  '3',
                  '4',
                  '5',
                ]"
                v-model="input.racik.usage.dropdownInput.usage"
              ></v-combobox>
              <v-combobox
                :disabled="input.radio_usage !== 0"
                class="form-body__data pt-0 mt-0 mb-5 pt-2"
                dense
                auto-select-first
                append-icon="mdi-chevron-down"
                :items="resource.packaging.nonracik"
                v-model="input.racik.usage.dropdownInput.package"
              >
              </v-combobox>
            </div>

            <label class="body-2  mt-n10 font-weight-bold text-left"
              >Rute</label
            >
            <v-select
              dense
              class=" mt-n10"
              placeholder="Pilih Rute"
              append-icon="mdi-chevron-down"
              :items="resource.route"
              v-model="input.racik.route"
              hide-details="false"
            ></v-select>

            <label class="body-2 mt-n10 font-weight-bold text-left"
              >Aturan Umum</label
            >
            <v-select
              dense
              class=" mt-n10"
              placeholder="Aturan Umum"
              :items="resource.generalRules"
              append-icon="mdi-chevron-down"
              v-model="input.racik.generalRules"
              hide-details="false"
            ></v-select>
          </div>
        </v-col>
        <v-col cols="4">
          <div class="col-layout py-5 px-10">
            <label class="body-2 font-weight-bold text-left"
              >Instruksi Khusus</label
            >
            <v-text-field
              placeholder="Instruksi khusus"
              dense
              hide-details="false"
              v-model.number="input.racik.special_instruction"
            ></v-text-field>
            <label class="body-2 font-weight-bold text-left">Keterangan</label>
            <v-textarea
              placeholder="Masukkan keterangan"
              dense
              no-resize
              outlined
              hide-details="false"
              v-model="input.racik.note"
            ></v-textarea>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="5">
          <v-data-table
            :headers="table.headers"
            :items="table.items"
            :items-per-page="5"
          >
            <template v-slot:[`item.action`]="{ item }">
              <v-btn icon @click="deleteItem(item)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <template v-slot:[`item.no`]="{ item }">
              <td>{{ table.items.indexOf(item) + 1 }}</td>
            </template>
            <template v-slot:[`item.drugName`]="{ item }">
              <td>{{ item.nama }}</td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        tile
        depressed
        width="150"
        class="text-none"
        @click="addData()"
        >Simpan</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import Constant from '@/const';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import alertMixin from '@/mixin/alertMixin';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'DrugEdit',
  mixins: [alertMixin],
  props: {
    patientData: {
      type: Object,
      default: null,
    },
    // idRecipe: {
    //   type: String,
    // },
    idDrugAdministration: {
      type: String,
    },
    drugItem: {
      type: Object,
    },
    allergy: {
      type: Array,
    },
  },
  data() {
    return {
      tabs: null,
      resource: {
        generalRules: [
          'Sebelum makan',
          '1 jam setelah makan',
          '½ jam sebelum makan',
          '2 jam  sesudah makan',
          'Sebelum suapan pertama',
          'Bersama atau tanpa makan',
          'Bersama makan',
          'Kunyah ',
          'Kunyah bersama makan',
          'Sebelum atau sesudah makan',
          'Sesudah makan',
          'Setelah suapan pertama',
          'Taruh diatas lidah',
          'Taruh dibawah lidah ',
        ],
        route: [
          'Per Oral',
          'Sublingual',
          'Per Vagina',
          'Per Rectal',
          'Intravena',
          'Intramuscular',
          'Subcutan',
          'Okular (mata)',
          'Otic (telinga)',
          'Nasal (hidung)',
          'Inhalasi',
          'Transdermal',
          'Intratekal',
        ],
        drugs: [],

        packaging: {
          packageRacik: [
            { name: 'Sirup', value: 'Sendok Teh' },
            { name: 'Bungkus', value: 'Bungkus' },
            { name: 'Kapsul', value: 'Kapsul' },
            { name: 'Salep', value: 'Oleskan' },
          ],
          nonracik: [
            'Bungkus',
            'Hisap',
            'Kapsul',
            'Kumur',
            'ml',
            'mg',
            'Oleskan',
            'Semprot',
            'Sendok Makan',
            'Sendok Obat',
            'Sendok Sup',
            'Sendok Teh',
            'Tablet',
            'Tetes',
            'Ampul',
            'gr',
            'Ovula',
            'Patch',
            'Rectal Tube',
            'pcs',
            'TPM',
            'Unit',
            'Flexpen',
            'Vial',
          ],
        },
      },
      multiple: [
        {
          text: '1/8',
          value: 8,
        },
        {
          text: '1/5',
          value: 5,
        },
        {
          text: '1/4',
          value: 4,
        },
        {
          text: '1/3',
          value: 3,
        },
        {
          text: '1/2',
          value: 2,
        },
        {
          text: '2/3',
          value: 0.6,
        },
        {
          text: '3/4',
          value: 0.75,
        },
        {
          text: '1',
          value: 1,
        },
      ],
      selectedMultiple: '',
      table: {
        headers: [
          { text: 'No', value: 'no' },
          { text: 'Nama Obat', value: 'name' },
          { text: 'Jenis Sediaan', value: 'preparation' },
          { text: 'Dosis', value: 'dosage' },
          { text: '', value: 'action' },
        ],
        items: [],
      },
      input: {
        name: '',
        radio_usage: 0,
        nonracik: {
          drugName: '',
          amount: null,
          isExtra: false,
          usage: {
            dropdownInput: {
              usage: '',
              day: '',
              package: '',
            },
            manualInput: {
              usage: '',
              package: '',
            },
          },
          route: '',
          note: '',
          dosage: '',
          generalRules: '',
          special_instruction: '',
        },
        racik: {
          drugName: '',
          amount: null,
          isExtra: false,
          recipeName: '',
          usage: {
            dropdownInput: {
              usage: '',
              day: '',
              package: '',
            },
          },
          route: '',
          status: '',
          generalRules: '',
          packaging: {
            name: '',
            value: '',
          },
          note: '',
          special_instruction: '',
        },
      },
      drug: {
        displayName: '',
        name: '',
        id: '',
        strength: '',
        strength_unit: '',
      },
      loading: false,
      searchDrug: '',
      searchDrugRacik: '',
      recipePreparation: '',
    };
  },
  mounted() {
    // this.getDrugs('');
    this.mapData();
    this.input.racik.drugName = '';
  },
  watch: {
    searchDrug(val) {
      if (val) {
        // this.getDrugs(val);
        this.searchDataDrug(this);
      }
    },
    searchDrugRacik(val) {
      if (val) {
        // this.getDrugs(val);
        this.searchDataDrugRacik(this);
      }
    },
    selectedMultiple(value) {
      if (value && this.mixDosageValue !== '') {
        if (value === 0.75 || value === 0.6) {
          this.input.racik.dosage = this.mixDosageValue * value;
        } else {
          this.input.racik.dosage = this.mixDosageValue / value;
        }
      }
      if (value === undefined) {
        this.input.racik.dosage = this.mixDosageValue;
      }
    },
    'input.nonracik.drugName.preparation'(val) {
      if (val) {
        this.recipePreparation = this.input.nonracik.drugName.preparation;
      }
    },
  },
  methods: {
    searchDataDrug: _.debounce(v => v.getDrugs(v.searchDrug), 650),
    searchDataDrugRacik: _.debounce(v => v.getDrugs(v.searchDrugRacik), 650),
    mapData() {
      const data = this.drugItem;

      if (data.racik.type === 'racik') {
        this.input.name = data.nama;
      } else {
        this.drug = {
          id: data._id,
          // id_stock: data?.id_stock,
          name: data.nama,
          // text: data.drugs.detail.name,
          // displayName: data.drugs.detail.name,
          strength_unit: data.nonracik.unit_dosage,
          strength: data.nonracik.dosage,
          price: data.nonracik.price,
          // pricePerItem: data.pricePerItem,
          packaging: data.nonracik.packaging,
          preparation: data.nonracik.preparation,
          quantity: data.nonracik.quantity,
        };
      }

      if (data.racik.type === 'racik') {
        this.input.racik = {
          ...data.racik,
          _id: data._id,
          isExtra: data.isExtra || data.is_extra_drugs,
          packaging: data.racik.packaging,
          drugName: data.racik.drugName,
          recipeName: data.nama,
          amount: data.racik.quantity,
          usage: {
            dropdownInput: {
              day: data.racik.dose.split(' ')[0],
              usage: data.racik.dose.split(' ')[2],
              package: data.racik.dose.split(' ')[3],
            },
          },
        };
        this.table.items = data.racik.drugs;
      } else {
        this.input.radio_usage =
          data.nonracik.usageRule.split(' ').length >= 4 ? 0 : 1;
        this.recipePreparation = data.nonracik.preparation;
        this.input.nonracik = {
          ...data.nonracik,
          // preparation: data.nonracik.preparation,
          _id: data._id,
          isExtra: data.isExtra || data.is_extra_drugs,
          amount: data.nonracik.quantity,
          usage: {
            dropdownInput:
              data.nonracik.usageRule.split(' ').length >= 4
                ? {
                    day: data.nonracik.usageRule.split(' ')[0],
                    usage: data.nonracik.usageRule.split(' ')[2],
                    package: `${
                      data.nonracik.usageRule.split(' ')[3]
                    } ${data.nonracik.usageRule.split(' ')[4] || ''}`,
                  }
                : {
                    day: '',
                    usage: '',
                    package: '',
                  },
            manualInput:
              data.nonracik.usageRule.split(' ').length <= 3
                ? {
                    usage: data.nonracik.usageRule.split(' ')[0],
                    package: `${
                      data.nonracik.usageRule.split(' ')[1]
                    } ${data.nonracik.usageRule.split(' ')[2] || ''}`,
                  }
                : {
                    usage: '',
                    package: '',
                  },
          },
        };
      }
    },
    getDrugs(keyword) {
      this.loading = true;

      axios
        .get(
          Constant.apiUrl.concat(
            `/stock/all/names?page=1&itemCount=30&category=Obat&name=${
              !keyword ? '' : keyword
            }`,
          ),
        )
        .then(response => {
          let data = response.data.data;
          this.resource.drugs = data.map(arr => {
            return {
              id: arr.id_drugs,
              id_stock: arr._id,
              name: arr.drugs.detail.name,
              text: `${arr.drugs.detail.name} ${arr.drugs.detail.strength} ${arr.drugs.detail.strength_unit} (${arr.drugs.detail.factory})`,
              displayName: `${arr.drugs.detail.name} ${arr.drugs.detail.strength} ${arr.drugs.detail.strength_unit}`,
              strength_unit: arr.drugs.detail.strength_unit,
              strength: arr.drugs.detail.strength,
              preparation: arr.drugs.detail.preparation,
              packaging: arr.package,
              price: arr.price,
              expiry: arr.expiry_date,
              stock: arr.quantity,
            };
          });
          this.drug = this.resource.drugs.filter(arr => {
            return this.drug.name === arr.name;
          });
          this.loading = false;
        });
    },
    addRacik() {
      this.table.items.push({
        id: this.input.racik.drugName.id,
        name: this.input.racik.drugName.name,
        preparation: this.input.racik.drugName.preparation,
        dosage: this.input.racik.dosage,
        dosage_needed: this.input.racik.dosage,
        strength_unit: this.input.racik.drugName.strength_unit,
        strength: this.input.racik.drugName.strength,
        // unit_dosage: this.input.racik.drugName.unit_dosage,
      });

      this.selectedMultiple = '';
      this.mixDosageValue = '';
      this.input.racik.drugName = '';
      this.input.racik.preparation = '';
      this.input.racik.dosage = '';
      this.getDrugs('');
    },
    deleteItem(item) {
      const index = this.table.items.indexOf(item);

      this.table.items.splice(index, 1);
    },
    addData() {
      // const id_recipe = this.idRecipe;

      if (this.drugItem.racik.type !== 'racik') {
        const payload = {
          drugs: [
            {
              // history: this.drugItem.history,
              // id_recipe: this.drugItem.id_recipe,
              is_continued: this.drugItem.isContinued,
              is_extra_drugs: this.input.nonracik.isExtra,
              _id: this.input.nonracik._id,

              nonracik:
                // arr.nonracik?.type === 'nonracik'
                //   ?
                {
                  // date: moment(new Date()).format(),
                  // _id: this.input.nonracik._id,
                  name:
                    this.input.nonracik.drugName.name ||
                    this.input.nonracik.drugName,
                  id_stock: this.drug.id_stock,
                  id_drugs: this.drug.id || this.drugItem.nonracik.id_drugs,
                  // preparation: this.drug.preparation,
                  isOrdered: false,
                  // dosage: this.drug.strength,
                  // is_extra_drugs: this.input.nonracik.isExtra,
                  unit_dosage: this.drug.strength_unit,
                  quantity: parseInt(this.input.nonracik.amount),
                  expiry: this.drug.expiry,
                  packaging: this.drug.packaging,
                  description: this.input.nonracik.note,
                  general_rule: this.input.nonracik.generalRules,
                  special_instruction: this.input.nonracik.special_instruction,
                  usage:
                    this.input.radio_usage === 0
                      ? `${this.input.nonracik.usage.dropdownInput.day} x ${this.input.nonracik.usage.dropdownInput.usage} ${this.input.nonracik.usage.dropdownInput.package}`
                      : `${this.input.nonracik.usage.manualInput.usage} ${this.input.nonracik.usage.manualInput.package}`,
                  // name: this.input.name || '',

                  price: this.drug.price,
                  date: moment(new Date())
                    .locale('id')
                    .format(),
                  staff: this.$store.getters.userLoggedIn.id,

                  staff_approve: this.$store.getters.userLoggedIn.name,
                  route: this.input.nonracik.route,
                  dosage: this.input.nonracik.dosage,
                  // id_drugs: this.input.nonracik.drugName.id,
                  // packaging: this.input.nonracik.drugName.packaging.package,
                  preparation: this.input.nonracik.drugName.preparation,
                  // price: this.input.nonracik.drugName.hpp,
                  // unit_dosage: this.input.nonracik.drugName.strength_unit,
                },
              // : { time_delivery: [] },
              racik:
                // arr.racik?.type === 'racik'
                //   ? {
                //       ...arr.racik,
                //       name: arr.racik.drugName,
                //       description: arr.racik.note,
                //       general_rule: arr.racik.generalRules,
                //       usage: arr.racik.usageRule,
                //     }
                //   :
                {
                  drugs: [],
                  time_delivery: [],
                },
              timestamps: {
                // created_at: this.drugItem.created_at,
                created_by: this.$store.getters.userLoggedIn.id,
              },
            },
          ],
        };

        axios
          .put(
            Constant.apiUrl.concat(
              `/inpatient/drugadministration/${this.idDrugAdministration}`,
            ),
            payload,
          )
          .then(() => {
            Swal.fire('Data obat berhasil ditambahkan', '', 'success');
            this.$emit('close-dialog');
          })
          .catch(error => {
            this.showErrorAxios(error);
          });
      } else {
        const payload = {
          drugs: [
            {
              // history: [],
              // id_recipe: this.drugItem.id_recipe,
              is_continued: this.drugItem.isContinued,
              is_extra_drugs: this.input.racik.isExtra,
              _id: this.input.racik._id,

              nonracik: { time_delivery: [] },
              racik: {
                name: this.input.racik.recipeName,
                isOrdered: false,

                drugs: this.table.items.map(arr => {
                  return {
                    ...arr,
                    dosage: arr.dosage.toString(),
                    id_drugs: arr.id,
                    dosage_needed: arr.dosage_needed,
                    _id: arr.id,
                    name: arr.name,
                    preparation: arr.preparation,
                    // price: this.resource.drugs.price,
                    unit_dosage: arr.unit_dosage,
                    // packaging: arr.packaging,
                  };
                }),
                quantity: parseInt(this.input.racik.amount),
                requested_quantity: parseInt(this.input.racik.amount),
                packaging: this.input.racik.packaging.name,
                description: this.input.racik.note,
                price: this.input.racik.price,
                general_rule: this.input.racik.generalRules,
                special_instruction: this.input.racik.special_instruction,
                date: moment(new Date())
                  .locale('id')
                  .format(),
                staff: this.$store.getters.userLoggedIn.id,
                staff_approve: this.$store.getters.userLoggedIn.name,
                route: this.input.racik.route,
                usage: `${this.input.racik.usage.dropdownInput.day} x ${this.input.racik.usage.dropdownInput.usage} ${this.input.racik.usage.dropdownInput.package}`,
              },
              //   :
              // {
              //   drugs: [],
              //   time_delivery: [],
              // },
              timestamps: {
                created_by: this.$store.getters.userLoggedIn.id,
              },
            },
          ],
        };

        axios
          .put(
            Constant.apiUrl.concat(
              `/inpatient/drugadministration/${this.idDrugAdministration}`,
            ),
            payload,
          )
          .then(() => {
            Swal.fire('Data obat berhasil ditambahkan', '', 'success');
            this.$emit('close-dialog');
          })
          .catch(error => {
            this.showErrorAxios(error);
          });
      }
    },
    onClear() {
      this.getDrugs('');
      this.input.nonracik.dosage = '';
      this.input.racik.dosage = '';
    },
  },
};
</script>

<style scoped lang="scss">
.col-layout {
  display: grid;
  grid-template-columns: 17vh 32vh;
  grid-template-rows: repeat(5, 8vh);
  &.usage {
    display: grid;
    // grid-template-columns: 19vh 34vh;
    grid-template-rows: 8vh;
  }
  &.table-col {
    grid-template-rows: repeat(3, 8vh);
    display: grid;
    // grid-template-columns: 19vh 34vh;
  }
}

.usage-rules {
  display: grid;
  grid-template-columns: 0.6fr 0.3fr 0.6fr 3fr;
  column-gap: 10px;
  span {
    padding-top: 9px;
  }
}
.usage-rules2 {
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 10px;
}

@media screen and (min-width: 1904px) {
  .col-layout {
    grid-template-columns: 14vh 25vh;
  }
}

.header-grid {
  padding-left: 12px;
  display: grid;
  font-size: 0.9rem;
  grid-template-columns: repeat(2, 1fr 2fr);
  row-gap: 5px;
}
</style>
