<template>
  <v-card id="main">
    <v-progress-circular
      color="primary"
      width="3"
      size="20"
      indeterminate
      v-if="loading"
      id="loading"
    ></v-progress-circular>
    <v-btn @click="close" text fab id="close"><v-icon>mdi-close</v-icon></v-btn>
    <v-card-title class="d-flex justify-center" primary-title>
      Pendaftaran Pasien
    </v-card-title>
    <v-row>
      <v-col class="grid-display">
        <label>No RM</label>
        <label>{{ rm }}</label>
        <label class="required">Nama Lengkap</label>
        <v-text-field
          dense
          readonly
          :rules="rules.name"
          placeholder="Masukkan Nama Pasien"
          v-model="patient.name"
          required
          hide-details
        ></v-text-field>
        <label class="required">Status Pasien</label>
        <v-select
          dense
          hide-details
          placeholder="Pilih Status"
          :rules="rules.status"
          :items="resource.status"
          v-model="patient.nickname"
          required
          append-icon="mdi-chevron-down"
        ></v-select>
        <label class="required">Jenis Kelamin</label>
        <v-radio-group
          readonly
          :rules="rules.gender"
          v-model="patient.gender"
          required
          row
          class="mt-0 pt-0"
        >
          <v-radio label="Laki-laki" :value="true"></v-radio>
          <v-radio label="Perempuan" :value="false"></v-radio>
        </v-radio-group>
        <label>Tempat Lahir</label>
        <v-text-field
          dense
          placeholder="Masukkan Tempat Lahir"
          class="mt-0"
          v-model="patient.birth.place"
          required
        ></v-text-field>
        <label class="required">Tanggal Lahir</label>
        <v-text-field
          v-model="patient.birth.date"
          :rules="rules.birthDate"
          readonly
          placeholder="dd/mm/yyyy"
          class="mt-0 pt-0"
          maxlength="10"
          required
        ></v-text-field>
        <label>Usia</label>
        <v-text-field
          v-model="patient.age"
          readonly
          class="mt-0 pt-0"
          maxlength="10"
        ></v-text-field>
      </v-col>
      <v-col class="grid-display">
        <label>Jenis Identitas</label>
        <v-select
          dense
          placeholder="Pilih Identitas"
          :items="resource.identityType"
          v-model="patient.identity.type"
          readonly
          append-icon="mdi-chevron-down"
        ></v-select>
        <label>No. Identitas</label>
        <v-text-field
          dense
          readonly
          placeholder="Masukkan Nomor Identitas"
          type="number"
          v-model="patient.identity.number"
        ></v-text-field>
        <label class="required">Jenis Penjamin</label>
        <span class="text-start">BPJS</span>
        <label class="required">Status Pendaftaran</label>
        <span class="text-start">{{ isNew ? 'Baru' : 'Lama' }}</span>

        <label class="required">Nomor BPJS</label>
        <v-text-field
          dense
          placeholder="Masukkan Nomor BPJS"
          :rules="rules.bpjs"
          v-model="patient.assurance.number"
          class="mt-0"
          readonly
          :error-messages="bpjs_error"
          :append-icon="checkNo.bpjs ? 'mdi-check-circle' : undefined"
        >
        </v-text-field>
        <label>Nomor NIK</label>
        <v-text-field
          dense
          readonly
          placeholder="Masukkan Nomor NIK"
          v-model="patient.identity.number"
          class="mt-0"
          :error-messages="nik_error"
          required
          type="number"
        ></v-text-field>
        <label v-if="patient.assurance.type === 'bpjs'">Nama Provider</label>
        <v-text-field
          v-if="patient.assurance.type === 'bpjs'"
          dense
          class="mt-0"
          v-model="patient.assurance.provider"
          readonly
        >
        </v-text-field>
        <label>PROLANIS</label>
        <v-radio-group
          v-model="patient.assurance.isProlanis"
          row
          readonly
          class="mt-0 pt-0"
          hide-details="false"
        >
          <v-radio label="Ya" :value="true"></v-radio>
          <v-radio label="Bukan" :value="false"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-divider class="mb-4"></v-divider>
    <v-footer class="d-flex justify-end footer">
      <v-btn text @click="close">Kembali</v-btn>

      <v-btn
        :disabled="!isFormValid"
        @click="next"
        outlined
        lighten-1
        color="primary"
        class="ml-4"
        >Lanjut</v-btn
      >
    </v-footer>
    <v-dialog :max-width="1200" v-model="dialog">
      <anamnesis-form />
    </v-dialog>
  </v-card>
</template>

<script>
import Constant from '@/const';
import AnamnesisForm from '../AnamnesisForm.vue';
import Swal from 'sweetalert2';

const axios = require('axios');
const moment = require('moment');
export default {
  name: 'SocialDataMJKN',
  components: { AnamnesisForm },
  props: {
    rm: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      patient: {
        name: '',
        nickname: '',
        gender: '',
        age: '',
        assurance: {
          type: '',
          name: '',
          number: '',
          isProlanis: false,
          isBpjsBased: true,
          provider: '',
        },
        identity: {
          type: '',
          number: '',
        },
        birth: {
          place: '',
          date: '',
        },
      },
      resource: {
        status: [
          { text: 'Nyonya', value: 'nyonya' },
          { text: 'Tuan', value: 'tuan' },
          { text: 'Nona', value: 'nona' },
          { text: 'Saudara', value: 'saudara' },
          { text: 'Anak', value: 'anak' },
          { text: 'Bayi', value: 'bayi' },
        ],
        identityType: ['Tidak Ada Identitas', 'KTP', 'SIM', 'Passport'],
      },
      checkNo: {
        bpjs: false,
        nik: false,
      },
      bpjs_error: [],
      nik_error: [],
      rules: {
        name: [v => !!v || 'Perlu mengisi nama'],
        gender: [v => v !== '' || 'Perlu memilih jenis kelamin'],
        status: [v => !!v || 'Perlu memilih status pasien'],
        birthPlace: [v => !!v || 'Perlu mengisi tempat lahir'],
        birthDate: [
          v => !!v || 'Perlu mengisi tanggal lahir',
          v => this.checkDateFormat(v) || 'Tanggal Tidak Valid',
        ],
        address: [v => !!v || 'Perlu mengisi alamat'],
        religion: [v => !!v || 'Perlu memilih agama'],
        bpjs: [v => !!v || 'Perlu mengisi nomor BPJS'],
      },
    };
  },
  computed: {
    isNew() {
      return this.$store.getters.selectedPatient.isNew;
    },
    isFormValid() {
      return (
        this.patient.name &&
        this.patient.nickname &&
        this.patient.gender &&
        this.patient.assurance.number &&
        this.patient.birth.date
      );
    },
  },
  watch: {
    'patient.assurance.number'(value) {
      this.bpjs_error = [];
      this.checkNo.bpjs = false;
      if (value.length == 13 && this.patient.assurance.type == 'bpjs') {
        this.checkActivation(this.patient.assurance.type, value);
      }
      if (value.length > 13 && this.patient.assurance.type == 'bpjs') {
        this.bpjs_error.push('Nomer BPJS tidak dapat lebih dari 13');
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getPatientData();
    });
  },
  methods: {
    async getPatientData() {
      if (!this.rm) return;
      try {
        this.loading = true;
        const response = await axios.get(
          Constant.apiUrl.concat(`/patient/emr/social/${this.rm}`),
        );
        const { data } = response.data;
        this.patient = {
          ...this.patient,
          idEmr: data.id_emr,
          idRegistration: this.$store.getters.selectedPatient.id_registration,
          isCheckIn: true,
          address: {
            city: data.adress?.city?.name || '',
            district: data.adress?.district?.name || '',
            province: data.adress?.province?.name || '',
            sub_district: data.adress?.sub_district?.name || '',
            text: data.adress?.text || '',
          },
          phoneNumber: data.phone_number,
          ethnicity: data.ethnicity,
          languange: data.language_mastering,
          communicationObstacles: data.communication_obstacles,
          disabilities: data.disabilities,
          religion: data.religion,
          bloodType: data.blood_type,
          education: data.education,
          professino: data.profession,
          father: data.father,
          mother: data.mother,
          martialStatus: data.martial_status,
          name: data.name,
          nickname: data.nickname,
          gender: data.gender,
          age: data.age,
          assurance: {
            ...this.patient.assurance,
            type: data.assurance?.type,
            name: data.assurance?.name,
            number: data.assurance?.number,
            isProlanis: data.assurance?.isProlanis,
            isBpjsBased: !data.identity?.number && data.identity.type !== 'KTP',
          },
          identity: {
            number: data.identity?.number,
            type: data.identity?.type,
          },
          birth: {
            place: data.birth?.place,
            date: moment(data.birth?.date, 'YYYY-MM-DD').format('DD-MM-YYYY'),
          },
        };
      } catch (error) {
        if (error) {
          this.patient = {
            name: '',
            nickname: '',
            gender: '',
            age: '',
            assurance: {
              type: '',
              name: '',
              number: '',
              isProlanis: false,
              isBpjsBased: true,
              provider: '',
            },
            identity: {
              type: '',
              number: '',
            },
            birth: {
              place: '',
              date: '',
            },
          };
        }
      } finally {
        this.loading = false;
      }
    },
    checkDateFormat(date) {
      const dateInput = moment(date, 'DD/MM/YYYY');
      return dateInput.isValid();
    },
    checkActivation(type, no) {
      axios
        .get(Constant.apiUrl.concat(`/integration/pcare/patient/${type}/${no}`))
        .then(response => {
          const dataBPJS = response.data.data;
          this.patient.bpjsData = {
            ...this.patient.bpjsData,
            no: dataBPJS.noKartu,
            name: dataBPJS.nama,
            birthDate: dataBPJS.tglLahir,
            birthDateFormatted: moment(
              dataBPJS.tglLahir,
              'DD-MM-YYYY',
            ).format(),
            age:
              moment().diff(moment(dataBPJS.tglLahir, 'DD/MM/YYYY'), 'years') +
              ' Tahun',
            ktpNo: dataBPJS.noKTP,
          };

          const data = response.data.data;
          this.patient.assurance.provider = data.kdProviderPst.nmProvider;
          if (!data.aktif) {
            this.bpjs_error.push('Status Keanggotaan: Tidak Aktif');
          } else {
            this.checkNo.bpjs = true;
          }
        })
        .catch(error => {
          if (error.response?.status < 500) {
            this.bpjs_error.push('Nomer BPJS tidak ditemukan');
          } else {
            this.pcareServerError = true;
            Swal.fire('Tidak dapat terhubung dengan BPJS', '', 'info');
          }
        });
    },
    close() {
      this.$emit('close-form');
    },
    next() {
      this.$emit('mjkn-next', this.patient);
    },
  },
};
</script>

<style lang="scss" scoped>
#main {
  overflow: hidden;
  position: relative;
  padding: auto 5rem;

  #close {
    position: absolute;
    top: 0.5rem;
    right: 1rem;
  }
  #loading {
    position: absolute;
    top: 0.5rem;
    left: 1rem;
  }
  .grid-display {
    display: grid;
    grid-template-columns: 40% 60%;
    width: 100%;
    row-gap: 2rem;
    padding: 3rem;

    label {
      font-weight: 600;
      text-align: start;
    }
  }

  .footer {
    background-color: white;
    padding: 2rem;
  }

  .required:after {
    content: ' *';
    color: red;
  }
}
</style>
