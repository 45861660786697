<template>
  <div class="container text-sm-left">
    <v-main>
      <v-container class="px-6 py-0">
        <PageJumbotron
          title="Billing"
          subtitle="Cek Informasi Mengenai Billing Disini"
          image="illustration_card_payment.svg"
        />

        <h3 class="py-4">Kategori</h3>
        <SubMenu :submenus="submenus" />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import PageJumbotron from '@/components/SharedComponent/PageJumbotron.vue';
import SubMenu from '@/components/SharedComponent/SubMenu.vue';

export default {
  data() {
    return {
      submenus: [
        {
          title: 'Tagihan Pasien',
          icon: 'billing_patient_bill',
          route: '/keuangan/billing/tagihan-pasien',
        },
        {
          title: 'Tagihan Pasien BPJS',
          icon: 'billing_report_bill',
          route: '/keuangan/billing/tagihan-pasien-bpjs',
        },
        {
          title: 'Verifikasi Setor Kas',
          icon: 'billing_setor_kas',
          route: '/keuangan/billing/verifikasi-setor-kas',
        },
        {
          title: 'Laporan Pendapatan',
          icon: 'finance_verification',
          route: '/keuangan/billing/laporan-pendapatan',
        },
        {
          title: 'Laporan Jasa Medis',
          icon: 'finance_income',
          route: '/keuangan/billing/laporan-jasa-medis',
        },
      ],
    };
  },
  components: {
    PageJumbotron,
    SubMenu,
  },
};
</script>
