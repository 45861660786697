<template>
  <v-card class="main-container">
    <v-card-title class="justify-center">
      <h3>Detail Prosedur</h3>
      <v-btn absolute right icon @click="onCloseButtonClick()">
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row justify="center">
          <v-col md="10">
            <div class="detail-input">
              <div class="detail-input__label">
                <label for="name">Kode Prosedur</label>
              </div>

              <v-text-field
                disabled
                v-model="procedure.code"
                placeholder="Masukkan Kode Prosedur"
                class="detail-input__input-text"
                required
              />
            </div>
            <div class="detail-input">
              <div class="detail-input__label">
                <label for="name">Deskripsi Prosedur</label>
              </div>

              <v-textarea
                disabled
                v-model="procedure.description"
                placeholder="Tambahkan Deskripsi Prosedur"
                class="detail-input__input-text"
                name="deskripsidiagnosa"
                outlined
                height="200"
              ></v-textarea>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ['procedureDetail'],
  data() {
    return {
      procedure: {
        id: this.procedureDetail.id,
        code: this.procedureDetail.code,
        description: this.procedureDetail.description,
      },
    };
  },
  methods: {
    onCloseButtonClick() {
      this.$emit('close-dialog');
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  width: 30vw;
}

.column {
  margin: 0 auto;
}

.detail-radio {
  display: flex;
  justify-content: flex-end;
}

.detail-input {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  &__label {
    justify-content: flex-end;
    flex: 1;
    display: flex;
    color: #222;
  }

  &__input-text {
    margin-left: 10px;
    width: 150px;
  }
}

.padding {
  padding-left: 10px;
}

.radio {
  margin: 0;
}

.table-kemasan {
  width: 100%;
  margin-top: 20px;
}
</style>
