import { render, staticRenderFns } from "./AddFactoryDialog.vue?vue&type=template&id=035b5a34&scoped=true&"
import script from "./AddFactoryDialog.vue?vue&type=script&lang=js&"
export * from "./AddFactoryDialog.vue?vue&type=script&lang=js&"
import style0 from "./AddFactoryDialog.vue?vue&type=style&index=0&id=035b5a34&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "035b5a34",
  null
  
)

export default component.exports