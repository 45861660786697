<template>
  <div class="cashverification">
    <v-toolbar flat outlined min-height="84">
      <v-container fluid>
        <v-row align="center">
          <div class="d-flex flex-column justify-start">
            <v-toolbar-title class="font-weight-bold text-left ">
              Verifikasi Setor Kas
            </v-toolbar-title>
            <div class="caption grey--text ">
              0 Transaksi untuk diverifikasi
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-combobox
            v-model="selectShift"
            :items="items"
            item-value="value"
            item-text="title"
            placeholder="Pilih Shift"
            class="input mr-4 mt-5"
            dense
            outlined
            @change="getData()"
          />
          <v-menu
            class="ma-0 pa-0"
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            min-width="290px"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                outlined
                placeholder="Masukkan Tanggal"
                class="input mr-4 mt-5 text-left"
                clearable
                @click:clear="getData()"
              />
            </template>
            <v-date-picker
              v-model="date"
              @input="
                menu2 = false;
                getData();
              "
            ></v-date-picker>
          </v-menu>
          <v-btn class="mr-2" color="grey" icon text>
            <v-icon>mdi-home </v-icon>
          </v-btn>
          <v-btn class="mr-2" color="grey" icon text @click="getData">
            <v-icon>mdi-autorenew </v-icon>
          </v-btn>
          <v-text-field
            background-color="grey lighten-3"
            placeholder="Search"
            solo
            dense
            hide-details
            flat
            prepend-inner-icon="mdi-magnify"
            single-line
            v-model="search"
          />
        </v-row>
      </v-container>
    </v-toolbar>
    <v-main>
      <v-container>
        <v-tabs center-active v-model="tabs" class="d-flex justify-center">
          <v-tab
            class="font-weight-bold text-capitalize"
            @click="
              selectedTab = 'wadiah';
              getData();
            "
            >Wadiah</v-tab
          >
          <v-tab
            class="font-weight-bold text-capitalize"
            @click="
              selectedTab = 'tagihan';
              getData();
            "
            >Tagihan Pasien</v-tab
          >
        </v-tabs>
      </v-container>
      <v-tabs-items v-model="tabs">
        <v-tab-item>
          <template>
            <v-data-table
              :headers="wadiah.headers"
              :items="wadiah.item"
              :items-per-page="5"
              hide-default-footer
              :page.sync="page"
              :search="search"
              :loading="isLoading"
            >
              <template v-slot:[`item.no`]="{ item }">
                <tr>
                  <td>{{ wadiah.item.indexOf(item) + 1 }}</td>
                </tr>
              </template>
              <template v-slot:[`item.checkbox`]="{ item }">
                <v-checkbox
                  color="green lighten-1"
                  v-model="selectedWadiah"
                  :value="item.id"
                  @click="select"
                ></v-checkbox>
              </template>
              <template v-slot:[`body.append`]="{}">
                <tr>
                  <td colspan="7" class="text-right">
                    <span class="font-weight-bold">Total</span>
                  </td>
                  <td class="font-weight-bold text-sm-left">Nominal</td>
                  <td>
                    <v-row>
                      <v-col class="px-0">
                        <v-simple-checkbox
                          color="green lighten-1"
                          v-model="selectAllWadiah"
                          @click="allSelected('wadiah')"
                        ></v-simple-checkbox>
                      </v-col>
                      <v-col class="px-0">
                        <span class="font-weight-bold ml-2"
                          >[{{ selectedWadiah.length }}]</span
                        >
                      </v-col>
                      <v-col class="px-0">
                        <v-btn icon small @click="addVerification()">
                          <v-icon>mdi-plus-box</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </template>
        </v-tab-item>
        <v-tab-item>
          <template>
            <v-data-table
              :headers="patientBill.headers"
              :items="patientBill.item"
              :items-per-page="5"
              hide-default-footer
              :search="search"
              :loading="isLoading"
            >
              <template v-slot:[`item.no`]="{ item }">
                <tr>
                  <td>{{ patientBill.item.indexOf(item) + 1 }}</td>
                </tr>
              </template>
              <template v-slot:[`item.checkbox`]="{ item }">
                <v-checkbox
                  color="green lighten-1"
                  v-model="selectedTagihan"
                  :value="item.id"
                ></v-checkbox>
              </template>
              <template v-slot:[`body.append`]="{}">
                <tr>
                  <td colspan="7" class="text-right">
                    <span class="font-weight-bold">Total</span>
                  </td>
                  <td class="font-weight-bold text-sm-left">Nominal</td>
                  <td>
                    <v-row>
                      <v-col class="px-0">
                        <v-simple-checkbox
                          color="green lighten-1"
                          v-model="selectAllTagihan"
                          @click="allSelected('tagihan')"
                        ></v-simple-checkbox>
                      </v-col>
                      <v-col class="px-0">
                        <span class="font-weight-bold ml-2"
                          >[{{ selectedTagihan.length }}]</span
                        >
                      </v-col>
                      <v-col class="px-0">
                        <v-btn icon small @click="addVerification()">
                          <v-icon>mdi-plus-box</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </template>
        </v-tab-item>
      </v-tabs-items>
    </v-main>
    <v-footer color="white">
      <div class="text-center">
        <v-pagination v-model="page" :length="pageLength"></v-pagination>
      </div>
    </v-footer>
  </div>
</template>

<script>
import Constant from '@/const';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';

const axios = require('axios');

export default {
  data() {
    return {
      items: [
        {
          title: 'Shift Pagi',
          value: 'pagi',
        },
        {
          title: 'Shift Siang',
          value: 'siang',
        },
        {
          title: 'Shift Malam',
          value: 'malam',
        },
        {
          title: 'Semua Shift',
          value: '',
        },
      ],
      menu2: false,
      tabs: null,
      patientBill: {
        headers: [
          {
            text: 'No',
            align: 'start',
            sortable: false,
            value: 'no',
          },
          { text: 'Tanggal Transaksi', value: 'transactionDate' },
          { text: 'No. Pendaftaran', value: 'registNumber' },
          { text: 'Nama', value: 'name' },
          { text: 'Tipe Penjamin', value: 'assurance' },
          { text: 'Status Pasien', value: 'patientStatus' },
          { text: 'Status Kuitansi', value: 'receiptStatus' },
          { text: 'Nominal', value: 'nominal' },
          { text: '', value: 'checkbox' },
        ],
        item: [],
      },
      wadiah: {
        headers: [
          {
            text: 'No',
            align: 'start',
            sortable: false,
            value: 'no',
          },
          { text: 'Tanggal Transaksi', value: 'transactionDate' },
          { text: 'No. Pendaftaran', value: 'registNumber' },
          { text: 'Nama', value: 'name' },
          { text: 'Tipe Penjamin', value: 'assurance' },
          { text: 'Status Pasien', value: 'patientStatus' },
          { text: 'Status Kuitansi', value: 'receiptStatus' },
          { text: 'Nominal', value: 'nominal' },
          { text: '', value: 'checkbox' },
        ],
        item: [],
      },
      page: 1,
      pageLength: 0,
      total: 0,
      selectedWadiah: [],
      selectedTagihan: [],
      selectAllWadiah: false,
      selectAllTagihan: false,
      selectedTab: 'wadiah',
      search: '',
      isLoading: false,
      date: '',
      selectShift: '',
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getData();
    });
  },
  methods: {
    async getData() {
      if (this.selectedTab === 'wadiah') {
        this.isLoading = true;
        await axios
          .get(
            Constant.apiUrl.concat(
              `/billing/all/cash-verification?type=wadiah&date=${this.date}&shift=${this.selectShift}`,
            ),
          )
          .then(response => {
            const data = response.data.data;
            this.pageLength = Math.ceil(data.length / 5);

            this.wadiah.item = data.map(verif => {
              return {
                id: verif._id,
                transactionDate: moment(verif.timestamps.created_at)
                  .locale('id')
                  .format('l'),
                registNumber: verif.registration.registration_number,
                name: verif.emr.social[0].name,
                assurance: verif.emr.social[0].assurance.type.toUpperCase(),
                receiptStatus: verif.id_billing.isPaid
                  ? 'Lunas'
                  : 'Belum Lunas',
                nominal: verif.amount,
                patientStatus: verif.registration.queue.isFinish
                  ? 'Terlayani'
                  : 'Terdaftar',
              };
            });
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
      } else {
        this.isLoading = true;
        await axios
          .get(
            Constant.apiUrl.concat(
              `/billing/all/cash-verification?type=pelunasan&date=${this.date}&shift=${this.selectShift}`,
            ),
          )
          .then(response => {
            const data = response.data.data;
            this.pageLength = Math.ceil(data.length / 5);

            this.patientBill.item = data.map(verif => {
              return {
                id: verif._id,
                transactionDate: moment(verif.timestamps.created_at)
                  .locale('id')
                  .format('l'),
                registNumber: verif.registration.registration_number,
                name: verif.emr.social[0].name,
                assurance: verif.emr.social[0].assurance.type.toUpperCase(),
                receiptStatus: verif.id_billing.isPaid
                  ? 'Lunas'
                  : 'Belum Lunas',
                nominal: verif.amount,
                patientStatus: verif.registration.queue.isFinish
                  ? 'Terlayani'
                  : 'Terdaftar',
              };
            });
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
      }
    },
    allSelected(val) {
      switch (val) {
        case 'wadiah':
          this.selectedWadiah = [];

          if (this.selectAllWadiah) {
            for (const user in this.wadiah.item) {
              this.selectedWadiah.push(Number(user));
            }
          }
          break;
        case 'tagihan':
          this.selectedTagihan = [];

          if (this.selectAllTagihan) {
            for (const user in this.patientBill.item) {
              this.selectedTagihan.push(Number(user));
            }
          }
          break;
      }
    },
    select() {
      this.selectAllWadiah = false;
      this.selectedTagihan = false;
    },
    addVerification() {
      if (this.selectedTab === 'wadiah') {
        axios
          .post(
            Constant.apiUrl.concat('/billing/transaction/cash-verification'),
            {
              list: this.selectedWadiah,
              verified_by: this.$store.getters.userLoggedIn.id,
            },
          )
          .then(() => {
            Swal.fire({
              icon: 'success',
              title: 'Verifikasi Berhasil',
              showConfirmButton: false,
              timer: 1500,
            });
            this.getData();
            this.selectedWadiah = [];
          });
      } else {
        axios
          .post(
            Constant.apiUrl.concat('/billing/transaction/cash-verification'),
            {
              list: this.selectedTagihan,
              verified_by: this.$store.getters.userLoggedIn.id,
            },
          )
          .then(() => {
            Swal.fire({
              icon: 'success',
              title: 'Verifikasi Berhasil',
              showConfirmButton: false,
              timer: 1500,
            });
            this.getData();
            this.selectedTagihan = [];
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input {
  width: 180px !important;
}

::v-deep {
  .v-toolbar__content {
    height: 84px !important;
  }
}
</style>
