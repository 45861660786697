import _ from 'lodash';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import Documents from '@/mixin/documents';
import PrintMixin from '@/mixin/printMixin';
import { createNamespacedHelpers } from 'vuex';
const {
  mapGetters: icGetters,
  mapActions: icActions,
  mapMutations: icMutations,
} = createNamespacedHelpers('InformedConsent');

export default {
  mixins: [Documents, PrintMixin],
  data() {
    return {
      step: 1,
      snackbarEdit: false,
      table: {
        headers: [
          {
            text: 'Waktu Pernyataan',
            value: 'created_at',
            sortable: false,
          },
          {
            text: 'Tindakan Kedokteran',
            value: 'action',
            sortable: false,
          },
          {
            text: 'Yang Bertandatangan',
            value: 'signer_name',
            sortable: false,
          },
          {
            text: 'Pemberi Informasi',
            value: 'staff_information',
            sortable: false,
          },
          {
            text: 'Jenis Pernyataan',
            value: 'patient_action_agreement',
            sortable: false,
          },
          {
            text: '',
            width: '10%',
            value: 'action',
            sortable: false,
          },
        ],
      },
      actionItems: [
        {
          label: 'Lihat Detail',
          action: (id, created_at) => {
            this.handleSelectDialog(true, 'detail', id, created_at);
          },
        },
        {
          label: 'Print Formulir Informed Consent',
          action: id => {
            this.resolveGetInformConsentById(id)
              .then(item => {
                const doc = this.generateFormInformedConsent(
                  item.detail,
                  this.patientData,
                );
                this.printDocument(doc, {
                  docName: 'Formulir Informed Consent',
                  rmNumber: this.patientData.noRM,
                });
              })
              .finally(() => this.clearFormInformedConsent());
          },
        },
        {
          label: 'Print Surat Pernyataan',
          action: id => {
            this.resolveGetInformConsentById(id)
              .then(item => {
                const doc = this.generateInformedConsentDocV2(
                  item.detail,
                  this.patientData,
                );
                this.printDocument(doc, {
                  docName: 'Surat Pernyataan Informed Consent',
                  rmNumber: this.patientData.noRM,
                });
              })
              .finally(() => this.clearFormInformedConsent());
          },
        },
      ],
      familyStatus: [
        'Ayah',
        'Ibu',
        'Anak',
        'Saudara Kandung',
        'Suami',
        'Istri',
        'Bibi',
        'Paman',
        'Kakek',
        'Nenek',
        'Cucu',
        'Cicit',
      ],
      rules: {
        required: [v => !!v || v === false || 'Wajib Diisi'],
        action: [v => (!!v?.name && v?.name !== 'Lain-lain') || 'Wajib Diisi'],
        maxChar: [v => v?.length <= 60 || 'Tidak boleh melebihi 60 karakter'],
        numberOnly: [
          v => /^\d+$/.test(v) || v === '' || 'Tidak boleh input selain angka',
        ],
      },
    };
  },
  computed: {
    ...icGetters([
      'getShowDialog',
      'getInformationForm',
      'getItems',
      'getDataPatient',
      'getStatementLetter',
      'getIsCheckup',
      'getSnackbar',
      'getStateForm',
      'getIsChanged',
      'getInitialForm',
      'getIdIC',
      'getIsEditable',
    ]),
    showDialog: {
      get() {
        return this.getShowDialog;
      },
    },
    informationForm: {
      get() {
        return this.getInformationForm;
      },
    },
    isEditable: {
      get() {
        return this.getIsEditable;
      },
    },
    statementLetter: {
      get() {
        return this.getStatementLetter;
      },
    },
    items: {
      get() {
        return this.getItems;
      },
    },
    patientData: {
      get() {
        return this.getDataPatient;
      },
    },
    isCheckup: {
      get() {
        return this.getIsCheckup;
      },
    },
    snackbar: {
      get() {
        return this.getSnackbar;
      },
    },
    stateForm: {
      get() {
        return this.getStateForm;
      },
    },
    isChanged: {
      get() {
        return this.getIsChanged;
      },
    },
    idIC: {
      get() {
        return this.getIdIC;
      },
    },
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
  },
  watch: {
    statementLetter: {
      handler() {
        this.checkIsChanged();
      },
      deep: true,
    },
    informationForm: {
      deep: true,
      handler() {
        this.checkIsChanged();
      },
    },
  },
  methods: {
    onChangeDiagnosis() {
      this.informationForm.diagnose.value = this.informationForm.diagnose.value.filter(
        item => typeof item !== 'string',
      );
    },
    ...icMutations([
      'setIdIC',
      'setStateForm',
      'setIsChanged',
      'setShowDialog',
      'setDataPatient',
      'setIsEditable',
      'clearFormInformedConsent',
      'clearItemsInformedConsent',
    ]),
    ...icActions([
      'resolveGetStaff',
      'resolveGetDiagnose',
      'resolveGetAllService',
      'resolveGetCheckupRegist',
      'resolvePostInformedConsent',
      'resolveGetInformConsentList',
      'resolveGetInformConsentById',
      'resolvePutInformedConsent',
    ]),
    today() {
      return moment().format('DD-MM-YYYY');
    },
    formatDate(val) {
      return moment(val).format('DD-MM-YYYY, HH:mm');
    },
    closeForm() {
      if (this.showDialog === true) {
        if (this.isChanged === false) {
          Swal.fire({
            title:
              '<div style="font-family: Roboto, Serif; color: #222222; font-weight: 700; font-size: 20px; line-height: 30px;"><p>Yakin Ingin Keluar dari Formulir</p><p>Informed Consent?</p></div>',
            iconHtml: '<span class="mdi mdi-help-circle-outline"></span>',
            html:
              '<div style="font-family: Roboto; font-style: normal; font-weight: 400; font-size: 14px; color: #616161; line-height: 150%;"><p>Data yang anda input akan hilang. Kembali dan</p><p>simpan formulir terlebih dahulu</p></div>',
            showCancelButton: true,
            customClass: {
              confirmButton: 'button-cancel',
              cancelButton: 'button-confirm',
            },
            confirmButtonText: 'Yakin',
            cancelButtonText: 'Kembali',
          }).then(result => {
            if (result.isConfirmed) {
              this.setShowDialog(false);
              this.resolveGetInformConsentList(this.patientData.id_emr);
              this.clearFormInformedConsent();
              this.resolveGetCheckupRegist({
                idRegist: this.patientData.id_registration,
                unit: this.unit,
              });
              this.step = 1;
            }
          });
        } else {
          this.setShowDialog(false);
          this.resolveGetInformConsentList(this.patientData.id_emr);
          this.clearFormInformedConsent();
          this.resolveGetCheckupRegist({
            idRegist: this.patientData.id_registration,
            unit: this.unit,
          });
          this.step = 1;
        }
      } else {
        this.$emit('close-form');
        this.clearFormInformedConsent();
        this.clearItemsInformedConsent();
      }
      this.$emit('close-form');
    },
    getStatementType(val) {
      if (val) {
        return { color: '#F0F9FF', text: 'Persetujuan' };
      } else {
        return { color: '#FFF2F2', text: 'Penolakan' };
      }
    },
    async handleSelectDialog(val, state, id, created_at) {
      this.setIdIC(id);
      this.setShowDialog(val);
      this.resolveGetStaff();
      if (state === 'add') {
        this.resolveGetAllService({
          keyword: '',
          guarantorType: this.patientData.guarantorType.toLowerCase(),
        });
        this.setStateForm(state);
        this.setIsChanged(
          _.isEqual(
            this.getStatementLetter,
            this.getInitialForm.statementLetter,
          ) &&
            _.isEqual(
              this.getInformationForm,
              this.getInitialForm.informationForm,
            ),
        );
      }
      if (this.isCheckup === false) {
        this.resolveGetAllService({
          keyword: '',
          guarantorType: this.patientData.guarantorType.toLowerCase(),
        });
        this.resolveGetCheckupRegist({
          idRegist: this.patientData.id_registration,
          unit: this.unit,
        });
        this.resolveGetDiagnose({ keyword: '' });
      }
      if (state === 'edit' || state === 'detail') {
        const response = await this.resolveGetInformConsentById(id);
        let keyword =
          response.detail?.information_checklist?.action?.value?.name ===
          'Lain-lain'
            ? ''
            : response.detail?.information_checklist?.action?.value?.name;
        this.resolveGetAllService({
          keyword,
          guarantorType: this.patientData.guarantorType.toLowerCase(),
        });
        this.setStateForm('detail');
        if (moment(created_at).isSame(moment(), 'day')) {
          this.setIsEditable(true);
        } else {
          this.setIsEditable(false);
        }
      }
    },
    async updateData() {
      if (this.$refs.informationForm.validate()) {
        try {
          const response = await this.resolvePutInformedConsent();
          await this.resolveGetInformConsentById(response.data.data._id);
          if (response.status === 200) {
            this.snackbarEdit = {
              show: true,
              color: 'success',
              text: 'Formulir Informed Consent berhasil diubah',
            };
          }
        } catch {
          this.snackbarEdit = {
            show: true,
            color: 'error',
            text: 'Terjadi kesalahan, Formulir Informed Consent gagal diubah',
          };
        }
        this.setStateForm('detail');
      }
    },
    async handleNextForm() {
      if (this.$refs.informationForm.validate()) {
        if (this.step === 1) {
          this.step++;
        } else {
          if (this.$refs.statementLetter.validate()) {
            if (this.stateForm === 'add') {
              if (this.$refs.informationForm.validate()) {
                await this.resolvePostInformedConsent();
                this.closeForm();
              }
            } else {
              if (this.$refs.informationForm.validate()) {
                await this.resolvePutInformedConsent();
              }
            }
          }
        }
      }
    },
    handleBackForm() {
      if (this.step === 2) {
        this.step--;
      }
    },
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9]+$/.test(char)) {
        return true;
      } else if (char === '.' || char === ',') {
        e.preventDefault();
        return false;
      } else {
        e.preventDefault();
      }
    },
    handlePrint(id, name, state) {
      const {
        doctor,
        staff_executor,
        staff_companion,
        staff_information,
        patient_receiver_info,
        signer,
        signer_name,
        signer_name_patient,
        signer_as,
        signer_age,
        signer_age_patient,
        no_telp,
        patient_action_agreement,
        address,
        family_name,
        family_as,
      } = this.statementLetter;
      const {
        diagnose,
        base_diagnose,
        action,
        action_indication,
        action_risk,
        procedure,
        purpose,
        complication,
        prognosis,
        alt_risk,
        cost,
        others,
      } = this.informationForm;
      const data = {
        information_checklist: {
          diagnose,
          base_diagnose,
          action,
          action_indication,
          action_risk,
          procedure,
          purpose,
          complication,
          prognosis,
          alt_risk,
          cost,
          others,
        },

        signer_as,
        patient_action_agreement,
        doctor: {
          id: doctor,
          name: this.items.staff.items.find(item => item.id === doctor).name,
        },
        staff_executor: {
          id: staff_executor,
          name: this.items.staff.items.find(item => item.id === staff_executor)
            .name,
        },
        staff_companion,
        staff_information: {
          id: staff_information,
          name: this.items.staff.items.find(
            item => item.id === staff_information,
          ).name,
        },
        patient_receiver_info,
        signer,
        signer_name: signer === 'pasien' ? signer_name_patient : signer_name,
        no_telp,
        address,
        family_name,
        family_as,
        signer_age: signer === 'pasien' ? signer_age_patient : signer_age,
        name,
      };
      switch (state) {
        case 'add':
          switch (name) {
            case 'form':
              this.printDocument(
                this.generateFormInformedConsent(data, this.patientData),
                {
                  docName: 'Formulir Informed Consent',
                  rmNumber: this.patientData.noRM,
                },
              );
              break;
            case 'letter':
              this.printDocument(
                this.generateInformedConsentDocV2(data, this.patientData),
                {
                  docName: 'Surat Pernyataan Informed Consent',
                  rmNumber: this.patientData.noRM,
                },
              );
              break;
          }
          break;
        case 'edit':
          switch (name) {
            case 'form':
              this.printDocument(
                this.generateFormInformedConsent(data, this.patientData),
                {
                  docName: 'Formulir Informed Consent',
                  rmNumber: this.patientData.noRM,
                },
              );
              break;
            case 'letter':
              this.printDocument(
                this.generateInformedConsentDocV2(data, this.patientData),
                {
                  docName: 'Surat Pernyataan Informed Consent',
                  rmNumber: this.patientData.noRM,
                },
              );
              break;
          }
          break;
        case 'detail':
          switch (name) {
            case 'form':
              this.resolveGetInformConsentById(id).then(item => {
                const doc = this.generateFormInformedConsent(
                  item.detail,
                  this.patientData,
                );
                this.printDocument(doc, {
                  docName: 'Formulir Informed Consent',
                  rmNumber: this.patientData.noRM,
                });
              });
              break;
            case 'letter':
              this.resolveGetInformConsentById(id).then(item => {
                const doc = this.generateInformedConsentDocV2(
                  item.detail,
                  this.patientData,
                );
                this.printDocument(doc, {
                  docName: 'Surat Pernyataan Informed Consent',
                  rmNumber: this.patientData.noRM,
                });
              });
              break;
          }
          break;
      }
    },
    checkIsChanged() {
      this.setIsChanged(
        _.isEqual(
          this.getStatementLetter,
          this.getInitialForm.statementLetter,
        ) &&
          _.isEqual(
            this.getInformationForm,
            this.getInitialForm.informationForm,
          ),
      );
    },
    handleChangeService: _.debounce(function(event) {
      this.resolveGetAllService({
        keyword: event.target.value,
        guarantorType: this.patientData.guarantorType.toLowerCase(),
      });
    }, 600),
    handleChangeDiagnose: _.debounce(function(event) {
      this.resolveGetDiagnose({ keyword: event.target.value });
    }, 600),
  },
};
