<template>
  <v-card height="600">
    <section class="py-5 mx-8">
      <div class="d-flex justify-space-between">
        <h2 class="text-left">Order Obat Ke Farmasi</h2>
        <v-btn @click="$emit('close-dialog')" small fab text>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </section>
    <section class="mx-8">
      <v-data-table
        :headers="table.headers"
        :items="table.items"
        :items-per-page="table.items.length"
        height="420"
        show-select
        @click:row="
          (item, slot) => {
            if (item.type === 'racik') slot.expand(!slot.isExpanded);
          }
        "
        single-expand
        item-key="no"
        hide-default-footer
        v-model="selectedData"
      >
        <template v-slot:[`item.no`]="{ item }">
          <td class="font-weight-regular">{{ item.no }}</td>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-btn
            v-if="item.status === 'Order belum dikonfirmasi'"
            icon
            @click="deleteItem(item)"
          >
            <v-icon color="red">mdi-close-box</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.drugName`]="{ item }">
          <td class="text-left font-weight-regular">
            {{ item.nonracik.drugName || item.racik.drugName }}
          </td>
        </template>
        <template v-slot:[`item.dosage`]="{ item }">
          <td class="text-center font-weight-regular">
            {{ item.nonracik.dosage || item.racik.dosage || '-' }}
          </td>
        </template>
        <template v-slot:[`item.usageRule`]="{ item }">
          <td class="text-center font-weight-regular">
            {{ item.nonracik.usageRule || item.racik.usageRule }}
          </td>
        </template>
        <template v-slot:[`item.route`]="{ item }">
          <td class="text-center font-weight-regular">
            {{ item.nonracik.route || item.racik.route }}
          </td>
        </template>
        <template v-slot:[`item.preparation`]="{ item }">
          <td class="text-center font-weight-regular">
            {{ item.nonracik.preparation || item.racik.preparation }}
          </td>
        </template>
        <template v-slot:[`item.staff`]="{ item }">
          <td class="text-center font-weight-regular">
            {{ item.nonracik.staff || item.racik.staff }}
          </td>
        </template>
        <template v-slot:[`item.date`]="{ item }">
          <td class="text-center font-weight-regular">
            {{ item.nonracik.date || item.racik.date }}
          </td>
        </template>
        <template v-slot:[`item.given`]="{ item }">
          <td class="text-center font-weight-regular">
            {{
              item.nonracik.given?.toUpperCase() ||
                item.racik.given?.toUpperCase()
            }}
          </td>
        </template>
        <template v-slot:[`item.quantity`]="{ item }">
          <td class="text-center font-weight-regular">
            {{ item.nonracik.quantity || item.racik.quantity }}
          </td>
        </template>
        <template v-slot:expanded-item="{ item, headers }">
          <td
            :colspan="headers.length"
            class="px-0"
            style="background-color: white;"
          >
            <v-row
              no-gutters
              class="expand-layout"
              v-for="(drug, idx) in item.drugs"
              :key="'drug-' + idx"
            >
              <span></span>
              <span></span>
              <label class="text-left caption grey--text">{{
                drug.drugName
              }}</label>
              <label class="text-center caption grey--text">{{
                drug.dosage
              }}</label>
              <label class="text-center caption grey--text">{{
                drug.preparation
              }}</label>
              <label class="text-center caption grey--text">{{
                drug.quantity
              }}</label>
            </v-row>
          </td>
        </template>
      </v-data-table>
      <p class="pa-0 ma-0 text-right grey--text caption">
        {{ selectedData.length + '/' + table.items.length }} Obat dipesan
      </p>
    </section>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        tile
        depressed
        width="150"
        class="text-none"
        @click="orderPharmacy()"
        >Simpan</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import Constant from '@/const';
// import moment from 'moment-timezone';
import Swal from 'sweetalert2';

const axios = require('axios');

export default {
  name: 'PharmacyOrder',
  props: {
    patientData: {
      type: Object,
      default: null,
    },
    idEmr: {
      type: String,
    },
    idStaffMixRecipe: {
      type: String,
    },
    idStaffRecipe: {
      type: String,
    },
    patientStatus: {
      type: String,
    },
    drugs: {
      type: Array,
      default: null,
    },
    allergy: {
      type: Array,
    },
  },

  data() {
    return {
      table: {
        headers: [
          {
            text: 'No',
            value: 'no',
            width: '2%',
            sortable: false,
          },
          {
            text: '',
            value: 'data-table-select',
            align: 'center',
          },
          {
            text: 'Nama Obat',
            value: 'drugName',
            width: '25%',
            sortable: false,
          },
          {
            class: 'text-sm-center text-left',
            text: 'Dosis',
            value: 'dosage',
            width: '7%',
            sortable: false,
          },
          {
            class: 'text-sm-center px-0',
            text: 'Aturan Pakai',
            value: 'usageRule',
            sortable: false,
            width: '7%',
          },
          {
            class: 'text-sm-center',
            text: 'Rute',
            value: 'route',
            sortable: false,
          },
          {
            class: 'text-sm-center px-0',
            text: 'Jenis Sediaan',
            value: 'preparation',
            width: '7%',
            sortable: false,
          },
          {
            class: 'text-sm-center px-0',
            text: 'Diresepkan oleh',
            value: 'staff',
            width: '10%',
            sortable: false,
          },
          {
            class: 'text-sm-center px-0',
            text: 'Waktu resep',
            value: 'date',
            width: '10%',
            sortable: false,
          },
          {
            class: 'text-sm-center',
            text: 'Tempat Diberikan',
            value: 'given',
            width: '12%',
            sortable: false,
          },
          {
            class: 'text-sm-center',
            text: 'Jumlah Dipesan',
            value: 'quantity',
            sortable: false,
          },
        ],
        items: [],
      },
      input: {
        drug: '',
      },
      selectedData: [],
    };
  },
  computed: {
    poly() {
      let result;
      const routeArray = this.$route.path.split('/');
      switch (routeArray[routeArray.length - 1]) {
        case 'klinik-igd':
          result = 'igd';
          break;
        case 'rawat-inap':
          result = 'rawat_inap';
          break;
      }
      return result;
    },
  },
  mounted() {
    this.table.items = this.drugs;
  },
  methods: {
    orderPharmacy() {
      const mix_recipe = this.selectedData
        .filter(data => data.racik.drugs)
        .map(arr => {
          return {
            date: arr.racik.date,
            description: arr.racik.description,
            drugs: arr.racik.drugs.map(x => {
              return {
                ...x,
                preparation: x.preparation,
              };
            }),
            _id: arr._id,
            isOrdered: true,
            name: arr.racik.drugName,
            general_rule: arr.racik.generalRules,
            route: arr.racik.route,
            packaging: arr.racik.packaging,
            special_instruction: arr.racik.special_instruction,
            is_extra_drugs: arr.isExtra,
            price: arr.racik.price,
            quantity: arr.racik.quantity,
            staff: this.idStaffMixRecipe,
            usage: arr.racik.usageRule || arr.racik.usage || arr.racik.dose,
          };
        });
      const recipe = this.selectedData
        .filter(data => data.nonracik.id_drugs)
        .map(arr => {
          return {
            _id: arr._id,
            isOrdered: true,
            date: arr.nonracik.date,
            description: arr.nonracik.description,
            dosage: +arr.nonracik.dosage,
            general_rule: arr.nonracik.generalRules,
            route: arr.nonracik.route,
            id_drugs: arr.nonracik.id_drugs,
            name: arr.nonracik.drugName,
            packaging: arr.nonracik.packaging,
            preparation: arr.nonracik.preparation,
            price: arr.nonracik.price,
            quantity: arr.nonracik.quantity,
            staff: this.idStaffRecipe,
            unit_dosage: arr.nonracik.unit_dosage,
            usage: arr.nonracik.usageRule,
            is_extra_drugs: arr.isExtra,
            special_instruction: arr.nonracik.special_instruction,
          };
        });
      const payload = {
        id_emr: this.idEmr,
        id_registration: this.patientData.id_registration,
        mix_recipe: mix_recipe,
        isTakeHome: false,
        recipe: recipe,
        medical_tools: [],
        patient_status: this.patientStatus,
        timestamps: {
          created_by: this.$store.getters.userLoggedIn.id,
        },
      };

      axios
        .post(
          Constant.apiUrl.concat(
            `/inpatient/drug/recomendation/${this.patientData.id_registration}?type=${this.poly}`,
          ),
          payload,
        )
        .then(() => {
          Swal.fire('Obat Berhasil Diorder!', '', 'success');
          this.$emit('close-dialog');
        })
        .catch(error => {
          if (error) {
            Swal.fire({
              icon: 'error',
              title: 'Gagal Order Obat.',
              text: 'Silahkan Ulangi.',
            });
          }
        });
    },
    deleteItem(item) {
      const index = this.table.items.indexOf(item);

      this.table.items.splice(index, 1);
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-data-table-header {
  background-color: #f4f5f5;
}
.expand-layout {
  display: grid;
  grid-template-columns: 6% 10% 15% 10% 10%;
  grid-template-rows: 5vh;
}
</style>
