<template>
  <v-card elevation="0">
    <v-snackbar
      class="snackbar"
      :timeout="6500"
      v-model="snackbar"
      tile
      color="red accent-2"
      v-if="!this.input.isRacik"
    >
      Stok obat {{ drug.name }} tidak mencukupi, tersisa
      {{ drug.quantity }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Tutup
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      height:100
      :timeout="3000"
      v-model="snackbar"
      tile
      class="text-center"
      color="red accent-2"
      style="right: 18vw"
      v-for="(item, index) in drugNotEnough"
      :key="index"
      :style="{ 'margin-bottom': calcMargin(index), top: calcTop(index) }"
    >
      Stock obat {{ item.name }} tidak mencukupi, tersisa {{ item.stock }}
    </v-snackbar>
    <v-card-title
      class="justify-space-between font-weight-medium primary--text py-0 pl-0 mb-2"
    >
      <span class="heading-5">Edit Obat</span>
      <div>
        <v-btn
          outlined
          class="red--text caption"
          max-height="30"
          @click="mapData()"
        >
          <font-awesome-icon icon="sync-alt" class="mt-0 mr-2" />
          <span class="font-weight-medium text-capitalize">Reset Form</span>
        </v-btn>
        <v-btn
          color="primary"
          class="ml-3 caption"
          @click="$emit('input-new')"
          depressed
          max-height="30"
        >
          <v-icon left small>mdi-plus</v-icon>
          <span class="font-weight-medium text-capitalize">Form Obat Baru</span>
        </v-btn>
      </div>
    </v-card-title>
    <v-card-text class="main-body">
      <v-container class="container-layout mr-0 pr-10">
        <v-row no-gutters>
          <v-col md="12">
            <v-row no-gutters>
              <v-col cols="6" class="detail-col black--text pt-0 pr-9">
                <v-row>
                  <v-col class="text-left pb-0" cols="5">
                    <label for="name"
                      >Nama Obat {{ input.isRacik ? 'Racik' : '' }}</label
                    >
                  </v-col>
                  <v-col v-if="!input.isRacik" cols="7" class="pt-2 px-0 pb-0">
                    <!-- @change="searchDrug()" -->
                    <v-combobox
                      placeholder="Masukkan Nama Obat"
                      dense
                      item-value="id_stock"
                      v-model="drug"
                      :items="resource.drugs"
                      :search-input.sync="searchDrug"
                      return-object
                      clearable
                      :loading="isLoading"
                    >
                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-layout>
                              <v-flex class="d-flex justify-start">
                                <span class="text-left">
                                  {{ `${data.item.text}` }}
                                </span>
                              </v-flex>
                            </v-layout>
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            <v-layout>
                              <span class="mr-4">
                                Exp:
                                {{ data?.item?.expiryDateFormatted }}
                              </span>
                              <span class="mr-4">
                                No Batch: {{ data?.item?.batchNumber }}
                              </span>
                              <span class="mr-4">{{
                                `Sisa Stok : ${data?.item?.quantity} ${data?.item?.packaging}`
                              }}</span>
                              <span>
                                {{ currency(data?.item?.pricePerItem) || 0 }}
                              </span>
                            </v-layout>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-combobox>
                    <!-- :search-input.sync="searchDrug" -->
                  </v-col>
                  <v-col v-else cols="7" class="pt-2 pa-0">
                    <v-text-field v-model="input.name"></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="input.isRacik" class="justify-end">
                  <v-card
                    color="#F0F9FF"
                    style="border: 0.5px solid #bdbdbd"
                    class="pa-0 pt-2 pr-2 mb-3 drugs-card"
                    flat
                  >
                    <v-flex
                      v-for="(drug, index) in input.drugs"
                      :key="'drug-' + index"
                      v-show="index + 1 === page"
                      pa-3
                    >
                      <v-row>
                        <v-col class="text-left py-1" cols="5">
                          <label>Nama Obat</label>
                        </v-col>
                        <v-col class="py-1 pr-0 pl-5">
                          <v-combobox
                            placeholder="Masukkan Nama Obat"
                            dense
                            append-icon="mdi-chevron-down"
                            v-model="drug.detail"
                            item-text="displayName"
                            item-value="id_stock"
                            :items="resource.drugs"
                            return-object
                            :loading="isLoading"
                            :search-input.sync="searchDrugs[index]"
                            @change="quantityCheck(index)"
                          >
                            <template v-slot:item="data">
                              <v-list-item-content>
                                <v-list-item-title>
                                  <v-layout>
                                    <v-flex class="d-flex justify-start">
                                      <span class="text-left">
                                        {{ `${data.item.displayName}` }}
                                      </span>
                                    </v-flex>
                                  </v-layout>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  <v-layout>
                                    <span class="mr-4">
                                      Exp:
                                      {{ data.item.expiryDateFormatted }}
                                    </span>
                                    <span class="mr-4">
                                      No Batch: {{ data.item.batchNumber }}
                                    </span>
                                    <span class="mr-4">{{
                                      `Sisa Stok : ${data.item.quantity} ${data.item.packaging}`
                                    }}</span>
                                    <span>{{
                                      currency(data.item.pricePerItem || 0)
                                    }}</span>
                                  </v-layout>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-combobox>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="text-left py-0" cols="5">
                          <label>Dosis</label>
                        </v-col>
                        <v-col class="pl-5 pr-0 py-0">
                          <v-text-field
                            id="dosage"
                            @keyup="
                              updateDrugQuantity({
                                dosage: drug.dosage_needed,
                                index,
                              })
                            "
                            dense
                            single-line
                            v-model.number="drug.dosage_needed"
                            :suffix="drug.strength_unit"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="text-left py-1" cols="5">
                          <label for="demanded">Jumlah Digunakan</label>
                        </v-col>
                        <v-col class="py-1 px-0 pl-5">
                          <v-text-field
                            id="demanded"
                            dense
                            single-line
                            v-model="drug.amount_requested"
                            :readonly="!isRecipeManual"
                            :suffix="drug.packaging"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="text-left py-1" cols="5">
                          <label for="given">Jumlah Ditagihkan</label>
                        </v-col>
                        <v-col class="py-1 px-0 pl-5">
                          <v-text-field
                            id="given"
                            :readonly="!isRecipeManual"
                            type="number"
                            v-model="drug.amount_given"
                            :suffix="drug.packaging"
                          />
                        </v-col>
                      </v-row>
                    </v-flex>
                    <v-card-actions style="position: relative">
                      <div v-if="input.drugs.length > 1" class="delete-card">
                        <v-icon
                          @click="deleteItem()"
                          class="delete-icon"
                          color="red"
                          center
                          small
                          >mdi-delete</v-icon
                        >
                      </div>
                      <v-btn
                        outlined
                        class="primary--text caption pa-0"
                        max-height="30"
                        style="min-width: 10%"
                        @click="addDrugs()"
                      >
                        <v-icon size="1" dense small center>mdi-plus</v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        outlined
                        class="primary--text caption pa-0"
                        max-height="30"
                        style="min-width: 9%"
                        @click="previous()"
                        :disabled="page === 1"
                      >
                        <v-icon center>mdi-chevron-left</v-icon>
                      </v-btn>
                      <span
                        class="font-weight-bold mx-2"
                        style="font-size: 0.8rem"
                        >{{ page }} / {{ input.drugs.length }}</span
                      >
                      <v-btn
                        outlined
                        class="primary--text caption pa-0"
                        max-height="30"
                        style="min-width: 9%"
                        @click="next()"
                        :disabled="page === input.drugs.length"
                      >
                        <v-icon center>mdi-chevron-right</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-row>
                <v-row v-if="!input.isRacik">
                  <v-col class="text-left pt-2" cols="5">
                    <label for="dosage">Dosis</label>
                  </v-col>
                  <v-col cols="7" class="pa-0">
                    <v-text-field
                      id="dosage"
                      v-model="drug.strength"
                      :suffix="drug.strength_unit"
                    />
                  </v-col>
                </v-row>
                <v-row v-if="!input.isRacik">
                  <v-col class="text-left py-0" cols="5">
                    <label for="demanded">Jumlah Diminta</label>
                  </v-col>
                  <v-col cols="7" class="pa-0">
                    <v-text-field
                      id="demanded"
                      dense
                      single-line
                      v-model="input.amount_requested"
                      readonly
                      :suffix="drug.packaging"
                    />
                  </v-col>
                </v-row>
                <v-row v-if="!input.isRacik">
                  <v-col class="text-left py-0 pt-1" cols="5">
                    <label for="given">Jumlah Diberikan</label>
                  </v-col>
                  <v-col class="pa-0 pt-1" cols="7">
                    <v-text-field
                      dense
                      single-line
                      id="given"
                      required
                      type="number"
                      v-model="input.amount_given"
                      :suffix="drug.packaging"
                    />
                  </v-col>
                </v-row>
                <v-row v-if="input.isRacik" class="mt-4">
                  <v-col class="text-left py-0" cols="5">
                    <label for="rules">Jumlah Diminta</label>
                  </v-col>
                  <v-col cols="3" class="pa-0">
                    <v-text-field
                      class="form-body__data mt-0"
                      dense
                      readonly
                      v-model="input.amount_requested"
                    />
                  </v-col>
                  <v-col></v-col>
                  <v-col cols="3" class="pa-0">
                    <v-select
                      class="form-body__data mt-0"
                      dense
                      append-icon="mdi-chevron-down"
                      :items="resource.packaging.racik"
                      v-model="input.packaging"
                      readonly
                    ></v-select
                  ></v-col>
                </v-row>
                <v-row v-if="input.isRacik" class="mt-4">
                  <v-col class="text-left pt-3" cols="5">
                    <label for="rules">Jumlah Diberikan</label>
                  </v-col>
                  <v-col cols="3" class="pt-2 pa-0">
                    <v-text-field
                      class="form-body__data mt-0"
                      dense
                      v-model="input.amount_given"
                      @keyup="updateDrugQuantity({ index: undefined })"
                    />
                  </v-col>
                  <v-col></v-col>
                  <v-col cols="3" class="pt-2 pa-0">
                    <v-combobox
                      class="form-body__data mt-0"
                      dense
                      auto-select-first
                      clearable
                      append-icon="mdi-chevron-down"
                      :items="resource.packaging.racik"
                      v-model="input.packaging"
                    ></v-combobox
                  ></v-col>
                </v-row>

                <v-row v-if="!input.isRacik">
                  <v-col class="text-left" cols="4">
                    <label for="rules">Aturan Pakai</label>
                  </v-col>
                  <v-col cols="8" class="pa-0">
                    <!-- <v-text-field id="rules" required v-model="input.usage" /> -->
                    <v-row no-gutters>
                      <v-col class="input-usage__checkbox" cols="2">
                        <v-radio-group v-model="input.radio_usage">
                          <v-radio class="mb-5" :value="0"></v-radio>
                          <v-radio :value="1"></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col class="input-usage__data" cols="10">
                        <div class="usage-rule">
                          <v-combobox
                            :disabled="input.radio_usage !== 0"
                            class="form-body__data pt-0 mt-0"
                            append-icon="mdi-chevron-down"
                            dense
                            auto-select-first
                            v-model="input.recipe.usage.autoInput.day"
                            :items="resource.usageOne"
                          ></v-combobox>
                          <span>x</span>
                          <v-combobox
                            :disabled="input.radio_usage !== 0"
                            append-icon="mdi-chevron-down"
                            class="form-body__data pt-0 mt-0 mb-5"
                            dense
                            auto-select-first
                            :items="resource.usageTwo"
                            v-model="input.recipe.usage.autoInput.usage"
                          ></v-combobox>
                          <v-combobox
                            :disabled="input.radio_usage !== 0"
                            class="form-body__data pt-0 mt-0 mb-5"
                            dense
                            auto-select-first
                            append-icon="mdi-chevron-down"
                            clearable
                            :items="resource.packaging.nonRacik"
                            v-model="input.recipe.usage.autoInput.package"
                          >
                          </v-combobox>
                        </div>
                        <div class="usage-rule2">
                          <v-text-field
                            :disabled="input.radio_usage !== 1"
                            class="form-body__data mt-0"
                            dense
                            v-model="input.recipe.usage.manualInput.usage"
                          ></v-text-field>
                          <v-combobox
                            :disabled="input.radio_usage !== 1"
                            class="form-body__data pt-0 mt-0 mb-5"
                            dense
                            auto-select-first
                            append-icon="mdi-chevron-down"
                            clearable
                            :items="resource.packaging.nonRacik"
                            v-model="input.recipe.usage.manualInput.package"
                          >
                          </v-combobox>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row v-else class="mt-3">
                  <v-col class="text-left pt-2" cols="5">
                    <label for="rules">Aturan Pakai</label>
                  </v-col>
                  <v-col cols="7" class="pa-0">
                    <!-- <v-text-field id="rules" required v-model="input.usage" /> -->
                    <v-row no-gutters>
                      <v-col class="input-usage__data py-0" cols="12">
                        <div class="usage-rule">
                          <v-combobox
                            :disabled="input.radio_usage !== 0"
                            class="form-body__data pt-0 mt-0"
                            append-icon="mdi-chevron-down"
                            dense
                            auto-select-first
                            v-model="input.mix_recipe.usage.autoInput.day"
                            :items="resource.usageOne"
                          ></v-combobox>
                          <span>x</span>
                          <v-combobox
                            :disabled="input.radio_usage !== 0"
                            append-icon="mdi-chevron-down"
                            class="form-body__data pt-0 mt-0 mb-5"
                            dense
                            auto-select-first
                            :items="resource.usageTwo"
                            v-model="input.mix_recipe.usage.autoInput.usage"
                          ></v-combobox>
                          <v-combobox
                            :disabled="input.radio_usage !== 0"
                            class="form-body__data pt-0 mt-0 mb-5"
                            dense
                            auto-select-first
                            clearable
                            append-icon="mdi-chevron-down"
                            :items="resource.packaging.racik"
                            v-model="input.mix_recipe.usage.autoInput.package"
                          >
                          </v-combobox>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="text-left pt-4" cols="5">
                    <label for="rules">Aturan Umum</label>
                  </v-col>
                  <v-col cols="7" class="pa-0 pt-3">
                    <v-autocomplete
                      append-icon="mdi-chevron-down"
                      placeholder="Pilih Aturan Umum"
                      class="form-body__data mt-0"
                      dense
                      :items="resource.general_rules"
                      v-model="input.general_rule"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="text-left pt-3" cols="5">
                    <label for="route">Rute</label>
                  </v-col>
                  <v-col cols="7" class="pa-0 pt-3">
                    <v-combobox
                      :items="resource.route"
                      placeholder="Pilih Rute"
                      append-icon="mdi-chevron-down"
                      dense
                      auto-select-first
                      v-model="input.route"
                    ></v-combobox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-left pt-2" cols="5">
                    <label for="detail">Keterangan</label>
                  </v-col>
                  <v-col cols="7" class="pa-0">
                    <v-text-field id="detail" v-model="input.description" />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6" class="detail-col text-left black--text pt-0">
                <div class="d-flex">
                  <span>Resep ODD</span
                  ><v-switch
                    class="ml-5 mt-n1"
                    v-model="input.isOdd"
                  ></v-switch>
                </div>
                <div class="d-flex">
                  <div>
                    <v-row
                      v-for="(time, index) in resource.schedule.today"
                      :key="'time-' + index"
                    >
                      <v-col class="py-0">
                        <v-checkbox
                          dense
                          single-line
                          :label="time.time"
                          :disabled="!input.isOdd"
                          v-model="input.time_delivery.today[index].check"
                          class="mt-0 pt-0"
                          :class="{ 'pt-4': index === 0 }"
                        ></v-checkbox>
                      </v-col>
                      <v-col class="py-0" :class="{ 'pt-4': index === 0 }">
                        <v-text-field
                          dense
                          single-line
                          :placeholder="time.placeHolder"
                          v-model="input.time_delivery.today[index].time"
                          :disabled="!input.time_delivery.today[index].check"
                          :readonly="input.time_delivery.today[index].check"
                        />
                      </v-col>
                    </v-row>
                  </div>
                  <div>
                    <v-row
                      v-for="(time, index) in resource.schedule.nextDay"
                      :key="'time-' + index"
                    >
                      <v-col class="py-0">
                        <v-checkbox
                          dense
                          single-line
                          :label="time.time"
                          :disabled="!input.isOdd"
                          v-model="input.time_delivery.nextDay[index].check"
                          class="mt-0 pt-0"
                          :class="{ 'pt-4': index === 0 }"
                        ></v-checkbox>
                      </v-col>
                      <v-col class="py-0" :class="{ 'pt-4': index === 0 }">
                        <v-text-field
                          dense
                          single-line
                          :placeholder="time.placeHolder"
                          v-model="input.time_delivery.nextDay[index].time"
                          :disabled="!input.time_delivery.nextDay[index].check"
                          :readonly="input.time_delivery.nextDay[index].check"
                        />
                      </v-col>
                    </v-row>
                  </div>
                </div>
                <v-row class="mb-2">
                  <v-col cols="12" class="py-2">
                    <span class="py-0 my-0">Alasan Konfirmasi</span>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-textarea
                      placeholder="Isi Alasan Konfirmasi"
                      no-resize
                      dense
                      single-line
                      v-model="input.change_note"
                      background-color="white"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
                <v-row class="px-3 mb-4">
                  <v-col class="drug-price" cols="12">
                    <div class="d-flex">
                      <v-icon class="mr-2">mdi-shopping-outline</v-icon>
                      <span>Harga Obat</span>
                    </div>
                    <span>
                      {{
                        !input.isRacik
                          ? currency(
                              drug.pricePerItem * input.amount_given || 0,
                            )
                          : currency(
                              input.drugs
                                .filter(drug => drug.detail !== '')
                                .reduce(
                                  (a, drug) =>
                                    a +
                                    drug.detail.pricePerItem *
                                      drug.amount_given,
                                  0,
                                ) || 0,
                            )
                      }}
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="5">
                    <v-btn
                      color="primary"
                      outlined
                      class="text-capitalize button"
                      width="153"
                      @click="saveData()"
                      depressed
                      :disabled="!save"
                      :loading="isSaveLoading"
                    >
                      <v-icon small>mdi-edit</v-icon><span>Simpan</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import Constant from '@/const';
import Swal from 'sweetalert2';
import moment from 'moment-timezone';
import alertMixin from '@/mixin/alertMixin';
import formatMixin from '@/mixin/formatMixin';

const _ = require('lodash');
const axios = require('axios');

export default {
  name: 'DrugRequestEdit',
  mixins: [formatMixin, alertMixin],
  props: {
    drugDetail: Object,
    patient: Object,
    isTakeHome: Boolean,
  },
  data() {
    return {
      isSaveLoading: false,
      isLoading: false,
      dialog: false,
      save: true,
      old_id_drug: '', // identifier non racik
      old_name_recipe: '', // identifier nama non racik
      old_dosage: '', // identifier nama non racik
      old_unit_dosage: '', // identifier nama non racik
      old_name_mix_recipe: '', //identifier racik
      snackbar: false,
      drugNotEnough: [],
      resource: {
        usageOne: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
        usageTwo: ['1/4', '1/3', '1/2', '2/3', '3/4', '1', '2', '3', '4', '5'],
        packaging: {
          nonRacik: [
            'Bungkus',
            'Hisap',
            'Kapsul',
            'Kumur',
            'ml',
            'mg',
            'Oleskan',
            'Semprot',
            'Sendok Makan',
            'Sendok Obat',
            'Sendok Sup',
            'Sendok Teh',
            'Sirup Kering',
            'Tablet',
            'Tetes Oral',
            'Ampul',
            'gr',
            'Ovula',
            'Patch',
            'Rectal Tube',
            'pcs',
            'TPM',
            'Unit',
            'Flexpen',
            'Vial',
          ],
          racik: [
            'Sirup',
            'Salep',
            'Bungkus',
            'Kapsul',
            'Tube',
            'Botol',
            'Tablet',
          ],
        },
        general_rules: [
          'Sebelum makan',
          '1 jam setelah makan',
          '½ jam sebelum makan',
          '2 jam  sesudah makan',
          'Sebelum suapan pertama',
          'Bersama atau tanpa makan',
          'Bersama makan',
          'Kunyah ',
          'Kunyah bersama makan',
          'Sebelum atau sesudah makan',
          'Sesudah makan',
          'Setelah suapan pertama',
          'Taruh diatas lidah',
          'Taruh dibawah lidah ',
        ],
        route: [
          'Per Oral',
          'Sublingual',
          'Per Vagina',
          'Per Rectal',
          'Intravena',
          'Intramuscular',
          'Subcutan',
          'Okular (mata)',
          'Otic (telinga)',
          'Nasal (hidung)',
          'Inhalasi',
          'Transdermal',
          'Intratekal',
          'Topikal',
          'Intrakutan',
        ],
        schedule: {
          today: [
            { time: 'Pagi', placeHolder: '05:30' },
            { time: 'Siang', placeHolder: '13:30' },
            { time: 'Sore', placeHolder: '17:30' },
            { time: 'Malam', placeHolder: '21:30' },
          ],
          nextDay: [
            { time: 'Pagi', placeHolder: '05:30' },
            { time: 'Siang', placeHolder: '13:30' },
            { time: 'Sore', placeHolder: '17:30' },
            { time: 'Malam', placeHolder: '21:30' },
          ],
        },
        drugs: [],
      },
      page: 1,
      input: {
        mix_recipe: {
          usage: {
            autoInput: {
              day: '',
              usage: '',
              package: '',
            },
            manualInput: {
              usage: '',
              package: '',
            },
          },
        },
        recipe: {
          usage: {
            autoInput: {
              day: '',
              usage: '',
              package: '',
            },
            manualInput: {
              usage: '',
              package: '',
            },
          },
        },
        radio_usage: 0,
        isOdd: false,
        name: '',
        dosage: '',
        drugs: [
          {
            detail: '',
            dosage: '',
            unit_dosage: '',
            amount_requested: '',
            amount_given: '',
          },
        ],
        amount_requested: 0,
        amount_given: '',
        usage: '',
        route: '',
        description: '',
        special_instruction: '',
        change_note: '',
        is_extra_drugs: false,
        isRacik: null,
        time_delivery: {
          today: [
            {
              check: false,
              menu: false,
              isNextDay: false,
              name: 'pagi',
              time: '05:30',
            },
            {
              check: false,
              menu: false,
              isNextDay: false,
              name: 'siang',
              time: '13:30',
            },
            {
              check: false,
              menu: false,
              name: 'sore',
              isNextDay: false,
              time: '17:30',
            },
            {
              check: false,
              menu: false,
              name: 'malam',
              isNextDay: false,
              time: '21:30',
            },
          ],
          nextDay: [
            {
              check: false,
              menu: false,
              isNextDay: true,
              name: 'pagi',
              time: '05:30',
            },
            {
              check: false,
              menu: false,
              name: 'siang',
              isNextDay: true,
              time: '13:30',
            },
            {
              check: false,
              menu: false,
              isNextDay: true,
              name: 'sore',
              time: '17:30',
            },
            {
              check: false,
              menu: false,
              isNextDay: true,
              name: 'malam',
              time: '21:30',
            },
          ],
        },
      },
      drug: {
        displayName: '',
        name: '',
        id: '',
        strength: '',
        strength_unit: '',
      },
      searchDrug: null,
      searchDrugs: [],
      schedule: [],
    };
  },
  computed: {
    route() {
      return this.$route.path;
    },
    routeSplit() {
      return this.$route.path.split('/');
    },
    routeController() {
      return this.routeSplit[2];
    },
    routeName() {
      switch (this.routeController) {
        case 'rawat-inap':
          return 'rawat_inap';

        case 'rawat-jalan':
          return 'rawat_jalan';

        case 'igd':
          return 'igd';

        default:
          return '';
      }
    },
    isRecipeManual() {
      return (
        this.$store.getters.userLoggedIn.config.pharmacy_mix_recipe_input ===
        'manual'
      );
    },
  },
  mounted() {
    this.resetAll();
  },
  watch: {
    drugDetail(value) {
      if (value) {
        this.resetAll();
      }
    },
    searchDrug(value) {
      if (value) {
        this.searchDataDrug(this);
      }
    },
    drug(value) {
      if (value) {
        if (this.input.amount_requested > value.quantity) {
          this.save = false;
          this.snackbar = true;
        }
        if (this.input.amount_requested <= value.quantity) {
          this.save = true;
        }
        this.input = {
          ...this.input,
          id_drugs: value.id,
          name: value.name,
          text: value.name,
          strength: value.strength,
          strength_unit: value.strength_unit,
        };
      }
    },
    searchDrugs(values) {
      if (values) {
        this.getAllDrugs(values[this.page - 1]);
      }
    },
  },
  methods: {
    searchDataDrug: _.debounce(v => v.getAllDrugs(v.searchDrug), 650),
    calcMargin(i) {
      return i * 60 + 'px';
    },
    calcTop(i) {
      return -68 + i * 8 + 'vh';
    },
    quantityCheck(index) {
      this.drugNotEnough = [];
      this.input.drugs[index].dosage_needed = +this.input.drugs[index].detail
        .strength;
      this.updateDrugQuantity({
        dosage: this.input.drugs[index].dosage_needed,
        index,
      });
      if (
        this.input.drugs[index].detail.quantity <
        this.input.drugs[index].amount_given
      ) {
        this.save = false;
        this.drugNotEnough.push({
          name: this.input.drugs[index].detail.name,
          quantity: this.input.drugs[index].detail.quantity,
        });
        this.snackbar = true;
      } else {
        this.save = true;
        this.snackbar = false;
      }
    },
    updateDrugQuantity({ dosage, index }) {
      if (isNaN(index)) {
        const newArray = this.input.drugs.map(drug => {
          return {
            ...drug,
            amount_requested:
              (drug.dosage_needed * +this.input.amount_given) /
              +drug.detail.strength,
            amount_given: Math.ceil(
              (drug.dosage_needed * +this.input.amount_given) /
                +drug.detail.strength,
            ),
          };
        });
        this.input.drugs = newArray;
      } else {
        const newObjectValue = {
          ...this.input.drugs[index],
          amount_requested:
            (dosage * +this.input.amount_given) /
            +this.input.drugs[index].detail.strength,
          amount_given: Math.ceil(
            (dosage * +this.input.amount_given) /
              +this.input.drugs[index].detail.strength,
          ),
        };
        this.input.drugs[index] = newObjectValue;
      }
      this.input = {
        ...this.input,
        drugs: this.input.drugs,
      };
    },
    deleteItem() {
      this.input.drugs.splice(this.page - 1, 1);
      if (this.page === 1) {
        this.page = 1;
      } else {
        this.page -= 1;
      }
    },
    resetAll() {
      this.mapData();
    },
    next() {
      this.page += 1;
    },
    previous() {
      this.page -= 1;
    },
    getAllDrugs(keyword) {
      this.isLoading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/stock/all/names?page=1&itemCount=30&category=Obat&name=${
              !keyword ? '' : keyword
            }`,
          ),
        )
        .then(response => {
          const drug = response.data.data;
          this.resource.drugs = drug.map(arr => {
            return {
              id: arr.id_drugs, // id drugs
              id_stock: arr._id, // id stock
              batchNumber: arr.batch_number,
              name: arr.drugs.detail.name,
              text: `${arr.drugs.detail.name} ${arr.drugs.detail.strength} ${arr.drugs.detail.strength_unit} (${arr.drugs.detail.factory})`,
              displayName: `${arr.drugs.detail.name} ${arr.drugs.detail.strength} ${arr.drugs.detail.strength_unit} (${arr.drugs.detail.factory})`,
              strength_unit: arr.drugs.detail.strength_unit,
              strength: arr.drugs.detail.strength,
              preparation: arr.drugs.detail.preparation,
              packaging: arr.package,
              pricePerItem: arr.price,
              quantity: arr.quantity,
              expiry: arr.expiry_date,
              expiryDateFormatted: moment(arr.expiry_date, 'YYYY-MM-DD').format(
                'DD-MM-YYYY',
              ),
            };
          });
          this.drug = this.resource.drug.filter(arr => {
            return this.drug.name === arr.name;
          });
        })
        .catch(() => {
          this.isLoading = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async getDrugById(id) {
      const response = await axios.get(
        Constant.apiUrl.concat(`/master/drugs/${id}`),
      );

      const drug = response.data.data;
      if (drug) {
        this.drug = {
          id: drug._id,
          name: drug.detail.name,
          displayName: drug.detail.name,
          strength_unit: drug.detail.strength_unit,
          strength: drug.detail.strength,
        };
      }
    },
    async mapData() {
      const data = this.drugDetail;
      const { isRacik } = data;

      if (isRacik) {
        this.input.name = data.name;
        this.old_name_mix_recipe = data.name;
      } else {
        this.old_id_drug = data.drugs._id;
        this.old_name_recipe = data.drug_name;
        this.old_dosage = data.dosage;
        this.old_unit_dosage = data.unit_dosage;
        this.drug = {
          id: data.drugs._id,
          id_stock: data?.id_stock,
          name: data.drug_name,
          text: data.drugs.name,
          displayName: data.drugs.name,
          strength_unit: data.unit_dosage,
          strength: data.dosage,
          price: data.price,
          pricePerItem: data.pricePerItem,
          packaging: data.packaging,
          preparation: data.preparation,
          quantity: data.remainingStock,
        };
      }
      this.input = {
        ...this.input,
        _id: data.id,
        dosage: data.dosage,
        unit_dosage: data.unit_dosage,
        amount_requested: data.requested_quantity,
        price: data.price,
        pricePerItem: data.pricePerItem,
        description: data.description,
        isRacik: data.isRacik,
        preparation: data.preparation,
        recipe_number: data.recipe_number,
        packaging: data.packaging,
        staff: data.staff,
        change_note: data.change_note,
        isOdd: data.isOdd,
        amount_given: data.quantity,
        route: data.route,
        general_rule: data.general_rule,
        is_extra_drugs: data.is_extra_drugs,
        special_instruction: data.special_instruction,
        time_delivery: {
          today:
            data.time_delivery.length === 8
              ? data.time_delivery
                  .filter(
                    time =>
                      time.time === 'pagi' ||
                      time.time === 'siang' ||
                      time.time === 'sore' ||
                      time.time === 'malam',
                  )
                  .map(t => {
                    return {
                      check: t.check,
                      menu: t.menu,
                      isNextDay: false,
                      name: t.time,
                      time: t.value,
                    };
                  })
              : [
                  {
                    check: false,
                    menu: false,
                    isNextDay: false,
                    name: 'pagi',
                    time: '05:30',
                  },
                  {
                    check: false,
                    menu: false,
                    isNextDay: false,
                    name: 'siang',
                    time: '13:30',
                  },
                  {
                    check: false,
                    menu: false,
                    name: 'sore',
                    isNextDay: false,
                    time: '17:30',
                  },
                  {
                    check: false,
                    menu: false,
                    name: 'malam',
                    isNextDay: false,
                    time: '21:30',
                  },
                ],
          nextDay:
            data.time_delivery.length === 8
              ? data.time_delivery
                  .filter(
                    time =>
                      time.time === 'pagi_selanjutnya' ||
                      time.time === 'siang_selanjutnya' ||
                      time.time === 'sore_selanjutnya' ||
                      time.time === 'malam_selanjutnya',
                  )
                  .map(t => {
                    return {
                      check: t.check,
                      menu: t.menu,
                      isNextDay: true,
                      name: t.time.split('_')[0],
                      time: t.value,
                    };
                  })
              : [
                  {
                    check: false,
                    menu: false,
                    isNextDay: true,
                    name: 'pagi',
                    time: '05:30',
                  },
                  {
                    check: false,
                    menu: false,
                    name: 'siang',
                    isNextDay: true,
                    time: '13:30',
                  },
                  {
                    check: false,
                    menu: false,
                    isNextDay: true,
                    name: 'sore',
                    time: '17:30',
                  },
                  {
                    check: false,
                    menu: false,
                    isNextDay: true,
                    name: 'malam',
                    time: '21:30',
                  },
                ],
        },
      };

      if (isRacik) {
        if (data.usage.split(' ').length >= 4) {
          this.input.mix_recipe.usage.autoInput = {
            day: data.usage.split(' ')[0],
            usage: data.usage.split(' ')[2],
            package: `${data.usage.split(' ')[3]} ${data.usage.split(' ')[4] ||
              ''}`,
          };
        } else {
          this.input.mix_recipe.usage.manualInput = {
            usage: data.usage.split(' ').slice(0, -1),
            packaging: data.usage.split(' ').pop(),
          };
        }
        data.drugs.forEach(async (drug, index) => {
          const drugs = drug.id_drugs;
          this.input.drugs[index] = {
            detail: {
              displayName: drugs.name,
              text: drugs.name,
              name: drug.name,
              id: drugs._id,
              strength: drug.dosage,
              strength_unit: drug.unit_dosage,
              packaging: drug.preparation,
              preparation: drug.preparation,
              price: drug.price,
              pricePerItem: drug.pricePerItem,
              quantity: drug.availability_stock,
              id_stock: drug?.id_stock,
            },
            id: drug._id,
            strength: drug.dosage_needed,
            dosage_needed: drug.dosage_needed,
            strength_unit: drug.unit_dosage,
            amount_requested: drug.requested_quantity,
            amount_given: drug.quantity,
            packaging: drug.preparation,
            preparation: drug.preparation,
            price: drug.price,
            pricePerItem: drug.pricePerItem,
          };
        });
      } else {
        if (data.usage_type) {
          this.input.radio_usage = 0;
          this.input.recipe.usage.autoInput = {
            day: data.usage.split(' ')[0],
            usage: data.usage.split(' ')[2],
            package: `${data.usage.split(' ')[3]} ${data.usage.split(' ')[4] ||
              ''}`,
          };
        } else {
          this.input.radio_usage = 1;
          this.input.recipe.usage.manualInput = {
            usage: data.usage.split(' ')[0],
            package: `${data.usage.split(' ')[1]} ${data.usage.split(' ')[2] ||
              ''}`,
          };
        }

        this.input = {
          ...this.input,
        };
      }
    },
    addDrugs() {
      this.input.drugs.push({
        detail: '',
        dosage: '',
        unit_dosage: '',
        amount_requested: '',
        amount_given: '',
      });
      this.page = this.input.drugs.length;
    },
    saveData() {
      const id_recipe = this.patient.id_recipe;

      if (this.input.isRacik) {
        const payload = {
          _id: this.input._id,
          old_name: this.old_name_mix_recipe,
          name: this.input.name,
          isOrdered: true,
          isOdd: this.input.isOdd,
          drugs: this.input.drugs.map(drug => {
            return {
              id_drugs: drug.detail.id,
              id_stock: drug.detail?.id_stock,
              name: drug.detail?.name,
              preparation: drug.detail.preparation,
              dosage: drug.detail.strength,
              dosage_needed: drug.dosage_needed,
              unit_dosage: drug.detail.strength_unit,
              price: drug.detail.pricePerItem,
              expiry: drug.detail.expiry,
              packaging: drug.detail.packaging,
              availability_stock: drug.detail.stock,
              requested_quantity: drug.amount_requested,
              quantity: drug.amount_given,
            };
          }),
          quantity: parseInt(this.input.amount_given) || 0,
          requested_quantity: parseInt(this.input.amount_requested),
          packaging: this.input.packaging,
          description: this.input.description,
          usage:
            this.input.radio_usage === 0
              ? `${this.input.mix_recipe.usage.autoInput.day} x ${this.input.mix_recipe.usage.autoInput.usage} ${this.input.mix_recipe.usage.autoInput.package}`
              : `${this.input.mix_recipe.usage.manualInput.usage} ${this.input.mix_recipe.usage.manualInput.package}`,
          price: this.input.drugs.reduce(
            (a, { detail }) => a + detail.price,
            0,
          ),
          general_rule: this.input.general_rule,
          date: moment(new Date())
            .locale('id')
            .format(),
          recipe_number: this.input.recipe_number,
          staff: this.input.staff,
          change_note: this.input.change_note,
          special_instruction: this.input.special_instruction,
          is_extra_drugs: this.input.is_extra_drugs,
          staff_approve: this.$store.getters.userLoggedIn.name,
          route: this.input.route,
          time_delivery: this.input.time_delivery.today.concat(
            this.input.time_delivery.nextDay,
          ),
        };

        this.isSaveLoading = true;
        axios
          .put(
            Constant.apiUrl.concat(
              `/patient/recipe/${id_recipe}?type=${this.routeName}&isTakeHome=${this.isTakeHome}`,
            ),
            {
              mix_recipe: payload,
            },
          )
          .then(response => {
            if (response.status === 200) {
              this.$emit('close-form');
              Swal.fire(
                'Data Permintaan Obat dan Alkes Tersimpan',
                '',
                'success',
              );
            }
            this.isSaveLoading = false;
          })
          .catch(error => {
            this.showErrorAxios(error);
            this.isSaveLoading = false;
          });
      } else {
        const payload = {
          old_id_drug: this.old_id_drug,
          old_name: this.old_name_recipe,
          old_dosage: this.old_dosage,
          old_unit_dosage: this.old_unit_dosage,
          _id: this.input._id,
          id_stock: this.drug.id_stock,
          id_drugs: this.drug.id,
          preparation: this.drug.preparation,
          isOrdered: true,
          dosage: this.drug.strength,
          unit_dosage: this.drug.strength_unit,
          quantity: parseInt(this.input.amount_given),
          requested_quantity: parseInt(this.input.amount_requested),
          expiry: this.drug.expiry,
          isOdd: this.input.isOdd,
          packaging: this.drug.packaging,
          recipe_number: this.input.recipe_number,
          description: this.input.description,
          general_rule: this.input.general_rule,
          usage:
            this.input.radio_usage === 0
              ? `${this.input.recipe.usage.autoInput.day} x ${this.input.recipe.usage.autoInput.usage} ${this.input.recipe.usage.autoInput.package}`
              : `${this.input.recipe.usage.manualInput.usage} ${this.input.recipe.usage.manualInput.package}`,
          name: this.drug.name,
          price: this.drug.pricePerItem,
          date: moment(new Date())
            .locale('id')
            .format(),
          staff: this.input.staff,
          change_note: this.input.change_note,
          is_extra_drugs: this.input.is_extra_drugs,
          special_instruction: this.input.special_instruction,
          staff_approve: this.$store.getters.userLoggedIn.name,
          route: this.input.route,
          time_delivery: this.input.time_delivery.today.concat(
            this.input.time_delivery.nextDay,
          ),
        };
        axios
          .put(
            Constant.apiUrl.concat(
              `/patient/recipe/${id_recipe}?type=${this.routeName}&isTakeHome=${this.isTakeHome}`,
            ),
            {
              recipe: payload,
            },
          )
          .then(response => {
            if (response.status === 200) {
              this.$emit('close-form');
              Swal.fire(
                'Data Permintaan Obat dan Alkes Tersimpan',
                '',
                'success',
              );
            }
            this.isSaveLoading = false;
          })
          .catch(error => {
            this.showErrorAxios(error);
            this.isSaveLoading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.drugs-card {
  width: 50vw !important;
}
.snackbar {
  top: -68vh;
  right: 18vw;
}
::v-deep .v-snack__content {
  padding: 6px 10px !important;
}
.drug-price {
  display: flex;
  justify-content: space-between;
  background-color: #f0f9ff;
  border-radius: 4px;
  border: 0.5px solid #bdbdbd;
}
.main-body {
  background-color: #deeef9;
}
.form-body {
  &__data {
    height: 20px;
  }
}

.usage-rule {
  display: grid;
  grid-template-columns: 1fr 0.5fr 1fr 2fr;
  column-gap: 10px;
  span {
    padding-top: 9px;
  }
}

.usage-rule2 {
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 10px;
}

.input-usage {
  &__data {
    padding: 11px 0px;
  }
  &__checkbox {
    padding-left: 0px;
  }
}
.container-layout {
  max-width: 1250px;
  max-height: auto;
  // overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 3px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: rgb(204, 202, 202);
  }
}
.detail-col {
  padding-right: 12px;
  padding-top: 5px;

  h3 {
    text-align: left;
  }
}

.confirmation {
  padding-left: 10px;
  padding-top: 20px;

  h3 {
    padding-bottom: 30px;
    text-align: left;
  }
}
.delete-card {
  border: solid 1px red;
  padding-top: 2px;
  margin-right: 2%;
  min-height: 30px;
  min-width: 10%;
  border-radius: 0.3rem;
  cursor: pointer;
}
.delete-icon:hover {
  animation: vibrate 0.25s ease-in-out;
}

@keyframes vibrate {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(40deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-40deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>
