<template>
  <v-card class="px-10">
    <v-card-title class="justify-center">
      <h4>Input Mutasi Barang</h4>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col class="text-left" cols="4">
          <label class="black--text font-weight-bold">Dipesan dari</label>
          <v-combobox
            placeholder="Pilih Unit Tujuan"
            :items="resource.unit"
            item-text="name"
            dense
            style="width: 50%;"
            class="mt-3"
          ></v-combobox>
        </v-col>
        <v-col>
          <v-text-field
            placeholder="Nama Barang"
            append-icon="mdi-magnify"
            dense
            style="width: 50%;"
            class="mt-8"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-data-table :headers="table.headersRequest"></v-data-table>
      </v-row>

      <v-row>
        <v-col class="text-left" style="display: grid;" cols="4">
          <label class="black--text font-weight-bold">Mutasi ke :</label>
          <label class="mt-3">Farmasi IGD</label>
        </v-col>
        <v-col>
          <v-text-field
            placeholder="Nama Barang"
            append-icon="mdi-magnify"
            dense
            style="width: 50%;"
            class="mt-8"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-data-table :headers="table.headersMutation"></v-data-table>
      </v-row>
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-btn text class="text-none" @click="$emit('close-form')">Kembali</v-btn>
      <v-btn tile depressed color="primary" class="text-none">Simpan</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Constant from '@/const';

const axios = require('axios');

export default {
  name: 'InputMutation',
  data() {
    return {
      resource: {
        unit: [],
      },
      table: {
        headersRequest: [
          { text: 'No', value: 'no' },
          { text: 'Nama Barang', value: 'goodsName' },
          { text: 'Expired Date', value: 'expiredDate' },
          { text: 'Batch', value: 'batch' },
          { text: 'Sisa', value: 'remain' },
          { text: 'Package', value: 'package' },
          { text: '', value: 'add' },
        ],
        itemRequest: [],
        headersMutation: [
          { text: 'No', value: 'no' },
          { text: 'Nama Barang', value: 'goodsName' },
          { text: 'Expired Date', value: 'expiredDate' },
          { text: 'Batch', value: 'batch' },
          { text: 'Package', value: 'package' },
          { text: 'Jumlah Pesan', value: 'amount' },
          { text: '', value: 'delete' },
        ],
        itemMutation: [],
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getUnit();
    });
  },
  methods: {
    getUnit() {
      axios
        .get(
          Constant.apiUrl.concat(
            '/master/unit?page=1&itemCount=&search=&sort=name',
          ),
        )
        .then(response => {
          this.resource.unit = response.data.data;
        });
    },
  },
};
</script>

<style></style>
