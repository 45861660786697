<template>
  <v-card flat>
    <v-card-title>
      <v-row v-if="!isFromServiceDashboard" class="mb-4 pl-10">
        <v-col cols="2" class="text-left">
          <label class="body-2 grey--text mr-2">No. RM</label>
          <label class="body-2 black--text font-weight-bold">{{
            patientData?.rmNumber || socialData.rmNumber
          }}</label>
        </v-col>
        <v-col cols="1" class="text-right" style="max-width: 70px">
          <label class="body-2 grey--text">Nama</label>
        </v-col>
        <v-col cols="2" class="text-left">
          <label class="body-2 black--text font-weight-bold"
            >{{ patientData?.meta?.name || socialData.name }} ({{
              patientData?.meta?.gender === 'Laki-laki' || socialData.gender
                ? 'L'
                : 'P'
            }})</label
          >
        </v-col>
        <v-col cols="2" class="text-right" style="max-width: 120px">
          <label class="body-2 grey--text">Tanggal Lahir</label>
        </v-col>
        <v-col cols="2" class="text-left">
          <label class="body-2 black--text font-weight-bold"
            >{{ patientData?.meta?.birthDate || socialData.birthDate }} ({{
              patientData?.meta?.age || socialData.age
            }}
            tahun)</label
          >
        </v-col>
        <v-col class="text-right">
          <h3>Pembuatan <i>General Consent</i></h3>
        </v-col>
        <v-col cols="1" class="pt-0" style="max-width: 50px">
          <v-btn icon @click="onCloseButtonClick()">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <div class="d-flex" v-else>
        <h4 class="pl-13 mr-3">Formulir General Consent</h4>
        <v-chip :class="`chip-${input.status.class}`">{{
          input.status.text
        }}</v-chip>
      </div>
    </v-card-title>
    <v-card-text>
      <v-container
        fluid
        :class="isFromServiceDashboard ? 'card-layout-service' : 'card-layout'"
      >
        <v-form lazy-validation ref="form">
          <v-row class="pa-5">
            <v-col cols="6">
              <h2 class="text-left pl-5 primary--text mb-2">
                Identitas
              </h2>
              <div class="pl-5">
                <v-row class="align-center pt-2">
                  <v-col cols="4" class="text-left">
                    <label class="black--text">Yang bertandatangan</label>
                  </v-col>
                  <v-col>
                    <v-radio-group
                      :readonly="isFromServiceDashboard"
                      row
                      class="pt-0 mt-0"
                      v-model="input.signer"
                      hide-details="false"
                    >
                      <v-radio label="Pasien" value="pasien"></v-radio>
                      <v-radio label="Wali" value="wali"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row class="align-center">
                  <v-col cols="4" class="text-left">
                    <label class="required black--text"
                      >Nama
                      {{ input.signer === 'pasien' ? 'Pasien' : 'Wali' }}</label
                    >
                  </v-col>
                  <v-col cols="5">
                    <v-text-field
                      :readonly="isFromServiceDashboard"
                      placeholder="Nama Lengkap"
                      :rules="[v => !!v || 'Wajib Diisi']"
                      dense
                      class="mt-0 pt-0"
                      v-model="input.name"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4" class="text-left">
                    <label class="black--text">Alamat Lengkap</label>
                  </v-col>
                  <v-col cols="6">
                    <v-textarea
                      :readonly="isFromServiceDashboard"
                      placeholder="Masukkan alamat di sini"
                      style="width: 22rem;"
                      outlined
                      rows="5"
                      v-model="input.address"
                      hide-details="false"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col cols="6">
              <v-row class="align-center pt-9">
                <v-col cols="4" class="text-left">
                  <label class="black--text">Nomor Telepon</label>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    :readonly="isFromServiceDashboard"
                    placeholder="No Telp"
                    dense
                    type="number"
                    class="mt-0 pt-0"
                    v-model="input.telp"
                    hide-details="false"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="align-center mb-5">
                <v-col cols="4" class="text-left">
                  <label class="black--text">No. Identitas/KTP/SIM</label>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    :readonly="isFromServiceDashboard"
                    placeholder="No. Identitas/KTP/SIM"
                    dense
                    class="mt-0 pt-0"
                    v-model="input.identityNumber"
                    hide-details="false"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider style="width: 74%" class="mb-5"></v-divider>
              <v-row class="align-center mb-5">
                <v-col cols="4" class="text-left">
                  <label class="black--text required"
                    >Petugas Pemberi Penjelasan</label
                  >
                </v-col>
                <v-col cols="5">
                  <v-combobox
                    :readonly="isFromServiceDashboard"
                    :loading="loading"
                    :rules="[v => !!v || 'Wajib Diisi']"
                    :clearable="!isFromServiceDashboard"
                    placeholder="Nama Petugas"
                    :items="resource.staff"
                    item-text="text"
                    append-icon="mdi-chevron-down"
                    dense
                    :search-input.sync="searchStaff"
                    class="mt-0 pt-0"
                    v-model="input.staff"
                  ></v-combobox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
        <v-row class="pa-5">
          <v-col>
            <h2 class="text-left primary--text pl-5 mb-4">
              Persetujuan Pasien
            </h2>
            <div class="pl-5">
              <!-- persetujuan layanan -->
              <h3 class="black--text text-left">
                Persetujuan Pelayanan
              </h3>
              <v-row class="align-center pt-2 mb-1">
                <v-col class="text-left">
                  <label class="black--text"
                    >Apakah pasien menyetujui atas pelayanan yang akan diberikan
                    oleh klinik?
                  </label>
                </v-col>
                <v-col>
                  <v-radio-group
                    v-model="input.patientAgreement.patientConsentService"
                    :readonly="isFromServiceDashboard"
                    row
                    hide-details="false"
                    class="mt-0 pt-0"
                  >
                    <v-radio label="Ya" :value="true"></v-radio>
                    <v-radio label="Tidak" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <!-- ketentuan pembayaran -->
              <h3 class="black--text text-left">
                Ketentuan Pembayaran
              </h3>
              <v-row class="align-center pt-2 mb-1">
                <v-col class="text-left">
                  <label class="black--text"
                    >Apakah pasien menyetujui atas ketentuan pembayaran yang
                    telah ditentukan oleh klinik?</label
                  >
                </v-col>
                <v-col>
                  <v-radio-group
                    v-model="input.patientAgreement.patientConsentPayment"
                    :readonly="isFromServiceDashboard"
                    row
                    hide-details="false"
                    class="mt-0 pt-0"
                  >
                    <v-radio label="Setuju" :value="true"></v-radio>
                    <v-radio label="Tidak Setuju" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <!-- hak dan kewajiban pasien -->
              <h3 class="black--text text-left mb-1">
                Hak dan Kewajiban Pasien
              </h3>
              <v-row class="align-center pt-2 mb-1">
                <v-col class="text-left">
                  <label class="black--text"
                    >Apakah pasien menyetujui atas hak dan kewajiban pasien yang
                    telah ditentukan oleh klinik?</label
                  >
                </v-col>
                <v-col>
                  <v-radio-group
                    v-model="input.patientAgreement.patientRightsObligation"
                    :readonly="isFromServiceDashboard"
                    row
                    hide-details="false"
                    class="mt-0 pt-0"
                  >
                    <v-radio label="Setuju" :value="true"></v-radio>
                    <v-radio label="Tidak Setuju" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <!-- Tata Tertib Klinik -->
              <h3 class="black--text text-left">
                Tata Tertib Klinik
              </h3>
              <v-row class="align-center pt-2 mb-1">
                <v-col class="text-left">
                  <label class="black--text"
                    >Apakah pasien menyetujui atas tata tertib klinik yang telah
                    ditentukan oleh klinik?</label
                  >
                </v-col>
                <v-col>
                  <v-radio-group
                    v-model="input.patientAgreement.patientTermCondition"
                    :readonly="isFromServiceDashboard"
                    row
                    hide-details="false"
                    class="mt-0 pt-0"
                  >
                    <v-radio label="Setuju" :value="true"></v-radio>
                    <v-radio label="Tidak Setuju" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <!-- Kebutuhan Penerjemah Bahasa -->
              <h3 class="black--text text-left">
                Kebutuhan Penerjemah Bahasa
              </h3>
              <v-row class="align-center pt-2">
                <v-col class="text-left">
                  <label class="black--text"
                    >Apakah pasien membutuhkan penerjemah bahasa?</label
                  >
                </v-col>
                <v-col>
                  <v-radio-group
                    :readonly="isFromServiceDashboard"
                    v-model="input.patientAgreement.patientTranslatorBahasa"
                    row
                    hide-details="false"
                    class="mt-0 pt-0"
                  >
                    <v-radio label="Ya" :value="true"></v-radio>
                    <v-radio label="Tidak" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col class="pt-10">
            <!-- Kebutuhan Rohaniawan -->
            <h3 class="black--text text-left">
              Kebutuhan Rohaniawan
            </h3>
            <v-row class="align-center pt-2 mb-1">
              <v-col class="text-left">
                <label class="black--text"
                  >Apakah pasien membutuhkan rohaniawan?</label
                >
              </v-col>
              <v-col>
                <v-radio-group
                  v-model="input.patientAgreement.patientRohaniawanNeeds"
                  :readonly="isFromServiceDashboard"
                  row
                  hide-details="false"
                  class="mt-0 pt-0"
                >
                  <v-radio label="Ya" :value="true"></v-radio>
                  <v-radio label="Tidak" :value="false"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <!-- Pelepasan Informasi -->
            <h3 class="black--text text-left mb-3">
              Pelepasan Informasi
            </h3>
            <v-row class="align-center pt-2">
              <v-col class="text-left py-0">
                <label class="black--text"
                  >Apakah pasien menyetujui atas ketentuan pelepasan informasi
                  pasien yang telah ditentukan oleh klinik?</label
                >
              </v-col>
              <v-col class="py-0">
                <v-radio-group
                  v-model="input.patientAgreement.patientConsentRelease"
                  :readonly="isFromServiceDashboard"
                  row
                  hide-details="false"
                  class="mt-0 pt-0"
                >
                  <v-radio label="Setuju" :value="true"></v-radio>
                  <v-radio label="Tidak Setuju" :value="false"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row class="align-center">
              <v-col class="text-left py-3">
                <label class="black--text"
                  >Apakah pasien setuju bahwa hasil pemeriksaan penunjang dapat
                  diberikan kepada pihak penjamin?</label
                >
              </v-col>
              <v-col class="py-3">
                <v-radio-group
                  v-model="
                    input.patientAgreement.patientConsentInspectionGuarantor
                  "
                  :readonly="isFromServiceDashboard"
                  row
                  hide-details="false"
                  class="mt-0 pt-0"
                >
                  <v-radio label="Setuju" :value="true"></v-radio>
                  <v-radio label="Tidak Setuju" :value="false"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row class="align-center">
              <v-col class="text-left py-0">
                <label class="black--text"
                  >Apakah pasien setuju bahwa hasil pemeriksaan penunjang dapat
                  diakses oleh peserta didik?</label
                >
              </v-col>
              <v-col class="py-0">
                <v-radio-group
                  v-model="
                    input.patientAgreement.patientConsentInspectionLearner
                  "
                  :readonly="isFromServiceDashboard"
                  row
                  hide-details="false"
                  class="mt-0 pt-0"
                >
                  <v-radio label="Setuju" :value="true"></v-radio>
                  <v-radio label="Tidak Setuju" :value="false"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row class="align-center">
              <v-col class="text-left py-3">
                <label class="black--text"
                  >Apakah pasien setuju bahwa informasi pasien dapat diberikan
                  kepada fasyankes tujuan rujukan?</label
                >
              </v-col>
              <v-col class="py-3">
                <v-radio-group
                  v-model="
                    input.patientAgreement.patientConsentInformationFasyankes
                  "
                  :readonly="isFromServiceDashboard"
                  row
                  hide-details="false"
                  class="mt-0 pt-0"
                >
                  <v-radio label="Setuju" :value="true"></v-radio>
                  <v-radio label="Tidak Setuju" :value="false"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row class="align-center mb-2">
              <v-col class="text-left py-0">
                <label class="black--text"
                  >Apakah ada orang lain yang berhak mendapatkan informasi
                  pasien?</label
                >
              </v-col>
              <v-col class="py-0">
                <v-radio-group
                  row
                  :readonly="isFromServiceDashboard"
                  hide-details="false"
                  class="pt-0 mt-0"
                  v-model="input.restrictedInfo"
                >
                  <v-radio label="Ya" :value="true"></v-radio>
                  <v-radio label="Tidak" :value="false"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row v-show="input.restrictedInfo" class="mb-2">
              <template v-for="(allow, index) in input.visitorAllowed">
                <v-col
                  cols="2"
                  class="text-left pb-0"
                  :key="'nameLabel-' + index"
                >
                  <label class="black--text font-weight-bold">Nama</label>
                </v-col>
                <v-col cols="4" class="pt-2 pb-0" :key="'name-' + index">
                  <v-text-field
                    :readonly="isFromServiceDashboard"
                    placeholder="Nama"
                    dense
                    class="mt-0 pt-0"
                    v-model="input.visitorAllowed[index].name"
                    @keyup="addVisitor($event, index)"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="2"
                  class="text-left pb-0"
                  :key="'relationLabel-' + index"
                >
                  <label class="black--text font-weight-bold">Hubungan</label>
                </v-col>
                <v-col cols="4" class="pt-2 pb-0" :key="'relation-' + index">
                  <v-combobox
                    :readonly="isFromServiceDashboard"
                    placeholder="Hubungan"
                    dense
                    v-model="input.visitorAllowed[index].relation"
                    class="mt-0 pt-0"
                    :items="resource.familyStatus"
                    item-text="text"
                  ></v-combobox>
                </v-col>
              </template>
            </v-row>
            <!-- Privasi -->
            <h3 class="black--text text-left">
              Privasi
            </h3>
            <v-row class="align-center pt-2">
              <v-col class="text-left">
                <label class="black--text"
                  >Apakah pasien/wali mengizinkan pasien untuk dijenguk oleh
                  keluarga/kerabat?</label
                >
              </v-col>
              <v-col>
                <v-radio-group
                  row
                  :readonly="isFromServiceDashboard"
                  hide-details="false"
                  class="mt-0 pt-0"
                  v-model="input.privacy"
                >
                  <v-radio label="Ya" :value="true"></v-radio>
                  <v-radio label="Tidak" :value="false"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-row class="align-center" v-if="input.privacy">
              <template v-for="(privacy, index) in input.person">
                <v-col
                  class="text-left"
                  :key="'privacyLabel-' + index"
                  cols="6"
                >
                  <label class="black--text font-weight-bold"
                    >Tuliskan nama orang yang tidak diberi izin menjenguk
                    pasien</label
                  >
                </v-col>
                <v-col class="pt-0 pb-0" :key="'person-' + index" cols="6">
                  <v-text-field
                    placeholder="Nama"
                    :readonly="isFromServiceDashboard"
                    class="mt-0 pt-0"
                    hide-details="false"
                    v-model="input.person[index]"
                    @keyup="addPerson($event, index)"
                  ></v-text-field>
                </v-col>
              </template>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions class="pb-4">
      <v-spacer></v-spacer>
      <v-btn
        class="text-none"
        :outlined="!isFromServiceDashboard"
        color="primary"
        :tile="!isFromServiceDashboard"
        elevation="0"
        width="153"
        @click="printConsent"
        :disabled="disabled"
      >
        <font-awesome-icon icon="print" />
        Print</v-btn
      >
      <v-btn
        v-if="!isFromServiceDashboard"
        class="text-none"
        color="primary"
        tile
        elevation="0"
        width="153"
        @click="addGeneralConsent()"
        >Simpan</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import Documents from '@/mixin/documents';
import moment from 'moment-timezone';
import Constant from '@/const';
import PrintMixin from '@/mixin/printMixin';
import jwtMixin from '@/mixin/jwtMixin';
import Swal from 'sweetalert2';
import AlertMixin from '@/mixin/alertMixin';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'GeneralConsentFrom',
  mixins: [AlertMixin, PrintMixin, Documents, jwtMixin],
  props: {
    id_emr: String,
    socialData: Object,
    isFromServiceDashboard: Boolean,
  },
  data() {
    return {
      clientData: this.$store.getters.userLoggedIn.clinic,
      searchStaff: '',
      radioClick: '',
      loading: false,
      input: {
        signer: '',
        name: '',
        address: '',
        telp: '',
        identityNumber: '',
        rmNumber: '',
        staff: '',
        person: [''],
        patientAgreement: {
          patientConsentService: true,
          patientConsentPayment: true,
          patientRightsObligation: true,
          patientTermCondition: true,
          patientTranslatorBahasa: true,
          patientRohaniawanNeeds: true,
          patientConsentRelease: true,
          patientConsentInspectionGuarantor: true,
          patientConsentInspectionLearner: true,
          patientConsentInformationFasyankes: true,
        },
        privacy: false,
        restrictedInfo: false,
        visitorAllowed: [
          {
            name: '',
            relation: '',
          },
        ],
      },
      resource: {
        staff: [],
        familyStatus: [
          'Ayah',
          'Ibu',
          'Anak',
          'Saudara Kandung',
          'Suami',
          'Istri',
          'Bibi',
          'Paman',
          'Kakek',
          'Nenek',
          'Cucu',
          'Cicit',
          'Tetangga',
          'Rekan Kerja',
          'Lain-lain',
        ],
        pointToPrint: {
          a1: `Saya/pasien menyetujui untuk perawatan di ${this.$store.getters.userLoggedIn.clinic.name} sebagai pasien rawat jalan tergantung kepada kebutuhan medis. Perawatan dapat meliputi pemeriksaan radiologi, pemeriksaan laboratorium, perawatan rutin dan prosedur seperti pemasangan infus atau suntikan dan evaluasi (misal: wawancara dan pemeriksaan fisik).`,
          b1:
            'Persetujuan yang saya/ pasien berikan tidak termasuk persetujuan untuk prosedur/ tindakan invasif (misal : Operasi) atau tindakan yang mempunyai resiko tinggi.',
          c1: `Jika saya/ pasien memutuskan untuk menghentikan perawatan medis untuk diri saya/ pasien sendiri. Saya memahami dan menyadari bahwa ${this.$store.getters.userLoggedIn.clinic.name} atau dokter tidak bertanggung jawab atas hasil yang merugikan saya /pasien.`,
          a2: `Saya/ pasien memahami informasi yang ada dalam diri saya/ pasien, termasuk diagnosis dan hasil tes diagnosis yang akan digunakan untuk perawatan medis, ${this.$store.getters.userLoggedIn.clinic.name} akan menjamin kerahasiaannya`,
          b2: `Saya/ pasien memberikan wewenang kepada ${this.$store.getters.userLoggedIn.clinic.name} untuk memberikan informasi tentang diagnosis, hasil pelayanan dan pengobatan saya kepada :`,
          c2: `Saya/ pasien memberi wewenang kepada ${this.$store.getters.userLoggedIn.clinic.name} untuk memberikan informasi tentang diagnosis, hasil pelayanan dan pengobatan bila diperlukan untuk memproses klaim asuransi/ perusahaan dan atau lembaga pemerintah.`,
          a3:
            'Saya/ pasien memiliki hak untuk mengambil bagian dalam keputusan mengenai penyakit saya/ pasien dan dalam hal perawatan medis dan rencana pengobatan.',
          b3: `Saya/ pasien telah mendapatkan informasi tentang “Hak dan Kewajiban Pasien” di Klinik ${this.$store.getters.userLoggedIn.clinic.name} melalui leaflet yang disediakan.`,
          c3: `Saya/ pasien memahami bahwa ${this.$store.getters.userLoggedIn.clinic.name} tidak bertanggung jawab atas kehilangan barang-barang pribadi dan barang berharga yang dibawa ke klinik.`,
          a4:
            'Saya/ pasien tidak diperkenankan untuk membawa barang berharga ke ruang rawat inap. Jika ada, anggota keluarga atau teman harus diminta untuk membawa pulang barang berharga tersebut.',
          b4:
            'Saya/ pasien telah menerima informasi tentang peraturan yang diberlakukan oleh klinik dan saya beserta keluarga bersedia untuk mematuhinya, termasuk akan mematuhi jam berkunjung pasien sesuai dengan aturan di klinik.',
          c4:
            'Anggota keluarga saya yang menunggu saya, bersedia untuk selalu memakai tanda pengenal khusus yang diberikan oleh klinik.',
          privacy:
            'Saya (mengizinkan/ tidak mengizinkan) klinik memberi akses bagi keluarga dan handai taulan serta orang-orang yang akan menegok saya (sebutkan nama bila ada permintaan khusus yang tidak diijinkan) : (nama orang yang tidak diberi izin menjenguk pasien)',
          bill:
            'Saya memahami tentang informasi biaya pengobatan dan biaya tindakan yang dijelaskan oleh petugas klinik.',
          syariah:
            'Saya menyetujui baik sebagai pasien atau wakil pasien bahwa klinik menerapkan pelayanan Kesehatan berdasarkan prinsip syari’ah yang terdiri dari assesmen spiritual, pemakaian hijab untuk Muslimah, mengingatkan waktu shalat, pemakaian kerudung menutup dada ibu menyusui, dan penunggu pasien adalah mahram atau keluarga.',
          sign:
            'Dengan tanda tangan saya di bawah, saya menyatakan bahwa saya telah membaca dan memahami isi persetujuan umum/ general consent.',
        },
        rights: {
          r1:
            'Mendapat pelayanan kesehatan optimal/ sebaik-baiknya sesuai standar profesi kedokteran',
          r2:
            'Hak atas informasi yang jelas dan benar tentang penyakit dan tindakan medis yang akan dilakukan dokter',
          r3: 'Hak memilih dokter dan pelayanan kesehatan yang merawat pasien',
          r4:
            'Hak atas rahasia kedokteran/ data penyakit, status, diagnosis, dll',
          r5:
            'Hak untuk memberi persetujuan / menolak atas tindakan medis yang akan dilakukan pada pasien',
          r6: 'Hak untuk menghentikan program pengobatan',
          r7:
            'Hak untuk mencari pendapat kedua/pendapat dari dokter lain/ di rumah sakit',
          r8: 'Hak atas isi rekaman medis/ data medis',
          r9: 'Hak untuk didampingi anggota keluarga dalam keadaan kritis',
          r10:
            'Hak untuk memeriksa dan menerima penjelasan tentang biaya yang dikenakan/dokumen pembayaran/bon/bill',
          r11:
            'Pasien berhak mengajukan usul, saran perbaikan atas pelayanan kesehatan terhadap dirinya',
          r12:
            'Hak untuk mendapatkan ganti rugi kalau terjadi kelalaian dan tindakan yang tidak mengikuti prosedur operasi profesi kesehatan',
        },
        obligation: {
          o1:
            'Memberi keterangan yang jujur tentang penyakit dan perjalanan penyakit terhadap petugas kesehatan',
          o2: 'Mematuhi nasihat dokter dan perawat',
          o3: 'Harus ikut menjaga kesehatan dirinya',
          o4: 'Memenuhi imbalan jasa pelayanan',
        },
      },
      disabled: false,
    };
  },
  watch: {
    searchStaff() {
      this.searchStaffData(this);
    },
  },
  computed: {
    patientData() {
      return this.$store.getters.selectedPatient;
    },
    letterData() {
      return Constant.client.find(
        item =>
          item.name.toLowerCase() ===
          this.$store.getters.userLoggedIn.clinic.name.toLowerCase(),
      );
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getGeneralConsent();
      this.getStaff();
    });
  },
  methods: {
    searchStaffData: _.debounce(v => {
      v.getStaff(v.searchStaff);
    }),
    onCloseButtonClick() {
      this.$emit('close-form');
    },
    getStaff(keyword = '') {
      this.loading = true;
      this.runApiCalls(() => {
        axios
          .get(Constant.apiUrl.concat(`/master/staff?search=${keyword || ''}`))
          .then(response => {
            const data = response.data.data;
            this.resource.staff = data.map(staff => {
              return {
                id: staff._id,
                text: staff.detail.name,
              };
            });
          })
          .catch(() => {
            this.resource.staff = [];
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    printConsent() {
      if (this.$refs.form.validate()) {
        const doc = this.generateGeneralConsentDoc(this.input, this.socialData);
        this.printDocument(doc, {
          docName: 'General Consent Rawat Jalan',
          rmNumber: this.socialData.rmNumber,
        });
      }
    },
    addVisitor(event, index) {
      if (event.keyCode === 9) return;
      if (
        this.input.visitorAllowed[index].name !== '' &&
        this.input.visitorAllowed.length - 1 === index
      ) {
        this.input.visitorAllowed.push({
          name: '',
          relation: '',
        });
      } else if (
        this.input.visitorAllowed[index].name === '' &&
        this.input.visitorAllowed.length !== 1
      ) {
        this.input.visitorAllowed.splice(index, 1);
      }
    },
    addPerson(event, index) {
      if (event.keyCode === 9) return;
      if (
        this.input.person[index] !== '' &&
        this.input.person.length - 1 === index
      ) {
        this.input.person.push('');
      } else if (
        this.input.person[index] === '' &&
        this.input.person.length !== 1
      ) {
        this.input.person.splice(index, 1);
      }
    },
    addGeneralConsent() {
      if (this.$refs.form.validate()) {
        const payload = {
          id_emr: this.id_emr,
          detail: {
            signer: this.input.signer,
            signer_name: this.input.name,
            address: this.input.address,
            staff: this.input.staff.id,
            no_telp: this.input.telp,
            no_identitas: this.input.identityNumber,
            privacy: this.input.privacy,
            patient_consent_service: this.input.patientAgreement
              .patientConsentService,
            patient_consent_payment: this.input.patientAgreement
              .patientConsentPayment,
            patient_rights_obligation: this.input.patientAgreement
              .patientRightsObligation,
            patient_term_condition: this.input.patientAgreement
              .patientTermCondition,
            patient_translator_bahasa: this.input.patientAgreement
              .patientTranslatorBahasa,
            patient_rohaniawan_needs: this.input.patientAgreement
              .patientRohaniawanNeeds,
            patient_consent_release: this.input.patientAgreement
              .patientConsentRelease,
            patient_consent_inspection_guarantor: this.input.patientAgreement
              .patientConsentInspectionGuarantor,
            patient_consent_inspection_learner: this.input.patientAgreement
              .patientConsentInspectionLearner,
            patient_consent_information_fasyankes: this.input.patientAgreement
              .patientConsentInformationFasyankes,
            visitor_restricted: this.input.person.filter(x => x !== ''),
            restricted_information: this.input.restrictedInfo,
            visitor_allowed: this.input.visitorAllowed.filter(
              x => x.name !== '',
            ),
            patient_status: 'rawat jalan',
            timestamps: {
              created_by: this.$store.getters.userLoggedIn.id,
            },
          },
        };

        axios
          .post(Constant.apiUrl.concat('/document/general-consent'), payload)
          .then(() => {
            Swal.fire({
              title: 'Data General Consent Tersimpan!',
              text: '',
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok',
            }).then(result => {
              if (result.isConfirmed) {
                this.getGeneralConsent();
              }
            });
          })
          .catch(error => {
            this.showErrorAxios(error);
          });
      }
    },
    getGeneralConsent() {
      axios
        .get(Constant.apiUrl.concat(`/document/general-consent/${this.id_emr}`))
        .then(response => {
          const letter = response.data.data;
          this.input = {
            patientAgreement: {
              patientConsentService: letter.detail.patient_consent_service,
              patientConsentPayment: letter.detail.patient_consent_payment,
              patientRightsObligation: letter.detail.patient_rights_obligation,
              patientTermCondition: letter.detail.patient_term_condition,
              patientTranslatorBahasa: letter.detail.patient_translator_bahasa,
              patientRohaniawanNeeds: letter.detail.patient_rohaniawan_needs,
              patientConsentRelease: letter.detail.patient_consent_release,
              patientConsentInspectionGuarantor:
                letter.detail.patient_consent_inspection_guarantor,
              patientConsentInspectionLearner:
                letter.detail.patient_consent_inspection_learner,
              patientConsentInformationFasyankes:
                letter.detail.patient_consent_information_fasyankes,
            },
            signer: letter.detail.signer,
            staff: {
              text: letter.detail.staff.name,
              id: letter.detail.staff.id,
            },
            status: {
              text: letter.detail.generalConsentSigned
                ? 'Sudah ditandatangani'
                : 'Sudah diproses',
              class: letter.detail.generalConsentSigned ? 'signed' : 'created',
            },
            name: letter.detail.signer_name,
            patientName: letter.detail.signer_name,
            address: letter.detail.address,
            telp: letter.detail.no_telp,
            age: letter.detail.age,
            rmNumber: letter.detail.rmNumber,
            gender: letter.detail.gender,
            birthDate: moment(letter.detail.birth.date, 'YYYY-MM-DD').format(
              'DD/MM/YYYY',
            ),
            identityNumber: letter.detail.no_identitas,
            person: letter.detail.privacy
              ? letter.detail.visitor_restricted
              : [''],
            privacy: letter.detail.privacy,
            restrictedInfo: letter.detail.restricted_information,
            visitorAllowed: letter.detail.restricted_information
              ? letter.detail.visitor_allowed.map(arr => {
                  return {
                    name: arr.name,
                    relation: arr.relation,
                  };
                })
              : [
                  {
                    name: '',
                    relation: '',
                  },
                ],
          };
          this.disabled = false;
        })
        .catch(err => {
          if (err.response.status === 404) this.disabled = true;
          this.input = {
            ...this.input,
            patientAgreement: {
              patientConsentService: true,
              patientConsentPayment: true,
              patientRightsObligation: true,
              patientTermCondition: true,
              patientTranslatorBahasa: true,
              patientRohaniawanNeeds: true,
              patientConsentRelease: true,
              patientConsentInspectionGuarantor: true,
              patientConsentInspectionLearner: true,
              patientConsentInformationFasyankes: true,
            },
            signer: 'pasien',
            name: this.socialData.name,
            address: this.socialData.address,
            telp: this.socialData.telp,
            identityNumber: this.socialData.identityNumber,
            age: this.socialData.age,
            gender: this.socialData.gender,
            rmNumber: this.socialData.rmNumber,
            birthDate: this.socialData.birthDate,
            person: [''],
            staff: '',
            privacy: false,
            restrictedInfo: false,
            visitorAllowed: [
              {
                name: '',
                relation: '',
              },
            ],
          };
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.card-layout {
  height: 67vh;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0.4rem;
    border-radius: 1rem;
    background-color: rgb(214, 214, 214);
  }
  &::-webkit-scrollbar-thumb {
    width: 0.4rem;
    border-radius: 1rem;
    background-color: rgb(146, 146, 146);
  }
}

.card-layout-service {
  height: 77vh;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0.4rem;
    border-radius: 1rem;
    background-color: rgb(214, 214, 214);
  }
  &::-webkit-scrollbar-thumb {
    width: 0.4rem;
    border-radius: 1rem;
    background-color: rgb(146, 146, 146);
  }
}

.required:after {
  content: ' *';
  color: red;
}

@media screen and (max-width: 1254px) {
  .card-layout {
    height: 57vh;
  }
}

.chip-created {
  border-radius: 4px !important;
  background-color: #f0f9ff !important;
  font-size: 0.6vw;
  border: 1px solid #2f80ed;
  color: #2f80ed;
}
.chip-signed {
  border-radius: 4px !important;
  background-color: #e5fff0 !important;
  font-size: 0.6vw;
  border: 1px solid #2d965a;
  color: #2d965a;
}
</style>
