<template>
  <v-card>
    <v-card-title class="justify-center">
      <h4>Apakah Anda yakin ingin menyimpan data ini?</h4>
    </v-card-title>
    <v-container fluid>
      <v-card
        color="#F0F9FF"
        height="360"
        flat
        class="pa-3 mx-5 text-left"
        v-if="!isWarehouse"
      >
        <label class="font-weight-bold body-2 grey--text">RINGKASAN DATA</label>
        <v-row no-gutters class="request-detail header">
          <label class="caption font-weight-bold text-center">No</label>
          <label class="caption font-weight-bold">Nama Barang</label>
          <label class="caption font-weight-bold text-center"
            >Jenis Sediaan</label
          >
          <label class="caption font-weight-bold">Nama Pabrik</label>
          <label class="caption font-weight-bold text-center"
            >Jumlah Diberikan</label
          >
          <label class="caption font-weight-bold text-center"
            >Jumlah Stok Akhir</label
          >
        </v-row>
        <template>
          <v-row
            v-for="(item, idx) in dataRequest"
            no-gutters
            class="request-detail py-3"
            :key="'request-' + idx"
          >
            <label class="body-2 text-center">{{ idx + 1 }}</label>
            <div>
              <label class="body-2">{{ item.displayName }}</label
              ><br />
              <label class="body-2 grey--text"
                >Total Permintaan : {{ item.req_quantity }}</label
              >
              <br />
              <label class="body-2 grey--text"
                >Total Diberikan : {{ item.given_quantity }}</label
              >
            </div>

            <label class="body-2 text-center">{{ item.strength_unit }}</label>
            <!-- <template v-for="(stock, idx) in item.listStock"> -->
            <!-- <v-row> -->
            <label class="body-2 text-center">{{ item.factory }}</label>
            <!-- </v-row> -->
            <!-- </template> -->
            <label class="body-2 text-center">{{ item.given_quantity }}</label>
            <label class="body-2 text-center">{{ item.warehouseRemain }}</label>
          </v-row>
        </template>
      </v-card>
    </v-container>
    <v-card-actions>
      <v-btn
        outlined
        color="primary"
        class="text-none"
        tile
        @click="$emit('close-dialog')"
        >Batal</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn outlined color="primary" class="text-none" tile>
        <v-icon>mdi-printer-outline</v-icon>
        Print
      </v-btn>
      <v-btn
        color="primary"
        depressed
        tile
        class="text-none"
        @click="confirmData()"
        >Konfirmasi</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
const axios = require('axios');
import AlertMixin from '@/mixin/alertMixin';
import moment from 'moment-timezone';

import Constant from '@/const';

export default {
  name: 'DialogDetail',
  mixins: [AlertMixin],
  props: {
    dataRequest: {
      type: Array,
    },
    selectedItem: {
      type: Object,
    },
  },
  methods: {
    confirmData() {
      const payload = {
        id_clinic: this.selectedItem.from.id,
        id_warehouse: this.selectedItem.to.id,
        confirmation_date: moment().format(),
        status: this.selectedItem.status,
        request: this.dataRequest.map(item => {
          return {
            name: item.name,
            amount: +item.quantity,
            amount_given: +item.given_quantity,
            display_name: item.displayName,
            strength: item.strength,
            strength_unit: item.strength_unit,
            preparation: item.preparation,
            status: this.selectedItem.status ? 'given' : 'rejected',
            stock: item.listStock.map(i => {
              return {
                id_stock: i.id_stock,
              };
            }),
            fulfillment: item.listStock.map(i => {
              return {
                id_stock: i.id_stock,
                id_goods: i.id_goods,
                id_drugs: i.id_drugs,
                amount: +i.amountGiven,
              };
            }),
          };
        }),
        timestamps: {
          created_by: this.$store.getters.userLoggedIn.id,
        },
      };
      axios
        .put(
          Constant.apiUrl.concat(`/stock/request/${this.selectedItem.id}`),
          payload,
        )
        .then(response => {
          if (response) {
            this.showFillSuccess('Konfirmasi Permintaan Barang Berhasil');
            this.$emit('close-confirm');
          }
        })
        .catch(error => this.showErrorAxios(error));
    },
  },
};
</script>

<style lang="scss" scoped>
.request-detail {
  display: grid;
  grid-template-columns: 10% 25% 15% 20% 15% 15%;
  &.header {
    grid-template-rows: 5vh;
    border-top: 1px solid #bdbdbd;
    border-bottom: 1px solid #bdbdbd;
    align-items: center;
  }
}
</style>
