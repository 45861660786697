<template>
  <div class="pa-0">
    <v-toolbar flat outlined>
      <v-container fluid>
        <v-row align="center">
          <v-col style="display: grid;" class="text-left" cols="6">
            <v-toolbar-title class="font-weight-bold float-left">
              Mutasi Barang
            </v-toolbar-title>
          </v-col>
          <v-col cols="1" class="text-right">
            <v-btn @click="filterBtn()" text class="px-0">
              <span class="text-capitalize">Filters</span>
              <v-icon>mdi-filter-variant</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="1">
            <v-btn color="grey" icon text>
              <v-icon @click="getAllSupply()">mdi-autorenew</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-text-field
              class="input"
              background-color="grey lighten-3"
              placeholder="Search"
              solo
              dense
              hide-details
              flat
              prepend-inner-icon="mdi-magnify"
              single-line
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-toolbar>
    <v-row no-gutters class="pa-5" v-show="showFilter" justify="center">
      <v-btn text class="text-none" @click="reset()">Reset</v-btn>
      <v-col cols="2" class="mx-3">
        <v-menu
          dense
          ref="menuStart"
          v-model="menuStart"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              @input="fillterByDate()"
              outlined
              dense
              class="input"
              v-model="startDate"
              label="Tanggal Awal"
              readonly
              v-bind="attrs"
              v-on="on"
              hide-details="false"
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="startDate"
            no-title
            scrollable
            @input="menuStart = false"
          />
        </v-menu>
      </v-col>
      <v-col cols="2" class="mx-3">
        <v-menu
          dense
          ref="menuEnd"
          v-model="menuEnd"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              @input="fillterByDate()"
              outlined
              dense
              class="input"
              v-model="endDate"
              label="Tanggal Akhir"
              readonly
              v-bind="attrs"
              v-on="on"
              hide-details="false"
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="endDate"
            no-title
            scrollable
            @input="menuEnd = false"
          />
        </v-menu>
      </v-col>
      <v-col cols="2" class="mx-3">
        <v-menu
          dense
          ref="menuCons"
          v-model="menuCons"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              @input="fillterByDate()"
              outlined
              dense
              class="input"
              v-model="confirmStart"
              label="Tanggal Konfirmasi Awal"
              readonly
              v-bind="attrs"
              v-on="on"
              hide-details="false"
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="confirmStart"
            no-title
            scrollable
            @input="menuCons = false"
          />
        </v-menu>
      </v-col>
      <v-col cols="2" class="mx-3">
        <v-menu
          dense
          ref="menuCone"
          v-model="menuCone"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              @input="fillterByDate()"
              outlined
              dense
              class="input"
              v-model="confirmEnd"
              label="Tanggal Konfirmasi Akhir"
              readonly
              v-bind="attrs"
              v-on="on"
              hide-details="false"
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="confirmEnd"
            no-title
            scrollable
            @input="menuCone = false"
          />
        </v-menu>
      </v-col>
    </v-row>
    <v-main>
      <v-data-table
        :headers="table.headers"
        :items="table.items"
        :items-per-page="table.items.length"
        hide-default-footer
      >
        <template v-slot:[`item.status`]="{ item }">
          <v-card
            v-if="item.status === 'requested'"
            color="#ffe5e5"
            class="text-center pa-1 font-weight-bold red--text"
            width="100"
            height="30"
            flat
          >
            {{ item.status }}
          </v-card>
          <v-card
            v-else-if="item.status === 'received'"
            color="#e5fff0"
            class="text-center pa-1 font-weight-bold green--text"
            width="100"
            height="30"
            flat
          >
            {{ item.status }}
          </v-card>
          <v-card
            v-else
            color="#f0f9ff"
            class="text-center pa-1 font-weight-bold blue--text"
            width="100"
            height="30"
            flat
          >
            {{ item.status }}
          </v-card>
        </template>
        <template v-slot:[`item.detail`]="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on: tooltip }">
              <v-btn
                icon
                v-on="{ ...tooltip }"
                class="hover"
                @click="selectItem(1)"
              >
                <v-icon small>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <span class="caption">Detail Mutasi</span>
          </v-tooltip>
          <v-tooltip bottom v-if="item.status === 'requested'">
            <template #activator="{ on: tooltip }">
              <v-btn icon v-on="{ ...tooltip }" class="hover">
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </template>
            <span class="caption">Batal Pesan</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-main>
    <v-footer color="white">
      <v-pagination></v-pagination>
      <v-spacer />
      <v-btn fab color="primary" @click="selectItem(2)">
        <v-icon>
          mdi-plus
        </v-icon>
      </v-btn>
    </v-footer>

    <v-dialog width="1000" v-model="dialog" :fullscreen="selectedForm === 1">
      <DetailMutation v-if="selectedForm === 1" @close-form="dialog = false" />
      <InputMutation
        v-else-if="selectedForm === 2"
        @close-form="dialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import InputMutation from './Mutation/InputMutation.vue';
import DetailMutation from './Mutation/DetailMutation.vue';

export default {
  name: 'Mutation',
  components: {
    InputMutation,
    DetailMutation,
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      confirmStart: null,
      confirmEnd: null,
      menuStart: false,
      menuEnd: false,
      menuCons: false,
      menuCone: false,
      showFilter: false,
      table: {
        headers: [
          { text: 'No', value: 'no' },
          { text: 'Tanggal Permintaan', value: 'requestDate' },
          { text: 'Tanggal Konfirmasi', value: 'confirmDate' },
          { text: 'Dari', value: 'from' },
          { text: 'Ke', value: 'to' },
          { text: 'Users', value: 'user' },
          { text: 'Status', value: 'status' },
          { text: '', value: 'detail', sortable: false },
          //   { text: '', value: 'delete', sortable: false },
        ],
        items: [
          {
            no: 1,
            requestDate: '02/08/2021',
            confirmDate: '04/08/2021',
            from: 'Farmasi IGD',
            to: 'Gudang Rumah Tangga',
            user: 'Administrator',
            status: 'requested',
          },
          {
            no: 2,
            requestDate: '02/08/2021',
            confirmDate: '04/08/2021',
            from: 'Farmasi IGD',
            to: 'Gudang Rumah Tangga',
            user: 'Administrator',
            status: 'received',
          },
          {
            no: 3,
            requestDate: '02/08/2021',
            confirmDate: '04/08/2021',
            from: 'Farmasi IGD',
            to: 'Gudang Rumah Tangga',
            user: 'Administrator',
            status: 'rejected',
          },
        ],
      },
      dialog: false,
      selectedForm: null,
    };
  },
  methods: {
    reset() {
      this.startDate = null;
      this.endDate = null;
      this.confirmStart = null;
      this.confirmEnd = null;
    },
    filterBtn() {
      this.showFilter = !this.showFilter;
    },
    selectItem(value) {
      this.selectedForm = value;
      this.dialog = true;
    },
  },
};
</script>

<style>
.hover:hover {
  color: #2196f3 !important;
}
</style>
