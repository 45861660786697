<template>
  <v-form ref="form">
    <v-card class="pa-3">
      <v-row class="px-15 mx-10 mt-0 mb-16 scrollbar">
        <v-col>
          <v-row>
            <v-col cols="5" class="text-left">
              <label class="font-weight-bold">Nomor Surat</label>
            </v-col>
            <v-col cols="6" class="text-left">
              <label class="">{{ numberLetter }}</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="text-left">
              <label class="font-weight-bold">Nama Pasien</label>
            </v-col>
            <v-col cols="6" class="text-left">
              <label class="">{{ patientData.meta.name }}</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="text-left">
              <label class="font-weight-bold">No RM</label>
            </v-col>
            <v-col cols="6" class="text-left">
              <label class="">{{ patientData.rmNumber }}</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="text-left">
              <label class="font-weight-bold">Jenis Kelamin</label>
            </v-col>
            <v-col cols="6" class="text-left">
              <label class="">{{ patientData.meta.gender }}</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="text-left">
              <label class="font-weight-bold">Tanggal Lahir</label>
            </v-col>
            <v-col cols="6" class="text-left">
              <label class="">{{ patientData.meta.birthDate }}</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="text-left">
              <label class="font-weight-bold">Alamat</label>
            </v-col>
            <v-col cols="6" class="text-left">
              <label class="">{{ patientData.meta.address }}</label>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="5" class="text-left">
              <label class="font-weight-bold">Dokter Pemeriksa</label>
            </v-col>
            <v-col cols="6" class="">
              <v-combobox
                :rules="[v => !!v || 'Wajib diisi']"
                dense
                class="pt-0 mt-0"
                placeholder="Pilih Dokter"
                clearable
                auto-select-first
                :items="resource.doctor"
                item-text="doctorName"
                return-object
                v-model="input.doctor"
              ></v-combobox>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="5" class="text-left">
              <label class="font-weight-bold">Tanggal mulai izin sakit</label>
            </v-col>
            <v-col cols="6" class="text-left">
              <v-menu
                ref="menuDate"
                v-model="menuDate"
                :close-on-content-click="false"
                :return-value.sync="input.date_start"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="input.date_start"
                    :rules="[v => !!v || 'Wajib diisi']"
                    placeholder="Pilih tanggal"
                    append-icon="mdi-chevron-down"
                    v-bind="attrs"
                    v-on="on"
                    dense
                    hide-details="false"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="input.date_start"
                  no-title
                  scrollable
                  @input="
                    $refs.menuDate.save(input.date_start);
                    menuDate = false;
                  "
                />
              </v-menu>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="5" class="text-left">
              <label class="font-weight-bold">Tanggal selesai izin sakit</label>
            </v-col>
            <v-col cols="6" class="text-left">
              <v-menu
                ref="last"
                v-model="last"
                :close-on-content-click="false"
                :return-value.sync="input.date_end"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="input.date_end"
                    :rules="[v => !!v || 'Wajib diisi']"
                    placeholder="Pilih tanggal"
                    append-icon="mdi-chevron-down"
                    v-bind="attrs"
                    v-on="on"
                    dense
                    hide-details="false"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="input.date_end"
                  :min="input.date_start"
                  no-title
                  scrollable
                  @input="
                    $refs.last.save(input.date_end);
                    last = false;
                  "
                />
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-row align="center">
            <v-col cols="5" class="text-left">
              <label class="font-weight-bold">Tinggi badan</label>
            </v-col>
            <v-col cols="6" class="text-left">
              <v-text-field
                suffix="cm"
                dense
                v-model="input.height"
                :rules="[v => !!v || 'Wajib diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="5" class="text-left">
              <label class="font-weight-bold">Berat badan</label>
            </v-col>
            <v-col cols="6" class="text-left">
              <v-text-field
                suffix="kg"
                dense
                v-model="input.weight"
                :rules="[v => !!v || 'Wajib diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="5" class="text-left">
              <label class="font-weight-bold">Tensi Meter</label>
            </v-col>
            <v-col cols="3">
              <v-text-field
                dense
                v-model="input.sistole"
                :rules="[v => !!v || 'Wajib diisi']"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="px-0" style="max-width: 10px">/</v-col>
            <v-col cols="3">
              <v-text-field
                dense
                suffix="mmHg"
                v-model="input.diastole"
                :rules="[v => !!v || 'Wajib diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="5" class="text-left">
              <label class="font-weight-bold">Nadi</label>
            </v-col>
            <v-col cols="6" class="text-left">
              <v-text-field
                dense
                suffix="x/menit"
                v-model="input.pulse"
                :rules="[v => !!v || 'Wajib diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="5" class="text-left">
              <label class="font-weight-bold">Respirasi</label>
            </v-col>
            <v-col cols="6" class="text-left">
              <v-text-field
                hide-details="false"
                dense
                suffix="x/menit"
                v-model="input.respiration"
                :rules="[v => !!v || 'Wajib diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="start">
            <v-col cols="5" class="text-left">
              <label class="font-weight-bold">Catatan</label>
            </v-col>
            <v-col cols="6" class="text-left">
              <v-textarea
                hide-details="false"
                dense
                outlined
                placholder="Masukkan Catatan"
                v-model="input.notes"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-card-actions class="pa-0">
        <v-btn class="text-none" plain @click="$emit('close-form')">
          Kembali
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          tile
          outlined
          color="primary"
          class="text-none"
          width="116"
          :disabled="disabled"
          @click="printSickLetter()"
        >
          <font-awesome-icon icon="print" />
          Print
        </v-btn>
        <v-btn
          color="primary"
          tile
          elevation="0"
          class="text-none"
          width="108"
          @click="addSickLetter()"
          >Simpan</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import Constant from '@/const';
import Swal from 'sweetalert2';
import AlertMixin from '@/mixin/alertMixin';
import 'jspdf-autotable';
import jwtMixin from '@/mixin/jwtMixin';
import printMixin from '@/mixin/printMixin';
import Letter from '@/mixin/Letter';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'SickLetter',
  mixins: [AlertMixin, jwtMixin, printMixin, Letter],
  props: {
    patientData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      clientData: this.$store.getters.userLoggedIn.clinic,
      last: false,
      doctorSip: '',
      menuDate: false,
      resource: {
        doctor: [],
      },
      input: {
        date_start: new Date().toISOString().substr(0, 10),
        date_end: new Date().toISOString().substr(0, 10),
        doctor: '',
        height: null,
        weight: null,
        sistole: null,
        diastole: null,
        pulse: null,
        respiration: null,
        notes: null,
      },
      timestamps: new Date(),
      numberLetter: '',
      disabled: false,
    };
  },
  computed: {
    letterData() {
      return Constant.client.find(
        item =>
          item.name.toLowerCase() ===
          this.$store.getters.userLoggedIn.clinic.name.toLowerCase(),
      );
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.runApiCalls(() => {
        this.getSickLetter();
        this.getDoctor();
      });
    });
  },
  methods: {
    getDoctor() {
      axios
        .get(Constant.apiUrl.concat('/master/staff'))
        .then(response => {
          if (response.status === 200) {
            var data = response.data.data;
            this.resource.doctor = data
              .filter(
                v => v.role.role.toLowerCase() === 'dokter' && v.role.status,
              )
              .map(doctor => {
                return {
                  doctorId: doctor._id.toString(),
                  doctorName: doctor.detail.name,
                };
              });

            // this.getOutpatientLetter();
          }
        })
        .catch(() => {
          this.resource.doctor = [];
        });
    },
    addSickLetter() {
      if (!this.$refs.form.validate()) return;
      axios
        .post(Constant.apiUrl.concat('/letter/sick-patient-letter'), {
          id_registration: this.patientData.id_registration,
          letter_number: this.numberLetter,
          detail: {
            ...this.input,
            doctor: this.input.doctor.doctorId,
            timestamps: {
              created_by: this.$store.getters.userLoggedIn.id,
            },
          },
        })
        .then(() => {
          Swal.fire('Surat Keterangan Sakit Berhasil Dibuat', '', 'success');
          this.getSickLetter();

          // this.$emit('close-form');
        })
        .catch(err => {
          this.showErrorAxios(err);
        });
    },
    printSickLetter() {
      if (!this.$refs.form.validate()) return;
      const doc = this.generateSickLetter(this.patientData, this.input);
      this.printDocument(doc, {
        docName: 'Surat Keterangan Sakit',
        rmNumber: this.patientData.rmNumber,
      });
    },
    generateNumber() {
      axios
        .get(Constant.apiUrl.concat('/letter/sick-patient-letter/generate'))
        .then(response => {
          this.numberLetter = response.data.data;
        });
    },
    getSickLetter() {
      axios
        .get(
          Constant.apiUrl.concat(
            `/letter/sick-patient-letter/registration/${this.patientData.id_registration}`,
          ),
        )
        .then(response => {
          this.disabled = false;
          const sick = response.data.data;
          this.numberLetter = sick.letter_number;
          this.input = {
            date_start: _.get(sick, 'detail.date_start', ''),
            date_end: _.get(sick, 'detail.date_end', ''),
            doctor: {
              doctorId: sick.detail.doctor._id,
              doctorName: sick.detail.doctor.detail[0].name,
            },
            height: _.get(sick, 'detail.height', ''),
            weight: _.get(sick, 'detail.weight', ''),
            sistole: _.get(sick, 'detail.sistole', ''),
            diastole: _.get(sick, 'detail.diastole', ''),
            pulse: _.get(sick, 'detail.pulse', ''),
            respiration: _.get(sick, 'detail.respiration', ''),
            notes: _.get(sick, 'detail.notes', ''),
          };
          this.doctorSip = sick.detail.doctor.detail[0].sip;
        })
        .catch(err => {
          if (err.response.status === 404) {
            this.getDataAssessment();
            this.disabled = true;
            this.input.doctor = {
              doctorId: this.patientData.meta.doctorId,
              doctorName: this.patientData.meta.doctor,
            };
            this.generateNumber();
          } else this.showErrorAxios(err);
        });
    },
    async getDataAssessment() {
      try {
        const response = await axios.get(
          Constant.apiUrl.concat(
            `/patient/checkup/regist/${this.patientData.id_registration}/service`,
          ),
        );
        const { data } = response.data;
        this.input = {
          ...this.input,
          height: data.objective.height === 0 ? null : data.objective.height,
          weight: data.objective.weight === 0 ? null : data.objective.weight,
          sistole: data.objective.sistol || 0,
          diastole: data.objective.diastol || 0,
          respiration: data.objective.respiration_rate || 0,
          pulse: data.objective.heart_rate || 0,
        };
      } catch {
        this.input = {
          ...this.input,
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.body-container {
  padding-left: 150px;
}
.form-body {
  &__title {
    font-size: 14px;
    font-weight: 700;
    color: black;
    text-align: left;
  }
  &__data {
    height: 20px;
  }
}

.scrollbar {
  height: 400px;
  overflow-y: auto;

  /* Custom scrollbar */
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #9e9e9e;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: #c2c2c2;
  }
}
</style>
