var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"main-card"},[_c('v-card-title',{staticClass:"justify-end"},[_c('h3',[_vm._v("Pembuatan Surat")])]),_c('v-card-text',{staticClass:"pb-0"},[_c('v-tabs',{attrs:{"centered":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[(
          _vm.isInpatient || _vm.isIGD || _vm.isGeneral || _vm.isDental || _vm.isKIA || _vm.isVaccine
        )?_c('v-tab',{key:"outpatient",staticClass:"text-none font-weight-bold",staticStyle:{"width":"150px"}},[_vm._v("Surat Kontrol Rawat Jalan")]):_vm._e(),(
          !_vm.isInpatient || _vm.isIGD || _vm.isGeneral || _vm.isDental || _vm.isKIA || _vm.isVaccine
        )?_c('v-tab',{key:"inpatient",staticClass:"text-none font-weight-bold",staticStyle:{"width":"150px"}},[_vm._v("Surat Perintah Rawat Inap")]):_vm._e(),(
          (_vm.isBpjs && _vm.isInpatient) ||
            (_vm.isBpjs && _vm.isIGD) ||
            (_vm.isBpjs && _vm.isGeneral) ||
            (_vm.isBpjs && _vm.isDental) ||
            (_vm.isBpjs && _vm.isKIA) ||
            (_vm.isBpjs && _vm.isVaccine)
        )?_c('v-tab',{key:"hospitalPcare",staticClass:"text-none font-weight-bold",staticStyle:{"width":"200px"}},[_vm._v("Surat Rujukan ke Rumah Sakit")]):_vm._e(),(
          (!_vm.isBpjs && _vm.isInpatient) ||
            (!_vm.isBpjs && _vm.isIGD) ||
            (!_vm.isBpjs && _vm.isGeneral) ||
            (!_vm.isBpjs && _vm.isDental) ||
            (!_vm.isBpjs && _vm.isKIA) ||
            (!_vm.isBpjs && _vm.isVaccine)
        )?_c('v-tab',{key:"hospital",staticClass:"text-none font-weight-bold",staticStyle:{"width":"200px"}},[_vm._v("Surat Rujukan ke Rumah Sakit")]):_vm._e(),(
          _vm.isInpatient || _vm.isIGD || _vm.isGeneral || _vm.isDental || _vm.isKIA || _vm.isVaccine
        )?_c('v-tab',{key:"mortality",staticClass:"text-none font-weight-bold",staticStyle:{"width":"200px"}},[_vm._v("Surat Keterangan Kematian")]):_vm._e(),(
          _vm.isInpatient || _vm.isIGD || _vm.isGeneral || _vm.isDental || _vm.isKIA || _vm.isVaccine
        )?_c('v-tab',{key:"sick",staticClass:"text-none font-weight-bold",staticStyle:{"width":"220px"}},[_vm._v("Surat Keterangan Sakit")]):_vm._e(),(
          !_vm.isInpatient || _vm.isIGD || _vm.isGeneral || _vm.isDental || _vm.isKIA || _vm.isVaccine
        )?_c('v-tab',{key:"healthy",staticClass:"text-none font-weight-bold",staticStyle:{"width":"220px"}},[_vm._v("Surat Keterangan Dokter")]):_vm._e()],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[(
          _vm.isInpatient || _vm.isIGD || _vm.isGeneral || _vm.isDental || _vm.isKIA || _vm.isVaccine
        )?_c('v-tab-item',{key:"outpatient"},[_c('OutpatientWarrant',{attrs:{"patient-data":_vm.patientData},on:{"close-form":function($event){return _vm.$emit('close-form')}}})],1):_vm._e(),(
          !_vm.isInpatient || _vm.isIGD || _vm.isGeneral || _vm.isDental || _vm.isKIA || _vm.isVaccine
        )?_c('v-tab-item',{key:"inpatient"},[_c('InpatientWarrant',{attrs:{"patient-data":_vm.patientData},on:{"close-form":function($event){return _vm.$emit('close-form')}}})],1):_vm._e(),(
          (_vm.isBpjs && _vm.isInpatient) ||
            (_vm.isBpjs && _vm.isIGD) ||
            (_vm.isBpjs && _vm.isGeneral) ||
            (_vm.isBpjs && _vm.isDental) ||
            (_vm.isBpjs && _vm.isKIA) ||
            (_vm.isBpjs && _vm.isVaccine)
        )?_c('v-tab-item',{key:"hospitalPcare"},[_c('HospitalPcare',{attrs:{"patient-data":_vm.patientData},on:{"close-form":function($event){return _vm.$emit('close-form')}}})],1):_vm._e(),(
          (!_vm.isBpjs && _vm.isInpatient) ||
            (!_vm.isBpjs && _vm.isIGD) ||
            (!_vm.isBpjs && _vm.isGeneral) ||
            (!_vm.isBpjs && _vm.isDental) ||
            (!_vm.isBpjs && _vm.isKIA) ||
            (!_vm.isBpjs && _vm.isVaccine)
        )?_c('v-tab-item',{key:"hospital"},[_c('Hospital',{attrs:{"patient-data":_vm.patientData},on:{"close-form":function($event){return _vm.$emit('close-form')}}})],1):_vm._e(),(
          _vm.isInpatient || _vm.isIGD || _vm.isGeneral || _vm.isDental || _vm.isKIA || _vm.isVaccine
        )?_c('v-tab-item',{key:"mortality"},[_c('Mortality',{attrs:{"patient-data":_vm.patientData},on:{"close-form":function($event){return _vm.$emit('close-form')}}})],1):_vm._e(),(
          _vm.isInpatient || _vm.isIGD || _vm.isGeneral || _vm.isDental || _vm.isKIA || _vm.isVaccine
        )?_c('v-tab-item',{key:"sick"},[_c('Sick',{attrs:{"patient-data":_vm.patientData},on:{"close-form":function($event){return _vm.$emit('close-form')}}})],1):_vm._e(),(
          !_vm.isInpatient || _vm.isIGD || _vm.isGeneral || _vm.isDental || _vm.isKIA || _vm.isVaccine
        )?_c('v-tab-item',{key:"healthy"},[_c('Healthy',{attrs:{"patient-data":_vm.patientData},on:{"close-form":function($event){return _vm.$emit('close-form')}}})],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }