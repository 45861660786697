<template>
  <v-card class="d-flex dental-main">
    <history-sidebar v-if="!isEmr" />
    <v-container fluid>
      <header-assessment
        :data="headerData"
        pl="2vw"
        pr="2vw"
        mb="2vw"
        title="Assesmen Poli Gigi"
      />
      <v-main>
        <!-- EMR -->
        <input-assessment
          v-if="!emrData"
          v-on="$listeners"
          @close-form="$emit('close-form')"
          :patient-data="patientData"
          :tab="tabs"
        />

        <!-- EMR -->
        <input-assessment
          v-else
          v-on="$listeners"
          @close-form="$emit('close-form')"
          :patient-data="patientData"
          :emr-data="emrData"
          :is-emr="isEmr"
        />
      </v-main>
    </v-container>
  </v-card>
</template>

<script>
// import HistoryAssessment from './HistoryAssessment.vue';
import HistorySidebar from '../AssessmentComponents/HistorySidebar.vue';
import InputAssessment from './InputAssessment.vue';
import HeaderAssessment from '../AssessmentComponents/HeaderAssessment.vue';

const moment = require('moment');

export default {
  name: 'DentalPolyAssestment',
  components: { InputAssessment, HistorySidebar, HeaderAssessment },
  props: {
    patientData: {
      type: Object,
      default: null,
    },
    emrData: {
      type: Object,
      default: null,
    },
    isEmr: Boolean,
  },
  data() {
    return {
      headerData: '',
    };
  },
  mounted() {
    this.headerData = [
      {
        label: 'No.RM',
        text: this.patientData.rmNumber,
      },
      {
        label: 'Nama',
        text: `${this.patientData.meta.name} (${
          this.patientData.meta.gender === 'Laki-laki' ? 'L' : 'P'
        })`,
      },
      {
        label: 'Tanggal Lahir',
        text: `${moment(this.patientData.meta.birthDate, 'DD MMMM YYYY').format(
          'DD/MM/YYYY',
        )} (${this.patientData.meta.age} Tahun)`,
      },
      {
        label: 'Hambatan dalam Berkomunikasi',
        text: this.patientData.commmunicationObstacles || 'Tidak Ada',
      },
    ];
  },
};
</script>

<style lang="scss" scoped>
.dental-main {
  font-family: 'Roboto';
  height: 100%;
}
</style>
