<template>
  <div class="main-goods">
    <v-toolbar flat outlined>
      <v-container fluid>
        <v-row align="center">
          <v-col>
            <v-toolbar-title class="font-weight-bold float-left">
              Data Barang Rumah Tangga
            </v-toolbar-title>
          </v-col>

          <v-spacer></v-spacer>

          <v-btn
            class="mr-2"
            color="grey"
            icon
            text
            @click="refreshAndAutoUpdate"
          >
            <v-icon>mdi-autorenew</v-icon>
          </v-btn>
          <v-text-field
            class="input"
            v-model="search"
            background-color="grey lighten-3"
            placeholder="Search"
            solo
            dense
            hide-details
            flat
            prepend-inner-icon="mdi-magnify"
            single-line
          ></v-text-field>
        </v-row>
      </v-container>
    </v-toolbar>

    <v-main>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="table.headers"
              :items="items.dataGoods.data"
              :expanded="expanded"
              :loading="items.dataGoods.isLoading"
              single-expand
              item-key="id"
              class="expand"
              :items-per-page="itemsPerRow"
              hide-default-footer
              @click:row="
                (item, slot) => {
                  slot.expand(!slot.isExpanded);
                }
              "
            >
              <template v-slot:[`item.isActive`]="{ item }">
                <td>
                  <v-switch
                    v-model="item.isActive"
                    :label="`${item.isActive ? 'Aktif' : 'Tidak'}`"
                    color="info"
                    @click="changeIsActive(item)"
                    inset
                    dense
                  ></v-switch>
                </td>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="mr-4 icon-style"
                      dense
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteItem(item)"
                      color="red"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span> Hapus </span>
                </v-tooltip>
              </template>
              <template v-slot:expanded-item="{ item, headers }">
                <td :colspan="headers.length" class="expand">
                  <v-card flat color="grey lighten-4" class="text-right">
                    <v-container>
                      <v-row no-gutters>
                        <v-col cols="12">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                x-small
                                icon
                                class="mx-2 hover"
                                @click="openDialog(item, 'detail')"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon>mdi-clipboard-text</v-icon>
                              </v-btn>
                            </template>
                            <span>Detail Barang</span>
                          </v-tooltip>

                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                x-small
                                icon
                                class="mx-2 hover"
                                v-bind="attrs"
                                v-on="on"
                                @click="openDialog(item, 'edit')"
                              >
                                <v-icon>mdi-clipboard-plus</v-icon>
                              </v-btn>
                            </template>

                            <span>Ubah Barang</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-footer color="white">
      <v-container fluid>
        <v-row>
          <v-pagination
            v-model="table.pagination.page"
            :length="goodsPages"
            :total-visible="10"
            @input="getAllGoodsQueue()"
          ></v-pagination>
          <v-spacer></v-spacer>

          <template>
            <v-btn color="primary" fab dark @click="openDialog(null, 'add')">
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </template>
        </v-row>
      </v-container>
    </v-footer>
    <v-dialog v-model="dialog" max-width="1200" presistent>
      <FormGoods
        v-if="dialog"
        ref="formGoods"
        @close-dialog="closeDialog"
        :state="formState"
        @update-data="refreshAndAutoUpdate"
      />
    </v-dialog>
  </div>
</template>

<script>
import Swal from 'sweetalert2';

import jwtMixin from '@/mixin/jwtMixin';
import alertMixin from '@/mixin/alertMixin';
import formatMixin from '@/mixin/formatMixin';

import FormGoods from './components/Goods/FormGoods.vue';

import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(
  'MasterGoods',
);
const _ = require('lodash');

export default {
  name: 'MasterdataGoods',
  mixins: [jwtMixin, alertMixin, formatMixin],
  components: {
    FormGoods,
  },
  data() {
    return {
      expanded: [],
      detailDialog: false,
      updateDialog: false,
      goods: [],
      packaging: [],
      text: 'Data Aktif Diubah',
      timeout: 1000,
      goodsPages: 0,
      table: {
        headers: [
          {
            text: 'Nama',
            align: 'start',
            value: 'name',
          },
          { text: 'Kategori', value: 'category', sortable: false },

          { text: 'Pabrik', value: 'factory', sortable: false },
          { text: 'Hpp', value: 'hppDisplay', sortable: false },

          { text: 'Status', value: 'isActive', sortable: false },
          { text: '', value: 'actions', sortable: false },
        ],
        pagination: {
          descending: false,
          page: 1,
          rowsPerPage: 7,
          sortBy: '',
        },
      },
      search: '',
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshAndAutoUpdate();
    });
  },
  watch: {
    search() {
      this.searchData(this);
      this.table.pagination.page = 1;
    },
  },
  computed: {
    ...mapGetters(['getItems', 'getDialog']),
    dialog: {
      get() {
        return this.getDialog;
      },
    },
    items: {
      get() {
        return this.getItems;
      },
    },
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
  },
  methods: {
    ...mapMutations(['setDialog', 'setForm', 'clearForm']),
    ...mapActions([
      'resolveGetAllGoods',
      'resolveDeleteGoods',
      'resolvePutStatusGoods',
    ]),
    openDialog(item, state) {
      this.clearForm();
      this.setDialog(true);
      this.formState = state;
      switch (state) {
        case 'add':
          break;
        case 'edit':
          this.setForm({ id: item.id });
          break;
        case 'detail':
          this.setForm({ id: item.id });
          break;
      }
    },
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.getAllGoodsQueue();
    }, 650),
    closeDialog() {
      this.setDialog(false);
    },

    changeIsActive(item) {
      const goodsSave = { detail: [] };
      const getGoods = {
        name: item.name,
        barcode: item.barcode,
        hna: item.hna,
        hpp: item.hpp,
        category: item.category,
        isActive: item.isActive,
        factory: item.factory,
        packaging: item.packaging,
        min_stock: item.min_stock,
        timestamps: {
          created_by: '',
        },
      };
      goodsSave.detail.push(getGoods);
      goodsSave.detail[0].timestamps.created_by = this.$store.getters.userLoggedIn.id;
      this.resolvePutStatusGoods({
        id: item.id,
        payload: { detail: goodsSave.detail },
      })
        .then(() => {
          this.getAllGoodsQueue();
        })
        .catch(error => this.showErrorAxios(error));
    },

    getAllGoodsQueue() {
      const itemCount = this.itemsPerRow;
      const halaman = this.table.pagination.page;
      let sortBy = '';
      let keyword = this.search;
      this.resolveGetAllGoods({
        halaman,
        itemCount,
        sortBy,
        keyword,
      }).then(item => {
        this.goodsPages = Math.ceil(
          (item === undefined ? 0 : item) / itemCount,
        );
      });
    },

    deleteItem(item) {
      Swal.fire({
        title: 'Menghapus Data',
        html: `Anda akan menghapus Master Data<br><b>${item.name}</b>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
      }).then(result => {
        if (result.value) {
          this.resolveDeleteGoods(item.id).then(() => {
            Swal.fire('Berhasil', `${item.name} berhasil dihapus`, 'success');
            this.getAllGoodsQueue();
          });
        }
      });
    },

    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.getAllGoodsQueue();
      this.interval = setInterval(this.getAllGoodsQueue, 60000);
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.input {
  max-width: 30%;
}

.hover:hover {
  color: #2196f3;
}

::v-deep {
  .v-data-table
    > .v-data-table__wrapper
    tbody
    tr.v-data-table__expanded__content {
    box-shadow: none;
    background-color: #f4f5f5;
  }

  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border: none;
  }
}
</style>
