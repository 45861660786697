<template>
  <div>
    <v-toolbar flat>
      <v-container fluid>
        <v-row align="center">
          <transition name="slide-fade">
            <v-col>
              <v-toolbar-title class="font-weight-bold float-left">
                Tagihan Pasien BPJS
              </v-toolbar-title>
            </v-col>
          </transition>
          <v-spacer></v-spacer>
          <v-btn class="mr-2" color="grey" icon text>
            <router-link to="/keuangan/billing">
              <v-icon>mdi-home</v-icon>
            </router-link>
          </v-btn>
          <v-btn
            @click="exportIncomeReport()"
            class="text-none"
            color="primary"
            tile
            depressed
            >Export</v-btn
          >
        </v-row>
      </v-container>
    </v-toolbar>
    <v-container class="bpjs-billing-container">
      <div class="grid-billing mb-4">
        <h4 class="text-start">Jumlah Tagihan Pasien BPJS</h4>
        <v-menu
          offset-y
          :close-on-content-click="false"
          v-model="totalBillBpjs.monthPickerRatio"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              hide-details
              class="result-filter text-end"
              readonly
              v-model="totalBillBpjs.dateTextRatio"
              v-on="on"
              placeholder="Bulan, tahun"
              outlined
              dense
              single-line
              required
            ></v-text-field>
          </template>
          <v-date-picker
            type="month"
            v-model="totalBillBpjs.selectedMonthRatio"
            @input="changeDateBpjs"
          ></v-date-picker>
        </v-menu>
      </div>
      <div class="billing-container">
        <div
          class="grid-billing item mb-2"
          v-for="(item, index) in bpjsBilling"
          :key="'item-' + index"
        >
          <span class="font-weight-bold text-start">
            {{ item.text }}
          </span>
          <span class="text-end font-weight-medium">
            {{ item.billing }}
          </span>
        </div>
      </div>
      <div class="grid-billing total mb-4">
        <h4 class="text-start">Total Tagihan</h4>
        <h4 class="text-end">{{ currency(totalBpjsBilling || 0) }}</h4>
      </div>
      <v-card rounded="9" elevation="0" class="result-card bpjs">
        <div class="result-grid">
          <h4 class="text-start">Perbandingan Pendapatan Kapitasi</h4>
          <v-menu
            offset-y
            :close-on-content-click="false"
            v-model="secRatio.monthPickerRatio"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                class="result-filter text-end"
                readonly
                v-model="secRatio.dateTextRatio"
                v-on="on"
                placeholder="Bulan, tahun"
                outlined
                dense
                single-line
                required
              ></v-text-field>
            </template>
            <v-date-picker
              type="month"
              v-model="secRatio.selectedMonthRatio"
              @input="changeDate"
            ></v-date-picker>
          </v-menu>
          <span class="text-start">Pendapatan Kapitasi BPJS</span>
          <span class="text-end">{{
            currency(secRatioCapitationInc.capitationIncome || 0)
          }}</span>
          <span class="text-start">Tagihan Pasien BPJS</span>
          <span class="text-end">{{
            currency(secRatioCapitationInc.serviceIncome || 0)
          }}</span>
        </div>
        <v-divider></v-divider>
        <v-row class="pa-4">
          <v-col class="d-flex justify-space-between">
            <span class="black--text font-weight-bold">Total Selisih</span>
            <span class="black--text font-weight-bold">{{
              currency(secRatioCapitationInc.difference || 0)
            }}</span>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Constant from '@/const';
import moment from 'moment-timezone';
import formatMixin from '@/mixin/formatMixin';
import jwtMixin from '@/mixin/jwtMixin';
import Swal from 'sweetalert2';

const axios = require('axios');
export default {
  name: 'PatientBillingBPJS',
  mixins: [formatMixin, jwtMixin],

  data() {
    return {
      bpjsBilling: [],
      totalBpjsBilling: 0,
      totalBillBpjs: {
        monthPickerRatio: '',
        dateTextRatio: '',
        selectedMonthRatio: '',
      },
      secRatio: {
        monthPickerRatio: '',
        dateTextRatio: '',
        selectedMonthRatio: '',
      },
      secRatioCapitationInc: {
        capitationIncome: 0,
        serviceIncome: 0,
        difference: 0,
      },
    };
  },
  mounted() {
    this.showIncomeDataBPJS();
    this.showTotalCapitationIncome();
  },
  methods: {
    changeDate() {
      this.secRatio.dateTextRatio = moment(
        this.secRatio.selectedMonthRatio,
        'YYYY-MM',
      )
        .locale('id')
        .format('MMMM, YYYY');
      this.secRatio.monthPickerRatio = false;
      this.showTotalCapitationIncome();
    },
    changeDateBpjs() {
      this.totalBillBpjs.dateTextRatio = moment(
        this.totalBillBpjs.selectedMonthRatio,
        'YYYY-MM',
      )
        .locale('id')
        .format('MMMM, YYYY');
      this.totalBillBpjs.monthPickerRatio = false;
      this.showIncomeDataBPJS();
    },
    showIncomeDataBPJS() {
      let date = '';
      if (this.totalBillBpjs.selectedMonthRatio == '')
        date = moment().format('YYYY-MM');
      else date = this.totalBillBpjs.selectedMonthRatio;

      let startDate = moment(date, 'YYYY-MM')
        .startOf('month')
        .format('YYYY-MM-DD');
      let endDate = moment(date, 'YYYY-MM')
        .endOf('month')
        .format('YYYY-MM-DD');

      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/report/billing/income?start_date=${startDate}&last_date=${endDate}&type[]=bpjs`,
            ),
          )
          .then(response => {
            const { data } = response.data;

            this.bpjsBilling = data[0].detail.map(v => {
              return {
                text: `Tagihan ${v.unit}`,
                billing: this.currency(v.total_amount || 0),
              };
            });
            this.totalBpjsBilling = data[0].detail.reduce(
              (acc, item) => acc + item.total_amount,
              0,
            );
          });
      });
    },
    async exportIncomeReport() {
      try {
        const response = await axios.get(
          Constant.apiUrl.concat(
            `/report/billing/income_component?start_date=${
              this.startDate === null ? '' : this.startDate
            }&last_date=${this.endDate === null ? '' : this.endDate}`,
          ),
        );
        if (response.status === 200) {
          this.showFillSuccess('Export Berhasil');
          const { url } = response.config;
          window.open(url, '_blank');
        }
      } catch (error) {
        if (error) {
          Swal.fire({
            icon: 'error',
            title: 'Terjadi Kesalahan',
            text: 'Tidak dapat mengexport dokumen',
          });
        }
      }
    },
    showTotalCapitationIncome() {
      let date = '';
      if (this.secRatio.selectedMonthRatio != '')
        date = moment(this.secRatio.selectedMonthRatio, 'YYYY-MM').format(
          'MM/YYYY',
        );
      this.runApiCalls(() => {
        axios
          .get(Constant.apiUrl.concat(`/finance/captincome/total?date=${date}`))
          .then(response => {
            const { data } = response.data;
            this.secRatioCapitationInc = {
              difference: data.total_difference,
              capitationIncome: data.total_capitation_income,
              serviceIncome: data.total_billing_income,
            };
            this.secRatio = {
              ...this.secRatio,
              dateTextRatio: data.date
                ? moment(data.date, 'MM/YYYY')
                    .locale('id')
                    .format('MMMM, YYYY')
                : '',
            };
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bpjs-billing-container {
  height: 73.5vh;

  .grid-billing {
    background-color: #f5f5f5;
    padding: 0.7rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;

    &.item {
      border: 1px solid #ededed;
      background-color: white;
      color: #2d9cdb;
      border-radius: 4px;
    }

    &.total {
      background-color: #deeef9;
    }

    .result-filter {
      background-color: white;
      width: 30%;
      margin-left: auto;
    }
  }
}
.result-card {
  background-color: #f0f9ff;
  width: 98%;
  margin: auto;

  &.bpjs {
    margin: 0;
    width: 100%;
  }

  .result-grid {
    display: grid;
    width: 100%;
    padding: 1rem;
    row-gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 2.5rem repeat(2, 1fr);
    span {
      color: #616161;
    }

    .result-filter {
      background-color: white;
      width: 30%;
      margin-left: auto;
    }
  }
}
</style>
