<template>
  <v-card>
    <v-card-title class="justify-center">
      <h3>Detail Diagnosa Baru</h3>
      <v-btn absolute right @click="onCloseButtonClick()" small text>
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row no-gutters justify="center">
          <v-col md="5">
            <div class="detail-input">
              <div class="detail-input__label">
                <label for="name">Kode Diagnosa</label>
              </div>

              <v-text-field
                disabled
                v-model="diagnose.code"
                placeholder="Masukkan Kode Diagnosa"
                class="detail-input__input-text"
                required
              />
            </div>
            <div class="detail-input">
              <div class="detail-input__label">
                <label for="name">Deskripsi Diagnosa</label>
              </div>

              <v-textarea
                disabled
                v-model="diagnose.description"
                placeholder="Tambahkan Deskripsi Diagnosa"
                class="detail-input__input-text"
                name="deskripsidiagnosa"
                outlined
                height="200"
              ></v-textarea>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ['diagnoseDetail'],
  data() {
    return {
      diagnose: {
        id: this.diagnoseDetail.id,
        code: this.diagnoseDetail.code,
        description: this.diagnoseDetail.description,
      },
    };
  },
  methods: {
    onCloseButtonClick() {
      this.$emit('close-dialog');
    },
  },
};
</script>

<style lang="scss" scoped>
.column {
  margin: 0 auto;
}

.detail-radio {
  display: flex;
  justify-content: flex-end;
}

.detail-input {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  &__label {
    justify-content: flex-end;
    flex: 1;
    display: flex;
    color: #222;
  }

  &__input-text {
    margin-left: 10px;
    width: 150px;
  }
}

.padding {
  padding-left: 10px;
}

.radio {
  margin: 0;
}

.table-kemasan {
  width: 100%;
  margin-top: 20px;
}
</style>
