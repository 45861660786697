<template>
  <div>
    <v-toolbar flat>
      <v-row class="pt-3">
        <v-col cols="5" class="text-left">
          <h2>Kas Masuk dan Keluar</h2>
          <h5>Update : {{ timestamp }}</h5>
        </v-col>
        <v-col cols="2" class="pt-6">
          <v-dialog v-model="dialogCategory" max-width="1200px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#59ABE1"
                tile
                depressed
                class="text-none white--text"
                v-bind="attrs"
                v-on="on"
              >
                Buat/Edit Kategori
              </v-btn>
            </template>
            <category-create-edit
              @close-form="dialogCategory = false"
            ></category-create-edit>
          </v-dialog>
        </v-col>
        <v-col cols="2" class="pt-5">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                hide-details="auto"
                v-model="dateFilter"
                class="input"
                outlined
                placeholder="Pilih Tanggal"
                prepend-inner-icon="mdi-calendar-month"
                readonly
                dense
                v-bind="attrs"
                v-on="on"
                clearable
                @click:clear="getItem()"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              no-title
              scrollable
              @input="
                filterDate();
                menu = false;
              "
            />
          </v-menu>
        </v-col>
        <v-col cols="3" class="pt-5">
          <v-text-field
            class="input"
            background-color="grey lighten-3"
            placeholder="Search"
            solo
            dense
            hide-details
            flat
            prepend-inner-icon="mdi-magnify"
            single-line
            v-model="search"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-col class="text-right pt-0 pr-4">
      <router-link :to="history" active>
        <v-btn outlined tile color="#59ABE1" class="text-none">
          Riwayat Transaksi
        </v-btn>
      </router-link>
    </v-col>
    <v-divider></v-divider>
    <v-data-table
      :headers="cash.headers"
      :items="cash.dataItems"
      class="cashTable"
      :page.sync="table.pagination.page"
      hide-default-footer
      :items-per-page="5"
      :search="search"
    >
      <template v-slot:[`item.no`]="{ item }">
        <tr class="text-left" :key="index">
          <td>
            {{ cash.dataItems.indexOf(item) + 1 }}
          </td>
        </tr>
      </template>
      <template v-slot:[`item.nominal`]="{ item }">
        <v-icon color="green" v-if="item.cashType === 'debit'">
          mdi-plus-box</v-icon
        >
        <v-icon color="red" v-if="item.cashType === 'kredit'"
          >mdi-minus-box</v-icon
        >
        {{ item.nominal }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-4 icon-style"
              @click="deleteItem(item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-delete
            </v-icon>
          </template>
          <span> Hapus Transaksi</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click="onClickFooter(0, item)"
              v-bind="attrs"
              v-on="on"
              class="icon-style"
            >
              mdi-square-edit-outline
            </v-icon>
          </template>
          <span>Edit Transaksi</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-card-actions>
      <v-pagination
        v-model="table.pagination.page"
        :length="itemPages"
        total-visible="7"
      >
      </v-pagination>
      <v-spacer></v-spacer>
      <template>
        <v-btn
          color="primary"
          fab
          elevation="0"
          class="btn-add"
          @click="onClickFooter(1, '')"
        >
          <v-icon>
            mdi-plus
          </v-icon>
        </v-btn>
        <v-dialog v-model="dialog" max-width="1200px">
          <edit-data
            @close-form="closeForm"
            v-if="selectedForm == 0"
            @change-form="changeForm"
          ></edit-data>

          <new-input-cash
            v-if="selectedForm == 1"
            @change-form="changeForm"
            @close-form="closeForm"
          ></new-input-cash>
        </v-dialog>
      </template>
    </v-card-actions>
  </div>
</template>

<script>
import Constant from '@/const';
const moment = require('moment-timezone');
const axios = require('axios');
import Swal from 'sweetalert2';
import CategoryCreateEdit from './CashInput/CategoryCreateEdit.vue';
import EditData from './CashInput/EditData.vue';
import NewInputCash from './CashInput/NewInputCash.vue';
import jwtMixin from '@/mixin/jwtMixin';

export default {
  name: 'CashInput',
  mixins: [jwtMixin],
  components: {
    NewInputCash,
    CategoryCreateEdit,
    EditData,
  },
  data() {
    return {
      search: '',
      itemPages: '',
      menu: false,
      date: new Date().toISOString().substr(0, 10),
      dateFilter: '',
      dialog: false,
      dialogEdit: false,
      dialogCategory: false,
      timestamp: '',
      table: {
        pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 5,
          totalItems: null,
        },
      },
      cash: {
        headers: [
          { text: 'No', value: 'no' },
          { text: 'Tanggal Transaksi', value: 'transDate' },
          { text: 'Nama Transaksi', value: 'transName' },
          { text: 'Sub Kategori', value: 'categorySub' },
          { text: 'Jenis Kas', value: 'cashType' },
          { text: 'Nominal', value: 'nominal' },
          { text: '', value: 'actions' },
        ],
        dataItems: [],
      },
      selectedForm: null,
    };
  },
  watch: {
    dialog(newValue) {
      if (!newValue) {
        this.selectedForm = null;
      }
    },
    date(val) {
      if (val) {
        this.dateFilter = this.date;
      }
    },
  },
  mounted() {
    this.$nextTick(function() {
      this.refreshAndAutoUpdate();
    });
  },
  computed: {
    history() {
      return '/keuangan/kas-masuk-keluar/riwayat-transaksi';
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    filterDate() {
      const itemCount = 5;
      const date = this.date;
      this.runApiCalls(() => {
        axios
          .get(Constant.apiUrl.concat('/finance/report?search=').concat(date))
          .then(response => {
            if (response.status == 200) {
              const getData = response.data.data;
              this.itemPages = Math.ceil(response.data.data.length / itemCount);
              this.cash.dataItems = getData
                ? getData.map(item => {
                    return {
                      id: item._id.toString(),
                      transDate: moment(item.date).format('DD/MM/YYYY'),
                      transName: item.detail[0].name_transaction,
                      categorySub: item.sub_category,
                      cashType:
                        item.detail[0].type_fund === 'debit'
                          ? 'Kas Masuk'
                          : 'Kas Keluar',
                      nominal: this.formatNumber(
                        ` Rp. ${item.detail[0].total}`,
                      ),
                    };
                  })
                : [];
              this.menu = false;
            } else {
              this.cash.dataItems = [];
            }
          })
          .catch(() => {
            this.cash.dataItems = [];
          });
      });
    },

    onClickFooter(index, cashInput) {
      this.changeSelectedItem(cashInput);

      switch (index) {
        default:
          this.changeForm(index);
          break;
      }
      this.dialog = true;
    },

    changeSelectedItem(cashInput) {
      localStorage.setItem('cashInput_id', cashInput.id);
    },
    changeForm(item) {
      this.selectedForm = item;
    },

    closeForm() {
      this.dialog = false;
      this.getItem();
    },
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    },
    getItem() {
      const itemCount = 5;
      this.runApiCalls(() => {
        axios
          .get(Constant.apiUrl.concat('/finance/report'))
          .then(response => {
            const getDataItem = response.data.data;
            const item = getDataItem.map(item => {
              return {
                id: item._id.toString(),
                transDate: moment(item.date).format('DD/MM/YYYY'),
                transName: item.detail[0].name_transaction,
                categorySub: item.sub_category,
                cashType:
                  item.detail[0].type_fund === 'debit'
                    ? 'Kas Masuk'
                    : 'Kas Keluar',
                nominal: this.formatNumber(` Rp. ${item.detail[0].total}`),
                isDeleted: item.isDeleted !== undefined,
              };
            });
            this.cash.dataItems = item.filter(x => x.isDeleted === false);
            this.itemPages = Math.ceil(this.cash.dataItems.length / itemCount);
          })
          .catch(() => {
            this.cash.dataItems = [];
          });
      });
    },

    deleteItem(data) {
      Swal.fire({
        title: 'Yakin?',
        text: `Anda akan menghapus Data ${data.transName}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak',
      }).then(result => {
        if (result.value) {
          axios
            .delete(Constant.apiUrl.concat('/finance/report/').concat(data.id))
            .then(response => {
              if (response.status === 200) {
                Swal.fire('Berhasil', `${response.data.pesan}`, 'success');
                this.getItem();
              }
            });
        }
      });
    },
    getNow() {
      const monthNames = [
        'Januari',
        'Februari',
        'Maret',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Agustus',
        'September',
        'Oktober',
        'November',
        'Desember',
      ];
      const today = new Date();
      const date =
        today.getDate() +
        ' ' +
        monthNames[today.getMonth()] +
        ' ' +
        today.getFullYear();
      this.timestamp = date;
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.getItem();
      this.interval = setInterval(this.getItem, 60000);
    },
  },
  created() {
    setInterval(this.getNow, 500);
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.cashTable {
  width: 100%;
}

.pagination {
  position: absolute;
  bottom: 50px;
}

.btn-add {
  position: absolute;
  bottom: 40px;
  right: 30px;
}

::v-deep .v-dialog:not(.v-dialog--fullscreen) {
  height: 80%;
}
.icon-style:hover {
  color: #1976d2;
}
a {
  text-decoration: none;
}
</style>
