import {
  putDrugs,
  postDrugs,
  getAllDrugs,
  deleteDrugs,
  getDrugsById,
  putStatusDrugs,
} from '@/fetchApi/MasterData/Drugs';
import {
  getRoa,
  getGroup,
  getFactory,
  getPreparation,
  getCategoryByMenu,
  getPackagesByMenu,
  getPharmacotherapy,
  addFactory,
} from '@/fetchApi/Resource/Drugs';

const state = {
  snackbar: {
    text: '',
    color: '',
    show: false,
  },
  dashboard: {
    dialog: false,
  },
  form: {
    id: null,
    name: '',
    barcode: '',
    hna: 0,
    hpp: 0,
    min_stock: 0,
    category: '',
    shelf: '',
    factory: '',
    strength: '',
    strength_unit: '',
    preparation: '',
    roa: '',
    indication: '',
    c_indication: '',
    ingredients: '',
    side_effects: '',
    isFormulary: null,
    isFornas: null,
    isHighAlert: null,
    isGeneric: null,
    isCatastrophic: null,
    isActive: null,
    isVEN: null,
    group: '',
    pharmacotherapy: '',
    packaging: [],
  },
  items: {
    dataDrugs: {
      isLoading: false,
      data: [],
    },
    factory: {
      isLoading: false,
      data: [],
    },
    categoryByMenu: {
      isLoading: false,
      data: [],
    },
    packagesByMenu: {
      isLoading: false,
      data: [],
    },
    preparation: {
      isLoading: false,
      data: [],
    },
    roa: {
      isLoading: false,
      data: [],
    },
    group: {
      isLoading: false,
      data: [],
    },
    pharmacotherapy: {
      isLoading: false,
      data: [],
    },
  },
};

const getters = {
  // Get State Dialog
  getDialog: state => state.dashboard.dialog,

  // Get State Items
  getItems: state => state.items,

  // Get State Form
  getForm: state => state.form,
};

const mutations = {
  // Set State Dialog
  setDialog(state, payload) {
    state.dashboard.dialog = payload;
  },

  // Set State Items
  setItems(state, payload) {
    const { label, value, isLoading } = payload;
    state.items[label] = {
      ...state[label],
      data: value,
      isLoading,
    };
  },

  // Set State Form
  setForm(state, payload) {
    state.form = payload;
  },

  // Clear Form
  clearForm(state) {
    state.form = {
      id: null,
      name: '',
      barcode: '',
      hna: 0,
      hpp: 0,
      min_stock: 0,
      category: '',
      shelf: '',
      factory: '',
      strength: '',
      strength_unit: '',
      preparation: '',
      roa: '',
      indication: '',
      c_indication: '',
      ingredients: '',
      side_effects: '',
      isFormulary: null,
      isFornas: null,
      isHighAlert: null,
      isGeneric: null,
      isCatastrophic: null,
      isActive: null,
      isVEN: null,
      group: '',
      pharmacotherapy: '',
      packaging: [],
    };
  },
};

const actions = {
  // Get Data
  // Gett All Data Drugs
  async resolveGetAllDrugs(
    { commit, state },
    { halaman, itemCount, sortBy, keyword },
  ) {
    commit('setItems', {
      label: 'dataDrugs',
      value: [...state.items.dataDrugs.data],
      isLoading: true,
    });
    try {
      const drugsResponse = await getAllDrugs({
        halaman,
        itemCount,
        sortBy,
        keyword,
      });

      const drugsMapper = drugsResponse?.data?.map(item => ({
        id: item._id.toString(),
        name: item.detail.name,
        barcode: item.detail.barcode,
        hpp: item.detail.hpp,
        hna: item.detail.hna,
        category: item.detail.category,
        strength: item.detail.strength,
        strength_unit: item.detail.strength_unit,
        preparation: item.detail.preparation,
        indication: item.detail.indication,
        c_indication: item.detail.c_indication,
        side_effects: item.detail.side_effects,
        shelf: item.detail.shelf,
        ingredients: item.detail.ingredients,
        roa: item.detail.roa,
        isFormulary: item.detail.isFormulary,
        isFornas: item.detail.isFornas,
        isHighAlert: item.detail.isHighAlert,
        isPotent: item.detail.isPotent,
        isGeneric: item.detail.isGeneric,
        isCatastrophic: item.detail.isCatastrophic,
        isActive: item.detail.isActive,
        isVEN: item.detail.isVEN,
        factory: item.detail.factory,
        group: item.detail.group,
        min_stock: item.detail.min_stock,
        pharmacotherapy: item.detail.pharmacotherapy,
        packaging: item.detail.packaging.map(p => {
          return {
            isDefault: p.isDefault,
            package: p.package,
            package_unit: p.package_unit,
            quantity: p.quantity,
          };
        }),
      }));
      commit('setItems', {
        label: 'dataDrugs',
        value: drugsMapper,
        isLoading: false,
      });
      return drugsResponse.length;
    } catch {
      commit('setItems', {
        label: 'dataDrugs',
        value: [],
        isLoading: false,
      });
    }
  },
  // Get Data Drugs By Id
  async resolveGetDrugsById({ state, commit }) {
    const responseGetDrugsById = await getDrugsById(state.form.id);
    try {
      commit('setForm', { ...state.form, ...responseGetDrugsById });
      return responseGetDrugsById;
    } catch (error) {
      return error;
    }
  },

  // Get Data Resource
  async fetchDataResource({ commit }, { label, apiFunction, params }) {
    commit('setItems', {
      label,
      value: [],
      isLoading: true,
    });
    try {
      const response = await apiFunction(params);
      const mapper = response.map(item => item.name);
      label === 'pharmacotherapy' ?? mapper?.sort((a, b) => a - b);
      commit('setItems', {
        label,
        value: mapper,
        isLoading: false,
      });
    } catch (error) {
      commit('setItems', {
        label,
        value: [],
        isLoading: false,
      });
      return error;
    }
  },
  // Get Data Factory
  async resolveGetFactory({ dispatch }, search = '') {
    await dispatch('fetchDataResource', {
      label: 'factory',
      apiFunction: getFactory,
      params: { search },
    });
  },
  // Get Data CategoryByMenu
  async resolveGetCategoryByMenu({ dispatch }) {
    await dispatch('fetchDataResource', {
      label: 'categoryByMenu',
      apiFunction: getCategoryByMenu,
    });
  },
  // Get Data PackagesByMenu
  async resolveGetPackagesByMenu({ dispatch }) {
    await dispatch('fetchDataResource', {
      label: 'packagesByMenu',
      apiFunction: getPackagesByMenu,
    });
  },
  // Get Data Preparation
  async resolveGetPreparation({ dispatch }) {
    await dispatch('fetchDataResource', {
      label: 'preparation',
      apiFunction: getPreparation,
    });
  },
  // Get Data Roa
  async resolveGetRoa({ dispatch }) {
    await dispatch('fetchDataResource', {
      label: 'roa',
      apiFunction: getRoa,
    });
  },
  // Get Data Group
  async resolveGetGroup({ dispatch }) {
    await dispatch('fetchDataResource', {
      label: 'group',
      apiFunction: getGroup,
    });
  },
  // Get Data Pharmacotherapy
  async resolveGetPharmacotherapy({ dispatch }) {
    await dispatch('fetchDataResource', {
      label: 'pharmacotherapy',
      apiFunction: getPharmacotherapy,
    });
  },
  // End Get Data Resource
  // End Get Data

  // Post Data
  async resolvePostDrugs({ state, commit }, packaging) {
    const payload = {
      ...state.form,
      packaging,
    };

    Object.keys(payload).forEach(key => {
      if (payload[key] === null) {
        delete payload[key];
      }
    });
    delete payload.id;

    try {
      const postDrugsRes = await postDrugs(payload);
      if (postDrugsRes.status === 200) {
        commit('setDialog', false);
      }
      return postDrugsRes.status;
    } catch (error) {
      return error;
    }
  },
  // End Post Data

  // Put Data
  async resolvePutDrugs({ state, commit }, packaging) {
    const payload = {
      ...state.form,
      packaging,
    };

    Object.keys(payload).forEach(key => {
      if (payload[key] === null) {
        delete payload[key];
      }
    });
    delete payload.id;

    try {
      const putDrugsRes = await putDrugs(state.form.id, payload);
      if (putDrugsRes.status === 200) {
        commit('setDialog', false);
      }
      return putDrugsRes.status;
    } catch (error) {
      return error;
    }
  },

  async resolvePutStatusDrugs(_, { id_drug, isActive }) {
    try {
      await putStatusDrugs({ id_drug, isActive });
    } catch (error) {
      return error;
    }
  },

  // Delete Data
  async resolveDeleteDrugs(_, id) {
    try {
      const responseDeleteDrugs = await deleteDrugs(id);
      return responseDeleteDrugs;
    } catch (error) {
      return error;
    }
  },

  //  Add new factory
  async resolveAddFactory(_, payload) {
    try {
      await addFactory(payload);
    } catch (error) {
      throw new Error(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
